<template>
  <div v-if="content || findings.length">
    <h6 class="headline6 mb-4" data-testid="tickets-page-email-preview-title">
      {{ $t("ticketDetails.fullDetails.title") }}
    </h6>
    <findings-full-details
      :ticket="ticket"
      :show-title="false"
      class="mb-4"
    ></findings-full-details>
    <v-expansion-panels
      v-if="attachments?.length"
      :model-value="0"
      flat
      accordion
      class="full-details-wrapper expansion-panel-wrapper"
    >
      <v-expansion-panel>
        <v-expansion-panel-title class="expansion-panel-header pl-4 pt-2 pb-2">
          <template v-slot:actions="{ expanded }">
            <v-icon :class="{ rotated: expanded }" icon="$chevron"></v-icon>
          </template>
          <span class="subtitle1">{{ $t("ticketDetails.fullDetails.emailAttachments") }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div v-for="attachment in attachments" :key="attachment.fileName" class="body2">
            <span>{{ attachment.fileName }} - {{ convertSize(attachment.fileSize, 1) }}</span>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <email-preview
      v-if="content"
      class="full-details-wrapper"
      :html-content="content"
      data-testid="tickets-page-email-preview"
    ></email-preview>
  </div>
</template>

<script lang="ts">
import { convertSize, removeMaliciousLinksFromContent } from "@/_helpers/utils";
import { computed, defineComponent, type PropType } from "vue";
import FindingsFullDetails from "@/components/tickets/ticket-preview-details/full-details/FindingsFullDetails.vue";
import EmailPreview from "@/components/tickets/EmailPreview.vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";

export default defineComponent({
  components: {
    FindingsFullDetails,
    EmailPreview,
  },
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const content = computed(() =>
      removeMaliciousLinksFromContent(props.ticket.sections?.emailMetaData?.content ?? "")
    );
    const attachments = computed(() => props.ticket.sections?.emailMetaData?.attachments ?? []);
    const findings = computed(() => props.ticket.sections?.findings ?? []);

    return {
      content,
      convertSize,
      attachments,
      findings,
    };
  },
});
</script>

<style lang="scss" scoped>
.full-details-wrapper {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}

.expansion-panel-wrapper {
  border-radius: 0;
}

.expansion-panel-header {
  height: 80px;
}
:deep(*) {
  .v-expansion-panel-text__wrapper {
    padding-left: 16px;
  }
}
</style>
