export enum AccountErrors {
  GENERIC = "genericError",
  GENERAL = "general_error",
  NOT_ACCEPTED_INVITATION = "notAcceptedInvitation",
  CREDENTIALS = "credentialsError",
  EMAIL_NOT_FOUND = "emailNotFound",
  PASSWORD_SHOULD_BE_DIFFERENT = "passwordShouldBeDifferent",
  RESET_LINK_INVALID = "resetLinkInvalid",
  INCORRECT_PASSWORD = "Incorrect password",
  USER_DOES_NOT_EXIST = "user_does_not_exist",
  WEAK_PASSWORD = "weakPassword",
  TOKEN_EXPIRED = "tokenExpired",
  INVITE_HAS_BEEN_REVOKED = "inviteRevoked",
  MFA_REQUIRED = "mfaRequired",
  INVALID_VERIFICATION_CODE = "invalidVerificationCode",
  TOO_MANY_OTP_REQUESTS = "tooManyOtpResetRequests",
  INVALID_PHONE_NUMBER = "invalidPhoneNumber",
}

export enum MfaStatus {
  NONE = "NONE",
  TOTP = "TOTP", //account completed both auth app and sms verification
}

export enum VerificationMode {
  SMS = "sms",
  AUTH_APP = "app",
}

export enum AccountAction {
  TOGGLE_MFA = "toggleMFAConfig",
}
