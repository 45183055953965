import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Paged, Pagination } from "@/types";
import {
  type DevicesFilters,
  type ExportsFilters,
  type TicketsFilters,
  type UsersSettingsFilters,
  useFiltersStore,
} from "@/_store/filters.module";
import { useSnackbarStore } from "@/_store/snackbar.module";
import moment from "moment";
import { i18n } from "@/plugins/i18n";
import type { ActivityLogsFilters } from "@/_store/activity-logs.module";
import type { ISelection } from "@/_store/selector.module";
import { convertDevicesGroupsFilter } from "@/_store/devices.module";
import { TicketStatus } from "@/constants/tickets";
import type { Service } from "@/constants/cloud-apps";

export enum ExportFileExtension {
  CSV = "csv",
  ZIP = "zip",
}

export interface ExportListItem {
  id: string;
  type: ExportCategory;
  exportFileExtension: ExportFileExtension;
  exportName: string;
  creationTime: number;
  downloadLink: string;
}

export enum ExportCategory {
  ACTIVITY_LOGS = "activityLogs",
  DEVICES = "devices",
  USERS = "users",
  TICKETS = "tickets",
}

export enum ExportChoice {
  ALL = "all",
  SPECIFIC = "specific",
}

export interface ExportDialogPayload<K> {
  type: ExportChoice;
  filters: K;
}

interface ExportsStoreState {
  loading: boolean;
  exportsList: Paged<ExportListItem>;
  pagination: Pagination;
}

const defaultReportsState: ExportsStoreState = {
  loading: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  exportsList: {
    items: [],
    total: 0,
  },
};

export const useExportsStore = defineStore("exports", {
  state: (): ExportsStoreState => ({ ...defaultReportsState }),
  actions: {
    async getExportsList() {
      const filters = convertFiltersForBackend(useFiltersStore().filters.exportsFilters);
      const request = {
        ...api.getExportsList(),
        params: {
          ...filters,
        },
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.exportsList = data;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async exportActivityLogsToCsv(
      payload: ExportDialogPayload<ActivityLogsFilters>
    ): Promise<void> {
      let params = {};
      if (payload.type === ExportChoice.SPECIFIC) {
        const { search, timeFrame, types } = payload.filters;
        params = {
          search,
          types,
          toTime: timeFrame?.end ? moment(timeFrame?.end).endOf("day").utc().valueOf() : null,
          fromTime: timeFrame?.start
            ? moment(timeFrame?.start).startOf("day").utc().valueOf()
            : null,
        };
      }
      try {
        const request = {
          ...api.activityLogsExportCSV,
          params,
        };

        await axiosInstance.request(request);

        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.exports.exportCsvStarted")
        );
      } catch (error) {
        console.error(error);
      }
    },
    async exportProtectedUsersToCsv(payload: ExportDialogPayload<UsersSettingsFilters>) {
      const request = {
        ...api.exportUsersCsv,
        params: {
          cloudServices:
            payload.type === ExportChoice.SPECIFIC
              ? payload.filters.cloudServices
                  .filter((v) => v.type === "cloudService")
                  .map((v) => v.name as Service)
                  .join(",")
              : undefined,
        },
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.exports.exportCsvStarted")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async exportDevicesToCsv(payload: ExportDialogPayload<DevicesFilters>) {
      const filter =
        payload.type === ExportChoice.ALL
          ? {}
          : {
              ...payload.filters,
              ...convertDevicesGroupsFilter(payload.filters.labels),
            };
      const requestData: {
        selection: ISelection;
        filter: { [key: string]: unknown };
      } = {
        selection: {
          isAllPages: true,
          include: [],
          exclude: [],
        },
        filter,
      };
      const request = {
        ...api.devicesCsvExport,
        data: requestData,
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.exports.exportCsvStarted")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async exportTicketsToCsv(payload: ExportDialogPayload<TicketsFilters>) {
      const filters =
        payload.type === ExportChoice.ALL
          ? {
              protectedUsersOnly: false,
            }
          : {
              ...convertTicketFiltersForBackend({ ...payload.filters }),
              protectedUsersOnly: false,
            };
      const request = {
        ...api.exportTicketsToCsv(),
        data: {
          filters,
          selection: {
            isAllPages: true,
            include: [],
            exclude: [],
          },
        },
      };
      try {
        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.exports.exportCsvStarted")
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
});

function convertFiltersForBackend({ timeFrame: { end, start }, search, types }: ExportsFilters) {
  return {
    search,
    types: types?.join(","),
    toTime: end ? moment(end).endOf("day").utc().valueOf() : null,
    fromTime: start ? moment(start).startOf("day").utc().valueOf() : null,
  };
}

export const convertTicketFiltersForBackend = (filters: TicketsFilters) => {
  const processed =
    filters.status === TicketStatus.CLOSED
      ? true
      : filters.status === TicketStatus.OPEN
      ? false
      : undefined;
  const fromTime = filters.eventTimeRange?.start
    ? moment(filters.eventTimeRange.start).startOf("day").utc().valueOf()
    : undefined;
  const toTime = filters.eventTimeRange?.end
    ? moment(filters.eventTimeRange.end).endOf("day").utc().valueOf()
    : undefined;
  return {
    ...filters,
    processed,
    fromTime,
    toTime,
  };
};
