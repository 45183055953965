<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="d-flex justify-space-between align-center mb-5 mt-4">
      <div class="headline5 d-flex align-center text-primary">
        <span>{{ $t("connectors.title") }}</span>
      </div>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false"></page-tabs>
    <RouterView v-slot="{ Component }">
      <component :is="Component" :provider="provider" />
    </RouterView>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useAccountStore } from "@/_store";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { ConnectorActions } from "@/constants/connectors";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";

enum PsaConnectorConfigurationPageTabs {
  WORKSPACE_MAPPING = "workspace-mapping",
  SERVICE_MAPPING = "service-mapping",
  TICKETING = "ticketing",
}

export default defineComponent({
  components: {
    PageTabs,
  },
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  setup() {
    const accountStore = useAccountStore();
    const i18n = useI18n();

    const { account } = accountStore;
    const psaEnabled = account.psaEnabled;
    const isTicketingEnabled = false; // TODO replace with real data when implemented

    const tabs: CoroTab[] = [
      {
        id: 0,
        name: i18n.t("connectwise.tabs.workspaceMapping"),
        route: PsaConnectorConfigurationPageTabs.WORKSPACE_MAPPING,
      },
      {
        id: 1,
        name: i18n.t("connectwise.tabs.serviceMapping"),
        route: PsaConnectorConfigurationPageTabs.SERVICE_MAPPING,
      },
    ];

    if (isTicketingEnabled) {
      tabs.push({
        id: 2,
        name: i18n.t("connectwise.tabs.ticketing"),
        route: PsaConnectorConfigurationPageTabs.TICKETING,
      });
    }

    return {
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings/connectors/psa" },
        },
      ],
      coronetSkeletonLoaderTypes,
      psaEnabled,
      tabs,
      connectorActions: ConnectorActions,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  tr {
    height: 54px;
  }
}

.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}
</style>
