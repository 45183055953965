<template>
  <div class="content-md margin-auto">
    <div class="mb-3">
      <v-breadcrumbs data-testid="go-home-link" :items="breadCrumbsItems" />
    </div>

    <v-row class="mx-0 mt-5">
      <v-col class="pl-0" cols="6">
        <v-card>
          <v-card-text>
            <div class="subtitle1 mb-4 pt-4 pl-4">
              {{ $t(`ticketDetails.fullDetails.abnormalDataManipulation.${eventTrigger}Files`) }}
            </div>
            <div class="px-4">
              <div class="d-flex align-center pb-3">
                <v-icon
                  size="40"
                  class="mr-4"
                  :icon="`$${ticketTriggerIcons[eventTrigger]}`"
                ></v-icon>
                <div class="coro-body2 text--grey">{{ path }}</div>
              </div>
              <div class="file-list-wrapper">
                <div class="d-flex justify-center">
                  <v-progress-circular v-if="loading" color="primary" indeterminate size="36" />
                </div>
                <v-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th>
                          {{ $t("ticketDetails.fullDetails.abnormalDataManipulation.name") }}
                        </th>
                        <th>
                          {{ $t("ticketDetails.fullDetails.abnormalDataManipulation.mimeType") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(file, index) in files"
                        :id="index.toString()"
                        :key="`row-${index}`"
                        v-intersect="handleVirtualScroll"
                      >
                        <td class="pa-4">
                          <span class="subtitle2">{{ file.fileName }}</span>
                        </td>
                        <td class="pa-4">
                          <span class="body2">{{ file.mimeType }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { handleVirtualScrollData } from "@/_helpers/utils";
import { defineComponent, type PropType, ref, toRefs, watch } from "vue";
import { TicketStatus, type TicketTrigger, ticketTriggerIcons } from "@/constants/tickets";
import { useI18n } from "vue-i18n";
import { type AbnormalDataManipulationFile, useTicketStore } from "@/_store/tickets/ticket.module";

export default defineComponent({
  props: {
    ticketId: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    pathId: {
      type: String,
      required: true,
    },
    eventTrigger: {
      type: String as PropType<TicketTrigger>,
      required: true,
    },
  },
  setup(props) {
    const { path } = toRefs(props);
    const ticketStore = useTicketStore();
    const i18n = useI18n();
    const page = ref(0);
    const totalFiles = ref(0);
    const loading = ref(false);
    const files = ref<AbnormalDataManipulationFile[]>([]);

    async function getDetails() {
      loading.value = true;
      const response = await ticketStore.getMassDeleteDownloadFiles({
        page: page.value,
        folderId: props.pathId,
        eventId: props.ticketId,
      });
      files.value = handleVirtualScrollData(files.value, response.items, "id");
      totalFiles.value = response.total;
      loading.value = false;
    }

    const handleVirtualScroll = (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === files.value.length - 1) {
        // last tab reached in backend, don't do new request
        if (intersectedItemId === totalFiles.value - 1) {
          return;
        }
        page.value += 1;
        getDetails();
      }
    };

    watch(
      path,
      async (val) => {
        if (val) {
          await getDetails();
        }
      },
      { deep: true, immediate: true }
    );

    return {
      ticketTriggerIcons,
      breadCrumbsItems: [
        {
          title: i18n.t("general.ticketLog"),
          disabled: false,
          to: {
            path: "/portal/tickets",
            query: { search: props.ticketId, status: TicketStatus.ALL },
          },
        },
      ],
      files,
      loading,
      handleVirtualScroll,
    };
  },
});
</script>
<style lang="scss" scoped>
.file-list-wrapper {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
</style>
