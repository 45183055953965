<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
    >
      <div class="subtitle1 mb-5">
        {{ $t("accessControlPage.activeSessions.description") }}
      </div>
      <active-sessions-table
        :loading="loading"
        :items="activeSessions"
        :total-items="total"
        :pagination="pagination"
        @page-changed="onPageChange($event)"
      />
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import ActiveSessionsTable from "@/components/tables/ActiveSessionsTable.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useActiveSessionsStore } from "@/_store/active-sessions.module";
import { storeToRefs } from "pinia";
import type { Pagination } from "@/types";

export default defineComponent({
  components: {
    ActiveSessionsTable,
  },
  setup() {
    const i18n = useI18n();
    const activeSessionStore = useActiveSessionsStore();
    const { activeSessions, showSkeletonLoader, total, pagination, loading } =
      storeToRefs(activeSessionStore);

    onMounted(async () => {
      await activeSessionStore.getActiveSessions(true);
    });

    const onPageChange = async ({ page, pageSize }: Pagination) => {
      activeSessionStore.setPagination({
        page,
        pageSize,
      });
      await activeSessionStore.getActiveSessions();
    };

    return {
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      coronetSkeletonLoaderTypes,
      activeSessions,
      showSkeletonLoader,
      total,
      pagination,
      loading,
      onPageChange,
    };
  },
});
</script>
