<template>
  <div class="w-100 d-flex justify-center mt-12">Page in development...</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped></style>
