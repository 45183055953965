<template>
  <v-skeleton-loader
    class="skeleton-loader--small-table"
    :loading="showSkeletonLoader"
    type="table-tbody"
  >
    <div class="d-flex justify-space-between align-center mb-8">
      <div class="subtitle1 mt-3">
        {{ $t("network.settings.tunnels.title") }}
      </div>
      <v-btn
        size="large"
        rounded="pill"
        class="add-btn"
        color="primary"
        @click="onActionClick({ action: SiteToSiteTunnelsAction.ADD })"
      >
        <v-icon color="white" class="mr-2" icon="$add"> </v-icon>
        <span>{{ $t("general.add") }}</span>
      </v-btn>
    </div>
    <site-to-site-tunnels-table
      @item-action-applied="onActionClick($event)"
      @last-item-reached="onLastItemReached()"
    />
  </v-skeleton-loader>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import SiteToSiteTunnelsTable from "@/components/tables/SiteToSiteTunnelsTable.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  type SiteToSiteTunnel,
  useSiteToSiteTunnelStore,
} from "@/_store/network/site-to-site-tunnels.module";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SubscriptionModule } from "@/constants/workplaces";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SiteToSiteTunnelsAction } from "@/constants/network";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import AddSiteToSiteTunnelModal from "@/components/modals/network/AddSiteToSiteTunnelModal.vue";
import TestSiteToSiteTunnelModal from "@/components/modals/network/TestSiteToSiteTunnelModal.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { SiteToSiteTunnelsTable },
  setup() {
    const i18n = useI18n();
    const dialogStore = useDialogsStore();
    const siteToSiteTunnelsStore = useSiteToSiteTunnelStore();
    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.NETWORK
    );
    const { tunnelsList, total, pagination, showSkeletonLoader } =
      storeToRefs(siteToSiteTunnelsStore);

    onMounted(async () => {
      siteToSiteTunnelsStore.resetTunnelsList();
      await siteToSiteTunnelsStore.getTunnelsList(true, true);
    });

    const onLastItemReached = async () => {
      siteToSiteTunnelsStore.$patch((state) => {
        state.pagination.page += 1;
      });
      await siteToSiteTunnelsStore.getTunnelsList();
    };

    const onActionClick = async ({
      action,
      item,
    }: {
      item?: SiteToSiteTunnel;
      action: SiteToSiteTunnelsAction;
    }) => {
      const callbackMap = {
        [SiteToSiteTunnelsAction.ADD]: siteToSiteTunnelsStore.addTunnel,
        [SiteToSiteTunnelsAction.DELETE]: siteToSiteTunnelsStore.deleteTunnel,
        [SiteToSiteTunnelsAction.EDIT]: siteToSiteTunnelsStore.editTunnel,
      };
      switch (action) {
        case SiteToSiteTunnelsAction.TEST:
          dialogStore.openDialog({
            ...componentDialogsConfigConstructor({
              component: TestSiteToSiteTunnelModal,
              item,
              action,
              width: ModalWidth.LARGE,
              callback: () => {},
              disable: actionNotAllowed,
            }),
            footer: {
              buttons: [
                {
                  title: i18n.t("general.close"),
                  color: "primary",
                  spacer: "before",
                  callback: () => {
                    dialogStore.closeDialog();
                  },
                  cancel: false,
                },
              ],
            },
          });
          break;
        case SiteToSiteTunnelsAction.ADD:
        case SiteToSiteTunnelsAction.EDIT:
          dialogStore.openDialog({
            ...componentDialogsConfigConstructor({
              component: AddSiteToSiteTunnelModal,
              item,
              action,
              width: ModalWidth.LARGE,
              callback: callbackMap[action],
              disable: actionNotAllowed,
              scrollable: true,
            }),
          });
          break;
        case SiteToSiteTunnelsAction.DELETE:
          dialogStore.openDialog({
            ...confirmationDialogsConfigConstructor({
              action,
              disable: actionNotAllowed,
              item,
              callback: callbackMap[action],
            }),
          });
          break;
        case SiteToSiteTunnelsAction.ENABLE_TUNNEL:
        case SiteToSiteTunnelsAction.DISABLE_TUNNEL:
          await siteToSiteTunnelsStore.toggleTunnelDisabledState(
            item!,
            action === SiteToSiteTunnelsAction.ENABLE_TUNNEL
          );
          break;
        case SiteToSiteTunnelsAction.DOWNLOAD_LOG:
          await siteToSiteTunnelsStore.downloadTunnelLogs(item!);
          break;
      }
    };

    return {
      coronetSkeletonLoaderTypes,
      tunnelsList,
      total,
      pagination,
      showSkeletonLoader,
      actionNotAllowed,
      onLastItemReached,
      onActionClick,
      SiteToSiteTunnelsAction,
    };
  },
});
</script>

<style lang="scss" scoped></style>
