<template>
  <v-app class="vue3">
    <v-main>
      <navigation-drawer v-if="logged" />
      <app-bar v-if="logged && account.workplace" />
      <v-container fluid :class="{ 'pa-0 h-100': !logged }">
        <router-view :key="workspaceTimestamp" />
      </v-container>
    </v-main>
    <CoroDialog
      :config="dialogConfig"
      :value="showDialog"
      v-if="showDialog"
      @onClose="closeDialog"
    />
    <CoroSnackbar />
  </v-app>
</template>

<script lang="ts">
import AppBar from "@/components/AppBar.vue";
import CoroSnackbar from "@/components/CoroSnackbar.vue";
import CoroDialog from "@/components/modals/CoroDialog.vue";

import { defineComponent, onMounted, ref, watch } from "vue";
import { useErrorsStore } from "@/_store/errors.module";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { storeToRefs } from "pinia";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useSubscriptionStore } from "@/_store/subscription.module";
import { dialogsConfigConstructor, getSelectedAddons, getSelectedModules } from "@/_helpers/utils";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useAccountStore } from "@/_store";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants/routes";
import CryptoJS from "crypto-js";
import { WorkspaceLocale } from "@/constants/workplaces";
import { i18n } from "@/plugins/i18n";
import moment from "moment/moment";
import { en } from "@/translations/en";

const chameleonSecret = import.meta.env.VITE_CHAMELEON_SECRET;
const environment = import.meta.env.VITE_CLIENT_ENV;

export default defineComponent({
  components: {
    NavigationDrawer,
    CoroSnackbar,
    CoroDialog,
    AppBar,
  },
  setup() {
    const errorsStore = useErrorsStore();
    const snackbarStore = useSnackbarStore();
    const dialogsStore = useDialogsStore();
    const workspacesStore = useWorkspacesStore();
    const myAccountStore = useMyAccountStore();
    const accountStore = useAccountStore();
    const subscriptionStore = useSubscriptionStore();
    const { closeDialog, openDialog } = dialogsStore;
    const { showDialog, dialogConfig } = storeToRefs(dialogsStore);
    const { error, errors } = storeToRefs(errorsStore);
    const { subscription } = storeToRefs(subscriptionStore);
    const { add } = snackbarStore;
    const { logged, account } = storeToRefs(accountStore);
    const workspaceTimestamp = ref(0);
    const route = useRoute();
    const openErrorModal = (errorMessage: string) => {
      const errorModal = {
        width: ModalWidth.SMALL,
        header: undefined,
        content: {
          component: ErrorModal,
        },
        footer: null,
        data: {
          item: errorMessage,
        },
      };
      const dialogConfig = dialogsConfigConstructor(errorModal);
      openDialog(dialogConfig);
    };

    const getLoggedUserDataForChameleon = () => {
      const modules = getSelectedModules(subscription.value);
      const bundles = subscription.value.bundles;
      const addons = getSelectedAddons(subscription.value);
      const { workspaceType, workplace } = account.value;
      return {
        workspaceType,
        uid: workplace,
        workspaceId: workplace,
        subscription: {
          modules,
          bundles,
          addons,
        },
        subscriptionType: subscription.value.currentPlan.subscriptionType,
      };
    };

    const identifyChameleon = () => {
      const { email, workplace } = account.value;
      const userId = email && workplace ? `${email}+${workplace}` : "unknown user";
      const uid_hash = CryptoJS.HmacSHA256(userId, chameleonSecret).toString(CryptoJS.enc.Hex);
      if (accountStore.logged) {
        chmln.identify(userId, {
          uid_hash,
          environment,
          email,
          ...getLoggedUserDataForChameleon(),
        });
      } else {
        chmln.identify(userId, {
          uid_hash,
          environment,
        });
      }
    };

    watch(
      () => [...errors.value],
      (newValue, previousValue) => {
        // If error is null or popping errors from store - do nothing
        if (!error.value || newValue.length < previousValue.length) return;
        const errorMessage = (en.errors as any)[error.value.message]
          ? i18n.global.t(`errors.${error.value.message}`)
          : error.value.message;
        const showModal = error.value.showDialog;
        if (showModal) {
          openErrorModal(errorMessage);
        } else {
          const snackbarMessage = {
            html: errorMessage,
            type: SnackbarTypes.ERROR,
          };
          add(snackbarMessage);
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      if (accountStore.account.workplace) {
        await workspacesStore.getCurrentWorkspace();
        await myAccountStore.getProfileData();
      }
      identifyChameleon();
    });

    watch(
      () => account.value.workplace,
      () => {
        if (route.name === RouteName.DASHBOARD) {
          workspaceTimestamp.value = new Date().getTime();
        }
        identifyChameleon();
      }
    );

    watch(
      () => account.value.languageCode,
      (value, previousValue) => {
        if (value !== previousValue) {
          i18n.global.locale.value = value ?? WorkspaceLocale.EN;
          moment.locale(value ?? WorkspaceLocale.EN);
        }
      },
      { immediate: true }
    );

    return {
      errors,
      dialogConfig,
      showDialog,
      closeDialog,
      logged,
      account,
      workspaceTimestamp,
    };
  },
});
</script>

<style lang="scss">
@import "main";
</style>
