<template>
  <v-menu v-model="menu" :close-on-content-click="false" :persistent="false" :min-width="360">
    <template v-slot:activator="{ props }">
      <div v-bind="props">
        <slot></slot>
      </div>
    </template>
    <v-date-picker
      :modelValue="getDate"
      :min="minDate"
      :hide-header="true"
      prev-icon="icon-chevron"
      next-icon="icon-chevron"
      @update:modelValue="onDateSelect($event)"
    ></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { format } from "date-fns";

export default defineComponent({
  name: "MenuDatePicker",
  props: {
    date: {
      type: String,
      default: "",
    },
    minDate: {
      type: String,
      default: format(new Date(), "yyyy-MM-dd"),
    },
  },
  emits: ["update:date"],
  setup(props, { emit }) {
    const date = ref(props.date);

    const getDate = computed(() => {
      return date.value ? new Date(date.value) : new Date();
    });

    const menu = ref(false);

    const onDateSelect = (val: Date) => {
      menu.value = false;
      date.value = val.toDateString();
      emit("update:date", val);
    };

    return {
      getDate,
      menu,
      onDateSelect,
    };
  },
});
</script>
