import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { AdminUser } from "@/_store";

export enum SocContactNotificationTypes {
  EMAIL = "email",
  DO_NOT_NOTIFY = "doNotNotify",
}

export enum SocContactClosureNotification {
  PER_TICKET = "perTicket",
  WEEKLY = "weekly",
  DO_NOT_NOTIFY = "doNotNotify",
}

export interface SocContactItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  notification: SocContactNotificationTypes;
  closureNotification: SocContactClosureNotification;
}

interface SocContactState {
  items: Array<SocContactItem>;
  showSkeletonLoader: boolean;
}

const defaultSocRemediationState: SocContactState = {
  items: [],
  showSkeletonLoader: false,
};

export const useSocContactStore = defineStore("socContact", {
  state: (): SocContactState => ({ ...defaultSocRemediationState }),
  persist: false,
  actions: {
    async getSocContacts(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;
      try {
        const { data } = await axiosInstance.request(api.getSocContacts);
        this.items = data;
        this.showSkeletonLoader = false;
      } catch (e) {
        this.showSkeletonLoader = false;
        console.error(e);
      }
    },
    async updateSocRemediationSettings(payload: DialogDataConfig<SocContactItem>) {
      const request = {
        ...api.editSocContacts(payload.item.id),
        data: payload.item,
      };
      try {
        await axiosInstance.request(request);
        await this.getSocContacts(false);
        const snackbarStore = useSnackbarStore();
        const snackbarMessage = {
          html: i18n.global.t("snackbar.messages.general.settingsUpdated"),
          type: SnackbarTypes.SUCCESS,
        };
        snackbarStore.add(snackbarMessage);
      } catch (e) {
        console.error(e);
      }
    },
    async deleteSocRemediationSettings(payload: DialogDataConfig<SocContactItem>) {
      const request = {
        ...api.editSocContacts(payload.item.id),
        method: "delete",
      };
      try {
        await axiosInstance.request(request);
        await this.getSocContacts(false);
        const snackbarStore = useSnackbarStore();
        const snackbarMessage = {
          html: i18n.global.t("snackbar.messages.general.settingsUpdated"),
          type: SnackbarTypes.SUCCESS,
        };
        snackbarStore.add(snackbarMessage);
      } catch (e) {
        console.error(e);
      }
    },
    async getAdmins(search = ""): Promise<AdminUser[]> {
      try {
        const { data } = await axiosInstance.request({
          ...api.adminUsers,
          params: { search },
        });
        return data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async addSocRemediationSettings(payload: DialogDataConfig<SocContactItem>) {
      const request = {
        ...api.getSocContacts,
        method: "POST",
        data: payload.item,
      };
      try {
        await axiosInstance.request(request);
        await this.getSocContacts(false);
        const snackbarStore = useSnackbarStore();
        const snackbarMessage = {
          html: i18n.global.t("snackbar.messages.general.settingsUpdated"),
          type: SnackbarTypes.SUCCESS,
        };
        snackbarStore.add(snackbarMessage);
      } catch (e) {
        console.error(e);
      }
    },
  },
});
