import { useSnackbarStore } from "@/_store";
import { getSelectionAsStringsArray } from "@/_helpers/utils";
import { defineStore } from "pinia";
import type { Pagination } from "@/types";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { useUsersSettingsStore } from "@/_store/users-settings/users-settings.module";
import { i18n } from "@/plugins/i18n";
import type { ISelection } from "@/_store/selector.module";
import type { AxiosResponse } from "axios";

export interface UserGroupSettingsItem {
  groupId: string;
  name: string;
  protectionGrantedTime: number;
  membersCount: number;
  service: string;
}

export interface UserGroupsTabFilters {
  searchTerm: string;
}

interface UserGroupsTabState {
  userGroups: UserGroupSettingsItem[];
  totalItems: number;
  totalPages: number;
  pagination: Pagination;
  showSkeletonLoader: boolean;
  loading: boolean;
  filters: UserGroupsTabFilters;
}

export const defaultGroupsFilters = {
  searchTerm: "",
};

const defaultUserGroupsState = {
  userGroups: [],
  totalItems: 0,
  totalPages: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  showSkeletonLoader: true,
  loading: false,
  filters: {
    ...defaultGroupsFilters,
  },
};

export const useGroupsSettingsTabStore = defineStore("users-groups-tab", {
  state: (): UserGroupsTabState => ({ ...defaultUserGroupsState }),
  actions: {
    init() {
      this.getUserGroups();
    },
    getUserGroups(showSkeletonLoader: boolean = false) {
      const request = {
        ...api.groups(this.filters.searchTerm, this.pagination),
      };
      this.loading = true;
      this.showSkeletonLoader = showSkeletonLoader;
      axiosInstance
        .request(request)
        .then((response) => {
          const data = response.data;
          this.totalItems = data.total;
          this.userGroups = data.items;
          this.loading = false;
          this.showSkeletonLoader = false;
        })
        .catch(() => {
          this.loading = false;
          this.showSkeletonLoader = false;
        });
    },
    searchProtectedGroups(
      searchTerm: string
    ): Promise<AxiosResponse<{ items: UserGroupSettingsItem[] }>> {
      const request = {
        ...api.groups(searchTerm, { page: 0, pageSize: 10 }),
      };
      return axiosInstance.request(request);
    },
    searchUserGroups(name: string) {
      const request = {
        ...api.searchGroups(name),
      };

      return axiosInstance.request(request);
    },
    applyPaginationChange(payload: Pagination) {
      this.pagination = payload;
      this.getUserGroups();
    },
    async addNewGroup(payload: { groups: Array<UserGroupSettingsItem> }) {
      const request = {
        ...api.addGroups,
        data: payload.groups,
      };

      try {
        await axiosInstance.request(request);
        handleGroupsChange();
      } catch (error) {
        console.error(error);
      }
    },
    async removeGroups(payload: { selection: ISelection<UserGroupSettingsItem> }) {
      const request = {
        ...api.removeGroups,
        data: {
          selection: getSelectionAsStringsArray(payload.selection, "groupId"),
          filters: this.filters,
        },
      };
      try {
        await axiosInstance.request(request);
        handleGroupsChange();
      } catch (error) {
        console.error(error);
      }
    },
    resetState() {
      this.$reset();
    },
    setGroupsFilters(filters: UserGroupsTabFilters) {
      this.filters = filters;
    },
    setGroupsPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
  },
});

function handleGroupsChange() {
  const userGroupsTabStore = useGroupsSettingsTabStore();
  const userSettingsStore = useUsersSettingsStore();
  const snackbarStore = useSnackbarStore();
  userGroupsTabStore.getUserGroups();
  userSettingsStore.init();
  snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.userGroups.userGroupsUpdated"));
}
