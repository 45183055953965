import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { useAccountStore } from "@/_store/account.module";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";
import { AppType, Service, ServiceStatus } from "@/constants/cloud-apps";
import type { ConnectServiceRequestParams } from "@/_helpers/api.types";

export interface CloudAppService {
  name: Service;
  serviceStatus: ServiceStatus;
  connectedThirdPartyApps: number;
}

export interface CloudAppsState {
  services: CloudAppService[];
  isLoading: boolean;
  showSkeletonLoader: boolean;
  boxConfigFileError: boolean;
}

const defaultCloudAppsState = {
  services: [],
  isLoading: false,
  showSkeletonLoader: false,
  boxConfigFileError: false,
};

export const useCloudAppsStore = defineStore("cloud-apps", {
  state: (): CloudAppsState => ({ ...defaultCloudAppsState }),
  getters: {
    connectedServices(state) {
      return state.services.filter((service) => service.serviceStatus === ServiceStatus.CONNECTED);
    },
    notConnectedServices(state) {
      return state.services.filter((service) => service.serviceStatus !== ServiceStatus.CONNECTED);
    },
  },
  actions: {
    async getServices(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;

      const request = {
        ...api.services,
      };

      try {
        const { data } = await axiosInstance.request(request);

        this.services = data;
      } catch (error) {
        console.error(error);
      }
      this.showSkeletonLoader = false;
    },
    async uploadBoxConfiguration(file: File) {
      this.isLoading = true;
      this.boxConfigFileError = false;

      const formData = new FormData();
      formData.append("file", file);

      const request = {
        ...api.uploadBoxConfiguration(formData),
      };

      try {
        await axiosInstance.request(request);
      } catch (error) {
        console.error(error);
        this.boxConfigFileError = true;
      }

      this.isLoading = false;
    },
    async connect({ appType, service, onboarding }: ConnectServiceRequestParams) {
      this.isLoading = true;
      const { utmParams, brandingAlias } = useAccountStore().account;
      const request = {
        ...api.connectService(),
        params: {
          brandingAlias,
          service,
          appType: appType || AppType.DEFAULT,
          onboarding: onboarding || false,
          ...utmParams,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);

        window.location.href = data.redirectUrl;
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    async disconnect(payload: Service) {
      this.isLoading = true;

      const request = {
        ...api.disconnectService(payload),
      };

      try {
        await axiosInstance.request(request);

        await this.getServices();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.cloudApps.disconnected", {
            service: i18n.global.t(`services.${payload}`),
          })
        );
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
  },
});
