<template>
  <div
    class="draggable-file-uploader d-flex align-center justify-center text-center flex-column"
    @click="triggerFileInputClick"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div>
      <v-icon size="90" icon="$uploadFile" />
      <template v-if="!file || errorMessage">
        <div class="headline6" v-html="description" />
        <div class="body2 text-indigo-medium">{{ subtitle }}</div>
        <div
          v-if="!!errorMessage"
          class="body2 text-red-dark mt-2 d-flex align-center justify-center"
        >
          <v-icon size="24" icon="$redAlert" class="mr-2" />
          {{ errorMessage }}
        </div>
      </template>
      <template v-else>
        <div class="headline6">{{ fileName }}</div>
        <div class="coro-link caption" @click="removeFile($event)">
          {{ $t("general.remove") }}
        </div>
      </template>
      <v-file-input
        ref="fileInput"
        v-model="file"
        :accept="accept"
        class="d-none"
        @update:modelValue="handleUpload"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { i18n } from "@/plugins/i18n";

export default defineComponent({
  props: {
    description: {
      type: String,
      default() {
        return i18n.global.t("draggableFileUploader.description");
      },
    },
    subtitle: {
      type: String,
      default() {
        return i18n.global.t("draggableFileUploader.subtitle");
      },
    },
    accept: {
      type: String,
      default() {
        return "text/csv";
      },
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  emits: ["file-changed"],
  setup(props, { emit }) {
    const file = ref<File | File[]>();
    const fileInput = ref();
    const handleUpload = (event: File | File[]) => {
      file.value = event;
      emit("file-changed", (file.value as File[])[0] || file.value);
    };
    const dragover = (event: DragEvent) => {
      event.preventDefault();
      (event.currentTarget as HTMLDivElement)?.classList.add("draggable-file-uploader--dragover");
    };
    const dragleave = (event: DragEvent) => {
      (event.currentTarget as HTMLDivElement)?.classList.remove(
        "draggable-file-uploader--dragover"
      );
    };
    const drop = (event: DragEvent) => {
      event.preventDefault();
      (event.currentTarget as HTMLDivElement)?.classList.remove(
        "draggable-file-uploader--dragover"
      );
      const dropFile = event?.dataTransfer?.files[0];
      if (dropFile?.type && !props.accept.includes(dropFile.type)) {
        file.value = undefined;
        return;
      }
      if (dropFile) {
        file.value = [dropFile];
        emit("file-changed", file.value[0]);
      }
    };
    const removeFile = (event: MouseEvent) => {
      event.stopPropagation();
      file.value = undefined;
      emit("file-changed", file.value);
    };
    const triggerFileInputClick = () => {
      fileInput.value.click();
    };

    const fileName = computed(() => (file.value as File[])[0]?.name || (file.value as File).name);

    return {
      file,
      fileInput,
      handleUpload,
      dragover,
      dragleave,
      drop,
      fileName,
      removeFile,
      triggerFileInputClick,
    };
  },
});
</script>
<style scoped lang="scss">
.draggable-file-uploader {
  width: 100%;
  height: 270px;
  border-radius: 8px;
  border: dashed 1px rgb(var(--v-theme-primary));
  transition: 0.3s;
}

.draggable-file-uploader--dragover {
  background-color: rgb(var(--v-theme-indigo-pale));
}

.draggable-file-uploader:hover {
  background-color: rgb(var(--v-theme-indigo-pale));
  cursor: pointer;
}
</style>
