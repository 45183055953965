<template>
  <virtual-office-tab-settings
    v-if="showSettings"
    class="mb-4"
    :settings="virtualOfficeSettings"
    @settings-update="updateVpnSettings"
  />
  <div>
    <div class="d-flex justify-space-between align-center mb-5">
      <span class="subtitle1" v-text="$t('network.virtualOffice.includedExcludedResources')" />
      <div class="d-flex align-center">
        <filter-wrapper
          class="mr-2"
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters(getVpnUrlList)"
        >
          <v-select
            v-model="localFilters.excluded"
            density="compact"
            class="search-field filter-menu mr-2"
            :items="exclusionChoices"
            :class="{ 'filter-active': localFilters.excluded !== undefined }"
            :placeholder="$t('network.virtualOffice.allLists')"
            hide-details
            outlined
          >
            <template #item="{ item, props }">
              <v-list-item v-bind="props">
                <span v-text="$t(`network.virtualOffice.${item.raw ? 'excluded' : 'included'}`)" />
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <span v-text="$t(`network.virtualOffice.${item.raw ? 'excluded' : 'included'}`)" />
            </template>
          </v-select>
          <v-text-field
            v-model.trim="localFilters.search"
            data-testid="users-page-users-search"
            :class="{ 'filter-active': localFilters.search?.length }"
            dense
            outlined
            density="compact"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            clearable
            clear-icon="icon-x"
            hide-details
          />
        </filter-wrapper>
        <v-menu>
          <template #activator="{ props }">
            <v-btn v-bind="props" class="add-btn" color="primary" rounded large>
              <v-icon icon="$add" color="white" />
              <span v-text="$t('general.add')" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              class="v-list-item v-list-item--link theme--light"
              @click="
                onActionClick({
                  item: {
                    excluded: false,
                  },
                  action: VirtualOfficeAction.ADD_URL_TO_URL_LIST,
                })
              "
            >
              <template #title
                >{{ $t("network.virtualOffice.actions.addUrlToIncludelist") }}
              </template>
            </v-list-item>
            <v-list-item
              class="v-list-item v-list-item--link theme--light"
              @click="
                onActionClick({
                  item: {
                    excluded: true,
                  },
                  action: VirtualOfficeAction.ADD_URL_TO_URL_LIST,
                })
              "
            >
              <template #title
                >{{ $t("network.virtualOffice.actions.addUrlToExcludelist") }}
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-progress-linear v-if="urlListLoading" indeterminate height="2px" />
    <virtual-office-tab-url-table
      :pagination="pagination"
      :total-items="total"
      :items="urlList"
      :loading="urlListLoading"
      @remove-url="onActionClick($event)"
      @page-change="onPageChange($event)"
      @last-item-reached="onLastItemReached()"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted, watch } from "vue";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { useVirtualOfficeStore } from "@/_store/network/virtual-office.module";
import VirtualOfficeTabSettings from "@/components/VirtualOfficeTabSettings.vue";
import { storeToRefs } from "pinia";
import FilterWrapper from "@/components/FilterWrapper.vue";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import VirtualOfficeTabUrlTable from "@/components/tables/VirtualOfficeTabUrlTable.vue";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import { VirtualOfficeAction } from "@/constants/network";
import { useI18n } from "vue-i18n";
import AddVpnUrlModal from "@/components/modals/network/AddVpnUrlModal.vue";

const filterContext = FilterContext.VIRTUAL_OFFICE;

export default defineComponent({
  components: {
    VirtualOfficeTabUrlTable,
    FilterWrapper,
    VirtualOfficeTabSettings,
  },
  setup() {
    const { t } = useI18n();

    const virtualOfficeStore = useVirtualOfficeStore();
    const dialogStore = useDialogsStore();
    const {
      getVpnSettings,
      updateVpnSettings,
      getVpnUrlList,
      removeFromVpnUrlList,
      addUrlToUrList,
    } = virtualOfficeStore;
    const { openDialog } = dialogStore;
    const {
      urlList,
      urlListLoading,
      pagination,
      total,
      settings: virtualOfficeSettings,
      fetchingSettings,
    } = storeToRefs(virtualOfficeStore);

    const { clearFilters, showClearFiltersButton, localFilters, filtersUpdating } =
      useFilters(filterContext);

    const exclusionChoices = [false, true];

    onMounted(async () => {
      await getVpnSettings();
      await clearFilters(getVpnUrlList);
    });

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await getVpnUrlList();
      },
      { deep: true, immediate: true }
    );

    const showSettings = computed(
      () => Object.keys(virtualOfficeSettings.value).length > 0 && !fetchingSettings.value
    );

    const onActionClick = ({
      item,
      action,
    }: {
      item: { excluded: boolean; urlName?: string };
      action: string;
    }) => {
      let modalConfig: DialogConfig = {} as DialogConfig;
      switch (action) {
        case VirtualOfficeAction.REMOVE_FROM_INCLUDELIST:
        case VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST:
          modalConfig = {
            ...confirmationDialogsConfigConstructor({
              width: ModalWidth.SMALL,
              item,
              action,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.NETWORK
              ),
              callback: () => {
                removeFromVpnUrlList({
                  urlName: item.urlName!,
                  excluded: action === VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST,
                });
              },
            }),
            content: {
              html: t(`modals.${action}.description`, {
                item: item.urlName!,
              }),
            },
          };
          break;
        case VirtualOfficeAction.ADD_URL_TO_URL_LIST:
          modalConfig = {
            ...componentDialogsConfigConstructor({
              width: ModalWidth.LARGE,
              item,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.PROTECTION,
                SubscriptionModule.NETWORK
              ),
              component: AddVpnUrlModal,
              callback: (payload) => {
                addUrlToUrList(payload);
              },
              action,
            }),
            header: {
              title: t(
                `modals.${VirtualOfficeAction.ADD_URL_TO_URL_LIST}.${
                  item.excluded ? "excludedListTitle" : "includeListTitle"
                }`
              ),
            },
          };
          break;
        default:
      }
      openDialog(modalConfig);
    };

    const onPageChange = async (pageObj: { page: number; itemsPerPage: number }): Promise<void> => {
      virtualOfficeStore.$patch({
        pagination: {
          page: pageObj.page - 1,
          pageSize: pageObj.itemsPerPage,
        },
      });
      await getVpnUrlList();
    };

    const clearFiltersCallback = async (): Promise<void> => {
      virtualOfficeStore.$patch({
        pagination: {
          page: 0,
          pageSize: 25,
        },
      });
      await getVpnUrlList();
    };

    const onLastItemReached = async (): Promise<void> => {
      virtualOfficeStore.$patch({
        pagination: { page: pagination.value.page, pageSize: 25 },
      });
      await getVpnUrlList(true);
    };

    return {
      onPageChange,
      onLastItemReached,
      clearFiltersCallback,
      virtualOfficeSettings,
      showSettings,
      showClearFiltersButton,
      localFilters,
      exclusionChoices,
      VirtualOfficeAction,
      onActionClick,
      urlList,
      clearFilters,
      getVpnUrlList,
      total,
      urlListLoading,
      pagination,
      updateVpnSettings,
    };
  },
});
</script>

<style scoped lang="scss">
.virtual-office-tab-settings {
  display: flex;
  justify-content: center;
}
</style>
