<template>
  <div class="verification-page-wrapper margin-auto">
    <div class="headline5 mb-4">{{ $t("verification.title") }}</div>
    <div class="body1 mb-8">{{ $t(`verification.${currentMode}.enterCode`) }}</div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div v-if="tooManyOtpResetRequests" class="error-block d-flex mb-2 mt-2 align-center w-100">
        <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
        <span class="body2 text-red-dark">
          {{ $t("validations.tooManyOtpResetRequests") }}
        </span>
      </div>
      <v-otp-input
        ref="otp"
        v-model="verificationCode"
        length="6"
        :height="72"
        :max-width="372"
        type="number"
        @keydown="onOTPInput"
        @finish="onVerify(verificationCode)"
      />
      <div
        class="caption text-error mb-1 validation-text"
        :style="{ visibility: errorMessage ? 'visible' : 'hidden' }"
      >
        {{ errorMessage }}
      </div>
      <div v-if="!brandingAlias">
        <span class="body2">{{ $t("verification.cantFindCode") }}&nbsp;</span>
        <span class="body2" v-html="$t(`verification.supportLink`)" />
      </div>
      <v-btn
        class="mt-2"
        size="large"
        color="primary"
        rounded
        :loading="loading"
        :disabled="!valid"
        @click="onVerify(verificationCode)"
      >
        {{ $t("verification.verifyBtn") }}
      </v-btn>
    </v-form>
    <div
      class="caption text-center mt-12"
      data-size="invisible"
      data-badge="inline"
      v-html="$t('general.recaptcha')"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Patterns from "@/constants/patterns";
import { AccountErrors, VerificationMode } from "@/constants/account";
import { useMfaStore } from "@/_store/mfa.module";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  setup() {
    const router = useRouter();
    const mfaStore = useMfaStore();
    const accountStore = useAccountStore();
    const { loading, mfaFlowToken } = storeToRefs(mfaStore);
    const { verifyMFACode, resetMFAConfig, completeMFAReset, resendSMS } = mfaStore;
    const { processLoginData } = accountStore;
    const valid = ref(true);
    const verificationCode = ref("");
    const currentMode = ref(VerificationMode.AUTH_APP);
    const showTimer = ref(false);
    const countdown = ref(59);
    const tooManyOtpResetRequests = ref(false);
    const errorMessage = ref();
    const brandingAlias = computed(() => accountStore.account.brandingAlias);

    onBeforeMount(() => {
      if (!mfaFlowToken.value) {
        router.push({ name: RouteName.LOGIN });
      }
    });

    onMounted(() => {
      setTimeout(() => {
        const otpInput = document.querySelector<HTMLInputElement>(".v-otp-input__field");
        otpInput?.focus();
      });
    });

    const changeMode = () => {
      currentMode.value =
        currentMode.value === VerificationMode.SMS
          ? VerificationMode.AUTH_APP
          : VerificationMode.SMS;
      const otpInput = document.querySelector<HTMLInputElement>(".otp-input");
      otpInput?.focus();
    };

    const onReset = async () => {
      try {
        await resetMFAConfig();
        changeMode();
      } catch (err) {
        console.error(err);
      }
    };

    const onVerify = async (code: string) => {
      onOTPInput();

      if (!valid.value) return;

      if (currentMode.value === VerificationMode.AUTH_APP) {
        try {
          const res = await verifyMFACode(code);
          await processLoginData(res?.data);
        } catch (err: any) {
          if (err.response?.data.errors?.includes(AccountErrors.INVALID_VERIFICATION_CODE)) {
            valid.value = false;
            errorMessage.value = "Invalid verification code"; // replace with translation
          }
        }
      } else {
        try {
          const res = await completeMFAReset(code);
          await processLoginData(res?.data);
        } catch (err: any) {
          if (err.response?.data.errors?.includes(AccountErrors.INVALID_VERIFICATION_CODE)) {
            valid.value = false;
            errorMessage.value = "Invalid verification code"; // replace with translation
          }
        }
      }
    };

    const onOTPInput = (e?: KeyboardEvent) => {
      const key = e?.key;
      if (["-", "+", "e", "."].includes(key as string)) e?.preventDefault();

      if (!verificationCode.value) {
        valid.value = false;
        errorMessage.value = "Code is required"; // replace with translation
      } else if (!Patterns.DIGITS_RANGE(6, 6).test(verificationCode.value)) {
        valid.value = false;
        errorMessage.value = "Please enter 6 digits"; // replace with translation
      } else {
        valid.value = true;
        errorMessage.value = null;
      }
    };

    const onResendSMS = async () => {
      try {
        await resendSMS();
        startCountdown();
      } catch (err: any) {
        if (err.response.data.errors.includes(AccountErrors.TOO_MANY_OTP_REQUESTS)) {
          tooManyOtpResetRequests.value = true;
        }
      }
    };

    const startCountdown = () => {
      showTimer.value = true;
      const interval = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          showTimer.value = false;
          countdown.value = 59;
          clearInterval(interval);
        }
      }, 1000);
    };

    return {
      valid,
      verificationCode,
      currentMode,
      showTimer,
      countdown,
      tooManyOtpResetRequests,
      errorMessage,
      loading,
      brandingAlias,
      changeMode,
      onReset,
      onVerify,
      onOTPInput,
      onResendSMS,
      startCountdown,
    };
  },
});
</script>

<style lang="scss" scoped>
.verification-page-wrapper {
  max-width: 372px;
  margin-top: 88px;

  .validation-text {
    min-height: 21px;
  }
}
</style>
