<template>
  <div class="subtitle1 mb-3 mt-5 d-flex align-center text-primary">
    <span>{{ $t("connectors.psa.title") }}</span>
  </div>
  <v-progress-linear
    :style="{ visibility: loading ? 'visible' : 'hidden' }"
    indeterminate
    height="2px"
  />
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
  >
    <table-wrapper v-if="connectors.length > 0" class="access-restrictions-table__wrapper">
      <v-table class="white">
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("connectors.psa.connector") }}
              </th>
              <th class="text-left">
                {{ $t("connectors.psa.workspaceMapping") }}
              </th>
              <th class="text-left">
                {{ $t("connectors.psa.serviceMapping") }}
              </th>
              <th class="text-left" v-if="!hideTicketing">
                {{ $t("connectors.psa.ticketing") }}
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(connector, i) in connectors">
              <tr
                class="service-row"
                v-bind:key="i"
                v-if="connector.status !== PsaConnectionStatus.CONFIG_NOT_FOUND"
              >
                <td>
                  <div class="d-flex align-center">
                    <v-img
                      class="ml-1 gradient-logo"
                      :src="`/images/logos/${connector.providerName}.svg`"
                      contain
                    />
                    <div class="ml-4">
                      <div class="subtitle1">
                        {{ $t(`connectors.psa.provider.${connector.providerName}`) }}
                      </div>
                      <div :class="`body2 ${camelCase(connector.status)}`">
                        {{ $t(`connectors.psa.status.${camelCase(connector.status)}`) }}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <template v-if="connector.status === PsaConnectionStatus.CONNECTED">
                    <template v-if="connector.providerName === psaProviders.GRADIENT">
                      {{ $t("general.notAvailable") }}
                    </template>
                    <template v-else>
                      <router-link
                        :to="`${connector.providerName}/workspace-mapping`"
                        class="coro-link"
                      >
                        {{ getCompaniesMappingLabel(connector) }}
                      </router-link>
                      <span class="coro-link"></span>
                    </template>
                  </template>
                </td>
                <td>
                  <template v-if="connector.status === PsaConnectionStatus.CONNECTED">
                    <template v-if="connector.providerName === psaProviders.GRADIENT">
                      {{ $t("general.notAvailable") }}
                    </template>
                    <template v-else>
                      <router-link
                        :to="`${connector.providerName}/service-mapping`"
                        class="coro-link"
                      >
                        {{ getProductsMappingLabel(connector) }}
                      </router-link>
                    </template>
                  </template>
                </td>
                <td v-if="!hideTicketing">
                  <template v-if="connector.providerName === psaProviders.GRADIENT">
                    {{ $t("general.notAvailable") }}
                  </template>
                  <template
                    v-else-if="connector.status === PsaConnectionStatus.CONNECTED"
                  ></template>
                </td>
                <td class="text-right" @click="$event.stopPropagation()">
                  <v-menu bottom left>
                    <template v-slot:activator="{ props }">
                      <v-icon icon="$dots" v-bind="props"> </v-icon>
                    </template>
                    <v-list>
                      <template v-for="(actionItem, index) in getActions(connector)">
                        <template v-if="actionItem.show(connector)">
                          <v-list-item
                            :key="index"
                            :value="index"
                            @click="actionItem.callback(connector)"
                          >
                            <v-list-item-title>
                              {{ $t(`connectors.psa.actions.${actionItem.action}`) }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </template>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-table>
    </table-wrapper>
    <template v-else>
      <empty-state class="margin-auto mt-12 w-65">
        <template #icon>
          <v-icon icon="$connectors" :size="80" />
        </template>
        <template #description>
          <div class="headline5 mb-3 mt-3">
            {{ $t("connectors.psa.noItems.description") }}
          </div>
        </template>
        <template #subtitle>
          {{ $t("connectors.psa.noItems.subDescription") }}
        </template>
        <template #button>
          <v-btn
            rounded
            color="primary"
            density="default"
            size="large"
            elevation="0"
            @click="addConnector()"
          >
            {{ $t("connectors.psa.add") }}
          </v-btn>
        </template>
      </empty-state>
    </template>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { usePsaStore } from "@/_store/connectors/psa.module";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import TableWrapper from "@/components/TableWrapper.vue";
import {
  ConnectorActions,
  PsaConnectorActions,
  PsaModalHeights,
  PsaProviders,
} from "@/constants/connectors";
import { useDialogsStore } from "@/_store/dialogs.module";
import { camelCase } from "lodash";
import EmptyState from "@/components/EmptyState.vue";
import { useI18n } from "vue-i18n";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import AddPsaConnectorModal from "@/components/modals/psa-modals/AddPsaConnectorModal.vue";
import type { ConnectwiseConfig, PsaConnectorAdapted } from "@/_store/connectors/adapters";
import { PsaConnectionStatus } from "@/_store/connectors/adapters";
import { ModalWidth } from "@/constants/modals";
import { storeToRefs } from "pinia";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

export default defineComponent({
  components: { TableWrapper, EmptyState },
  setup() {
    const psaStore = usePsaStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CONNECTORS
      );
    });
    const { getConnectors } = psaStore;

    const { showSkeletonLoader, connectors, loading } = storeToRefs(psaStore);

    const deleteItem = (item: PsaConnectorAdapted) => {
      let callback;

      switch (item.providerName) {
        case PsaProviders.CONNECTWISE: {
          callback = psaStore.deleteConnectwise;
          break;
        }
        case PsaProviders.AUTOTASK: {
          callback = psaStore.deleteAutotask;
          break;
        }
        default: {
          callback = psaStore.deleteGradient;
        }
      }

      const dialogConfig = confirmationDialogsConfigConstructor({
        item,
        action: camelCase(ConnectorActions.REMOVE_PSA),
        callback,
        disable: actionNotAllowed.value,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const getCompaniesMappingLabel = (payload: PsaConnectorAdapted) => {
      const item = payload as ConnectwiseConfig & { providerName: string };
      if (item.status === PsaConnectionStatus.CONNECTED) {
        if (!item.mappedWorkspaces) {
          return i18n.t("connectors.psa.setupRequired");
        } else {
          return i18n.t("connectors.psa.nOfm", {
            first: item.mappedWorkspaces,
            second: item.totalWorkspaces,
          });
        }
      } else {
        return i18n.t("general.disconnected");
      }
    };

    const getProductsMappingLabel = (payload: PsaConnectorAdapted) => {
      const item = payload as ConnectwiseConfig & { providerName: string };
      if (item.status === PsaConnectionStatus.CONNECTED) {
        if (!item.mappedWorkspaces) {
          return i18n.t("connectors.psa.setupRequired");
        } else {
          return i18n.t("connectors.psa.nOfm", {
            first: item.mappedProducts,
            second: item.totalProducts,
          });
        }
      } else {
        return i18n.t("general.disconnected");
      }
    };

    const openPsaConfigurationDialog = (item?: PsaConnectorAdapted) => {
      const dialogConfig = componentDialogsConfigConstructor({
        item,
        action: ConnectorActions.ADD_PSA,
        component: AddPsaConnectorModal,
        width: ModalWidth.LARGE,
        callback: () => {},
        disable: actionNotAllowed.value,
      });

      // Specify height for scroll to work corrently, only needed if
      const heightParam = item ? { height: PsaModalHeights[item.providerName] } : {};

      dialogsStore.openDialog({
        ...dialogConfig,
        header: {
          title: i18n.t(
            item
              ? `modals.addConnectwiseConnector.title.edit.${item.providerName}`
              : "connectors.psa.addPsaConnector.title"
          ),
          close: true,
        },
        footer: null,
        scrollable: true,
        content: {
          ...dialogConfig.content,
          ...heightParam,
        },
      });
    };

    const getActions = (item: PsaConnectorAdapted) => {
      return [
        {
          action:
            item.status === PsaConnectionStatus.CONNECTED
              ? PsaConnectorActions.EDIT
              : PsaConnectorActions.CONNECT,
          show: () => true,
          callback: openPsaConfigurationDialog,
        },
        {
          action: PsaConnectorActions.DISABLE,
          show: (item: PsaConnectorAdapted) => PsaConnectionStatus.CONNECTED === item.status,
          callback: deleteItem,
        },
      ];
    };

    onMounted(() => {
      getConnectors();
    });

    return {
      showSkeletonLoader,
      connectors,
      addConnector: openPsaConfigurationDialog,
      getActions,
      getCompaniesMappingLabel,
      getProductsMappingLabel,
      psaProviders: PsaProviders,
      PsaConnectionStatus,
      connectorActions: ConnectorActions,
      loading,
      coronetSkeletonLoaderTypes,
      camelCase,
      hideTicketing: true, // TODO remove when ticketing is implemented
    };
  },
});
</script>

<style lang="scss" scoped>
.gradient-logo {
  max-width: 30px;
  max-height: 30px;
}

.service-row {
  height: 80px;
}

.configSuspended,
.disconnected {
  color: rgb(var(--v-theme-red-darkest));
}

.connected {
  color: rgb(var(--v-theme-green-dark));
}
</style>
