<template>
  <div>
    <div class="coro-terminal pa-2" @mouseup="onClick">
      <div v-if="loading" class="coro-terminal-ellipsis-loader">{{ loadingText }}</div>
      <template v-else>
        <div v-if="welcomeMessage" class="text-pre-wrap">{{ welcomeMessage }}</div>
        <div class="coro-terminal-content">
          <div v-for="(command, i) of commands" :key="command.text + i.toString()">
            <span v-if="!isInteractiveMode" class="coro-terminal-prompt mr-1">{{ prompt }}</span>
            <span class="coro-terminal-command">{{ command.text }}</span>
            <div class="coro-terminal-response" aria-live="polite">{{ command.response }}</div>
          </div>
        </div>
        <div v-if="!commandInProgress" class="coro-terminal-prompt-container">
          <span v-if="!isInteractiveMode" class="coro-terminal-prompt mr-1">{{ prompt }}</span>
          <input
            ref="input"
            v-model="commandText"
            type="text"
            :disabled="loading"
            class="coro-terminal-input"
            autocomplete="off"
            @keydown="onKeydown"
            @keydown.up.prevent="onArrowUp"
            @keydown.down="onArrowDown"
            @keyup.ctrl.c.stop="handleCtrlC"
          />
        </div>
        <Transition name="fade">
          <div v-if="commandInProgress" class="coro-terminal-ellipsis-loader">
            {{ commandInProgressText }}
          </div>
        </Transition>
      </template>
    </div>
    <div class="d-flex mt-4">
      <v-file-input
        ref="fileInput"
        v-model="uploadedScript"
        class="mr-4"
        :rules="scriptRules"
        accept=".ps1"
        variant="outlined"
        density="compact"
        prepend-icon=""
        hide-details="auto"
        :disabled="commandInProgress || loading || isInteractiveMode"
        :hint="$t('modals.openRemoteShellSession.uploadScriptHint')"
        persistent-hint
        :label="$t('modals.openRemoteShellSession.uploadScriptLabel')"
      />
      <v-btn
        color="primary"
        elevation="2"
        :disabled="!uploadedScript || commandInProgress || loading || isInteractiveMode"
        @click="runScript()"
      >
        {{ $t("modals.openRemoteShellSession.run") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useTerminalStore } from "@/_store/terminal.module";
import { TerminalCommand } from "@/constants/terminal";
import { i18n } from "@/plugins/i18n";
import _set from "lodash/set";
export default {
  props: {
    welcomeMessage: {
      type: String,
      default: null,
    },
    prompt: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    commandInProgress: {
      type: Boolean,
      default: false,
    },
    isInteractiveMode: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default() {
        return i18n.global.t("terminal.defaultLoadingText");
      },
    },
    commandInProgressText: {
      type: String,
      default() {
        return i18n.global.t("terminal.defaultCommandInProgressText");
      },
    },
  },
  /**
   * @returns {{currentCommandIndex: number, commandText: string, commands: {text: string; response: string}[]}}
   */
  data() {
    return {
      commandText: "",
      commands: [],
      currentCommandIndex: 0,
      uploadedScript: null,
      scriptRules: [
        (file) => {
          if (file && !file?.[0]?.name.endsWith(".ps1")) {
            return this.$t("validations.script");
          }
          return true;
        },
      ],
    };
  },
  watch: {
    loading(currentValue, previousValue) {
      // focus terminal after it finished loading
      if (!currentValue && previousValue) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    commandInProgress(currentValue, previousValue) {
      // focus terminal after command has given response out
      if (!currentValue && previousValue) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
  },
  mounted() {
    this.addEventHandler({ type: "response", handler: this.responseListener });
    if (!this.loading) {
      this.$refs.input.focus();
    }
  },
  updated() {
    this.$el.querySelector(".coro-terminal").scrollTop =
      this.$el.querySelector(".coro-terminal").scrollHeight;
  },
  beforeUnmount() {
    this.removeEventHandler({ type: "response", handler: this.responseListener });
  },
  methods: {
    ...mapActions(useTerminalStore, {
      addEventHandler: "addEventHandler",
      removeEventHandler: "removeEventHandler",
      emitEvent: "emitEvent",
    }),
    onClick() {
      const hasSelection = document.getSelection().toString().length;
      if (!hasSelection) {
        this.$refs.input?.focus();
      }
    },
    onKeydown(event) {
      if (event.code === "Enter") {
        this.commands.push({ text: this.commandText });
        this.emitEvent({ type: "command", payload: this.commandText });
        this.commandText = "";
      }
    },
    responseListener(response) {
      const lastCommandResponse = (this.commands[this.commands.length - 1].response ?? "").concat(
        response
      );
      _set(this.commands[this.commands.length - 1], "response", lastCommandResponse);
      this.currentCommandIndex = this.commands.length - 1;
    },
    onArrowUp() {
      if (this.currentCommandIndex > 0) {
        this.currentCommandIndex--;
        this.commandText = this.commands[this.currentCommandIndex]?.text ?? "";
      }
    },
    onArrowDown() {
      if (this.currentCommandIndex < this.commands.length - 1) {
        this.currentCommandIndex++;
        this.commandText = this.commands[this.currentCommandIndex]?.text ?? "";
      }
    },
    handleCtrlC() {
      this.commands.push({ text: TerminalCommand.CONTROL_C });
      this.emitEvent({ type: "command", payload: TerminalCommand.CONTROL_C });
      this.commandText = "";
    },
    runScript() {
      this.commands.push({ text: this.uploadedScript?.[0]?.name });
      this.$emit("run-script", this.uploadedScript[0]);
      this.commandText = "";
      this.uploadedScript = null;
    },
  },
};
</script>

<style lang="scss" scoped>
$terminal-background: #21252b;
$terminal-font-color: #abb2bf;

.coro-terminal {
  height: 36rem;
  overflow: auto;
  background-color: $terminal-background;
  color: $terminal-font-color;
  border-radius: 4px;
  font-family: "Source Code Pro", monospace;
  font-size: 15px;

  &-response {
    white-space: pre-wrap;
  }

  &-prompt-container {
    display: flex;
    align-items: center;
  }

  &-input {
    flex: 1 1 auto;
    border: 0 none;
    background-color: transparent;
    color: inherit;
    padding: 0;
    outline: 0 none;
    caret-color: $terminal-font-color;

    &::-ms-clear {
      display: none;
    }
  }

  &-ellipsis-loader:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: three-dot-ellipsis steps(4, end) 900ms infinite;
    animation: three-dot-ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the three-dot-ellipsis character */
    width: 0;
  }

  .fade-enter-active {
    transition: opacity 1s;
    transition-delay: 1s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @keyframes three-dot-ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes three-dot-ellipsis {
    to {
      width: 1.25em;
    }
  }
}
</style>
