<template>
  <div
    v-if="viewMenu"
    class="coro-context-menu"
    :style="{ width: !isOperatorMenu ? '240px' : '120px', top, left }"
  >
    <div class="coro-context-menu__content" ref="content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { SearchSegmentCreationStep } from "@/components/smart-search/SmartSearch.vue";

export interface MenuPosition {
  x: number;
  y: number;
}

export default defineComponent({
  computed: {
    SearchSegmentCreationStep() {
      return SearchSegmentCreationStep;
    },
  },
  props: {
    showMenu: {
      type: Boolean,
      required: true,
    },
    activatorId: {
      type: String,
      required: true,
    },
    marginBottom: {
      type: Number,
      default: 35,
    },
    currentStep: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const viewMenu = ref(false);
    const top = ref("0px");
    const left = ref("0px");
    const content = ref<HTMLElement>();
    watch(
      () => props.showMenu,
      (newVal) => {
        viewMenu.value = newVal;
        if (newVal) {
          setMenuPosition(getMenuPosition());
        }
      }
    );

    const setMenuPosition = ({ x, y }: MenuPosition) => {
      top.value = y + "px";
      left.value = x + "px";
    };

    function getMenuPosition() {
      const newSegmentRefPosition = document
        .getElementById(props.activatorId)
        ?.getBoundingClientRect();
      const x = newSegmentRefPosition?.x || 0;
      const y = (newSegmentRefPosition?.y || 0) + props.marginBottom;
      return { x, y };
    }

    const isOperatorMenu = computed(() => {
      return props.currentStep === SearchSegmentCreationStep.CREATE_OPERATOR;
    });

    return {
      viewMenu,
      content,
      top,
      left,
      isOperatorMenu,
    };
  },
});
</script>

<style scoped lang="scss">
.coro-context-menu {
  position: absolute;
  z-index: 999999;
  &__content {
    background: #ffffff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
}
</style>
