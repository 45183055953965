<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <div class="subtitle1">{{ $t(`modals.${config.action}.selectOs`) }}</div>
    <v-radio-group
      v-model="localValue.item.osType"
      hide-details
      class="mt-4"
      :disabled="config.action === SensitiveDataScansActions.EDIT"
    >
      <v-radio
        v-for="osType in operationSystems"
        :key="osType"
        :value="osType"
        class="mb-4"
        :ripple="false"
        :label="$t(`general.osVersion.${camelCase(osType)}`)"
      ></v-radio>
    </v-radio-group>
    <div class="d-flex mb-6">
      <div class="d-flex flex-column">
        <div class="subtitle1 mb-2">
          {{ $t(`modals.${config.action}.every`) }}
        </div>
        <v-select
          v-model="localValue.item.daysCount"
          :items="days"
          density="comfortable"
          class="mr-6 period-select"
          hide-details
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="$t('general.everyXDays', item.value, { n: item.value })"
            />
          </template>
          <template v-slot:selection="{ item }">
            {{ $t("general.everyXDays", item.value, { n: item.value }) }}
          </template>
        </v-select>
      </div>
      <div class="d-flex flex-column">
        <div class="subtitle1 mb-2">
          {{ $t(`modals.${config.action}.preferredTime`) }}
        </div>
        <v-select
          v-model="localValue.item.preferredHour"
          :items="timeValues"
          density="comfortable"
          class="period-select"
          hide-details
          item-title="displayValue"
          item-value="value"
        >
        </v-select>
      </div>
    </div>
    <div class="subtitle1">{{ $t(`modals.${config.action}.applyTo`) }}</div>
    <v-radio-group v-model="localValue.item.appliedDriveType" hide-details class="mt-4">
      <v-radio
        v-for="driveType in driveTypes"
        :key="driveType"
        :value="driveType"
        class="mb-4"
        :ripple="false"
        :label="$t(`modals.${config.action}.driveTypes.${driveType}`)"
      ></v-radio>
    </v-radio-group>
    <v-autocomplete
      v-model="localValue.item.labels"
      :items="labels"
      :rules="[atLeastOneIsRequired()]"
      @update:search="getLabels"
      variant="outlined"
      class="mt-3"
      :label="$t(`modals.${config.action}.labelsLabel`)"
      :placeholder="$t(`modals.${config.action}.labelsPlaceholder`)"
      item-title="name"
      return-object
      chips
      multiple
    >
      <template v-slot:chip="{ props, item }">
        <coro-label v-bind="props" class="mr-1" :background-color="item.raw.color">
          <div class="d-flex align-center">
            <span class="ml-1 mr-2">{{ item.raw.name }}</span>
            <v-icon small rounded icon="$x" @click="removeLabel(item.raw)"> </v-icon>
          </div>
        </coro-label>
      </template>
      <template v-slot:item="{ props, item }">
        <v-list-item v-bind="props" :title="item?.raw?.name">
          <template v-slot:prepend>
            <coro-label :background-color="item.raw.color" variant="square" class="mr-2">
            </coro-label>
          </template>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import { OsType } from "@/constants/devices";
import moment from "moment/moment";
import { SensitiveDataScanDriveTypes } from "@/constants/endpoint-data-governance";
import CoroLabel from "@/components/CoroLabel.vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { SensitiveDataScanItem } from "@/_store/endpoint-data-governance/sensitive-data-scans.module";
import camelCase from "lodash/camelCase";
import { SensitiveDataScansActions } from "@/constants/endpoint-data-governance";
import { atLeastOneIsRequired } from "@/_helpers/validators";
import { type DeviceLabel, useDevicesSettingsStore } from "@/_store/devices-settings.module";

export default defineComponent({
  components: { CoroLabel },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const devicesSettingsStore = useDevicesSettingsStore();
    const operationSystems = [OsType.WINDOWS, OsType.MAC_OS];
    const driveTypes = [SensitiveDataScanDriveTypes.ALL, SensitiveDataScanDriveTypes.UNENCRYPTED];
    const localValue = ref<DialogDataConfig<SensitiveDataScanItem>>({
      item: cloneDeep(props.config.item),
      action: props.config.action,
    });
    const valid = ref(true);
    const form = ref();
    let labels = ref<Array<DeviceLabel>>([]);
    const required = reactive([(v: string) => (!v ? i18n.t("validations.required") : true)]);
    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };
    const timeValues = computed(() => {
      return Array.from(
        {
          length: 24,
        },
        (_, hour) => {
          return {
            value: hour,
            displayValue: moment({
              hour,
              minutes: 0,
            }).format("hh:mm A"),
          };
        }
      );
    });

    const getLabels = async (searchParam: string = "") => {
      const { data } = await devicesSettingsStore.searchLabels(searchParam);
      labels.value = data;
    };

    const days = Array.from({ length: 21 }, (_, i) => i + 1);

    const removeLabel = (label: DeviceLabel) => {
      const arr = localValue.value.item.labels;
      const index = arr.findIndex((v: DeviceLabel) => v.id === label.id);
      if (index > -1) {
        arr.splice(index, 1);
      }
      labels.value = arr;
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );

    watch(
      valid,
      (newVal) => {
        if (newVal === null) return;
        emit("update:valid", newVal);
      },
      { deep: true, immediate: false }
    );

    onMounted(async () => {
      await getLabels();
    });

    return {
      camelCase,
      localValue,
      getLabels,
      timeValues,
      driveTypes,
      labels,
      days,
      valid,
      form,
      required,
      validate,
      operationSystems,
      atLeastOneIsRequired,
      removeLabel,
      SensitiveDataScansActions,
    };
  },
});
</script>

<style lang="scss" scoped>
.period-select {
  width: 172px;
}
</style>
