<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      class="skeleton-loader--security-settings"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS"
    >
      <v-card class="exclusions-tab" width="100%">
        <v-card-text class="px-8 py-10">
          <div class="body1 text--semibold mb-3">
            {{ $t("userDataGovernanceSettings.exclusionsTab.title") }}
          </div>
          <v-checkbox
            v-model="localValue.emailSubjectKeywordsEnabled"
            :disabled="actionNotAllowed"
            data-testid="custom-data-monitor-passwords-checkbox"
            :ripple="false"
            :label="$t('userDataGovernanceSettings.exclusionsTab.excludeEmailsWithKeywords')"
            class="mt-3 mb-1"
          />
          <v-combobox
            :disabled="actionNotAllowed || !localValue.emailSubjectKeywordsEnabled"
            v-model="localValue.emailSubjectKeywords"
            data-testid="custom-data-monitor-keywords-combobox"
            variant="outlined"
            multiple
            class="ml-7"
            :rules="sensitiveKeywordsRule"
            :placeholder="
              $t('userDataGovernanceSettings.exclusionsTab.specificKeywordsPlaceholder')
            "
            @update:search="onKeywordInput()"
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :closable="true"
                variant="flat"
                size="default"
                close-icon="$closeCircle"
                :text="item.title"
                color="indigo-faint"
              >
                <div class="d-flex align-center">
                  <span class="ml-1 mr-2">{{ item.title }}</span>
                </div>
              </v-chip>
            </template>
          </v-combobox>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import type { UserDataGovernanceExclusions } from "@/_store/user-data-governance/user-data-governance.module";
import { useUserDataGovernanceModule } from "@/_store/user-data-governance/user-data-governance.module";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useI18n } from "vue-i18n";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { SubscriptionModule } from "@/constants/workplaces";
import { RolePermissionsScope } from "@/_store/roles.module";
import { camelCase, debounce, uniq } from "lodash";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  setup() {
    const userDataGovernanceStore = useUserDataGovernanceModule();
    const i18n = useI18n();
    const { getExclusions, updateExclusions, showSkeletonLoader } = userDataGovernanceStore;
    const { exclusions } = storeToRefs(userDataGovernanceStore);
    const localValue = ref<UserDataGovernanceExclusions>({
      emailSubjectKeywordsEnabled: false,
      emailSubjectKeywords: [],
    });

    onMounted(async () => {
      await getExclusions();
      localValue.value = cloneDeep(exclusions.value);
    });

    const sensitiveKeywordsRule = [
      (keywords: string[]) => {
        if (keywords?.length > 200) {
          return i18n.t("validations.maximumAllowedKeywords");
        }
        return true;
      },
    ];

    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.USER_DATA_GOVERNANCE
    );

    const onKeywordInput = () => {
      const value = localValue.value.emailSubjectKeywords as string[];
      localValue.value.emailSubjectKeywords = uniq(value.slice().map((keyword) => keyword.trim()));
    };

    watch(
      localValue,
      debounce((newVal) => {
        if (!isEqual(newVal, exclusions.value)) {
          updateExclusions(newVal);
        }
      }, 500),
      { deep: true }
    );

    return {
      exclusions,
      showSkeletonLoader,
      coronetSkeletonLoaderTypes,
      localValue,
      sensitiveKeywordsRule,
      onKeywordInput,
      actionNotAllowed,
      camelCase,
    };
  },
});
</script>

<style scoped lang="scss">
.chip-item {
  height: 36px;
  border-radius: 18px;
}

.exclusions-tab {
  height: calc(100vh - 270px);
}

:deep(*) {
  .v-field .v-chip {
    --v-chip-height: 32px;
  }
}
</style>
