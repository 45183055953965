<template>
  <div class="mb-6 pb-12">
    <site-to-site-tunnels-section></site-to-site-tunnels-section>
    <!--    TODO: uncomment when requested-->
    <!--    <vo-firewall></vo-firewall>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SiteToSiteTunnelsSection from "@/components/network/SiteToSiteTunnelsSection.vue";

export default defineComponent({
  components: {
    SiteToSiteTunnelsSection,
  },
});
</script>

<style scoped lang="scss"></style>
