<template>
  <div class="summary-item">
    <div class="d-flex align-center">
      <div class="mr-2">
        <v-icon :icon="icon" :size="50" />
      </div>
      <div>
        <div class="subtitle1">
          {{ $t(`modals.createWorkspace.summary.${item}.title`) }}
        </div>
        <div class="body2 mb-1" v-if="subtitle">
          {{ subtitle }}
        </div>

        <template v-if="type === summaryType.BUNDLE">
          <div v-for="value in modulesInBundle" :key="value" class="caption">
            {{ $t(`subscriptions.modules.${value}`) }}
          </div>
          <div v-for="value in getAddonsInBundle()" :key="value" class="caption">
            {{ $t(`subscriptions.addons.${value}`) }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { SubscriptionAddon, SubscriptionBundle, SubscriptionModule } from "@/constants/workplaces";
import { bundlesContent } from "@/constants/workplaces";
import { useI18n } from "vue-i18n";
import type { SubscriptionModuleInfo } from "@/_store/subscription.module";
import { hasSocAddonInModule } from "@/_helpers/utils";

export enum SubscriptionSummaryType {
  ADDON = "addon",
  MODULE = "module",
  BUNDLE = "bundle",
}

export default defineComponent({
  props: {
    item: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<SubscriptionSummaryType>,
      required: true,
    },
    module: {
      type: Object as PropType<SubscriptionModuleInfo>,
      default: () => {},
    },
  },
  setup(props) {
    const i18n = useI18n();
    const iconsMap: Partial<
      Record<SubscriptionBundle | SubscriptionAddon | SubscriptionModule, string>
    > = {
      [SubscriptionBundle.CORO_ESSENTIALS]: "icon-all-good",
      [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: "icon-all-good",
      [SubscriptionBundle.SASE]: "icon-privatize",
      [SubscriptionBundle.MANAGED_SASE]: "icon-privatize",
      [SubscriptionBundle.EMAIL_PROTECTION]: "icon-emails",
      [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: "icon-emails",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "icon-devices",
      [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: "icon-devices",
      [SubscriptionModule.ENDPOINT_SECURITY]: "icon-devices",
      [SubscriptionModule.NETWORK]: "icon-privatize",
      [SubscriptionModule.EMAIL_SECURITY]: "icon-emails",
      [SubscriptionModule.USER_DATA_GOVERNANCE]: "icon-data-copy",
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "icon-endpoint-data-governance",
      [SubscriptionModule.MDM]: "icon-mdm",
      [SubscriptionModule.CLOUD_SECURITY]: "icon-cloud-apps",
      [SubscriptionModule.EDR]: "icon-edr-hexagon",
      [SubscriptionAddon.WIFI_PHISHING]: "icon-wifi-phishing-l",
      [SubscriptionAddon.SWG]: "icon-access-restirctions",
      [SubscriptionAddon.SECURED_MESSAGES]: "icon-coro-mail",
      [SubscriptionAddon.ZTNA]: "icon-ztna-l",
      [SubscriptionAddon.INBOUND_GATEWAY]: "icon-inbound-gateway-l",
    };

    const subtitle = computed(() => {
      switch (props.type) {
        case SubscriptionSummaryType.BUNDLE:
          return i18n.t(`modals.createWorkspace.summary.${props.item}.subtitle`);
        case SubscriptionSummaryType.MODULE:
          return hasSocAddonInModule(props.module)
            ? i18n.t("modals.createWorkspace.managed")
            : i18n.t("modals.createWorkspace.unmanaged");
        default:
          return null;
      }
    });

    const modulesInBundle = computed(() => {
      return bundlesContent[props.item as SubscriptionBundle].modules;
    });

    const getAddonsInBundle = () => {
      const managedBundles = [
        SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
        SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
        SubscriptionBundle.MANAGED_SASE,
        SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
      ];

      const bundlesMap = {
        [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: SubscriptionBundle.ENDPOINT_PROTECTION,
        [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: SubscriptionBundle.EMAIL_PROTECTION,
        [SubscriptionBundle.MANAGED_SASE]: SubscriptionBundle.SASE,
        [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: SubscriptionBundle.CORO_ESSENTIALS,
      } as Record<SubscriptionBundle, SubscriptionBundle>;

      const isManagedBundle = managedBundles.includes(props.item as SubscriptionBundle);

      return isManagedBundle
        ? bundlesContent[bundlesMap[props.item as SubscriptionBundle]].addons
        : bundlesContent[props.item as SubscriptionBundle].addons;
    };

    const icon = computed(() => {
      return iconsMap[props.item as SubscriptionAddon | SubscriptionModule | SubscriptionBundle];
    });

    return {
      bundlesContent,
      getAddonsInBundle,
      modulesInBundle,
      icon,
      SubscriptionBundle,
      summaryType: SubscriptionSummaryType,
      subtitle,
    };
  },
});
</script>

<style scoped></style>
