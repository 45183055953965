export enum ConnectorActions {
  ADD_SIEM = "addSiemConnector",
  DELETE_SIEM = "deleteSiemConnector",
  UPDATE_SIEM = "updateSiemConnector",
  ADD_PSA = "addPsa",
  REMOVE_PSA = "removePsa",
  ADD_API_KEY = "addApiKey",
  REMOVE_API_KEY = "removeApiKey",
}
export enum PsaConnectorActions {
  CONNECT = "connect",
  EDIT = "edit",
  DISABLE = "disable",
}

export enum PsaProviders {
  GRADIENT = "gradient",
  CONNECTWISE = "connectwise",
  AUTOTASK = "autotask",
}

export enum SiemConnectorFormat {
  SPLUNK = "splunk",
  AZURE_SENTINEL = "azureSentinel",
  FLUENCY = "fluency",
  GENERIC = "generic",
}
export enum CoroProductNames {
  CLOUD_SECURITY = "CLOUD_SECURITY",
  ENDPOINT_SECURITY = "ENDPOINT_SECURITY",
  EMAIL_SECURITY = "EMAIL_SECURITY",
  USER_DATA_GOVERNANCE = "USER_DATA_GOVERNANCE",
  ENDPOINT_DATA_GOVERNANCE = "ENDPOINT_DATA_GOVERNANCE",
  EDR = "EDR",
  NETWORK = "NETWORK",
  MDM = "MDM",
  INBOUND_GATEWAY = "INBOUND_GATEWAY",
  SECURED_WEB_GATEWAY = "SECURED_WEB_GATEWAY",
  WIFI_PHISHING = "WIFI_PHISHING",
  SECURED_MESSAGES = "SECURED_MESSAGES",
  MDR = "MDR",
  SOC_CLOUD_SECURITY = "SOC_CLOUD_SECURITY",
  SOC_ENDPOINT_SECURITY = "SOC_ENDPOINT_SECURITY",
  SOC_EMAIL_SECURITY = "SOC_EMAIL_SECURITY",
  SOC_USER_DATA_GOVERNANCE = "SOC_USER_DATA_GOVERNANCE",
  SOC_ENDPOINT_DATA_GOVERNANCE = "SOC_ENDPOINT_DATA_GOVERNANCE",
  SOC_NETWORK = "SOC_NETWORK",
  ZERO_TRUST_NETWORK_ACCESS = "ZERO_TRUST_NETWORK_ACCESS",
  OUTBOUND_GATEWAY = "OUTBOUND_GATEWAY",
}

export const PsaModalHeights = {
  [PsaProviders.CONNECTWISE]: 505,
  [PsaProviders.AUTOTASK]: 400,
  [PsaProviders.GRADIENT]: 172,
};
