<template>
  <div data-testid="drives-list">
    <div class="mb-2">
      <div class="headline6">{{ $t("general.drives") }}</div>
    </div>
    <table-wrapper v-if="drives.length">
      <div class="coronet-table--loader">
        <v-progress-linear v-if="loading" indeterminate height="2px" />
        <v-data-table
          v-model:expanded="expanded"
          :headers="headers"
          :items="drives"
          :show-expand="true"
          item-value="name"
          class="drives-table"
        >
          <template #item="{ item, toggleExpand, isExpanded, internalItem }">
            <tr>
              <td>
                <v-icon
                  class="mr-2 cursor-pointer"
                  icon="$dropdown"
                  color="primary"
                  :class="{ rotated: isExpanded(internalItem) }"
                  @click="toggleExpand(internalItem)"
                />
              </td>
              <td class="name-block">
                <div class="d-flex">
                  <div>
                    <div class="subtitle1">{{ `${item.friendlyName}(${item.name})` }}</div>
                    <div class="body2 text-indigo-medium">
                      {{
                        item.isRemovable
                          ? $t(`devices.drives.types.removable`)
                          : $t(`devices.drives.types.nonRemovable`)
                      }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    <div :style="getEncryptionStatusColor(item.status)" class="body1">
                      {{ $t(`devices.drives.statuses.${item.status}`) }}
                    </div>
                  </div>
                  <div>
                    <div
                      v-if="item.encryptionInProgress && showEncryptButton(item)"
                      class="d-flex align-center"
                    >
                      <v-icon class="mr-1" icon="$sync"> </v-icon>
                      <span class="body2">{{ $t("devices.drives.encryptionInProgress") }}</span>
                    </div>
                    <v-btn
                      v-else-if="showEncryptButton(item)"
                      :disabled="actionNotAllowed"
                      class="encrypt-btn ml-2"
                      rounded
                      color="primary"
                      @click="encryptDrive(enrollmentCode, item)"
                    >
                      {{ $t(`devices.drives.encryptBtn.${osType?.toLowerCase()}`) }}
                    </v-btn>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template #expanded-row="{ item, columns }">
            <td :colspan="columns.length" class="pl-16 pb-4">
              <template v-if="showKey(item)">
                <div class="subtitle2 text-primary mt-4">
                  {{ $t("devices.drives.password") }}
                </div>
                <v-tooltip open-delay="300" location="top">
                  <template #activator="{ props }">
                    <span class="body2">{{ item.password }}</span>
                    <v-icon
                      role="button"
                      class="ml-1"
                      v-bind="props"
                      @click="onClipboardCopy(item.password!)"
                      icon="$copy"
                      size="16"
                    >
                    </v-icon>
                  </template>
                  {{ $t("general.copyToClipboard") }}
                </v-tooltip>
              </template>
              <template v-if="item.model">
                <div class="subtitle2 text-primary mt-4">{{ $t("devices.drives.model") }}</div>
                <div class="body2">{{ item.model }}</div>
              </template>
              <template v-if="item.serialKey">
                <div class="subtitle2 text-primary mt-4">
                  {{ $t("devices.drives.serialKey") }}
                </div>
                <v-tooltip open-delay="300" location="top">
                  <template #activator="{ props }">
                    <span class="body2">{{ item.serialKey }}</span>
                    <v-icon
                      role="button"
                      class="ml-1"
                      v-bind="props"
                      @click="onClipboardCopy(item.serialKey!)"
                      icon="$copy"
                      size="16"
                    >
                    </v-icon>
                  </template>
                  {{ $t("general.copyToClipboard") }}
                </v-tooltip>
              </template>
            </td>
          </template>
          <template #bottom></template>
        </v-data-table>
      </div>
    </table-wrapper>
    <div v-else class="no-data-container">
      <div class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="68" class="mb-2" icon="$hardDrive"> </v-icon>
        <span class="subtitle1">{{ $t("general.noData") }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DriveEncryptionStatus, OsType } from "@/constants/devices";
import camelCase from "lodash/camelCase";
import { copyToClipboard, isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import type { DeviceDetails, DeviceDrive } from "@/_store/devices.module";
import { useDevicesStore } from "@/_store/devices.module";
import { computed, defineComponent, type PropType, ref } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useSnackbarStore } from "@/_store";
import { useI18n } from "vue-i18n";
import { isSuitableDriveForEncryption } from "@/_helpers/device-actions";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    drives: {
      type: Array as PropType<DeviceDrive[]>,
      required: true,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enrollmentCode: {
      type: String,
      required: true,
    },
    osType: {
      type: String as PropType<OsType>,
      required: false,
    },
    deviceDetails: {
      type: Object as PropType<DeviceDetails>,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const i18n = useI18n();
    const devicesStore = useDevicesStore();
    const expanded = ref([]);
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "devicesView");
    });

    const headers = [
      { title: "", key: "data-table-expand", sortable: false },
      {
        title: i18n.t("general.name"),
        key: "name",
        sortable: false,
      },
      {
        title: i18n.t("general.status"),
        key: "status",
        sortable: false,
      },
      { title: "", key: "actions", sortable: false },
    ];

    const onClipboardCopy = (password: string) => {
      copyToClipboard(password);
      useSnackbarStore().addGenericSuccess(i18n.t("snackbar.messages.general.copiedToClipboard"));
    };

    /**
     * There can be 4 cases:
     * Unencrypted
     * Encrypted by Coro + recovery key
     * Encrypted + recovery key
     * Encrypted
     */
    const showKey = (drive: DeviceDrive): boolean => {
      return (
        [DriveEncryptionStatus.ENCRYPTED, DriveEncryptionStatus.ENCRYPTED_BY_CORO].includes(
          drive.status
        ) && !!drive.password?.length
      );
    };
    const getEncryptionStatusColor = (status: DriveEncryptionStatus) => {
      if ([DriveEncryptionStatus.NOT_ENCRYPTED, DriveEncryptionStatus.FAILED].includes(status)) {
        return "color: rgb(var(--v-theme-red-dark))";
      }
    };
    const showEncryptButton = (drive: DeviceDrive) =>
      isSuitableDriveForEncryption(drive, props.deviceDetails);

    const encryptDrive = (enrollmentCode: string, drive: DeviceDrive) => {
      devicesStore.encryptDrive({
        enrollmentCode,
        driveName: drive.name,
      });
    };

    return {
      actionNotAllowed,
      camelCase,
      encryptDrive,
      showEncryptButton,
      showKey,
      getEncryptionStatusColor,
      onClipboardCopy,
      headers,
      expanded,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .encryption-key {
    max-width: 340px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .v-table__wrapper {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 1px solid rgb(var(--v-theme-indigo-faint));
  }

  .drives-table {
    td {
      height: 80px !important;
    }
  }

  .encrypt-btn {
    padding: 0 15px !important;
  }

  .icon-hard-drive:before {
    color: rgb(var(--v-theme-indigo-light)) !important;
  }

  .name-block {
    padding-left: 0 !important;
  }
}

.icon-copy:before {
  color: rgb(var(--v-theme-indigo-medium)) !important;
}

.no-data-container {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
