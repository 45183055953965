<template>
  <div>
    <div class="body1">
      {{ $t("cloudSecurity.noItems.modalDescription") }}
    </div>
    <div class="services-chip-group margin-auto" :class="{ 'mb-0': config.item.name }">
      <v-chip-group v-model="config.item.name">
        <v-chip
          v-for="service in notConnectedServices"
          :key="service.name"
          variant="outlined"
          :value="service.name"
          :disabled="actionNotAllowed"
        >
          <div class="d-flex flex-column align-center w-100" :data-testid="`${service.name}-chip`">
            <coro-icon class="service-icon" :icon-name="service.name"></coro-icon>
            <div class="subtitle1">
              {{ $t(`services.${service.name}`) }}
            </div>
          </div>
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script lang="ts">
import CoroIcon from "@/components/CoroIcon.vue";
import { computed, defineComponent } from "vue";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

export default defineComponent({
  components: {
    CoroIcon,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { notConnectedServices } = useCloudAppsStore();

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CLOUD_APPS
      );
    });

    return { notConnectedServices, actionNotAllowed };
  },
});
</script>

<style lang="scss"></style>
