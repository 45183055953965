<template>
  <img v-if="isGradientIcon" :src="src" alt="" />
  <svg v-else class="coronet-icon">
    <use :xlink:href="src" :class="iconColorClass" class="coronet-icon-svg"></use>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PsaProviders, SiemConnectorFormat } from "@/constants/connectors";
import { Service } from "@/constants/cloud-apps";
import { kebabCase } from "lodash";

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: "default",
    },
  },
  computed: {
    iconColorClass() {
      switch (this.iconColor) {
        case "grey":
          return "icon--grey";
        case "orange":
          return "icon--orange";
        case "green":
          return "icon--green";
        default:
          return "icon--default";
      }
    },
    isGradientIcon() {
      return (
        [
          Service.OFFICE_365,
          SiemConnectorFormat.AZURE_SENTINEL,
          PsaProviders.AUTOTASK,
          PsaProviders.CONNECTWISE,
          PsaProviders.GRADIENT,
        ] as string[]
      ).includes(this.iconName);
    },
    src(): string {
      if (this.isGradientIcon) {
        return `/images/logos/${kebabCase(this.iconName)}.svg`;
      }
      return `/images/icons.svg#${this.iconName}`;
    },
  },
});
</script>

<style lang="scss">
.coronet-icon-svg {
  --logo-background: #e1f6ff;
}
// in this classes we are re-assigning color variables to be able to change colors
// dynamically in svg which is in shadow dom.
.icon--default {
  --slack-icon--red: #e01e5a;
  --slack-icon--cyan: #36c5f0;
  --slack-icon--green: #2eb67d;
  --slack-icon--yellow: #ecb22e;
  --google-icon--blue: #4285f4;
  --google-icon--green: #34a853;
  --google-icon--yellow: #fbbc05;
  --google-icon--red: #eb4335;
  --salesforce-icon: #00a1e0;
  --office-icon: #c75b12;
  --dropbox-icon: #345c9a;
  --box-icon: #0079b3;
  --coronet-icon--red: #e15c39;
  --coronet-icon--black: #32393f;
}

.icon--grey {
  --slack-icon--red: rgb(var(--v-theme-indigo-medium));
  --slack-icon--cyan: rgb(var(--v-theme-indigo-medium));
  --slack-icon--green: rgb(var(--v-theme-indigo-medium));
  --slack-icon--yellow: rgb(var(--v-theme-indigo-medium));
  --google-icon--blue: rgb(var(--v-theme-indigo-medium));
  --google-icon--green: rgb(var(--v-theme-indigo-medium));
  --google-icon--yellow: rgb(var(--v-theme-indigo-medium));
  --google-icon--red: rgb(var(--v-theme-indigo-medium));
  --salesforce-icon: rgb(var(--v-theme-indigo-medium));
  --office-icon: rgb(var(--v-theme-indigo-medium));
  --dropbox-icon: rgb(var(--v-theme-indigo-medium));
  --box-icon: rgb(var(--v-theme-indigo-medium));
  --coronet-icon--red: rgb(var(--v-theme-indigo-medium));
  --coronet-icon--black: rgb(var(--v-theme-indigo-medium));
}

.icon--green {
  --slack-icon--red: rgb(var(--v-theme-green-base));
  --slack-icon--cyan: rgb(var(--v-theme-green-base));
  --slack-icon--green: rgb(var(--v-theme-green-base));
  --slack-icon--yellow: rgb(var(--v-theme-green-base));
  --google-icon--blue: rgb(var(--v-theme-green-base));
  --google-icon--green: rgb(var(--v-theme-green-base));
  --google-icon--yellow: rgb(var(--v-theme-green-base));
  --google-icon--red: rgb(var(--v-theme-green-base));
  --salesforce-icon: rgb(var(--v-theme-green-base));
  --office-icon: rgb(var(--v-theme-green-base));
  --dropbox-icon: rgb(var(--v-theme-green-base));
  --box-icon: rgb(var(--v-theme-green-base));
  --coronet-icon--red: rgb(var(--v-theme-green-base));
  --coronet-icon--black: rgb(var(--v-theme-green-base));
}

.icon--orange {
  --slack-icon--red: rgb(var(--v-theme-orange-base));
  --slack-icon--cyan: rgb(var(--v-theme-orange-base));
  --slack-icon--green: rgb(var(--v-theme-orange-base));
  --slack-icon--yellow: rgb(var(--v-theme-orange-base));
  --google-icon--blue: rgb(var(--v-theme-orange-base));
  --google-icon--green: rgb(var(--v-theme-orange-base));
  --google-icon--yellow: rgb(var(--v-theme-orange-base));
  --google-icon--red: rgb(var(--v-theme-orange-base));
  --salesforce-icon: rgb(var(--v-theme-orange-base));
  --office-icon: rgb(var(--v-theme-orange-base));
  --dropbox-icon: rgb(var(--v-theme-orange-base));
  --box-icon: rgb(var(--v-theme-orange-base));
  --coronet-icon--red: rgb(var(--v-theme-orange-base));
  --coronet-icon--black: rgb(var(--v-theme-orange-base));
}
</style>
