<template>
  <v-card
    :class="{
      'border-md border-opacity-100 border-orange-light': message.highPriority,
    }"
    class="message__wrapper"
    :ripple="false"
    :link="false"
  >
    <v-card-title class="pa-4">
      <div class="d-flex justify-start align-start w-100">
        <v-chip variant="flat" size="default" label color="indigo-faint" density="comfortable">
          <span class="coro-label text-primary text-uppercase" data-testid="alert-widget-title">
            {{ messageTypeText }}
          </span>
        </v-chip>
      </div>
      <v-btn
        v-if="dismissible && !isWorkspaceFrozen()"
        icon
        :ripple="false"
        height="14"
        width="14"
        class="pt-2 message__close-btn"
        :loading="loading"
        data-testid="alert-widget-dismiss-btn"
        @click.stop="onMessageDismiss()"
      >
        <v-icon size="24" icon="$close"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="caption">
        {{ format(new Date(message.timestamp), "MMM dd yyyy, p") }}
      </div>
      <div
        class="body2"
        data-testid="alert-widget-text"
        v-html="
          $t(`messages.types.${message.type}`, {
            ...message.params,
            service: $t(`services.${message.params.service}`),
            startDate: message.params.start_timestamp
              ? format(new Date(message.params.start_timestamp), 'MMM dd, p')
              : '',
            endDate: message.params.end_timestamp
              ? format(new Date(message.params.end_timestamp), 'MMM dd, p')
              : '',
            link:
              message.params.service === Service.OFFICE_365
                ? 'https://compliance.microsoft.com/auditlogsearch'
                : 'https://support.google.com/a/answer/4579579?hl=en',
            linkDescription:
              message.params.service === Service.OFFICE_365
                ? $t('messages.auditLogs')
                : $t('messages.gSuiteAuditLogs'),
          })
        "
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref } from "vue";
import { Service } from "@/constants/cloud-apps";
import { format } from "date-fns";
import { isWorkspaceFrozen } from "@/_helpers/utils";
import { type AlertMessage, useMessagesStore } from "@/_store/messages.module";
import { MessageType } from "@/constants/messages";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<AlertMessage>,
      required: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loading = ref(false);
    const i18n = useI18n();
    const messagesStore = useMessagesStore();

    const messageTypeText = computed(() => {
      switch (props.message.type) {
        case MessageType.CONNECTOR_HAS_BEEN_DISCONNECTED:
          return i18n.t("messages.categories.connectors");
        default:
          return i18n.t("messages.categories.cloudApps");
      }
    });

    const onMessageDismiss = async () => {
      loading.value = true;
      await messagesStore.dismissMessage(props.message.id);
      loading.value = false;
    };

    return {
      Service,
      loading,
      messageTypeText,
      format,
      isWorkspaceFrozen,
      onMessageDismiss,
    };
  },
});
</script>

<style scoped lang="scss">
.message {
  &__close-btn {
    position: absolute;
    top: 12px;
    right: 0;
  }

  &__wrapper {
    &.v-card {
      transition: box-shadow 0.4s ease-in-out;

      &.v-card--link {
        cursor: default;

        &:before {
          background: inherit;
        }
      }
    }
  }

  &__category {
    pointer-events: none;
  }
}
</style>
