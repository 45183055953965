<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
    >
      <mapped-items-table
        :items="serviceMapping"
        :show-pagination="false"
        :total-items="serviceMapping.length"
        :title="$t(`connectors.psa.${provider}ServiceMapping.title`)"
        :secondary-items="productsSelectOptions"
        :secondary-items-loading="productsLoading"
        :first-column-title="$t('serviceMapping.coroModules')"
        :second-column-title="$t(`serviceMapping.${provider}Services`)"
        :search-placeholder="$t('serviceMapping.searchForModules')"
        :local-filters="localFilters"
        :loading="loading"
        @mapping-update="updateMappingValue($event)"
        @secondary-item-search="updateProductSearch($event)"
        @clear-filters="clearFilters()"
      >
      </mapped-items-table>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import { debounce } from "lodash";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import MappedItemsTable from "@/components/tables/MappedItemsTable.vue";
import type { PsaMappingUpdate } from "@/components/tables/MappedItemsTable.vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import { usePsaConfigurationStore } from "@/_store/connectors/psa-configuration.module";
import { defaultPsaConnectorMappingFilter } from "@/_store/connectors/psa.module";
import type { PsaProviders } from "@/constants/connectors";

export default defineComponent({
  components: {
    MappedItemsTable,
  },
  props: {
    provider: {
      type: String as PropType<PsaProviders>,
      required: true,
    },
  },
  setup(props) {
    const psaConnectorStore = usePsaConfigurationStore(props.provider);

    const localValue = ref({
      models: [] as (string | null)[],
    });
    const i18n = useI18n();
    const { loading, servicesMapping, companiesLoading, showSkeletonLoader } =
      storeToRefs(psaConnectorStore);

    const currentlyLoadingIndex = ref(null);
    const productsSelectOptions = computed(() => psaConnectorStore.productsMappingOptions);
    const localFilters = ref({
      ...defaultPsaConnectorMappingFilter,
    });

    watch(servicesMapping, (value) => {
      localValue.value = {
        models: value.map(({ identifier }) => {
          return identifier || null;
        }),
      };
    });

    onMounted(async () => {
      await psaConnectorStore.getServiceMapping(localFilters.value);
      await psaConnectorStore.getProducts("");
    });

    const updateProductSearch = debounce((value: string, loadingIndex = null) => {
      psaConnectorStore.getProducts(value);
      currentlyLoadingIndex.value = loadingIndex;
    }, 500);

    const mappedTableData = computed(() =>
      servicesMapping.value.map((mapping) => ({
        primaryItem: i18n.t(`connectors.psa.products.${mapping.service}`),
        secondaryItem: mapping.identifier,
      }))
    );

    const updateMappingValue = async ({ selectedSecondaryItem, rowIndex }: PsaMappingUpdate) => {
      const { service } = servicesMapping.value[rowIndex];
      const params = selectedSecondaryItem
        ? {
            service,
            product: {
              id: selectedSecondaryItem.value,
              identifier: selectedSecondaryItem.title,
            },
          }
        : {
            service,
          };

      await psaConnectorStore.updateServiceMapping(params);
      await psaConnectorStore.getServiceMapping(localFilters.value, false);
    };

    watch(
      localFilters,
      debounce(async (val) => {
        await psaConnectorStore.getServiceMapping(val, false);
      }, 500),
      { immediate: false, deep: true }
    );

    const clearFilters = () => {
      localFilters.value = cloneDeep(defaultPsaConnectorMappingFilter);
    };

    return {
      loading,
      localValue,
      productsSelectOptions,
      updateMappingValue,
      serviceMapping: mappedTableData,
      updateProductSearch,
      productsLoading: companiesLoading,
      coronetSkeletonLoaderTypes,
      showSkeletonLoader,
      localFilters,
      clearFilters,
      currentlyLoadingIndex,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .icon.icon-triangle {
    font-size: 8px;
  }

  .company-select {
    .v-field__input {
      padding: 10px 0 !important;
    }
  }
}

.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}

// table styles

.text-align-right {
  text-align: right;
  text-align: -webkit-right;
}
</style>
