<template>
  <div>
    <v-text-field
      v-model="searchText"
      id="#smart-search"
      @blur="handleAddition(true)"
      @click="onClick($event)"
      @keydown="handleKeydown"
      @keydown.enter="handleAddition"
      background-color="white"
      :placeholder="placeholderValue"
      variant="outlined"
      rounded
      density="compact"
      autocomplete="off"
    >
      <template #prepend-inner>
        <slot name="history"></slot>
      </template>
      <template #append-inner>
        <slot name="search"></slot>
      </template>
      <template #default>
        <template v-for="(segment, index) in segments" :key="index">
          <search-segment :segment="segment" @segment-removed="onItemRemove($event)" />
        </template>
        <!--Creation of new segment-->
        <div>
          <search-segment :segment="newSegment" />
        </div>
      </template>
    </v-text-field>
    <smart-search-menu
      :show-menu="showMenu"
      :current-step="currentStep"
      activator-id="#smart-search"
    >
      <div v-click-outside="{ handler: onClickOutside }">
        <slot
          name="menu"
          :currentStep="currentStep"
          :handleSelect="handleSelect"
          :searchText="searchText"
        />
      </div>
    </smart-search-menu>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  type PropType,
  type Ref,
  ref,
  watch,
} from "vue";
import SearchSegment, {
  type SmartSearchSegment,
} from "@/components/smart-search/SearchSegment.vue";
import SmartSearchMenu from "@/components/smart-search/SmartSearchMenu.vue";
import isEmpty from "lodash/isEmpty";
import { i18n } from "@/plugins/i18n";
import moment from "moment/moment";
import type { EdrTelemetryFilters } from "@/_store/filters.module";
import { SearchSuggestion } from "@/constants/general";

export interface SmartSearchSegmentItem {
  displayName: string;
  value: string | SegmentKey;
}

export enum SegmentFilterKey {
  TYPE = "type",
  TIME_RANGE = "timeRange",
}

export enum SegmentKey {
  MAIN_ENTRY = SearchSuggestion.NAME,
  ENROLLMENT_CODE = SearchSuggestion.ENROLLMENT_CODE,
  DEVICE = SearchSuggestion.HOSTNAME,
  PROCESS_NAME = SearchSuggestion.PROCESS_NAME,
  PROCESS_HASH = SearchSuggestion.PROCESS_HASH,
  EVENT_ID = SearchSuggestion.EVENT_ID,
  ACCOUNT_DOMAIN = SearchSuggestion.DOMAIN,
  CURRENT_VALUE = SearchSuggestion.CURRENT_VALUE,
  OLD_VALUE = SearchSuggestion.OLD_VALUE,
  REGISTRY_KEY_OPERATION_TYPE = SearchSuggestion.REGISTRY_KEY_OPERATION_TYPE,
  SCHEDULED_TASK_OPERATION_TYPE = SearchSuggestion.SCHEDULED_TASK_OPERATION_TYPE,
  ACTION = SearchSuggestion.ACTION,
  TARGET_ACCOUNT = SearchSuggestion.TARGET_ACCOUNT,
  TYPE = SegmentFilterKey.TYPE,
  TIME_RANGE = SegmentFilterKey.TIME_RANGE,
}

export enum SegmentOperator {
  AND = "=",
}

export interface SmartSearchSegmentKey {
  value: SegmentKey;
  displayName: string;
}

export interface SmartSearchSegmentOperator {
  value: SegmentOperator;
  displayName: string;
}

export interface SmartSearchSegmentMenuItem extends SmartSearchSegmentItem {
  subFilters?: SmartSearchSegmentItem[];
}

export enum SearchSegmentCreationStep {
  CREATE_KEY = "createKey",
  CREATE_OPERATOR = "createOperator",
  CREATE_VALUE = "createValue",
}

export default defineComponent({
  components: {
    SmartSearchMenu,
    SearchSegment,
  },
  props: {
    filters: {
      type: Object as PropType<EdrTelemetryFilters>,
      required: true,
    },
    placeholder: {
      type: String,
      default: i18n.global.t("smartSearch.placeholder"),
    },
  },
  emits: ["filters-updated"],
  setup(props, { emit }) {
    const showMenu = ref(false);
    const searchText = ref("");
    const newSegment: Ref<SmartSearchSegment> = ref({} as SmartSearchSegment);
    const currentStep: Ref<SearchSegmentCreationStep> = ref(SearchSegmentCreationStep.CREATE_KEY);
    const mounted: Ref<boolean> = ref(false);
    const segments = ref(props.filters.searches || []);

    const placeholderValue = computed(() => {
      if (!isEmpty(newSegment.value)) {
        if (newSegment.value.key && newSegment.value.operator) {
          return i18n.global.t("smartSearch.enterValue");
        }
        return "";
      }
      if (segments.value.length > 0) {
        return "";
      }
      return props.placeholder;
    });

    const handleAddition = (isBlur: boolean = false) => {
      if (currentStep.value === SearchSegmentCreationStep.CREATE_VALUE) {
        if (searchText.value.length === 0) return;

        // Check if search query is being edited
        if (segments.value.length && !segments.value[segments.value.length - 1].value) {
          segments.value[segments.value.length - 1].value = searchText.value;
          searchText.value = "";
          currentStep.value = SearchSegmentCreationStep.CREATE_KEY;
        } else {
          newSegment.value.value = searchText.value;
          nextTick(() => {
            searchText.value = "";
            currentStep.value = SearchSegmentCreationStep.CREATE_KEY;
            newSegment.value.id = moment().unix();
            segments.value.push(newSegment.value);
            newSegment.value = {} as SmartSearchSegment;
          });
        }
      } else if (searchText.value.length > 0) {
        newSegment.value.value = searchText.value;
        searchText.value = "";
        newSegment.value.id = new Date().getMilliseconds();
        segments.value.push(newSegment.value);
        newSegment.value = {} as SmartSearchSegment;
      }
      if (!isBlur) {
        setTimeout(() => {
          showMenu.value = true;
        }, 100);
      }
    };

    const handleSelect = (item: SmartSearchSegmentItem) => {
      if (currentStep.value === SearchSegmentCreationStep.CREATE_KEY) {
        newSegment.value.key = item as SmartSearchSegmentKey;
        nextTick(() => {
          currentStep.value = SearchSegmentCreationStep.CREATE_OPERATOR;
          showMenu.value = false;
          setTimeout(() => {
            showMenu.value = true;
          }, 100);
        });
      }
      if (currentStep.value === SearchSegmentCreationStep.CREATE_OPERATOR) {
        newSegment.value.operator = item as SmartSearchSegmentOperator;
        nextTick(() => {
          currentStep.value = SearchSegmentCreationStep.CREATE_VALUE;
          document.getElementById("#smart-search")?.focus();
          showMenu.value = false;
        });
      }
      searchText.value = "";
    };

    const onClick = (event: MouseEvent) => {
      if (currentStep.value === SearchSegmentCreationStep.CREATE_VALUE) return;
      showMenu.value = true;
      event.preventDefault();
    };

    const onClickOutside = () => {
      nextTick(() => {
        showMenu.value = false;
      });
    };

    const onItemRemove = (item: SmartSearchSegment) => {
      segments.value = segments.value.filter((segment: SmartSearchSegment) => {
        return segment.id !== item.id;
      });
    };

    const handleBackspace = () => {
      if (searchText.value.length > 0) return;
      if (!isEmpty(newSegment.value)) {
        newSegment.value = {} as SmartSearchSegment;
        currentStep.value = SearchSegmentCreationStep.CREATE_KEY;
        return;
      }
      if (segments.value[segments.value.length - 1].value) {
        const lastSegmentSearchString = segments.value[segments.value.length - 1].value;
        segments.value[segments.value.length - 1].value = "";
        searchText.value = lastSegmentSearchString as string;
        currentStep.value = SearchSegmentCreationStep.CREATE_VALUE;
      } else {
        segments.value = segments.value.slice(0, -1);
        currentStep.value = SearchSegmentCreationStep.CREATE_KEY;
      }
    };

    const handleKeydown = (event: KeyboardEvent) => {
      const { key } = event;
      if (key === "Backspace" || key === "Delete") {
        handleBackspace();
        return;
      }
    };

    watch(
      segments,
      (value) => {
        emit("filters-updated", value);
      },
      { deep: true }
    );

    watch(
      () => props.filters,
      (value) => {
        segments.value = value.searches || [];
      },
      { deep: true }
    );

    onMounted(() => {
      mounted.value = true;
    });

    return {
      showMenu,
      searchText,
      segments,
      handleSelect,
      handleAddition,
      newSegment,
      currentStep,
      mounted,
      onClick,
      onClickOutside,
      onItemRemove,
      isEmpty,
      placeholderValue,
      handleKeydown,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field {
    line-height: 24px;
    border-radius: 20px;
    background-color: rgb(var(--v-theme-white));
    border: none;
  }

  .v-field__outline__start,
  .v-field__outline__end {
    border-color: rgb(var(--v-theme-indigo-pale)) !important;
  }
}
</style>
