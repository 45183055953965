import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import { useSnackbarStore } from "@/_store";
import { i18n } from "@/plugins/i18n";
import type { SiemConnectorFormat } from "@/constants/connectors";
import type { ServiceStatus } from "@/constants/cloud-apps";

export interface SiemConnectorProviderData {
  workspaceId: string;
  primaryKey: string;
  logType: string;
  url?: string;
  headers?: { key: string; value: string }[];
}

interface SiemConnectorBaseData {
  id: string;
  workspaceId: string;
  name: string;
  childWorkspaceIds: string[];
  format: SiemConnectorFormat;
  status: ServiceStatus;
  applyToAll: boolean;
  editable: boolean;
}

export type SiemConnector = SiemConnectorBaseData &
  Partial<Record<SiemConnectorFormat, SiemConnectorProviderData>>;

interface SiemState {
  connectors: SiemConnector[];
  loading: boolean;
  showSkeletonLoader: boolean;
}

const defaultSiemState = {
  connectors: [],
  loading: false,
  showSkeletonLoader: false,
};

export const useSiemStore = defineStore("siem", {
  state: (): SiemState => ({ ...defaultSiemState }),
  actions: {
    async getConnectors(showSkeletonLoader: boolean = true): Promise<void> {
      if (showSkeletonLoader) {
        this.showSkeletonLoader = true;
      }

      try {
        const response = await axiosInstance.request(api.siemConnectors());

        this.connectors = response.data;

        this.showSkeletonLoader = false;
      } catch (err) {
        console.error(err);
        this.showSkeletonLoader = false;
        throw err;
      }
    },
    async addSiemConnector(data: SiemConnector): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.siemConnectors(),
          data,
          method: "post",
        };

        await axiosInstance.request(request);

        await this.getConnectors(false);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async updateConnector(data: SiemConnector): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.siemConnector(data.id),
          data,
          method: "put",
        };

        await axiosInstance.request(request);

        await this.getConnectors(false);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async deleteConnector(data: SiemConnector): Promise<void> {
      this.loading = true;

      try {
        const request = {
          ...api.siemConnector(data.id),
          method: "delete",
        };

        await axiosInstance.request(request);

        await this.getConnectors(false);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    async syncConnector(data: SiemConnector): Promise<void> {
      try {
        this.loading = true;

        const request = {
          ...api.syncSiemConnector(),
          data,
          method: "post",
        };

        await axiosInstance.request(request);

        const snackbarModule = useSnackbarStore();

        snackbarModule.addGenericSuccess(
          i18n.global.t("snackbar.messages.connectors.syncCompleted")
        );

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },
  },
});
