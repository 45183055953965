import { defineStore } from "pinia";
import type { Pagination } from "@/types";
import type { TimeRange } from "@/_store/filters.module";
import { useFiltersStore } from "@/_store/filters.module";
import moment from "moment/moment";
import type { TelemetryType } from "@/constants/edr";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { handleVirtualScrollData } from "@/_helpers/utils";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";
import { remove, cloneDeep } from "lodash";
import type { SmartSearchSegment } from "@/components/smart-search/SearchSegment.vue";
import { SegmentKey, SegmentOperator } from "@/components/smart-search/SmartSearch.vue";

export const defaultTelemetryState = {
  items: [],
  showSkeletonLoader: true,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  loading: false,
  details: {},
  detailsLoading: false,
  hasMore: true,
  loadingItemsList: [],
  recentSearches: [],
};

export interface TelemetryState {
  items: Array<TelemetryItem>;
  showSkeletonLoader: boolean;
  pagination: Pagination;
  loading: boolean;
  details: TelemetryDetails;
  detailsLoading: boolean;
  hasMore: boolean;
  loadingItemsList: Array<string>;
  recentSearches: SmartSearchSegment[][];
}

export interface TelemetryItem {
  actions: Array<string>;
  deviceDetails: {
    enrollmentCode: string;
    hostname: string;
  };
  processDetails: {
    name: string;
    processHash: string;
  };
  id: string;
  name: string;
  timestamp: number;
  type: string;
}

export interface TelemetryDetails {
  [id: string]: TelemetryDetailsItem;
}

export interface TelemetryDetailsItem {
  id: string;
  processDetails: TelemetryProcessDetails;
  type: TelemetryType;
  userName: TelemetryDetailsProperty;
  currentValue: TelemetryDetailsProperty;
  oldValue: TelemetryDetailsProperty;
  eventId: TelemetryDetailsProperty;
  targetServer: TelemetryDetailsProperty;
  group: TelemetryDetailsProperty;
  action: TelemetryDetailsProperty;
  trigger: TelemetryDetailsProperty;
  accountDomain: TelemetryDetailsProperty;
  operationType: TelemetryDetailsProperty;
  success: TelemetryDetailsProperty;
  authenticationType: TelemetryDetailsProperty;
  uid: TelemetryDetailsProperty;
  userType: TelemetryDetailsProperty;
  dbPath: TelemetryDetailsProperty;
  instigator: TelemetryDetailsProperty;
  usbDevice: TelemetryDetailsProperty;
  friendlyName: TelemetryDetailsProperty;
  groupPolicyObjectName: TelemetryDetailsProperty;
  passwordMinimumAgePolicy: TelemetryDetailsProperty;
  passwordLengthPolicy: TelemetryDetailsProperty;
}

export interface TelemetryProcessDetails {
  processName: string;
  processHash: string;
  commandLine: string;
  parentProcessName: string;
  parentProcessHash: string;
  parentCommandLine: string;
}

export enum TelemetryDetailsProperty {
  USER_NAME = "userName",
  CURRENT_VALUE = "currentValue",
  OLD_VALUE = "oldValue",
  EVENT_ID = "eventId",
  TARGET_SERVER = "targetServer",
  GROUP = "group",
  ACTION = "action",
  TRIGGER = "trigger",
  ACCOUNT_DOMAIN = "accountDomain",
  OPERATION_TYPE = "operationType",
  SUCCESS = "success",
  AUTH_TYPE = "authenticationType",
  UID = "uid",
  USER_TYPE = "userType",
  DB_PATH = "dbPath",
  INSTIGATOR = "instigator",
  USB_DEVICE = "usbDevice",
  FRIENDLY_NAME = "friendlyName",
  GROUP_POLICY_OBJECT_NAME = "groupPolicyObjectName",
  PASSWORD_MINIMUM_AGE_POLICY = "passwordMinimumAgePolicy",
  PASSWORD_LENGTH_POLICY = "passwordLengthPolicy",
}

export interface TelemetryGetItemsPayload {
  showSkeletonLoader?: boolean;
  virtualScroll?: boolean;
}

export const useTelemetryStore = defineStore("telemetry", {
  state: (): TelemetryState => ({
    ...defaultTelemetryState,
  }),
  getters: {
    infiniteLoadingInProgress(state) {
      return state.loading && state.pagination.page > 0;
    },
  },
  actions: {
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    setHasMore(payload: boolean) {
      this.hasMore = payload;
    },
    resetPagination() {
      this.pagination = {
        page: 0,
        pageSize: 25,
      };
    },
    addItemToLoadingList(id: string) {
      this.loadingItemsList = [...this.loadingItemsList, id];
    },
    setDetails(item: TelemetryDetailsItem) {
      this.details[item.id] = item;
    },
    removeItemFromLoadingList(payload: string) {
      const newLoadingList = cloneDeep(this.loadingItemsList);
      remove(newLoadingList, (id: string) => id === payload);
      this.loadingItemsList = newLoadingList;
    },
    async getItems(
      { showSkeletonLoader = false, virtualScroll = false }: TelemetryGetItemsPayload = {
        showSkeletonLoader: false,
        virtualScroll: false,
      }
    ) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const filtersStore = useFiltersStore();
      const filters = convertFiltersForBackend({
        ...filtersStore.filters.edrTelemetryFilters,
      });
      const request = {
        ...api.getEdrTelemetryList({
          ...this.pagination,
          ...filters,
        }),
      };

      try {
        const { data } = await axiosInstance.request(request);
        if (data.length < this.pagination.pageSize) {
          this.setHasMore(false);
        }
        this.items = virtualScroll ? handleVirtualScrollData(this.items, data, "id") : data;
      } catch (err) {
        console.error(err);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async getEdrLog(id: string) {
      const request = {
        ...api.getEdrLog(id),
      };

      try {
        const { data } = await axiosInstance.request(request);
        if (data.logFormat === "xml") {
          const blob = new Blob([data.fullLog], { type: "text/xml" });
          const url = URL.createObjectURL(blob);
          window.open(url);
          URL.revokeObjectURL(url);
        } else {
          const prettyJSON = JSON.stringify(JSON.parse(data.fullLog), null, 4);
          const newTab = window.open();
          newTab?.document.write(`<pre>${prettyJSON}</pre>`);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async applyTelemetryAction({ action, item }: { action: string; item: TelemetryItem }) {
      const snackbarMessage = {
        html: i18n.global.t(`snackbar.messages.edr.${action}`, { quantity: 1 }, 1),
        type: SnackbarTypes.SUCCESS,
      };
      const request = {
        ...api.processTelemetryAction(item.id),
        data: {
          action,
        },
      };

      try {
        await axiosInstance.request(request);
        await this.getItems();
        const snackbarStore = useSnackbarStore();
        snackbarStore.add(snackbarMessage);
      } catch (err) {
        console.error(err);
      }
    },
    async getDetails(id: string) {
      this.detailsLoading = true;
      this.addItemToLoadingList(id);

      const request = {
        ...api.getEdrTelemetryDetails(id),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.setDetails(data);
      } catch (err) {
        console.error(err);
      }
      this.removeItemFromLoadingList(id);
      this.detailsLoading = false;
    },
    async getTelemetryRecentSearches() {
      const request = {
        ...api.getTelemetryRecentSearches,
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.recentSearches = convertRecentSearchesFromBackend(data);
      } catch (err) {
        console.error(err);
      }
    },
    async clearTelemetryRecentSearches() {
      const request = {
        ...api.clearTelemetryRecentSearches,
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.recentSearches = data;
      } catch (err) {
        console.error(err);
      }
    },
  },
});

const convertFiltersForBackend = ({
  timeRange,
  types,
  searches,
}: {
  timeRange: Partial<TimeRange>;
  types: TelemetryType[];
  searches: SmartSearchSegment[];
}) => {
  const fromTime = timeRange.start ? moment(timeRange.start).valueOf() : undefined;
  const toTime = timeRange.end ? moment(timeRange.end).valueOf() : undefined;
  const filteredSearches = searches
    .filter((e) => e.value)
    .map((e) => `${e.key?.value ? e.key.value : ""}${e.value}`);
  return {
    fromTime,
    toTime,
    types,
    searches: filteredSearches,
  };
};

const convertRecentSearchesFromBackend = (
  recentSearches: Array<{
    types: TelemetryType[];
    fromTime: number;
    toTime: number;
    searches: Array<string>;
  }>
): SmartSearchSegment[][] => {
  const segments = [];
  let segment = [];
  for (const query of recentSearches) {
    segment = [];
    if (query.types?.length) {
      segment.push({
        key: { displayName: i18n.global.t("general.type"), value: SegmentKey.TYPE },
        displayValue: query.types
          .map((type) => i18n.global.t(`edr.telemetryTab.types.${type}`))
          .join(",")
          .replace(",", ", "),
        value: query.types,
      });
    }

    if (query.fromTime > 0) {
      segment.push({
        key: { displayName: i18n.global.t("general.during"), value: SegmentKey.TIME_RANGE },
        displayValue: `${moment(query.fromTime).format("MM/DD/YYYY")} - ${moment(
          query.toTime
        ).format("MM/DD/YYYY")}`,
        value: {
          start: moment(query.fromTime).toISOString(),
          end: moment(query.toTime).toISOString(),
        },
      });
    }

    for (const search of query.searches) {
      if (!search.includes(":")) {
        segment.push({
          id: Math.floor(Math.random() * 1000000),
          displayValue: search,
          value: search,
        });
      } else {
        const keyValuePair = search.split(":");
        segment.push({
          id: Math.floor(Math.random() * 1000000),
          key: {
            displayName: i18n.global.t(`smartSearch.searchPrefixes.${keyValuePair[0]}`),
            value: `${keyValuePair[0]}:` as unknown as SegmentKey,
          },
          operator: { displayName: SegmentOperator.AND, value: SegmentOperator.AND },
          displayValue: keyValuePair[1],
          value: keyValuePair[1],
        });
      }
    }
    segments.push(segment);
  }

  return segments;
};
