<template>
  <div>
    <div v-if="reasonOfFail" class="no-connection-wrapper">
      <v-icon class="mb-4" size="64" icon="$warning"></v-icon>
      <div class="headline5 text-center" v-html="failReasonTranslated"></div>
    </div>
    <coro-terminal
      v-else
      :welcome-message="welcomeMessage"
      :is-interactive-mode="isInInteractiveMode"
      :prompt="prompt"
      :loading="loading"
      :loading-text="loadingText"
      :command-in-progress="commandInProgress"
      :command-in-progress-text="$t('modals.openRemoteShellSession.commandInProgress')"
      @run-script="uploadScript($event)"
    />
  </div>
</template>

<script>
import { ShellSessionFailedReason, TerminalCommand } from "@/constants/terminal";
import { DeviceAction } from "@/constants/devices";
import { useTerminalStore } from "@/_store/terminal.module";
import { useDevicesStore } from "@/_store/devices.module";
import { mapActions, mapState } from "pinia";
import { useAccountStore } from "@/_store";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import CoroTerminal from "@/components/CoroTerminal.vue";

export default {
  components: { CoroTerminal },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localValue: {
        ...this.config,
      },
      isLoading: false,
      initialLoadTimerId: null,
      commandInProgressTimer: null,
      interactiveCommandInProgressIntervalId: null,
      initialLoadIntervalId: null,
      reasonOfFail: null,
    };
  },
  computed: {
    ...mapState(useTerminalStore, [
      "commandInProgress",
      "supportedCommands",
      "sessionId",
      "sessionOpened",
      "failReason",
      "lastCommandReceivedTime",
      "websocketConnected",
      "isInInteractiveMode",
    ]),
    ...mapState(useDevicesSettingsStore, {
      devicesSettings: "settings",
    }),
    isGlobalAdmin() {
      return useAccountStore().isGlobalAdmin;
    },
    deviceName() {
      return this.config.item.device.hostname ?? this.config.item.enrollmentCode;
    },
    welcomeMessage() {
      return this.$t("modals.openRemoteShellSession.connectedTo", { name: this.deviceName })
        .concat("\n")
        .concat(this.getFormattedHelpOutput());
    },
    prompt() {
      return `${this.deviceName} $`;
    },
    loadingText() {
      return this.$t("modals.openRemoteShellSession.connectingTo", {
        name: this.deviceName,
        minutes: this.maxConnectionTimeSeconds / 60,
      });
    },
    loading: {
      get() {
        return this.isLoading || !this.websocketConnected || !this.sessionOpened;
      },
      set(value) {
        this.isLoading = value;
      },
    },
    // we should wait for 2 of heartbeat intervals (interval is in minutes)
    // for example, heartbeat interval is 1, then we should wait for 120000 milliseconds
    maxConnectionTimeSeconds() {
      return this.devicesSettings.heartbeatInterval * 60 * 2;
    },
    failReasonTranslated() {
      return this.$t(`modals.openRemoteShellSession.errors.${this.reasonOfFail}`);
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(loading) {
        loading ? this.pollForOpenShellCommand() : clearInterval(this.initialLoadIntervalId);
      },
    },
    commandInProgress: {
      immediate: true,
      handler(inProgress) {
        inProgress ? this.waitForCommandResult() : clearTimeout(this.commandInProgressTimer);
      },
    },
    failReason: {
      immediate: true,
      handler(reason) {
        if (reason) {
          this.failConnection(reason);
        }
      },
    },
    isInInteractiveMode: {
      handler(inProgress) {
        inProgress
          ? this.pollForInteractiveCommandResult()
          : clearInterval(this.interactiveCommandInProgressIntervalId);
      },
    },
  },
  mounted() {
    this.addEventHandler({ type: "command", handler: this.commandHandler });
  },
  beforeUnmount() {
    this.removeEventHandler({ type: "command", handler: this.commandHandler });
  },
  async created() {
    this.loading = true;
    await this.openRemoteShellSession(
      this.getRemoteShellActionPayload(DeviceAction.OPEN_REMOTE_SHELL_SESSION)
    ).catch(() => {
      this.failConnection(ShellSessionFailedReason.OPEN_SHELL_COMMAND);
    });
    await this.getSupportedCommands(this.config.item.enrollmentCode);
    this.loading = false;
  },
  unmounted() {
    clearTimeout(this.commandInProgressTimer);
    clearInterval(this.initialLoadIntervalId);
    clearInterval(this.interactiveCommandInProgressIntervalId);
    this.closeSession();
  },
  methods: {
    ...mapActions(useTerminalStore, {
      addEventHandler: "addEventHandler",
      removeEventHandler: "removeEventHandler",
      emitEvent: "emitEvent",
      sendTerminalCommand: "sendTerminalCommand",
      getSupportedCommands: "getSupportedCommands",
      getLastCommandResults: "getLastCommandResults",
      getSessionStatus: "getSessionStatus",
      resetState: "resetState",
      setCommandInProgress: "setCommandInProgress",
      setLastCommandReceivedTime: "setLastCommandReceivedTime",
    }),
    ...mapActions(useDevicesStore, {
      openRemoteShellSession: "openRemoteShellSession",
      closeRemoteShellSession: "closeRemoteShellSession",
    }),
    /**
     * Handles the full command provided by the user
     * @param {string} fullCommand - The full command provided by the user
     * @returns {void}
     */
    commandHandler(fullCommand) {
      if (fullCommand.includes(";") && !this.isGlobalAdmin) {
        this.emitEvent({
          type: "response",
          payload: this.$t("modals.openRemoteShellSession.combinedCommandNotSupported"),
        });
        return;
      }
      const [command] = fullCommand.split(" ");
      if (!command || !/\S/.test(command)) {
        // Didn't find something other than a space which means it's empty
        this.emitEvent({ type: "response", payload: "\n" });
        return;
      }
      if (command.includes(TerminalCommand.HELP)) {
        this.emitEvent({ type: "response", payload: this.getFormattedHelpOutput() });
        return;
      }
      const commandNames = this.supportedCommands
        .map((v) => v.name)
        .concat(TerminalCommand.CONTROL_C);
      if (commandNames.includes(command)) {
        if (command === TerminalCommand.CONTROL_C && this.interactiveCommandInProgressIntervalId) {
          clearInterval(this.interactiveCommandInProgressIntervalId);
        }
        this.sendTerminalCommand({
          enrollmentCode: this.config.item.enrollmentCode,
          sshCommand: fullCommand,
        });
      } else {
        this.emitEvent({
          type: "response",
          payload: this.$t("modals.openRemoteShellSession.unknownCommand", { command }),
        });
      }
    },
    /**
     * Returns the payload needed to perform a remote shell action.
     * @param {string} action - The action to perform.
     * @returns {Object} - The payload object.
     */
    getRemoteShellActionPayload(action) {
      return {
        action,
        selection: this.config.item.selection,
        sshSessionId: this.sessionId,
      };
    },
    /**
     * Returns the formatted help output.
     * @returns {string} - The formatted help output.
     */
    getFormattedHelpOutput() {
      const commandsFormatted = this.supportedCommands
        .map((command) => {
          const description = command.description;
          // split the description by 70 symbols chunks
          const splitText = description.match(/.{1,70}/g);
          // get first line
          let padded = splitText[0];

          for (let i = 1; i < splitText.length; i++) {
            // padEnd by one more symbol because of \n
            padded += "\n".padEnd(16).concat(splitText[i]); // pad subsequent lines
          }
          return `${command.name.padEnd(15).concat(padded)}\n`;
        })
        .join("\n");
      return `${this.$t("modals.openRemoteShellSession.helpTitle")}\n\n${commandsFormatted}\n`;
    },
    /**
     * Fails the connection.
     * @param {string} reason - The reason for the connection failure.
     */
    failConnection(reason) {
      this.reasonOfFail = reason;
      clearTimeout(this.commandInProgressTimer);
      clearInterval(this.initialLoadIntervalId);
      clearInterval(this.interactiveCommandInProgressIntervalId);
      this.closeSession();
    },
    closeSession() {
      if (
        [
          ShellSessionFailedReason.CLIENT_SESSION_CLOSED,
          ShellSessionFailedReason.TOO_MANY_SHELL_SESSIONS,
        ].includes(this.reasonOfFail)
      ) {
        this.resetState();
      } else if (this.sessionId) {
        this.closeRemoteShellSession(
          this.getRemoteShellActionPayload(DeviceAction.CLOSE_REMOTE_SHELL_SESSION)
        );
      }
    },
    pollForOpenShellCommand() {
      let counter = 0;
      this.initialLoadIntervalId = setInterval(() => {
        counter += 1;
        // if session is established => status 200
        // if session is not established => status 404
        this.getSessionStatus().catch(() => {
          // counter is used to make a request in the middle of heartbeat interval and in the end
          if (counter === 4) {
            this.failConnection(ShellSessionFailedReason.OPEN_SHELL_COMMAND);
          }
        });
      }, (this.maxConnectionTimeSeconds / 4) * 1000);
    },
    waitForCommandResult() {
      this.commandInProgressTimer = setTimeout(() => {
        this.getLastCommandResults()
          .then(({ data }) => {
            this.setCommandInProgress(false);
            this.emitEvent({ type: "response", payload: data.map((v) => v.data).join("") });
          })
          .catch(() => {
            this.failConnection(ShellSessionFailedReason.COMMAND_FAILED);
          });
      }, 20 * 1000); //wait for 20 sec, if 404 then close everything
    },
    pollForInteractiveCommandResult() {
      this.interactiveCommandInProgressIntervalId = setInterval(() => {
        this.getLastCommandResults()
          .then(({ data }) => {
            this.setCommandInProgress(false);
            // Check if the last command (by checking the last received time of command) and command that we get via API call
            // are the same, then we skip showing this command
            // if not - then there is a possibility that something is wrong with WS, and then we show the output and update the command time
            const lastApiCommandReceivedTime = data.at(-1)?.receivedAt;
            if (this.lastCommandReceivedTime === lastApiCommandReceivedTime) return;
            this.setLastCommandReceivedTime(lastApiCommandReceivedTime);
            this.emitEvent({ type: "response", payload: data.map((v) => v.data).join("") });
          })
          .catch(() => {
            this.failConnection(ShellSessionFailedReason.COMMAND_FAILED);
          });
      }, 20 * 1000); // check each 20 sec for output, if 404 then close everything
    },
    uploadScript(script) {
      this.sendTerminalCommand({
        enrollmentCode: this.config.item.enrollmentCode,
        sshCommand: "",
        script,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-connection-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
}
</style>
