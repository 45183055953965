<template>
  <div>
    <v-app-bar app color="white" dark height="56" elevation="0">
      <v-container fluid height="56" class="pt-0">
        <div class="app-bar--content">
          <div class="d-flex align-center text-no-wrap justify-space-between">
            <span
              class="subtitle2 text-primary text-uppercase"
              v-text="$t('general.coroConsole')"
            ></span>
          </div>
          <div v-if="logged && account.onboardingCompleted" class="d-flex justify-end w-100">
            <div class="d-inline-flex align-center">
              <select-workspace-dropdown />
            </div>
          </div>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useAccountStore } from "@/_store/account.module";
import { storeToRefs } from "pinia";
import SelectWorkspaceDropdown from "@/components/modals/SelectWorkspaceDropdown.vue";

export default defineComponent({
  components: { SelectWorkspaceDropdown },
  setup() {
    const accountStore = useAccountStore();
    const { account, logged } = storeToRefs(accountStore);

    const showCustomerName = computed(
      () => accountStore.logged && accountStore.account.customerName
    );

    return {
      account,
      logged,
      showCustomerName,
    };
  },
});
</script>

<style lang="scss" scoped>
.app-bar--content {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}
</style>
