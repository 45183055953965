<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlitable mb-10"
      :items="items"
      :headers="headers"
      :loading="loading"
      :fixed-header="true"
      :show-select="false"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      item-value="id"
      @update:options="handlePageChange"
    >
      <template #item="{ item, index }">
        <tr :id="`${index}`" :key="index" v-intersect="handleVirtualScroll">
          <td class="pl-2 pr-2">
            <v-icon size="43" icon="$globe" />
            <span class="body2 text--semibold" v-text="item.urlName" />
          </td>
          <td>
            <span
              v-if="item.excluded"
              class="excluded-label"
              v-text="$t(`network.virtualOffice.excluded`)"
            />

            <span v-else class="included-label" v-text="$t(`network.virtualOffice.included`)" />
          </td>
          <td class="d-flex justify-end">
            <v-btn icon="$trashOutlined" @click="handleRemoveUrl(item)" />
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { VirtualOfficeAction } from "@/constants/network";
import type { VirtualOfficeURLListItem } from "@/_store/network/virtual-office.module";
import type { Pagination } from "@/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: { type: Array as PropType<VirtualOfficeURLListItem[]>, required: true },
    loading: { type: Boolean, default: false },
    totalItems: { type: Number, required: true },
    pagination: { type: Object as PropType<Pagination>, required: true },
  },

  emits: ["last-item-reached", "page-change", "remove-url"],

  setup(props, { emit }) {
    const i18n = useI18n();
    const handleRemoveUrl = (item: VirtualOfficeURLListItem) => {
      const payload = {
        item,
        action: item.excluded
          ? VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST
          : VirtualOfficeAction.REMOVE_FROM_INCLUDELIST,
      };
      emit("remove-url", payload);
    };
    const handlePageChange = (payload: Pagination): void => {
      emit("page-change", payload);
    };
    const handleVirtualScroll = (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === props.items?.length - 1) {
        if (intersectedItemId === props?.totalItems - 1) {
          return;
        }
        emit("last-item-reached");
      }
    };

    const headers = [
      {
        title: i18n.t("network.virtualOffice.table.name"),
        key: "urlName",
        sortable: false,
      },
      {
        title: i18n.t("network.virtualOffice.table.status"),
        key: "status",
        sortable: false,
      },
      {},
    ];
    return {
      handlePageChange,
      handleRemoveUrl,
      handleVirtualScroll,
      headers,
    };
  },
});
</script>

<style scoped lang="scss">
.excluded-label {
  background: rgb(var(--v-theme-indigo-light));
  padding: 4px;
  color: rgb(var(--v-theme-primary));
  border-radius: 8px;
}

.included-label {
  background: rgb(var(--v-theme-primary));
  padding: 4px;
  color: white;
  border-radius: 8px;
}
</style>
