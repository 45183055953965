<template>
  <div>
    <div class="info-block px-3 py-1 mb-10 d-flex align-center">
      <v-icon size="24" icon="$warning" />
      <span class="subtitle2 ml-2" v-html="$t('modals.exportTickets.disclaimer')" />
    </div>
    <div class="subtitle1 mb-1">
      {{ $t("modals.exportTickets.subtitle") }}
    </div>
    <v-radio-group v-model="localValue.type" hide-details>
      <v-radio
        v-for="choice in [ExportChoice.ALL, ExportChoice.SPECIFIC]"
        :key="choice"
        class="mb-3"
        :value="choice"
        :ripple="false"
        :label="$t(`modals.exportTickets.choices.${choice}`)"
      ></v-radio>
    </v-radio-group>
    <filter-wrapper class="mt-2" :show-clear-button="false">
      <div class="d-flex flex-column">
        <v-select
          v-model="localValue.filters.widget"
          :items="widgets"
          :disabled="localValue.type === ExportChoice.ALL"
          :menu-props="{ maxHeight: '300' }"
          class="filter-menu widget-filter mt-2 fit"
          :class="{ 'filter-active': localValue.filters.widget }"
          :placeholder="$t('tickets.filters.widgets.placeholder')"
          density="compact"
          item-value="name"
          item-title="name"
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ $t(`tickets.filters.widgets.${item.title}`) }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" :title="$t(`tickets.filters.widgets.${item.title}`)">
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="localValue.filters.eventTriggers"
          :items="triggers"
          :menu-props="{ maxHeight: '300' }"
          :disabled="localValue.type === ExportChoice.ALL"
          class="filter-menu triggers-filter mt-2"
          :class="{
            'filter-active': !!localValue.filters.eventTriggers?.length,
          }"
          :placeholder="$t('tickets.filters.type')"
          density="compact"
          variant="outlined"
          item-value="name"
          item-title="name"
          multiple
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("tickets.filters.type") }}</span>
          </template>
          <template #label>
            <span v-if="localValue.filters.eventTriggers?.length" class="filter-label">{{
              localValue.filters.eventTriggers.length
            }}</span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
            <v-list-item v-else v-bind="props" title="">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>

              <v-list-item-title :title="''">
                {{ $t(`tickets.eventTriggers.${item.raw.name}`) }}</v-list-item-title
              >
            </v-list-item>
          </template>
        </v-select>
        <date-range-picker
          v-model="localValue.filters.eventTimeRange"
          :disabled="localValue.type === ExportChoice.ALL"
          :preset-dates="presets"
          :placeholder="$t('activityLogs.filters.dateRange')"
          class="mt-2 range-picker"
          :teleport="true"
          hide-menu-arrow
          @click.stop.prevent
        ></date-range-picker>
      </div>
    </filter-wrapper>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import { ExportChoice, type ExportDialogPayload } from "@/_store/exports.module";
import { defaultFiltersState, type TicketsFilters } from "@/_store/filters.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { camelCase } from "lodash";
import { useI18n } from "vue-i18n";
import { TicketTrigger, eventTriggerFilterMap, TicketsModuleFilter } from "@/constants/tickets";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { i18n } from "@/plugins/i18n";
import moment from "moment";

const presets = [
  {
    label: i18n.global.t("dateRangePicker.today"),
    value: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.yesterday"),
    value: [
      moment().subtract(1, "days").format("YYYY-MM-DD"),
      moment().subtract(1, "days").format("YYYY-MM-DD"),
    ],
  },
  {
    label: i18n.global.t("dateRangePicker.lastSevenDays"),
    value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThirtyDays"),
    value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.lastNinetyDays"),
    value: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
];

export default defineComponent({
  components: { DateRangePicker, FilterWrapper },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();

    const localValue = ref<ExportDialogPayload<TicketsFilters>>({
      type: ExportChoice.ALL,
      filters: {
        ...defaultFiltersState.ticketsFilters,
      },
    });

    const triggers = computed<{ subheader?: boolean; name: string }[]>(() => {
      return localValue.value.filters.widget
        ? [
            {
              subheader: true,
              name: i18n.t(`tickets.filters.widgets.${localValue.value.filters.widget}`),
            },
            ...sortTriggers(eventTriggerFilterMap[localValue.value.filters.widget]).map((v) => ({
              name: v,
            })),
          ]
        : Object.entries(eventTriggerFilterMap).flatMap(([widget, triggers]) => [
            {
              subheader: true,
              name: i18n.t(`tickets.filters.widgets.${widget}`),
            },
            ...sortTriggers(triggers).map((v) => ({ name: v })),
          ]);
    });

    const sortTriggers = (triggers: TicketTrigger[]): TicketTrigger[] => {
      // If $te (Translation exists) take event trigger translation, else event type translation
      const getTriggerOrTypeTranslation = (item: string) =>
        i18n.te(`tickets.eventTriggers.${item}`)
          ? i18n.t(`tickets.eventTriggers.${item}`)
          : i18n.t(`tickets.eventTypes.${item}`);
      return triggers.slice().sort((a, b) => {
        return getTriggerOrTypeTranslation(a).localeCompare(getTriggerOrTypeTranslation(b));
      });
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );
    return {
      localValue,
      ExportChoice,
      camelCase,
      widgets: Object.values(TicketsModuleFilter),
      triggers,
      presets,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-menu {
  width: 240px !important;
}

.range-picker {
  width: 240px;
}
</style>
