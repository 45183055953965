export enum GenericModalType {
  ERROR_MESSAGE = "errorMessage",
}

export enum AddonMarketplaceLink {
  OUTLOOK = "https://appsource.microsoft.com/en-us/product/office/WA200003312",
  GMAIL = "https://workspace.google.com/marketplace/app/coro_for_gmail/1077570784406",
  SECURE_MESSAGES_OUTLOOK = "https://appsource.microsoft.com/en-us/product/office/wa200005606?tab=overview",
  SECURE_MESSAGES_GMAIL = "https://workspace.google.com/marketplace/app/coromail_for_gmail/766890953338",
}

export enum CoroMarketingLink {
  PRIVACY_POLICY = "https://www.coro.net/privacy",
  TERMS_OF_USE = "https://www.coro.net/terms",
  CONTACT_US = "https://coro.net/contact-us/",
}

export enum DataSizeUnit {
  BYTE = 1,
  KB = 1000,
  MB = 1000000,
}

export enum SearchSuggestion {
  ENROLLMENT_CODE = "enrollmentCode:",
  HOSTNAME = "hostname:",
  PROCESS_HASH = "processHash:",
  PROCESS_NAME = "processName:",
  BLOCKED = "blocked:",
  TICKET_ID = "ticketId:",
  USER = "user:",
  VIOLATOR = "violator:",
  DOMAIN = "domain:",
  PATH = "path:",
  NAME = "name:",
  EVENT_ID = "eventId:",
  CURRENT_VALUE = "currentValue:",
  OLD_VALUE = "oldValue:",
  REGISTRY_KEY_OPERATION_TYPE = "registryKeyOperationType:",
  SCHEDULED_TASK_OPERATION_TYPE = "scheduledTaskOperationType:",
  ACTION = "action:",
  TARGET_ACCOUNT = "targetAccount:",
}
export const coroMailPortalLink = "https://mail.secure.coro.net";

export enum SeverityType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}
