<template>
  <div
    class="coro-chip"
    :class="`coro-chip--${variant}`"
    :style="{
      'background-color': backgroundColor,
    }"
  >
    <div
      class="coro-chip__content"
      :class="`coro-chip__content--${density}`"
      :style="{ 'mix-blend-mode': mixBlendMode, color: color }"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
export default {
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<"square" | "default">,
      default: "default",
    },
    mixBlendMode: {
      type: String as PropType<"difference" | "normal" | "multiply" | "hard-light" | "initial">,
      default: "difference",
    },
    density: {
      type: String as PropType<"default" | "xs">,
      default: "default",
    },
    color: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.coro-chip {
  border-radius: 4px;
  display: inline-flex;
  &--default {
    .coro-chip__content {
      color: white;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      &--default {
        padding: 4px 8px;
      }
      &--xs {
        padding: 2px 4px;
      }
    }
  }

  &--square {
    height: 20px;
    width: 20px;
  }
}
</style>
