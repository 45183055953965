<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
  >
    <div class="d-flex justify-end mb-8 label-filters">
      <filter-wrapper :show-clear-button="false">
        <div class="d-flex align-center">
          <v-text-field
            density="compact"
            style="width: 250px"
            clear-icon="$x"
            :class="{ 'filter-active': !!localFilters.search }"
            :placeholder="$t('general.search')"
            prepend-inner-icon="$search"
            clearable
            mappingSelectRef
            hide-details
            v-model="localFilters.search"
            data-testid="labels-search-input"
            filled
            rounded="xl"
            variant="outlined"
            background-color="white"
            class="search-field"
          />
        </div>
      </filter-wrapper>
    </div>
    <table-wrapper class="label-table">
      <div class="coronet-table--loader">
        <v-progress-linear v-if="loading" indeterminate height="2px" />
        <v-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left caption name-block">
                  {{ $t("general.name") }}
                </th>
                <th class="text-left caption">
                  {{ $t("devicesSettings.labelsTab.deviceCount") }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(label, index) in labels" :key="label.name">
                <td class="name-block">
                  <div class="d-flex align-center">
                    <div
                      class="color-preview"
                      :style="{ 'background-color': label.color }"
                      :data-testid="`labels-table-color-row-${index}`"
                    />
                    <div
                      class="coro-subtitle-2 ml-4"
                      :data-testid="`labels-table-name-row-${index}`"
                    >
                      {{ label.name }}
                    </div>
                  </div>
                </td>
                <td :data-testid="`labels-table-count-row-${index}`">
                  <span
                    v-if="
                      label.deviceCount &&
                      !isAccessRestricted(RolePermissionsScope.VIEWS, 'devicesView')
                    "
                    role="button"
                    class="coro-link"
                    @click="goToDevicesTable(label)"
                  >
                    {{ label.deviceCount }}
                  </span>
                  <span v-else class="text-indigo-medium">
                    {{ label.deviceCount || 0 }}
                  </span>
                </td>
                <td class="text-right">
                  <v-menu offset-y location="bottom right">
                    <template #activator="{ props }">
                      <v-btn
                        :icon="true"
                        :data-testid="`labels-table-actions-btn-row-${index}`"
                        :disabled="label.predefined"
                        v-bind="props"
                        variant="text"
                      >
                        <v-icon icon="$dots"></v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="action in actionsList"
                        :key="action"
                        :disabled="isActionDisabled(action, label)"
                        :data-testid="`labels-table-action-${action}`"
                        @click="onActionClick(action, label)"
                      >
                        <v-list-item-title>
                          <v-tooltip
                            v-if="isActionDisabled(action, label)"
                            open-delay="300"
                            open-on-hover
                            :text="$t('devicesSettings.labelsTab.labelInUseTooltip')"
                            location="top"
                          >
                            <template #activator="{ props }">
                              <div class="item-clickable" @click.stop v-bind="props">
                                {{ $t(`devicesSettings.labelsTab.actions.${action}`) }}
                              </div>
                            </template>
                          </v-tooltip>
                          <span v-else>
                            {{ $t(`devicesSettings.labelsTab.actions.${action}`) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </div>
    </table-wrapper>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { LabelAction } from "@/constants/devices";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { defineComponent, onMounted, watch } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { type DeviceLabel, useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { useFilters } from "@/composables/useFilters";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isAccessRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import AddLabelModal from "@/components/modals/AddLabelModal.vue";
import { modalWidthByType } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    TableWrapper,
    FilterWrapper,
  },
  setup() {
    const i18n = useI18n();
    const devicesSettingsStore = useDevicesSettingsStore();
    const { labels, showSkeletonLoader, loading } = storeToRefs(devicesSettingsStore);
    const route = useRoute();
    const router = useRouter();
    const { updateFilters, localFilters, filtersUpdating } = useFilters(FilterContext.LABELS, 1);
    const filtersStore = useFiltersStore();
    const dialogsStore = useDialogsStore();

    function isActionDisabled(action: LabelAction, label: DeviceLabel) {
      return action === LabelAction.DELETE_LABEL && label.inUseBySettings;
    }

    function onActionClick(action: LabelAction, item?: DeviceLabel) {
      switch (action) {
        case LabelAction.EDIT_LABEL:
        case LabelAction.CREATE_LABEL:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              component: AddLabelModal,
              item,
              action,
              width: modalWidthByType.ADD_LABEL,
              callback:
                action === LabelAction.CREATE_LABEL
                  ? devicesSettingsStore.createLabel
                  : devicesSettingsStore.editLabel,
              disable: isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.DEVICES
              ),
            })
          );
          break;
        case LabelAction.DELETE_LABEL:
        default:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              item,
              callback: devicesSettingsStore.deleteLabel,
              text: i18n.t(`modals.${action}.description`, { ...item }, item?.deviceCount ?? 0),
            })
          );
      }
    }

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await devicesSettingsStore.getLabels();
      },
      { deep: true }
    );

    function goToDevicesTable(label: DeviceLabel) {
      filtersStore.setFilters(FilterContext.DEVICES, {
        labels: [{ ...label, statusLabel: false }],
      });
      router.push({ name: "devices" });
    }

    onMounted(async () => {
      const search = (route.query.search as string) ?? "";
      updateFilters({ search });
      await router.replace({ query: undefined });
      await devicesSettingsStore.getLabels(true);
    });

    return {
      RolePermissionsScope,
      loading,
      localFilters,
      labels,
      showSkeletonLoader,
      LabelAction,
      coronetSkeletonLoaderTypes,
      actionsList: [LabelAction.EDIT_LABEL, LabelAction.DELETE_LABEL],
      isActionDisabled,
      isAccessRestricted,
      onActionClick,
      goToDevicesTable,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .color-preview {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    border-radius: 4px !important;
  }

  .add-label-btn {
    top: -105px;
    position: relative;
    align-self: center;
    padding-left: 10px !important;
    padding-right: 16px !important;
  }

  .label-filters {
    top: -115px;
    position: relative;
    max-width: 0;
    left: 100%;
  }

  .v-list-item--disabled {
    pointer-events: auto !important;
  }
}
</style>
