<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS"
  >
    <v-card>
      <v-card-text class="mt-3 mb-3 ml-3 mr-3">
        <div class="subtitle1">{{ $t("soc.remediationTab.title") }}</div>
        <v-radio-group
          class="mt-4"
          v-model="localValue.remediationType"
          :disabled="actionNotAllowed"
          hide-details
        >
          <div v-for="remediationType in remediationTypes" :key="remediationType" class="mb-3">
            <v-radio
              :key="remediationType"
              :value="remediationType"
              @click.native.prevent.stop.capture="onRemediationTypeChange(remediationType)"
              :ripple="false"
              :label="$t(`soc.remediationTab.${remediationType}`)"
            ></v-radio>
            <div class="radio-subtitle body2">
              {{ $t(`soc.remediationTab.${remediationType}Subtitle`) }}
            </div>
          </div>
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  defaultSocRemediationState,
  SocRemediationNotifications,
  SocRemediationType,
  useSocRemediationStore,
} from "@/_store/soc/soc-remediation.module";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import {
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SocRemediationActions } from "@/constants/soc";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import { ProtectionScopeModules, RolePermissionsScope } from "@/_store/roles.module";

export default defineComponent({
  setup() {
    const socRemediationStore = useSocRemediationStore();
    const dialogsStore = useDialogsStore();
    const { getSocRemediationSettings, updateSocRemediationSettings } = socRemediationStore;
    const { settings } = storeToRefs(socRemediationStore);
    const showSkeletonLoader = computed(() => socRemediationStore.showSkeletonLoader);
    const remediationTypes = [
      SocRemediationType.REMEDIATE_MYSELF,
      SocRemediationType.REMEDIATE_BY_CORO_SOC,
    ];

    const remediationNotifications = [
      SocRemediationNotifications.NO_REMEDIATION,
      SocRemediationNotifications.MEDIUM_IMPACT_REMEDIATION,
      SocRemediationNotifications.LOW_IMPACT_REMEDIATION,
    ];

    const localValue = ref(defaultSocRemediationState.settings);
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        ProtectionScopeModules.MANAGED_SOC
      );
    });
    const onRemediationTypeChange = (value: SocRemediationType) => {
      if (value === SocRemediationType.REMEDIATE_BY_CORO_SOC) {
        if (localValue.value.remediationType === SocRemediationType.REMEDIATE_BY_CORO_SOC) return;
        const dialogConfig = confirmationDialogsConfigConstructor({
          item: {},
          action: SocRemediationActions.CHANGE_REMEDIATION_TYPE,
          disable: actionNotAllowed.value,
          callback: () => {
            localValue.value.remediationType = value;
            localValue.value.grantAccess = true;
          },
        });
        dialogConfig.width = ModalWidth.MEDIUM;
        dialogConfig.header.titleClass = "text-red-dark";
        dialogConfig.header.titleIcon = "warning";
        dialogsStore.openDialog(dialogConfig);
      } else {
        localValue.value.remediationType = value;
        localValue.value.grantAccess = false;
      }
    };

    watch(
      localValue,
      (newVal) => {
        if (isEqual(newVal, settings.value)) return;
        updateSocRemediationSettings(newVal);
      },
      { deep: true }
    );

    watch(
      settings,
      (newVal) => {
        localValue.value = cloneDeep(newVal);
      },
      { deep: true }
    );

    onMounted(async () => {
      await getSocRemediationSettings();
    });

    return {
      SocRemediationType,
      coronetSkeletonLoaderTypes,
      remediationTypes,
      remediationNotifications,
      showSkeletonLoader,
      localValue,
      settings,
      onRemediationTypeChange,
      actionNotAllowed,
    };
  },
});
</script>

<style scoped lang="scss">
.radio-subtitle {
  margin-left: 38px;
  margin-top: 5px;
  color: rgba(var(--v-theme-primary), 0.65) !important;
}

:deep(*) {
  .grant-access-checkbox {
    .v-selection-control {
      display: flex !important;
      align-items: flex-start !important;
    }

    .v-label {
      color: rgba(var(--v-theme-primary), 0.65) !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px !important;
      line-height: 130%;
    }
  }
}

.remediation-by-coro-section {
  margin-left: 34px;
}
</style>
