export enum TerminalCommand {
  KILL = "kill",
  MKDIR = "mkdir",
  LS = "ls",
  CD = "cd",
  MV = "mv",
  START = "start",
  RUN_SCRIPT = "runscript",
  SHUTDOWN = "shutdown",
  PS = "ps",
  HELP = "help",
  CONTROL_C = "Control^C",
}

export enum ShellSessionFailedReason {
  OPEN_SHELL_COMMAND = "shellSessionOpeningFailed",
  CLIENT_SESSION_CLOSED = "shellSessionClosed",
  COMMAND_FAILED = "commandFailed",
  TOO_MANY_SHELL_SESSIONS = "tooManyShellSessionsOpened",
}
