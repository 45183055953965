export enum ProxySecurityMode {
  WARNING = "warning",
  BLOCK = "block",
}

export enum ProxyDirection {
  INBOUND = "inbound",
  OUTBOUND = "outbound",
}

export enum EmailProxyAction {
  ADD_DOMAIN = "addDomain",
  EDIT_DOMAIN = "editDomain",
  REMOVE_DOMAIN = "removeDomain",
  TEST_DOMAIN = "testDomain",
}

export enum ProxyStatus {
  NOT_TESTED = "notTested",
  PENDING = "pending",
  PASSED = "passed",
  FAILED = "failed",
}

export enum ProxyError {
  DOMAIN_ALREADY_EXISTS = "domainAlreadyExists",
  PROXY_VERIFICATION_LINK_EXPIRED = "expiredProxyLink",
}
