<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader :loading="showSkeletonLoader" :type="coronetSkeletonLoaderTypes.CARD">
      <div class="mb-3">
        <v-breadcrumbs
          data-testid="alerts-page-breadcrumbs"
          :items="breadCrumbsItems"
          divider="<"
        ></v-breadcrumbs>
      </div>
      <div class="d-flex align-center">
        <span class="headline5">{{ $t("messages.title") }}</span>
        <v-chip class="ml-2 chip--counter" data-testid="alerts-page-alert-count">
          {{ total }}
        </v-chip>
      </div>
      <message-widget
        v-for="(message, index) in messages"
        :key="message.id"
        :data-testid="`alert-widget-container-${index}`"
        :message="message"
        class="mt-4"
      ></message-widget>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import MessageWidget from "@/components/MessageWidget.vue";
import { useMessagesStore } from "@/_store/messages.module";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { MessageWidget },
  setup() {
    let alertInterval: ReturnType<typeof setInterval>;
    const i18n = useI18n();
    const messagesStore = useMessagesStore();
    const { messages, total, showSkeletonLoader } = storeToRefs(messagesStore);

    onMounted(async () => {
      await messagesStore.getMessages();
      alertInterval = setInterval(() => {
        messagesStore.getMessages();
      }, 60000);
    });

    onUnmounted(() => {
      clearInterval(alertInterval);
    });

    return {
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToDashboard"),
          disabled: false,
          to: { path: "/portal/dashboard" },
        },
      ],
      coronetSkeletonLoaderTypes,
      messages,
      total,
      showSkeletonLoader,
    };
  },
});
</script>

<style scoped></style>
