<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader :loading="showSkeletonLoader" type="table" color="transparent">
      <table-wrapper>
        <v-data-table-server
          class="white"
          :headers="headers"
          v-model:expanded="expanded"
          :items="roles"
          :show-expand="true"
          :fixed-header="true"
          :loading="loading"
          :page="pagination.page + 1"
          :items-length="rolesTotal"
          @update:options="
            handlePaginationChange({ pageSize: $event.itemsPerPage, page: $event.page - 1 })
          "
          :items-per-page="pagination.pageSize"
          color="primary"
        >
          <template #item="{ item, toggleExpand, isExpanded, internalItem }">
            <tr>
              <td class="w-5">
                <v-icon
                  class="mr-2 cursor-pointer"
                  icon="$dropdown"
                  color="primary"
                  :class="{ rotated: isExpanded(internalItem) }"
                  @click="toggleExpand(internalItem)"
                />
              </td>
              <td class="subtitle2 w-45">{{ item.name }}</td>
              <td class="body2">{{ item.assigneesCount }}</td>
              <td class="w-5">
                <v-menu bottom left>
                  <template v-slot:activator="{ props }">
                    <v-icon icon="$dots" class="item-clickable" v-bind="props"></v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(action, index) in getActions(item)"
                      :key="index"
                      :value="index"
                      @click="onWorkspaceActionClick(action, item)"
                    >
                      <v-list-item-title>
                        {{ $t(`accessControlPage.roles.table.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-row="{ item }">
            <expanded-role-details
              v-if="roleDetails[item.id]"
              :item="item"
              :permissions="roleDetails[item.id].permissions"
              :assignees="roleDetails[item.id].assignees"
            />
          </template>
        </v-data-table-server>
      </table-wrapper>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import TableWrapper from "@/components/TableWrapper.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { defineComponent, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import ExpandedRoleDetails from "@/components/roles/RoleDetails.vue";
import type { RolesAction, RoleType } from "@/constants/roles";
import { useRolesTab } from "@/composables/useRolesTab";

export default defineComponent({
  props: {
    roleType: {
      type: String as PropType<RoleType>,
      required: true,
    },
  },
  components: { ExpandedRoleDetails, TableWrapper },
  setup(props) {
    const i18n = useI18n();

    const {
      roles,
      roleDetails,
      expanded,
      showPagination,
      page,
      totalPages,
      loading,
      showSkeletonLoader,
      pagination,
      rolesTotal,
      handlePaginationChange,
      getActions,
      onWorkspaceActionClick,
    } = useRolesTab<RolesAction>(props.roleType);

    return {
      roles,
      roleDetails,
      expanded,
      showPagination,
      page,
      totalPages,
      loading,
      showSkeletonLoader,
      pagination,
      coronetSkeletonLoaderTypes,
      handlePaginationChange,
      getActions,
      onWorkspaceActionClick,
      rolesTotal,
      headers: [
        { title: "", key: "data-table-expand", sortable: false },
        {
          title: i18n.t("accessControlPage.roles.table.headers.role"),
          key: "name",
          sortable: false,
        },
        {
          title: i18n.t("accessControlPage.roles.table.headers.assigneesCount"),
          key: "assigneesCount",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
    };
  },
});
</script>

<style lang="scss" scoped></style>
