<template>
  <div>
    <v-btn
      color="primary"
      rounded
      elevation="0"
      size="large"
      class="add-btn"
      @click="openAddRoleDialog()"
    >
      <v-icon color="white" class="mr-2" icon="$add"></v-icon>
      <span>{{ $t("backOffice.roles.table.actions.addGlobalRole") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { emptyGlobalPermissions, GlobalRolesAction, RoleType } from "@/constants/roles";
import { ModalWidth } from "@/constants/modals";
import { useRolesStore } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import AddGlobalRoleModal from "@/components/modals/add-role/AddGlobalRoleModal.vue";

export default defineComponent({
  setup() {
    const dialogsStore = useDialogsStore();
    const rolesStore = useRolesStore(RoleType.GLOBAL);
    const openAddRoleDialog = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: GlobalRolesAction.ADD,
        item: { ...emptyGlobalPermissions },
        component: AddGlobalRoleModal,
        width: ModalWidth.LARGE,
        callback: rolesStore.addRole,
      });
      dialogsStore.openDialog({ ...dialogConfig, scrollable: true });
    };

    return {
      openAddRoleDialog,
    };
  },
});
</script>

<style scoped></style>
