<template>
  <div class="bar">
    <div class="wrapper">
      <v-tabs hide-slider :height="32" @update:modelValue="onTabUpdate($event as number)">
        <template v-for="(tab, i) of tabs" :key="i">
          <div class="mr-8 page-tab-separator" v-if="tab.hasSeparator"></div>
          <v-tab
            :ripple="false"
            class="mr-8"
            :to="tab.route"
            :data-testid="tab.testId"
            :disabled="!!tab.disabled"
          >
            <template v-if="withTranslation">{{ $t(tab.name) }}</template>
            <template v-else>{{ tab.name }}</template>
            <div class="ml-1">
              <beta-chip :addon-or-module="tab.addonOrModule"></beta-chip>
            </div>
          </v-tab>
        </template>
      </v-tabs>
      <slot name="tabsArea"></slot>
    </div>
    <slot name="afterTabsArea"></slot>
  </div>
</template>
<script lang="ts">
import BetaChip from "@/components/BetaChip.vue";
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { RouteLocationRaw } from "vue-router";

export interface CoroTab {
  id: number;
  name: string;
  route: RouteLocationRaw;
  testId?: string;
  disabled?: boolean;
  addonOrModule?: string;
  hasSeparator?: boolean;
}

export default defineComponent({
  components: {
    BetaChip,
  },
  props: {
    tabs: {
      type: Object as PropType<CoroTab[]>,
      required: true,
    },
    withTranslation: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:tab"],
  setup(props, { emit }) {
    const onTabUpdate = (index: number) => {
      emit("update:tab", props.tabs[index]);
    };

    return {
      onTabUpdate,
    };
  },
});
</script>
<style lang="scss" scoped>
.bar {
  display: flex;
  width: 100%;
  .wrapper {
    display: flex;
    flex-grow: 1;
    z-index: 40;
  }
}
:deep(.v-tabs) {
  .v-btn {
    box-sizing: content-box;
    color: rgba(var(--v-theme-primary), 0.65) !important;
    border-radius: 0px !important;

    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.1px;
    text-align: left;
    text-transform: unset;
    padding: unset !important;
    min-width: 0 !important;
    .v-btn__content {
      font-size: 16px !important;
    }
  }
  .v-btn:hover {
    color: rgb(var(--v-theme-orange-base)) !important;

    & > .v-btn__overlay {
      background: transparent !important;
      opacity: 0 !important;
    }
  }
  .v-tab--selected {
    color: rgb(var(--v-theme-orange-base)) !important;
    border-bottom: 3px solid rgb(var(--v-theme-orange-base));
    box-sizing: border-box;
  }
}

.page-tab-separator {
  border-left: 1px solid rgb(var(--v-theme-indigo-light));
  height: 20px;
  margin-top: 8px;
}
</style>
