import {
  DevicePostureChoice,
  DevicePostureOsType,
  DevicePostureSettings,
  DeviceVulnerability,
  WifiPostureConnectionType,
} from "@/constants/devices";

import { defineStore } from "pinia";
import api from "@/_helpers/api";

import {
  convertRegularPolicyForBackend,
  convertUsbLockdownPolicyForBackend,
  convertUserAccountPolicyForBackend,
  convertWifiPolicyForBackend,
  mapPostureSettings,
} from "@/_store/device-posture/adapters";
import { axiosInstance } from "@/plugins/https";
import type { AxiosRequestConfig } from "axios";
import type { DeviceLabel } from "@/_store/devices-settings.module";

export type DevicePostureCombinedType =
  | (DevicePostureRegularItem & { displayValue: string })
  | (DevicePostureWifiItem & { displayValue: string })
  | (DevicePostureUserAccountItem & { displayValue: undefined })
  | (DevicePostureUsbLockdownItem & { displayValue: string });

const defaultDevicesPostureSettingsState = {
  settings: [],
  devicesPostureRegularSettings: null,
  devicesPostureWifiSettings: null,
  devicesPostureUserAccountSettings: null,
  devicesPostureUsbLockdownSettings: null,
  showSkeletonLoader: true,
  loading: false,
};

export interface DevicePostureRegularItem {
  id?: string;
  postureValue: DevicePostureChoice;
  labels: DeviceLabel[];
}

export type RegularDevicePostureSettings = {
  [osType in DevicePostureOsType]: Record<DeviceVulnerability, DevicePostureRegularItem[]>;
};

export interface DevicePostureUserAccountItem {
  id?: string;
  passwordHistLen: number;
  passwordMustMeetComplexity: boolean;
  lockoutDuration: number;
  lockoutThreshold: number;
  maxPasswdAge?: number;
  minPasswdAge?: number;
  minPasswdLen?: number;
  osType?: string;
  labels: DeviceLabel[];
}

export interface DevicePostureWifiItem {
  id?: string;
  networks: string[];
  connectionType: WifiPostureConnectionType;
  labels: DeviceLabel[];
}

export interface DevicePostureUsbLockdownItem {
  id?: string;
  blockMassStorage: boolean;
  blockPortableDevices: boolean;
  osType?: string;
  labels: DeviceLabel[];
}

export type DevicePostureUserAccountSettings = {
  [osType in DevicePostureOsType]: DevicePostureUserAccountItem[];
};

export type DevicePostureWifiSettings = {
  [osType in DevicePostureOsType]: DevicePostureWifiItem[];
};

export type DevicePostureUsbLockdownSettings = {
  [osType in DevicePostureOsType]: DevicePostureUsbLockdownItem[];
};

export interface DevicePostureAdaptedItem {
  tableItemId: string;
  policyType: DevicePostureSettings;
  osType: DevicePostureOsType;
  policies: DevicePostureCombinedType[];
  policiesQuantity: number;
  position: number;
}

interface DevicesPostureSettingsState {
  settings: DevicePostureAdaptedItem[];
  devicesPostureRegularSettings: RegularDevicePostureSettings | null;
  devicesPostureWifiSettings: DevicePostureWifiSettings | null;
  devicesPostureUserAccountSettings: DevicePostureUserAccountSettings | null;
  devicesPostureUsbLockdownSettings: DevicePostureUsbLockdownSettings | null;
  showSkeletonLoader: boolean;
  loading: boolean;
}

export interface DevicePostureFormData {
  osType: DevicePostureOsType;
  policyType: DevicePostureSettings | DeviceVulnerability;
  postureValue: DevicePostureChoice;
  connectionType: WifiPostureConnectionType;
  blockPortableDevices: boolean;
  blockMassStorage: boolean;
  networks: string[];
  labels: DeviceLabel[];
  passwordMustMeetComplexity: boolean;
  minPasswdLen: number;
  minPasswdAge: number;
  maxPasswdAge: number;
  passwordHistLen: number;
  lockoutDuration: number;
  lockoutThreshold: number;
  id?: string;
}

export const useDevicesPostureStore = defineStore("devices-posture", {
  state: (): DevicesPostureSettingsState => ({ ...defaultDevicesPostureSettingsState }),
  actions: {
    async addDevicePosturePolicy(payload: DevicePostureFormData) {
      const request = getAddRequestConfiguration(payload);

      try {
        await axiosInstance.request(request as AxiosRequestConfig);
        await this.fetchSettingsForPolicyType(payload.policyType as DevicePostureSettings);
      } catch (error) {
        console.error(error);
      }
    },
    async editDevicePosturePolicy(payload: DevicePostureFormData) {
      // different policies use different endpoints with different data structure
      const request = getEditRequestConfiguration(payload);

      try {
        await axiosInstance.request(request as AxiosRequestConfig);
        await this.fetchSettingsForPolicyType(payload.policyType as DevicePostureSettings);
      } catch (error) {
        console.error(error);
      }
    },
    async deleteDevicePosturePolicy(payload: DevicePostureFormData) {
      // different policies use different endpoints with different data structure
      const request = getDeleteRequestConfiguration(payload);

      try {
        await axiosInstance.request(request);
        await this.fetchSettingsForPolicyType(payload.policyType as DevicePostureSettings);
      } catch (error) {
        console.error(error);
      }
    },
    async getAllPostureSettings(showSkeletonLoader = false) {
      this.loading = true;
      this.showSkeletonLoader = showSkeletonLoader;

      try {
        const [
          regularPostureSettings,
          userAccountPostureSettings,
          wifiPostureSettings,
          usbLockdownSettings,
        ] = await Promise.all([
          axiosInstance.request(api.getDevicesPostureRegularSettings),
          axiosInstance.request(api.getDevicesPostureUserAccountSettings),
          axiosInstance.request(api.getDevicesPostureWifiSettings),
          axiosInstance.request(api.getDevicesPostureUsbLockdownSettings),
        ]);

        this.devicesPostureRegularSettings = regularPostureSettings.data;
        this.devicesPostureUserAccountSettings = userAccountPostureSettings.data;
        this.devicesPostureWifiSettings = wifiPostureSettings.data;
        this.devicesPostureUsbLockdownSettings = usbLockdownSettings.data;

        this.settings = mapPostureSettings(
          regularPostureSettings.data,
          userAccountPostureSettings.data,
          wifiPostureSettings.data,
          usbLockdownSettings.data
        );
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
      this.showSkeletonLoader = false;
    },
    async getDevicesPostureRegularSettings(showSkeletonLoader = false) {
      this.loading = true;

      const request = {
        ...api.getDevicesPostureRegularSettings,
      };
      if (showSkeletonLoader) {
        this.showSkeletonLoader = true;
      }

      try {
        const res = await axiosInstance.request(request);

        this.devicesPostureRegularSettings = res.data;
        this.settings = mapPostureSettings(
          this.devicesPostureRegularSettings!,
          this.devicesPostureUserAccountSettings!,
          this.devicesPostureWifiSettings!,
          this.devicesPostureUsbLockdownSettings!
        );
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async getDevicesPostureWiFiSettings() {
      this.loading = true;
      const request = {
        ...api.getDevicesPostureWifiSettings,
      };

      try {
        const res = await axiosInstance.request(request);
        this.devicesPostureWifiSettings = res.data;
        this.settings = mapPostureSettings(
          this.devicesPostureRegularSettings!,
          this.devicesPostureUserAccountSettings!,
          this.devicesPostureWifiSettings!,
          this.devicesPostureUsbLockdownSettings!
        );
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
    },
    async getDevicesPostureUserAccountSettings() {
      this.loading = true;
      const request = {
        ...api.getDevicesPostureUserAccountSettings,
      };

      try {
        const res = await axiosInstance.request(request);

        this.devicesPostureUserAccountSettings = res.data;
        this.settings = mapPostureSettings(
          this.devicesPostureRegularSettings!,
          this.devicesPostureUserAccountSettings!,
          this.devicesPostureWifiSettings!,
          this.devicesPostureUsbLockdownSettings!
        );
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async getDevicesPostureUsbLockdownSettings() {
      this.loading = true;
      const request = {
        ...api.getDevicesPostureUsbLockdownSettings,
      };

      try {
        const res = await axiosInstance.request(request);

        this.devicesPostureUsbLockdownSettings = res.data;
        this.settings = mapPostureSettings(
          this.devicesPostureRegularSettings!,
          this.devicesPostureUserAccountSettings!,
          this.devicesPostureWifiSettings!,
          this.devicesPostureUsbLockdownSettings!
        );
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async fetchSettingsForPolicyType(policyType: DevicePostureSettings): Promise<void> {
      let method;
      switch (policyType) {
        case DevicePostureSettings.WIFI:
          method = this.getDevicesPostureWiFiSettings;
          break;
        case DevicePostureSettings.USER_ACCOUNT_POLICY:
          method = this.getDevicesPostureUserAccountSettings;
          break;
        case DevicePostureSettings.USB_LOCKDOWN:
          method = this.getDevicesPostureUsbLockdownSettings;
          break;
        default:
          method = this.getDevicesPostureRegularSettings;
          break;
      }

      await method();
    },
    async getDeviceLabels(enrollmentCode: string): Promise<DeviceLabel[]> {
      const request = {
        ...api.getDeviceInfo(enrollmentCode),
      };
      try {
        const res = await axiosInstance.request(request);

        return res.data.labels;
      } catch (error) {
        console.error(error);

        return [];
      }
    },
  },
});

function getAddRequestConfiguration(payload: DevicePostureFormData) {
  switch (payload.policyType) {
    case DevicePostureSettings.WIFI:
      return {
        ...api.addDevicePostureWifiSetting(),
        data: {
          [payload.osType]: [convertWifiPolicyForBackend(payload)],
        },
      };
    case DevicePostureSettings.USER_ACCOUNT_POLICY:
      return {
        ...api.addDevicePostureUserAccountSetting(),
        data: convertUserAccountPolicyForBackend(payload),
      };
    case DevicePostureSettings.USB_LOCKDOWN:
      return {
        ...api.addDevicesPostureUsbLockdownSettings(),
        data: convertUsbLockdownPolicyForBackend(payload),
      };
    default:
      return {
        ...api.addDevicePostureRegularSetting(),
        data: convertRegularPolicyForBackend(payload),
      };
  }
}

function getEditRequestConfiguration(payload: DevicePostureFormData) {
  switch (payload.policyType) {
    case DevicePostureSettings.WIFI:
      return {
        ...api.updateDevicePostureWifiSetting(payload.id!),
        data: convertWifiPolicyForBackend(payload),
      };
    case DevicePostureSettings.USER_ACCOUNT_POLICY:
      return {
        ...api.updateDevicePostureUserAccountSetting(payload.id!),
        data: convertUserAccountPolicyForBackend(payload),
      };
    case DevicePostureSettings.USB_LOCKDOWN:
      return {
        ...api.updateDevicePostureUsbLockdownSetting(payload.id!),
        data: convertUsbLockdownPolicyForBackend(payload),
      };
    default:
      return {
        ...api.updateDevicePostureRegularSetting(payload.id!),
        data: convertRegularPolicyForBackend(payload),
      };
  }
}

function getDeleteRequestConfiguration(payload: DevicePostureFormData) {
  switch (payload.policyType) {
    case DevicePostureSettings.WIFI:
      return {
        ...api.deleteDevicesPostureWifiSettings(payload.id!),
      };
    case DevicePostureSettings.USER_ACCOUNT_POLICY:
      return {
        ...api.deleteDevicesPostureUserAccountSettings(payload.id!),
      };
    case DevicePostureSettings.USB_LOCKDOWN:
      return {
        ...api.deleteDevicesPostureUsbLockdownSettings(payload.id!),
      };
    default:
      return {
        ...api.deleteDevicePostureSetting(payload.id!),
      };
  }
}
