<template>
  <div class="content-sm margin-auto">
    <div class="d-flex justify-center mt-16">
      <template v-if="account.requestInProgress">
        <v-progress-circular color="primary" indeterminate size="120" />
        <div class="mt-10 headline5">
          {{ $t("sso.loginInProcess") }}
        </div>
      </template>
      <empty-state v-else-if="!account.requestInProgress && account.error">
        <template #icon>
          <v-icon size="80" icon="$warning"></v-icon>
        </template>
        <template #description>
          <span v-html="account.error"></span>
        </template>
      </empty-state>
    </div>
  </div>
</template>

<script lang="ts">
import EmptyState from "@/components/EmptyState.vue";
import { defineComponent, onMounted } from "vue";
import { useAccountStore } from "@/_store";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";
import { storeToRefs } from "pinia";

export default defineComponent({
  components: { EmptyState },
  setup() {
    const accountStore = useAccountStore();
    const { account } = storeToRefs(accountStore);
    const router = useRouter();
    onMounted(async () => {
      accountStore.setRequestInProgress(true);
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (!accountStore.logged && token) {
        await accountStore.loginViaSSO(token);
      }

      if (!token) {
        router.push({ name: RouteName.LOGIN }).catch(() => {});
        return;
      }

      router.replace({}).catch(() => {});
    });
    return {
      account,
    };
  },
});
</script>
