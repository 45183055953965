export const coronetSkeletonLoaderTypes = {
  USERS_SETTINGS: "article, actions, table-thead, table-tbody, table-tfoot",
  SECURITY_SETTINGS: "list-item-three-line@3",
  SECURITY_SETTINGS_DENSE: "list-item-three-line@6",
  ACCOUNT_SETTINGS: "article, button",
  DEFAULT_TABLE: "table-thead, table-tbody",
  CLOUD_APPS_SETTINGS: "chip, heading, text, list-item-avatar@6",
  HIGHLIGHTABLE_TABLE: "actions, table-thead, table-tbody, table-tfoot",
  OVERVIEW_WIDGETS: "list-item-three-line@5",
  CARD: "heading, list-item-three-line@4",
};
