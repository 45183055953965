<template>
  <div class="mb-4">
    <div class="mb-4">
      {{ $t("modals.addNewGroup.description") }}
    </div>
    <v-form v-model="valid" validate-on="lazy input" ref="form">
      <v-autocomplete
        v-model="localValue.groups"
        class="mb-12"
        data-testid="add-group-modal-autocomplete"
        :loading="dataLoading"
        :items="groups"
        :label="$t('modals.addNewGroup.label')"
        :placeholder="$t('modals.addNewGroup.placeholder')"
        :rules="[atLeastOneIsRequired()]"
        maxlength="150"
        variant="outlined"
        multiple
        no-filter
        return-object
        clear-on-select
        :hide-no-data="hideNoData"
        item-value="groupId"
        :no-data-text="$t('modals.addNewGroup.noData')"
        v-model:search="currentInputValue"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            close-icon="$closeCircle"
            :text="item.title"
            color="indigo-faint"
          >
            <div class="d-flex align-center">
              <coro-icon class="service-icon-sm" :icon-name="item.raw.service"></coro-icon>
              <span class="ml-1 mr-2">{{ item.raw.name }}</span>
            </div>
          </v-chip>
        </template>
        <template #item="{ props, item }">
          <v-list-item v-bind="props" title="">
            <div class="d-flex align-center" :data-testid="`item-${camelCase(item.raw.service)}`">
              <coro-icon class="service-icon" :icon-name="item.raw.service"></coro-icon>
              <span class="ml-1">{{ item.raw.name }}</span>
            </div>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import CoroIcon from "@/components/CoroIcon.vue";
import camelCase from "lodash/camelCase";
import { useGroupsSettingsTabStore } from "@/_store/users-settings/user-groups-tab.module";
import type { VuetifyFormRef } from "@/types";
import { atLeastOneIsRequired } from "@/_helpers/validators";

export default defineComponent({
  components: {
    CoroIcon,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const userGroupsStore = useGroupsSettingsTabStore();
    const form = ref<VuetifyFormRef>();
    const groups = ref<any[]>([]);
    const currentInputValue = ref("");
    const dataLoading = ref(false);
    const valid = ref<boolean>(false);
    let timer: ReturnType<typeof setTimeout>;
    const localValue = ref({
      groups: [],
    });

    const hideNoData = () => {
      return dataLoading.value ? true : !currentInputValue.value && groups.value.length === 0;
    };

    watch(currentInputValue, async (val: string) => {
      clearTimeout(timer);
      dataLoading.value = true;
      // delay new call 500ms
      timer = setTimeout(() => {
        userGroupsStore.searchUserGroups(val).then((response) => {
          groups.value = [...localValue.value.groups, ...response.data];
          dataLoading.value = false;
        });
      }, 500);
    });

    watch(
      localValue,
      (value) => {
        emit("update:localValue", value);
      },
      { deep: true }
    );

    const validate = async () => {
      const validationResult = await form.value?.validate();
      emit("update:valid", !!validationResult?.valid);
      return validationResult?.valid;
    };

    return {
      groups,
      currentInputValue,
      dataLoading,
      localValue,
      form,
      valid,
      hideNoData,
      atLeastOneIsRequired,
      camelCase,
      validate,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  width: 40px;
  height: 40px;
}

.service-icon-sm {
  width: 25px;
  height: 25px;
}

:deep(*) {
  .v-field .v-chip {
    --v-chip-height: 32px;
  }
}
</style>
