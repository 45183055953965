<template>
  <div>
    <div class="pa-4">
      <div v-if="valid === false" class="error-block d-flex align-center">
        <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
        <span class="body2 text-red-dark">{{ $t("validations.general") }}</span>
      </div>
      <v-card class="account-form__wrapper">
        <v-form ref="form" v-model="valid" class="pa-4">
          <div class="subtitle1">{{ $t("myAccount.profile.title") }}</div>
          <div class="mt-8 d-flex align-center">
            <v-text-field
              v-model="localValue.firstName"
              class="mr-4"
              variant="outlined"
              :rules="[required()]"
              :label="$t('myAccount.profile.firstName.label')"
            />
            <v-text-field
              v-model="localValue.lastName"
              variant="outlined"
              :rules="[required()]"
              :label="$t('myAccount.profile.lastName.label')"
              @keyup.enter="onUpdateProfile()"
            />
          </div>
          <v-text-field
            v-model="account.email"
            class="mt-4"
            variant="outlined"
            disabled
            :label="$t('myAccount.profile.email.label')"
            :hint="$t('myAccount.profile.email.hint')"
            persistent-hint
          />
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              rounded
              class="mt-6 mb-2"
              :loading="loading"
              :disabled="!valid"
              @click="onUpdateProfile()"
            >
              {{ $t("myAccount.profile.updateProfile") }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { type ProfileData, useMyAccountStore } from "@/_store/my-account.module";
import { required } from "@/_helpers/validators";
import type { VuetifyFormRef } from "@/types";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  setup() {
    const localValue = ref<ProfileData>({ allowMfa: false });
    const valid = ref(true);
    const loading = ref(false);
    const form = ref<VuetifyFormRef>();
    const showValidationAlert = ref(false);
    const myAccountStore = useMyAccountStore();
    const accountStore = useAccountStore();
    const { myAccount } = storeToRefs(myAccountStore);
    const { account } = storeToRefs(accountStore);

    const onUpdateProfile = async () => {
      const validationResult = await form.value?.validate();
      if (validationResult?.valid) {
        loading.value = true;
        await myAccountStore.updateProfileData(localValue.value!);
        loading.value = false;
      }
    };

    watch(
      () => myAccount.value.profileData,
      (value) => {
        localValue.value = cloneDeep(value);
      },
      { deep: true, immediate: true }
    );

    return {
      loading,
      localValue,
      required,
      valid,
      form,
      showValidationAlert,
      myAccount,
      account,
      onUpdateProfile,
    };
  },
});
</script>
