<template>
  <div>
    <div v-if="config?.item?.description" class="mb-4">
      {{ config.item.description }}
    </div>
    <v-form v-model="valid" validate-on="lazy input" ref="form">
      <v-combobox
        v-model="localValue.users"
        data-testid="add-user-modal-combobox"
        class="mt-3 mb-12 pb-12 coro-scrollable-combobox"
        :loading="dataLoading"
        :items="users"
        :label="$t('modals.addUsers.label')"
        :placeholder="$t('modals.addUsers.placeholder')"
        :rules="emailRules"
        v-model:search="currentInputValue"
        variant="outlined"
        multiple
        no-filter
        return-object
        hide-no-data
        item-value="userId"
        item-title="email"
        @update:modelValue="handleChange()"
        @blur="handleBlur()"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            :color="getChipColor(item.raw.email)"
            close-icon="$closeCircle"
            :text="item.title"
          >
            <div class="d-flex align-center">
              {{ item.raw.email }}
            </div>
          </v-chip>
        </template>
        <template #item="{ props, item }">
          <v-list-item v-bind="props" title="">
            <div class="d-flex align-center" :data-testid="`item-${camelCase(item.raw.email)}`">
              <span class="ml-1">{{ item.raw.email }}</span>
            </div>
          </v-list-item>
        </template>
      </v-combobox>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { useAddUserModal } from "@/composables/useAddUsersModal";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const onUserChanged = (nevVal: string) => {
      emit("update:localValue", nevVal);
    };

    const onValidationChange = (isValid: boolean) => {
      emit("update:valid", isValid);
    };

    const {
      users,
      form,
      valid,
      currentInputValue,
      dataLoading,
      localValue,
      emailRules,
      handleChange,
      handleBlur,
      getChipColor,
      camelCase,
      validate,
    } = useAddUserModal(onUserChanged, onValidationChange);

    return {
      form,
      users,
      valid,
      currentInputValue,
      dataLoading,
      localValue,
      emailRules,
      handleChange,
      handleBlur,
      getChipColor,
      camelCase,
      validate,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-field .v-chip {
    --v-chip-height: 32px;
  }
}
</style>
