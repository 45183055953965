<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      class="skeleton-loader--cloud-apps"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.CLOUD_APPS_SETTINGS"
    >
      <template v-if="connectedServices.length">
        <div class="body1 mb-4">
          {{ $t("cloudSecurity.description") }}
        </div>
        <v-card>
          <table-wrapper>
            <v-table>
              <template #default>
                <tbody>
                  <tr
                    v-for="item in connectedServices"
                    :key="item.name"
                    class="service-row"
                    :data-testid="`service-row-${item.name}`"
                  >
                    <td>
                      <div class="d-flex">
                        <coro-icon class="service-icon mr-4" :icon-name="item.name"></coro-icon>
                        <div>
                          <div class="service-name">
                            {{ $t(`services.${item.name}`) }}
                          </div>
                          <div :class="`body2 ${item.serviceStatus}`">
                            {{ $t(`services.status.${item.serviceStatus}`) }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="text-right" @click="$event.stopPropagation()">
                      <v-btn
                        color="primary"
                        rounded
                        min-width="108"
                        @click="goToAccessPermissions(item.name)"
                      >
                        {{ $t("cloudSecurity.accessPermissions") }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
          </table-wrapper>
        </v-card>
      </template>
      <empty-state v-else class="margin-auto mt-12 w-65">
        <template #icon>
          <v-icon :size="80" icon="$cloudApps"> </v-icon>
        </template>
        <template #description>
          <div class="headline5 mb-3 mt-3">
            {{ $t("cloudSecurity.noItems.title") }}
          </div>
        </template>
        <template #subtitle>
          {{ $t("cloudSecurity.noItems.description") }}
        </template>
        <template #button>
          <v-btn
            class="mb-4"
            color="primary"
            rounded
            size="large"
            :to="{
              name: RouteName.CLOUD_APPS_PAGE,
              query: { noServicesConnected: 'true' },
            }"
          >
            {{ $t("cloudSecurity.noItems.connectBtn") }}
          </v-btn>
        </template>
      </empty-state>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { Service, ServiceAction } from "@/constants/cloud-apps";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";

import TableWrapper from "@/components/TableWrapper.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { useI18n } from "vue-i18n";
import EmptyState from "@/components/EmptyState.vue";
import { useRouter } from "vue-router";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { defineComponent, onMounted } from "vue";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    EmptyState,
    TableWrapper,
    CoroIcon,
  },
  setup() {
    const i18n = useI18n();
    const router = useRouter();
    const cloudAppsStore = useCloudAppsStore();
    const { connectedServices, showSkeletonLoader } = storeToRefs(cloudAppsStore);
    const goToAccessPermissions = (service: Service) => {
      router.push({
        name: RouteName.CLOUD_SECURITY_ACCESS_PERMISSIONS_PAGE,
        query: { service },
      });
    };

    onMounted(async () => {
      await cloudAppsStore.getServices();
    });

    return {
      ServiceAction,
      coronetSkeletonLoaderTypes,
      connectedServices,
      showSkeletonLoader,
      RouteName,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      goToAccessPermissions,
    };
  },
});
</script>

<style lang="scss" scoped>
.no-cloud-apps {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 620px;

  &__item {
    padding: 0 15px;
    max-width: 272px;
    box-sizing: initial;
  }
}

.service-row {
  height: 80px;
}

.service-icon {
  height: 48px;
  width: 48px;
}

.service-name {
  font-size: 18px;
  line-height: 24px;
}

.service-status {
  font-size: 16px;
}
</style>
