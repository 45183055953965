import { subDays, subMonths, getTime, startOfMonth, endOfMonth, format } from "date-fns";
import { i18n } from "@/plugins/i18n";

export const getCurrentDate = () => format(new Date(), "yyyy-MM-dd");
export const currentDate = format(new Date(), "yyyy-MM-dd");
export const firstDayLastMonth = format(
  getTime(startOfMonth(subMonths(new Date(), 1))),
  "yyyy-MM-dd"
);
export const lastDayLastMonth = format(getTime(endOfMonth(subMonths(new Date(), 1))), "yyyy-MM-dd");
export const firstDayThisMonth = format(getTime(startOfMonth(new Date())), "yyyy-MM-dd");
export const lastDayThisMonth = format(getTime(endOfMonth(new Date())), "yyyy-MM-dd");
export const sixDaysAgo = format(getTime(subDays(new Date(), 6)), "yyyy-MM-dd");
export const twoDaysAgo = format(getTime(subDays(new Date(), 2)), "yyyy-MM-dd");
export const thirtyDaysAgo = format(getTime(subDays(new Date(), 29)), "yyyy-MM-dd");
export const yesterday = format(getTime(subDays(new Date(), 1)), "yyyy-MM-dd");
export const getNinetyDaysAgo = () => format(getTime(subDays(new Date(), 90)), "yyyy-MM-dd");
export const getNDaysAgo = (amount: number) =>
  format(getTime(subDays(new Date(), amount)), "yyyy-MM-dd");
export const timeRangePresets = [
  {
    label: i18n.global.t("dateRangePicker.today"),
    range: [getCurrentDate(), currentDate],
  },
  {
    label: i18n.global.t("dateRangePicker.yesterday"),
    range: [yesterday, yesterday],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThreeDays"),
    range: [twoDaysAgo, getCurrentDate()],
  },
  {
    label: i18n.global.t("dateRangePicker.lastSevenDays"),
    range: [sixDaysAgo, getCurrentDate()],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThirtyDays"),
    range: [thirtyDaysAgo, getCurrentDate()],
  },
  {
    label: i18n.global.t("dateRangePicker.thisMonth"),
    range: [firstDayThisMonth, lastDayThisMonth],
  },
];
