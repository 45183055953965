<template>
  <div class="content-md margin-auto">
    <v-card>
      <v-row class="h-100 mx-0">
        <v-col class="pl-0 pr-0 list-with-preview__list" :cols="listColumns">
          <v-skeleton-loader
            :loading="showSkeletonLoader"
            type="list-item-avatar-three-line@15"
            class="skeleton-loader--table"
          >
            <table-wrapper class="list-with-preview">
              <slot name="list"></slot>
            </table-wrapper>
          </v-skeleton-loader>
        </v-col>
        <v-col
          id="scroll-container"
          class="list-with-preview__preview-container"
          :cols="12 - listColumns"
        >
          <v-skeleton-loader
            :loading="showSkeletonLoader"
            type="list-item-three-line@15"
            class="skeleton-loader--table"
          >
            <slot name="preview"></slot>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import TableWrapper from "@/components/TableWrapper.vue";

export default {
  components: { TableWrapper },
  props: {
    showSkeletonLoader: {
      type: Boolean,
      default: false,
    },
    listColumns: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-with-preview {
  &__preview-container {
    overflow: auto;
    padding: 0 4px 4px 4px;
    min-height: 70vh;
  }

  &__list {
    border-right: 1px solid rgb(var(--v-theme-indigo-faint));
  }
}

:deep(*) {
  .v-table {
    box-shadow: unset !important;
  }

  .v-skeleton-loader {
    background-color: unset !important;
  }
}
</style>
