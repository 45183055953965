export enum UserAction {
  ADD_USERS = "addUsers",
  REMOVE_USERS = "removeUsers",
  ADD_TO_EXCEPTION = "addToException",
  ADD_ALL_USERS = "addAllUsers",
  ADD_ALL_USERS_CONFIRMATION = "addAllUsersConfirmation",
  ADD_USERS_TO_PROTECTION_FROM_REPORT = "addUsersToProtectionFromReport",
  IMPORT_USERS_FROM_CSV = "importUsersFromCsv",
  DOWNLOAD_USERS_EXAMPLE_CSV = "downloadUsersExampleCsv",
  REMOVE_EXCEPTION = "removeException",
}

export enum UserGroupAction {
  ADD_NEW_GROUP = "addNewGroup",
  REMOVE_GROUPS = "removeGroups",
}

export enum ExceptionsAction {
  ADD_NEW_EXCEPTION = "addNewException",
  REMOVE_EXCEPTION = "removeException",
}
