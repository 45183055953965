<template>
  <div>
    <div v-if="restrictedModules.length > 0" class="warning-block pt-3 pb-3 mb-3">
      <div class="d-flex align-center w-100">
        <v-icon class="mr-3 ml-4" icon="$warning" />
        <span class="subtitle1">{{ $t(`modals.${config.action}.permissionsBanner`) }}</span>
      </div>
      <ul class="ml-12 mt-1">
        <li v-for="module in restrictedModules" :key="module" class="body2 ml-5">
          {{ $t(`subscriptions.modules.${module}`) }}
        </li>
      </ul>
    </div>
    <div v-if="!isAllModulesDisabled">
      <div v-if="restrictedModules.length === 0" class="body1 mb-7">
        {{ $t(`modals.${config.action}.description`) }}
      </div>
      <div v-else>
        <div class="body1">
          {{ $t(`modals.${config.action}.notRestrictedModules`) }}
        </div>
        <ul class="ml-2 mt-2 mb-2">
          <li v-for="module in notRestrictedModules" :key="module" class="subtitle1 ml-4">
            {{ $t(`subscriptions.modules.${module}`) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex align-center justify-end mb-3">
      <div>
        <v-btn
          color="primary"
          class="mt-7"
          rounded
          size="large"
          variant="text"
          @click="$emit('dismissed')"
        >
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          class="mt-7"
          rounded
          size="large"
          :disabled="disable || isAllModulesDisabled"
          @click="onAction()"
        >
          {{ $t(`modals.${config.action}.actionBtn`) }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import difference from "lodash/difference";
import { isActionRestricted } from "@/_helpers/utils";
import { computed, defineComponent, onMounted, type PropType, ref } from "vue";
import { RolePermissionsScope, type TicketsScope } from "@/_store/roles.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { SubscriptionModule } from "@/constants/workplaces";
import { TicketActionSeverity } from "@/constants/tickets";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:localValue", "dismissed"],
  setup(props, { emit }) {
    const localValue = ref(cloneDeep(props.config));
    const modules = computed(() => {
      return props.config.item.modules;
    });

    const restrictedModules = computed(() => {
      return modules.value.filter((module: SubscriptionModule) => {
        return isActionRestricted(
          RolePermissionsScope.TICKETS,
          module as keyof TicketsScope,
          TicketActionSeverity.LOW
        );
      });
    });

    const notRestrictedModules = computed(() => {
      return modules.value.filter((module: SubscriptionModule) => {
        return !isActionRestricted(
          RolePermissionsScope.TICKETS,
          module as keyof TicketsScope,
          TicketActionSeverity.LOW
        );
      });
    });

    const isAllModulesDisabled = computed(() => {
      return difference(modules.value, restrictedModules.value).length === 0;
    });

    const onAction = () => {
      emit("dismissed");
      props.config.item.callback({
        action: props.config.action,
        selection: props.config.item.selection,
      });
    };

    onMounted(() => {
      emit("update:localValue", localValue.value);
    });

    return {
      localValue,
      modules,
      notRestrictedModules,
      restrictedModules,
      isAllModulesDisabled,
      onAction,
    };
  },
});
</script>
