import { TicketTrigger } from "@/constants/tickets";
import { groupBy, uniqueId } from "lodash";
import { DataLossAction } from "@/constants/user-data-governance";
import type { DataLossPermissionChoice } from "@/constants/user-data-governance";

const ALL_USERS_POLICY = "allUsersPolicy";

const categoryOrder = [
  TicketTrigger.DLP_PII,
  TicketTrigger.DLP_PCI,
  TicketTrigger.DLP_PHI,
  TicketTrigger.DLP_NPI,
  TicketTrigger.CRITICAL_DATA_PASSWORD,
  TicketTrigger.CRITICAL_DATA_CERTIFICATE,
  TicketTrigger.CRITICAL_DATA_SOURCE_CODE,
  TicketTrigger.CRITICAL_DATA_KEYWORDS,
  TicketTrigger.CRITICAL_DATA_FILE_TYPES,
];

export enum PermissionsHolderType {
  USER_DOMAIN = "USER_DOMAIN",
  GROUP = "GROUP",
  ALL_USERS = "ALL_USERS",
}

interface PermissionsGroupPolicy {
  groupId: string;
  name: string;
  grantType: DataLossPermissionChoice;
  serviceName: string;
}
export interface UserDataGovernancePermissionConfig {
  groupPolicies: PermissionsGroupPolicy[];
  emailDomainPolicies: Record<string, DataLossPermissionChoice>;
  allUsersPolicy: DataLossPermissionChoice;
  emailAliases: Record<string, DataLossPermissionChoice>;
}

export type DataLossPermissionsRaw = {
  [key in TicketTrigger]?: UserDataGovernancePermissionConfig;
};

export interface DataLossPermissionsNormalized {
  id: string;
  name: string;
  serviceName?: string;
  groupId?: string;
  choice: DataLossPermissionChoice;
  position: number;
  category: TicketTrigger;
  aliases?: string[];
}

export interface PermissionsFormData {
  users: string[];
  groups: PermissionsGroupPolicy[];
  action: DataLossAction;
  choice: DataLossPermissionChoice;
  selectedDataType: TicketTrigger;
}

export interface PermissionsDataForCreation {
  holderType: PermissionsHolderType;
  data: {
    triggers: TicketTrigger[];
    grantType: DataLossPermissionChoice;
    holders?: string[];
  };
}

export const mapDataLossPermissionCategory = ([category, data]: [
  TicketTrigger,
  UserDataGovernancePermissionConfig
]): DataLossPermissionsNormalized[] => {
  const position = categoryOrder.indexOf(category);
  const allUsersPolicy = data[ALL_USERS_POLICY]
    ? [
        {
          id: uniqueId("permission_"),
          name: ALL_USERS_POLICY,
          choice: data[ALL_USERS_POLICY],
          position,
          category,
        },
      ]
    : [];
  const groups = data.groupPolicies.map(({ groupId, name, grantType, serviceName }) => {
    return {
      id: uniqueId("permission_"),
      groupId,
      name,
      choice: grantType,
      position,
      category,
      serviceName,
    };
  });
  const users = Object.entries(data.emailDomainPolicies).map(([name, choice]) => {
    const aliases = data?.emailAliases?.[name] || [];
    return {
      id: uniqueId("permission_"),
      name,
      choice,
      position,
      category,
      aliases,
    };
  });
  return [...allUsersPolicy, ...groups, ...users];
};

export const denormalizePermissionsData = (
  tableData: DataLossPermissionsNormalized[]
): DataLossPermissionsRaw => {
  const grouped = groupBy(tableData, "category");

  return Object.fromEntries(
    Object.entries(grouped).map(([category, value]) => {
      const allUsersPolicy = value.find((v) => v.name === ALL_USERS_POLICY)?.choice;
      const groupPolicies = value
        .filter((v) => v.groupId)
        .map(({ groupId, name, choice: grantType }) => ({
          groupId,
          name,
          grantType,
        }));
      const emailDomainPolicies = value
        .filter((v) => !v.groupId && v.name !== ALL_USERS_POLICY)
        .reduce((acc, curr) => {
          acc[curr.name] = curr.choice;
          return acc;
        }, {} as Record<string, DataLossPermissionChoice>);

      return [
        category,
        {
          allUsersPolicy,
          groupPolicies,
          emailDomainPolicies,
        },
      ];
    })
  );
};

export const denormalizePermissionsForCreation = (
  payload: PermissionsFormData
): PermissionsDataForCreation => {
  const actionParamMap = {
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_USERS]: PermissionsHolderType.USER_DOMAIN,
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_GROUPS]: PermissionsHolderType.GROUP,
    [DataLossAction.ADD_PERMISSION_FOR_ALL_USERS]: PermissionsHolderType.ALL_USERS,
  };
  let holders;
  if (payload.action === DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_GROUPS) {
    holders = payload.groups.map(({ groupId }) => groupId);
  }
  if (payload.action === DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_USERS) {
    holders = payload.users;
  }
  return {
    holderType: actionParamMap[payload.action],
    data: {
      triggers: [payload.selectedDataType],
      grantType: payload.choice,
      holders,
    },
  };
};
