<template>
  <div class="content-md margin-auto mt-4">
    <div>
      <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs"></v-breadcrumbs>
    </div>
    <div class="title-with-btn d-flex justify-space-between align-center mb-5 mt-4">
      <div class="headline5 d-flex align-center text-primary">
        {{ $t("userDataGovernanceSettings.title") }}
      </div>
      <div>
        <add-data-permissions-button v-if="showAddPermissionsBtn()" />
      </div>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="mb-4"> </page-tabs>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import AddDataPermissionsButton from "@/views/settings/user-data-governance/AddDataPermissionsButton.vue";
import { RouteName } from "@/constants/routes";

enum UserDataGovernanceTabs {
  MONITORING = "monitoring",
  PERMISSIONS = "permissions",
  EXCLUSIONS = "exclusions",
}

export default defineComponent({
  components: { AddDataPermissionsButton, PageTabs },
  setup() {
    const i18n = useI18n();

    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t("userDataGovernanceSettings.tabs.monitoring"),
        route: { name: RouteName.USER_DATA_GOVERNANCE_MONITORING_TAB },
        testId: "data-governance-monitoring-tab",
      },
      {
        id: 2,
        name: i18n.t("userDataGovernanceSettings.tabs.permissions"),
        route: { name: RouteName.USER_DATA_GOVERNANCE_PERMISSIONS_TAB },
        testId: "data-governance-permissions-tab",
      },
      {
        id: 3,
        name: i18n.t("userDataGovernanceSettings.tabs.exclusions"),
        route: { name: RouteName.USER_DATA_GOVERNANCE_EXCLUSIONS_TAB },
        testId: "exclusions-tab",
      },
      // TODO: Uncomment when needed. For now should be hidden
      // { id: 4, name: this.$t("userDataGovernanceSettings.tabs.outboundGateway"), route: "outbound-gateway" },
    ];
    const route = useRoute();
    const path = computed(() => route.path);

    const showAddPermissionsBtn = () => {
      return path.value.includes(UserDataGovernanceTabs.PERMISSIONS);
    };

    return {
      tabs,
      showAddPermissionsBtn,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
.title-with-btn {
  height: 36px;
}
</style>
