<template>
  <div class="tiles-block-wrapper d-flex justify-space-between mb-8 html2pdf__page-break">
    <div class="w-50 pa-8 tiles-block">
      <ReportTiles
        :title="$t('reports.dnsSummaryReport.topDomainsPermitted')"
        :sub-title="$t('reports.dnsSummaryReport.perHits')"
        :data="data.topDomainsPermitted"
        item-key="domain"
      />
    </div>
    <div class="w-50 pa-8 tiles-block">
      <ReportTiles
        :title="$t('reports.dnsSummaryReport.topDomainsBlocked')"
        :sub-title="$t('reports.dnsSummaryReport.perHits')"
        :data="data.topDomainsBlocked"
        item-key="domain"
      />
    </div>
  </div>
  <div class="tiles-block-wrapper d-flex justify-space-between">
    <div class="w-50 pa-8 tiles-block">
      <ReportTiles
        :title="$t('reports.dnsSummaryReport.topClients')"
        :sub-title="$t('reports.dnsSummaryReport.perRequest')"
        :data="data.topClients"
        item-key="host"
      />
    </div>
    <div class="w-50 pa-8 tiles-block">
      <ReportTiles
        :title="$t('reports.dnsSummaryReport.topClientsBlocked')"
        :sub-title="$t('reports.dnsSummaryReport.perRequest')"
        :data="data.topClientsBlocked"
        item-key="host"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ReportTiles from "@/components/reports/ReportTiles.vue";

export default defineComponent({
  components: { ReportTiles },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.tiles-block-wrapper {
  background-color: rgb(var(--v-theme-indigo-faint));
  border-radius: 8px;
}

.tiles-block:first-child {
  border-right: 1px solid rgb(var(--v-theme-indigo-pale));
}
</style>
