import type { DeviceDrive } from "@/_store/devices.module";
import { DriveEncryptionStatus } from "@/constants/devices";
import { isNewerOrSameVersion } from "@/_helpers/utils";

/**
 * Returns if is suitable for encryption
 * @param {{status: string; isRemovable: boolean;}} drive
 * @param {object} {osType, marketingVersion}
 * @returns {boolean}
 */
export const isSuitableDriveForEncryption = (
  drive: DeviceDrive,
  { marketingVersion }: { marketingVersion: string }
): boolean => {
  const clientVersionWhichSupportsEncryption = "1.7";
  if (![DriveEncryptionStatus.NOT_ENCRYPTED, DriveEncryptionStatus.FAILED].includes(drive.status)) {
    return false;
  }
  if (drive.isRemovable) {
    return false;
  }
  return isNewerOrSameVersion(clientVersionWhichSupportsEncryption, marketingVersion);
};
