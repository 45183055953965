import { defineStore } from "pinia";
import type { Pagination } from "@/types";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { useAccountStore } from "@/_store/account.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export interface ActiveSessionItem {
  sessionId: string;
  email: string;
  name: string;
  signedIn: number;
  lastAccessed: number;
  country: string;
  ip: string;
  browser: string;
  os: string;
  currentSession?: boolean;
}

export interface ActiveSessionsState {
  activeSessions: ActiveSessionItem[];
  total: number;
  showSkeletonLoader: boolean;
  pagination: Pagination;
  loading: boolean;
}

const defaultActiveSessionsState: ActiveSessionsState = {
  activeSessions: [],
  total: 0,
  showSkeletonLoader: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  loading: false,
};

export const useActiveSessionsStore = defineStore("activeSessions", {
  state: () => ({ ...defaultActiveSessionsState }),
  actions: {
    setPagination(payload: Pagination) {
      this.pagination = payload;
    },
    async getActiveSessions(showSkeletonLoader = false) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;

      const request = {
        ...api.getActiveSessions(this.pagination),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.activeSessions = data.items;
        this.total = data.total;
      } catch (error) {
        console.error(error);
      }
      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async revokeSession(payload: DialogDataConfig<ActiveSessionItem>) {
      const request = {
        ...api.revokeSession(payload.item.sessionId),
      };

      try {
        await axiosInstance.request(request);
        if (payload.item.currentSession) {
          await useAccountStore().logout();
          return;
        }

        await this.getActiveSessions();
      } catch (error) {
        console.error(error);
      }
    },
  },
});
