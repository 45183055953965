import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { useAccountStore } from "@/_store/account.module";
import {
  isAccessRestricted,
  isAddonDisabled,
  isModuleDisabled,
  socAddonsEnabled,
} from "@/_helpers/utils";
import {
  type GlobalRolePermissions,
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  type NestedGlobalPermissions,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
  type WorkspaceRolePermissions,
} from "@/_store/roles.module";
import { type SubscriptionAddon, SubscriptionModule, WorkspaceType } from "@/constants/workplaces";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions";
import { isGlobalAccessRestricted, isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { RouteName } from "@/constants/routes";
import { useMyAccountStore } from "@/_store/my-account.module";

export const dashboardGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const accountStore = useAccountStore();
  const { logged, account } = accountStore;

  if (!logged) {
    return next({ name: RouteName.LOGIN });
  }

  const { onboardingCompleted, workplace } = account;

  if (onboardingCompleted) {
    return workplace ? next() : next({ name: RouteName.WORKSPACES });
  }

  if (logged && !to.path.includes("sign-up")) {
    return next({ name: RouteName.SIGN_UP });
  }

  return next();
};

export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const accountStore = useAccountStore();
  const { logged } = accountStore;
  return logged ? next() : next("/login");
};

export const moduleNavigationGuard = (moduleName: SubscriptionModule) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    return isModuleDisabled(moduleName) ? next("portal/dashboard") : next();
  };
};

export const addonNavigationGuard = (addonName: SubscriptionAddon) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    return isAddonDisabled(addonName) ? next("portal/dashboard") : next();
  };
};

export const publicPagesGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const accountStore = useAccountStore();
  const { logged } = accountStore;
  return logged ? next({ name: RouteName.DASHBOARD }) : next();
};

export const invitedPageGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const accountStore = useAccountStore();
  const { logged } = accountStore;
  if (logged) {
    accountStore.setWorkspace("");
    return next({ name: RouteName.WORKSPACES });
  }
  return next();
};

export const mfaPageGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const myAccountStore = useMyAccountStore();
  if (!myAccountStore.myAccount.profileData.allowMfa) {
    return next({ name: RouteName.DASHBOARD });
  }
  return next();
};

export const socGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const accountStore = useAccountStore();
  const { logged } = accountStore;
  return logged && socAddonsEnabled() ? next() : next("/portal/dashboard");
};

export const socTicketsLogGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const socTicketsLogDisabled = isGlobalAccessRestricted(
    GlobalRoleScopes.GLOBAL_SCOPE,
    GlobalRoleScopeSection.SOC_PORTAL
  );
  return socTicketsLogDisabled ? next("/dashboard") : next();
};

export const permissionsGuard = <
  T extends RolePermissionsScope = RolePermissionsScope,
  K extends keyof WorkspaceRolePermissions[T] = keyof WorkspaceRolePermissions[RolePermissionsScope]
>(
  scopeName: T,
  key: K
) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const accessRestricted = isAccessRestricted(scopeName, key);
    return accessRestricted ? next("/portal/dashboard") : next();
  };
};

type AccessControlPageSection =
  | WorkspaceManagementScopeSections.ADMIN_USERS
  | WorkspaceManagementScopeSections.ROLES
  | WorkspaceManagementScopeSections.ACTIVE_SESSIONS;

type TabGuardParams<T extends string> = {
  accessRestricted: (key: T) => boolean;
  tabs: readonly T[];
  tabNameToRouteName: Record<T, string>;
};

const createPageTabGuard = <T extends string>({
  accessRestricted,
  tabs,
  tabNameToRouteName,
}: TabGuardParams<T>) => {
  return (tab: T) => {
    return (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => {
      const allTabsRestricted = tabs.every((t) => accessRestricted(t));

      if (allTabsRestricted) {
        return next("/portal/dashboard");
      }

      const firstAvailableTab = tabs.find((t) => !accessRestricted(t))!;

      if (accessRestricted(tab)) {
        return next({ name: tabNameToRouteName[firstAvailableTab] });
      }

      return next();
    };
  };
};

// Specific guards using the generic createPageTabGuard function

export const permissionsPageTabGuard = createPageTabGuard({
  accessRestricted: (key: AccessControlPageSection) =>
    isAccessRestricted(RolePermissionsScope.WORKSPACE_MANAGEMENT, key),
  tabs: [
    WorkspaceManagementScopeSections.ADMIN_USERS,
    WorkspaceManagementScopeSections.ROLES,
    WorkspaceManagementScopeSections.ACTIVE_SESSIONS,
  ],
  tabNameToRouteName: {
    [WorkspaceManagementScopeSections.ADMIN_USERS]: RouteName.ACCESS_CONTROL_ADMIN_USERS_TAB,
    [WorkspaceManagementScopeSections.ROLES]: RouteName.ACCESS_CONTROL_ROLES_TAB,
    [WorkspaceManagementScopeSections.ACTIVE_SESSIONS]:
      RouteName.ACCESS_CONTROL_ACTIVE_SESSIONS_TAB,
  },
});

export const mspPageTabGuard = createPageTabGuard({
  accessRestricted: (key: MspPortalScopeSection) => isMspAccessRestricted(key),
  tabs: Object.values(MspPortalScopeSection),
  tabNameToRouteName: {
    [MspPortalScopeSection.MSP_WORKSPACES]: RouteName.MSP_WORKSPACES_TAB,
    [MspPortalScopeSection.MSP_ADMIN_USERS]: RouteName.MSP_ADMIN_USERS_TAB,
    [MspPortalScopeSection.MSP_ROLES]: RouteName.MSP_ROLES_TAB,
  },
});

export const globalPermissionsPageTabGuard = createPageTabGuard({
  accessRestricted: (
    key: GlobalRoleScopeSection.GLOBAL_ROLES | GlobalRoleScopeSection.GLOBAL_ADMIN_USERS
  ) => isGlobalAccessRestricted(GlobalRoleScopes.GLOBAL_SCOPE, key),
  tabs: [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS, GlobalRoleScopeSection.GLOBAL_ROLES],
  tabNameToRouteName: {
    [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS]: RouteName.GLOBAL_ADMIN_USERS,
    [GlobalRoleScopeSection.GLOBAL_ROLES]: RouteName.GLOBAL_ROLES,
  },
});

export const usersTablePageNavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const anyUsersModuleEnabled =
    !isModuleDisabled(SubscriptionModule.CLOUD_SECURITY) ||
    !isModuleDisabled(SubscriptionModule.EMAIL_SECURITY) ||
    !isModuleDisabled(SubscriptionModule.USER_DATA_GOVERNANCE);
  return anyUsersModuleEnabled && !isAccessRestricted(RolePermissionsScope.VIEWS, "usersView")
    ? next()
    : next("portal/dashboard");
};

export const deviceTablePageNavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const anyDeviceModuleEnabled = [
    SubscriptionModule.ENDPOINT_SECURITY,
    SubscriptionModule.EDR,
    SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
    SubscriptionModule.NETWORK,
  ].some((module) => !isModuleDisabled(module));
  return anyDeviceModuleEnabled ? next() : next("portal/dashboard");
};

export const backOfficeGuard = <
  T extends GlobalRoleScopes,
  K extends keyof GlobalRolePermissions[T],
  V extends GlobalRolePermissions[T][K] extends NestedGlobalPermissions
    ? NonNullable<GlobalRolePermissions[T][K]["editAccessModePermission"]>
    : GlobalRolePermissions[T][K]
>(
  scopeName?: T,
  key?: K,
  nestedKey?: keyof V
) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    // Backoffice page should be accessible only for coronet workspaces
    const backOfficeDisabled = isGlobalActionRestricted(
      GlobalRoleScopes.GLOBAL_SCOPE,
      GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
      "backOffice"
    );
    if (backOfficeDisabled) return next("portal/dashboard");
    if (scopeName && key) {
      if (key === GlobalRoleScopeSection.SPECIAL_PERMISSIONS) {
        return isGlobalActionRestricted(scopeName, key as NonNullable<K>, nestedKey)
          ? next("portal/dashboard")
          : next();
      }
      return isGlobalAccessRestricted(scopeName, key) ? next("portal/dashboard") : next();
    }

    return next();
  };
};

export const workspaceTypesNavigationGuard = (workspaceTypes: WorkspaceType[]) => {
  return (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const currentWorkspaceType = useAccountStore().account.workspaceType;

    return workspaceTypes.includes(currentWorkspaceType) ? next() : next("portal/dashboard");
  };
};
