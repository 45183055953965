<template>
  <div>
    <div>{{ $t("modals.newOfficeAccountError.description") }}</div>
    <div class="d-flex justify-center mt-10 mb-3">
      <v-btn rounded color="primary" @click="goToSignup()">
        {{ $t("modals.newOfficeAccountError.actionButton") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import { useAccountStore } from "@/_store";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const accountStore = useAccountStore();
    const router = useRouter();

    const goToSignup = () => {
      accountStore.$reset();
      router.replace({ name: RouteName.SIGN_UP, query: {} });
      emit("dismissed");
    };

    return {
      goToSignup,
    };
  },
});
</script>

<style lang="scss" scoped></style>
