<template>
  <div>
    <div class="d-flex justify-end mb-5">
      <filter-wrapper
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(clearFiltersCallback)"
      >
        <v-combobox
          v-model="localFilters.search"
          variant="outlined"
          clearable
          clear-icon="icon-x"
          background-color="white"
          :items="suggestions"
          :placeholder="$t('general.search')"
          class="search-field"
          append-icon=""
          hide-details
          density="compact"
          rounded
          bg-color="white"
          menu-icon=""
          persistent-clear
          data-testid="telemetry-page-search-field"
          :class="{
            'filter-active': localFilters.search,
          }"
        />
      </filter-wrapper>
    </div>
    <list-with-preview :show-skeleton-loader="showSkeletonLoader" class="edr-content">
      <template #list>
        <processes-table
          :items="items"
          :total-items="total"
          :loading="loading"
          :pagination="pagination"
          :available-bulk-actions="availableBulkActions"
          @selection-changed="setSelection($event)"
          @page-changed="onPageChange"
          @sorting-changed="onSortingChanged($event)"
          @update-preview="onPreviewUpdate($event)"
        />
      </template>
      <template #preview>
        <processes-preview :preview="preview" />
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { storeToRefs } from "pinia";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { onMounted, watch, onUnmounted, ref } from "vue";
import { EdrAction } from "@/constants/edr";
import { SearchSuggestion } from "@/constants/general";
import ListWithPreview from "@/components/ListWithPreview.vue";
import { type ProcessItem, useProcessesStore } from "@/_store/endpoint-security/processes.module";
import ProcessesPreview from "@/components/ProcessesPreview.vue";
import ProcessesTable from "@/components/tables/ProcessesTable.vue";
import { useSelectorStore } from "@/_store/selector.module";
import uniq from "lodash/uniq";
import type { Pagination } from "@/types";
import isEqual from "lodash/isEqual";
import { useRoute } from "vue-router";

const filterContext = FilterContext.EDR_PROCESSES;

interface IActionPerVulnerabilityMap {
  [key: string]: boolean;
}

export default defineComponent({
  components: {
    ProcessesTable,
    ProcessesPreview,
    ListWithPreview,
    FilterWrapper,
  },
  setup() {
    const processesStore = useProcessesStore();
    const selectorStore = useSelectorStore();
    const route = useRoute();
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } =
      useFilters(filterContext);
    const { selection } = storeToRefs(selectorStore);
    const { setSelection, deselectAllPages } = selectorStore;
    const { items, pagination, loading, showSkeletonLoader, total } = storeToRefs(processesStore);
    const { setPagination, resetPagination, getItems, setSorting } = processesStore;
    const preview = ref<ProcessItem | null>(null);

    const suggestions = [
      SearchSuggestion.ENROLLMENT_CODE,
      SearchSuggestion.HOSTNAME,
      SearchSuggestion.PROCESS_HASH,
      SearchSuggestion.PROCESS_NAME,
      SearchSuggestion.BLOCKED,
    ];

    const availableBulkActions = computed(() => {
      const actions = [EdrAction.BLOCK_PROCESS, EdrAction.UNBLOCK_PROCESS];
      if (!selection.value.isAllPages) {
        return getFilteredBulkActions(actions);
      }
      return actions;
    });

    const blockedProcessesInSelection = computed(() => {
      if (selection.value.isAllPages) return [];
      const blockedProcessesInRows = selection.value.include.map((process) => process.blocked);
      return uniq(blockedProcessesInRows);
    });

    const clearFiltersCallback = async () => {
      resetPagination();
      await getItems();
    };

    const onPageChange = async (pagination: Pagination) => {
      setPagination(pagination);
      await getItems();
    };

    const getFilteredBulkActions = (actions: EdrAction[]) => {
      const actionPerVulnerabilityMap: IActionPerVulnerabilityMap = {
        [EdrAction.BLOCK_PROCESS]: false,
        [EdrAction.UNBLOCK_PROCESS]: true,
      };
      return actions.filter((action) => {
        return blockedProcessesInSelection.value.includes(actionPerVulnerabilityMap[action]);
      });
    };

    const onSortingChanged = (sort: string) => {
      setSorting(sort);
      getItems();
    };

    const onPreviewUpdate = (item: ProcessItem) => {
      preview.value = item ? { ...item } : null;
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) {
          resetPagination();
          await getItems();
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      const filtersWithQuery = {
        ...{ ...localFilters.value },
        search: route.query?.search ? (route.query?.search as string) : localFilters.value.search,
      };

      resetPagination();
      // If filters are not equal, then update filters which triggers the watcher `filtersUpdating`
      if (!isEqual(filtersWithQuery, localFilters.value)) {
        localFilters.value = filtersWithQuery;
      } else {
        // else - do request manually
        await getItems({ showSkeletonLoader: true });
      }
    });

    onUnmounted(() => {
      deselectAllPages();
    });

    return {
      showClearFiltersButton,
      clearFilters,
      clearFiltersCallback,
      localFilters,
      suggestions,
      items,
      pagination,
      loading,
      showSkeletonLoader,
      total,
      setSelection,
      availableBulkActions,
      onSortingChanged,
      onPageChange,
      preview,
      onPreviewUpdate,
    };
  },
});
</script>

<style scoped lang="scss">
.filter-menu {
  width: 125px;
}
</style>
