<template>
  <div class="d-flex align-center mb-3">
    <v-icon size="40" :icon="iconToModuleMap[protectionModule]"></v-icon>
    <div class="headline6">
      {{ $t(`reports.executiveSummaryReport.${protectionModule}.title`) }}
    </div>
  </div>
  <div class="body1 mb-6 flex-column">
    <div>
      {{ $t(`reports.executiveSummaryReport.${protectionModule}.description`) }}
    </div>
    <div
      v-if="isModuleDisabled(protectionModule)"
      class="body1 text--semitransparent mt-2 d-flex align-center"
    >
      <v-icon class="mr-1" icon="$warning" size="24" />
      <span>{{ $t(`reports.executiveSummaryReport.disabledModuleNotification`) }}</span>
    </div>
  </div>
  <v-row class="bg-indigo-faint d-flex pa-10 rounded-lg" style="gap: 40px" no-gutters>
    <v-col v-if="columnsLayout.totalTicketsColumns" :cols="columnsLayout.totalTicketsColumns">
      <div class="d-flex flex-column">
        <div class="big-number mb-2 text-primary">
          {{ totalItemsProcessed }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.managedServicesSummaryReport.totalTicketsResolved") }}
        </div>
      </div>
    </v-col>
    <v-col v-if="columnsLayout.vulnerabilitiesColumns" :cols="columnsLayout.vulnerabilitiesColumns">
      <div class="subtitle1">
        {{ $t("reports.executiveSummaryReport.ticketsByType") }}
      </div>
      <div
        v-for="{ trigger, count } in alphabeticallySortedVulnerabilities"
        :key="trigger"
        class="es-report-module-overview__ticket-type d-flex text-right pa-2"
      >
        <v-row>
          <v-col class="body2">
            {{ $t(`tickets.eventTriggers.${trigger}`) }}
          </v-col>
          <v-col class="d-flex align-center">
            <div
              v-if="count !== 0"
              class="percentage-bar bg-indigo-light rounded-pill mr-2"
              :style="getProgressStyle(count)"
            ></div>
            <span class="body2 text--semibold" :class="{ 'text--semitransparent': count === 0 }">
              {{ count }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col v-if="columnsLayout.topViolatorsColumns" :cols="columnsLayout.topViolatorsColumns">
      <div v-if="hasViolators" class="subtitle1">
        {{ $t(`reports.executiveSummaryReport.${protectionModule}.topViolatorsTitle`) }}
      </div>
      <template v-for="(count, violator) in violators" :key="violator">
        <div class="d-flex justify-space-between text-primary py-2">
          <div class="caption word-break-all">
            {{ violator }}
          </div>
          <div class="caption ml-4">
            {{ count }}
          </div>
        </div>
        <v-divider class="border-opacity-100" color="white" />
      </template>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { CoroIcons } from "@/constants/coro-icon";
import { SubscriptionModule } from "@/constants/workplaces";
import { isModuleDisabled } from "@/_helpers/utils";
import type { TicketTrigger } from "@/constants/tickets";
import { useI18n } from "vue-i18n";

const iconToModuleMap: Record<string, string> = {
  [SubscriptionModule.CLOUD_SECURITY]: `$${CoroIcons.CLOUD_SECURITY}`,
  [SubscriptionModule.EMAIL_SECURITY]: `$${CoroIcons.EMAILS}`,
  [SubscriptionModule.ENDPOINT_SECURITY]: `$${CoroIcons.ENDPOINT_SECURITY}`,
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: `$${CoroIcons.ENDPOINT_DATA_GOVERNANCE}`,
  [SubscriptionModule.USER_DATA_GOVERNANCE]: `$${CoroIcons.USER_DATA_GOVERNANCE}`,
  [SubscriptionModule.EDR]: `$${CoroIcons.EDR}`,
};

export interface ReportModuleOverviewColumns {
  totalTicketsColumns: number;
  vulnerabilitiesColumns: number;
  topViolatorsColumns: number;
}

export default defineComponent({
  props: {
    protectionModule: {
      type: String as PropType<SubscriptionModule>,
      required: true,
    },
    vulnerabilities: {
      type: Object as PropType<{ [key in TicketTrigger]: number }>,
      required: true,
    },
    violators: {
      type: Object as PropType<{ [email: string]: number }>,
      required: false,
    },
    columnsLayout: {
      type: Object as PropType<ReportModuleOverviewColumns>,
      required: true,
    },
    totalItemsProcessed: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const getProgressStyle = (count: number) => {
      const highestNumberOfVulnerabilities = Math.max(...Object.values(props.vulnerabilities));
      const percentage =
        count === highestNumberOfVulnerabilities
          ? 100
          : (count / highestNumberOfVulnerabilities) * 100;
      return `width: ${Math.ceil(percentage)}%;`;
    };
    const hasViolators = computed(() =>
      props.violators ? !!Object.values(props.violators).length : false
    );

    const alphabeticallySortedVulnerabilities = computed(() => {
      return Object.entries(props.vulnerabilities)
        .map(([trigger, count]) => {
          return {
            trigger,
            count,
          };
        })
        .sort((a, b) =>
          i18n
            .t(`tickets.eventTriggers.${a.trigger}`)
            .localeCompare(i18n.t(`tickets.eventTriggers.${b.trigger}`))
        );
    });

    return {
      iconToModuleMap,
      hasViolators,
      alphabeticallySortedVulnerabilities,
      isModuleDisabled,
      getProgressStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.percentage-bar {
  height: 24px;
}

:deep(*) {
  .icon-warning:before {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
</style>
