import { defineStore } from "pinia";
import { getSelectionAsStringsArray, handleVirtualScrollData } from "@/_helpers/utils";
import { i18n } from "@/plugins/i18n";
import api from "@/_helpers/api";
import type { Pagination } from "@/types";
import { useFiltersStore } from "@/_store/filters.module";
import { axiosInstance } from "@/plugins/https";
import type { OsType } from "@/constants/devices";
import type { ISelection } from "@/_store/selector.module";
import { useSelectorStore } from "@/_store/selector.module";
import { useSnackbarStore } from "@/_store";
import { EdrAction } from "@/constants/edr";
import type { SortObject } from "@/components/ListTableHeader.vue";

export interface ProcessItem {
  affectedDevicesCount: number;
  blocked: boolean;
  id: string;
  lastActivity: number;
  name: string;
  osType: OsType;
  processHash: string;
}

export interface ProcessDetails {
  affectedDevicesCount?: number;
  blocked?: boolean;
  id: string;
  lastActivity?: number;
  name?: string;
  osType?: OsType;
  processHash?: string;
  blockedTime: number;
  actions?: EdrAction[];
  firstActivity: number;
  firstSeenOnHostname?: string;
  firstSeenOnEnrollmentCode?: string;
  firstUsername?: string;
}

export interface PathItem {
  path: string;
  deviceCount: number;
  enrollmentCode: string;
  hostname?: string;
}

export interface AliasItem {
  alias: string;
  deviceCount: number;
  enrollmentCode: string;
  hostname?: string;
}

export interface ProcessOpenTicketItem {
  issue: string;
  count: number;
}

interface EDRState {
  items: ProcessItem[];
  total: number;
  pagination: Pagination;
  showSkeletonLoader: boolean;
  sort: string;
  loading: boolean;
  detailsLoading: boolean;
  details: ProcessDetails;
  knownPaths: PathItem[];
  aliases: AliasItem[];
  hasMorePaths: boolean;
  hasMoreAliases: boolean;
  openTickets: ProcessOpenTicketItem[];
}

const defaultProcessesState = {
  items: [],
  total: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  showSkeletonLoader: true,
  sort: "affectedDevicesCount,desc",
  loading: false,
  detailsLoading: false,
  details: {
    id: "",
    blockedTime: 0,
    firstActivity: 0,
  },
  knownPaths: [],
  aliases: [],
  hasMorePaths: true,
  hasMoreAliases: true,
  openTickets: [],
};

export const useProcessesStore = defineStore("processes", {
  state: (): EDRState => ({
    ...defaultProcessesState,
  }),
  getters: {
    sortObject(state): SortObject {
      const [property, direction] = state.sort.split(",");
      return { property, direction };
    },
  },
  actions: {
    setSorting(sorting: string) {
      this.sort = sorting;
    },
    setLoadingState(isLoading: boolean) {
      this.loading = isLoading;
    },
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = {
        page: 0,
        pageSize: 25,
      };
    },
    async getItems({ showSkeletonLoader = false, triggeredByFilters = false } = {}) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const filters = useFiltersStore().filters.edrProcessesFilters;

      if (triggeredByFilters) {
        this.setPagination({ page: 0, pageSize: 15 });
      }

      const request = {
        ...api.getEdrProcessList({
          ...this.pagination,
          ...filters,
          sort: `${this.sortObject.property},${this.sortObject.direction}`,
        }),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.items = data.items;
        this.total = data.total;
      } catch (err) {
        console.error(err);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async getDetails(processId: string) {
      this.detailsLoading = true;
      const request = {
        ...api.getEdrProcessDetails(processId),
      };

      try {
        const [details] = await Promise.all([
          axiosInstance.request(request),
          this.getKnownPaths({ processId }),
          this.getAliases({ processId }),
          this.getOpenTickets(processId),
        ]);

        this.details = details.data;
      } catch (err) {
        console.error(err);
      }

      this.detailsLoading = false;
    },
    async getOpenTickets(id: string) {
      const request = {
        ...api.getEdrProcessTickets(id),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.openTickets = data;
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async getKnownPaths({
      processId,
      page = 0,
      virtualScroll = false,
    }: {
      processId: string;
      page?: number;
      virtualScroll?: boolean;
    }) {
      const request = {
        ...api.getEdrProcessPaths(processId, {
          page,
          pageSize: 10,
        }),
      };

      try {
        const { data } = await axiosInstance.request(request);
        if (data.length < 10) {
          this.hasMorePaths = false;
        }
        this.knownPaths = virtualScroll
          ? handleVirtualScrollData(this.knownPaths, data, "path")
          : data;

        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async getAliases({
      processId,
      page = 0,
      virtualScroll = false,
    }: {
      processId: string;
      page?: number;
      virtualScroll?: boolean;
    }) {
      const request = {
        ...api.getEdrProcessAliases(processId, {
          page,
          pageSize: 10,
        }),
      };

      try {
        const { data } = await axiosInstance.request(request);
        if (data.length < 10) {
          this.hasMoreAliases = false;
        }
        this.aliases = virtualScroll ? handleVirtualScrollData(this.aliases, data, "alias") : data;
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async applyProcessAction({
      action,
      item = {},
      selection,
    }: {
      action: EdrAction;
      item?: Partial<ProcessItem>;
      selection: ISelection;
    }) {
      const snackbarMessage = (appliedActions: number) => {
        const quantity = [
          EdrAction.DISABLE_NETWORK_BLOCK_MODE,
          EdrAction.ENABLE_NETWORK_BLOCK_MODE,
        ].includes(action)
          ? item?.affectedDevicesCount ?? 1
          : appliedActions;
        return i18n.global.t(`snackbar.messages.edr.${action}`, { quantity }, quantity);
      };
      const filters = useFiltersStore().filters.edrProcessesFilters;
      const request = {
        ...api.processEdrAction(),
        data: {
          action,
          selection: getSelectionAsStringsArray(selection, "processHash"),
          filter: {
            ...filters,
          },
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        const { deselectAllPages } = useSelectorStore();
        await this.getItems();
        useSnackbarStore().addGenericSuccess(snackbarMessage(data.appliedActions));
        deselectAllPages();
      } catch (err) {
        console.error(err);
      }
    },
  },
});
