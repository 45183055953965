<template>
  <v-list-item v-if="scope" class="pa-0">
    <template #default>
      <div class="d-flex mt-0">
        <v-col class="py-1" cols="6">
          <span class="permission-section__title subtitle2">
            {{ $t(`accessControlPage.roles.scopes.${scopeName}.title`) }}
          </span>
        </v-col>
        <v-col class="py-1" cols="6">
          <span class="permission-section__title subtitle2">
            {{ $t(`accessControlPage.roles.details.permissions`) }}
          </span>
        </v-col>
      </div>
      <div
        v-for="(permissionValue, permissionName) in scope"
        :key="permissionName"
        class="d-flex mb-1"
      >
        <v-col class="py-0" cols="6">
          <span class="body2 mb-1 text-wrap">
            {{ $t(`accessControlPage.roles.scopes.${scopeName}.${permissionName}.title`) }}
          </span>
        </v-col>
        <v-col class="py-0" cols="6">
          <template v-if="isEditAccessModePermission(permissionName, permissionValue)">
            <span class="body2 mb-1">
              {{
                $t(`accessControlPage.roles.details.permissionModes.${permissionValue.accessMode}`)
              }}
            </span>
            <ul
              class="d-flex flex-column ml-4"
              v-if="
                permissionValue.accessMode === RolePermissionAccessMode.EDIT &&
                permissionValue.editAccessModePermission
              "
            >
              <template
                v-for="(
                  nestedPermissionValue, nestedPermissionName
                ) in permissionValue.editAccessModePermission"
              >
                <li
                  v-if="nestedPermissionValue"
                  :key="nestedPermissionName"
                  class="body2 mb-1 text-wrap"
                >
                  {{
                    $t(
                      `accessControlPage.roles.scopes.${scopeName}.${permissionName}.editAccessModePermission.${nestedPermissionName}`
                    )
                  }}
                </li>
              </template>
            </ul>
          </template>
          <ul
            class="d-flex flex-column ml-4"
            v-else-if="hasEnabledSpecificPermissions(permissionValue)"
          >
            <template v-for="(nestedPermissionValue, nestedPermissionName) in permissionValue">
              <li
                v-if="nestedPermissionValue"
                class="body2 mb-1 text-wrap"
                :key="nestedPermissionName"
              >
                {{
                  $t(
                    `accessControlPage.roles.scopes.${scopeName}.${permissionName}.editAccessModePermission.${nestedPermissionName}`
                  )
                }}
              </li>
            </template>
          </ul>
          <div class="body2 mb-1 text-wrap" v-else>
            {{ $t("general.none") }}
          </div>
        </v-col>
      </div>
    </template>
  </v-list-item>
</template>

<script setup lang="ts" generic="T">
import {
  type GlobalAdminsAccessPermissions,
  type GlobalRolesAccessPermissions,
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  type RolePermissions,
  type SocPortalAccessPermissions,
  type SpecialPermissions,
  type WorkspaceSubscriptionTypeAccess,
  type WorkspaceTypeAccess,
} from "@/_store/roles.module";
import { RolePermissionAccessMode } from "@/constants/roles";

defineProps<{
  scope: RolePermissions[GlobalRoleScopes.GLOBAL_SCOPE];
}>();

const scopeName = GlobalRoleScopes.GLOBAL_SCOPE;

const isEditAccessModePermission = (
  permissionName: GlobalRoleScopeSection,
  permission:
    | GlobalAdminsAccessPermissions
    | GlobalRolesAccessPermissions
    | SocPortalAccessPermissions
    | WorkspaceSubscriptionTypeAccess
    | WorkspaceTypeAccess
    | SpecialPermissions
): permission is
  | GlobalAdminsAccessPermissions
  | GlobalRolesAccessPermissions
  | SocPortalAccessPermissions => {
  return [
    GlobalRoleScopeSection.GLOBAL_ADMIN_USERS,
    GlobalRoleScopeSection.GLOBAL_ROLES,
    GlobalRoleScopeSection.SOC_PORTAL,
  ].includes(permissionName);
};
const hasEnabledSpecificPermissions = (
  permission: WorkspaceSubscriptionTypeAccess | WorkspaceTypeAccess | SpecialPermissions
): boolean => {
  return Object.values(permission).some((v: boolean | null) => !!v);
};
</script>

<style scoped></style>
