<template>
  <v-skeleton-loader :loading="showSkeletonLoader" :type="coronetSkeletonLoaderTypes.DEFAULT_TABLE">
    <table-wrapper v-if="apiKeys.length" class="mt-4">
      <v-table class="white">
        <template #default>
          <thead>
            <tr>
              <th>{{ $t("connectors.apiKeys.table.clientId") }}</th>
              <th>{{ $t("connectors.apiKeys.table.description") }}</th>
              <th>{{ $t("connectors.apiKeys.table.createdTime") }}</th>
              <th>{{ $t("connectors.apiKeys.table.expirationTime") }}</th>
              <th>{{ $t("connectors.apiKeys.table.lastUsedTime") }}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in apiKeys" :key="item.clientId" class="service-row">
              <td>{{ item.clientId }}</td>
              <td>{{ item.description }}</td>
              <td>
                {{ format(new Date(item.createdDateTimeMs), "EEE, MMM d, K:mmaaa") }}
              </td>
              <td>
                <template v-if="item.expirationTime > 0">
                  <template v-if="isTokenExpired(item)">
                    <span class="text-red-dark">
                      {{ $t("connectors.apiKeys.expired") }}
                    </span>
                  </template>
                  <template v-else>
                    {{ getDaysUntil(item.expirationTime) }}
                  </template>
                </template>
                <template v-else> ∞ </template>
              </td>
              <td class="content-row">
                <template v-if="item.lastUsedTime > 0">
                  {{ formatDistanceToNow(new Date(item.lastUsedTime)) }}
                </template>
                <template v-else> -- </template>
              </td>
              <td class="text-right" @click="$event.stopPropagation()">
                <v-btn
                  :data-testid="`api-keys-table-remove-${i}`"
                  dense
                  color="primary"
                  rounded
                  @click="removeKey(item)"
                >
                  {{ $t("general.revoke") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </table-wrapper>
    <template v-else>
      <empty-state class="margin-auto mt-12 w-65">
        <template #icon>
          <v-icon icon="$connectors" :size="80" />
        </template>
        <template #description>
          <div class="headline5 mb-3 mt-3">
            {{ $t("connectors.apiKeys.noItems.description") }}
          </div>
        </template>
        <template #subtitle>
          {{ $t("connectors.apiKeys.noItems.subDescription") }}
        </template>
        <template #button>
          <v-btn
            rounded
            color="primary"
            density="default"
            size="large"
            elevation="0"
            @click="openModal()"
          >
            {{ $t("connectors.apiKeys.add") }}
          </v-btn>
        </template>
      </empty-state>
    </template>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import TableWrapper from "@/components/TableWrapper.vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import EmptyState from "@/components/EmptyState.vue";
import { useI18n } from "vue-i18n";
import type { ApiKey } from "@/_store/connectors/api-credentials.module";
import { useApiCredentialsStore } from "@/_store/connectors/api-credentials.module";
import { format, formatDistanceToNow } from "date-fns";
import moment from "moment";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  getDaysUntil,
  isActionRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { ConnectorActions } from "@/constants/connectors";
import { ModalWidth } from "@/constants/modals";
import AddApiKey from "@/components/modals/AddApiKey.vue";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

export default defineComponent({
  components: { TableWrapper, EmptyState },
  setup() {
    const apiCredentialsStore = useApiCredentialsStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();

    const { getApiKeys, addApiKey, removeApiKey } = apiCredentialsStore;
    const { showSkeletonLoader, apiKeys } = storeToRefs(apiCredentialsStore);
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CONNECTORS
      );
    });

    const openModal = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: ConnectorActions.ADD_API_KEY,
        component: AddApiKey,
        width: ModalWidth.MEDIUM,
        callback: addApiKey,
        disable: actionNotAllowed.value,
      });

      dialogsStore.openDialog({
        ...dialogConfig,
        header: {
          title: i18n.t("modals.addApiKey.title"),
          close: true,
        },
        persistOnAction: true,
      });
    };

    const removeKey = (item: ApiKey) => {
      const dialogConfig = confirmationDialogsConfigConstructor({
        action: ConnectorActions.REMOVE_API_KEY,
        callback: () => removeApiKey(item),
        disable: isActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.CONNECTORS
        ),
      });

      dialogsStore.openDialog(dialogConfig);
    };

    const isTokenExpired = (item: ApiKey) => {
      const isExpired = moment(item.expirationTime).diff(moment(), "days");
      return isExpired < 0;
    };

    onMounted(() => {
      getApiKeys();
    });

    return {
      openModal,
      removeKey,
      formatDistanceToNow,
      isTokenExpired,
      showSkeletonLoader,
      apiKeys,
      getDaysUntil,
      coronetSkeletonLoaderTypes,
      format,
    };
  },
});
</script>
