import { type Ref, ref, watch } from "vue";
import type { GenericCallback } from "@/types";

/**
 * Composable which handle the behavior of the list table. Handles items change, row click, which row is active and quantity of pages.
 * @param props Contains `items` and `itemKey` which are used to emit and handle data
 * @param previewChangeCallback
 */
export function useListTable<T>(
  props: { items: Ref<T[]>; itemKey: keyof T },
  previewChangeCallback: GenericCallback<[T | null]>
) {
  const activeRowId = ref<unknown>(null);

  watch(
    props.items,
    (items: T[]) => {
      if (items.length) {
        // once items array changes, update preview is emitted
        const activeItem = items.find((item: T) => item[props.itemKey] === activeRowId.value);
        if (activeItem) {
          previewChangeCallback(activeItem);
        } else {
          previewChangeCallback(items[0]);
          activeRowId.value = items[0][props.itemKey];
        }
      } else {
        activeRowId.value = null;
        previewChangeCallback(null);
      }
    },
    { immediate: true, deep: true }
  );

  const isRowActive = (itemId: string) => {
    return itemId === activeRowId.value;
  };
  const handleRowClick = (item: T) => {
    activeRowId.value = item[props.itemKey];
    previewChangeCallback(item);
  };
  return {
    activeRowId,
    isRowActive,
    handleRowClick,
  };
}
