import { computed } from "vue";
import { useRouter } from "vue-router";
import { useSubscriptionStore } from "@/_store/subscription.module";
import { TicketsModuleFilter, TicketStatus } from "@/constants/tickets";
import { SubscriptionType } from "@/constants/workplaces";
import type { WidgetVulnerability } from "@/_store/dashboard/dashboard.module";
import { isAccessRestricted } from "@/_helpers/utils";
import { getCurrentDate, getNinetyDaysAgo } from "@/constants/date-range-picker";
import { RouteName } from "@/constants/routes";

export function useWidgetContent() {
  const router = useRouter();
  const subscriptionStore = useSubscriptionStore();
  const currentPlan = computed(() => subscriptionStore.subscription.currentPlan);

  const getProtectedUsersOnlyFilter = (widget: TicketsModuleFilter) => {
    if (currentPlan.value?.subscriptionType === SubscriptionType.TRIAL) {
      return false;
    }
    return ![
      TicketsModuleFilter.ENDPOINT_DATA_GOVERNANCE,
      TicketsModuleFilter.DEVICE_WIDGET,
    ].includes(widget);
  };

  const viewVulnerability = async (
    event: { vulnerability: WidgetVulnerability; isActive: boolean },
    widget: TicketsModuleFilter
  ) => {
    await router.push({
      name: RouteName.TICKETS,
      query: {
        eventTriggers: event.vulnerability.issue,
        from: "widget",
        status: event.isActive ? TicketStatus.OPEN : TicketStatus.CLOSED,
        startTime: event.isActive ? null : getNinetyDaysAgo(),
        endTime: event.isActive ? null : getCurrentDate(),
        widget,
        protectedUsersOnly: getProtectedUsersOnlyFilter(widget).toString(),
      },
    });
  };

  const viewAllTickets = async (isActive: boolean, widget: TicketsModuleFilter) => {
    await router.push({
      name: RouteName.TICKETS,
      query: {
        from: "widget",
        status: isActive ? TicketStatus.OPEN : TicketStatus.CLOSED,
        startTime: isActive ? null : getNinetyDaysAgo(),
        endTime: isActive ? null : getCurrentDate(),
        widget,
        protectedUsersOnly: getProtectedUsersOnlyFilter(widget).toString(),
      },
    });
  };

  return {
    currentPlan,
    getProtectedUsersOnlyFilter,
    viewVulnerability,
    viewAllTickets,
    isAccessRestricted,
  };
}
