<template>
  <div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
@mixin full-height-table {
  .v-data-table__wrapper {
    height: calc(100vh - 360px);
  }
}

@mixin default-table {
  table {
    tr {
      height: 56px;
      .v-simple-checkbox {
        i {
          font-size: 16px;
          margin-left: 3px;
        }
      }
    }

    thead > tr {
      height: 56px;
    }

    th:first-child {
      padding-left: 21px;
    }

    [row-disabled] {
      background-color: rgb(var(--v-theme-gray-faint));
    }
  }

  .v-data-table__empty-wrapper > td {
    border-left: 0 !important;
  }

  .v-input--checkbox .v-input--selection-controls__input {
    margin-top: unset !important;
    margin-left: 1px !important;
  }

  .v-data-table-rows-no-data {
    height: calc(100vh - 370px);
  }
}

:deep(*) {
  .v-table__wrapper > table > thead > tr:not(.v-data-table-progress) > th {
    height: var(--v-table-header-height) !important;
  }

  .v-data-table-footer__items-per-page {
    .v-field__outline__start {
      border-radius: 33px 0 0 33px !important;
      min-width: 30px;
      border-color: rgb(var(--v-theme-indigo-pale)) !important;
    }
    .v-field__outline__end {
      border-radius: 0 33px 33px 0 !important;
      border-color: rgb(var(--v-theme-indigo-pale)) !important;
    }
  }

  .v-pagination__item {
    .v-btn:hover > .v-btn__overlay {
      background: rgb(var(--v-theme-primary)) !important;
    }

    .v-btn:hover > .v-btn__overlay {
      opacity: 0.2 !important;
    }
  }

  .icon-dots:before {
    color: rgb(var(--v-theme-primary));
  }

  .coronet-table--highlightable {
    @include default-table;
  }

  .coronet-table--list {
    @include default-table;

    table tr {
      cursor: pointer;
      height: 105px;

      &:last-child {
        td {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
        }
      }
    }

    .v-table__wrapper {
      border-radius: 0 !important;
      box-shadow: none !important;
      overflow-y: hidden !important;
      overflow-x: auto !important;
    }
  }

  .coronet-table--pagination {
    height: 40px;
    border: none;
    overflow: hidden;
  }

  td {
    white-space: nowrap;
  }

  th {
    font-size: 14px !important;
    color: rgba(var(--v-theme-primary), 0.65) !important;
    white-space: nowrap;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
  }

  td {
    color: rgb(var(--v-theme-primary));
    font-size: 16px !important;
  }

  .coro-table-row--selected,
  .coro-table-row--active {
    background-color: rgb(var(--v-theme-indigo-pale)) !important;
    &:hover {
      background-color: rgb(var(--v-theme-indigo-pale)) !important;
    }
  }

  .checkbox-col {
    width: 10px;
  }
}
</style>
