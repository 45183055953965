<template>
  <div v-if="showFullDetails">
    <h6 class="headline6 mb-4">{{ $t("ticketDetails.fullDetails.title") }}</h6>
    <div class="full-details-wrapper">
      <div class="d-flex align-center px-4 pt-4">
        <v-icon
          class="malware-icon mr-3"
          data-testid="tickets-page-malware-icon"
          size="40"
          icon="$malware"
        >
        </v-icon>
        <div class="d-flex flex-column">
          <div class="subtitle1" data-testid="tickets-page-malware-name">
            {{ malwareName }}
          </div>
          <div
            v-if="cloudActivityLogsMetaData.logType"
            class="body2 text-capitalize"
            data-testid="tickets-page-log-type"
          >
            {{ $t("ticketDetails.type") }}:
            {{ cloudActivityLogsMetaData.logType }}
          </div>
        </div>
      </div>
      <v-divider class="my-4"></v-divider>
      <v-row class="px-4">
        <v-col cols="6">
          <template v-if="ticket.sections.triggeredUser">
            <div class="subtitle1" data-testid="tickets-page-performed-by">
              {{ $t("ticketDetails.performedBy") }}
            </div>
            <div class="body2 mb-4" data-testid="tickets-page-triggered-user">
              {{ ticket.sections.triggeredUser }}
            </div>
          </template>

          <template v-if="malwareMetaData.fileName">
            <div class="subtitle1" data-testid="tickets-page-file-name-title">
              {{ $t("ticketDetails.fileName") }}
            </div>
            <div class="body2" data-testid="tickets-page-file-name">
              {{ malwareMetaData.fileName }}
            </div>
            <div class="text--link mb-4" data-testid="tickets-page-file-path">
              {{ filePath }}
            </div>
          </template>

          <template v-if="malwareMetaData.mimeType">
            <div class="subtitle1" data-testid="tickets-page-mime-type-title">
              {{ $t("ticketDetails.mimeType") }}
            </div>
            <div class="body2" data-testid="tickets-page-mime-type">
              {{ malwareMetaData.mimeType }}
            </div>
          </template>
        </v-col>
        <v-col cols="6">
          <div class="subtitle1" data-testid="tickets-page-created-time-title">
            {{ $t("ticketDetails.created") }}
          </div>
          <div class="body2 mb-4" data-testid="tickets-page-created-time">
            {{ getFormattedDateTime(ticket.creationTime, "MMM, DD, h:mm a") }}
          </div>

          <div class="subtitle1" data-testid="tickets-page-modified-time-title">
            {{ $t("ticketDetails.lastModified") }}
          </div>
          <div class="body2 mb-4" data-testid="tickets-page-modified-time">
            {{ getFormattedDateTime(ticket.endTime, "MMM, DD, h:mm a") }}
          </div>

          <template v-if="malwareMetaData.owner">
            <div class="subtitle1" data-testid="tickets-page-owned-by-title">
              {{ $t("ticketDetails.ownedBy") }}
            </div>
            <div class="body2" data-testid="tickets-page-owned-by">
              {{ malwareMetaData.owner }}
            </div>
          </template>
        </v-col>
      </v-row>
      <v-divider v-if="showDivider"></v-divider>
      <div class="px-4 my-4">
        <template v-if="malwareMetaData.sharedWith && malwareMetaData.sharedWith.length">
          <div class="subtitle1" data-testid="tickets-page-shared-with-title">
            {{ $t("ticketDetails.sharedWith") }}
          </div>
          <div class="body2 mb-4" data-testid="tickets-page-shared-with">
            {{ malwareMetaData.sharedWith.join(", ") }}
          </div>
        </template>
        <template v-if="location">
          <div class="subtitle1" data-testid="tickets-page-ip-address-title">
            {{ $t("ticketDetails.ipAddress") }}
          </div>
          <div class="body2 mb-4" data-testid="tickets-page-ip-address">
            {{ location.ip }}
          </div>
          <template v-if="location.countryName">
            <div class="subtitle1" data-testid="tickets-page-country-title">
              {{ $t("ticketDetails.country") }}
            </div>
            <div class="body2 mb-4" data-testid="tickets-page-country">
              {{ location.countryName }}
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import camelCase from "lodash/camelCase";
import { getFormattedDateTime } from "@/_helpers/utils";
import { computed, defineComponent, type PropType } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { TicketType } from "@/constants/tickets";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const malwareMetaData = computed(() => props.ticket.sections?.malwareMetaData);
    const cloudActivityLogsMetaData = computed(
      () => props.ticket.sections?.cloudActivityLogsMetaData?.[0]
    );
    const location = computed(() => props.ticket.sections?.locations?.[0]);

    const showFullDetails = computed(
      () => props.ticket.eventType === TicketType.MALWARE_IN_CLOUD_DRIVE && malwareMetaData.value
    );

    const showDivider = computed(() => malwareMetaData.value?.sharedWith?.length || location.value);

    const malwareName = computed(() => {
      if (malwareMetaData.value?.virusName === "unknown") {
        const detectedBy = i18n.t(
          `ticketDetails.fullDetails.${camelCase(malwareMetaData.value?.detectedBy)}`
        );
        return i18n.t("ticketDetails.fullDetails.unknownDetectedBy", {
          detectedBy,
        });
      }
      return malwareMetaData.value?.virusName;
    });

    const filePath = computed(
      () => malwareMetaData.value?.path && malwareMetaData.value.path.split("/").join(" › ")
    );

    return {
      malwareMetaData,
      location,
      cloudActivityLogsMetaData,
      showFullDetails,
      showDivider,
      malwareName,
      filePath,
      getFormattedDateTime,
    };
  },
});
</script>

<style lang="scss" scoped>
.malware-icon {
  font-size: 40px;
}
.full-details-wrapper {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>
