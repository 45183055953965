<template>
  <div data-testid="entity-activity-logs">
    <div class="d-flex justify-space-between mb-4">
      <div class="headline6">{{ $t("general.activityLog") }}</div>
      <router-link v-if="activityLogs.length && !hideAllActivityLink" :to="activityLogsLink">
        <a class="coro-link">{{ $t("general.allActivity") }} ›</a>
      </router-link>
    </div>
    <div class="table-container" data-testid="tickets-activity-logs-container">
      <div v-if="accessRestricted" class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="80" icon="$alertTriangle" />
        <span class="subtitle1">{{ $t("general.noAccess") }}</span>
      </div>
      <activity-logs-table
        v-else-if="activityLogs.length"
        :items="activityLogs"
        :total="activityLogsTotal"
        :loading="loading"
        :pagination="pagination"
        virtual-scrollable
        :show-icon-column="false"
        @undo-action="$emit('undo-clicked', $event)"
        @last-item-reached="$emit('last-item-reached', $event)"
      />
      <div v-else class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="80" icon="$activityLogs" />
        <span class="subtitle1">{{ $t("general.noActivity") }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ActivityLogsTable from "@/components/tables/ActivityLogsTable.vue";
import { computed, defineComponent, type PropType } from "vue";
import type { ActivityLogItem } from "@/_store/activity-logs.module";

export default defineComponent({
  components: {
    ActivityLogsTable,
  },
  props: {
    activityLogs: {
      type: Array as PropType<ActivityLogItem[]>,
      default() {
        return [];
      },
    },
    activityLogsTotal: {
      type: Number,
      default: 5,
    },
    entityId: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchPrefix: {
      type: String,
      default: null,
    },
    hideAllActivityLink: {
      type: Boolean,
      default: false,
    },
    accessRestricted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const pagination = {
      page: 0,
      pageSize: props.activityLogsTotal,
    };

    const activityLogsLink = computed(() => {
      if (props.searchPrefix) {
        return `/portal/settings/activity-logs?search=${props.searchPrefix}:${props.entityId}`;
      }
      return `/portal/settings/activity-logs?search=${props.entityId}`;
    });

    return {
      pagination,
      activityLogsLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.table-container {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}

.icon-activity-logs:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}

.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}

:deep(*) {
  .v-data-table tr {
    height: 105px !important;
  }
  .v-table__wrapper {
    box-shadow: unset !important;
    border-radius: unset !important;
    max-height: 450px;
    overflow: auto;
  }
  td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}
</style>
