<template>
  <DoughnutChart ref="chartRef" :data="data" :options="options" @click="handleChartClick" />
</template>

<script>
import { defineComponent, ref } from "vue";
import { Doughnut, getElementsAtEvent } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register Chart.js modules
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

export default defineComponent({
  components: {
    DoughnutChart: Doughnut,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label(tooltipItem) {
                  return tooltipItem.label;
                },
                title: () => null,
              },
              caretSize: 0,
              backgroundColor: "#262260",
              bodyFontColor: "#ffffff",
              bodyFontSize: 14,
              displayColors: false,
            },
          },
          layout: {
            padding: 10,
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          hoverOffset: 5,
          onHover: (event, activeElements) => {
            if (activeElements?.length > 0) {
              event.native.target.style.cursor = "pointer";
            } else {
              event.native.target.style.cursor = "auto";
            }
          },
        };
      },
    },
  },
  setup(props, { emit }) {
    const chartRef = ref(null);

    const handleChartClick = (event) => {
      const {
        value: { chart },
      } = chartRef;

      if (!chart) {
        return;
      }

      emit("on-chart-click", getElementsAtEvent(chart, event)[0].index);
    };

    return {
      chartRef,
      handleChartClick,
    };
  },
});
</script>
