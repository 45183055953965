<template>
  <div>
    <div>
      <div
        class="subtitle1 mb-4 mt-4"
        v-text="$t(`modals.selectPredefinedVpnLabel.selectedLabelConfirmation`)"
      />
      <div
        class="selected-label-block body2 pl-4 d-flex align-center"
        v-text="
          $t(`modals.selectPredefinedVpnLabel.selectedLabel`, {
            name: config.item.name,
          })
        "
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<{ item: { name: string }; action: string }>,
      default: () => ({ item: { name: "" } }),
    },
  },
});
</script>
<style lang="scss" scoped>
.selected-label-block {
  background: rgb(var(--v-theme-indigo-pale));
  height: 32px;
  border-radius: 4px;
}
</style>
