<template>
  <v-form ref="form" v-model="valid" validate-on="lazy input">
    <div class="body2 mb-3">{{ $t("modals.extendMspTrial.description") }}</div>
    <menu-date-picker @update:date="onDateSelect($event)">
      <v-text-field
        :modelValue="convertExpirationTime()"
        data-testid="add-psa-connector-modal-textfield"
        class="datepicker-activator"
        :label="$t('modals.extendMspTrial.expirationDate')"
        :placeholder="$t('modals.extendMspTrial.expirationDate')"
        variant="outlined"
        :rules="[required()]"
      ></v-text-field>
    </menu-date-picker>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, ref, watch } from "vue";
import { format } from "date-fns";
import moment from "moment";
import MenuDatePicker from "@/components/MenuDatePicker.vue";
import { required } from "@/_helpers/validators";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { MspWorkspaceDialogItem } from "@/_store/msp.module";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  components: { MenuDatePicker },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<MspWorkspaceDialogItem>>,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const localValue = cloneDeep(props.config);
    const valid = ref(true);
    const form = ref();

    onMounted(() => {
      emit("update:localValue", localValue);
      emit("update:valid", true);
    });

    const min = () => {
      return format(new Date(), "yyyy-MM-dd");
    };

    const convertExpirationTime = () => {
      return localValue.item.endDate ? format(localValue.item.endDate, "yyyy-MM-dd") : null;
    };
    const onDateSelect = (val: Date) => {
      localValue.item.endDate = moment(val).endOf("day").utc().valueOf();
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
        emit("update:valid", true);
      },
      { deep: true }
    );

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    return {
      form,
      valid,
      required,
      min,
      localValue,
      convertExpirationTime,
      onDateSelect,
      validate,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-input__append {
    margin: 0;
  }

  .v-input__slot {
    padding: 0 0 0 12px !important;
  }

  .v-text-field.v-input--dense .v-input__prepend-inner,
  .v-text-field.v-input--dense .v-input__append-inner {
    margin-top: unset !important;

    .v-btn:not(.v-btn--round).v-size--default {
      height: 40px !important;
    }
  }
}
.datepicker-activator {
  cursor: pointer;
}
</style>
