import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { defineStore } from "pinia";
import type { ProxyDirection } from "@/constants/email-proxy";
import type { ProxyStatus } from "@/constants/email-proxy";

export interface EmailProxySettings {
  inboundProxy: {
    emailSecuritySettings: string;
    dataGovernanceSettings: string;
  };
  outboundProxy: {
    emailSecuritySettings: string;
    dataGovernanceSettings: string;
  };
}

export interface EmailProxyRelaySmtpUrl {
  active: boolean;
  host: string;
  port: string;
}

export enum ProxyFailedReasons {
  CORO_MX_IS_NOT_PRIMARY = "CORO_MX_IS_NOT_PRIMARY",
  PROXY_POSTFIX_RELAY_HOST_NOT_REACHABLE = "PROXY_POSTFIX_RELAY_HOST_NOT_REACHABLE",
  PROXY_POSTFIX_SETTINGS_INACTIVE = "PROXY_POSTFIX_SETTINGS_INACTIVE",
  GENERAL_VERIFICATION_FAIL = "GENERAL_VERIFICATION_FAIL",
  PROXY_VERIFICATION_TIMEOUT = "PROXY_VERIFICATION_TIMEOUT",
  USER_VERIFICATION_TIMEOUT = "USER_VERIFICATION_TIMEOUT",
}

export interface EmailProxyDomain {
  id: string;
  name: string;
  direction: ProxyDirection;
  status: ProxyStatus;
  timestamp: number;
  failedReason?: ProxyFailedReasons;
  relaySmtpUrls: EmailProxyRelaySmtpUrl[];
  subnets: EmailProxyRelaySmtpUrl[];
}

export interface EmailProxyToken {
  sub: string;
  iat: number;
  exp: number;
  customerId: string;
  proxyDomainId: string;
  tokenType: string;
}

interface EmailProxyState {
  settings: EmailProxySettings;
  domains: EmailProxyDomain[];
  isProxyActive: boolean;
  proxyStatus: string | null;
  showSkeletonLoader: boolean;
  loading: boolean;
}

const defaultEmailProxyState = {
  settings: {
    inboundProxy: {
      emailSecuritySettings: "warning",
      dataGovernanceSettings: "warning",
    },
    outboundProxy: {
      emailSecuritySettings: "warning",
      dataGovernanceSettings: "warning",
    },
  },
  domains: [],
  isProxyActive: false,
  proxyStatus: null,
  showSkeletonLoader: true,
  loading: false,
};

export const useEmailProxyStore = defineStore("emailProxyStore", {
  state: (): EmailProxyState => ({ ...defaultEmailProxyState }),
  actions: {
    async init(direction: ProxyDirection) {
      this.showSkeletonLoader = true;

      await this.getDomains(direction);

      this.showSkeletonLoader = false;
    },
    async updateProxySettings(data: EmailProxySettings) {
      const request = {
        ...api.emailProxySettings(),
        data,
        method: "PUT",
      };

      try {
        const settings = await axiosInstance.request(request);

        this.settings = settings.data;
      } catch (err) {
        console.error(err);
      }
    },
    async getDomains(direction: ProxyDirection) {
      this.loading = true;

      const request = {
        ...api.emailProxyDomains(),
        params: {
          direction,
        },
      };

      try {
        const domains = await axiosInstance.request(request);

        this.domains = domains.data;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async addDomain(payload: Partial<EmailProxyDomain>) {
      this.loading = true;

      const request = {
        ...api.emailProxyDomains(),
        data: payload,
        method: "POST",
      };

      try {
        await axiosInstance.request(request);
        this.getDomains(payload.direction!);
      } catch (err) {
        console.error(err);
        this.loading = false;
        throw err;
      }

      this.loading = false;
    },
    async editDomain(payload: EmailProxyDomain) {
      this.loading = true;

      const request = {
        ...api.editEmailProxyDomain(payload.id),
        data: payload,
        method: "PUT",
      };

      try {
        await axiosInstance.request(request);
        this.getDomains(payload.direction);
      } catch (err) {
        console.error(err);
        this.loading = false;
        throw err;
      }

      this.loading = false;
    },
    async removeDomain(payload: EmailProxyDomain) {
      this.loading = true;

      const request = {
        ...api.editEmailProxyDomain(payload.id),
        method: "DELETE",
      };

      try {
        await axiosInstance.request(request);

        this.getDomains(payload.direction);
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async testDomain(payload: EmailProxyDomain & { testEmail: string }) {
      this.loading = true;

      const request = {
        ...api.testEmailProxyDomain(payload.id),
        params: {
          testEmail: payload.testEmail,
        },
      };

      try {
        await axiosInstance.request(request);

        this.getDomains(payload.direction);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async completeProxyTest(payload: EmailProxyToken) {
      this.showSkeletonLoader = true;
      const request = {
        ...api.completeEmailProxyTest(payload.proxyDomainId),
      };

      try {
        const response = await axiosInstance.request(request);
        this.showSkeletonLoader = false;
        return response;
      } catch (error) {
        console.error(error);
        this.showSkeletonLoader = false;
        throw error;
      }
    },
    async getEmailProxyStatus(
      { direction }: { direction: ProxyDirection },
      proxyRecipientsDomain?: string
    ) {
      const request = {
        ...api.getEmailProxyStatus(),
        params: {
          direction,
          proxyRecipientsDomain,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);

        this.isProxyActive = data.isProxyActive;

        if (data.proxyStatus) {
          this.proxyStatus = data.proxyStatus;
        }

        return data.isProxyActive;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
});
