<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom" max-height="450">
    <template v-slot:activator="{ props }">
      <div v-bind="props" role="button" class="d-flex align-center">
        <span>
          {{ currentWorkspace.name }}
        </span>
        <v-icon class="ml-2" size="10" icon="$triangle"></v-icon>
      </div>
    </template>

    <v-card min-width="400" elevation="2">
      <v-list>
        <v-text-field
          density="comfortable"
          rounded
          min-width="400"
          class="mr-4 ml-4 mb-3 mt-1"
          clear-icon="$x"
          :placeholder="$t('general.search')"
          prepend-inner-icon="$search"
          clearable
          variant="filled"
          bg-color="indigo-faint"
          :hide-details="true"
          @update:model-value="workspaceSearch = $event"
        >
        </v-text-field>

        <v-list-item density="comfortable">
          <template v-slot:title>
            <div class="caption">
              <span v-text="`${total} workspaces`"></span>
            </div>
          </template>
        </v-list-item>
        <v-list-item
          v-for="(workspace, i) in workspaces"
          :id="i.toString()"
          density="comfortable"
          variant="flat"
          height="56"
          :key="workspace.workspaceId"
          :active="workspace.workspaceId === currentWorkspace.workspaceId"
          @click="selectWorkplace(workspace)"
          v-intersect="onIntersect"
        >
          <template v-slot:title>
            <div class="d-flex align-center">
              <v-icon class="mr-2" size="40" :icon="iconsMap[workspace.type]"></v-icon>
              <span v-text="workspace.name"></span>
            </div>
          </template>
        </v-list-item>
        <div v-if="loading" class="d-flex align-center justify-center">
          <v-progress-circular size="40" indeterminate></v-progress-circular>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { WorkspaceAction, WorkspaceType } from "@/constants/workplaces";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import type { WorkspaceItem } from "@/_store/workspaces.module";
import { storeToRefs } from "pinia";
import { computed, onUnmounted, ref, watch } from "vue";
import { useAccountStore } from "@/_store";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";

export default {
  setup() {
    const workspaceStore = useWorkspacesStore();
    const workspaceSearch = ref("");
    const router = useRouter();
    let workspaceTimer: ReturnType<typeof setTimeout>;
    const { setPagination, getWorkspaces, resetState, setWorkspace, setWorkspaces } =
      workspaceStore;
    const { total, workspaces, pagination, loading } = storeToRefs(workspaceStore);
    const accountStore = useAccountStore();
    const menu = ref(false);
    const currentWorkspace = computed(() => ({
      name: accountStore.account.customerName,
      workspaceId: accountStore.account.workplace,
    }));

    const selectWorkplace = async (workplace: WorkspaceItem) => {
      setWorkspace(workplace);
      await router.push({ name: RouteName.DASHBOARD, replace: true, force: true });
      menu.value = false;
    };

    const onIntersect = async (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === (workspaces.value ?? []).length - 1) {
        // last tab reached in backend, don't do new request
        if (intersectedItemId === total.value - 1) {
          return;
        }
        loadMoreWorkplaces();
      }
    };

    const loadMoreWorkplaces = () => {
      setPagination({
        page: pagination.value.page + 1,
        pageSize: 25,
      });
      getWorkspaces(workspaceSearch.value, true);
    };

    watch(workspaceSearch, (newVal) => {
      // cancel pending call
      clearTimeout(workspaceTimer);
      // delay new call 500ms
      workspaceTimer = setTimeout(() => {
        setWorkspaces([]);
        setPagination({
          page: 0,
          pageSize: 25,
        });
        getWorkspaces(newVal.length < 3 ? "" : newVal);
      }, 500);
    });

    watch(menu, (opened) => {
      if (opened) {
        setPagination({
          page: 0,
          pageSize: 25,
        });
        getWorkspaces();
      }
    });

    onUnmounted(() => {
      resetState();
    });

    return {
      menu,
      loading,
      workspaceSearch,
      workplacesActions: WorkspaceAction,
      workspaces,
      total,
      pagination,
      selectWorkplace,
      onIntersect,
      currentWorkspace,
      iconsMap: {
        [WorkspaceType.REGULAR]: "icon-regular-plan",
        [WorkspaceType.CHANNEL]: "icon-parent-plan",
        [WorkspaceType.CHILD]: "icon-child-plan",
        [WorkspaceType.CORO_AGENT]: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-field__outline {
    display: none !important;
  }
}
</style>
