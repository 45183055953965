export enum CoroIcons {
  CLOUD_SECURITY = "cloudApps",
  CLOUD_APPS = "cloudAppsNoHexagon",
  PHISHING = "phishing",
  EMAIL_SECURITY = "emailSecurity",
  MALWARE = "malware",
  DATA_LEAKAGE = "dataLoss",
  ACCOUNT_SECURITY = "accountSecurity",
  ADMIN_USERS = "adminUsers",
  ACTIVITY_LOGS = "activityLogs",
  PROTECTED_USERS = "usersNoHexagon",
  DEVICES = "devicesNoHexagon",
  ENDPOINT_SECURITY = "devices",
  LOCATION_ACCESS = "locationAccess",
  DOWNLOAD_LINK = "downloadLink",
  PASSWORD_RESET = "passwordReset",
  ALERTS = "alerts",
  EMAIL_OCTAGON = "emailOctagon",
  DATA_OCTAGON = "dataOctagon",
  AUTHENTICATION = "auth",
  SETTINGS = "settings",
  EMAIL = "email",
  EMAIL_OUTLINED = "emailOutlined",
  EMAILS = "emails",
  USER_DATA_GOVERNANCE = "data",
  EMAIL_PROXY = "accessRestrictions",
  CONNECTORS = "connectors",
  REPORTS = "dataNoHexagon",
  CORO_MAIL = "coroMail",
  NETWORK = "addonNetwork",
  ENDPOINT_DATA_GOVERNANCE = "endpointDataGovernance",
  EDR = "edrHexagon",
  MOBILE_DEVICE_MANAGEMENT = "mdm",
  BILLING = "billing",
  SOC = "socSettings",
}
