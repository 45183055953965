<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <v-text-field
      v-model="localValue.item.firstName"
      data-testid="add-admin-modal-firstname"
      variant="outlined"
      class="mt-3"
      :rules="required"
      :label="$t('modals.editSocContacts.firstName')"
      :placeholder="$t('modals.editSocContacts.firstName')"
    ></v-text-field>
    <v-text-field
      v-model="localValue.item.lastName"
      data-testid="add-admin-modal-lastname"
      variant="outlined"
      class="mt-3"
      :rules="required"
      :label="$t('modals.editSocContacts.lastName')"
      :placeholder="$t('modals.editSocContacts.lastName')"
    ></v-text-field>
    <v-autocomplete
      v-model="localValue.item.email"
      class="mt-3"
      :loading="dataLoading"
      :items="users"
      :label="$t('modals.editSocContacts.email')"
      :placeholder="$t('modals.editSocContacts.email')"
      :rules="required"
      variant="outlined"
      no-filter
      :no-data-text="$t('general.noData')"
      data-testid="add-admin-modal-email"
      hide-no-data
      item-title="email"
      item-value="email"
      @update:search="onAdminUserSearch($event)"
    >
    </v-autocomplete>

    <div class="subtitle1">
      {{ $t("modals.editSocContacts.notificationSettings.title") }}
    </div>
    <v-radio-group v-model="localValue.item.notification" class="mt-4">
      <v-radio
        v-for="notificationType in notifications"
        :key="notificationType"
        :value="notificationType"
        class="mb-4"
        :ripple="false"
        :label="$t(`modals.editSocContacts.notificationSettings.${notificationType}`)"
      ></v-radio>
    </v-radio-group>

    <div class="subtitle1">
      {{ $t("modals.editSocContacts.closureSettings.title") }}
    </div>
    <v-radio-group v-model="localValue.item.closureNotification" class="mt-4">
      <v-radio
        v-for="notificationType in closureNotifications"
        :key="notificationType"
        :value="notificationType"
        class="mb-4"
        :ripple="false"
        :label="$t(`modals.editSocContacts.closureSettings.${notificationType}`)"
      ></v-radio>
    </v-radio-group>
  </v-form>
</template>

<script lang="ts">
import Patterns from "@/constants/patterns";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import {
  SocContactClosureNotification,
  SocContactNotificationTypes,
  useSocContactStore,
} from "@/_store/soc/soc-contact.module";
import debounce from "lodash/debounce";
import type { AdminUser } from "@/_store";

interface NewSocContactItem {
  item: {
    firstName: string;
    lastName: string;
    email: null | string;
    notification: string;
    closureNotification: string;
  };
  action: string;
}

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { getAdmins } = useSocContactStore();
    const localValue = ref<NewSocContactItem>({
      item: cloneDeep(props.config.item),
      action: props.config.action,
    });
    const valid = ref(true);
    const form = ref();
    const dataLoading = ref(false);
    const users = ref<AdminUser[]>([]);
    const required = reactive([(v: string) => (!v ? i18n.t("validations.required") : true)]);
    const emailRules = reactive([
      (email: string) => {
        if (!email || email.length <= 0) {
          return i18n.t("validations.required");
        }
        if (!Patterns.EMAIL.test(email) || Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(email.trim())) {
          return i18n.t("validations.email");
        }
        return true;
      },
    ]);
    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const notifications = [
      SocContactNotificationTypes.EMAIL,
      SocContactNotificationTypes.DO_NOT_NOTIFY,
    ];

    const closureNotifications = [
      SocContactClosureNotification.WEEKLY,
      SocContactClosureNotification.PER_TICKET,
      SocContactClosureNotification.DO_NOT_NOTIFY,
    ];

    const onAdminUserSearch = debounce(async function (value) {
      if (localValue.value.item.email === value) return;
      dataLoading.value = true;
      users.value = await getAdmins(value);
      dataLoading.value = false;
    }, 500);

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    onMounted(() => {
      onAdminUserSearch("");
    });

    watch(
      valid,
      (newVal) => {
        if (newVal === null) return;
        emit("update:valid", newVal);
      },
      { deep: true, immediate: false }
    );

    return {
      localValue,
      valid,
      form,
      required,
      emailRules,
      notifications,
      closureNotifications,
      validate,
      dataLoading,
      users,
      onAdminUserSearch,
    };
  },
});
</script>

<style lang="scss" scoped></style>
