import { i18n } from "@/plugins/i18n";
import Patterns from "@/constants/patterns";
import { required } from "@/_helpers/validators";
import { computed, reactive, ref, type Ref } from "vue";
import { onlySpaces } from "@/_helpers/utils";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { type MspWorkspaceDialogItem, NotificationsLevel } from "@/_store/msp.module";
import { WorkspaceType } from "@/constants/workplaces";

export enum WorkspaceCreationSteps {
  COMPANY_DETAILS = "companyDetails",
  MSP_SETTINGS = "mspSettings",
  BRANDING = "branding",
  SUBSCRIPTION = "subscriptionBundles",
  REVIEW_SUBSCRIPTION = "reviewSubscription",
  BILLING = "billing",
}

export function useMspWorkspaceModal(localValue: Ref<DialogDataConfig<MspWorkspaceDialogItem>>) {
  const currentStep = ref(WorkspaceCreationSteps.COMPANY_DETAILS);

  const showPreviousStepBtn = computed(() => {
    return currentStep.value !== WorkspaceCreationSteps.COMPANY_DETAILS;
  });

  const showCompleteBtn = computed(() => {
    return currentStep.value === WorkspaceCreationSteps.REVIEW_SUBSCRIPTION;
  });

  const getCurrentStepNumber = (steps: string[]) => {
    return steps.indexOf(currentStep.value) + 1;
  };

  const getStepsProgress = (steps: string[]) => {
    return (getCurrentStepNumber(steps) * 100) / steps.length;
  };

  const companyNameRules = reactive([
    (companyName: string) => {
      if (!companyName || companyName.length <= 0) {
        return i18n.global.t("validations.required");
      }
      if (companyName.length > 100) {
        return i18n.global.t("validations.maxNCharacters", { n: 100 });
      }
      return true;
    },
  ]);

  const adminEmailRules = reactive([
    (email: string) => {
      if (!email || email.length <= 0) {
        return i18n.global.t("validations.required");
      }
      if (email.length > 100) {
        return i18n.global.t("validations.maxNCharacters", { n: 100 });
      }
      if (!Patterns.EMAIL.test(email) || Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(email.trim())) {
        return i18n.global.t("validations.email");
      }
      return true;
    },
  ]);
  const supportEmailRules = reactive([
    (email: string) => {
      if (!email) return true;
      if (email.length > 100) {
        return i18n.global.t("validations.maxNCharacters", { n: 100 });
      }
      if (!Patterns.EMAIL.test(email) || Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(email.trim())) {
        return i18n.global.t("validations.email");
      }
      return true;
    },
  ]);
  const aliasRules = reactive([
    (alias: string) => {
      if (!alias) return true;
      if (alias.length > 40) {
        return i18n.global.t("validations.maxNCharacters", { n: 40 });
      }
      if (alias.includes(" ")) {
        return i18n.global.t("validations.noSpacesAllowed");
      }
      if (!Patterns.SUBDOMAIN.test(alias)) {
        return i18n.global.t("validations.invalidSubdomain");
      }
      return true;
    },
  ]);
  const brandColorRules = reactive([
    (color: string) => {
      if (!color) return true;
      if (!Patterns.HEX_COLOR.test(color)) {
        return i18n.global.t("validations.invalidHexColor");
      }
      return true;
    },
  ]);
  const displayNameRules = reactive([
    (displayName: string) => {
      if (!displayName || displayName.length <= 0 || onlySpaces(displayName)) {
        return i18n.global.t("validations.required");
      }
      if (displayName.length > 30) {
        return i18n.global.t("validations.maxNCharacters", { n: 30 });
      }
      return true;
    },
  ]);
  const domainRules = reactive([
    (domain: string) => {
      if (!domain || domain.length <= 0) {
        return i18n.global.t("validations.required");
      }
      if (domain.length > 40) {
        return i18n.global.t("validations.maxNCharacters", { n: 40 });
      }
      if (!Patterns.DOMAIN.test(domain)) {
        return i18n.global.t("validations.domain");
      }
      if (Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(domain.trim())) {
        return i18n.global.t("validations.domainZoneIsNotAllowed");
      }
      return true;
    },
  ]);
  const limitProtectedUsersRules = reactive([
    (number: string) => {
      if (!number) return true;
      if (parseInt(number) <= 0) {
        return i18n.global.t("validations.onlyPositiveNumbers");
      }
      if (parseInt(number) > 2147483648) {
        return i18n.global.t("validations.maxNumberAllowed", { n: 2147483648 }, 2147483648);
      }
      if (!Patterns.ONLY_DIGITS.test(number)) return i18n.global.t("validations.onlyInteger");
      return true;
    },
  ]);

  const filterKey = (e: KeyboardEvent) => {
    const key = e.key;
    // do not allow - '.', 'e', '-', '+'
    if (["-", "+", "e", "."].includes(key)) return e.preventDefault();
  };

  const aliasHint = computed(() => {
    return localValue.value.item.branding.alias
      ? i18n.global.t("modals.editWorkspace.branding.aliasFullDomainHint", {
          domain: localValue.value.item.branding.alias,
        })
      : i18n.global.t("modals.editWorkspace.branding.aliasHint");
  });

  const notificationLevels = computed(() => {
    return localValue.value.item.parentWorkspaceId || localValue.value.item.parentDomain
      ? [
          NotificationsLevel.NONE,
          NotificationsLevel.PARENT,
          NotificationsLevel.ALL,
          NotificationsLevel.LOCAL_ADMINS,
        ]
      : [NotificationsLevel.NONE, NotificationsLevel.PARENT, NotificationsLevel.ALL];
  });

  const getNotificationLevelText = (
    workspaceType: WorkspaceType,
    notificationLevel: NotificationsLevel,
    domain?: string
  ) => {
    const isSubchannelWorkspace =
      workspaceType === WorkspaceType.CHANNEL &&
      (localValue.value.item.parentWorkspaceId || localValue.value.item.parentDomain);

    if (isSubchannelWorkspace && notificationLevel === NotificationsLevel.PARENT) {
      return i18n.global.t(
        `modals.createWorkspace.notificationLevels.${workspaceType}.subchannelParent`,
        {
          domain,
        }
      );
    }
    return i18n.global.t(
      `modals.createWorkspace.notificationLevels.${workspaceType}.${notificationLevel}`,
      {
        domain,
      }
    );
  };

  return {
    required,
    companyNameRules,
    adminEmailRules,
    supportEmailRules,
    aliasRules,
    brandColorRules,
    displayNameRules,
    domainRules,
    limitProtectedUsersRules,
    filterKey,
    aliasHint,
    notificationLevels,
    currentStep,
    showPreviousStepBtn,
    showCompleteBtn,
    getCurrentStepNumber,
    getStepsProgress,
    getNotificationLevelText,
  };
}
