<template>
  <v-card v-if="!isEmpty(getBetaTag(addonOrModule))">
    <v-card-text class="ml-4 mr-4 mt-4 mb-4">
      <div class="subtitle1">
        {{ $t("betaBanner.title", { addonOrModule: title }) }}
      </div>
      <div class="body1 mt-1">
        {{ $t("betaBanner.subtitle") }}
      </div>

      <template v-if="expanded">
        <div v-for="issue in potentialIssues" :key="issue" class="mt-3">
          <div class="subtitle1"><span class="mr-2">·</span> {{ $t(`betaBanner.${issue}`) }}</div>
          <div class="body1 mt-1 ml-4" v-html="$t(`betaBanner.${issue}Description`)" />
        </div>
      </template>

      <div class="coro-link mt-3" @click="expanded = !expanded">
        {{ expanded ? $t("general.seeLess") : $t("general.seeMore") }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import isEmpty from "lodash/isEmpty";
import { getBetaTag } from "@/_helpers/utils";
import { ref } from "vue";

export default {
  props: {
    addonOrModule: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const expanded = ref(true);
    return {
      expanded,
      isEmpty,
      getBetaTag,
      potentialIssues: [
        "limitedSupport",
        "potentialBugs",
        "notForProduction",
        "constantImprovements",
        "releaseUpdates",
      ],
    };
  },
};
</script>

<style scoped lang="scss"></style>
