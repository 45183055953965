import { AgentDeploymentChannel, OsType } from "@/constants/devices";
import { defineStore } from "pinia";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export interface DevicesSettingsState {
  downloadClientPageLink: string;
  settings: DevicesSettings;
  selectedChannel: AgentDeploymentChannel;
  showSkeletonLoader: boolean;
  availableVersions: DeviceVersionItem[];
  labels: DeviceLabel[];
  loading: boolean;
  wifiPhishingSettings: WifiPhishingSettings;
  ngavSettings: NgavSettings;
}

export interface DevicesSettings {
  heartbeatInterval: number;
  tamperResistanceEnabled: boolean;
  tamperResistanceLabels: DeviceLabel[];
  autoUpdateLabels: DeviceLabel[];
  visibilityModeEnabled: boolean;
  visibilityModeLabels: DeviceLabel[];
}

export interface DeviceLabel {
  id: string;
  name: string;
  color: string;
  deviceCount: number;
  predefined: boolean;
  inUseBySettings: boolean;
}

export interface DeviceVersionItem {
  id: string;
  version: string;
  marketingVersion: string;
  osType: OsType.WINDOWS | OsType.OSX;
  releaseNotes: string[];
  releaseDate: number;
  isStable: boolean;
  installedOnDevices: number;
  channel: AgentDeploymentChannel;
  channelTransitions: AgentDeploymentChannel[];
}

export interface NgavSettings {
  realTimeMalwareProtectionEnabled: boolean;
  atcEnabled: boolean;
  atcLabels: DeviceLabel[];
  vssPolicyEnabled: boolean;
  vssPolicyLabels: DeviceLabel[];
  sharedOnAccessCooperationEnabled: boolean;
  sharedOnAccessCooperationLabels: DeviceLabel[];
  initialDevicesScanEnabled: boolean;
  initialDevicesScanLabels: DeviceLabel[];
  enforceContainerQuarantineEnabled: boolean;
  enforceContainerQuarantineLabels: DeviceLabel[];
}

export interface WifiPhishingSettings {
  wifiPhishingEnabled: boolean;
  wifiPhishingLabels: DeviceLabel[];
}

const defaultDevicesSettingsState: DevicesSettingsState = {
  downloadClientPageLink: "",
  settings: {} as DevicesSettings,
  selectedChannel: AgentDeploymentChannel.STABLE,
  showSkeletonLoader: true,
  availableVersions: [],
  labels: [],
  loading: false,
  wifiPhishingSettings: {} as WifiPhishingSettings,
  ngavSettings: {} as NgavSettings,
};

export const useDevicesSettingsStore = defineStore("devices-settings", {
  state: (): DevicesSettingsState => ({ ...defaultDevicesSettingsState }),
  getters: {
    selectedChannel() {
      const showBetaVersions =
        useFiltersStore().filters[FilterContext.AGENT_DEPLOYMENT].showBetaVersions;
      return showBetaVersions ? AgentDeploymentChannel.ALL : AgentDeploymentChannel.STABLE;
    },
    filteredLabels(state) {
      return state.labels.filter((label) => !label.predefined);
    },
  },
  actions: {
    async getDownloadClientPageLink() {
      const request = {
        ...api.downloadClientPageLink,
      };
      this.showSkeletonLoader = true;
      try {
        const { data } = await axiosInstance.request(request);
        this.downloadClientPageLink = data.downloadClientPageLink;
      } catch (e) {
        console.error(e);
      }
      this.showSkeletonLoader = false;
    },
    async getDevicesSettings() {
      const request = {
        ...api.devicesSettings,
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.settings = data;
      } catch (e) {
        console.error(e);
      }
    },
    async updateDevicesSettings(data: DevicesSettings) {
      const request = {
        ...api.devicesSettings,
        data,
        method: "PUT",
      };
      try {
        await axiosInstance.request(request);
        await this.getDevicesSettings();
      } catch (e) {
        console.error(e);
      }
    },
    async getAvailableVersions() {
      this.showSkeletonLoader = true;
      const showBetaVersions = useFiltersStore().filters.agentDeploymentFilters.showBetaVersions;
      const request = {
        ...api.devicesAgentVersions,
        params: {
          showBetaVersions,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.availableVersions = data;
      } catch (err) {
        console.error(err);
      }

      this.showSkeletonLoader = false;
    },
    async selectChannel(payload: AgentDeploymentChannel) {
      const filtersStore = useFiltersStore();
      filtersStore.setFilters(FilterContext.AGENT_DEPLOYMENT, {
        showBetaVersions: payload === AgentDeploymentChannel.ALL,
      });
      await this.getAvailableVersions();
    },
    async getLabels(showSkeletonLoader = false) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const { search } = useFiltersStore().filters.labelsFilters;
      const request = {
        ...api.devicesLabels,
        params: {
          search,
        },
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.labels = data;
      } catch (err) {
        console.error(err);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    searchLabels(searchParam: string) {
      const request = {
        ...api.searchLabels(searchParam),
      };
      return axiosInstance.request(request);
    },
    async createLabel({ name, color }: Pick<DeviceLabel, "name" | "color">) {
      this.loading = true;
      const request = {
        ...api.devicesLabels,
        method: "POST",
        data: { name: name.trim(), color },
      };

      try {
        await axiosInstance.request(request);
        await this.getLabels();
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async editLabel({ name, color, id }: Pick<DeviceLabel, "name" | "color" | "id">) {
      this.loading = true;
      const request = {
        ...api.devicesLabel(id),
        method: "PUT",
        data: { name: name.trim(), color, id },
      };

      try {
        await axiosInstance.request(request);
        await this.getLabels();
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async deleteLabel(payload: DialogDataConfig<DeviceLabel>) {
      this.loading = true;
      const request = {
        ...api.devicesLabel(payload.item.id),
        method: "DELETE",
      };

      try {
        await axiosInstance.request(request);
        await this.getLabels();
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    async getWifiPhishingSettings() {
      const request = {
        ...api.getDevicesWifiPhishingSettings(),
      };

      try {
        const response = await axiosInstance.request(request);

        this.wifiPhishingSettings = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateWifiPhishingSettings(payload: WifiPhishingSettings) {
      const request = {
        ...api.getDevicesWifiPhishingSettings(),
        method: "PUT",
        data: payload,
      };

      try {
        await axiosInstance.request(request);
        await this.getWifiPhishingSettings();
      } catch (error) {
        console.error(error);
      }
    },
    async getNgavSettings() {
      const request = {
        ...api.getNgavSettings(),
      };

      try {
        const response = await axiosInstance.request(request);

        this.ngavSettings = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateNgavSettings(payload: NgavSettings) {
      const request = {
        ...api.getNgavSettings(),
        method: "PUT",
        data: payload,
      };

      try {
        await axiosInstance.request(request);

        await this.getNgavSettings();
      } catch (error) {
        console.error(error);
      }
    },
  },
});
