<template>
  <table-wrapper>
    <div class="coronet-table--loader">
      <v-progress-linear
        v-if="loading"
        data-testid="table-progress-bar"
        indeterminate
        height="2px"
      ></v-progress-linear>
    </div>
    <v-data-table-server
      class="coronet-table--highlightable"
      :items="items"
      :headers="headers"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      item-key="id"
      @update:options="
        $emit('page-changed', { pageSize: $event.itemsPerPage, page: $event.page - 1 })
      "
    >
      <template #headers="{ columns }">
        <tr>
          <th v-for="header of columns" :key="header.key as string">
            <span :data-testid="`table-${header.key}-header`">
              {{ $t(`usersSettings.exceptionsTab.tableHeaders.${header.key}`) }}
            </span>
          </th>
          <th class="checkbox-col text-center"></th>
        </tr>
      </template>
      <!--Slot for rows-->
      <template #item="{ item, index }">
        <tr
          :key="item.userId"
          :id="`${index}`"
          :data-testid="`table-row-${index}`"
          class="service-row"
        >
          <td :data-testid="`users-table-user-col-row-${index}`">
            <div class="d-flex align-center">
              <b
                class="ml-1 mr-2"
                :class="{
                  strikethrough: getNotExcepted(item),
                }"
                :data-testid="`exceptions-table-group-name-row-${index}`"
                >{{ item.email }}</b
              >
              <span v-if="getNotExcepted(item)">
                {{ $t(`usersSettings.exceptionsTab.notExcluded`) }}
              </span>
            </div>
          </td>
          <td :data-testid="`users-table-joined-via-col-row-${index}`">
            {{ item.exceptedBy }}
          </td>
          <td :data-testid="`users-table-protection-granted-col-row-${index}`">
            {{ getFormattedDateTime(item.exceptedDateTime, "ddd, MMM D YYYY, hh:mm A") }}
          </td>
          <td @click="$event.stopPropagation()" class="w-5">
            <v-menu bottom left>
              <template #activator="{ props }">
                <!--hiding toggler for non-protectable users-->
                <v-btn
                  dark
                  icon
                  :data-testid="`users-table-action-btn-row-${index}`"
                  v-bind="props"
                >
                  <v-icon icon="$dots" />
                </v-btn>
              </template>

              <v-list>
                <template v-for="exceptionsAction in itemActions" :key="exceptionsAction">
                  <v-list-item @click="handleItemAction(exceptionsAction, item)">
                    <v-list-item-title :data-testid="`users-table-${exceptionsAction}-action`">
                      {{ $t(`usersSettings.exceptionsTab.actions.${exceptionsAction}`) }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template #no-data>
        <v-icon class="mt-3 mb-3" icon="$users" size="80" />
        <div class="headline5 mb-3">
          {{ $t("usersSettings.usersTab.noUsers") }}
        </div>
        <a
          data-testid="users-table-add-users-btn"
          class="coro-link body1"
          @click="handleItemAction(userActions.ADD_NEW_EXCEPTION)"
        >
          {{ $t("usersSettings.addExceptionsBtn") }}
        </a>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";

import { getFormattedDateTime, isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { ExceptionsAction } from "@/constants/users";
import { CoroIcons } from "@/constants/coro-icon";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import type { UserSettingsListItem } from "@/_store/users-settings/users-tab.module";
import type { Pagination } from "@/types";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<UserSettingsListItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["item-action-applied", "page-changed"],
  setup(props, { emit }) {
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      );
    });

    const getNotExcepted = (item: UserSettingsListItem) => {
      return !item.exceptedUser;
    };

    const handleItemAction = (action: string, item?: UserSettingsListItem) => {
      emit("item-action-applied", {
        action,
        item,
      });
    };

    return {
      userActions: ExceptionsAction,
      coronetIcons: CoroIcons,
      itemActions: [ExceptionsAction.REMOVE_EXCEPTION],
      headers: [
        {
          key: "email",
          sortable: false,
        },
        {
          key: "addedBy",
          sortable: false,
        },
        {
          key: "addedOn",
          sortable: false,
        },
      ],
      actionNotAllowed,
      getNotExcepted,
      handleItemAction,
      getFormattedDateTime,
    };
  },
});
</script>

<style scoped lang="scss">
.strikethrough {
  text-decoration: line-through;
}
.service-icon-excepted {
  filter: grayscale(1) saturate(0%);
}
.service-icon {
  height: 30px;
  width: 30px;
}
</style>
