<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlightable"
      :items="items"
      :fixed-header="true"
      :page="pagination.page + 1"
      :show-select="false"
      :headers-length="headers.length"
      :loading="loading"
      :headers="headers"
      :items-per-page="pagination.pageSize"
      :items-length="total"
      @update:options="
        $emit('page-changed', {
          pageSize: $event.itemsPerPage,
          page: $event.page - 1,
        })
      "
      :no-data-text="$t('manageWorkspaces.noData')"
      item-key="email"
    >
      <!--Slot for rows-->
      <template #item="{ item, index }">
        <tr :key="index" :data-testid="`table-row-${index}`" class="msp-table-row">
          <td :data-testid="`msp-table-name-col-row-${index}`">
            <div class="d-flex align-center">
              <v-icon class="workspace-icon" size="40">{{ iconsMap[item.type] }}</v-icon>
              <div class="ml-3">
                <div class="subtitle2 white-space-normal">
                  {{ item.name }}
                </div>
                <div v-if="item.parentName" class="body2 white-space-normal">
                  {{ $t(`manageWorkspaces.workspaceTypes.${item.type}`) }} /
                  {{ item.parentName }}
                </div>
                <div v-else class="body2">
                  {{ $t(`manageWorkspaces.workspaceTypes.${item.type}`) }}
                </div>
                <div class="caption text-indigo-medium white-space-normal">
                  {{ item.workspaceId }}
                </div>
              </div>
            </div>
          </td>
          <td :data-testid="`msp-table-plan-col-row-${index}`">
            <div>
              {{ $t(`subscriptions.plans.${item.subscriptionType}`) }}
            </div>
            <div v-if="item.since" class="caption text-indigo-medium">
              {{ format(new Date(item.since), "ddd, MMM DD YYYY") }}
            </div>
          </td>
          <td :data-testid="`msp-table-daysLeft-col-row-${index}`">
            <span class="body2" v-if="item.creationTime">
              {{ format(new Date(item.creationTime), "ddd, MMM DD YYYY") }}
            </span>
            <template v-else> --- </template>
          </td>
          <td :data-testid="`msp-table-protectedUsers-col-row-${index}`">
            <span class="body2">{{ item.protectedUsers }}</span>
          </td>
          <td :data-testid="`msp-table-protectableUsers-col-row-${index}`">
            <span class="body2">{{ item.protectableUsers }}</span>
          </td>
          <td :data-testid="`msp-table-protectedDevices-col-row-${index}`">
            <span class="body2">{{ item.protectedDevices }}</span>
          </td>
          <td :data-testid="`msp-table-lastActivity-col-row-${index}`">
            <span class="body2" v-if="item.lastActivity">{{
              formatDistanceToNow(new Date(item.lastActivity))
            }}</span>
            <template v-else>---</template>
          </td>
          <td @click="$event.stopPropagation()">
            <v-menu location="bottom left">
              <template #activator="{ props }">
                <v-btn
                  icon="$dots"
                  :data-testid="`users-table-action-btn-row-${index}`"
                  v-bind="props"
                >
                </v-btn>
              </template>

              <v-list>
                <template v-for="mspAction in itemActions">
                  <v-list-item
                    v-if="showAction(mspAction, item)"
                    :key="mspAction"
                    @click="handleItemAction(mspAction, item)"
                  >
                    <v-list-item-title :data-testid="`msp-table-${mspAction}-action`">{{
                      $t(`manageWorkspaces.actions.${mspAction}`)
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { CoroIcons } from "@/constants/coro-icon";
import {
  MspWorkspaceAction,
  SubscriptionType,
  WorkspaceAction,
  WorkspaceType,
} from "@/constants/workplaces";
import { useAccountStore } from "@/_store";
import { formatDistanceToNow } from "date-fns";
import { MfaStatus } from "@/constants/account";
import type { MspWorkspaceItem } from "@/_store/msp.module";
import { useMyAccountStore } from "@/_store/my-account.module";
import type { Pagination } from "@/types";
import { useI18n } from "vue-i18n";
import { useDialogsStore } from "@/_store/dialogs.module";
import { confirmationDialogsConfigConstructor, getFormattedDateTime } from "@/_helpers/utils";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<Array<MspWorkspaceItem>>,
      required: true,
    },
    itemActions: {
      type: Array as PropType<Array<MspWorkspaceAction>>,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["item-action-applied", "page-changed"],
  setup(_props, { emit }) {
    const i18n = useI18n();
    const accountStore = useAccountStore();
    const myAccountStore = useMyAccountStore();

    const headers = computed(() => {
      return [
        {
          title: i18n.t("manageWorkspaces.table.name"),
          key: "name",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.table.plan"),
          key: "plan",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.table.createdOn"),
          key: "createdOn",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.table.protectedUsers"),
          key: "protectedUsers",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.table.protectableUsers"),
          key: "protectableUsers",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.table.protectedDevices"),
          key: "protectedDevices",
          sortable: false,
        },
        {
          title: i18n.t("manageWorkspaces.table.lastActivity"),
          key: "lastActivity",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ];
    });

    const showAction = (action: MspWorkspaceAction, item: MspWorkspaceItem) => {
      const plansWithoutSubscription = [
        SubscriptionType.INITIAL,
        SubscriptionType.FREEZE,
        SubscriptionType.TRIAL,
      ];

      const plansInTrial = [SubscriptionType.FREEZE, SubscriptionType.TRIAL];

      if (item.subscriptionType === SubscriptionType.ARCHIVED) {
        return action === MspWorkspaceAction.UNARCHIVE_WORKSPACE;
      }
      switch (action) {
        case MspWorkspaceAction.VIEW_WORKSPACE:
        case MspWorkspaceAction.EDIT_WORKSPACE:
        case MspWorkspaceAction.EDIT_SUBSCRIPTION:
          return true;
        case MspWorkspaceAction.ARCHIVE_WORKSPACE:
          return item.canBeArchived;
        case MspWorkspaceAction.START_SUBSCRIPTION:
          return plansWithoutSubscription.includes(item.subscriptionType);
        case MspWorkspaceAction.STOP_SUBSCRIPTION:
          return !plansWithoutSubscription.includes(item.subscriptionType);
        case MspWorkspaceAction.CONVERT:
          return accountStore.isGlobalAdmin;
        case MspWorkspaceAction.EXTEND_TRIAL:
          return accountStore.isGlobalAdmin && plansInTrial.includes(item.subscriptionType);
      }
    };

    const handleItemAction = (action: MspWorkspaceAction, item: MspWorkspaceItem) => {
      const payload = {
        item,
        itemAction: action,
      };
      if (
        myAccountStore.myAccount.profileData.mfaConfig === MfaStatus.NONE &&
        action === MspWorkspaceAction.VIEW_WORKSPACE &&
        myAccountStore.myAccount.profileData.allowMfa &&
        item.mfaRequired &&
        !accountStore.account.socialLogin
      ) {
        useDialogsStore().openDialog(
          confirmationDialogsConfigConstructor({
            item,
            action: WorkspaceAction.LAUNCH_WITH_2FA,
            callback: () => emit("item-action-applied", payload),
          })
        );
      } else {
        emit("item-action-applied", payload);
      }
    };

    return {
      formatDistanceToNow,
      format: getFormattedDateTime,
      coronetIcons: CoroIcons,
      MspWorkspacesAction: MspWorkspaceAction,
      iconsMap: {
        [WorkspaceType.REGULAR]: "icon-regular-plan",
        [WorkspaceType.CHANNEL]: "icon-parent-plan",
        [WorkspaceType.CHILD]: "icon-child-plan",
        [WorkspaceType.CORO_AGENT]: "",
      },
      headers,
      showAction,
      handleItemAction,
      SubscriptionType,
    };
  },
});
</script>

<style scoped lang="scss">
.msp-table-row {
  height: 96px;
}

.plan-col {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 87px;
  overflow: hidden;
}

.white-space-normal {
  white-space: normal !important;
  word-break: break-all;
  min-width: 150px;
}
</style>
