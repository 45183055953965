import { defineStore } from "pinia";
import type { ModulesSettings } from "@/_store/subscription.module";
import type { SubscriptionBundle } from "@/constants/workplaces";
import type { WorkspaceType } from "@/constants/workplaces";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import cloneDeep from "lodash/cloneDeep";
import { defaultSubscriptionModulesConfig } from "@/constants/workplaces";
import type { Paged } from "@/types";
import { useFiltersStore } from "@/_store/filters.module";
import { downloadFile } from "@/_helpers/utils";
import moment from "moment/moment";

export enum WorkspaceCodeRelationType {
  GENERAL = "general",
  MARKETING = "marketing",
  AGENT = "agent",
  MSP = "msp",
  SALES = "sales",
}

export const defaultWorkspaceCodeDetails: WorkspaceCodeDetails = {
  trialDuration: null,
  commercialTerms: null,
  discount: null,
  creditCardRequired: false,
  bundles: [],
  modules: cloneDeep(defaultSubscriptionModulesConfig),
};

export const defaultWorkspaceCodeItem: WorkspaceCodeItem = {
  name: "",
  type: null,
  validTill: null,
  priceBookNumber: null,
  workspaceType: null,
  details: cloneDeep(defaultWorkspaceCodeDetails),
  relatedTo: {
    type: WorkspaceCodeRelationType.GENERAL,
  },
};

export enum WorkspaceCodeType {
  SUBSCRIPTION = "subscription",
  TRIAL_DURATION = "trialDuration",
  COMMERCIAL_TERMS = "commercialTerms",
  FIXED_DISCOUNT = "fixedDiscount",
  CREDIT_CARD_DETAILS = "creditCardDetails",
  COMBINED = "combined",
}

export enum WorkspaceCodeStatus {
  ACTIVATED = "activated",
  DEACTIVATED = "deactivated",
}

export interface WorkspaceCodeItem {
  name: string;
  type: WorkspaceCodeType | null;
  status?: WorkspaceCodeStatus | null;
  validTill?: number | null;
  priceBookNumber: string | null;
  workspaceType: WorkspaceType | null;
  assignedWorkspacesCount?: number | null;
  details: WorkspaceCodeDetails;
  relatedTo: {
    type: WorkspaceCodeRelationType;
    agentDetails?: string;
    mspDetails?: string;
    salesDetails?: string;
  };
}

export interface WorkspaceCodeListItem extends Omit<WorkspaceCodeItem, "relatedTo"> {
  id: string;
  relatedTo: {
    type: WorkspaceCodeRelationType;
    agentDetails?: string;
    mspDetails?: WorkspaceCodeWorkspaceInfo;
    salesDetails?: WorkspaceCodeSalesAdministrator;
  };
}

export interface WorkspaceCodeSalesAdministrator {
  name?: string;
  email: string;
  id: string;
}

export interface WorkspaceCodeWorkspaceInfo {
  workspaceId: string;
  workspaceName: string;
}

export interface WorkspaceCodeDetails {
  trialDuration: number | null;
  commercialTerms: string | null;
  discount: number | null;
  creditCardRequired: boolean;
  bundles: SubscriptionBundle[];
  modules: ModulesSettings;
}

export interface WorkspaceCodesState {
  workspaceCodes: WorkspaceCodeListItem[];
  showSkeletonLoader: boolean;
}

const defaultWorkspaceCodesState: WorkspaceCodesState = {
  workspaceCodes: [],
  showSkeletonLoader: false,
};

export const useWorkspaceCodesStore = defineStore("backofficeWorkspaceCodes", {
  state: () => ({ ...defaultWorkspaceCodesState }),
  actions: {
    async getWorkspaceCodes(showSkeletonLoader: boolean = false) {
      this.showSkeletonLoader = showSkeletonLoader;
      const { filters } = useFiltersStore();
      const request = {
        ...api.promoCodes(),
        method: "GET",
        params: {
          name: filters.workspaceCodesFilters.name,
          type: filters.workspaceCodesFilters.type
            ? filters.workspaceCodesFilters.type.join(",")
            : "",
          status: filters.workspaceCodesFilters.status,
        },
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.workspaceCodes = data;
        this.showSkeletonLoader = false;
      } catch (e) {
        console.error(e);
        this.showSkeletonLoader = false;
      }
    },
    async exportCsv(): Promise<void> {
      try {
        const { filters } = useFiltersStore();
        const request = {
          ...api.exportPromoCodesCsv,
          params: {
            name: filters.workspaceCodesFilters.name,
            type: filters.workspaceCodesFilters.type
              ? filters.workspaceCodesFilters.type.join(",")
              : "",
            status: filters.workspaceCodesFilters.status,
          },
        };
        const { data } = await axiosInstance.request(request);
        const file = new Blob([data]);
        downloadFile(file, `workspace-codes-${moment().format("YYYY-MM-DD_hh:mm:ss A")}.csv`);
      } catch (error) {
        console.error(error);
      }
    },
    async createWorkspaceCode(data: WorkspaceCodeItem) {
      const request = {
        ...api.promoCodes(),
        method: "POST",
        data,
      };
      try {
        await axiosInstance.request(request);
        await this.getWorkspaceCodes();
      } catch (e) {
        console.error(e);
      }
    },
    async deactivateWorkspaceCode(data: { item: WorkspaceCodeListItem }) {
      const request = {
        ...api.deactivatePromoCode(data.item.id),
      };
      try {
        await axiosInstance.request(request);
        await this.getWorkspaceCodes();
      } catch (e) {
        console.error(e);
      }
    },
    async deleteWorkspaceCode(data: { item: WorkspaceCodeListItem }) {
      const request = {
        ...api.deletePromoCode(data.item.id),
      };
      try {
        await axiosInstance.request(request);
        await this.getWorkspaceCodes();
      } catch (e) {
        console.error(e);
      }
    },
    async activateWorkspaceCode(data: WorkspaceCodeListItem) {
      const request = {
        ...api.activatePromoCode(data.id),
        params: {
          validTill: data.validTill,
        },
      };
      try {
        await axiosInstance.request(request);
        await this.getWorkspaceCodes();
      } catch (e) {
        console.error(e);
      }
    },
    async searchChannelWorkspaces(
      page: number = 0,
      search = ""
    ): Promise<Paged<WorkspaceCodeWorkspaceInfo>> {
      const request = {
        ...api.searchPromoCodeChannelWorkspaces(search, { pageSize: 25, page }),
      };
      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (e) {
        console.error(e);
        return {
          items: [],
          total: 0,
        };
      }
    },
    async searchSalesAdministrators(search = ""): Promise<WorkspaceCodeSalesAdministrator[]> {
      const request = {
        ...api.searchPromoCodeSalesAdministrators(search),
      };
      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
  },
});
