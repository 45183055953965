import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { MessageType } from "@/constants/messages";
import type { Service } from "@/constants/cloud-apps";
import type { Paged } from "@/types";

export interface AlertMessage {
  id: string;
  timestamp: number;
  type: MessageType;
  dismissed: boolean;
  highPriority: boolean;
  params: MessageParams;
}

export interface MessageParams {
  operator_name?: string;
  service: Service;
  start_timestamp?: number;
  end_timestamp?: number;
}

interface MessagesState {
  messages: AlertMessage[];
  total: number;
  showSkeletonLoader: boolean;
}

const defaultMessagesState: MessagesState = {
  messages: [],
  showSkeletonLoader: false,
  total: 0,
};

export const useMessagesStore = defineStore("messages", {
  state: (): MessagesState => ({ ...defaultMessagesState }),
  persist: false,
  actions: {
    async getMessages(): Promise<void> {
      this.showSkeletonLoader = true;
      const request = {
        ...api.messages,
      };
      try {
        const { data } = await axiosInstance.request<Paged<AlertMessage>>(request);
        this.messages = data.items;
        this.total = data.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.showSkeletonLoader = false;
      }
    },
    async dismissMessage(id: string): Promise<void> {
      const request = {
        ...api.dismissMessage(id),
      };
      try {
        await axiosInstance.request(request);
        await this.getMessages();
      } catch (e) {
        console.error(e);
      }
    },
  },
});
