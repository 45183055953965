<template>
  <div class="coro-widget-header">
    <span class="headline6">
      <span class="subtitle1">{{ $t(`${widgetConfig.header.widgetTitle}`) }}</span>
    </span>
    <v-icon
      v-if="showSettingsIcon"
      size="24"
      icon="$settings"
      class="cursor-pointer"
      @click="onSettingsIconClicked()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { RolePermissionsScope } from "@/_store/roles.module";
import { WidgetAction, WidgetType } from "@/constants/dashboard";
import { isAccessRestricted } from "@/_helpers/utils";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    widgetConfig: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    headerConfig: {
      type: Object,
      default: () => ({
        showViewAll: false,
        showIcon: false,
        isInWarningState: false,
      }),
    },
  },
  setup(props) {
    const widgetActions = ref(WidgetAction);
    const router = useRouter();
    const showSettingsIcon = computed(() => {
      return !isAccessRestricted(RolePermissionsScope.PROTECTION, props.widgetConfig.widgetType);
    });

    const onSettingsIconClicked = () => {
      switch (props.widgetConfig.widgetType) {
        case WidgetType.CLOUD_SECURITY:
          router.push({ name: RouteName.CLOUD_SECURITY_PAGE });
          break;
        case WidgetType.ENDPOINT_SECURITY:
          router.push({ name: RouteName.ENDPOINT_SECURITY_DEVICE_POSTURE_TAB });
          break;
        case WidgetType.EMAIL_SECURITY:
          router.push({ name: RouteName.EMAIL_SECURITY_PAGE });
          break;
        case WidgetType.USER_DATA_GOVERNANCE:
          router.push({ name: RouteName.USER_DATA_GOVERNANCE_PAGE });
          break;
        case WidgetType.ENDPOINT_DATA_GOVERNANCE:
          router.push({ name: RouteName.ENDPOINT_DATA_GOVERNANCE_PAGE });
          break;
        case WidgetType.EDR:
          router.push({ name: RouteName.EDR_PROCESSES_TAB });
          break;
      }
    };

    return {
      widgetActions,
      showSettingsIcon,
      onSettingsIconClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
.coro-widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
