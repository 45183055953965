export enum BackOfficeOverviewWidgetType {
  WINDOWS_DEVICES = "windowsDevices",
  MACOS_DEVICES = "macosDevices",
  CUSTOMERS = "customers",
  PROTECTABLE_USERS = "protectableUsers",
  PROTECTED_USERS = "protectedUsers",
  PROTECTED_USERS_IN_PAID_WORKSPACES = "paidProtectedUsers",
  PROTECTED_USERS_REPORTED_TO_RECURLY = "paidRecurlyProtectedUsers",
}

export enum WorkspaceCodeActions {
  CREATE = "createWorkspaceCode",
  ACTIVATE = "activateWorkspaceCode",
  DEACTIVATE = "deactivateWorkspaceCode",
  DELETE = "deleteWorkspaceCode",
  COPY_LINK_TO_SIGNUP = "copyLinkToSignup",
  EXPORT_CSV = "exportCsv",
}
