import type { UserExceptionsFilters } from "@/_store/users-settings/user-exceptions-tab.module";
import { UserSettingsAppStatus } from "@/_store/users-settings/users-tab.module";
import type { Service } from "@/constants/cloud-apps";
import type { UsersSettingsFilters } from "@/_store/filters.module";

export const splitAndConvertUsersFilters = (
  filters: UserExceptionsFilters | UsersSettingsFilters
) => {
  const convertedFilters: {
    cloudServices: string;
    inboundGatewayDomains: string;
    connectedCloudApplications?: string;
    isActive?: boolean;
  } = {
    cloudServices: filters.cloudServices
      .filter((v) => v.type === "cloudService")
      .map((v) => v.name as Service)
      .join(","),
    inboundGatewayDomains: filters.cloudServices
      .filter((v) => v.type === "domain")
      .map((v) => v.name)
      .join(","),
  };

  if (
    [UserSettingsAppStatus.ACTIVE, UserSettingsAppStatus.INACTIVE].includes(
      (filters as UsersSettingsFilters).status!
    )
  ) {
    const status = (filters as UsersSettingsFilters).status;

    convertedFilters.isActive = status === UserSettingsAppStatus.ACTIVE;
  }

  if ((filters as UsersSettingsFilters).connectedApps) {
    convertedFilters.connectedCloudApplications = (
      filters as UsersSettingsFilters
    ).connectedApps?.join(",");
  }

  return convertedFilters;
};
