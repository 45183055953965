<template>
  <div
    v-html="
      $t('modals.blockApp.body', {
        appName: $t(`services.${config.item.name}`),
        cloudAppName: $t(`services.${config.item.cloudApplication}`),
      })
    "
  ></div>

  <v-checkbox
    v-model="localValue.notify"
    class="mt-3"
    :ripple="false"
    :label="$t('modals.blockApp.notifyUsersMessage')"
  >
  </v-checkbox>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { defineComponent, ref, watch } from "vue";
import type { ThirdPartyApp } from "@/_store/cloud-apps/third-party-apps.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<ThirdPartyApp>>,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const localValue = ref({
      notify: false,
    });

    watch(
      localValue,
      (val) => {
        emit("update:localValue", val);
      },
      { deep: true }
    );

    return {
      localValue,
    };
  },
});
</script>

<style lang="scss" scoped></style>
