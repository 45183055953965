<template>
  <div>
    <div
      v-if="currentStep === 0"
      class="services-chip-group margin-auto"
      :class="{ 'mb-0': providerName }"
    >
      <div class="text-primary">
        {{ $t("connectors.psa.addPsaConnector.description") }}
      </div>
      <v-chip-group v-model="providerName" class="justify-center mt-4 mb-6">
        <v-chip
          v-for="provider in providers"
          :key="provider.name"
          :value="provider.name"
          variant="outlined"
          :disabled="actionNotAllowed"
        >
          <div
            class="d-flex flex-column align-center w-100 provider-selection-chip"
            :data-testid="`${provider.name}-chip`"
          >
            <coro-icon class="service-icon" :icon-name="provider.name"></coro-icon>
            <div class="subtitle1">
              {{ $t(`connectors.psa.provider.${provider.name}`) }}
            </div>
            <div :class="provider.status" v-if="provider.isDisabled">
              {{ $t(`connectors.psa.status.${provider.status}`) }}
            </div>
          </div>
        </v-chip>
      </v-chip-group>
    </div>
    <component
      :is="componentType"
      v-else
      :config="localValue"
      @update:valid="updateValid($event)"
      @update:value="updateValue($event)"
      @cancel="cancel"
    ></component>
  </div>
</template>

<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import CoroIcon from "@/components/CoroIcon.vue";
import ConnectwisePsaConfigurator from "@/components/modals/psa-modals/ConnectwisePsaConfigurator.vue";
import GradientPsaConfigurator from "@/components/modals/psa-modals/GradientPsaConfigurator.vue";
import AutotaskPsaConfigurator from "@/components/modals/psa-modals/AutotaskPsaConfigurator.vue";
import { PsaProviders } from "@/constants/connectors";
import { camelCase, capitalize } from "lodash";
import { useI18n } from "vue-i18n";
import { usePsaStore } from "@/_store/connectors/psa.module";
import { PsaConnectionStatus } from "@/_store/connectors/adapters";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

export default defineComponent({
  components: {
    CoroIcon,
    ConnectwisePsaConfigurator,
    AutotaskPsaConfigurator,
    GradientPsaConfigurator,
  },
  name: "AddPsaConnectorModal",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["dismissed", "change-header-title", "update:valid", "change-footer", "update:localValue"],
  setup(props, { emit }) {
    const i18n = useI18n();

    const psaModule = usePsaStore();
    const localValue = reactive(props.config || { item: null });
    const isEdit = !!localValue.item;
    const providerName: Ref<PsaProviders> = ref(localValue.item?.providerName || "");
    const configuredConnectors = psaModule.connectors;
    let currentStep = ref(providerName.value ? 1 : 0);
    let valid = false;
    const componentType = computed(() => capitalize(providerName.value) + "PsaConfigurator");
    const actionNotAllowed = computed(() =>
      isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CONNECTORS
      )
    );
    const providers = Object.values(PsaProviders).map((provider) => {
      const connector = configuredConnectors.find(
        (connector) => connector.providerName === provider
      );

      return {
        name: provider,
        status: camelCase(connector?.status),
        isDisabled: connector && connector.status !== PsaConnectionStatus.CONFIG_NOT_FOUND,
      };
    });

    onMounted(() => {
      emit("update:localValue", localValue.item);
    });

    const updateDialogFooter = (newValue: PsaProviders) => {
      let actionCallback;

      switch (newValue) {
        case PsaProviders.CONNECTWISE: {
          actionCallback = isEdit
            ? psaModule.updateConnectwiseConnection
            : psaModule.addConnectwiseConnection;
          break;
        }
        case PsaProviders.AUTOTASK: {
          actionCallback = isEdit
            ? psaModule.updateAutotaskConnection
            : psaModule.addAutotaskConnection;
          break;
        }
        case PsaProviders.GRADIENT: {
          actionCallback = isEdit
            ? psaModule.updateGradientConnection
            : psaModule.addGradientConnection;
          break;
        }
      }

      emit("change-footer", {
        buttons: [
          {
            title: i18n.t("general.cancel"),
            spacer: "before",
            type: "text",
            cancel: true,
          },
          {
            title: i18n.t("general.connect"),
            color: "primary",
            callback: actionCallback,
          },
        ],
      });
    };

    if (isEdit) {
      updateDialogFooter(providerName.value);
    }

    watch(providerName, (newValue) => {
      // By design, we allow user to click disabled items.
      // If user clicks on disabled chip for the second time it emits undefined
      if (!newValue) {
        return;
      }

      const isConnectorExist = !!configuredConnectors.find(
        (connector) => connector.providerName === newValue
      );

      if (isConnectorExist) {
        return;
      }

      emit("change-header-title", i18n.t(`modals.addConnectwiseConnector.title.add.${newValue}`));

      currentStep.value++;

      updateDialogFooter(newValue);
    });

    const cancel = () => {
      emit("dismissed");
    };

    const updateValid = (value: boolean) => {
      emit("update:valid", value);
      valid = value;
    };

    const updateValue = (value: any) => {
      emit("update:localValue", value);
    };

    const validate = () => {
      return valid;
    };

    return {
      actionNotAllowed,
      providers,
      localValue,
      providerName,
      currentStep,
      cancel,
      updateValid,
      updateValue,
      validate,
      capitalize,
      componentType,
      PsaProviders,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-slide-group__content {
    justify-content: center;
  }

  .v-chip {
    width: 160px;
    height: 160px !important;
    border-radius: 6px;
  }

  .provider-selection-chip {
    max-height: 71px;
  }

  .v-chip .v-chip__content {
    width: 100% !important;
    text-align: center;
  }

  .v-chip-group .v-chip--active {
    border: 2px solid rgb(var(--v-theme-primary)) !important;
  }

  .deep-purple--text.text--accent-4 {
    color: transparent !important;
  }

  .v-chip:not(.v-chip--active) {
    border: solid 1px rgb(var(--v-theme-gray-pale)) !important;
  }

  .v-chip:before {
    background-color: transparent !important;
  }

  .service-icon {
    height: 48px;
    width: 48px;
  }

  .services-chip-group {
    display: flex;
    justify-content: center;
    margin-bottom: 92px;
    margin-top: 32px;
    max-width: 510px;
  }

  .description-bold {
    font-weight: 600;
  }

  .client-id-copy-field {
    width: 328px;

    .v-text-field__details {
      display: none;
    }

    .v-input__slot {
      padding-right: 0 !important;
    }

    .v-input__append-inner {
      margin-top: 0 !important;
    }

    .v-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      pointer-events: initial;
    }
  }

  .mdi-check:before {
    font-size: 16px;
  }

  .icon-warning:before {
    color: rgb(var(--v-theme-red-dark)) !important;
  }

  .connect-service-modal__service-icon {
    width: 96px;
    height: 96px;
  }
}

.configSuspended,
.disconnected {
  color: rgb(var(--v-theme-red-darkest));
}

.connected {
  color: rgb(var(--v-theme-green-dark));
}
</style>
