<template>
  <div v-if="accessViolation">
    <h6 class="headline6 mb-4">{{ $t("ticketDetails.fullDetails.title") }}</h6>
    <div class="full-details-wrapper mb-10">
      <div class="d-flex align-center px-4 pt-4">
        <div class="subtitle1 mb-4" data-testid="tickets-page-access-violation-title">
          {{ $t("ticketDetails.fullDetails.accessViolations.title") }}
        </div>
      </div>
      <v-divider></v-divider>
      <div class="py-4">
        <template v-for="(user, index) of accessViolation.users" :key="index">
          <div class="px-4 d-flex align-center">
            <div
              class="inner-circle red-circle mr-2"
              :data-testid="`tickets-page-access-violation-circle-row-${index}`"
            />
            <div
              :data-testid="`tickets-page-access-violation-direction-row-${index}`"
              v-html="
                $t(
                  `ticketDetails.fullDetails.accessViolations.${accessViolation.violationDirection}`,
                  { user, trigger }
                )
              "
            ></div>
          </div>
          <v-divider
            v-if="index !== accessViolation.users.length - 1"
            :key="user"
            class="my-4"
          ></v-divider>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { UserDataAccessViolation } from "@/_store/tickets/tickets.module";
import type { TicketTrigger } from "@/constants/tickets";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    accessViolation: {
      type: Object as PropType<UserDataAccessViolation>,
    },
    ticketTrigger: {
      type: String as PropType<TicketTrigger>,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const trigger = computed(() =>
      i18n.t(`ticketDetails.fullDetails.accessViolations.${props.ticketTrigger}`)
    );
    return {
      trigger,
    };
  },
});
</script>

<style lang="scss" scoped>
.red-circle {
  background-color: rgb(var(--v-theme-red-dark));
  border-color: rgb(var(--v-theme-red-dark));
}
.full-details-wrapper {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>
