<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlitable"
      :headers="headers"
      :items="items"
      :fixed-header="true"
      :show-select="false"
      item-key="email"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      @update:options="
        $emit('page-changed', { pageSize: $event.itemsPerPage, page: $event.page - 1 })
      "
    >
      <template #headers="{ columns }">
        <tr>
          <th v-for="header of columns" :key="header.key as string">
            <span
              v-if="!header.sortable && header.value !== 'data-table-select'"
              :data-testid="`table-${header.value}-header`"
            >
              {{ $t(`phishingSettings.table.${header.value}`) }}
            </span>
          </th>
          <th class="checkbox-col text-center" v-if="items.length"></th>
        </tr>
      </template>

      <!--Slot for rows-->
      <template #item="{ item, index }">
        <tr :key="index" :data-testid="`table-row-${index}`">
          <td :data-testid="`allow-list-table-name-col-${index}`">
            <div class="d-flex align-center name-col">
              <v-icon size="43" :icon="iconToTypeMap[item.type]"></v-icon>
              <span class="ml-4 coro-body2 text--semibold ellipsis d-inline-block">{{
                item.name
              }}</span>
            </div>
          </td>
          <td :data-testid="`allow-list-table-status-col-${index}`">
            <div :class="getStatusClass(item)">{{ getStatusName(item) }}</div>
          </td>
          <td>
            <v-menu bottom left>
              <template #activator="{ props }">
                <v-btn
                  dark
                  icon
                  :data-testid="`allow-list-table-remove-btn-${index}`"
                  :disabled="item.inheritedFromParent"
                  v-bind="props"
                >
                  <v-icon icon="$dots" />
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="
                    handleItemAction(
                      item.allowList
                        ? PhishingAction.REMOVE_FROM_ALLOWLIST
                        : PhishingAction.REMOVE_FROM_BLOCKLIST,
                      item
                    )
                  "
                >
                  {{ $t("phishingSettings.actions.remove") }}
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import TableWrapper from "@/components/TableWrapper.vue";
import { defineComponent, toRefs } from "vue";
import type { PropType } from "vue";
import { PhishingAction } from "@/constants/phishing";
import { useI18n } from "vue-i18n";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import type { AllowBlockListItem } from "@/_store/email-security/email-settings.module";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<AllowBlockListItem[]>,
      required: true,
      default() {
        return [];
      },
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    pagesCount: {
      type: Number,
      required: false,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    itemActions: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-item-action", "page-changed"],
  setup(props, { emit }) {
    const i18n = useI18n();

    const { totalItems, items, pagination } = toRefs(props);

    const { totalPages, page, handleListTablePaginationChange } = usePageableTable(
      {
        totalItems,
        items,
        pagination,
      },
      (value: Pagination) => {
        emit("page-changed", value);
      }
    );

    const getStatusName = (item: AllowBlockListItem) => {
      const listType = item.allowList
        ? i18n.t("phishingSettings.allow")
        : i18n.t("phishingSettings.block");
      return i18n.t(`phishingSettings.${item.senderClassificationType}`, {
        listType: listType.toLowerCase(),
      });
    };

    const getStatusClass = (item: AllowBlockListItem) => {
      return item.allowList ? "text-green-dark" : "text-red-dark";
    };

    const handleItemAction = (action: PhishingAction, item: AllowBlockListItem) => {
      const payload = {
        item,
        action,
      };
      emit("on-item-action", payload);
    };

    const headers = [
      {
        key: "name",
        sortable: false,
      },
      {
        key: "list",
        sortable: false,
      },
    ];

    return {
      getStatusName,
      getStatusClass,
      iconToTypeMap: {
        domain: "$locationAccess",
        ip: "$locationAccess",
        ipRange: "$locationAccess",
        email: "$email",
      },
      headers,
      PhishingAction,
      allItemsSelected: false,
      handleItemAction,
      page,
      totalPages,
      handlePaginationChange: handleListTablePaginationChange,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .icon-location-access:before {
    color: rgb(var(--v-theme-primary)) !important;
  }

  .v-data-table-rows-no-data {
    height: calc(100vh - 465px);
  }
}

.name-col {
  max-width: 500px;
}
</style>
