import {
  DevicePostureOsType,
  DevicePostureSettings,
  DeviceVulnerability,
  WifiPostureConnectionType,
} from "@/constants/devices";
import { i18n } from "@/plugins/i18n";
import type {
  DevicePostureAdaptedItem,
  DevicePostureCombinedType,
  DevicePostureFormData,
  DevicePostureRegularItem,
  DevicePostureUsbLockdownItem,
  DevicePostureUsbLockdownSettings,
  DevicePostureUserAccountItem,
  DevicePostureUserAccountSettings,
  DevicePostureWifiItem,
  DevicePostureWifiSettings,
  RegularDevicePostureSettings,
} from "@/_store/device-posture/devices-posture-settings.module";

export const mapPostureSettings = (
  regularPostureSettings: RegularDevicePostureSettings,
  userAccountPolicySettings: DevicePostureUserAccountSettings,
  wifiSettings: DevicePostureWifiSettings,
  usbLockdownSettings: DevicePostureUsbLockdownSettings
): DevicePostureAdaptedItem[] => {
  return [
    ...mapDefaultPostureSettings(regularPostureSettings),
    ...mapUserAccountPostureToTableData(userAccountPolicySettings),
    ...mapWiFiPostureToTableData(wifiSettings),
    ...mapUsbLockdownTableData(usbLockdownSettings),
  ];
};

export const mapDefaultPostureSettings = (
  postureSettings: RegularDevicePostureSettings
): DevicePostureAdaptedItem[] => {
  return Object.entries(postureSettings).flatMap(([osType, value]) =>
    mapDefaultPostureToTableData(value, osType as DevicePostureOsType)
  );
};

const mapDefaultPostureToTableData = (
  data: Record<DeviceVulnerability, DevicePostureRegularItem[]>,
  osType: DevicePostureOsType
): DevicePostureAdaptedItem[] => {
  return (
    Object.entries(data)
      // TODO: remove this filter hack when BE will remove it from response
      .filter(
        ([policyType]) =>
          ![
            DevicePostureSettings.WIFI,
            DevicePostureSettings.DLP_SCAN_SCHEDULE,
            DevicePostureSettings.USER_ACCOUNT_POLICY,
          ].includes(policyType as DevicePostureSettings)
      )
      .map(([policyType, policyItems]) => {
        return mapToTableItem(
          policyType as DevicePostureSettings,
          osType,
          policyItems as DevicePostureCombinedType[]
        );
      }) as DevicePostureAdaptedItem[]
  );
};

const mapWiFiPostureToTableData = (data: DevicePostureWifiSettings) => {
  if (!data) {
    return [];
  }

  return Object.entries(data).map(([osType, policyItems]) => {
    return mapToTableItem(
      DevicePostureSettings.WIFI,
      osType as DevicePostureOsType,
      policyItems as DevicePostureCombinedType[]
    );
  });
};

/** Maps to object[] that will be used in template
 */
const mapUserAccountPostureToTableData = (
  data: DevicePostureUserAccountSettings
): DevicePostureAdaptedItem[] => {
  if (!data) {
    return [];
  }

  return Object.entries(data).map(([osType, policyItems]) => {
    return mapToTableItem(
      DevicePostureSettings.USER_ACCOUNT_POLICY,
      osType as DevicePostureOsType,
      policyItems as DevicePostureCombinedType[]
    );
  });
};

/** Maps to object[] that will be used in template
 */
const mapUsbLockdownTableData = (data: DevicePostureUsbLockdownSettings) => {
  if (!data) {
    return [];
  }

  return Object.entries(data).map(([osType, policyItems]) => {
    return mapToTableItem(
      DevicePostureSettings.USB_LOCKDOWN,
      osType as DevicePostureOsType,
      policyItems as DevicePostureCombinedType[]
    );
  });
};

/**
 * Create PostureTableItem object for template
 */
export const mapToTableItem = (
  policyType: DevicePostureSettings,
  osType: DevicePostureOsType,
  policyItems: DevicePostureCombinedType[]
): DevicePostureAdaptedItem => {
  // osTypeOrder and position are needed to specify the order of the osTypes
  const osTypeOrder = [DevicePostureOsType.WINDOWS, DevicePostureOsType.MAC_OS];
  const policiesQuantity = policyItems.length;
  const policies = mapPolicyItemsDisplayValue(policyType, policyItems);
  return {
    tableItemId: `${osType}-${policyType}`,
    policyType,
    osType,
    policies,
    policiesQuantity,
    position: osTypeOrder.indexOf(osType),
  };
};

/**
 * Adds displayValue property to policies
 */
export const mapPolicyItemsDisplayValue = (
  policyType: DevicePostureSettings,
  policyItems: DevicePostureCombinedType[]
): DevicePostureCombinedType[] => {
  switch (policyType) {
    case DevicePostureSettings.WIFI:
      return (policyItems as DevicePostureWifiItem[]).map((p) => {
        const displayValue =
          p.connectionType === WifiPostureConnectionType.ALL_ENCRYPTED
            ? i18n.global.t("devicesSettings.devicePostureTab.encrypted")
            : i18n.global.t(
                "devicesSettings.devicePostureTab.networks",
                { n: p.networks.length },
                p.networks.length
              );
        return {
          ...p,
          displayValue,
        };
      });
    case DevicePostureSettings.USB_LOCKDOWN:
      return (policyItems as DevicePostureUsbLockdownItem[]).map((p) => {
        const displayValue = [];
        if (p.blockMassStorage) {
          displayValue.push(
            i18n.global.t("modals.addDevicePosturePolicy.usbLockdown.blockMassStorage")
          );
        }
        if (p.blockPortableDevices) {
          displayValue.push(
            i18n.global.t("modals.addDevicePosturePolicy.usbLockdown.blockPortableDevices")
          );
        }
        return {
          ...p,
          displayValue: displayValue.join(", "),
        };
      });
    case DevicePostureSettings.USER_ACCOUNT_POLICY:
      return (policyItems as DevicePostureUserAccountItem[]).map((p) => {
        return {
          ...p,
          displayValue: undefined,
        };
      });
    default:
      return (policyItems as DevicePostureRegularItem[]).map((p) => ({
        ...p,
        displayValue: i18n.global.t(`devicesSettings.devicePostureTab.choices.${p.postureValue}`),
      }));
  }
};

/**
 * Converts default policy item to BE format
 */
export function convertRegularPolicyForBackend(
  payload: DevicePostureFormData
): Partial<RegularDevicePostureSettings> {
  const { policyType, osType, labels, postureValue, id } = payload;
  return {
    [osType]: {
      [policyType]: [
        {
          id,
          labels,
          postureValue,
        },
      ],
    },
  };
}

/**
 * Converts Wi-Fi policy item to BE format
 */
export function convertWifiPolicyForBackend(payload: DevicePostureFormData): DevicePostureWifiItem {
  const { labels, networks, connectionType, id } = payload;
  return {
    id,
    networks,
    connectionType,
    labels,
  };
}

/**
 * Converts User Account policy item to BE format
 */
export function convertUserAccountPolicyForBackend(
  payload: DevicePostureFormData
): DevicePostureUserAccountItem {
  const {
    labels,
    lockoutThreshold,
    lockoutDuration,
    passwordMustMeetComplexity,
    maxPasswdAge,
    passwordHistLen,
    minPasswdAge,
    minPasswdLen,
    osType,
  } = payload;
  const osTypes = {
    [DevicePostureOsType.WINDOWS]: "windows",
    [DevicePostureOsType.MAC_OS]: "macOs",
  };
  return {
    lockoutThreshold,
    lockoutDuration,
    passwordMustMeetComplexity,
    maxPasswdAge,
    passwordHistLen,
    minPasswdAge,
    minPasswdLen,
    osType: osTypes[osType],
    labels,
  };
}

/**
 * Converts USB lockdown policy item to BE format
 */
export function convertUsbLockdownPolicyForBackend(
  payload: DevicePostureFormData
): DevicePostureUsbLockdownItem {
  const osTypes = {
    [DevicePostureOsType.WINDOWS]: "windows",
    [DevicePostureOsType.MAC_OS]: "macOs",
  };
  const { blockPortableDevices, blockMassStorage, labels, osType } = payload;
  return {
    osType: osTypes[osType],
    blockPortableDevices,
    blockMassStorage,
    labels,
  };
}
