<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      class="skeleton-loader--cloud-apps"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.CLOUD_APPS_SETTINGS"
    >
      <v-breadcrumbs class="mt-2" :items="breadCrumbsItems" divider="<"></v-breadcrumbs>
      <div class="settings-header-with-btn">
        <div class="headline5">{{ $t("cloudApplications.title") }}</div>
        <v-btn
          color="primary"
          rounded
          density="default"
          size="large"
          @click="openModal({ action: ServiceAction.CONNECT, item: {} })"
        >
          <v-icon class="mr-2" icon="$add" />
          {{ $t("cloudApplications.connect") }}
        </v-btn>
      </div>
      <div v-if="hasConnectedServices" class="setting-description mb-4">
        {{ $t("cloudApplications.description") }}
      </div>
      <v-card v-if="hasConnectedServices">
        <table-wrapper class="service-table__wrapper">
          <v-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("cloudApplications.cloudApplication") }}
                  </th>
                  <th class="text-left">
                    {{ $t("cloudApplications.thirdPartyApps") }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in filterServicesByStatus(true)"
                  :key="item.name"
                  class="service-row"
                  :data-testid="`service-row-${item.name}`"
                >
                  <td>
                    <div class="d-flex">
                      <coro-icon class="service-icon mr-4" :icon-name="item.name"></coro-icon>
                      <div>
                        <div class="service-name">
                          {{ $t(`cloudApplications.${item.name}`) }}
                        </div>
                        <div :class="`body2 ${item.serviceStatus}`">
                          {{ $t(`services.status.${item.serviceStatus}`) }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <router-link :to="getThirdPartyAppsRedirectLink(item.name)" class="coro-link">
                      {{
                        $t("cloudApplications.numberConnected", {
                          n: item.connectedThirdPartyApps || 0,
                        })
                      }}
                    </router-link>
                  </td>
                  <td
                    v-if="isConnected(item.serviceStatus)"
                    class="text-right"
                    @click="$event.stopPropagation()"
                  >
                    <v-btn
                      color="primary"
                      rounded
                      variant="outlined"
                      min-width="108"
                      :data-testid="`${item.name}-connect-btn`"
                      @click="
                        openModal({
                          action: ServiceAction.DISCONNECT,
                          item,
                        })
                      "
                    >
                      {{ $t("cloudApplications.buttons.disconnect") }}
                    </v-btn>
                  </td>
                  <td
                    v-else-if="isIncompleteOrDisconnected(item.serviceStatus)"
                    class="text-right"
                    @click="$event.stopPropagation()"
                  >
                    <v-menu bottom left>
                      <template #activator="{ props }">
                        <v-icon icon="$dots" v-bind="props" />
                      </template>

                      <v-list>
                        <v-list-item
                          :key="ServiceAction.CONNECT"
                          :data-testid="`${ServiceAction.CONNECT}-action-item`"
                          :disabled="actionNotAllowed"
                          @click="
                            openModal({
                              action: ServiceAction.CONNECT,
                              item,
                            })
                          "
                        >
                          <v-list-item-title>
                            {{ $t(`cloudApplications.buttons.connect`) }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :key="ServiceAction.DISCONNECT"
                          :data-testid="`${ServiceAction.DISCONNECT}-action-item`"
                          @click="
                            openModal({
                              action: ServiceAction.DISCONNECT,
                              item,
                            })
                          "
                        >
                          <v-list-item-title>
                            {{ $t(`cloudApplications.buttons.remove`) }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td v-else class="text-right">
                    <v-btn
                      color="primary"
                      rounded
                      min-width="108"
                      :disabled="actionNotAllowed"
                      :data-testid="`${item.name}-connect-btn`"
                      @click="openModal({ action: ServiceAction.CONNECT, item })"
                    >
                      {{ $t("cloudApplications.buttons.connect") }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </table-wrapper>
      </v-card>

      <div class="subtitle1 mt-6">
        {{ $t("cloudApplications.connectCloudApplication") }}
      </div>
      <v-chip-group v-model="applicationToConnect" class="justify-center mt-4 mb-6">
        <v-chip
          v-for="item in filterServicesByStatus(false)"
          :key="item.name"
          :value="item.name"
          variant="outlined"
          :disabled="actionNotAllowed"
        >
          <div
            class="d-flex flex-column align-center w-100 provider-selection-chip"
            :data-testid="`${item.name}-chip`"
          >
            <coro-icon class="service-icon" :icon-name="item.name"></coro-icon>
            <div class="subtitle1">
              {{ $t(`cloudApplications.${item.name}`) }}
            </div>
          </div>
        </v-chip>
      </v-chip-group>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { type CloudAppService, useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { Service, ServiceAction, ServiceStatus } from "@/constants/cloud-apps";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import ConnectServiceModal from "@/views/settings/cloud-apps/modals/ConnectServiceModal.vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";

export interface ConnectAppModalConfigItem extends CloudAppService {
  partialScan: boolean;
  signup?: boolean;
}

export interface ConnectAppModalConfig {
  action: ServiceAction;
  item: Partial<ConnectAppModalConfigItem>;
}

export default defineComponent({
  components: {
    TableWrapper,
    CoroIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const cloudAppsStore = useCloudAppsStore();
    const { showSkeletonLoader, services } = storeToRefs(cloudAppsStore);
    const dialogsStore = useDialogsStore();
    const { getServices } = cloudAppsStore;

    const applicationToConnect = ref();
    const i18n = useI18n();

    const hasConnectedServices = computed(() => {
      return services.value.some(
        (service) => service.serviceStatus !== ServiceStatus.NOT_CONNECTED
      );
    });

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CLOUD_APPS
      );
    });

    watch(applicationToConnect, (val) => {
      if (!val) {
        return;
      }

      openModal({
        action: ServiceAction.CONNECT,
        item: {
          name: val,
        },
      });

      applicationToConnect.value = undefined;
    });

    const openModal = (payload: ConnectAppModalConfig) => {
      switch (payload.action) {
        case ServiceAction.CONNECT: {
          const config = componentDialogsConfigConstructor({
            width: ModalWidth.LARGE,
            action: payload.action,
            item: {
              ...payload.item,
              partialScan: true,
            },
            component: ConnectServiceModal,
            disable: actionNotAllowed.value,
            callback: () => {},
            closeCallback: () => {
              router.replace({ query: {} });
            },
          });

          dialogsStore.openDialog({
            ...config,
            header: {
              title: payload.item.name
                ? i18n.t(`modals.${ServiceAction.CONNECT}.title`, {
                    service: i18n.t(`services.${payload.item.name}`),
                  })
                : i18n.t(`modals.${ServiceAction.CONNECT}.noServiceTitle`),
              close: true,
            },
            footer: null,
          });
          break;
        }
        case ServiceAction.DISCONNECT: {
          dialogsStore.openDialog({
            ...confirmationDialogsConfigConstructor({
              action: ServiceAction.DISCONNECT,
              text: i18n.t(`modals.${payload.action}.description`, {
                service: i18n.t(`services.${payload.item.name}`),
              }),
              callback: () => cloudAppsStore.disconnect(payload.item.name!),
              disable: actionNotAllowed.value,
            }),
          });
          break;
        }
      }
    };

    onMounted(async () => {
      await getServices();

      if (route.query.isNextStep === "true") {
        openModal({
          action: ServiceAction.CONNECT,
          item: { name: route.query.service as Service },
        });

        return;
      }

      if (route.query.noServicesConnected === "true") {
        openModal({ action: ServiceAction.CONNECT, item: {} });

        router.replace({ query: {} });

        return;
      }

      if ([Service.DROPBOX, Service.SLACK].includes(route.query.service as Service)) {
        openModal({
          action: ServiceAction.CONNECT,
          item: {
            name: route.query.service as Service,
            serviceStatus: ServiceStatus.CONNECTED,
          },
        });
      }
    });

    const isConnected = (serviceStatus: ServiceStatus) => {
      return serviceStatus === ServiceStatus.CONNECTED;
    };

    const isIncompleteOrDisconnected = (serviceStatus: ServiceStatus) => {
      return [ServiceStatus.INCOMPLETE, ServiceStatus.DISCONNECTED].includes(serviceStatus);
    };

    const filterServicesByStatus = (shouldBeConnected: boolean) => {
      return services.value.filter(({ serviceStatus }) =>
        shouldBeConnected
          ? serviceStatus !== ServiceStatus.NOT_CONNECTED
          : serviceStatus === ServiceStatus.NOT_CONNECTED
      );
    };

    const getThirdPartyAppsRedirectLink = (name: Service) => {
      return {
        name: RouteName.CLOUD_SECURITY_THIRD_PARTY_APPS_TAB,
        query: {
          cloudApplications: name,
        },
      };
    };

    return {
      getThirdPartyAppsRedirectLink,
      isConnected,
      isIncompleteOrDisconnected,
      openModal,
      applicationToConnect,
      showSkeletonLoader,
      ServiceAction,
      services,
      ServiceStatus,
      coronetSkeletonLoaderTypes,
      hasConnectedServices,
      actionNotAllowed,
      filterServicesByStatus,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .service-row {
    height: 80px;
  }

  .v-slide-group__content {
    flex-wrap: wrap;
    width: 100%;
  }
}

.service-icon {
  height: 48px;
  width: 48px;
}

.service-name {
  font-size: 18px;
  line-height: 24px;
  color: rgb(var(--v-theme-indigo-medium));
}

.service-status {
  font-size: 16px;
  color: rgb(var(--v-theme-indigo-medium));
}

.v-chip {
  width: 275px;
  height: 160px !important;
  border-radius: 6px;
  background-color: rgb(var(--v-theme-white));
  border: 0;
  justify-content: center;
}

.v-chip .v-chip__content {
  width: 100% !important;
  text-align: center;
}

.v-chip-group .v-chip--active {
  border: 2px solid rgb(var(--v-theme-primary)) !important;
}
</style>
