<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-textarea
      v-model.trim="localValue.message"
      variant="outlined"
      :label="$t('modals.addNewComment.label')"
      :rules="[required()]"
      :placeholder="$t('modals.addNewComment.placeholder')"
      hide-details
    >
    </v-textarea>
    <div class="d-flex justify-space-between align-center w-100 mt-3">
      <div class="cursor-pointer text-no-wrap" @click="expand = !expand">
        <v-icon icon="$triangle" size="8"></v-icon>
        <span class="ml-2 body2">{{ $t("general.notify") }}</span>
      </div>
      <div v-if="isGlobalAdmin">
        <v-switch
          v-model="confidential"
          :ripple="false"
          density="compact"
          inset
          :label="$t('comments.confidential')"
        />
      </div>
    </div>
    <coro-comment-notification
      v-if="expand"
      :settings="localValue"
      :is-in-modal="true"
      :confidential="confidential"
      @update-value="updateNotificationSettings($event)"
    />
  </v-form>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import CoroCommentNotification from "@/components/CoroCommentNotification.vue";
import { type CommentSettings, defaultCommentsSettings } from "@/_store/ticket-comments.module";
import { computed, defineComponent, ref, watch } from "vue";
import { required } from "@/_helpers/validators";
import { useAccountStore } from "@/_store";
import type { VuetifyFormRef } from "@/types";

export default defineComponent({
  components: { CoroCommentNotification },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localValue = ref({
      ...defaultCommentsSettings,
      ...props.config.item,
    });
    const form = ref<VuetifyFormRef>();

    const accountStore = useAccountStore();
    const confidential = ref(false);
    const valid = ref(false);
    const expand = ref(false);
    const isGlobalAdmin = computed(() => accountStore.isGlobalAdmin);

    watch(
      localValue,
      (value) => {
        emit("update:localValue", { ...value, confidential: confidential.value });
      },
      { deep: true, immediate: true }
    );
    watch(
      confidential,
      (value) => {
        emit("update:localValue", { ...localValue.value, confidential: value });
      },
      { deep: true, immediate: true }
    );

    const validate = async () => {
      const validationResult = await form.value?.validate();

      return validationResult?.valid;
    };

    const updateNotificationSettings = (event: CommentSettings) => {
      localValue.value = {
        ...localValue.value,
        ...cloneDeep(event),
      };
    };

    return {
      form,
      localValue,
      confidential,
      valid,
      expand,
      required,
      isGlobalAdmin,
      validate,
      updateNotificationSettings,
    };
  },
});
</script>

<style lang="scss" scoped></style>
