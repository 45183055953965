import { addWorkspaceHeaderIfNeeded, downloadFile } from "@/_helpers/utils";
import type { Pagination } from "@/types";
import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { i18n } from "@/plugins/i18n";
import { axiosInstance } from "@/plugins/https";
import { useSnackbarStore } from "@/_store";
import { useUsersSettingsStore } from "@/_store/users-settings/users-settings.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { Service } from "@/constants/cloud-apps";
import { ProxyDirection } from "@/constants/email-proxy";
import type { EmailProxyDomain } from "@/_store/email-security/email-proxy.module";
import { splitAndConvertUsersFilters } from "@/_store/users-settings/adapters";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";

export enum UserSettingsJoinedVia {
  INDIVIDUALLY = "INDIVIDUALLY",
  ADMIN = "ADMIN",
  GROUP = "GROUP",
  INBOUND_GATEWAY = "INBOUND_GATEWAY",
  CSV = "CSV",
}

export enum UserSettingsAppStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface UserListItemGroup {
  groupId: string;
  name: string;
  service: Service;
}

export interface UserSettingsProtectedApp {
  appName: Service;
  active: boolean;
  alias: string;
}

export interface UserSettingsListItem {
  userId: string;
  email: string;
  isAdmin: boolean;
  addedIndividually: boolean;
  groups: UserListItemGroup[];
  protectionGrantedTime: number;
  exceptedDateTime: number;
  exceptedBy: string;
  aliases: string[];
  cloudServices: string[];
  exceptedUser: boolean;
  joinedVia: UserSettingsJoinedVia[];
  connectedCloudApplications: UserSettingsProtectedApp[];
}

export interface UsersSettingsState {
  allUsers: number;
  pages: number;
  pagination: Pagination;
  users: UserSettingsListItem[];
  showSkeletonLoader: boolean;
  loading: boolean;
}

export const defaultUsersTabFilters = {
  searchTerm: "",
  cloudServices: [],
  connectedApps: [],
  status: null,
};

const defaultUsersState = {
  allUsers: 0,
  pages: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  users: [], // we are caching items to handle case with selection and switching pages
  showSkeletonLoader: true,
  loading: true,
  filters: {
    ...defaultUsersTabFilters,
  },
};

export const useUsersSettingsTabStore = defineStore("users-settings-tab", {
  state: (): UsersSettingsState => ({ ...defaultUsersState }),
  actions: {
    init() {
      this.getUsers();
    },
    getUsers() {
      const filters = useFiltersStore().filters[FilterContext.USERS_SETTINGS];
      const denormalizedFilters = splitAndConvertUsersFilters(filters);

      const request = {
        ...api.users(),
        params: {
          ...this.pagination,
          ...denormalizedFilters,
          email: filters.searchTerm,
        },
      };
      this.loading = true;
      this.showSkeletonLoader = true;
      axiosInstance
        .request(request)
        .then((response) => {
          const data = response.data;
          this.users = data.items;
          this.allUsers = data.total;
          this.loading = false;
          this.showSkeletonLoader = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.showSkeletonLoader = false;
        });
    },
    searchProtectedUsers() {
      const filters = useFiltersStore().filters[FilterContext.USERS_SETTINGS];

      const request = {
        ...api.users(),
        params: {
          searchTerm: filters.searchTerm,
          pagination: { page: 0, pageSize: 10 },
        },
      };
      return axiosInstance.request(request);
    },
    /**
     * This action will search users in cloud services
     * @param commit
     * @param email
     * @returns {Promise<AxiosResponse<any>>}
     */
    searchUsers(email: string) {
      const request = {
        ...api.searchUsers(email),
      };
      return axiosInstance.request(request);
    },
    /**
     * This action will search already synchronized users
     * @returns {Promise<AxiosResponse<any>>}
     * @param payload
     */
    searchSyncedUsers() {
      const filters = useFiltersStore().filters[FilterContext.USERS_SETTINGS];

      const request = {
        ...api.users(),
        params: {
          ...this.pagination,
          searchTerm: filters.searchTerm,
        },
      };
      return axiosInstance.request(request);
    },
    applyPaginationChange(payload: Pagination) {
      const pagination = {
        page: payload.page,
        pageSize: payload.pageSize,
      };
      this.pagination = pagination;
      this.getUsers();
    },
    async addUsers({
      users,
      workspaceId,
    }: {
      users: Array<{ email: string; service: Service }>;
      workspaceId?: string;
    }) {
      const snackbarStore = useSnackbarStore();
      const usersSettingsStore = useUsersSettingsStore();
      const request = {
        ...api.addUsers,
        data: users,
      };
      addWorkspaceHeaderIfNeeded(request, workspaceId);
      if (users.length > 30) {
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersAreAdding"));
      }

      try {
        await axiosInstance.request(request);
        this.getUsers();
        usersSettingsStore.init();
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersUpdated"));
      } catch (error) {
        console.error(error);
      }
    },
    async syncUsers() {
      const snackbarStore = useSnackbarStore();
      const request = {
        ...api.syncUsers,
      };
      try {
        await axiosInstance.request(request);
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersSyncStarted"));
      } catch (error) {
        console.error(error);
      }
    },
    async removeUsers(payload: DialogDataConfig) {
      const usersSettingsStore = useUsersSettingsStore();
      const snackbarStore = useSnackbarStore();
      const request = {
        ...api.removeUser({
          userId: payload.item.userId,
          addToExceptionList: payload.item.checkboxValue || false,
        }),
      };

      try {
        await axiosInstance.request(request);
        this.getUsers();
        usersSettingsStore.init();
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersUpdated"));
      } catch (error) {
        console.error(error);
      }
    },
    async addAllUsers(payload: DialogDataConfig) {
      const usersSettingsStore = useUsersSettingsStore();
      const snackbarStore = useSnackbarStore();
      const request = {
        ...api.addAllUsersToProtection(payload.item.service),
      };

      try {
        await axiosInstance.request(request);
        usersSettingsStore.init();
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersUpdated"));
      } catch (error) {
        console.error(error);
      }
    },
    resetState() {
      this.$reset();
    },
    async addToException(payload: DialogDataConfig) {
      const snackbarStore = useSnackbarStore();
      const { email, userId } = payload.item;
      const request = {
        ...api.addExceptions,
        data: [
          {
            email,
            userId,
            service: "coronet",
          },
        ],
      };
      try {
        await axiosInstance.request(request);
        this.getUsers();
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersUpdated"));
      } catch (error) {
        console.error(error);
      }
    },
    async importUsersFromCsv(payload: any) {
      const data = new FormData();
      data.append("file", payload);
      const request = {
        ...api.importUsersViaCsv(data),
      };
      return axiosInstance.request(request);
    },
    async downloadUsersExampleCsv() {
      const request = {
        ...api.getUsersExampleCsv(),
      };

      try {
        const { data, headers } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: headers["content-type"] });
        const fileName = "UsersExample.csv";
        downloadFile(blob, fileName);
      } catch (error) {
        console.error(error);
      }
    },
    async removeException(payload: DialogDataConfig) {
      const snackbarStore = useSnackbarStore();
      const request = {
        ...api.removeException(payload.item.userId),
      };
      try {
        await axiosInstance.request(request);
        this.getUsers();
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersUpdated"));
      } catch (e) {
        console.error(e);
      }
    },
    async exportCsv() {
      const snackbarStore = useSnackbarStore();
      const filters = useFiltersStore().filters[FilterContext.USERS_SETTINGS];

      const request = {
        ...api.exportUsersCsv,
        params: {
          cloudServices: filters.cloudServices.join(","),
        },
      };
      try {
        await axiosInstance.request(request);
        this.getUsers();
        snackbarStore.addGenericSuccess(
          i18n.global.t("snackbar.messages.general.exportCsvStarted")
        );
      } catch (e) {
        console.error(e);
      }
    },
    async getProxyDomains(
      direction: ProxyDirection = ProxyDirection.INBOUND
    ): Promise<EmailProxyDomain[]> {
      const request = {
        ...api.emailProxyDomains(),
        params: {
          direction,
        },
      };

      try {
        const { data } = await axiosInstance.request<EmailProxyDomain[]>(request);

        return data;
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    setUsersPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
  },
});
