<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS"
  >
    <v-card class="mt-2">
      <v-card-text class="mt-3 ml-3 mr-3 mb-3">
        <div class="subtitle1 mb-3">
          {{ $t("endpointDataGovernance.privacySensitiveDataTab.subtitle") }}
        </div>
        <v-checkbox
          hide-details
          :disabled="actionNotAllowed"
          v-model="localValue.monitorPII"
          :label="$t('endpointDataGovernance.privacySensitiveDataTab.monitorPII')"
        >
        </v-checkbox>
        <v-checkbox
          hide-details
          :disabled="actionNotAllowed"
          v-model="localValue.monitorPCI"
          :label="$t('endpointDataGovernance.privacySensitiveDataTab.monitorPCI')"
        >
        </v-checkbox>
        <v-checkbox
          hide-details
          :disabled="actionNotAllowed"
          v-model="localValue.monitorPHI"
          :label="$t('endpointDataGovernance.privacySensitiveDataTab.monitorPHI')"
        >
        </v-checkbox>
        <v-checkbox
          hide-details
          :disabled="actionNotAllowed"
          v-model="localValue.monitorNPI"
          :label="$t('endpointDataGovernance.privacySensitiveDataTab.monitorNPI')"
        >
        </v-checkbox>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  defaultPrivacySensitiveDataState,
  usePrivacySensitiveDataStore,
} from "@/_store/endpoint-data-governance/privacy-sensitive-data.module";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";

export default defineComponent({
  setup() {
    const privacySensitiveDataStore = usePrivacySensitiveDataStore();
    const { getSettings, updateSettings } = privacySensitiveDataStore;
    const localValue = ref(defaultPrivacySensitiveDataState.settings);
    const settings = computed(() => privacySensitiveDataStore.settings);
    const showSkeletonLoader = computed(() => privacySensitiveDataStore.showSkeletonLoader);
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
      );
    });
    onMounted(async () => {
      await getSettings();
    });

    watch(
      localValue,
      (newVal) => {
        if (isEqual(newVal, settings.value)) return;
        updateSettings(newVal);
      },
      { deep: true }
    );

    watch(
      settings,
      (newVal) => {
        localValue.value = cloneDeep(newVal);
      },
      { deep: true }
    );

    return {
      localValue,
      showSkeletonLoader,
      actionNotAllowed,
      coronetSkeletonLoaderTypes,
    };
  },
});
</script>

<style scoped></style>
