<template>
  <v-form ref="form" v-model="valid" validate-on="input">
    <v-text-field
      v-model="localValue.name"
      class="mt-6"
      :label="localValue.name ? $t('general.name') : ''"
      variant="outlined"
      :rules="[required()]"
      :placeholder="$t('general.name')"
    />
    <v-select
      menu-icon="icon-triangle"
      :class="{ 'filter-active': localValue.format }"
      :placeholder="$t('general.format')"
      v-model="localValue.format"
      class="mt-6"
      :label="$t('general.format')"
      :items="formatOptions"
      :disabled="!!localValue.id"
      variant="outlined"
    >
    </v-select>
    <template v-if="localValue.format === SiemConnectorFormat.AZURE_SENTINEL">
      <v-text-field
        class="mt-6"
        v-model="getCurrentSiemProvider().workspaceId"
        variant="outlined"
        :rules="[required()]"
        :label="
          getCurrentSiemProvider().workspaceId
            ? $t(`modals.addSiemConnector.${localValue.format}.workspaceId`)
            : ''
        "
        :placeholder="$t(`modals.addSiemConnector.${localValue.format}.workspaceId`)"
      />
      <v-text-field
        class="mt-6"
        v-model="getCurrentSiemProvider().primaryKey"
        variant="outlined"
        :rules="[required()]"
        :label="
          getCurrentSiemProvider().primaryKey
            ? $t(`modals.addSiemConnector.${localValue.format}.primaryKey`)
            : ''
        "
        :placeholder="$t(`modals.addSiemConnector.${localValue.format}.primaryKey`)"
      />
      <v-text-field
        class="mt-6"
        v-model="getCurrentSiemProvider().logType"
        :placeholder="$t(`modals.addSiemConnector.${localValue.format}.logType`)"
        :label="
          getCurrentSiemProvider().logType
            ? $t(`modals.addSiemConnector.${localValue.format}.logType`)
            : ''
        "
        variant="outlined"
      />
    </template>
    <template v-else>
      <v-text-field
        class="mt-6"
        v-model="getCurrentSiemProvider().url"
        :label="getCurrentSiemProvider().url ? $t('modals.addSiemConnector.listenerUrlLabel') : ''"
        variant="outlined"
        :rules="urlRules"
        :placeholder="$t('modals.addSiemConnector.listenerUrlLabel')"
        :hint="$t('modals.addSiemConnector.listenerUrlHint')"
        persistent-hint
      />
      <key-value-input
        class="mt-6"
        v-if="localValue.format !== SiemConnectorFormat.FLUENCY"
        v-model:value="getCurrentSiemProvider().headers"
        :key-label="$t('modals.addSiemConnector.header')"
        :value-label="$t('modals.addSiemConnector.value')"
        :show-add-btn="localValue.format === SiemConnectorFormat.GENERIC"
        :key-config="{
          rules: headersRules,
          cols: 4,
          md: 4,
          disabled: localValue.format === SiemConnectorFormat.SPLUNK,
        }"
        :value-config="{
          rules: headersRules,
          cols: 8,
          md: 8,
        }"
      >
      </key-value-input>
      <v-text-field
        v-else
        v-model="getCurrentSiemProvider().headers![0].value"
        class="mt-6"
        :label="$t('modals.addSiemConnector.token')"
        variant="outlined"
        :rules="headersRules"
        :placeholder="$t('modals.addSiemConnector.token')"
      />
    </template>
    <v-checkbox
      v-if="workspaceType !== WorkspaceType.CHILD"
      v-model="localValue.applyToAll"
      class="mt-6"
      :ripple="false"
    >
      <template #label>
        <div class="body1">
          {{ $t("modals.addSiemConnector.applyToAll") }}
        </div>
      </template>
    </v-checkbox>
  </v-form>
</template>

<script lang="ts">
import Patterns from "@/constants/patterns";
import { defineComponent, reactive, ref, watch } from "vue";
import type { PropType } from "vue";
import { useI18n } from "vue-i18n";
import { SiemConnectorFormat } from "@/constants/connectors";
import { required } from "@/_helpers/validators";
import { useAccountStore } from "@/_store";
import KeyValueInput from "@/components/KeyValueInput.vue";
import cloneDeep from "lodash/cloneDeep";
import type { SiemConnector, SiemConnectorProviderData } from "@/_store/connectors/siem.module";
import { WorkspaceType } from "@/constants/workplaces";

const defaultConnector = {
  name: "",
  format: SiemConnectorFormat.SPLUNK,
  applyToAll: false,
  [SiemConnectorFormat.SPLUNK]: {
    url: "",
    headers: [
      {
        key: "Authorization",
        value: "",
      },
    ],
  },
  [SiemConnectorFormat.FLUENCY]: {
    url: "",
    headers: [
      {
        key: "Authorization",
        value: "",
      },
    ],
  },
  [SiemConnectorFormat.AZURE_SENTINEL]: {
    logType: "Coro",
    primaryKey: "",
    workspaceId: "",
  },
  [SiemConnectorFormat.GENERIC]: {
    headers: [
      {
        key: "",
        value: "",
      },
    ],
  },
};

export default defineComponent({
  name: "AddSiemConnectorModal",
  components: { KeyValueInput },
  methods: { required },
  props: {
    config: {
      type: Object as PropType<{ item: SiemConnector; action: string }>,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const valid = ref(false);
    const {
      account: { workspaceType },
    } = useAccountStore();

    const localValue: Partial<SiemConnector> = reactive(
      cloneDeep(props.config.item || defaultConnector)
    );

    emit("update:localValue", localValue);

    const formatOptions = Object.values(SiemConnectorFormat).map((value) => ({
      title: i18n.t(`connectors.siem.format.${value}`),
      value: value,
    }));

    watch(valid, (value: boolean) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    watch(
      localValue,
      (value) => {
        emit("update:localValue", value);
      },
      { deep: true }
    );

    const form = ref();

    const validate = async () => {
      const isValid = await form.value.validate();

      return isValid.valid;
    };

    const urlRules = [
      (v: string) => {
        if (!Patterns.URL.test(v)) {
          return i18n.t("validations.url");
        }

        if (!v) {
          return i18n.t("validations.required");
        }

        return true;
      },
    ];
    const headersRules = [
      (v: string) => {
        if (!v && localValue.format !== SiemConnectorFormat.GENERIC) {
          return i18n.t("validations.required");
        }

        return true;
      },
    ];

    const onFormatChange = () => {
      form.value.resetValidation();
    };

    const getCurrentSiemProvider = (): SiemConnectorProviderData => {
      return localValue[localValue.format!]!;
    };

    return {
      valid,
      localValue,
      urlRules,
      headersRules,
      formatOptions,
      onFormatChange,
      SiemConnectorFormat,
      workspaceType,
      WorkspaceType,
      form,
      validate,
      getCurrentSiemProvider,
    };
  },
});
</script>
