<template>
  <div>
    <div class="d-flex align-center subtitle1 mb-4">
      {{ $t(`accessControlPage.roles.scopes.${scopeName}.title`) }}
      <v-tooltip v-if="showDetailsTooltip" open-delay="300" location="top">
        <template #activator="{ props }">
          <v-icon v-bind="props" color="primary" icon="$info" />
        </template>
        {{ $t(`modals.addMspRole.tooltips.${scopeName}`) }}
      </v-tooltip>
      <div class="ml-auto w-15">
        <v-select
          v-model="bulkActionSelectModel"
          variant="outlined"
          density="compact"
          rounded
          :items="permissionOptions"
          :placeholder="$t('general.select')"
          :label="bulkActionSelectModel ? '' : $t('general.select')"
          @update:modelValue="onBulkActionSelect($event)"
          hide-details
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="$t(`accessControlPage.roles.details.permissionModes.${item.raw}`)"
            />
          </template>
          <template v-slot:selection="{ item }">
            {{ $t(`accessControlPage.roles.details.permissionModes.${item.raw}`) }}
          </template>
        </v-select>
      </div>
    </div>
    <table class="permission-table">
      <template v-for="(value, name) in model[scopeName]" :key="name">
        <tr>
          <td class="pl-4 py-4">
            <span class="subtitle1">
              {{ $t(`accessControlPage.roles.scopes.${scopeName}.${name}.title`) }}
            </span>
          </td>
          <td class="pr-4 py-4 w-15">
            <div class="d-flex justify-end">
              <v-select
                v-if="isEditAccessModePermission(name, value)"
                :model-value="value.accessMode"
                variant="underlined"
                density="compact"
                :items="permissionOptions"
                :rules="[required()]"
                :placeholder="$t('general.select')"
                :label="value.accessMode ? '' : $t('general.select')"
                @update:modelValue="
                  item[scopeName][name].accessMode = $event;
                  resetBulkActionSelectValue();
                "
                hide-details
              >
                <template v-slot:item="{ props, item }">
                  <v-list-item
                    v-bind="props"
                    :title="$t(`accessControlPage.roles.details.permissionModes.${item.raw}`)"
                  />
                </template>
                <template v-slot:selection="{ item }">
                  {{ $t(`accessControlPage.roles.details.permissionModes.${item.raw}`) }}
                </template>
              </v-select>
            </div>
          </td>
        </tr>
        <tr
          v-if="
            isEditAccessModePermission(name, value) &&
            value.accessMode === RolePermissionAccessMode.EDIT
          "
        >
          <td colspan="2">
            <template
              v-for="(permissionValue, permissionName) in value.editAccessModePermission"
              :key="permissionName"
            >
              <v-checkbox
                v-if="permissionName !== 'assignRoles'"
                class="ml-8"
                :model-value="permissionValue"
                density="compact"
                :label="
                  $t(
                    `accessControlPage.roles.scopes.${scopeName}.${name}.editAccessModePermission.${permissionName}`
                  )
                "
                @update:modelValue="
                  item[scopeName][name].editAccessModePermission[permissionName] = $event
                "
              />
            </template>
          </td>
        </tr>
        <tr v-else-if="!isEditAccessModePermission(name, value)">
          <td colspan="2">
            <template v-for="(permissionValue, permissionName) in value" :key="permissionName">
              <v-checkbox
                class="ml-8"
                :model-value="permissionValue"
                density="compact"
                :label="
                  $t(
                    `accessControlPage.roles.scopes.${scopeName}.${name}.editAccessModePermission.${permissionName}`
                  )
                "
                @update:modelValue="item[scopeName][name][permissionName] = $event"
              />
            </template>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  type GlobalScope,
  type NestedPermissions,
  type SpecialPermissions,
  type WorkspaceSubscriptionTypeAccess,
  type WorkspaceTypeAccess,
} from "@/_store/roles.module";
import { required } from "@/_helpers/validators";
import { emptyGlobalPermissions, RolePermissionAccessMode } from "@/constants/roles";
import defaultsDeep from "lodash/defaultsDeep";

const permissionOptions = [
  RolePermissionAccessMode.EDIT,
  RolePermissionAccessMode.VIEW,
  RolePermissionAccessMode.NO_ACCESS,
];

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    emptyPermissionsObject: {
      type: Object,
      default() {
        return { ...emptyGlobalPermissions };
      },
    },
    showDetailsTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:item"],
  setup(props, { emit }) {
    const scopeName = GlobalRoleScopes.GLOBAL_SCOPE;
    const model = ref(defaultsDeep(props.item, props.emptyPermissionsObject));
    const bulkActionSelectModel = ref<RolePermissionAccessMode>();

    const onBulkActionSelect = (accessMode: RolePermissionAccessMode) => {
      const scope = model.value[scopeName];
      Object.entries(scope).forEach(([name, value]) => {
        if (
          isEditAccessModePermission(
            name,
            value as
              | NestedPermissions
              | WorkspaceSubscriptionTypeAccess
              | WorkspaceTypeAccess
              | SpecialPermissions
          )
        ) {
          scope[name].accessMode = accessMode;
        }
      });
    };

    const resetBulkActionSelectValue = () => {
      bulkActionSelectModel.value = undefined;
    };

    watch(
      model,
      (value) => {
        emit("update:item", value);
      },
      { deep: true }
    );

    const isEditAccessModePermission = (
      permissionName: string | number,
      permission:
        | NestedPermissions
        | WorkspaceSubscriptionTypeAccess
        | WorkspaceTypeAccess
        | SpecialPermissions
    ): permission is NestedPermissions => {
      return [
        GlobalRoleScopeSection.GLOBAL_ADMIN_USERS,
        GlobalRoleScopeSection.GLOBAL_ROLES,
        GlobalRoleScopeSection.SOC_PORTAL,
      ].includes(permissionName as keyof GlobalScope);
    };

    return {
      model,
      scopeName,
      permissionOptions,
      RolePermissionAccessMode,
      bulkActionSelectModel,
      isEditAccessModePermission,
      required,
      onBulkActionSelect,
      resetBulkActionSelectValue,
    };
  },
});
</script>
<style lang="scss" scoped>
.permission-table {
  border-radius: 8px;
  border-spacing: 0;
  width: 100%;
  border: 1px solid rgb(var(--v-theme-indigo-pale)) !important;

  td {
    border-bottom: 1px solid rgb(var(--v-theme-indigo-pale)) !important;
  }

  tr:last-child > td {
    border-bottom: 0 !important;
  }
}

:deep(*) {
  .v-field__outline {
    &:before,
    &:after {
      border: 0 !important;
    }
  }
}
</style>
