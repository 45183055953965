import type { Ref } from "vue";
import { ref } from "vue";

interface PayloadWithFile {
  file: File;
  [key: string]: any; // Allows additional properties for the provided callback
}

type ImportPayload = File | PayloadWithFile | null;

export function useImportCsvModal() {
  const file: Ref<File | null> = ref(null);
  const csvLoaded = ref(false);
  const csvLoading = ref(false);
  const csvLoadError = ref(false);
  const invalidRecords = ref(0);
  const successfullyUploaded = ref(0);

  const handleFileUpload = (uploadedFile: File) => {
    file.value = uploadedFile;
  };

  const handleImport = async (payload: ImportPayload = file.value, callback: Function) => {
    if (!file.value) return;

    csvLoaded.value = false;
    csvLoading.value = true;

    try {
      const response = await callback(payload);
      csvLoaded.value = true;
      csvLoading.value = false;
      csvLoadError.value = false;
      invalidRecords.value = response?.data?.invalidRecords;
      successfullyUploaded.value = response?.data?.successfullyUploaded;
    } catch (e) {
      console.error(e);
      csvLoaded.value = true;
      csvLoading.value = false;
      csvLoadError.value = true;
    }
  };

  return {
    file,
    csvLoaded,
    csvLoading,
    csvLoadError,
    invalidRecords,
    successfullyUploaded,
    handleFileUpload,
    handleImport,
  };
}
