<template>
  <table-wrapper>
    <v-data-table
      v-model:expanded="expanded"
      :headers="headers"
      :items="items"
      :items-length="items.length"
      item-value="name"
      show-expand
      :show-select="false"
      hide-default-footer
      hide-no-data
    >
      <template #headers>
        <tr>
          <th class="w-5 pr-0"></th>
          <th class="w-30">
            <span>
              {{ $t(`emailSettings.settingsTab.securityAwarenessTable.headers.name`) }}
            </span>
          </th>
          <th class="w-30">
            <span>
              {{ $t(`emailSettings.settingsTab.securityAwarenessTable.headers.configuration`) }}
            </span>
          </th>
          <th class="w-5"></th>
        </tr>
      </template>
      <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
        <tr :id="`${index}`" :key="index">
          <td>
            <v-icon
              class="ml-1"
              size="24"
              :class="{ rotated: isExpanded(internalItem) }"
              icon="$chevronDown"
              @click="toggleExpand(internalItem)"
            >
            </v-icon>
          </td>
          <td>
            <span class="body2 text--semibold">{{ item.name }}</span>
          </td>
          <td>
            <span class="body2"
              >{{
                $t(
                  "emailSettings.settingsTab.securityAwarenessTable.configurationDescription",
                  {
                    quantity: item.terms.length,
                  },
                  item.terms.length
                )
              }}
              {{
                $t(
                  "emailSettings.settingsTab.securityAwarenessTable.headerDescription",
                  {
                    quantity: item.headers.length,
                  },
                  item.headers.length
                )
              }}</span
            >
          </td>
          <td>
            <v-menu bottom left>
              <template v-slot:activator="{ props }">
                <v-icon icon="$dots" class="item-clickable" v-bind="props"></v-icon>
              </template>

              <v-list>
                <v-list-item class="cursor-pointer">
                  <v-list-item-title @click="items.splice(index, 1)">
                    {{ $t("emailSettings.settingsTab.securityAwarenessTable.actions.remove") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template #expanded-row="{ item }">
        <tr :key="item.name" :id="item.name">
          <td></td>
          <td :colspan="3">
            <div class="py-4">
              <div class="subtitle2 mb-2">
                {{ $t("emailSettings.settingsTab.securityAwarenessTable.domainsOrIp") }}
              </div>
              <div v-for="term in item.terms" :key="term">
                <span>{{ term }}</span>
              </div>
              <div class="subtitle2 mt-4 mb-2">
                {{ $t("emailSettings.settingsTab.securityAwarenessTable.headersTitle") }}
              </div>
              <div v-for="header in item.headers" :key="header.name">
                <span class="text--semibold">
                  {{ $t("emailSettings.settingsTab.securityAwarenessTable.headers.name") }}:
                </span>
                <span>{{ header.name }}/ </span>
                <span class="text--semibold">
                  {{ $t("emailSettings.settingsTab.securityAwarenessTable.headers.value") }}:
                </span>
                <span>{{ header.value }} </span>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </table-wrapper>
</template>

<script lang="ts">
import TableWrapper from "@/components/TableWrapper.vue";
import { defineComponent, ref } from "vue";
import type { PropType } from "vue";
import type { SecurityAwarenessProviderConfiguration } from "@/_store/email-security/email-settings.module";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<SecurityAwarenessProviderConfiguration[]>,
      required: true,
      default() {
        return [];
      },
    },
  },
  setup() {
    const expanded = ref([]);
    const headers = [
      {},
      {
        key: "name",
        sortable: false,
      },
      {
        key: "configuration",
        sortable: false,
      },
    ];
    return {
      headers,
      expanded,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.v-table) {
  box-shadow: none !important;
}
</style>
