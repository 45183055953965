<template>
  <v-navigation-drawer rail permanent color="primary" border="0">
    <div class="pa-2 d-flex align-center justify-center">
      <v-icon size="40" icon="$coroConsole" />
    </div>

    <v-divider color="indigo-medium" class="mb-6" opacity="100"></v-divider>

    <div class="d-flex flex-column justify-space-between">
      <v-list v-if="account.workplace" :lines="false">
        <template v-for="(button, index) in menuButtons" :key="index">
          <template v-if="button.show">
            <v-list-item
              v-if="button.routeName"
              class="mb-6"
              :to="{ name: button.routeName }"
              :active="isMenuButtonActive([button.routeName])"
              color="primary"
              active-class="menu-button--active"
              min-height="24"
              max-height="24"
              width="56"
              height="24"
              :ripple="false"
              variant="plain"
            >
              <template #default>
                <v-icon class="menu-icon" :icon="button.icon"> </v-icon>
                <v-tooltip
                  location="end"
                  open-delay="300"
                  content-class="menu-tooltip"
                  activator="parent"
                  offset="12"
                >
                  <span v-html="button.text" />
                </v-tooltip>
              </template>
            </v-list-item>
            <v-menu
              v-else-if="button.submenu"
              v-model="menuOpened"
              ref="submenu"
              open-on-click
              location="end"
              :offset="5"
              close-on-content-click
            >
              <template #activator="{ props }">
                <v-list-item
                  class="mb-6"
                  :active="isMenuButtonActive([...button.submenu.map((v) => v.routeName!)])"
                  color="primary"
                  active-class="menu-button--active"
                  min-height="24"
                  max-height="24"
                  width="56"
                  height="24"
                  v-bind="props"
                  :ripple="false"
                  variant="plain"
                >
                  <template #default>
                    <v-icon class="menu-icon" :icon="button.icon"> </v-icon>
                    <v-tooltip
                      location="end"
                      open-delay="300"
                      content-class="menu-tooltip"
                      activator="parent"
                      offset="12"
                      :disabled="menuOpened"
                    >
                      <span v-html="button.text" />
                    </v-tooltip>
                  </template>
                </v-list-item>
              </template>

              <v-list>
                <v-list-subheader :title="$t('general.viewByList')" />
                <template v-for="option in button.submenu" :key="option.text">
                  <v-list-item
                    v-if="option.show"
                    :to="{ name: option.routeName }"
                    :active="isMenuButtonActive([option.routeName!])"
                  >
                    <v-list-item-title>
                      {{ option.text }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
        </template>
      </v-list>
    </div>

    <template v-slot:append>
      <v-list v-if="account.workplace" :lines="false">
        <v-list-item
          class="mb-6"
          :active="isMenuButtonActive([RouteName.ALERTS])"
          :to="{ name: RouteName.ALERTS }"
          color="primary"
          active-class="menu-button--active"
          min-height="24"
          max-height="24"
          width="56"
          height="24"
          variant="plain"
          :ripple="false"
        >
          <template #default>
            <v-icon class="menu-icon" icon="$bell"> </v-icon>
            <v-tooltip
              location="end"
              open-delay="300"
              content-class="menu-tooltip"
              activator="parent"
              offset="12"
            >
              <span v-html="$t('general.messages')" />
            </v-tooltip>
          </template>
        </v-list-item>
      </v-list>

      <v-menu
        :open-on-hover="false"
        close-on-content-click
        close-on-back
        min-width="320"
        location="end"
      >
        <template #activator="{ props }">
          <div class="d-flex w-100 justify-center align-center mb-4">
            <v-avatar
              role="button"
              v-bind="props"
              :size="36"
              color="indigo-light"
              class="subtitle2"
              :text="initials"
            />
          </div>
        </template>
        <div class="px-4 pt-4 rounded bg-white elevation-4">
          <div class="d-flex ml-4">
            <v-avatar :size="40" color="indigo-light" class="subtitle2 mr-4" :text="initials" />
            <div class="d-flex flex-column">
              <div class="subtitle2 word-break-all" v-if="nameInfo.firstName && nameInfo.lastName">
                {{ `${nameInfo.firstName} ${nameInfo.lastName}` }}
              </div>
              <div class="body2 word-break-all">
                {{ nameInfo.email }}
              </div>
            </div>
          </div>

          <v-list density="compact">
            <div
              :data-testid="`account-menu-item-${i}`"
              v-for="(item, i) in userContextMenu"
              :key="i"
            >
              <v-list-item v-if="item.type === 'link'" :to="{ name: item.routeName }">
                <v-list-item-title class="text-capitalize">
                  {{ $t(item.titleKey) }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else-if="item.type === 'callback'" @click="item.callback()">
                <v-list-item-title class="text-capitalize">
                  {{ $t(item.titleKey) }}
                </v-list-item-title>
              </v-list-item>
              <v-divider v-else class="my-2" />
            </div>
          </v-list>
        </div>
      </v-menu>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { computed, defineComponent, ref, type Ref } from "vue";
import { useAccountStore } from "@/_store";
import { useMyAccountStore } from "@/_store/my-account.module";
import { i18n } from "@/plugins/i18n";
import { isGlobalAccessRestricted, isGlobalActionRestricted } from "@/_helpers/global-permissions";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  RolePermissionsScope,
} from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { isAccessRestricted, isModuleDisabled } from "@/_helpers/utils";
import { isMspAccessRestricted } from "@/_helpers/msp-permissions";
import { RouteName } from "@/constants/routes";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";

type ContextMenuItem =
  | {
      type: "link";
      titleKey: string;
      routeName: RouteName;
    }
  | {
      type: "callback";
      titleKey: string;
      callback: () => void;
    }
  | {
      type: "divider";
    };

type NameInformation = {
  firstName?: string;
  lastName?: string;
  email: string;
};

type MenuButton = {
  routeName?: RouteName;
  show: boolean;
  icon: string;
  text: string;
  submenu?: Omit<MenuButton, "icon" | "submenu">[];
};

export default defineComponent({
  setup() {
    const accountStore = useAccountStore();
    const myAccountStore = useMyAccountStore();
    const route = useRoute();
    const router = useRouter();
    const menuOpened = ref();
    const { account } = storeToRefs(accountStore);

    const isMFASetupInProgress = computed(() => route.name === RouteName.MFA_PAGE);

    const nameInfo = computed<NameInformation>(() => {
      const { firstName, lastName } = myAccountStore.myAccount.profileData;
      const email = accountStore.account.email;
      return { firstName, lastName, email };
    });

    const initials = computed(() => {
      const { firstName, lastName, email } = nameInfo.value;
      if (firstName && lastName) {
        return firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
      }

      return email.substring(0, 2).toUpperCase();
    });

    const backOfficeEnabled = computed(
      () =>
        !isGlobalActionRestricted(
          GlobalRoleScopes.GLOBAL_SCOPE,
          GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
          "backOffice"
        )
    );
    const socTicketLogEnabled = computed(
      () =>
        !isGlobalAccessRestricted(GlobalRoleScopes.GLOBAL_SCOPE, GlobalRoleScopeSection.SOC_PORTAL)
    );
    const devicesButtonEnabled = computed(() => {
      return (
        [
          SubscriptionModule.ENDPOINT_SECURITY,
          SubscriptionModule.EDR,
          SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
          SubscriptionModule.NETWORK,
        ].some((module) => !isModuleDisabled(module)) &&
        !isAccessRestricted(RolePermissionsScope.VIEWS, "devicesView")
      );
    });
    const protectedUsersButtonEnabled = computed(() => {
      return (
        [
          SubscriptionModule.USER_DATA_GOVERNANCE,
          SubscriptionModule.EMAIL_SECURITY,
          SubscriptionModule.CLOUD_SECURITY,
        ].some((module) => !isModuleDisabled(module)) &&
        !isAccessRestricted(RolePermissionsScope.VIEWS, "usersView")
      );
    });
    const showManageWorkspaces = computed(() => {
      const mspPermissionKeys = Object.values(MspPortalScopeSection);
      return mspPermissionKeys.some((key) => !isMspAccessRestricted(key));
    });

    const isMenuButtonActive = (parentRouteNames: RouteName[]) => {
      return parentRouteNames.some((parentRouteName) => {
        const parentRoute = router.resolve({ name: parentRouteName });
        return route.fullPath.startsWith(parentRoute.fullPath);
      });
    };

    const menuButtons = computed<MenuButton[]>(() => {
      return [
        {
          routeName: RouteName.DASHBOARD,
          show: true,
          icon: "$dashboard",
          text: i18n.global.t("general.home"),
        },
        {
          show: true,
          icon: "$events",
          text: i18n.global.t("general.views"),
          submenu: [
            {
              routeName: RouteName.PROTECTED_USERS,
              show: protectedUsersButtonEnabled.value,
              text: i18n.global.t("general.users"),
            },
            {
              routeName: RouteName.DEVICES,
              show: devicesButtonEnabled.value,
              text: i18n.global.t("general.devices"),
            },
            {
              routeName: RouteName.TICKETS,
              show: true,
              text: i18n.global.t("general.tickets"),
            },
          ],
        },
        {
          routeName: RouteName.SETTINGS,
          show: true,
          icon: "$settings",
          text: i18n.global.t("general.controlPanel"),
        },
        {
          routeName: RouteName.BACK_OFFICE_PAGE,
          show: backOfficeEnabled.value,
          icon: "$backOffice",
          text: i18n.global.t("general.backOffice"),
        },

        {
          routeName: RouteName.MSP_PAGE,
          show: showManageWorkspaces.value,
          icon: "$manageWorkspaces",
          text: i18n.global.t("general.manageWorkspaces"),
        },
        {
          routeName: RouteName.SOC_TICKETS,
          show: socTicketLogEnabled.value,
          icon: "$socXs",
          text: i18n.global.t("general.socTicketLog"),
        },
      ];
    });

    const userContextMenu: Ref<ContextMenuItem[]> = computed(() => {
      const supportMenuLinkItem: ContextMenuItem[] = accountStore.account.supportEnabled
        ? [
            {
              type: "callback",
              titleKey: "appBar.userContextMenu.contactSupport",
              callback: () => window.open("https://docs.coro.net/", "_blank"),
            },
          ]
        : [];

      const logoutItem: ContextMenuItem = {
        type: "callback",
        titleKey: "appBar.userContextMenu.logout",
        callback: accountStore.logout,
      };

      const menuItems: ContextMenuItem[] = [logoutItem];

      if (accountStore.account.workplace) {
        if (!isMFASetupInProgress.value) {
          menuItems.unshift(
            {
              type: "link",
              titleKey: "appBar.userContextMenu.myAccount",
              routeName: RouteName.MY_ACCOUNT_PAGE,
            },
            ...supportMenuLinkItem,
            { type: "divider" }
          );
        } else {
          menuItems.unshift(...supportMenuLinkItem, { type: "divider" });
        }
      }

      return menuItems;
    });

    return {
      menuOpened,
      nameInfo,
      initials,
      menuButtons,
      userContextMenu,
      isMenuButtonActive,
      RouteName,
      account,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-tooltip :deep(.menu-tooltip.v-overlay__content) {
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent transparent rgb(var(--v-theme-primary));
    transform: rotate(90deg);
  }
}

.icon.menu-icon:before {
  color: white;
}

.menu-button--active {
  opacity: 1;
  border-left: 4px solid white;
  padding-left: 12px !important;
}
</style>
