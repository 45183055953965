<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.HIGHLIGHTABLE_TABLE"
  >
    <v-progress-linear v-if="loading" indeterminate height="2px" />
    <table-wrapper>
      <v-data-table-server
        class="white"
        v-model:expanded="expanded"
        :items="settings"
        item-value="tableItemId"
        :show-expand="true"
        :hide-default-header="true"
        :items-per-page="-1"
        :items-length="0"
        color="primary"
        :group-by="[{ key: 'osType', order: 'asc' }]"
        :group-desc="false"
      >
        <template #headers>
          <tr>
            <th class="w-5"></th>
            <th class="w-50">
              {{ $t("devicesSettings.devicePostureTab.vulnerability") }}
            </th>
            <th class="pr-6">
              {{ $t("devicesSettings.devicePostureTab.policies") }}
            </th>
            <th class="pr-6 w-15">
              {{ $t("devicesSettings.devicePostureTab.action") }}
            </th>
            <th></th>
          </tr>
        </template>
        <template v-slot:group-header="{ item, toggleGroup, isGroupOpen }">
          <tr>
            <th class="group-header pl-0" colspan="5">
              <v-btn
                class="d-none"
                :ref="`${item.items[0].raw.osType}ExpandButton`"
                @click="toggleGroup(item)"
              ></v-btn>
              <v-btn
                :icon="true"
                variant="plain"
                :ripple="false"
                class="toggle-expander group-expand-icon"
                @click="expandGroup(item.items as any)"
              >
                <v-icon :class="{ rotated: isGroupOpen(item) }" icon="$chevronDown"> </v-icon>
              </v-btn>
              <span class="subtitle1">
                {{ $t(`devicesSettings.devicePostureTab.osTypes.${item.items[0].raw.osType}`) }}
              </span>
            </th>
          </tr>
        </template>

        <template #item="{ item, toggleExpand, isExpanded, internalItem }">
          <tr>
            <td class="w-5">
              <v-icon
                class="mr-2 cursor-pointer"
                icon="$dropdown"
                color="primary"
                v-if="showExpandGroup(item)"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
              />
            </td>
            <td class="pl-0">
              <div class="d-flex align-center">
                <v-icon size="40" class="mr-4" :icon="getPolicyTypeIcon(item.policyType)"> </v-icon>
                <span class="coro-subtitle-1">{{
                  $t(`devicesSettings.devicePostureTab.posturePolicies.${item.policyType}`)
                }}</span>
              </div>
            </td>
            <td>
              <div v-if="item.policiesQuantity" class="body2">
                {{
                  $t(
                    "devicesSettings.devicePostureTab.policiesQuantity",
                    {
                      n: item.policiesQuantity,
                    },
                    item.policiesQuantity
                  )
                }}
              </div>
              <div v-else class="body2 text--grey">
                {{ $t("devicesSettings.devicePostureTab.noPolicies") }}
              </div>
            </td>
            <td class="w-5 pr-1"></td>
            <td class="w-5 pr-1"></td>
          </tr>
        </template>
        <template v-slot:expanded-row="{ item }">
          <template v-for="policy in item.policies" :key="policy.id">
            <tr v-if="policy.labels?.length" :key="policy.id">
              <td class="w-5"></td>
              <td
                :colspan="2"
                class="pl-4 pr-5 py-4 word-break-all white-space-normal expandable-content"
              >
                <div class="pl-10 d-flex flex-wrap label-container">
                  <span
                    v-for="label in policy.labels"
                    :key="label.id"
                    class="label-item h-100 flex-shrink-0"
                    :style="{ 'background-color': label.color }"
                  >
                    {{ (label.name as any).replaceAll(" ", "&nbsp;") }}
                  </span>
                </div>
              </td>
              <td class="pl-4">
                <span class="body2" :class="getPostureTextClass((policy as any).postureValue)">
                  {{ policy.displayValue }}
                </span>
              </td>
              <td>
                <div class="d-flex">
                  <v-menu bottom left>
                    <template v-slot:activator="{ props }">
                      <v-icon icon="$dots" class="item-clickable" v-bind="props"></v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(action, index) in actions"
                        :key="index"
                        :value="index"
                        @click="handleAction(action, policy, item)"
                      >
                        <v-list-item-title>
                          {{ $t(`devicesSettings.devicePostureTab.actions.${action}`) }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </v-data-table-server>
    </table-wrapper>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  DevicePostureAction,
  DevicePostureChoice,
  DevicePostureOsType,
  DevicePostureSettings,
  DeviceVulnerability,
} from "@/constants/devices";
import type {
  DevicePostureAdaptedItem,
  DevicePostureCombinedType,
  DevicePostureFormData,
} from "@/_store/device-posture/devices-posture-settings.module";
import { useDevicesPostureStore } from "@/_store/device-posture/devices-posture-settings.module";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { format } from "date-fns";
import { useI18n } from "vue-i18n";
import { difference, union } from "lodash";
import { useDialogsStore } from "@/_store/dialogs.module";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { ModalWidth } from "@/constants/modals";
import AddOrEditPostureModal from "@/components/modals/device-posture/AddOrEditPostureModal.vue";

export default defineComponent({
  components: {
    TableWrapper,
  },
  setup() {
    const devicePostureStore = useDevicesPostureStore();
    const { showSkeletonLoader, loading, settings } = storeToRefs(devicePostureStore);
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const { getAllPostureSettings, deleteDevicePosturePolicy, editDevicePosturePolicy } =
      devicePostureStore;

    const icons: Record<string, string> = {
      [DeviceVulnerability.UAC_NOTIFICATIONS_MISSING]: "$vulnerability",
      [DeviceVulnerability.DEVICE_PASSWORD_MISSING]: "$listAuthentication",
      [DeviceVulnerability.FIREWALL_DISABLED]: "$endpointVulnerability",
      [DeviceVulnerability.ENCRYPTION_DISABLED]: "$deviceEncryption",
      [DeviceVulnerability.DEVELOPMENT_MODE_ENABLED]: "$developmentMode",
      [DeviceVulnerability.NON_GENUINE_WINDOWS]: "$nonGenuineWindows",
      [DevicePostureSettings.WIFI]: "$wifiNew",
      [DevicePostureSettings.DLP_SCAN_SCHEDULE]: "$deviceEncryption",
      [DevicePostureSettings.USER_ACCOUNT_POLICY]: "$file",
      [DeviceVulnerability.GATEKEEPER_UNAVAILABLE]: "$firewallDisabled",
      [DeviceVulnerability.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE]: "$firewallDisabled",
      [DeviceVulnerability.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE]: "$firewallDisabled",
      [DevicePostureSettings.USB_LOCKDOWN]: "$lock",
    };

    const expanded = ref<string[]>([]);
    const windowsSettingsLabeledExpandButton = ref();
    const macOsSettingsLabeledExpandButton = ref();
    const groups = ref([]);
    const getPolicyTypeIcon = (policyType: DeviceVulnerability | DevicePostureSettings): string => {
      return icons[policyType];
    };

    const getPostureTextClass = (data: DevicePostureChoice) => {
      return {
        "text-error": data === DevicePostureChoice.ENFORCE,
        "text-indigo-medium": data === DevicePostureChoice.REVIEW,
      };
    };

    const expandGroup = (items: [{ raw: DevicePostureAdaptedItem }]) => {
      // if already expanded, then filter out the group items from expanded items
      // if not - then add only unique values because separate items could be expanded before

      const mappedItems: string[] = items.map(({ raw }) => raw.tableItemId);

      expanded.value = isExpanded(mappedItems)
        ? expanded.value.filter((v) => !mappedItems.includes(v))
        : union(expanded.value, mappedItems);
    };
    const isExpanded = (items: string[]) => {
      return expanded.value.length ? difference(items, expanded.value).length === 0 : false;
    };

    const showExpandGroup = (item: DevicePostureAdaptedItem) => {
      return !!item.policiesQuantity;
    };

    const handleAction = (
      action: DevicePostureAction,
      policy: DevicePostureCombinedType,
      item: DevicePostureAdaptedItem
    ) => {
      let dialogConfig;
      switch (action) {
        case DevicePostureAction.REMOVE: {
          dialogConfig = confirmationDialogsConfigConstructor({
            item: {
              ...policy,
              policyType: item.policyType,
              osType: item.osType,
            },
            action,
            callback: ({ item }: { item: DevicePostureFormData }) =>
              deleteDevicePosturePolicy(item),
            disable: isWorkspaceFrozenOrActionRestricted(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.ENDPOINT_SECURITY
            ),
            text: i18n.t(`modals.${action}.description`),
          });
          break;
        }
        default: {
          // Edit
          dialogConfig = componentDialogsConfigConstructor({
            item: {
              ...policy,
              policyType: item.policyType,
              osType: item.osType,
            },
            action,
            component: AddOrEditPostureModal,
            width: ModalWidth.LARGE,
            callback: editDevicePosturePolicy,
            disable: isWorkspaceFrozenOrActionRestricted(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.ENDPOINT_SECURITY
            ),
            scrollable: true,
          });
          break;
        }
      }
      dialogsStore.openDialog(dialogConfig);
    };

    onMounted(async () => {
      await getAllPostureSettings(true);

      if (windowsSettingsLabeledExpandButton.value) {
        windowsSettingsLabeledExpandButton.value.$el.click();
      }

      if (macOsSettingsLabeledExpandButton.value) {
        macOsSettingsLabeledExpandButton.value.$el.click();
      }
    });

    return {
      coronetSkeletonLoaderTypes,
      DevicePostureOsType,
      expanded,
      windowsSettingsLabeledExpandButton,
      macOsSettingsLabeledExpandButton,
      groups,
      showSkeletonLoader,
      settings,
      showExpandGroup,
      handleAction,
      getPolicyTypeIcon,
      loading,
      expandGroup,
      actions: [DevicePostureAction.EDIT, DevicePostureAction.REMOVE],
      headers: [
        { title: "", key: "data-table-expand", sortable: false },
        {
          title: i18n.t("devicesSettings.devicePostureTab.vulnerability"),
          key: "vulnerability",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.devicePostureTab.policies"),
          key: "policy",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.devicePostureTab.action"),
          key: "action",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
      format,
      getPostureTextClass,
    };
  },
});
</script>

<style lang="scss" scoped>
.label-container {
  gap: 8px;
}

.label-item {
  display: inline-block;
  border-radius: 2px;
  overflow-wrap: initial;
  padding: 0 4px;
}

.group-expand-icon {
  .icon {
    background-color: rgb(var(--v-theme-primary));
    border-radius: 50%;

    &::before {
      color: rgb(var(--v-theme-white)) !important;
    }
  }
}
.group-header {
  background: rgb(var(--v-theme-indigo-pale)) !important;
  border-bottom: 0 !important;
}

.v-btn--icon {
  opacity: 1;
}

:deep(*) {
  .v-data-table-footer {
    display: none;
  }

  .v-data-table tr {
    height: 56px;
  }

  .v-select__selections {
    justify-content: flex-end;
  }

  .v-select__selections input {
    display: none !important;
  }
}
</style>
