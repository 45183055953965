<template>
  <div>
    <div class="body1 mb-6">
      {{
        $t("modals.addAccessPermissions.description", {
          service: $t(`services.${config.item.serviceName}`),
        })
      }}
    </div>
    <v-form ref="form" v-model="valid">
      <v-autocomplete
        v-if="isSpecificGroup"
        v-model="localValue.monitoredScope.groups"
        class="mt-6"
        :loading="dataLoading"
        :items="groups"
        :label="
          localValue.monitoredScope.groups.length
            ? $t('modals.addAccessPermissions.group.label')
            : ''
        "
        :placeholder="$t('modals.addAccessPermissions.group.placeholder')"
        :rules="[atLeastOneIsRequired()]"
        variant="outlined"
        multiple
        no-filter
        return-object
        :hide-no-data="!groupSearchTerm?.length"
        :no-data-text="$t('modals.addAccessPermissions.group.noData')"
        data-testid="add-access-restrictions-group-autocomplete"
        item-value="groupId"
        item-title="serviceName"
        clear-on-select
        v-model:search="groupSearchTerm"
        @update:search="onGroupsSearchUpdate($event)"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            close-icon="$closeCircle"
            :text="item.title"
            color="indigo-faint"
          >
            <div class="d-flex align-center">
              <coro-icon class="service-icon" :icon-name="item.raw.serviceName"></coro-icon>
              <span class="ml-1 mr-2">{{ item.raw.groupName }}</span>
            </div>
          </v-chip>
        </template>
        <template #item="{ props, item }">
          <v-list-item v-bind="props" title="">
            <div
              class="d-flex align-center"
              :data-testid="`add-access-restrictions-group-${item.raw.name}`"
            >
              <coro-icon class="service-icon" :icon-name="item.raw.serviceName"></coro-icon>
              <span class="ml-1">{{ item.raw.groupName }}</span>
            </div>
          </v-list-item>
        </template>
      </v-autocomplete>

      <v-autocomplete
        v-if="isSpecificUser"
        v-model="localValue.monitoredScope.users"
        data-testid="add-user-modal-combobox"
        class="mt-3 coro-scrollable-combobox"
        :loading="dataLoading"
        :items="users"
        :label="$t('modals.addAccessPermissions.users.label')"
        :placeholder="$t('modals.addAccessPermissions.users.placeholder')"
        :rules="[atLeastOneIsRequired(), usersRule]"
        variant="outlined"
        multiple
        no-filter
        return-object
        hide-no-data
        item-value="userId"
        item-text="email"
        clear-on-select
        @update:model-value="handleChange()"
        @update:search="onUsersSearchUpdate($event)"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            close-icon="$closeCircle"
            :text="item.title"
            :color="getChipColor(item.raw.email, Patterns.EMAIL)"
          >
            <div class="d-flex align-center justify-space-between">
              <span class="ml-1 mr-2">{{ item.raw.email }}</span>
            </div>
          </v-chip>
        </template>
        <template #item="{ props, item }">
          <v-list-item
            v-bind="props"
            :data-testid="`item-${camelCase(item.raw.email)}`"
            :title="item.raw.email"
          >
          </v-list-item>
        </template>
      </v-autocomplete>

      <div class="subtitle1 mt-8 mb-4">
        {{ $t("modals.addAccessPermissions.restrict.title") }}
      </div>
      <v-select
        v-model="localValue.restrictions.type"
        class="restriction-select"
        data-testid="access-restriction-modal-restriction-type-select"
        :label="
          localValue.restrictions.type ? '' : $t('modals.addAccessPermissions.restrict.placeholder')
        "
        :items="['country', 'ip']"
        :rules="[required('Please select permission type')]"
        variant="outlined"
        :return-object="false"
      >
        <template #selection="{ item }">
          {{
            item.value
              ? $t(`modals.addAccessPermissions.restrict.types.${item.value}`)
              : $t("modals.addAccessPermissions.restrict.placeholder")
          }}
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :data-testid="`access-restriction-modal-restriction-restrict-by-${item.value}`"
            :title="$t(`modals.addAccessPermissions.restrict.types.${item.value}`)"
          >
          </v-list-item>
        </template>
      </v-select>

      <template v-if="isRestrictionByCountry">
        <v-btn-toggle
          v-model="restrictBy"
          color="primary"
          class="mb-5"
          mandatory
          density="compact"
          selected-class="active-btn"
        >
          <v-btn value="country" class="toggle-btn mr-2" rounded="xl" variant="outlined">
            {{ $t("modals.addAccessPermissions.restrict.allCountries") }}
          </v-btn>
          <v-btn value="state" class="toggle-btn" rounded="xl" variant="outlined">
            {{ $t("modals.addAccessPermissions.restrict.usaStates") }}
          </v-btn>
        </v-btn-toggle>

        <v-autocomplete
          v-model="localValue.restrictions.allowedCountries"
          item-value="countryKey"
          item-title="name"
          :items="countriesOrStates"
          :label="
            localValue.restrictions.allowedCountries.length
              ? $t('modals.addAccessPermissions.restrict.country.label')
              : ''
          "
          :placeholder="$t('modals.addAccessPermissions.restrict.country.placeholder')"
          maxlength="150"
          variant="outlined"
          multiple
          chips
          closable-chips
          menu-icon="$triangle"
          :rules="[atLeastOneIsRequired()]"
          clear-on-select
          :no-data-text="$t('modals.addAccessPermissions.restrict.country.noData')"
          data-testid="access-restriction-modal-country-select"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              :closable="true"
              variant="flat"
              size="default"
              close-icon="$closeCircle"
              :text="getCountryOrStateName(item.value)"
              color="indigo-faint"
            ></v-chip>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" :title="item.title" />
          </template>
        </v-autocomplete>
      </template>
      <template v-if="isRestrictionByIP">
        <v-combobox
          v-model.trim="localValue.restrictions.allowedIps"
          :label="
            localValue.restrictions.allowedIps.length
              ? $t('modals.addAccessPermissions.restrict.ip.label')
              : ''
          "
          :placeholder="$t('modals.addAccessPermissions.restrict.ip.placeholder')"
          :rules="[atLeastOneIsRequired(), ipRule]"
          maxlength="150"
          variant="outlined"
          :multiple="true"
          :chips="true"
          :closable-chips="true"
          clearable
          hide-details="auto"
          data-testid="access-restriction-modal-add-ip-addresses-input"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              variant="flat"
              size="default"
              close-icon="$closeCircle"
              :color="getChipColor(item.value, Patterns.IP)"
              :text="item.title"
            ></v-chip>
          </template>
        </v-combobox>
        <v-text-field
          v-model="localValue.restrictions.description"
          class="mt-6"
          variant="outlined"
          data-testid="access-restriction-modal-description-input"
          :placeholder="$t('modals.addAccessPermissions.restrict.ipDescription.placeholder')"
        ></v-text-field>
      </template>

      <template v-if="isRestrictionByIP || isRestrictionByCountry">
        <div class="subtitle1 mt-8 mb-4">
          {{ $t("modals.addAccessPermissions.remediation.title") }}
        </div>
        <v-select
          v-model="localValue.remediation"
          :items="remediationOptions"
          data-testid="access-restriction-modal-remediation-select"
          variant="outlined"
        >
          <template #selection="{ item }">
            <span
              v-if="item.title"
              :data-testid="`access-restriction-modal-remediation-chip-${item.title}`"
              >{{ $t(`modals.addAccessPermissions.remediation.types.${item.title}`) }}</span
            >
          </template>
          <template #item="{ item, props }">
            <v-list-item
              v-bind="props"
              :title="$t(`modals.addAccessPermissions.remediation.types.${item.title}`)"
              :data-testid="`access-restriction-modal-remediation-${item.title}`"
            >
            </v-list-item>
          </template>
        </v-select>
      </template>
    </v-form>
  </div>
</template>

<script lang="ts">
import { countriesList, usaStatesList } from "@/constants/countries";
import Patterns from "@/constants/patterns";
import CoroIcon from "@/components/CoroIcon.vue";
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import { AccessPermissionType, Service } from "@/constants/cloud-apps";
import {
  type AccessPermissionItem,
  type MonitoredScopeUser,
  useAccessPermissionsStore,
} from "@/_store/cloud-apps/access-permissions.module";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import camelCase from "lodash/camelCase";
import debounce from "lodash/debounce";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { atLeastOneIsRequired, required } from "@/_helpers/validators";
import { useI18n } from "vue-i18n";
import defaultsDeep from "lodash/defaultsDeep";
import type { VuetifyFormRef } from "@/types";
import { getCountryOrStateName } from "@/_helpers/utils";

const getDefaultRestriction = (serviceName: Service, type: AccessPermissionType) => {
  return {
    serviceName,
    monitoredScope: {
      type,
      groups: [],
    },
    restrictions: {
      type: "",
      allowedCountries: [],
      allowedIps: [],
    },
    remediation: "none",
  };
};
export default defineComponent({
  components: {
    CoroIcon,
  },
  props: {
    config: {
      type: Object as PropType<
        DialogDataConfig<AccessPermissionItem & { type: AccessPermissionType }>
      >,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const accessPermissionsStore = useAccessPermissionsStore();
    const groups = ref(
      props.config.item?.monitoredScope?.groups?.length
        ? [...props.config.item.monitoredScope.groups]
        : []
    );
    const users = ref(
      props.config.item?.monitoredScope?.users?.length
        ? [...props.config.item.monitoredScope.users]
        : []
    );
    const ipRule = (values: string[]) => {
      for (const value of values) {
        if (!Patterns.IP.test(value)) {
          return i18n.t("validations.ip");
        }
      }

      return true;
    };
    const valid = ref(true);
    const form = ref<VuetifyFormRef>();
    const dataLoading = ref(false);
    const restrictBy = ref<"country" | "ip">("country");
    const groupSearchTerm = ref<string | undefined>("");
    const localValue = ref<AccessPermissionItem>(
      cloneDeep(
        defaultsDeep(
          props.config.item,
          getDefaultRestriction(props.config.item.serviceName, props.config.item.type)
        )
      )
    );
    const countriesOrStates = computed(() => {
      const availableCountries = Object.entries(countriesList).map(([key, value]) => ({
        countryKey: key,
        name: value,
      }));
      const availableStates = Object.entries(usaStatesList).map(([key, value]) => ({
        countryKey: key,
        name: value,
      }));
      return restrictBy.value === "country"
        ? uniqBy(availableCountries, "countryKey")
        : uniqBy(availableStates, "countryKey");
    });
    const remediationOptions = computed(() => {
      return props.config.item.serviceName === Service.BOX
        ? ["none", "suspend"]
        : ["none", "suspend", "signin"];
    });

    const isRestrictionByCountry = computed(() => {
      return localValue.value.restrictions.type === "country";
    });
    const isRestrictionByIP = computed(() => {
      return localValue.value.restrictions.type === "ip";
    });
    const isSpecificGroup = computed(() => {
      return localValue.value.monitoredScope.type === AccessPermissionType.SPECIFIC_GROUPS;
    });
    const isSpecificUser = computed(() => {
      return localValue.value.monitoredScope.type === AccessPermissionType.SPECIFIC_USERS;
    });

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    watch(valid, (value) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    const onUsersSearchUpdate = debounce(async function (email) {
      dataLoading.value = true;
      users.value = await accessPermissionsStore.searchProtectedUsers({
        email,
        serviceName: props.config.item.serviceName,
      });
      dataLoading.value = false;
    });

    const onGroupsSearchUpdate = debounce(async function (value) {
      dataLoading.value = true;
      const responseData = await accessPermissionsStore.searchGroups({
        searchTerm: value,
        limit: 10,
        service: props.config.item.serviceName,
      });
      groups.value = uniqBy(
        [...localValue.value.monitoredScope.groups, ...responseData],
        "groupId"
      );
      dataLoading.value = false;
    }, 500);

    const validate = async () => {
      const validationResult = await form.value?.validate();
      return validationResult?.valid;
    };

    const getChipColor = (value: string, pattern: RegExp) => {
      const isValid = pattern.test(value);
      return isValid ? "indigo-faint" : "error";
    };

    const handleChange = () => {
      localValue.value.monitoredScope.users = localValue.value.monitoredScope.users
        .filter((u: string | MonitoredScopeUser) => !!u)
        .map((user: string | MonitoredScopeUser): MonitoredScopeUser => {
          if ((user as MonitoredScopeUser).email) {
            return user as MonitoredScopeUser;
          }
          return {
            email: user as string,
            service: Service.CORONET,
          };
        });
    };

    const usersRule = (values: MonitoredScopeUser[]) => {
      if (values) {
        if (values.length === 0) return true;

        for (const value of values) {
          if (!Patterns.EMAIL.test(value.email)) {
            return i18n.t("validations.email");
          }
        }
      }
      return true;
    };

    return {
      form,
      localValue,
      valid,
      restrictBy,
      dataLoading,
      countriesOrStates,
      getCountryOrStateName,
      remediationOptions,
      isRestrictionByCountry,
      isRestrictionByIP,
      isSpecificGroup,
      isSpecificUser,
      groupSearchTerm,
      groups,
      users,
      Patterns,
      camelCase,
      handleChange,
      getChipColor,
      usersRule,
      required,
      atLeastOneIsRequired,
      ipRule,
      validate,
      onGroupsSearchUpdate,
      onUsersSearchUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
.restriction-select {
  width: 252px;
}
:deep(*) {
  .v-field .v-chip {
    --v-chip-height: 32px;
  }
  .toggle-btn {
    .v-btn__content {
      text-transform: capitalize !important;
      letter-spacing: normal !important;
      font-size: 16px !important;
      font-weight: normal !important;
    }
    &:not(.active-btn) {
      border: 1px solid rgb(var(--v-theme-indigo-faint)) !important;
    }
  }
  .service-icon {
    height: 24px;
    width: 24px;
  }
}
</style>
