import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import type { WebhookTriggerTestStatus } from "@/constants/webhooks";
import type { WebhookStatus } from "@/constants/webhooks";
import type { AxiosRequestConfig } from "axios";
import {
  denormalizeWebhook,
  getTriggerAlerts,
  getWebhookAlerts,
  normalizeWebhooks,
  normalizeWebhookTriggers,
} from "@/_store/connectors/adapters";
import type { SeverityType } from "@/constants/general";

export interface WebhookTrigger {
  triggerId: string;
  version: string;
  lastUsed: number;
  expirationDate?: number;
  label?: { type: SeverityType; text: string };
  testStatus: WebhookTriggerTestStatus | null; // enum
  lastTestAt: number | null; // timestamp
  triggerUniqueId?: string;
}

export interface WebhookHeader {
  key: string;
  value: string;
}

export interface Webhook {
  id: string;
  name: string;
  status: WebhookStatus;
  created: number; // timestamp
  expirationDate: number | null; // timestamp
  applyToAllDescendants: boolean;
  expirationLabel: string;
  showExpirationModal: boolean;
  isExpired: boolean;
  description: string;
  webhookUrl: string;
  secret: string;
  authorization: string;
  headers: WebhookHeader[];
  triggers: WebhookTrigger[];
}

export interface WebhookAlert {
  type: SeverityType;
  title: string;
  body: string;
}

interface WebhooksState {
  webhooks: Webhook[];
  triggers: WebhookTrigger[][];
  loading: boolean;
  showSkeletonLoader: boolean;
  alerts: WebhookAlert[];
  triggerAlerts: WebhookAlert[];
}

const defaultWebhooksState = {
  webhooks: [],
  triggers: [],
  loading: false,
  showSkeletonLoader: false,
  alerts: [],
  triggerAlerts: [],
};

export const useWebhooksStore = defineStore("webhooks", {
  state: (): WebhooksState => ({ ...defaultWebhooksState }),
  actions: {
    resetState() {
      this.$reset();
    },
    async getWebhooks(showSkeletonLoader: boolean = true): Promise<void> {
      if (showSkeletonLoader) {
        this.showSkeletonLoader = true;
      } else {
        this.loading = true;
      }

      try {
        const response = await axiosInstance.request({
          ...api.webhooks(),
        });

        this.webhooks = normalizeWebhooks(response.data);
        this.alerts = getWebhookAlerts(this.webhooks);
      } catch (err) {
        console.error(err);
      }

      this.showSkeletonLoader = false;
      this.loading = false;
    },
    async getTriggers(configId?: string): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.webhookTriggers(),
          params: {
            configId,
          },
        };

        const response = await axiosInstance.request(request);

        this.triggers = normalizeWebhookTriggers(response.data);
        this.triggerAlerts = getTriggerAlerts(this.triggers);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async addWebhook(data: Webhook): Promise<void> {
      try {
        this.loading = true;

        const denormalizedData = denormalizeWebhook(data);
        const request = {
          ...api.webhooks(),
          data: denormalizedData,
          method: "post",
        };

        await axiosInstance.request(request);

        await this.getWebhooks(false);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async updateWebhook(data: Webhook): Promise<void> {
      try {
        this.loading = true;
        const denormalizedData = denormalizeWebhook(data);
        const request = {
          ...api.webhooks(),
          data: denormalizedData,
          method: "put",
        };

        await axiosInstance.request(request);

        await this.getWebhooks(false);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async deleteWebhook(data: Webhook): Promise<void> {
      this.loading = true;

      try {
        const request = {
          ...api.webhook(data.id),
          method: "delete",
        };

        await axiosInstance.request(request);

        await this.getWebhooks(false);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    async testWebhook(webhook: Webhook, trigger?: WebhookTrigger): Promise<void> {
      this.loading = true;

      try {
        const request: AxiosRequestConfig = {
          ...api.testWebhook(webhook.id),
        };

        if (trigger) {
          const { triggerId, version } = trigger;

          request.params = {
            triggerId,
            version,
          };
        }

        await axiosInstance.request(request);
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
      await this.getWebhooks(false);
    },
  },
});
