import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
export const useMobileActivationStore = defineStore("mobileActivation", {
  persist: false,
  actions: {
    async activateMobile(token: string, workspaceId: string) {
      const request = {
        ...api.activateMobile(token, workspaceId),
      };
      return axiosInstance.request(request);
    },
    async resendActivationLink(token: { email: string; customerId: string }) {
      const request = {
        ...api.resendMobileActivationLink(token.customerId),
        data: token,
      };
      return axiosInstance.request(request);
    },
  },
});
