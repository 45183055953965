<template>
  <tr>
    <td></td>
    <td :colspan="colspan">
      <v-row class="my-0">
        <v-col cols="6" class="border-divider pl-0">
          <v-list>
            <global-role-details-section
              class="mb-10"
              :scope="permissions[GlobalRoleScopes.GLOBAL_SCOPE]"
              :key="GlobalRoleScopes.GLOBAL_SCOPE"
            />
            <role-details-section
              class="mb-10"
              :scope="permissions[MspRolePermissionScopes.MSP_PORTAL_SCOPE]"
              :scope-name="MspRolePermissionScopes.MSP_PORTAL_SCOPE"
              :key="MspRolePermissionScopes.MSP_PORTAL_SCOPE"
            />
            <div v-if="workspaceRolesSectionTitle" class="subtitle1 pl-3">
              {{ workspaceRolesSectionTitle }}
            </div>
            <role-details-section
              v-for="scope in workspaceRolesSectionScopes"
              class="mb-10"
              :scope="permissions[scope]"
              :scope-name="scope"
              :key="scope"
              :show-modules-disabled-by-subscription="showModulesDisabledBySubscription"
            />
          </v-list>
        </v-col>
        <v-col cols="6" class="pl-4">
          <div class="subtitle2 mt-3">
            {{ $t("accessControlPage.roles.details.adminUsers") }}
          </div>
          <div v-for="assignee in assignees?.items" :key="assignee.id" class="body2">
            {{ assignee.name ? `${assignee.name} (${assignee.email})` : assignee.email }}
          </div>
        </v-col>
      </v-row>
    </td>
    <td></td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import {
  type RoleAssignee,
  type RoleListItem,
  RolePermissionsScope,
  type RolePermissions,
  MspRolePermissionScopes,
  GlobalRoleScopes,
} from "@/_store/roles.module";
import type { Paged } from "@/types";
import RoleDetailsSection from "@/components/roles/RoleDetailsSection.vue";
import GlobalRoleDetailsSection from "@/components/roles/GlobalRoleDetailsSection.vue";

export default defineComponent({
  components: { GlobalRoleDetailsSection, RoleDetailsSection },
  props: {
    item: {
      type: Object as PropType<RoleListItem>,
      required: true,
    },
    permissions: {
      type: Object as PropType<RolePermissions>,
      required: true,
    },
    assignees: {
      type: Object as PropType<Paged<RoleAssignee>>,
    },
    workspaceRolesSectionTitle: {
      type: String,
    },
    colspan: {
      type: Number,
      default: 2,
    },
    showModulesDisabledBySubscription: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const workspaceRolesSectionScopes = [
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      RolePermissionsScope.VIEWS,
      RolePermissionsScope.PROTECTION,
      RolePermissionsScope.TICKETS,
    ];
    return {
      workspaceRolesSectionScopes,
      RolePermissionsScope,
      MspRolePermissionScopes,
      GlobalRoleScopes,
    };
  },
});
</script>

<style scoped lang="scss">
.border-divider {
  border-right: 1px solid rgb(var(--v-theme-gray-pale));
}
</style>
