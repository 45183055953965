<template>
  <div class="content-sm margin-auto" data-testid="sign-up-page-container">
    <template v-if="!account.token">
      <div class="mb-3 mt-2">
        <v-breadcrumbs :items="breadcrumbsItems" divider="" />
      </div>
      <div class="headline4 d-flex justify-center mt-16">
        {{ $t("signUpPage.title") }}
      </div>
      <div class="mt-6 mb-16">
        <v-form ref="onboardingForm" v-model="isTermsOfUseFormValid" lazy-validation>
          <div class="d-flex align-center justify-center">
            <v-checkbox
              v-model="isTermsOfUseChecked"
              data-testid="invited-page-privacy-policy-checkbox"
              :ripple="false"
              :rules="required"
            >
              <template #label>
                <div
                  class="caption text-center align-center"
                  :class="{ 'text-red-dark': isTermsOfUseFormValid === false }"
                  @click.stop
                >
                  <span>{{ $t("signUpPage.termsOfUseDescription") }} </span>
                  <a
                    class="signup-page__legal-link ml-1"
                    :class="{ 'text-red-dark': isTermsOfUseFormValid === false }"
                    target="_blank"
                    data-testid="signup-page-terms-of-use"
                    :href="coroMarketingLinks.TERMS_OF_USE"
                    >{{ $t("invitedPage.termsOfUseLink") }}</a
                  >
                  <span class="ml-1 mr-1">{{ $t("general.and") }}</span>
                  <a
                    class="signup-page__legal-link"
                    :class="{ 'text-red-dark': isTermsOfUseFormValid === false }"
                    target="_blank"
                    data-testid="signup-page-privacy-policy"
                    :href="coroMarketingLinks.PRIVACY_POLICY"
                    >{{ $t("invitedPage.privacyPolicyLink") }}</a
                  >
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-form>

        <div class="row d-flex justify-center">
          <div
            v-if="showError"
            class="signup-page__alert error-block d-flex mb-3 mt-6 align-center w-100"
          >
            <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
            <span class="body2 text-red-dark">
              <template v-if="isTermsOfUseFormValid === false">{{
                $t("signUpPage.errors.privacyPolicy")
              }}</template>
              <template v-else-if="!isAdmin">{{ $t("signUpPage.errors.notAdmin") }}</template>
              <template v-else-if="accountAlreadyExist">{{
                $t("signUpPage.errors.accountAlreadyCreated")
              }}</template>
              <template v-else-if="isNewOfficeAccount">{{
                $t("signUpPage.errors.newOfficeAccountAlertText")
              }}</template>
            </span>
          </div>
        </div>

        <div class="row d-flex justify-center mt-2">
          <div class="signup-page__service">
            <v-card class="signup-page__service-container">
              <coro-icon
                class="signup-page__service-icon"
                icon-name="office365Enforcement"
              ></coro-icon>
              <span class="signup-page__service--text">{{ $t("signUpPage.signUpOffice") }}</span>
              <v-btn
                color="primary"
                data-testid="office365-signup-btn"
                size="large"
                rounded
                @click="onSignupClick(cloudApplications.OFFICE_365)"
              >
                {{ $t("signUpPage.signUpBtn") }}
              </v-btn>
            </v-card>
            <div data-testid="office-error" class="caption text-red-dark mt-2">
              <template v-if="!isAdmin && isOffice">
                {{ $t("signUpPage.errors.accountNotAssociatedOffice") }}
              </template>
              <span
                v-if="accountAlreadyExist && isOffice"
                v-html="$t('signUpPage.errors.contactAdmin', { admin: adminsToContact })"
              >
              </span>
              <template v-if="isNewOfficeAccount && isOffice">
                {{ $t("signUpPage.errors.newOfficeAccountErrorText") }}
              </template>
            </div>
          </div>
          <div class="signup-page__service">
            <v-card class="signup-page__service-container">
              <coro-icon
                class="signup-page__service-icon"
                icon-name="googleDirectoryEnforcement"
              ></coro-icon>
              <span class="signup-page__service--text">{{ $t("signUpPage.signUpGoogle") }}</span>
              <v-btn
                data-testid="google-signup-btn"
                color="primary"
                size="large"
                rounded
                @click="onSignupClick(cloudApplications.G_SUITE)"
              >
                {{ $t("signUpPage.signUpBtn") }}
              </v-btn>
            </v-card>
            <div data-testid="google-error" class="caption text-red-dark mt-2">
              <template v-if="!isAdmin && isGoogle">
                {{ $t("signUpPage.errors.accountNotAssociatedGoogle") }}
              </template>
              <span
                v-if="accountAlreadyExist && isGoogle"
                v-html="$t('signUpPage.errors.contactAdmin', { admin: adminsToContact })"
              >
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="headline4 d-flex justify-center mt-16">
        {{ $t("signUpPage.welcomeToCoronet") }}
      </div>
      <div class="mt-6 mb-16 d-flex flex-column align-center">
        <div class="body1" data-testid="signup-step2-subtitle">
          <template v-if="isGoogle">{{ $t("signUpPage.googleNextStep") }}</template>
          <template v-else>{{ $t("signUpPage.office365NextStep") }}</template>
        </div>
        <v-btn
          color="primary"
          class="mt-8"
          size="large"
          rounded
          data-testid="signup-connect-service-btn"
          @click="
            openConnectServiceModal(
              isGoogle ? cloudApplications.G_SUITE : cloudApplications.OFFICE_365
            )
          "
        >
          {{ $t("signUpPage.finishSetupBtn") }}
        </v-btn>
      </div>
    </template>
    <div class="mt-16 d-flex justify-center">
      <div class="headline5">{{ $t("signUpPage.description") }}</div>
    </div>
    <coro-features class="mt-8 mb-5"></coro-features>
  </div>
</template>

<script lang="ts">
import CoroFeatures from "@/components/CoroFeatures.vue";
import pick from "lodash/pick";
import CoroIcon from "@/components/CoroIcon.vue";
import isEmpty from "lodash/isEmpty";

import { computed, defineComponent, onMounted, type Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { CoroMarketingLink } from "@/constants/general";
import { useAccountStore } from "@/_store";
import { storeToRefs } from "pinia";
import { GSuiteErrorCode, OfficeErrorCode, Service, ServiceAction } from "@/constants/cloud-apps";
import { type RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useGtm } from "@gtm-support/vue-gtm";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import ConnectServiceModal from "@/views/settings/cloud-apps/modals/ConnectServiceModal.vue";
import NewOfficeAccountErrorModal from "@/components/modals/NewOfficeAccountErrorModal.vue";

export default defineComponent({
  components: {
    CoroFeatures,
    CoroIcon,
  },
  setup() {
    const gtm = useGtm();
    const i18n = useI18n();
    const router = useRouter();
    const route = useRoute();
    const { openDialog } = useDialogsStore();
    const accountStore = useAccountStore();
    const { signUp, setUtmParams, setService } = accountStore;
    const isAdmin = ref(true);
    const coronetIcons = ref([]);
    const accountAlreadyExist = ref(false);
    const isNewOfficeAccount = ref(false);
    const isTermsOfUseChecked = ref(false);
    const isTermsOfUseFormValid = ref(true);
    const adminsToContact = ref("");
    const onboardingForm = ref();
    const required = ref([(v: boolean) => (!v ? i18n.t("validations.required") : true)]);
    const breadcrumbsItems = ref([
      {
        title: `‹ ${i18n.t("general.back")}`,
        disabled: false,
        to: { name: RouteName.LOGIN },
      },
    ]);
    const { account } = storeToRefs(accountStore);
    const isGoogle = computed(() => account.value.service === Service.G_SUITE);
    const isOffice = computed(() => account.value.service === Service.OFFICE_365);

    const showError = computed(() => {
      return (
        !isAdmin.value ||
        accountAlreadyExist.value ||
        isNewOfficeAccount.value ||
        isTermsOfUseFormValid.value === false
      );
    });

    const utmUrlParams = computed(() =>
      pick(route.query, "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content")
    );

    watch(
      () => router.currentRoute,
      (route: Ref<RouteLocationNormalized>) => {
        const { isAdmin: admin, admins, errors } = route.value.query as Record<string, string>;
        isAdmin.value = admin ? admin === "true" : true;
        accountAlreadyExist.value = errors === OfficeErrorCode.ACCOUNT_ALREADY_EXIST;
        isNewOfficeAccount.value = errors === OfficeErrorCode.IS_NEW_ACCOUNT;
        adminsToContact.value = admins ? admins : i18n.t("general.admin");
      },
      {
        immediate: true,
      }
    );

    onMounted(() => {
      if (gtm?.enabled()) {
        window.dataLayer?.push({ event: "Sign-up Pageview" });
      }

      const {
        isAdmin: isUserAnAdmin,
        errors,
        admins,
        isNextStep,
        service,
      } = route.query as Record<string, string>;

      isAdmin.value = isUserAnAdmin ? isUserAnAdmin === "true" : true;
      accountAlreadyExist.value = errors === OfficeErrorCode.ACCOUNT_ALREADY_EXIST;
      isNewOfficeAccount.value = errors === OfficeErrorCode.IS_NEW_ACCOUNT;
      adminsToContact.value = admins ? admins : i18n.t("general.admin");

      const isError = !isAdmin.value || accountAlreadyExist.value;

      if (!isEmpty(utmUrlParams.value)) {
        setUtmParams(utmUrlParams.value);
      }

      if (isNextStep === "true") {
        openConnectServiceModal(service as Service);
      }

      if (service) {
        setService(service as Service);
      }

      if (account.value.token && isError) {
        openModalWithError(service as Service);
      }

      if (account.value.token && isNewOfficeAccount.value) {
        const config = componentDialogsConfigConstructor({
          width: ModalWidth.LARGE,
          action: ServiceAction.CONNECT,
          item: {
            name: Service.OFFICE_365,
            serviceStatus: "active",
          },
          component: NewOfficeAccountErrorModal,
          disable: false,
          callback: () => {},
          hideFooter: true,
        });

        openDialog(config);
      }
    });

    const onSignupClick = async (service: Service) => {
      if (gtm?.enabled()) {
        const serviceTranslation = i18n.t(`services.${service}`);
        window.dataLayer?.push({ event: `Click - ${serviceTranslation}` });
      }

      if (!isTermsOfUseChecked.value) {
        await onboardingForm.value.validate();
        return;
      }

      await signUp(service);
    };

    const openConnectServiceModal = (service: Service) => {
      const item = {
        name: service,
        serviceStatus: "active",
        signup: true,
      };

      const config = componentDialogsConfigConstructor({
        width: ModalWidth.LARGE,
        action: ServiceAction.CONNECT,
        item,
        component: ConnectServiceModal,
        disable: false,
        callback: () => {},
        hideFooter: true,
      });

      openDialog(config);
    };

    const openModalWithError = (service: Service) => {
      const officeErrorCode = !isAdmin.value
        ? OfficeErrorCode.IS_NOT_ADMIN
        : OfficeErrorCode.ACCOUNT_ALREADY_EXIST;

      router.replace({
        name: "sign-up",
        query: {
          service: account.value.service,
          isNextStep: "true",
          error: isGoogle.value ? GSuiteErrorCode.IS_NOT_ADMIN : officeErrorCode,
          admins: adminsToContact.value,
        },
      });

      openConnectServiceModal(service);
    };

    return {
      isAdmin,
      coronetIcons,
      accountAlreadyExist,
      isNewOfficeAccount,
      isTermsOfUseChecked,
      isTermsOfUseFormValid,
      adminsToContact,
      required,
      breadcrumbsItems,
      coroMarketingLinks: CoroMarketingLink,
      onboardingForm,
      isGoogle,
      isOffice,
      showError,
      utmUrlParams,
      onSignupClick,
      openConnectServiceModal,
      openModalWithError,
      account,
      cloudApplications: Service,
    };
  },
});
</script>

<style scoped lang="scss">
.signup-page {
  &__alert {
    max-width: 59%;
    min-width: 320px;
  }
  &__legal-link {
    color: rgb(var(--v-theme-primary));
    text-decoration: underline;
  }
  &__service {
    flex: 0 0 28%;
    max-width: 28%;
    min-width: 160px;
    margin: 8px;
    &-container {
      min-height: 220px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    &--text {
      max-width: 200px;
      margin: 10px;
    }
  }
  &__service-icon {
    width: 48px;
    height: 48px;
  }
}

.signup-page__legal-link--error {
  color: rgb(var(--v-theme-error)) !important;
}

.signup-page__legal-link {
  color: rgb(var(--v-theme-primary));
  text-decoration: underline !important;
}
</style>
