import { CoroProductNames, PsaProviders } from "@/constants/connectors";
import { i18n } from "@/plugins/i18n";
import type { MappablePsaItemsFilter } from "@/_store/connectors/psa.module";
import { PsaConnectorMappingStatus } from "@/_store/connectors/psa.module";
import type { Webhook, WebhookAlert, WebhookTrigger } from "./webhooks.module";
import moment from "moment";
import { clone, sortBy } from "lodash";
import { SeverityType } from "@/constants/general";

export enum PsaConnectionStatus {
  CONNECTED = "CONNECTED",
  CONFIG_NOT_FOUND = "CONFIG_NOT_FOUND",
  CONFIG_SUSPENDED = "CONFIG_SUSPENDED",
  DELETED = "DELETED",
}

export interface GradientConfig {
  workspaceId: string;
  partnerApiKey: string;
  activeWorkspaces: string[];
  status: PsaConnectionStatus;
}

export interface ConnectwiseConfig {
  domain: string;
  companyName: string;
  publicKey: string;
  privateKey: string;
  status: PsaConnectionStatus;
  totalWorkspaces: number;
  totalProducts: number;
  mappedWorkspaces: number;
  mappedProducts: number;
}

export interface AutotaskConfig {
  username: string;
  secret: string;
  status: PsaConnectionStatus;
  totalWorkspaces: number;
  totalProducts: number;
  mappedWorkspaces: number;
  mappedProducts: number;
}

export interface PsaApiData {
  gradient: GradientConfig;
  connectwise: ConnectwiseConfig;
  autotask: AutotaskConfig;
}

export type PsaConnectorAdapted =
  | (ConnectwiseConfig & { providerName: PsaProviders })
  | (GradientConfig & { providerName: PsaProviders })
  | (AutotaskConfig & { providerName: PsaProviders });

export interface ConnectwiseConnectionPayload {
  partnerApiKey: string;
  integrationName: string;
  companyName: string;
  domain: string;
  publicKey: string;
  privateKey: string;
  ticketingEnabled: boolean;
  billingEnabled: boolean;
}

export interface AutotaskConnectionPayload {
  username: string;
  secret: string;
  ticketingEnabled: boolean;
  billingEnabled: boolean;
}

export type PsaConnectorServiceMapping = {
  [key in CoroProductNames]: {
    id: string;
    identifier: string;
  };
};

export interface AdaptedPsaConnectorServiceMapping {
  service: CoroProductNames;
  id?: string; // product id
  identifier?: string; // product name
}

export function psaAdapter(apiData: PsaApiData): PsaConnectorAdapted[] {
  const { gradient, connectwise, autotask } = apiData;

  return [
    {
      ...gradient,
      providerName: PsaProviders.GRADIENT,
    },
    {
      ...connectwise,
      providerName: PsaProviders.CONNECTWISE,
    },
    {
      ...autotask,
      providerName: PsaProviders.AUTOTASK,
    },
  ].filter((connector) => connector.status !== PsaConnectionStatus.CONFIG_NOT_FOUND);
}

export function connectwiseToApiDataAdapter(
  formData: ConnectwiseConnectionPayload
): Partial<ConnectwiseConfig> {
  const { domain, companyName, publicKey, privateKey } = formData;

  return {
    domain,
    companyName,
    publicKey,
    privateKey,
  };
}

export function autotaskToApiDataAdapter(
  formData: AutotaskConnectionPayload
): Partial<AutotaskConfig> {
  const { username, secret } = formData;

  return {
    username,
    secret,
  };
}

export function servicesMappingAdapter(
  apiData: PsaConnectorServiceMapping,
  filter: MappablePsaItemsFilter
): AdaptedPsaConnectorServiceMapping[] {
  const servicesList = Object.keys(CoroProductNames);

  return (servicesList as CoroProductNames[])
    .map((service: CoroProductNames) => {
      const mapping = apiData[service];

      return mapping
        ? {
            service,
            ...mapping,
          }
        : {
            service,
          };
    })
    .filter((service: AdaptedPsaConnectorServiceMapping) => {
      switch (true) {
        case service.id && filter.status === PsaConnectorMappingStatus.UNMAPPED: {
          return false;
        }
        case !service.id && filter.status === PsaConnectorMappingStatus.MAPPED: {
          return false;
        }
        case filter.search &&
          !i18n.global.t(`connectors.psa.products.${service.service}`).includes(filter.search):
          {
            return false;
          }
          break;
        default:
          return true;
      }
    });
}

export function normalizeWebhookTriggers(triggers: WebhookTrigger[][]): WebhookTrigger[][] {
  return triggers.map((triggerGroup) => {
    return triggerGroup.map((trigger) => {
      let labelType: SeverityType;
      let labelText: string;

      const current = moment();
      const triggerExpirationDate = moment(trigger.expirationDate);

      const isExpired = triggerExpirationDate.isBefore(current);
      if (trigger.expirationDate && !isExpired) {
        labelType = SeverityType.WARNING;
        labelText = i18n.global.t("connectors.webhooks.triggerLabels.aboutToExpire", {
          expirationDate: triggerExpirationDate.format("MMM D, yyyy"),
        });
      } else if (isExpired) {
        labelType = SeverityType.ERROR;
        labelText = i18n.global.t("connectors.webhooks.triggerLabels.expired");
      }
      const result = clone({
        ...trigger,
        version: trigger.version.includes("v") ? trigger.version : "v" + trigger.version, // TODO remove after get available webhooks return version with 'v'
      });

      if (!labelType!) {
        const isNewest = triggerGroup.some(
          (otherTrigger) =>
            otherTrigger.triggerId === trigger.triggerId && otherTrigger.expirationDate
        );

        if (isNewest) {
          labelType = SeverityType.INFO;
          labelText = i18n.global.t("connectors.webhooks.triggerLabels.newVersion");
        }
      }

      if (labelType!) {
        result.label = {
          type: labelType,
          text: labelText!,
        };
      }

      return {
        ...result,
        triggerUniqueId: result.triggerId + result.version,
      };
    });
  });
}

export function getTriggerAlerts(triggers: WebhookTrigger[][]): WebhookAlert[] {
  let warningAlert: WebhookAlert;
  let errorAlert: WebhookAlert;

  triggers.forEach((triggerGroup) => {
    triggerGroup.forEach((trigger) => {
      if (trigger.label) {
        if (trigger.label.type === SeverityType.ERROR && !errorAlert) {
          errorAlert = {
            type: SeverityType.ERROR,
            title: i18n.global.t("connectors.webhooks.alerts.triggerExpired.title"),
            body: i18n.global.t("connectors.webhooks.alerts.triggerExpired.body"),
          };

          return;
        }

        if (trigger.label.type === SeverityType.WARNING && !warningAlert) {
          warningAlert = {
            type: SeverityType.WARNING,
            title: i18n.global.t("connectors.webhooks.alerts.triggerAboutToExpire.title"),
            body: i18n.global.t("connectors.webhooks.alerts.triggerAboutToExpire.body"),
          };
        }
      }
    });
  });

  return [errorAlert!, warningAlert!].filter((alert) => !!alert);
}

export function normalizeWebhooks(
  webhooks?: (Omit<Webhook, "headers"> & { headers: Record<string, string> })[]
): Webhook[] {
  const normalizedWebhooks =
    webhooks?.map((item) => {
      const current = moment();
      const expirationDate = moment(item.expirationDate);
      const differenceMonths = expirationDate.diff(current, "months");
      const isExpired = expirationDate.isBefore(current);
      const showExpirationModal = expirationDate.diff(current, "days") === 7;
      let expirationLabel = "";

      if (item.expirationDate && !isExpired && differenceMonths < 7) {
        expirationLabel = i18n.global.t("connectors.webhooks.expirationLabels.expiresIn", {
          n: current.to(expirationDate),
        });
      } else if (isExpired) {
        expirationLabel = i18n.global.t("connectors.webhooks.expirationLabels.expired");
      }

      return {
        ...item,
        triggers: item.triggers.map((trigger) => ({
          ...trigger,
          triggerUniqueId: trigger.triggerId + trigger.version,
        })),
        expirationLabel,
        showExpirationModal,
        isExpired,
        headers: Object.entries(item.headers || {}).map(([key, value]) => ({ key, value })),
      };
    }) || [];

  return sortBy(normalizedWebhooks, "triggerId");
}

export function getWebhookAlerts(webhooks: Webhook[]): WebhookAlert[] {
  const warningAlerts: WebhookAlert[] = [];
  const errorAlerts: WebhookAlert[] = [];

  webhooks.forEach((webhook) => {
    if (webhook.isExpired) {
      errorAlerts.push({
        type: SeverityType.ERROR,
        title: i18n.global.t("connectors.webhooks.alerts.expired.title", { name: webhook.name }),
        body: i18n.global.t("connectors.webhooks.alerts.expired.body"),
      });

      return;
    }

    if (webhook.expirationLabel) {
      warningAlerts.push({
        type: SeverityType.WARNING,
        title: i18n.global.t("connectors.webhooks.alerts.aboutToExpire.title"),
        body: i18n.global.t("connectors.webhooks.alerts.aboutToExpire.body", {
          name: webhook.name,
          expirationDate: moment(webhook.expirationDate).format("MMM D, yyyy"),
        }),
      });
    }
  });

  const result: WebhookAlert[] = [];

  if (errorAlerts.length) {
    result.push({
      type: SeverityType.ERROR,
      title: errorAlerts.reduce((acc, curr) => {
        acc += `${curr.title}<br>`;
        return acc;
      }, ""),
      body: i18n.global.t("connectors.webhooks.alerts.expired.body", errorAlerts.length),
    });
  }

  if (warningAlerts.length) {
    result.push({
      type: SeverityType.WARNING,
      title: i18n.global.t("connectors.webhooks.alerts.aboutToExpire.title", warningAlerts.length),
      body: warningAlerts.reduce((acc, curr) => {
        acc += `${curr.body}<br>`;
        return acc;
      }, ""),
    });
  }

  return result;
}

export function denormalizeWebhook(
  data: Webhook
): Omit<Webhook, "headers"> & { headers: Record<string, string> } {
  return {
    ...data,
    triggers: data.triggers.map(({ triggerUniqueId, ...trigger }) => ({
      ...trigger,
      version: trigger.version.includes("v") ? trigger.version : `v${trigger.version}`, // TODO remove after get available webhooks return version with 'v'
    })),
    headers: data.headers.reduce((acc, curr) => {
      if (curr.key) {
        acc[curr.key] = curr.value;
      }

      return acc;
    }, {} as Record<string, string>),
  };
}
