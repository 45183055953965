import { addWorkspaceHeaderIfNeeded } from "./adapters";
import { handleVirtualScrollData } from "@/_helpers/utils";
import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { AxiosError } from "axios";
import router from "@/_helpers/router";
import { RouteName } from "@/constants/routes";
import type { Finding, TicketDetails } from "@/_store/tickets/tickets.module";
import type { Paged } from "@/types";

export interface FileFinding {
  filePath: string;
  fileName: string;
  fileSize: number;
  totalFindings: number;
  findings: Finding[];
}

export interface AbnormalDataManipulationFolder {
  id: string;
  path: string;
  filesAffected: number;
}

export interface AbnormalDataManipulationFile {
  id: string;
  fileName: string;
  mimeType: string;
}

export interface EdrDetectionAffectedDevice {
  enrollmentCode: string;
  hostname: string;
  filePath: string;
  executionTime: number;
  commandLine: string;
  userName: string;
  processName: string;
}

export interface TicketStoreState {
  eventId: string;
  workspaceId: string;
  ticket: TicketDetails;
  ticketLoading: boolean;
  fileFindings: FileFinding[];
  fileFindingsLoading: boolean;
  fileFindingsTotal: number;
  folders: AbnormalDataManipulationFolder[];
  foldersLoaded: boolean;
  foldersTotal: number;
  edrAffectedDevices: EdrDetectionAffectedDevice[];
  edrAffectedDevicesLoading: boolean;
  edrAffectedDevicesTotal: number;
}

const defaultTicketState: TicketStoreState = {
  eventId: "",
  workspaceId: "",
  ticket: {} as TicketDetails,
  ticketLoading: false,
  fileFindings: [] as FileFinding[],
  fileFindingsLoading: false,
  fileFindingsTotal: 0,
  folders: [],
  foldersLoaded: false,
  foldersTotal: 0,
  edrAffectedDevices: [],
  edrAffectedDevicesLoading: false,
  edrAffectedDevicesTotal: 0,
};

export const useTicketStore = defineStore("ticket", {
  state: () => ({ ...defaultTicketState }),
  actions: {
    async getTicket() {
      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.ticket(this.eventId),
        },
        this.workspaceId
      );
      this.ticketLoading = true;
      try {
        const { data } = await axiosInstance.request(request);
        this.ticket = data;
      } catch (err) {
        if ((err as AxiosError).response?.status === 404) {
          router.push({ name: RouteName.TICKETS }).catch(() => {});
        }
        console.error(err);
      } finally {
        this.ticketLoading = false;
      }
    },
    async getFileFindings({ page = 0, virtualScroll }: { page: number; virtualScroll?: boolean }) {
      this.fileFindingsLoading = true;
      try {
        const request = addWorkspaceHeaderIfNeeded(
          {
            ...api.findings({
              eventId: this.eventId,
              page,
              pageSize: 10,
            }),
          },
          this.workspaceId
        );
        const { data } = await axiosInstance.request(request);
        this.fileFindings = virtualScroll
          ? handleVirtualScrollData(this.fileFindings, data.items, "fileName")
          : data.items;
        this.fileFindingsTotal = data.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.fileFindingsLoading = false;
      }
    },
    async getMassDeleteDownloadFolders({
      eventId,
      page = 0,
      virtualScroll,
    }: {
      eventId?: string;
      page: number;
      virtualScroll?: boolean;
    }) {
      try {
        const request = addWorkspaceHeaderIfNeeded(
          {
            ...api.abnormalDataManipulationFolders({
              eventId: eventId ?? this.eventId,
              page,
              pageSize: 10,
            }),
          },
          this.workspaceId
        );
        const { data } = await axiosInstance.request(request);
        this.folders = virtualScroll
          ? handleVirtualScrollData(this.folders, data.items, "id")
          : data.items;
        this.foldersTotal = data.total;
      } catch (error) {
        console.error(error);
      }
      this.foldersLoaded = true;
    },
    async getMassDeleteDownloadFiles({
      eventId,
      page = 0,
      folderId,
    }: {
      eventId: string;
      page: number;
      folderId: string;
    }): Promise<Paged<AbnormalDataManipulationFile>> {
      try {
        const request = addWorkspaceHeaderIfNeeded(
          {
            ...api.abnormalDataManipulationFiles({
              eventId: eventId ?? this.eventId,
              page,
              folderId,
              pageSize: 10,
            }),
          },
          this.workspaceId
        );
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (error) {
        console.error(error);
        return { items: [], total: 0 };
      }
    },
    async getEdrAffectedDevices({
      page = 0,
      virtualScroll,
    }: {
      page: number;
      virtualScroll?: boolean;
    }) {
      this.edrAffectedDevicesLoading = true;

      try {
        const request = addWorkspaceHeaderIfNeeded(
          {
            ...api.edrAffectedDevices(this.eventId, { page, pageSize: 10 }),
          },
          this.workspaceId
        );

        const { data } = await axiosInstance.request(request);
        this.edrAffectedDevices = virtualScroll
          ? handleVirtualScrollData(this.edrAffectedDevices, data.items, "enrollmentCode")
          : data.items;
        this.edrAffectedDevicesTotal = data.total;
      } catch (error) {
        console.error(error);
      }

      this.edrAffectedDevicesLoading = false;
    },
    async getTicketRecipients(eventId: string, isDeviceEvent: boolean, workspaceId?: string) {
      const params: { protectedOnly?: boolean } = {};
      if (isDeviceEvent) {
        params.protectedOnly = false;
      }
      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.getTicketRecipients(eventId),
          params,
        },
        workspaceId
      );
      try {
        const { data } = await axiosInstance.request(request);

        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  },
});
