<template>
  <div class="margin-auto">
    <template v-if="connectedServices.length > 0">
      <div data-testid="add-all-users-description">{{ $t("modals.addAllUsers.description") }}</div>
      <div class="services-chip-group" :class="{ 'mb-0': selectedService }">
        <v-chip-group v-model="selectedService" class="justify-center">
          <v-chip
            v-for="service in connectedServices"
            variant="outlined"
            :key="service.name"
            :value="service.name"
          >
            <div
              class="d-flex flex-column align-center w-100"
              :data-testid="`${service.name}-chip`"
            >
              <coro-icon class="service-icon" :icon-name="service.name"></coro-icon>
              <div class="subtitle1">{{ $t(`services.${service.name}`) }}</div>
            </div>
          </v-chip>
        </v-chip-group>
      </div>
    </template>
    <div v-else class="text-center mt-8 mb-8">
      <v-icon class="no-service-icon" data-testid="no-service-icon" icon="$cloudApps" />
      <div class="headline5 text--black mt-4" data-testid="no-service-title">
        {{ $t("modals.addAllUsers.noAppsProtected") }}
      </div>
      <div class="caption mt-3 mb-8" data-testid="no-service-description">
        {{ $t("modals.addAllUsers.noAppsProtectedDescription") }}
      </div>
      <v-btn
        color="primary"
        rounded
        size="large"
        data-testid="navigate-to-cloud-apps-btn"
        @click="navigateToCloudApps"
      >
        {{ $t("modals.addAllUsers.connectServicesBtn") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, watch } from "vue";
import { UserAction } from "@/constants/users";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import CoroIcon from "@/components/CoroIcon.vue";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  components: {
    CoroIcon,
  },
  emits: ["dismissed", "change-footer", "update:localValue"],
  setup(props, { emit }) {
    const router = useRouter();
    const i18n = useI18n();
    const servicesStore = useCloudAppsStore();
    const { connectedServices } = storeToRefs(servicesStore);
    const selectedService = ref(null);

    const actionCallback = () => {
      emit("dismissed");
      setTimeout(() => {
        props.config.item.callback(selectedService.value);
      }, 500);
    };

    const footer = {
      buttons: [
        {
          title: i18n.t("general.cancel"),
          spacer: "before",
          type: "text",
          cancel: true,
        },
        {
          title: i18n.t("modals.addAllUsers.actionBtn"),
          color: "primary",
          callback: actionCallback,
        },
      ],
    };

    const navigateToCloudApps = () => {
      router.push({ name: RouteName.CLOUD_APPS_PAGE });
      emit("dismissed");
    };

    watch(selectedService, (newVal) => {
      if (newVal) {
        emit("change-footer", footer);
      } else {
        emit("change-footer", null);
      }
      emit("update:localValue", {
        service: newVal,
        module: "users",
        action: UserAction.ADD_ALL_USERS_CONFIRMATION,
      });
    });

    return {
      connectedServices,
      selectedService,
      footer,
      navigateToCloudApps,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-slide-group__content {
    justify-content: center;
  }

  .v-chip {
    width: 160px;
    height: 160px !important;
    border-radius: 6px;
  }

  .provider-selection-chip {
    max-height: 71px;
  }

  .v-chip .v-chip__content {
    width: 100% !important;
    text-align: center;
  }

  .v-chip--selected {
    border: 2px solid rgb(var(--v-theme-primary)) !important;
    .v-chip__overlay {
      opacity: 0 !important;
    }
  }

  .deep-purple--text.text--accent-4 {
    color: transparent !important;
  }

  .v-chip:not(.v-chip--selected) {
    border: solid 1px rgb(var(--v-theme-gray-pale)) !important;
  }

  .v-chip:before {
    background-color: transparent !important;
  }

  .service-icon {
    height: 48px;
    width: 48px;
  }
}
.services-chip-group {
  margin-bottom: 94px;
  margin-top: 32px;
}
</style>
