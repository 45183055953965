<template>
  <div class="coronet-progress" :style="style"></div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 8,
    },
    color: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const style = computed(() => {
      return `width: ${props.value}%; height: ${props.height}px;`;
    });

    return {
      style,
    };
  },
});
</script>

<style scoped lang="scss">
.coronet-progress {
  border-radius: 10px;
  background: rgb(var(--v-theme-indigo-medium));
}
</style>
