<template>
  <div class="content-md margin-auto mt-4 es-report">
    <date-range-picker
      v-model="localFilters.reportTimeFrame"
      :preset-dates="presets"
      :placeholder="$t('reports.executiveSummaryReport.dateRange')"
      class="mb-4 ml-auto range-picker"
    ></date-range-picker>
    <v-card class="pa-12" v-if="loading">
      <v-skeleton-loader color="transparent" type="heading,list-item-three-line" />
      <v-row>
        <v-col v-for="item in 3" :key="item" cols="4">
          <v-skeleton-loader color="transparent" type="avatar, list-item-two-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader
        color="transparent"
        type="heading,list-item-three-line, article, article"
      />
      <v-skeleton-loader class="mx-3 rounded-lg" color="transparent" type="image" />
    </v-card>
    <v-card class="pa-12 mt-1" v-else-if="executiveSummaryReport" id="report-card">
      <executive-summary-report-general-info
        :version="reportVersion"
        :logo-path="logoPath"
        :workplace="account.customerName"
        :selected-date-range="formattedSelectedDateRange"
        :report-data="executiveSummaryReport"
      />
      <div class="html2pdf__page-break"></div>
      <template v-if="hasVulnerabilities(executiveSummaryReport.topVulnerabilities)">
        <div class="es-report__section-title mb-6">
          {{ $t("reports.executiveSummaryReport.topVulnerabilities") }}
        </div>

        <div class="es-report__top-vulnerabilities py-6 px-10 mb-14">
          <div class="w-50">
            <template
              v-for="(count, eventTrigger) in executiveSummaryReport.topVulnerabilities"
              :key="eventTrigger"
            >
              <div class="d-flex justify-space-between text-primary py-2">
                <div class="caption">
                  {{ $t(`tickets.eventTriggers.${eventTrigger}`) }}
                </div>
                <div class="caption">
                  {{ count }}
                </div>
              </div>
              <v-divider class="border-opacity-100" color="white" />
            </template>
          </div>
        </div>
        <div class="html2pdf__page-break"></div>
      </template>
      <div class="es-report__section-title mb-6">
        {{ $t("reports.executiveSummaryReport.protection") }}
      </div>
      <div v-for="(data, index) in sortedProtectionData" :key="data.moduleName" class="mb-6">
        <executive-summary-report-module-overview
          :violators="data.violators"
          :vulnerabilities="data.vulnerabilities"
          :protection-module="data.moduleName"
          :columns-layout="columnsLayout"
        />
        <div
          v-if="index !== (sortedProtectionData ?? []).length - 1"
          class="html2pdf__page-break"
        ></div>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import moment from "moment";
import ExecutiveSummaryReportGeneralInfo from "@/components/reports/ExecutiveSummaryReportGeneralInfo.vue";
import ExecutiveSummaryReportModuleOverview from "@/components/reports/ExecutiveSummaryReportModuleOverview.vue";
import { ReportType } from "@/_store/reports.module";
import { SubscriptionModule } from "@/constants/workplaces";
import DateRangePicker from "@/components/DateRangePicker.vue";
import type { TicketTrigger } from "@/constants/tickets";
import { useReportsTab } from "@/composables/useReportsTab";

export default defineComponent({
  components: {
    DateRangePicker,
    ExecutiveSummaryReportModuleOverview,
    ExecutiveSummaryReportGeneralInfo,
  },
  setup() {
    const {
      account,
      executiveSummaryReport,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
    } = useReportsTab(ReportType.EXECUTIVE_SUMMARY);

    const sortedProtectionData = computed(() => {
      const data = executiveSummaryReport?.value?.protection;
      if (!data) return;
      const sortOrder = [
        SubscriptionModule.CLOUD_SECURITY,
        SubscriptionModule.EMAIL_SECURITY,
        SubscriptionModule.ENDPOINT_SECURITY,
        SubscriptionModule.USER_DATA_GOVERNANCE,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
        SubscriptionModule.EDR,
      ];
      return Object.entries(data)
        .map(
          ([module, { violators, vulnerabilities }]): {
            moduleName: SubscriptionModule;
            violators: { [p: string]: number };
            vulnerabilities: { [key in TicketTrigger]: number };
          } => {
            const moduleName = module as SubscriptionModule;
            return {
              moduleName,
              violators,
              vulnerabilities,
            };
          }
        )
        .slice()
        .sort((a, b) => {
          return sortOrder.indexOf(a.moduleName) - sortOrder.indexOf(b.moduleName);
        });
    });

    const hasVulnerabilities = (vulnerabilities: {
      [key in TicketTrigger]: number;
    }): boolean => {
      return Object.values(vulnerabilities).some((num) => Boolean(num));
    };

    const columnsLayout = {
      totalTicketsColumns: 0,
      vulnerabilitiesColumns: 7,
      topViolatorsColumns: 4,
    };

    return {
      account,
      executiveSummaryReport,
      sortedProtectionData,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
      moment,
      hasVulnerabilities,
      columnsLayout,
    };
  },
});
</script>

<style scoped lang="scss">
.es-report {
  .range-picker {
    max-width: 280px;
  }
  &__top-vulnerabilities {
    background-color: rgb(var(--v-theme-indigo-faint)) !important;
    border-radius: 8px;
  }
  &__section-title {
    border-radius: 4px;
    background: rgb(var(--v-theme-primary));
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    padding: 8px;
  }
}

:deep(*) {
  .coro-chip--default .coro-chip__content,
  .coro-chip--default.coro-chip__content {
    color: rgb(var(--v-theme-primary)) !important;
    mix-blend-mode: initial !important;
  }
}

.v-chip {
  height: 160px !important;
  border-radius: 6px;
}

.provider-selection-chip {
  max-height: 71px;
}

.v-chip .v-chip__content {
  width: 100% !important;
  text-align: center;
}
</style>
