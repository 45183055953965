import { defineStore } from "pinia";
import type { Service } from "@/constants/cloud-apps";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { convertPayloadForBE } from "@/_store/cloud-apps/adapters";
import { useSnackbarStore } from "@/_store";
import { i18n } from "@/plugins/i18n";

export interface AccessPermissionItem {
  restrictionId: string;
  serviceName: Service;
  monitoredScope: MonitoredScope;
  restrictions: AccessPermissionItemRestrictions;
  remediation: "none" | "suspend" | "signin";
}

export type MonitoredScopeUser = { userId?: string; email: string; service?: Service };

export interface MonitoredScope {
  type: string;
  groups: Group[];
  users: MonitoredScopeUser[];
}

export interface Group {
  groupId: string;
  groupName: string;
  serviceName: Service;
  name?: string;
  service?: Service;
}

export interface AccessPermissionItemRestrictions {
  type: "country" | "ip";
  allowedCountries: string[];
  allowedIps: string[];
  description?: string;
}

export interface AccessPermissionsState {
  permissions: AccessPermissionItem[];
  loading: boolean;
}

export const useAccessPermissionsStore = defineStore("access-permissions", {
  state: (): AccessPermissionsState => ({
    permissions: [],
    loading: false,
  }),
  actions: {
    async getAccessPermissions(serviceName: Service) {
      const request = {
        ...api.restrictions(),
        params: {
          serviceName,
        },
      };
      this.loading = true;
      try {
        const { data } = await axiosInstance.request(request);
        this.permissions = data;
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async searchProtectedUsers({
      email,
      serviceName,
    }: {
      email: string;
      serviceName: Service;
    }): Promise<MonitoredScopeUser[]> {
      const request = {
        ...api.users(),
        params: {
          pageSize: 10,
          page: 0,
          email,
          cloudServices: serviceName,
        },
      };
      const { data } = await axiosInstance.request(request);
      return data.items.map((u: Partial<MonitoredScopeUser>) => {
        return {
          userId: u.userId,
          email: u.email,
          service: serviceName,
        };
      });
    },
    async addAccessPermissions(payload: AccessPermissionItem) {
      const request = {
        ...api.restrictions(),
        method: "post",
        data: convertPayloadForBE(payload),
      };
      this.loading = true;
      try {
        await axiosInstance.request(request);
        await this.getAccessPermissions(payload.serviceName);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.accessPermissions.accessPermissionsUpdated")
        );
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async editAccessPermissions(payload: AccessPermissionItem) {
      const request = {
        ...api.restrictions(),
        method: "put",
        params: {
          restrictionId: payload.restrictionId,
        },
        data: convertPayloadForBE(payload),
      };
      this.loading = true;
      try {
        await axiosInstance.request(request);
        await this.getAccessPermissions(payload.serviceName);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.accessPermissions.accessPermissionsUpdated")
        );
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async deleteAccessPermissions(payload: AccessPermissionItem) {
      const request = {
        ...api.restrictions(),
        params: {
          restrictionId: payload.restrictionId,
        },
        method: "delete",
      };
      this.loading = true;
      try {
        await axiosInstance.request(request);
        await this.getAccessPermissions(payload.serviceName);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.accessPermissions.accessPermissionsUpdated")
        );
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async searchGroups({
      limit,
      searchTerm,
      service,
    }: {
      searchTerm: string;
      limit: number;
      service: Service;
    }): Promise<Group[]> {
      const request = {
        ...api.searchGroups(searchTerm, service, limit),
      };
      const { data } = await axiosInstance.request(request);
      return data.map((v: { groupId: string; name: string; service: Service }) => {
        return {
          ...v,
          serviceName: v.service,
          groupName: v.name,
        };
      });
    },
  },
});
