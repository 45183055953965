import type { ComputedRef } from "vue";
import { defineStore } from "pinia";
import type { Pagination } from "@/types";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { downloadFile } from "@/_helpers/utils";
import { useFiltersStore } from "@/_store/filters.module";
import { DeviceAllowBlockListTypes } from "@/constants/devices";

export interface DeviceAllowBlockListItem {
  value: string;
  description?: string;
  blocked?: boolean;
  type?: ComputedRef<string>;
  valueType?: string;
}

export interface DeviceAllowBlockListResponseItem {
  id: string;
  description: string;
  inheritedFromParent: boolean;
  type: string;
  value: string;
  blocked: boolean;
  timestamp: number;
}

export interface DeviceAllowBlockListInheritanceSettings {
  files: boolean;
  edrFiles: boolean;
  processes: boolean;
}

export interface DeviceAllowBlockListState {
  items: DeviceAllowBlockListResponseItem[];
  total: number;
  showSkeletonLoader: boolean;
  pagination: Pagination;
  loading: boolean;
  inheritanceSettings: DeviceAllowBlockListInheritanceSettings;
  isEdr: boolean;
}

export const defaultDeviceAllowBlockListState = {
  items: [],
  total: 0,
  showSkeletonLoader: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  loading: false,
  inheritanceSettings: {
    files: false,
    processes: false,
    edrFiles: false,
  },
  isEdr: false,
};

interface GetAllowBlocklistPayload {
  showSkeletonLoader?: boolean;
}

export const useDeviceAllowBlockListStore = defineStore("deviceAllowBlockList", {
  state: (): DeviceAllowBlockListState => ({
    ...defaultDeviceAllowBlockListState,
  }),
  actions: {
    setPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
    resetPagination() {
      this.pagination = {
        page: 0,
        pageSize: 25,
      };
    },
    setIsEdr(isEdr: boolean) {
      this.isEdr = isEdr;
    },
    async getItems(
      { showSkeletonLoader = false }: GetAllowBlocklistPayload = {
        showSkeletonLoader: false,
      }
    ) {
      this.showSkeletonLoader = showSkeletonLoader;
      this.loading = true;
      const filtersStore = useFiltersStore();
      const filters = this.isEdr
        ? {
            ...filtersStore.filters.edrAllowBlockListFilters,
            type:
              filtersStore.filters.edrAllowBlockListFilters.type ||
              `${DeviceAllowBlockListTypes.PROCESS},${DeviceAllowBlockListTypes.EDR_FOLDER},` +
                DeviceAllowBlockListTypes.EDR_FILE,
          }
        : {
            ...filtersStore.filters.deviceAllowBlockListFilters,
            type:
              filtersStore.filters.deviceAllowBlockListFilters.type ||
              `${DeviceAllowBlockListTypes.PROCESS},${DeviceAllowBlockListTypes.FOLDER},` +
                DeviceAllowBlockListTypes.FILE,
          };
      const request = {
        ...api.getDeviceAllowBlocklist({
          ...filters,
          ...this.pagination,
        }),
      };

      try {
        const { data } = await axiosInstance.request(request);
        this.items = data.items;
        this.total = data.total;
      } catch (error) {
        console.error(error);
      }

      this.loading = false;
      this.showSkeletonLoader = false;
    },
    async addDeviceAllowBlockList(payload: DialogDataConfig<DeviceAllowBlockListItem>) {
      const { value, description, blocked, type, valueType } = payload.item;
      const data: DeviceAllowBlockListItem = {
        value,
        description,
        blocked,
        type,
      };
      if (valueType) data.valueType = valueType;

      const request = {
        ...api.addDeviceAllowBlockList(data),
      };
      try {
        await axiosInstance.request(request);
        await this.getItems();
      } catch (e) {
        console.error(e);
      }
    },
    async uploadEdrAllowBlockListCSV(payload: File) {
      const data = new FormData();
      data.append("file", payload);
      data.append("isEdrPage", this.isEdr.toString());
      const request = {
        ...api.uploadEdrAllowBlockListCSV(data),
      };
      return axiosInstance.request(request);
    },
    async downloadEdrAllowBlockListCSVTemplate() {
      const request = {
        ...api.edrAllowBlockListCSVTemplate,
      };
      try {
        const { data, headers } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: headers["content-type"] });
        const fileName = "EndpointAllowBlockListExample.csv";
        downloadFile(blob, fileName);
      } catch (error) {
        console.error(error);
      }
    },
    async removeDeviceAllowBlockListRecord(payload: { item: DeviceAllowBlockListResponseItem }) {
      const { item } = payload;
      const request = {
        ...api.deleteDeviceAllowBlocklist(item.id),
      };

      try {
        await axiosInstance.request(request);
        await this.getItems();
      } catch (error) {
        console.error(error);
      }
    },
    async getInheritanceSettings() {
      const request = {
        ...api.deviceAllowBlockListInheritanceSettings,
      };

      try {
        const { data } = await axiosInstance.request(request);

        this.inheritanceSettings = data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateInheritanceSettings(settings: DeviceAllowBlockListInheritanceSettings) {
      const request = {
        ...api.deviceAllowBlockListInheritanceSettings,
        method: "PUT",
        params: {
          isEdrPage: this.isEdr,
        },
        data: {
          ...settings,
        },
      };

      try {
        await axiosInstance.request(request);

        this.inheritanceSettings = { ...settings };
      } catch (error) {
        console.error(error);
      }
    },
  },
});
