<template>
  <div class="coro-progress" :style="style"></div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 8,
    },
    color: {
      type: String,
      required: true,
    },
    showPattern: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const style = computed(() => {
      if (!props.showPattern) {
        return `width: ${props.value}%; height: ${props.height}px; background: unset; background-color: ${props.color};`;
      }
      return `width: ${props.value}%; height: ${props.height}px;`;
    });

    return {
      style,
    };
  },
});
</script>

<style scoped>
.coro-progress {
  border-radius: 10px;
  background: repeating-linear-gradient(135deg, white, white 1px, #69d488 3px, #69d488 6px);
}
</style>
