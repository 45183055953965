import { i18n } from "@/plugins/i18n";
import { onlySpaces } from "@/_helpers/utils";
import Patterns from "@/constants/patterns";

export const atLeastOneIsRequired = (
  errorMessage: string = i18n.global.t("validations.required")
) => {
  return (values: Array<any>) => {
    if (values && values.length === 0) {
      return errorMessage;
    }
    return true;
  };
};
export const required = (errorMessage: string = i18n.global.t("validations.required")) => {
  return (value: string) => {
    if (!value || !value.length || onlySpaces(value)) return errorMessage;
    return true;
  };
};

export const emailRule = (errorMessage: string = i18n.global.t("validations.email")) => {
  return (value: string | string[]) => {
    const isEmailInvalid = (v: string) =>
      !Patterns.EMAIL.test(v) || Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(v?.trim());

    if (Array.isArray(value)) {
      if (value.some((email) => isEmailInvalid(email))) {
        return errorMessage;
      }
      return true;
    }

    if (isEmailInvalid(value as string)) {
      return errorMessage;
    }
    return true;
  };
};

export const maxCharactersRule = (
  characterCount: number = 25,
  errorMessage: string = i18n.global.t("validations.maxNCharacters", { n: characterCount })
) => {
  return (value: string) => {
    if (value.length > characterCount) {
      return errorMessage;
    }
    return true;
  };
};

export const maxItems = (
  maxItemsCount: number = 5,
  errorMessage: string = i18n.global.t("validations.maxNItems", { n: maxItemsCount })
) => {
  return (value: unknown[]) => {
    if (value && value.length > maxItemsCount) {
      return errorMessage;
    }
    return true;
  };
};

export const minNumber = (
  minNumber: number = 1,
  errorMessage: string = i18n.global.t("validations.minNumberAllowed", { n: minNumber })
) => {
  return (value: number) => {
    if (value < minNumber) {
      return errorMessage;
    }
    return true;
  };
};

export const numberRequired = (errorMessage: string = i18n.global.t("validations.required")) => {
  return (value: number) => {
    if (!value || value < 0) return errorMessage;
    return true;
  };
};

export const onlyInteger = (
  integerErrorMessage: string = i18n.global.t("validations.onlyInteger")
) => {
  return (value: number) => {
    if (!Patterns.ONLY_DIGITS.test(value.toString())) return integerErrorMessage;
    return true;
  };
};

export const maxNDecimalPoints = (
  maxNumber: number = 2,
  maxNDecimalPointsErrorMessage: string = i18n.global.t("validations.maxNDecimalPoints", {
    n: maxNumber,
  })
) => {
  return (value: number) => {
    const valueAsString = value.toString();
    const [, decimals] = valueAsString.split(".");
    if (decimals) {
      if (decimals.length > maxNumber) {
        return maxNDecimalPointsErrorMessage;
      }
    }
    return true;
  };
};

export const noSpecialCharacters = (
  errorMessage: string = i18n.global.t("validations.noSpecialCharacters")
) => {
  return (value: string | string[]) => {
    const isInvalid = (v: string) => !Patterns.NO_SPECIAL_CHARACTERS.test(v);

    if (Array.isArray(value)) {
      if (value.some((v) => isInvalid(v))) {
        return errorMessage;
      }
      return true;
    }

    if (isInvalid(value as string)) {
      return errorMessage;
    }
    return true;
  };
};
