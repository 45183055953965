<template>
  <v-menu v-if="exportActions.length > 0" offset-y location="bottom right">
    <template #activator="{ props }">
      <v-btn rounded color="primary" v-bind="props" size="large" prepend-icon="$add">
        {{ $t("reports.exports.newExport") }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="action in exportActions"
        :key="action"
        @click="handleExportAction(action)"
      >
        <v-list-item-title>
          {{ $t(`reports.exports.actions.${action}`) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ExportAction } from "@/constants/exports";
import {
  componentDialogsConfigConstructor,
  isActionRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import ExportActivityLogsModal from "@/components/modals/exports/ExportActivityLogsModal.vue";
import ExportProtectedUsersModal from "@/components/modals/exports/ExportProtectedUsersModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useExportsStore } from "@/_store/exports.module";
import ExportDevicesModal from "@/components/modals/exports/ExportDevicesModal.vue";
import ExportTicketsModal from "@/components/modals/exports/ExportTicketsModal.vue";

export default defineComponent({
  setup() {
    const dialogsStore = useDialogsStore();
    const exportsStore = useExportsStore();

    const exportActions = computed<ExportAction[]>(() => {
      const actions = [
        ExportAction.EXPORT_ACTIVITY_LOGS,
        ExportAction.EXPORT_TICKETS,
        ExportAction.EXPORT_PROTECTED_USERS,
        ExportAction.EXPORT_DEVICES,
      ];

      const ticketModules = [
        SubscriptionModule.CLOUD_SECURITY,
        SubscriptionModule.ENDPOINT_SECURITY,
        SubscriptionModule.EMAIL_SECURITY,
        SubscriptionModule.USER_DATA_GOVERNANCE,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
        SubscriptionModule.EDR,
      ] as const;

      const enabledActionsMap: Record<ExportAction, boolean> = {
        [ExportAction.EXPORT_DEVICES]: !isActionRestricted(
          RolePermissionsScope.VIEWS,
          "devicesView"
        ),
        [ExportAction.EXPORT_PROTECTED_USERS]: !isActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.USERS
        ),
        [ExportAction.EXPORT_TICKETS]: ticketModules.some(
          (module) => !isActionRestricted(RolePermissionsScope.TICKETS, module)
        ),
        [ExportAction.EXPORT_ACTIVITY_LOGS]: !isActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.ACTIVITY_LOGS
        ),
      };
      return actions.filter((action) => enabledActionsMap[action]);
    });

    const handleExportAction = (action: ExportAction) => {
      const disable = isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.REPORTS
      );
      switch (action) {
        case ExportAction.EXPORT_ACTIVITY_LOGS:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              callback: async (data) => {
                await exportsStore.exportActivityLogsToCsv(data);
                await exportsStore.getExportsList();
              },
              component: ExportActivityLogsModal,
              width: ModalWidth.LARGE,
              disable,
            }),
            disablePersistentAnimation: true,
          });
          break;
        case ExportAction.EXPORT_PROTECTED_USERS:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              callback: async (data) => {
                await exportsStore.exportProtectedUsersToCsv(data);
                await exportsStore.getExportsList();
              },
              component: ExportProtectedUsersModal,
              width: ModalWidth.LARGE,
              disable,
            }),
            disablePersistentAnimation: true,
          });
          break;
        case ExportAction.EXPORT_DEVICES:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              callback: async (data) => {
                await exportsStore.exportDevicesToCsv(data);
                await exportsStore.getExportsList();
              },
              component: ExportDevicesModal,
              width: ModalWidth.LARGE,
              disable,
            }),
            disablePersistentAnimation: true,
          });
          break;
        case ExportAction.EXPORT_TICKETS:
          dialogsStore.openDialog({
            ...componentDialogsConfigConstructor({
              action,
              callback: async (data) => {
                await exportsStore.exportTicketsToCsv(data);
                await exportsStore.getExportsList();
              },
              component: ExportTicketsModal,
              width: ModalWidth.LARGE,
              disable,
            }),
            disablePersistentAnimation: true,
          });
          break;
      }
    };

    return {
      exportActions,
      handleExportAction,
    };
  },
});
</script>
