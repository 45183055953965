<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
    >
      <mapped-items-table
        :items="connectwiseWorkspaceMapping"
        :show-pagination="true"
        :total-items="totalWorkspaceMappings"
        :pagination="pagination"
        :secondary-items="companiesSelectOptions"
        :secondary-items-loading="companiesLoading"
        :local-filters="localFilters"
        :loading="loading"
        :first-column-title="$t('workspaceMapping.coroWorkspace')"
        :second-column-title="$t(`workspaceMapping.${provider}Company`)"
        :search-placeholder="$t('workspaceMapping.searchForWorkspaces')"
        :title="$t(`connectors.psa.${provider}WorkspaceMapping.title`)"
        @clear-filters="clearFilters()"
        @mapping-update="updateMappingValue($event)"
        @page-changed="handleListTablePaginationChange($event)"
        @secondary-item-search="updateCompanySearch($event)"
      >
      </mapped-items-table>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { PropType } from "vue";
import isEqual from "lodash/isEqual";
import { storeToRefs } from "pinia";
import { debounce } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import MappedItemsTable from "@/components/tables/MappedItemsTable.vue";
import type { PsaMappingUpdate } from "@/components/tables/MappedItemsTable.vue";
import {
  defaultPsaConnectorMappingFilter,
  PsaConnectorMappingStatus,
} from "@/_store/connectors/psa.module";
import { usePsaConfigurationStore } from "@/_store/connectors/psa-configuration.module";
import type { PsaProviders } from "@/constants/connectors";

export default defineComponent({
  components: { MappedItemsTable },
  props: {
    provider: {
      type: String as PropType<PsaProviders>,
      required: true,
    },
  },
  setup(props) {
    const psaConnectorStore = usePsaConfigurationStore(props.provider);
    const localValue = ref({
      models: [] as (string | null)[],
    });
    const localFilters = ref({
      ...defaultPsaConnectorMappingFilter,
    });
    const {
      loading,
      workspaceMapping,
      companiesLoading,
      showSkeletonLoader,
      pagination,
      totalWorkspaceMappings,
    } = storeToRefs(psaConnectorStore);

    const { showPagination, totalPages, page, handleListTablePaginationChange } = usePageableTable(
      {
        totalItems: totalWorkspaceMappings,
        items: workspaceMapping,
        pagination,
      },
      async (pagination: Pagination) => {
        psaConnectorStore.setPagination(pagination);
        await psaConnectorStore.getWorkspaceMapping(localFilters.value, false);
      }
    );

    onMounted(async () => {
      await psaConnectorStore.getWorkspaceMapping(localFilters.value);
      await psaConnectorStore.getCompanies("");
    });

    const showClearFiltersButton = () => {
      return !isEqual(localFilters.value, defaultPsaConnectorMappingFilter);
    };

    watch(
      localFilters,
      debounce(async (val) => {
        psaConnectorStore.resetPagination();
        await psaConnectorStore.getWorkspaceMapping(val, false);
      }, 500),
      { immediate: false, deep: true }
    );

    const clearFilters = () => {
      psaConnectorStore.resetPagination();
      localFilters.value = cloneDeep(defaultPsaConnectorMappingFilter);
    };

    const companiesSelectOptions = computed(() => psaConnectorStore.companiesMappingOptions);

    const mappedTableData = computed(() =>
      workspaceMapping.value.map((mapping) => ({
        primaryItem: mapping.workspace.workspaceName,
        secondaryItem: mapping.company?.name,
      }))
    );

    const updateCompanySearch = debounce((value: string) => {
      psaConnectorStore.getCompanies(value);
    }, 500);

    const updateMappingValue = async ({ selectedSecondaryItem, rowIndex }: PsaMappingUpdate) => {
      const { workspace } = workspaceMapping.value[rowIndex];
      const params = selectedSecondaryItem
        ? {
            workspaceId: workspace.workspaceId,
            company: {
              id: selectedSecondaryItem.value,
              name: selectedSecondaryItem.title,
            },
          }
        : {
            workspaceId: workspace.workspaceId,
          };

      await psaConnectorStore.updateWorkspaceMapping(params);
      await psaConnectorStore.getWorkspaceMapping(localFilters.value, false);
    };

    return {
      showClearFiltersButton,
      clearFilters,
      loading,
      localValue,
      localFilters,
      filterTypes: Object.values(PsaConnectorMappingStatus),
      connectwiseWorkspaceMapping: mappedTableData,
      companiesSelectOptions,
      updateMappingValue,
      updateCompanySearch,
      companiesLoading,
      coronetSkeletonLoaderTypes,
      showSkeletonLoader,
      showPagination,
      page,
      totalPages,
      pagination,
      totalWorkspaceMappings,
      handleListTablePaginationChange,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .icon.icon-triangle {
    font-size: 8px;
  }

  .filter-menu {
    width: 125px;
  }

  .company-select {
    .v-field__input {
      padding: 10px 0 !important;
    }
  }

  .filter-menu .v-field {
    display: flex;
    align-items: center;
  }
}

.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}

// table styles

.text-align-right {
  text-align: right;
  text-align: -webkit-right;
}
</style>
