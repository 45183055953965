<template>
  <div>
    <v-form ref="form" v-model="valid" validate-on="lazy input">
      <v-text-field
        v-model.trim="localValue.name"
        data-testid="add-label-modal-name"
        outlined
        autofocus
        :rules="[required(), maxCharactersRule(25)]"
        :placeholder="$t('modals.createLabel.placeholder')"
        :label="$t('modals.createLabel.placeholder')"
      >
        <template #append-inner>
          <div class="color-preview" :style="{ 'background-color': localValue.color }"></div>
        </template>
      </v-text-field>
      <div class="subtitle1 mb-4">{{ $t("modals.createLabel.selectColor") }}</div>
      <div class="d-flex flex-wrap colors-container">
        <v-btn-toggle
          v-model="localValue.color"
          mandatory
          class="d-flex flex-wrap colors-container"
        >
          <v-btn
            v-for="(color, index) in devicesLabelsColors"
            :key="index"
            :data-testid="`add-label-modal-color-${index}`"
            selected-class="selected-color-outline"
            :ripple="false"
            :value="color"
            class="color-preview pa-0 ma-0"
            :style="{ 'background-color': `${color} !important` }"
          />
        </v-btn-toggle>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import { devicesLabelsColors } from "@/constants/devices";
import { defineComponent, type PropType, ref, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { DeviceLabel } from "@/_store/devices-settings.module";
import cloneDeep from "lodash/cloneDeep";
import { maxCharactersRule, required } from "@/_helpers/validators";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<DeviceLabel>>,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const valid = ref(true);
    const form = ref();
    const testModel = ref(devicesLabelsColors[0]);
    const localValue = ref(
      cloneDeep(props.config.item ?? { name: null, color: devicesLabelsColors[0] })
    );
    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    watch(
      localValue,
      (value: DeviceLabel) => {
        emit("update:valid", value.color && value.name);
        emit("update:localValue", value);
      },
      { deep: true, immediate: false }
    );

    return {
      testModel,
      valid,
      devicesLabelsColors,
      form,
      localValue,
      validate,
      required,
      maxCharactersRule,
    };
  },
});
</script>
<style lang="scss" scoped>
.color-preview {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 4px !important;
}

.colors-container {
  gap: 5px;
}

.selected-color-outline {
  border: solid 2px rgb(var(--v-theme-primary)) !important;
}
:deep(*) {
  .v-btn-group--density-default.v-btn-group {
    height: unset;
  }
  .v-btn-toggle > .v-btn.v-btn {
    opacity: 1 !important;
  }
  .v-input__slot {
    padding-right: 8px !important;
  }
  .v-field__append-inner {
    margin-top: 0 !important;
    padding-left: 8px !important;
    height: 56px;
    border-left: 1px solid rgb(var(--v-theme-indigo-pale)) !important;
    display: flex;
    align-items: center;
  }
}
</style>
