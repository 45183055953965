<template>
  <div class="content-md margin-auto">
    <div class="d-flex align-center justify-center w-100 mt-12">
      <div class="d-flex justify-center flex-column align-center mt-12">
        <template v-if="!error">
          <v-progress-circular
            size="160"
            color="primary"
            class="mt-12"
            indeterminate
          ></v-progress-circular>
          <div class="subtitle1 mt-3">{{ $t("oauthPage.pleaseWait") }}</div>
        </template>
        <template v-else>
          <v-icon size="100" icon="$warning" color="error" />
          <div class="subtitle1 mt-3">{{ $t("errors.generic") }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useAccountStore } from "@/_store";

export default defineComponent({
  setup() {
    const accountStore = useAccountStore();
    const error = computed(() => accountStore.account.error);
    const loginInProcess = ref(true);
    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authCode = urlParams.get("authCode") || "";
      const workspace = urlParams.get("workspace") || "";
      loginInProcess.value = true;
      await accountStore.exchangeAuthCode(authCode, workspace);
      loginInProcess.value = false;
    });

    return {
      error,
    };
  },
});
</script>

<style scoped></style>
