<template>
  <v-dialog
    v-model="showDialog"
    :width="config.fullscreen ? null : config.width"
    :no-click-animation="config.disablePersistentAnimation"
    :scrollable="config.scrollable"
    :fullscreen="config.fullscreen"
    persistent
    noClickAnimation
    @keydown.enter.prevent
  >
    <v-card>
      <v-card-title v-if="config.header" class="d-flex justify-center w-100">
        <span
          class="d-flex justify-space-between w-100"
          :class="{ 'fullwidth-header': config.fullscreen }"
        >
          <span
            :class="[
              config.header.titleClass,
              config.header.titleIcon ? 'd-flex align-center' : '',
            ]"
            class="coro-modal-title headline6 text-primary"
            data-testid="modal-title"
          >
            <v-icon
              v-if="config.header.titleIcon"
              size="32"
              class="mr-1"
              :icon="`$${config.header.titleIcon}`"
            >
            </v-icon>
            <div>{{ config.header.title }}</div>
            <div v-if="config.header.subtitle" class="body2">
              {{ config.header.subtitle }}
            </div>
          </span>
          <v-btn
            v-if="config.header.close"
            data-testid="modal-close-icon"
            icon
            width="24"
            height="24"
            class="px-0 align-self-center"
            :ripple="false"
            @click="dismiss()"
            variant="plain"
          >
            <v-icon size="24" icon="$x"></v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <!--   @TODO Refactor to array of alerts -->
      <div class="mr-9 ml-6 mb-2">
        <div v-if="config.disclaimer" class="warning-block d-flex align-center">
          <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
          <span class="body2 text--primary">{{ config.disclaimer }}</span>
        </div>
        <div v-if="showValidationAlert && !valid" class="error-block d-flex align-center">
          <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
          <span class="body2 text-red-dark">{{ $t("validations.general") }}</span>
        </div>
        <div v-else-if="showBEErrorMessage" class="error-block d-flex align-center">
          <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
          <span class="body2 text-red-dark">{{ errorMessage }}</span>
        </div>
      </div>

      <v-card-text
        v-if="config.content"
        :style="{ height: contentHeight, 'max-height': contentMaxHeight }"
        :class="{ scrollable: config.scrollable }"
        class="coronet-modal__content"
      >
        <div v-if="config.content.html" v-html="config.content.html"></div>
        <component
          :is="config.content.component"
          v-if="config.content.component"
          ref="component"
          :modelValue="localValue"
          :config="config.data"
          :disable="config.disable"
          :showValidationAlert="showValidationAlert"
          class="coronet-modal"
          @update:localValue="handleModelUpdate($event)"
          @update:valid="updateValid($event)"
          @dismissed="dismiss()"
          @hide-footer="config.footer = null"
          @hide-header="config.header.title = null"
          @change-width="onWidthChange($event)"
          @change-footer="onFooterChange($event)"
          @change-header-title="onHeaderTitleChange($event)"
          @change-content-height="onContentHeightChange($event)"
        >
        </component>
      </v-card-text>

      <v-card-actions v-if="config.footer?.buttons">
        <template v-for="(button, index) in config.footer.buttons">
          <v-spacer v-if="button.spacer === 'before'" :key="index + 'beforeSpacer'"></v-spacer>
          <v-btn
            v-if="button.cancel"
            :key="index + 'cancelBtn'"
            data-testid="modal-cancel-button"
            :color="button.color || 'primary'"
            :ripple="false"
            variant="text"
            @click="dismiss()"
          >
            {{ button.title || $t("general.cancel") }}
          </v-btn>
          <v-btn
            v-else
            :key="index + 'actionBtn'"
            data-testid="modal-action-button"
            color="primary"
            rounded
            elevation="0"
            size="large"
            variant="flat"
            :disabled="disableAction"
            :loading="loading"
            @click="actionHandler(button.callback)"
          >
            {{ button.title }}
          </v-btn>
          <v-spacer v-if="button.spacer === 'after'" :key="index + 'afterSpacer'"></v-spacer>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/** Universal modal wrapper
 * Props:
 * value {Boolean} - show/hide modal
 * config: {
 *   width {Number} - modal width
 *   disablePersistentAnimation {Boolean} - indicates if modal should NOT be bouncing on outside click when it's persistent
 *   persistOnAction {Boolean} - indicates if modal should be NOT be closed on action
 *   disable {Boolean} - disable action button
 *   scrollable {Boolean} - indicates if modal body should be scrollable. Should be used with `content.maxHeight` to work
 *   showBtnLoadingIndicator {Boolean} - Show loading indicator on the action button
 *   disclaimer {string | null} - setting permissions to show disclaimer
 *   header: { - modal header (optional)
 *     title {String} - modal title,
 *     close {Boolean} - if true show close button (optional)
 *   },
 *   content: { - only html or component property can be present at the same time
 *     height {Number} - content height
 *     maxHeight {Number} - content maximum height
 *     html {String} - html template string
 *     component {Object} - component reference
 *   },
 *   footer: { - modal footer (optional)
 *     buttons: [{
 *       title {String} - button title,
 *       spacer {"before"|"after"} - add v-spacer before or after the button (optional),
 *       type {String} - any v-button boolean property i.e. 'text', 'outlined', 'right', etc. See https://vuetifyjs.com/en/components/buttons (optional)
 *       cancel {Boolean} - if true add dismiss handler on click (optional),
 *       clear {Boolean} - if true add clear filter handler on click (optional),
 *       callback {Function} - use only if cancel or clear props are false,
 *       width {Number|String} - Sets the width for the component.
 *       link {String} - transforms button to link. Pass url as a parameter
 *     }]
 *   }
 * }
 */
import get from "lodash/get";
import { i18n } from "@/plugins/i18n";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localValue: [],
      valid: true,
      showValidationAlert: false,
      loading: false,
      showBEErrorMessage: false,
      errorMessage: "",
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    disableAction() {
      return this.config.disable || (this.config.content.component && !this.valid);
    },
    contentHeight() {
      return this.config.content.height ? `${this.config.content.height}px` : "100%";
    },
    contentMaxHeight() {
      return this.config.content.maxHeight ? `${this.config.content.maxHeight}px` : "100%";
    },
  },
  methods: {
    handleModelUpdate($event) {
      this.localValue = $event;
    },
    onWidthChange(newWidth) {
      this.config.width = newWidth;
    },
    onFooterChange(newFooter) {
      this.config.footer = newFooter;
    },
    onHeaderTitleChange(newHeaderTitle) {
      this.config.header.title = newHeaderTitle;
    },
    onContentHeightChange(contentHeight) {
      this.config.content.height = contentHeight;
    },
    updateValid(newValid) {
      if (newValid) {
        this.showValidationAlert = false;
      }
      this.valid = newValid;
    },
    clearValues() {
      this.localValue = [];
    },
    dismiss() {
      if (this.config.closeCallback) {
        this.config.closeCallback();
      }

      this.$emit("onClose", false);
      this.localValue = [];
    },
    async actionHandler(callback) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      if (this.config.content.component && this.$refs.component?.validate) {
        const validationResult = await this.$refs.component.validate();
        if (!validationResult) {
          this.showValidationAlert = true;
          this.loading = false;
          return;
        }
      }
      setTimeout(async () => {
        // Added debounce to save combobox input field value
        if (this.config.showBtnLoadingIndicator) {
          this.showBEErrorMessage = false;
          try {
            await callback(this.config.content.component ? this.localValue : this.config.data);
          } catch (err) {
            this.loading = false;
            this.showBEErrorMessage = this.config.showBEErrorMessage;
            const error = get(err, "response.data.errors[0]", "generic");
            this.errorMessage = i18n.global.t(`errors.${error}`);
            this.localValue = {
              ...this.localValue,
              errors: err.response.data.errors,
            };
            this.$nextTick(() => {
              this.$refs.component.validate();
            });

            this.loading = false;
            return;
          }
        } else {
          callback(this.config.content.component ? this.localValue : this.config.data);
        }

        this.loading = false;

        if (this.config.persistOnAction) {
          return;
        }
        this.dismiss();
      }, 200);
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog--fullscreen .v-overlay__content {
  .v-card {
    border-radius: 0 !important;
  }

  .v-card-text {
    background-color: rgb(var(--v-theme-indigo-faint)) !important;
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }
}
:deep(*) {
  p {
    margin-bottom: 0;
  }

  .v-text-field__details {
    padding: 0 !important;
    .v-messages__message {
      font-size: 14px !important;
      line-height: 14px !important;
    }
  }

  .error-block {
    background-color: #f5e5e5;
    height: 32px;
    border-radius: 4px;
  }

  .v-card {
    border-radius: 16px !important;

    .v-card-text {
      padding: 12px 24px 24px;
      font-size: 18px;
      line-height: 24px;
    }

    .v-card-title {
      line-height: 32px;
      padding: 24px 24px 12px;
    }
  }

  .v-card-text {
    overflow: hidden;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: 0.128571px !important;
    &.scrollable {
      overflow: auto;
      background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 1)),
        linear-gradient(rgba(255, 255, 255, 1), #ffffff 66%) 0 100%,
        radial-gradient(farthest-corner at 0 0, #e0e0e0, #e0e0e0),
        radial-gradient(farthest-corner at 0 0, #e0e0e0, #e0e0e0) 0 100%;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      background-size: 100% 1px, 100% 1px, 100% 1px, 100% 1px;
    }
  }

  .v-card-title {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .coro-modal-title {
      word-break: break-word;
      max-width: 85%;
      white-space: normal;
      hyphens: none;
    }
  }

  .coronet-modal {
    &__label {
      font-weight: 500;
      letter-spacing: 0.1px;
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__content {
      min-height: 120px;
    }

    &__description {
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 24px;
      margin-bottom: 20px;
    }

    &__autocomplete {
      max-width: 230px;
    }

    &__timerange {
      height: 56px;
    }

    &__textbox {
      height: 56px;
    }

    &__column {
      padding: 0;
      margin: 0 12px 0 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .v-autocomplete {
    &.error--text {
      .v-messages__wrapper {
        margin-top: 8px;
      }
    }

    &:not(.error--text) {
      .v-text-field__details {
        display: none;
      }
    }
  }

  .v-dialog a {
    text-decoration: none !important;
  }

  .v-input--checkbox > .v-input__control > .v-input__slot {
    margin-bottom: 8px;
    height: 24px;
  }

  .v-card-actions .v-btn {
    padding: 0 28px !important;
  }
}

.v-card-actions {
  padding: 24px !important;
}

.fullwidth-header {
  min-width: 650px !important;
  max-width: 1150px !important;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
