import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import {
  autotaskToApiDataAdapter,
  connectwiseToApiDataAdapter,
  psaAdapter,
} from "@/_store/connectors/adapters";
import type {
  ConnectwiseConnectionPayload,
  AutotaskConnectionPayload,
  PsaConnectorAdapted,
} from "@/_store/connectors/adapters";
import { useSnackbarStore } from "@/_store";
import { i18n } from "@/plugins/i18n";

interface PsaState {
  connectors: PsaConnectorAdapted[];
  loading: boolean;
  showSkeletonLoader: boolean;
}

const defaultPsaState = {
  connectors: [],
  loading: false,
  showSkeletonLoader: false,
};

export interface GradientConnectionPayload {
  partnerApiKey: string;
}

export enum PsaConnectorMappingStatus {
  MAPPED = "mapped",
  UNMAPPED = "unmapped",
}

export interface ActiveWorkspace {
  workspaceId: string;
  workspaceName: string;
}

export interface PsaConnectorMappableCompany {
  id: string;
  name: string;
  mapped: boolean;
}

export interface PsaConnectorMappedCompany {
  id: string;
  name: string;
}

export interface PsaConnectorWorkspaceMapping {
  workspace: ActiveWorkspace;
  company?: PsaConnectorMappedCompany;
}

export interface PsaConnectorWorkspaceMappingUpdate {
  workspaceId: string;
  company?: PsaConnectorMappedCompany;
}

export interface MappablePsaItemsFilter {
  search: string | undefined;
  status: PsaConnectorMappingStatus | undefined;
}

export const defaultPsaConnectorMappingFilter: MappablePsaItemsFilter = {
  search: undefined,
  status: undefined,
};

export interface PsaConnectorMappableProduct {
  id: string;
  identifier: string;
  mapped: boolean;
}

export interface PsaMappingSelectOption {
  title: string;
  value: string;
  isMapped: boolean;
}

export const usePsaStore = defineStore("psa", {
  state: (): PsaState => ({ ...defaultPsaState }),
  actions: {
    async getConnectors(): Promise<void> {
      this.showSkeletonLoader = true;
      this.loading = true;
      try {
        const [gradient, connectwise, autotask] = await Promise.all([
          axiosInstance.request(api.getPsaGradientConnector()),
          axiosInstance.request(api.getPsaConnectwiseConnector()),
          axiosInstance.request(api.getPsaAutotaskConnector()),
        ]);

        this.connectors = psaAdapter({
          gradient: gradient.data,
          connectwise: connectwise.data,
          autotask: autotask.data,
        });

        this.showSkeletonLoader = false;
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.showSkeletonLoader = false;
        this.loading = false;
        throw err;
      }
    },
    async addGradientConnection({ partnerApiKey }: GradientConnectionPayload): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.getPsaGradientConnector(),
          method: "POST",
          data: {
            partnerApiKey,
          },
        };

        await axiosInstance.request(request);

        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.settingsUpdated")
        );

        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async updateGradientConnection({ partnerApiKey }: GradientConnectionPayload): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.getPsaGradientConnector(),
          method: "PUT",
          data: {
            partnerApiKey,
          },
        };

        await axiosInstance.request(request);
        await this.getConnectors();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.settingsUpdated")
        );

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async addConnectwiseConnection(data: ConnectwiseConnectionPayload): Promise<void> {
      const apiData = connectwiseToApiDataAdapter(data);

      try {
        this.loading = true;
        const request = {
          ...api.getPsaConnectwiseConnector(),
          method: "POST",
          data: apiData,
        };

        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.settingsUpdated")
        );
        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async updateConnectwiseConnection(data: ConnectwiseConnectionPayload): Promise<void> {
      const apiData = connectwiseToApiDataAdapter(data);

      try {
        this.loading = true;
        const request = {
          ...api.getPsaConnectwiseConnector(),
          method: "PUT",
          data: apiData,
        };

        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.settingsUpdated")
        );
        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async addAutotaskConnection(data: AutotaskConnectionPayload): Promise<void> {
      const apiData = autotaskToApiDataAdapter(data);

      try {
        this.loading = true;
        const request = {
          ...api.getPsaAutotaskConnector(),
          method: "POST",
          data: apiData,
        };

        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.settingsUpdated")
        );
        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async updateAutotaskConnection(data: AutotaskConnectionPayload): Promise<void> {
      const apiData = autotaskToApiDataAdapter(data);

      try {
        this.loading = true;
        const request = {
          ...api.getPsaAutotaskConnector(),
          method: "PUT",
          data: apiData,
        };

        await axiosInstance.request(request);
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.general.settingsUpdated")
        );
        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async deleteConnectwise(): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.getPsaConnectwiseConnector(),
          method: "delete",
        };

        await axiosInstance.request(request);
        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async deleteGradient(): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.getPsaGradientConnector(),
          method: "delete",
        };

        await axiosInstance.request(request);
        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
    async deleteAutotask(): Promise<void> {
      try {
        this.loading = true;
        const request = {
          ...api.getPsaAutotaskConnector(),
          method: "delete",
        };

        await axiosInstance.request(request);
        await this.getConnectors();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
        throw err;
      }
    },
  },
});
