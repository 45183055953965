<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.HIGHLIGHTABLE_TABLE"
  >
    <table-wrapper v-if="permissions.length">
      <v-data-table
        disable-sort
        :items="permissions"
        :items-per-page="-1"
        :group-by="[{ key: 'position', order: 'asc' }]"
        :group-desc="false"
      >
        <template v-slot:headers>
          <tr class="table-header">
            <th v-for="header in headers" :key="header.title" :class="header.class || ''">
              {{ header.title }}
            </th>
          </tr>
        </template>
        <template v-slot:group-header="{ item, toggleGroup, isGroupOpen }">
          <tr>
            <td
              class="group-header"
              colspan="2"
              :data-testid="`data-governance-${item.items[0].raw.category}-header-row`"
            >
              <v-btn
                :icon="true"
                variant="plain"
                :ripple="false"
                class="toggle-expander"
                @click="toggleGroup(item)"
              >
                <v-icon :class="{ rotated: isGroupOpen(item) }" icon="$chevronDown"> </v-icon>
              </v-btn>
              <span class="subtitle1">
                {{ $t(`userDataGovernanceSettings.permissionsTab.${item.items[0].raw.category}`) }}
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr :data-testid="`data-governance-${item.category}-${getTestIdName(item)}-row`">
            <td class="pl-10 pt-2 pb-2">
              <div class="d-flex align-center">
                <coro-icon
                  v-if="showIcon(item)"
                  class="service-icon mr-4"
                  :icon-name="getIconName(item)"
                ></coro-icon>
                <v-icon v-else class="service-icon mr-4" icon="$user"></v-icon>
                <span class="subtitle2">{{ getDisplayName(item.name) }}</span>
              </div>
              <email-aliases class="ml-10" :aliases="item.aliases" />
            </td>
            <td class="w-20 pr-1 float-end">
              <v-select
                v-model="item.choice"
                :disabled="actionNotAllowed()"
                :data-testid="`data-governance-${item.category}-${getTestIdName(
                  item
                )}-permission-select`"
                :items="choices"
                variant="plain"
                class="action-select"
                hide-details
                @update:modelValue="onChoiceChange($event, item)"
              >
                <template v-slot:selection="{ item: selectItem }">
                  <span class="body2">
                    {{
                      $t(
                        `userDataGovernanceSettings.permissionsTab.actions.${camelCase(
                          selectItem.title
                        )}`
                      )
                    }}
                  </span>
                </template>
                <template v-slot:item="{ props, item: selectItem }">
                  <v-list-item
                    v-bind="props"
                    :title="
                      $t(
                        `userDataGovernanceSettings.permissionsTab.actions.${camelCase(
                          selectItem.title
                        )}`
                      )
                    "
                  ></v-list-item>
                </template>
              </v-select>
            </td>
          </tr>
        </template>
      </v-data-table>
    </table-wrapper>
    <div v-else class="d-flex justify-center flex-column align-center mt-12">
      <v-icon size="104" icon="$data"></v-icon>
      <div class="headline5 mt-5 mb-5">
        {{ $t("userDataGovernanceSettings.permissionsTab.noPermissionsDescription") }}
      </div>
      <add-data-permissions-button />
    </div>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, watch } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useUserDataGovernanceModule } from "@/_store/user-data-governance/user-data-governance.module";
import { storeToRefs } from "pinia";
import TableWrapper from "@/components/TableWrapper.vue";
import { useI18n } from "vue-i18n";
import { camelCase, remove } from "lodash";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { DataLossPermissionChoice } from "@/constants/user-data-governance";
import EmailAliases from "@/components/EmailAliases.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import AddDataPermissionsButton from "@/views/settings/user-data-governance/AddDataPermissionsButton.vue";
import type { DataLossPermissionsNormalized } from "@/_store/user-data-governance/adapter";

const ALL_USERS_POLICY = "allUsersPolicy";

export default defineComponent({
  components: {
    EmailAliases,
    TableWrapper,
    CoroIcon,
    AddDataPermissionsButton,
  },
  setup() {
    const userDataGovernanceModule = useUserDataGovernanceModule();
    const i18n = useI18n();
    const { showSkeletonLoader, permissions } = storeToRefs(userDataGovernanceModule);

    const choices: DataLossPermissionChoice[] = [
      DataLossPermissionChoice.CAN_ACCESS,
      DataLossPermissionChoice.CAN_ACCESS_AND_EXPOSE,
      DataLossPermissionChoice.REMOVE,
    ];
    onMounted(async () => {
      await userDataGovernanceModule.getDataLossPermissions();
    });

    watch(permissions, () => {
      nextTick(() => {
        const buttons = document.querySelectorAll(".toggle-expander");
        const event = new MouseEvent("click");

        buttons.forEach((button) => {
          const icon = button.querySelector(".icon-chevron")!;

          if (!icon.classList.contains("rotated")) {
            button.dispatchEvent(event);
          }
        });
      });
    });

    const getTestIdName = (item: DataLossPermissionsNormalized) => {
      return item.name.replace("@", "_").replace(".", "_");
    };

    const getDisplayName = (name: string): string => {
      return name === ALL_USERS_POLICY
        ? i18n.t("userDataGovernanceSettings.permissionsTab.allUsersPolicy")
        : name;
    };
    const showIcon = (item: DataLossPermissionsNormalized): boolean => {
      return item.name === ALL_USERS_POLICY || !!item.groupId;
    };
    const getIconName = (item: DataLossPermissionsNormalized): string => {
      return item.name === ALL_USERS_POLICY ? "coronet" : item.serviceName!;
    };

    const actionNotAllowed = () => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.USER_DATA_GOVERNANCE
      );
    };

    const onChoiceChange = (
      action: DataLossPermissionChoice,
      item: DataLossPermissionsNormalized
    ) => {
      if (action === DataLossPermissionChoice.REMOVE) {
        remove(permissions.value, (permission) => permission.id === item.id);
      }

      userDataGovernanceModule.updateDataLossPermissions(permissions.value);
    };

    const headers = [
      {
        title: i18n.t("userDataGovernanceSettings.permissionsTab.usersAndGroups"),
      },
      {
        title: i18n.t("userDataGovernanceSettings.permissionsTab.permission"),
        class: "text-right",
      },
    ];

    return {
      showSkeletonLoader,
      permissions,
      getTestIdName,
      coronetSkeletonLoaderTypes,
      getDisplayName,
      showIcon,
      getIconName,
      camelCase,
      headers,
      actionNotAllowed,
      onChoiceChange,
      choices,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  width: 24px;
  height: 24px;
}

.table-header {
  height: 48px !important;
}

.group-header {
  background: rgb(var(--v-theme-indigo-pale)) !important;
  border-bottom: 0 !important;
  position: sticky;
  top: 0;
  z-index: 2;
  height: 48px !important;

  .icon-chevron:before {
    color: rgb(var(--v-theme-primary)) !important;
  }
}

:deep(.action-select) {
  width: fit-content;

  .v-field {
    align-items: center;
  }

  .v-field__input {
    min-width: 30px;
    padding: 10px 0 !important;
  }

  .v-field__append-inner {
    padding: 0 !important;
  }
}

:deep(*) {
  .v-data-table-footer {
    display: none;
  }

  td {
    border: 0 !important;
  }

  .v-select__selections {
    justify-content: flex-end;
  }

  .v-select__selections input {
    display: none !important;
  }
}
</style>
