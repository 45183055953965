<template>
  <div>
    <template v-if="confidential">
      <v-checkbox
        v-model="localValue.notifyCustomRecipients"
        :label="$t('modals.addNewComment.notifySpecificCoroAdmins')"
        class="mt-2"
        hide-details
        :ripple="false"
      />
      <v-autocomplete
        v-model="localValue.customRecipients"
        data-testid="add-comment-combobox"
        class="mt-3 ml-7 coro-scrollable-combobox coro-input-light"
        :disabled="!localValue.notifyCustomRecipients"
        :loading="dataLoading"
        :items="users"
        :label="$t('ticketDetails.recipients')"
        :placeholder="$t('ticketDetails.recipients')"
        :rules="emailRules"
        variant="outlined"
        multiple
        return-object
        clear-on-select
        hide-no-data
        @update:search="onSearchUpdate($event)"
        @keydown.enter="$event.preventDefault()"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            close-icon="$closeCircle"
            :color="getChipColor(item.value)"
            :text="item.title"
          >
            <div class="d-flex align-center">
              <span class="ml-1 mr-2">{{ item.title }}</span>
            </div>
          </v-chip>
        </template>
        <template #item="{ props, item }">
          <v-list-item
            v-bind="props"
            :data-testid="`item-${camelCase(item.title)}`"
            :title="item.title"
          >
          </v-list-item>
        </template>
      </v-autocomplete>
    </template>
    <template v-else>
      <v-checkbox
        v-if="!localValue.isDeviceTicket"
        v-model="localValue.notifyAffectedUsers"
        :label="$t('modals.addNewComment.notifyAffectedUsers')"
        class="mt-2"
        hide-details
        :ripple="false"
      />
      <v-checkbox
        v-model="localValue.notifyAllWorkspaceAdmins"
        :label="$t('modals.addNewComment.notifyAllWorkspaceAdmins')"
        class="mt-2"
        hide-details
        :ripple="false"
      />
      <v-checkbox
        v-if="localValue.socModuleEnabled"
        v-model="localValue.notifySocContacts"
        :label="$t('modals.addNewComment.notifySocContacts')"
        class="mt-2"
        hide-details
        :ripple="false"
      />
      <v-checkbox
        v-model="localValue.notifyCustomRecipients"
        :label="$t('modals.addNewComment.notifyCustomRecipients')"
        class="mt-2"
        hide-details
        :ripple="false"
      />
      <v-combobox
        v-model="localValue.customRecipients"
        :rules="emailRules"
        class="mt-3 ml-7 coro-scrollable-combobox coro-input-light"
        variant="outlined"
        multiple
        :disabled="!localValue.notifyCustomRecipients"
        :placeholder="$t('ticketDetails.recipients')"
        :label="$t('ticketDetails.recipients')"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            close-icon="$closeCircle"
            :text="item.title"
            color="indigo-faint"
          >
            <div class="d-flex align-center">
              <span class="ml-1 mr-2">{{ item.title }}</span>
            </div>
          </v-chip>
        </template>
        <template #item="{ props, item }">
          <v-list-item v-bind="props" :title="item.title"></v-list-item>
        </template>
      </v-combobox>
    </template>
  </div>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import camelCase from "lodash/camelCase";
import Patterns from "@/constants/patterns";
import isEqual from "lodash/isEqual";
import { computed, defineComponent, onMounted, type PropType, ref, toRefs, watch } from "vue";
import {
  type CommentSettings,
  defaultCommentsSettings,
  useTicketCommentsStore,
} from "@/_store/ticket-comments.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    settings: {
      type: Object as PropType<CommentSettings>,
      required: true,
    },
    confidential: {
      type: Boolean,
      required: true,
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-value"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { settings, confidential } = toRefs(props);
    const ticketCommentsStore = useTicketCommentsStore();
    const localValue = ref(cloneDeep(settings.value));
    const dataLoading = ref(false);
    const users = ref<any[]>([]);
    let timer: ReturnType<typeof setTimeout> | null = null;

    const emailRules = computed(() => [
      (emails: string[]) => {
        if (localValue.value.notifyCustomRecipients && !emails?.length) {
          return i18n.t("validations.required");
        }
        for (const email of emails) {
          if (
            !Patterns.EMAIL.test(email.trim()) ||
            Patterns.NOT_ALLOWED_DOMAIN_ZONE.test(email.trim())
          ) {
            return i18n.t("validations.email");
          }
        }
        return true;
      },
    ]);

    watch(
      localValue,
      (newVal) => {
        const newValue = cloneDeep(newVal);
        // not overriding message because message is not part of notifications
        const { message, ...value } = newValue;
        emit("update-value", value);
      },
      { deep: true }
    );

    watch(
      settings,
      (newVal) => {
        if (!isEqual(localValue.value, newVal)) {
          localValue.value = cloneDeep(newVal);
        }
      },
      { deep: true }
    );

    watch(confidential, () => {
      localValue.value = cloneDeep(defaultCommentsSettings);
    });

    onMounted(() => {
      localValue.value = cloneDeep(settings.value);
    });

    const getChipColor = (email: string) => {
      return Patterns.EMAIL.test(email) ? "indigo-faint" : "error";
    };

    const onSearchUpdate = (searchString: string) => {
      if (!searchString) return;
      if (timer) clearTimeout(timer);
      dataLoading.value = true;

      timer = setTimeout(() => {
        ticketCommentsStore.searchAdminEmails(searchString).then((response) => {
          users.value = [...localValue.value.customRecipients, ...response];
          dataLoading.value = false;
        });
      }, 500);
    };

    return {
      localValue,
      dataLoading,
      users,
      emailRules,
      getChipColor,
      onSearchUpdate,
      camelCase,
    };
  },
});
</script>

<style lang="scss" scoped></style>
