<template>
  <div>
    <div class="account-form__wrapper ma-4">
      <div v-if="!isMFACompleted" class="warning-block d-flex mb-4 mt-4 align-center w-100">
        <v-icon class="mr-3 ml-4" icon="$announcement"></v-icon>
        <span class="body2 text-orange-base">
          {{ $t("myAccount.mfa.notice") }}
        </span>
      </div>
      <div v-if="tooManyOtpResetRequests" class="error-block d-flex mb-4 mt-4 align-center w-100">
        <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
        <span class="body2 text-red-dark">
          {{ $t("validations.tooManyOtpResetRequests") }}
        </span>
      </div>
      <v-card>
        <v-form v-if="!isMFACompleted" ref="form" v-model="valid" class="pa-4" @submit.prevent>
          <div>
            <div class="subtitle1">{{ $t("myAccount.mfa.title") }}</div>
            <div
              class="body1 mb-6"
              v-html="$t('mfa.createMFADesc', { code: mfaConfig.otpSecret })"
            />
            <div class="d-flex">
              <qrcode-vue class="margin-auto" :value="mfaConfig.uri" :size="170" />
            </div>
            <v-text-field
              v-model="appVerificationCode"
              class="mt-9 mb-4"
              outlined
              :rules="verificationCodeRules"
              :placeholder="$t('mfa.enterCode')"
              @keydown.enter="verifyAuthAppCode()"
            />
            <v-btn
              class="mb-4"
              color="primary"
              size="large"
              rounded
              :disabled="!valid"
              :loading="loading"
              @click="verifyAuthAppCode()"
            >
              {{ $t("general.confirm") }}
            </v-btn>
          </div>
        </v-form>
        <div v-else-if="isMFACompleted" class="pa-4">
          <div class="subtitle1 mb-4">{{ $t("myAccount.mfa.mfaEnabled") }}</div>
          <div class="body1">{{ $t("myAccount.mfa.mfaEnabledDesc") }}</div>
          <a class="coro-link body2" @click="disableMfa()">
            {{ $t("myAccount.mfa.disableMFAButton") }}
          </a>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import Patterns from "@/constants/patterns";
import { useMfaStore } from "@/_store/mfa.module";
import { useI18n } from "vue-i18n";
import { AccountAction, AccountErrors, MfaStatus } from "@/constants/account";
import { storeToRefs } from "pinia";
import { useMyAccountStore } from "@/_store/my-account.module";
import { confirmationDialogsConfigConstructor } from "@/_helpers/utils";
import { useDialogsStore } from "@/_store/dialogs.module";
import QrcodeVue from "qrcode.vue";

export default defineComponent({
  components: { QrcodeVue },
  setup() {
    const mfaStore = useMfaStore();
    const dialogsStore = useDialogsStore();
    const myAccountStore = useMyAccountStore();
    const i18n = useI18n();
    const { mfaConfig, loading } = storeToRefs(mfaStore);
    const { completeMFA, toggleMFAConfig, getMFAFallback } = mfaStore;
    const { getProfileData } = myAccountStore;

    const currentStep = ref(1);
    const valid = ref(true);
    const appVerificationCode = ref("");
    const showTimer = ref(false);
    const countdown = ref(59);
    const isVerificationCodeIncorrect = ref(false);
    const isPhoneNumberInvalid = ref(false);
    const tooManyOtpResetRequests = ref(false);
    const profileData = computed(() => myAccountStore.myAccount.profileData);
    const isMFACompleted = computed(() => profileData.value.mfaConfig === MfaStatus.TOTP);
    const verificationCodeRules = [
      (code: string) => {
        if (!code) return i18n.t("validations.required");
        if (!Patterns.ONLY_DIGITS.test(code)) return i18n.t("validations.onlyDigits");
        if (!Patterns.DIGITS_RANGE(6, 6).test(code)) return i18n.t("validations.nDigits", { n: 6 });
        if (isVerificationCodeIncorrect.value) {
          isVerificationCodeIncorrect.value = false;
          return i18n.t("validations.invalidVerificationCode");
        }
        return true;
      },
    ];

    const abortMFASetup = () => {
      appVerificationCode.value = "";
      currentStep.value = 1;
    };

    const verifyAuthAppCode = async () => {
      if (!valid.value) return;
      try {
        await completeMFA(appVerificationCode.value);
        appVerificationCode.value = "";
        await getProfileData();
      } catch (err: any) {
        if (err.response.data.errors?.includes(AccountErrors.INVALID_VERIFICATION_CODE)) {
          isVerificationCodeIncorrect.value = true;
        }
      }
    };

    const startCountdown = () => {
      showTimer.value = true;
      const interval = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          showTimer.value = false;
          countdown.value = 59;
          clearInterval(interval);
        }
      }, 1000);
    };

    onMounted(async () => {
      if (!isMFACompleted.value) {
        await toggleMFAConfig();
      } else {
        await getMFAFallback();
      }
    });

    const disableMfa = () => {
      const item = {
        disable: true,
      };
      const dialogConfig = confirmationDialogsConfigConstructor({
        action: AccountAction.TOGGLE_MFA,
        callback: () => toggleMFAConfig(item),
        item,
        disable: false,
      });

      dialogsStore.openDialog(dialogConfig);
    };

    return {
      currentStep,
      valid,
      appVerificationCode,
      showTimer,
      countdown,
      isVerificationCodeIncorrect,
      isPhoneNumberInvalid,
      tooManyOtpResetRequests,
      mfaConfig,
      loading,
      profileData,
      isMFACompleted,
      verificationCodeRules,
      abortMFASetup,
      verifyAuthAppCode,
      startCountdown,
      disableMfa,
    };
  },
});
</script>

<style scoped></style>
