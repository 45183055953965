<template>
  <v-card
    v-if="isOnTrial || isOnFreeze"
    data-testid="trial-banner"
    class="d-flex flex-column align-center justify-center bg-primary text-center trial-banner"
  >
    <div :class="`headline6 ${getTrialAlertClass}`" data-testid="trial-banner-title">
      {{
        isOnTrial
          ? getDaysUntilTrialEnds(subscription.currentPlan.planEndDate)
          : $t("dashboard.widgets.alerts.subscriptionExpired")
      }}
    </div>
    <div
      class="caption text-white"
      data-testid="trial-banner-text"
      v-html="
        isOnTrial ? $t('dashboard.widgets.alerts.daysRemaining') : frozenSubscriptionDescription
      "
    ></div>
  </v-card>
</template>
<script lang="ts">
import { useAccountStore } from "@/_store";
import { computed, defineComponent } from "vue";
import { useSubscriptionStore } from "@/_store/subscription.module";
import { getDaysUntilTrialEnds } from "@/_helpers/utils";
import { SubscriptionType, WorkspaceType } from "@/constants/workplaces";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";

export default defineComponent({
  setup() {
    const subscriptionStore = useSubscriptionStore();
    const accountStore = useAccountStore();
    const { subscription } = storeToRefs(subscriptionStore);
    const i18n = useI18n();

    const isOnTrial = computed(() => {
      return (
        subscriptionStore.subscription.currentPlan?.subscriptionType === SubscriptionType.TRIAL
      );
    });

    const isOnFreeze = computed(() => {
      return (
        subscriptionStore.subscription.currentPlan?.subscriptionType === SubscriptionType.FREEZE
      );
    });

    const frozenSubscriptionDescription = computed(() => {
      if (accountStore.account.workspaceType === WorkspaceType.REGULAR) {
        return i18n.t("dashboard.widgets.alerts.regularSubscriptionExpiredDescription");
      }
      return i18n.t("dashboard.widgets.alerts.mspSubscriptionExpiredDescription");
    });

    const getTrialAlertClass = computed(() => {
      return isOnTrial.value ? "text-green-light" : "text-red-base";
    });

    return {
      subscription,
      isOnTrial,
      isOnFreeze,
      frozenSubscriptionDescription,
      getTrialAlertClass,
      getDaysUntilTrialEnds,
    };
  },
});
</script>

<style lang="scss" scoped>
.trial-banner {
  min-height: 65px;
  transition: all 0.4s ease-in-out;
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2) !important;
  }
}
</style>
