<template>
  <div class="logo-uploader">
    <div
      class="logo-uploader--content d-flex align-center justify-center flex-column"
      :style="{ 'background-color': bgColor }"
    >
      <v-img
        :src="imgUrl"
        width="40"
        height="40"
        max-height="40"
        max-width="40"
        class="mt-4 mb-3"
        contain
      >
      </v-img>
      <div>
        <v-file-input
          ref="fileInput"
          v-model="image"
          accept="image/png, image/svg+xml"
          style="display: none"
          @change="loadImagePreview"
        >
        </v-file-input>
        <div
          role="button"
          class="coro-link text-center mb-2"
          @click="($refs.fileInput as HTMLElement).click()"
        >
          {{ $t("logoUploader.replaceImage") }}
        </div>
        <div class="body3 text-center" :style="{ color: textColor }">
          {{ description }}
        </div>
      </div>
    </div>
    <div class="body3 mt-3 image-hint">
      {{ hint }}
    </div>
    <div v-if="!isImageValid" class="text-red-dark body3">
      <v-icon icon="$warning" />
      {{ $t("logoUploader.imageError") }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref } from "vue";
import { i18n } from "@/plugins/i18n";

export const defaultLogoUrl = {
  WHITE: "/images/logos/coro-logo-small.svg",
  PRIMARY: "/images/logos/coro-logo-small-primary.svg",
};

export default defineComponent({
  props: {
    defaultLogo: {
      type: String,
      default: defaultLogoUrl.WHITE,
    },
    brandColor: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default() {
        return i18n.global.t("logoUploader.headerIcon");
      },
    },
    hint: {
      type: String,
      default() {
        return i18n.global.t("logoUploader.imageHint");
      },
    },
    description: {
      type: String,
      default() {
        return i18n.global.t("logoUploader.description");
      },
    },
    color: {
      type: String as PropType<"primary" | "white">,
      default() {
        return "white";
      },
    },
  },
  emits: ["logo-changed"],
  setup(props, { emit }) {
    const imageUrl = ref("/images/logos/coro-logo-small.svg");
    const image = ref<File[]>([]);
    const isImageValid = computed(() => {
      if (image.value && image.value[0]) {
        // if file more than 500kb.
        if (image.value[0]?.size > 500000) {
          return false;
        }
      }
      return true;
    });
    const imgUrl = computed(() => {
      return imageUrl.value;
    });
    const bgColor = computed(() => {
      return props.brandColor ? props.brandColor : "#262260";
    });

    const textColor = computed(() => {
      switch (props.color) {
        case "white":
          return "rgb(var(--v-theme-white))";
        case "primary":
          return "rgb(var(--v-theme-primary))";
        default:
          return "rgb(var(--v-theme-white))";
      }
    });

    onMounted(() => {
      imageUrl.value = props.defaultLogo;
    });

    const loadImagePreview = () => {
      imageUrl.value = URL.createObjectURL(image.value[0]);
      emit("logo-changed", image.value[0]);
    };

    return {
      imageUrl,
      image,
      isImageValid,
      imgUrl,
      bgColor,
      textColor,
      loadImagePreview,
    };
  },
});
</script>

<style scoped lang="scss">
.image-hint {
  width: 270px;
  text-align: left;
}

.logo-uploader {
  width: 270px;
  &--content {
    height: 200px;
    width: 270px;
    border-radius: 8px;
  }
}
</style>
