<template>
  <div class="d-flex justify-center mt-16">
    <v-progress-circular v-if="loading" color="primary" indeterminate size="120" />
    <empty-state v-else>
      <template #icon>
        <v-icon :class="iconClass" size="80" class="mr-1" :icon="configuration.statusIcon"></v-icon>
      </template>
      <template #description>
        <span v-html="configuration.statusMessage"></span>
      </template>
      <template #subtitle>
        <span v-html="configuration.statusDescription"></span>
      </template>
    </empty-state>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useEmailSettingsStore } from "@/_store/email-security/email-settings.module";
import EmptyState from "@/components/EmptyState.vue";

export default defineComponent({
  components: { EmptyState },
  props: {
    allowListString: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    syncToken: {
      type: String,
      required: true,
    },
    emailAddress: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const isSuccess = ref(false);
    const loading = ref(true);
    const configuration = computed(() => {
      return isSuccess.value
        ? {
            statusIcon: "$allGood",
            statusMessage: i18n.t("pniVerification.success"),
            statusDescription: i18n.t("pniVerification.successMessage"),
          }
        : {
            statusIcon: "$redAlert",
            statusMessage: i18n.t("pniVerification.failure"),
            statusDescription: i18n.t("pniVerification.failureMessage"),
          };
    });

    const iconClass = computed(() => {
      return isSuccess.value ? "pni-verification__success-icon" : "pni-verification__failure-icon";
    });

    const allowList = computed(() => {
      if (props.allowListString && props.allowListString === "true") {
        return true;
      }
      if (props.allowListString && props.allowListString === "false") {
        return false;
      }
      return undefined;
    });

    onMounted(async () => {
      const { emailAddress, syncToken, token } = props;
      isSuccess.value = await useEmailSettingsStore().reportPhishingImpersonation({
        allowList: allowList.value,
        token,
        syncToken,
        emailAddress,
      });
      loading.value = false;
    });

    return {
      configuration,
      loading,
      isSuccess,
      coronetSkeletonLoaderTypes,
      iconClass,
      allowList,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .pni-verification__success-icon:before {
    color: rgb(var(--v-theme-green-dark));
  }
  .pni-verification__failure-icon:before {
    color: rgb(var(--v-theme-red-dark));
  }
}
</style>
