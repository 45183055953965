<template>
  <div class="content-xl">
    <users-settings-exclusions-table
      :items="exceptions"
      :total-items="totalItems"
      :pagination="pagination"
      :loading="loading"
      @page-changed="applyPaginationChange($event)"
      @item-action-applied="handleAction($event)"
    ></users-settings-exclusions-table>
  </div>
</template>

<script lang="ts">
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { defineComponent, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import type { UserSettingsListItem } from "@/_store/users-settings/users-tab.module";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";
import UsersSettingsExclusionsTable from "@/components/tables/UsersSettingsExclusionsTable.vue";
import { useUsersExceptionsSettingsTabStore } from "@/_store/users-settings/user-exceptions-tab.module";
import { ExceptionsAction } from "@/constants/users";
import AddUsersModal from "@/components/modals/AddUsersModal.vue";
import { ModalWidth } from "@/constants/modals";

export default defineComponent({
  components: {
    UsersSettingsExclusionsTable,
  },
  setup() {
    const exceptionsTabStore = useUsersExceptionsSettingsTabStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();

    const { getExceptions, applyPaginationChange, removeException, addNewException } =
      exceptionsTabStore;

    const { exceptions, pagination, totalItems, showSkeletonLoader, loading, filters } =
      storeToRefs(exceptionsTabStore);

    const actionNotAllowed = computed(() =>
      isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      )
    );

    onMounted(() => {
      getExceptions(true);
    });

    const handleAction = ({ action, item }: { action: string; item: UserSettingsListItem }) => {
      let dialogConfig = {} as DialogConfig;
      switch (action) {
        case ExceptionsAction.REMOVE_EXCEPTION:
          dialogConfig = confirmationDialogsConfigConstructor({
            item,
            action,
            disable: actionNotAllowed.value,
            callback: removeException,
          });
          break;
        case ExceptionsAction.ADD_NEW_EXCEPTION:
          dialogConfig = componentDialogsConfigConstructor({
            item: {
              description: i18n.t("modals.addNewException.description"),
            },
            action: action,
            component: AddUsersModal,
            width: ModalWidth.LARGE,
            callback: addNewException,
            disable: actionNotAllowed.value,
          });
          break;
      }

      dialogsStore.openDialog(dialogConfig);
    };

    return {
      exceptions,
      pagination,
      totalItems,
      showSkeletonLoader,
      loading,
      filters,
      applyPaginationChange,
      handleAction,
    };
  },
});
</script>
