import { SocContactsActions, SocRemediationActions } from "@/constants/soc";
import {
  SocContactClosureNotification,
  SocContactNotificationTypes,
} from "@/_store/soc/soc-contact.module";
import { SocRemediationType } from "@/_store/soc/soc-remediation.module";
import { SensitiveDataScansActions } from "@/constants/endpoint-data-governance";
import {
  TicketTrigger,
  TicketType,
  TicketAction,
  TicketActionSeverity,
  SocStatus,
  TicketsModal,
  CommentAction,
} from "@/constants/tickets";
import {
  MspWorkspaceAction,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
  SubscriptionType,
  WorkspaceAction,
  WorkspaceLocale,
  WorkspaceType,
} from "@/constants/workplaces";
import { ConnectorActions, CoroProductNames, PsaProviders } from "@/constants/connectors";
import {
  AgentDeploymentAction,
  AgentDeploymentChannel,
  DeviceAction,
  DeviceAllowBlockListTypes,
  DeviceAllowListActions,
  DevicePostureAction,
  DriveEncryptionStatus,
  LabelAction,
} from "@/constants/devices";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  MspRolePermissionScopes,
  ProtectionScopeModules,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import {
  GlobalRolesAction,
  MspRolesAction,
  RolePermissionAccessMode,
  RolesAction,
} from "@/constants/roles";
import {
  AdminUsersAction,
  GlobalAdminUsersAction,
  MspAdminUsersAction,
} from "@/constants/admin-accounts";
import {
  AccessPermissionAction,
  Service,
  ServiceStatus,
  ServiceAction,
  ThirdPartyAppsAction,
} from "@/constants/cloud-apps";
import { ActiveSessionsAction } from "@/constants/active-session";
import { EdrAction } from "@/constants/edr";
import { ExceptionsAction, UserAction, UserGroupAction } from "@/constants/users";
import { DataLossAction } from "@/constants/user-data-governance";
import { PhishingAction } from "@/constants/phishing";
import { EmailProxyAction, ProxySecurityMode } from "@/constants/email-proxy";
import { ActivityLogsType } from "@/constants/activity-logs";
import { MalwareAction } from "@/constants/malware-settings";
import { BackOfficeOverviewWidgetType, WorkspaceCodeActions } from "@/constants/back-office";
import { ExportCategory, ExportChoice, ExportFileExtension } from "@/_store/exports.module";
import { ExportAction } from "@/constants/exports";
import { ActivateMobileErrorType } from "@/views/ActivateMobilePage.vue";
import {
  WorkspaceCodeRelationType,
  WorkspaceCodeStatus,
  WorkspaceCodeType,
} from "@/_store/back-office/workspace-codes.module";
import {
  WebhookAction,
  WebhookStatus,
  WebhookTriggerType,
  WebhookTriggerTestStatus,
} from "@/constants/webhooks";
import {
  SecureWebGatewayAction,
  SiteToSiteTunnelsAction,
  VirtualOfficeAction,
} from "@/constants/network";
import {
  SiteToSiteTunnelStatus,
  SiteToSiteTunnelTestState,
} from "@/_store/network/site-to-site-tunnels.module";
import { ScheduledReportFrequency, ScheduledReportScope } from "@/_store/scheduled-reports.module";
import { ScheduledReportsAction } from "@/constants/scheduled-reports";
import { ThirdPartyAppStatus } from "@/_store/cloud-apps/third-party-apps.module";
import { BillingAction } from "@/constants/billing";
import { UserSettingsAppStatus } from "@/_store/users-settings/users-tab.module";
import { EmailSettingsAction, SecurityMode } from "@/constants/emails";
import { ReportType } from "@/_store/reports.module";
import { MessageType } from "@/constants/messages";
import { AccountAction, AccountErrors } from "@/constants/account";
import type { MasterTranslationsSchema } from "@/translations/en";
import { NotificationsLevel } from "@/_store/msp.module";

export const es: MasterTranslationsSchema = {
  validations: {
    invalidWorkspaceCode: "Por favor, utilice un código de espacio de trabajo válido",
    required: "El campo es obligatorio",
    domain: "Utilice sólo dominios válidos",
    emailOrDomain: "Por favor, utiliza sólo emails o dominios válidos",
    emailOrDomainOrIp:
      "Por favor, utilice sólo correos electrónicos/dominios o IPs/rangos de IP válidos",
    workspaceShouldMatch: "Por favor, escriba el espacio de trabajo que desea eliminar",
    onlyInteger: "Utilice sólo números enteros",
    bundleOrModuleIsRequired: "Se requiere paquete o módulo",
    onlyDigits: "El campo sólo puede contener dígitos",
    ip: "Utilice direcciones ipv4 o ipv6 válidas",
    ip4: "Utilice una dirección ipv4 válida",
    email: "Por favor, utilice sólo correos electrónicos válidos",
    password:
      "La contraseña debe tener un mínimo de 8 símbolos y contener al menos 1 mayúscula, 1 minúscula y 1 símbolo numérico",
    general: "Por favor, corrija los errores a continuación",
    matchesOldPassword: "Su contraseña no puede coincidir con la anterior",
    incorrectOldPassword: "Contraseña antigua incorrecta",
    maxNCharacters: "El campo no puede tener más de {n} caracteres",
    maxNItems: "No puede tener más de {n} elementos seleccionados",
    minNCharacters: "El campo no puede tener menos de {n} caracteres",
    json: "Por favor, cargue un archivo JSON válido",
    boxConfigurationFile: "Cargue un archivo de configuración de Box válido",
    onlyPositiveNumbers: "Sólo se permiten números mayores que 0",
    maxNumberAllowed: "Sólo se permiten números menores que {n}",
    minNumberAllowed: "Sólo se permiten números mayores que {n}",
    maxNDecimalPoints: "El campo no puede tener más de {n} puntos decimales",
    noSpecialCharacters: "No se permiten caracteres especiales",
    onlyCertainSpecialCharactersAllowed:
      "Sólo se permiten los siguientes caracteres especiales: {characters}",
    invalidSubdomain: "Subdominio no válido. Sólo se permiten letras, números y guiones",
    strengthMeter: {
      great: "Grande",
      good: "Bueno",
      soso: "Más o menos",
      veryWeak: "Muy débil",
    },
    nDigits: "El campo debe contener exactamente {n} dígitos",
    invalidVerificationCode: "Código de verificación incorrecto",
    invalidPhoneNumber: "Número de teléfono no válido",
    tooManyOtpResetRequests:
      "Demasiadas solicitudes de sms. La siguiente solicitud estará disponible en 1 minuto",
    domainZoneIsNotAllowed: "La zona de dominio no está permitida",
    noSpacesAllowed: "No se permiten espacios",
    invalidHexColor: "Color HEX no válido",
    maximumAllowedKeywords: "El número máximo de palabras clave es 200",
    script: "Por favor, cargue sólo scripts con extensiones permitidas",
    ssid: "Por favor, utilice sólo nombres de red válidos",
    url: "La URL no es válida",
    domainAlreadyExists: "El dominio ya existe",
    emailIsNotInTheListOfProxyDomains:
      "La dirección de correo electrónico no pertenece a la lista de dominios proxy",
    ipOrDomain: "Dominio o dirección IP no válidos. Introduzca valores válidos",
    ipAddress: "Utilice una dirección IP válida",
    atLeastOneFieldRequired: "Se requiere al menos un campo",
    pleaseSelectAtLeastOneOption: "Seleccione al menos una opción",
    eitherBothOrNone: "Especifique ambos valores o ninguno",
    ipRange: "Utilice un rango IP válido en notación CIDR (/XX)",
    ipExists: "El rango de IP ya existe",
    overlap: "Rango solapado",
    reservedIp:
      "Esta subred está reservada para conexiones VPN y no se puede utilizar. Elija una subred diferente para su configuración",
    fileRequired: "Se requiere archivo",
    sha256: "Introduzca un hash SHA-256 válido",
    sha256OrCdhash: "Por favor, introduzca un hash SHA-256 o CDHash válido",
    hashOrFilePath: "Por favor, introduzca un hash SHA-256/CDHash válido o una ruta de archivo",
    filePath: "Introduzca una ruta de archivo válida",
    maximumNumberOfItemsExceeded: "Se ha superado el número máximo de elementos",
    port: "Por favor, utilice un número de puerto válido",
    tcpOrUdp: "Por favor, utilice un protocolo TCP o UDP",
    missingFile: "Añada un archivo",
    fileSizeError: "Su archivo es mayor de 10 MB. Inténtelo de nuevo",
    punycodeDomain: "Debe ser un dominio válido que utilice sólo caracteres ASCII",
    expiredTriggerCannotBeSelected: "No se puede seleccionar el disparador caducado",
    accountAddedAsAdminUser:
      "Esta cuenta ya está añadida como administrador del canal en otro espacio de trabajo. ",
    typeApprove: {
      required: "Debe escribir APPROVE para confirmar esta acción",
      exactMatch: "Debe escribirse exactamente la palabra APROBAR",
    },
  },
  services: {
    [Service.BOX]: "Box",
    [Service.G_SUITE]: "Google Workspace",
    [Service.GOOGLE]: "Google",
    googleDirectoryEnforcementAlias: "G-Suite",
    [Service.DROPBOX]: "Dropbox",
    [Service.OFFICE_365]: "Microsoft 365",
    emailProxyEnforcement: "proxy de correo electrónico",
    [Service.SLACK]: "Slack",
    [Service.SALES_FORCE]: "Salesforce",
    [Service.DROPBOX]: "Dropbox",
    [Service.CORONET]: "Coro",
    hubspotEnforcement: "Hubspot",
    oktaEnforcement: "Okta",
    status: {
      [ServiceStatus.CONNECTED]: "Conectado",
      [ServiceStatus.DISCONNECTED]: "Desconectado",
      [ServiceStatus.ENABLED]: "Conectado",
      disabled: "Deshabilitado",
      [ServiceStatus.INCOMPLETE]: "Incomplete",
      [ServiceStatus.NOT_CONNECTED]: "No conectado",
    },
  },
  snackbar: {
    close: "Cerca",
    messages: {
      account: {
        mode: {
          visibility: "El modo de descubrimiento se ha activado con éxito",
          nonIntrusive: "El modo de protección se ha activado con éxito",
          enforcement: "El modo de protección se ha activado con éxito",
        },
        modeChangedByRemoteUser: "El modo del sistema fue cambiado por uno de los usuarios.",
        settingsSaved: "Configuración de la cuenta guardada.",
      },
      general: {
        actionError:
          "La acción no se realizó correctamente; consulte los registros de actividad para obtener más detalles.",
        actionSuccessful: "Acción exitosa",
        oops: "Vaya, algo salió mal",
        copiedToClipboard: "Copiado al portapapeles",
        settingsUpdated: "Configuraciones actualizadas",
        exportCsvStarted: "Exportación a CSV en curso. ",
      },
      user: {
        usersUpdated: "Usuarios actualizados",
        usersAreAdding: "Comenzamos a agregar usuarios, por favor espera...",
        NO_USERS_PROTECTED: "Ningún usuario protegido",
        usersSyncStarted: "Se inició la sincronización de usuarios desde aplicaciones en la nube",
        PROTECTED_USERS_MASS_REDUCTION: "{requestedLicenses} licencias eliminadas de los usuarios",
        LESS_THEN_8O_PERCENT_USERS_PROTECTED: "Menos del 80% de los usuarios están protegidos",
        USER_SUSPENDED_BY_ADMIN: "{userEmail} suspendido de {servicio} por {by}",
        USER_SIGNED_OUT_BY_ADMIN: "{userEmail} cerró sesión en {service} por {by}",
        USER_SUSPENDED_FROM_SERVICE: "{userEmail} suspendido de {servicio} por {por} regla",
        MASS_USER_SIGNED_OUT_OF_SERVICE: "El usuario {userEmails} cerró sesión en {servicio} | ",
      },
      users: {
        markAsProcessed: "Se han cerrado las entradas relacionadas.",
        signInToAll:
          "Se envió la solicitud para volver a iniciar sesión en todas las aplicaciones en la nube.",
        signInToService:
          "Se envió la solicitud para volver a iniciar sesión en la aplicación en la nube.",
        suspendFromAll: "Se envió la solicitud de suspensión a todas las aplicaciones en la nube.",
        contactUser: "El mensaje ha sido enviado.",
      },
      userGroups: {
        userGroupsUpdated: "Grupos de usuarios actualizados",
      },
      exceptions: {
        exceptionsUpdated: "Exclusiones actualizadas",
      },
      operator: {
        OPEN_SENSITIVE_FILES_ALLOWED_TO_ADMINS:
          "Los usuarios administradores pueden descargar archivos confidenciales mediante {by}",
        VIEWER_USER_ADDED: "Nuevo espectador {userEmail} agregado por {by}",
        ADMIN_USER_ADDED: "Nuevo administrador {userEmail} agregado por {by}",
      },
      service: {
        SERVICE_DISCONNECTED: "{servicio} está desconectado por {by}",
        SERVICE_NOT_MONITORED: "{servicio} no es supervisado por {por}",
        SERVICE_REMOVED: "{servicio} fue eliminado por {by}",
      },
      notification: {
        CONTENT_SCAN_REPORT_READY:
          "El informe de análisis de descubrimiento de contenido está listo",
      },
      event: {
        newCommentIsAdded: "El nuevo comentario se agregó correctamente.",
        allowProcess: "Se ha permitido el proceso.",
        commentUpdated: "Comentario actualizado",
        ABNORMAL_INCREASE_IN_EVENTS:
          "Aumento anormal de tickets de {ruleName} en el último período",
        MASS_FILE_COLLABORATION_BLOCKAGE: "Bloqueo de colaboración masiva de archivos. ",
        eventThreatStatusChanged: "Estado de amenaza de ticket actualizado",
        eventsUpdated: "Entradas actualizadas",
        downloadEmlFile: "Se ha realizado una solicitud de descarga para el correo electrónico.",
        markAsProcessed: "El ticket ha sido cerrado. ",
        markAsUnprocessed: "Boleto reabierto. ",
        treatFileAsSafe: "El archivo será tratado como seguro.",
        excludeFilePathAndCertificateFromScan:
          "El archivo será tratado como seguro (mediante certificado).",
        excludeFilePathAndFileHashFromScan: "El archivo será tratado como seguro (mediante hash).",
        treatFileAsMalicious: "El archivo será tratado como malicioso.",
        excludeFolderFromScan: "Carpeta excluida del análisis de malware.",
        excludeCertificateFromScan: "excluirCertificadoDeScan",
        suspendFromAll: "Se envió la solicitud de suspensión a todas las aplicaciones en la nube.",
        suspendFromService: "Se envió la solicitud de suspensión a la aplicación en la nube.",
        signInToAll:
          "Se envió la solicitud para volver a iniciar sesión en todas las aplicaciones en la nube.",
        signInToService:
          "Se envió la solicitud para volver a iniciar sesión en la aplicación en la nube.",
        encryptDrive:
          "El proceso de cifrado de la unidad comenzará en la siguiente comunicación con el dispositivo. ",
        remoteScan:
          "La exploración remota en busca de malware comenzará en la siguiente comunicación con el dispositivo. ",
        startDlpFullScan:
          "La búsqueda remota de datos confidenciales comenzará en la siguiente comunicación con el dispositivo. ",
        startDlpPartialScan:
          "La exploración remota en busca de datos confidenciales comienza en la siguiente comunicación con el dispositivo. ",
        uploadLogs: "Los registros se recopilarán en la próxima comunicación con el dispositivo.",
        collectQuarantineData:
          "La lista de elementos en cuarentena se recopilará en la próxima comunicación con el dispositivo.",
        stopRemoteScan:
          "La exploración remota en busca de malware se detendrá en la siguiente comunicación con el dispositivo. ",
        stopDlpScan:
          "La búsqueda remota de datos confidenciales se detendrá en la siguiente comunicación con el dispositivo. ",
        enableFirewall: "Cortafuegos habilitado. ",
        removeFromProtection: "Dispositivo eliminado de la protección. ",
        updateAgentToLatest:
          "El dispositivo se actualizará a la última versión estable del agente. ",
        enforceUAC: "Se volvió a habilitar la notificación de UAC. ",
        disableDevMode: "Modo desarrollador deshabilitado. ",
        removeExposingSharing: "Se ha enviado la solicitud de eliminación.",
        approveFile: "Se ha enviado la solicitud de aprobación del expediente.",
        deleteFile: "Se ha enviado la solicitud de eliminación de archivos.",
        generalApprove: "El correo electrónico ha sido aprobado.",
        specificApprove: {
          crowdblockedSender:
            "Remitente/Dominio incluido en la lista de bloqueo global por contenido sospechoso.",
          blocklistedSender:
            "Remitente/Dominio eliminado de la lista de bloqueo de contenido sospechoso.",
          missingRequiredAuthentication:
            "Remitente/Dominio eliminado de la lista de bloqueo de errores de autenticación.",
        },
        approveEmailsFromDomain: "{item} ha sido incluido en la lista permitida | ",
        discardEmailsFromDomain: "{item} ha sido bloqueado | ",
        approveEmailsFromSender: "{item} ha sido incluido en la lista permitida | ",
        discardEmailsFromSender: "{item} ha sido bloqueado | ",
        approveEmail: "Se ha permitido el correo electrónico.",
        discardEmail: "El correo electrónico ha sido eliminado.",
        contactUser: "El mensaje ha sido enviado.",
        allowNoEncryption: "El disco duro ha sido incluido en la lista permitida.",
        approveProcessGroup: "El grupo de procesos será tratado como seguro",
        discardInfectingFile: "El archivo será tratado como malicioso",
        enableTamperResistance: "Resistencia a manipulaciones habilitada. ",
        disableTamperResistance: "Resistencia a la manipulación desactivada. ",
        logForAuditReports: "El ticket ha sido registrado.",
        unLogForAuditReports: "El ticket ha sido desregistrado.",
        disableNetworkBlockMode:
          "El aislamiento de la red se desactivará en la próxima comunicación con el dispositivo | ",
        enableNetworkBlockMode:
          "El aislamiento de la red se habilitará en la próxima comunicación con el dispositivo | ",
        shutdownDevice: "El apagado se realizará en la próxima comunicación con el dispositivo | ",
        rebootDevice: "El reinicio se realizará en la próxima comunicación con el dispositivo | ",
        addLabel: "Se ha añadido la etiqueta al dispositivo | ",
        removeLabel: "Se ha eliminado la etiqueta del dispositivo | ",
        addToDataGovernancePermissions:
          "El usuario {users} ha sido autorizado a acceder a datos de {violationDirection} que contienen {trigger}.   ",
      },
      export: {
        exportCsvSuccessful: "CSV se exportó correctamente. ",
      },
      rule: {
        rulesUpdated: "Regla actualizada | ",
      },
      devices: {
        devicesUpdated: "Dispositivos actualizados",
        markAsProcessed: "Se han cerrado las entradas relacionadas.",
        downloadLogs: "Descargando registros, espere.",
        mergeSuccessful: "Los dispositivos se han fusionado correctamente.",
      },
      cloudApps: {
        disconnected: "Desconectado de {servicio}",
        connected: "{servicio} está conectado a Coro ",
      },
      accessPermissions: {
        accessPermissionsUpdated: "Permisos de acceso actualizados",
      },
      adminAccounts: {
        adminUsersUpdated: "Usuarios administradores actualizados",
        inviteSent: "1 usuario administrador invitado",
        permissionsUpdated: "Permisos de contenido actualizados",
        mfaDeleted: "Datos 2FA eliminados",
        mfaUpdated: "Configuración 2FA actualizada",
        copyInviteLink: "Enlace de invitación copiado al portapapeles",
      },
      resetPassword: {
        linkSent: "Las instrucciones para restablecer la contraseña se enviaron a {email}",
      },
      emails: {
        approveEmailsFromDomain: "{item} ha sido incluido en la lista permitida | ",
        discardEmailsFromDomain: "{item} ha sido bloqueado | ",
        approveEmailsFromSender: "{item} ha sido incluido en la lista permitida | ",
        discardEmailsFromSender: "{item} ha sido bloqueado | ",
        approveEmail: "Se ha permitido el correo electrónico.",
        discardEmail: "El correo electrónico ha sido bloqueado",
        markAsProcessed: "Se han cerrado las entradas relacionadas.",
        markAsUnprocessed: "Boleto reabierto.",
        contactUser: "El mensaje ha sido enviado.",
      },
      myAccount: {
        updated: "Perfil actualizado",
      },
      dashboard: {
        vulnerabilityMuted: "{vulnerabilidad} está silenciada",
        vulnerabilityUnmuted: "{vulnerabilidad} no está silenciada",
      },
      demoMode: {
        dataResetCompleted: "Restablecimiento de datos completado",
      },
      manageWorkspaces: {
        workspaceCreated: "Espacio de trabajo creado",
        workspaceUpdated: "Espacio de trabajo actualizado",
        workspaceDeleted: "Espacio de trabajo eliminado",
        subscriptionUpdated: "Suscripción actualizada",
        workspaceArchived: "Espacio de trabajo archivado",
        exportToCsv: "La exportación a CSV está en proceso. ",
      },
      edr: {
        blockProcess: "El proceso ha sido bloqueado | ",
        unblockProcess: "El proceso ha sido desbloqueado | ",
        enableNetworkBlockMode:
          "El aislamiento de la red se habilitará en la próxima comunicación con el dispositivo | ",
        disableNetworkBlockMode:
          "El aislamiento de la red se desactivará en la próxima comunicación con el dispositivo | ",
        shutdownDevice: "El apagado se realizará en la próxima comunicación con el dispositivo | ",
        rebootDevice: "El reinicio se realizará en la próxima comunicación con el dispositivo | ",
      },
      connectors: {
        syncCompleted: "Sincronización completada",
        apiKeyAdded: "Se han creado las credenciales API.",
        apiKeyRemoved: "Las credenciales API han sido revocadas",
      },
      emailProxy: {
        proxyDomainTestCompleted:
          "Se ha completado la verificación del proxy de correo electrónico.",
      },
      secureWebGateway: {
        importUrlGroupToAllowlist:
          "La categoría {apodo} con la entrada {cantidad} se ha agregado a la lista de permitidos. ",
        importUrlGroupToBlocklist:
          "La categoría {apodo} con la entrada {cantidad} se ha agregado a la lista de bloqueo. ",
        addUrlToAllowlist: "Se agregó {cantidad} entrada a la lista de permitidos. ",
        addUrlToBlocklist: "Se agregó {cantidad} entrada a la lista de bloqueo. ",
      },
      sideToSideTunnels: {
        syncCompleted: "Sincronización completada",
        listUpdated: "Túneles de sitio a sitio actualizados",
      },
      voFirewall: {
        listUpdated: "Lista de cortafuegos actualizada",
      },
      roles: {
        addRole: "{nombre} creado exitosamente",
        editRole: "{nombre} actualizado correctamente",
        deleteRole: "{nombre} eliminado exitosamente",
      },
      exports: {
        exportCsvStarted: "Exportación a CSV en curso. ",
      },
      socket: {
        suspendFromAll: {
          ok: "Se ha suspendido el acceso del usuario a todas las aplicaciones en la nube.",
          failed: "Error: suspender de todas las aplicaciones en la nube. ",
        },
        csvExport: {
          ok: "La exportación a CSV se realizó correctamente. ",
          failed: "La exportación a CSV falló. ",
        },
        suspendFromService: {
          ok: "Se ha suspendido el acceso del usuario a {servicio}.",
          failed: "Error: el servicio ha denegado la suspensión del usuario de {servicio}.",
        },
        signInToAll: {
          ok: "La solicitud de volver a iniciar sesión en todas las aplicaciones en la nube se envió correctamente.",
          failed: "Error: iniciar sesión en todos. ",
        },
        signInToService: {
          ok: "La solicitud para volver a iniciar sesión en la aplicación en la nube se envió correctamente.",
          failed: "Error: iniciar sesión en {servicio}'. ",
        },
        removeExposingSharing: {
          ok: "Se eliminaron todos los recursos compartidos con personas ajenas a su organización.",
          failed: "Error: eliminar el uso compartido expuesto. ",
        },
        approveFile: {
          ok: "El archivo {fileName} ha sido restaurado.",
          failed: "Error: el archivo {fileName} no se ha restaurado. ",
        },
        deleteFile: {
          ok: "El archivo {fileName} ha sido eliminado.",
          failed: "Error: el archivo {fileName} no se ha eliminado. ",
        },
        importUsersFromCsv: {
          ok: "La lista de direcciones de correo electrónico se importó correctamente. ",
          failed:
            "La importación de la lista de direcciones de correo electrónico falló debido al siguiente motivo: {reason}",
        },
        importEdrAllowBlockListFromCsv: {
          ok: "La lista de permitidos/bloqueados se importó correctamente. ",
          failed:
            "La importación de la lista de permitidos/bloqueados falló debido al siguiente motivo: {razón}",
        },
        operatorExportCreated: {
          ok: "El informe CSV está listo.",
          failed: "Error al generar el informe CSV. Inténtelo de nuevo.",
        },
      },
    },
  },
  smartSearch: {
    search: "Buscar",
    placeholder: "Buscar valores únicos o múltiples...",
    enterValue: "Introduzca el valor...",
    moreOptions: "Más opciones de búsqueda",
    lessOptions: "Menos opciones de búsqueda",
    searchBy: "Buscar por:",
    addOperator: "Agregar operador",
    searchByForensic: "Búsqueda por valores forenses:",
    noRecentSearches: "No tienes ninguna búsqueda reciente",
    searchPrefixes: {
      name: "Entrada principal",
      processHash: "Hash de proceso",
      hostname: "Dispositivo",
      enrollmentCode: "ID del dispositivo Coro",
      processName: "Proceso",
      user: "Nombre de usuario",
      eventId: "ID de evento",
      currentValue: "Valor actual",
      oldValue: "Valor antiguo",
      operationType: "Tipo de operación",
      registryKeyOperationType: "Tipo de operación",
      scheduledTaskOperationType: "Tipo de operación",
      action: "Acción",
      targetAccount: "Cuenta de destino",
      domain: "Dominio de la cuenta",
    },
    clearRecentSearches: "Borrar búsquedas recientes",
  },
  general: {
    description: "Descripción",
    coroConsole: "consola coro",
    review: "Revisar",
    notify: "Notificar",
    comment: "Comentario",
    or: "O",
    during: "Durante",
    type: "Tipo",
    device: "Dispositivo",
    process: "Proceso",
    enrollmentCode: "Código de inscripción",
    added: "Agregado",
    noActionsAvailable: "No hay acciones disponibles",
    itemsPerPage: "Artículos por página",
    sortBy: "Ordenar por",
    aliases: "alias",
    exportCsv: "Exportar CSV",
    cloudApps: "Aplicaciones en la nube",
    backToControlPanel: "< Panel de control",
    genericBackTo: "< Atrás",
    backToDashboard: "‹ Tablero de acción",
    users: "Usuarios protegidos",
    messages: "Mensajes",
    manageWorkspaces: "Administrar espacios de trabajo",
    tickets: "Registro de entradas",
    socTicketLog: "Registro de tickets de servicio administrado",
    controlPanel: "Panel de control",
    devices: "Dispositivos",
    backOffice: "Oficina administrativa",
    cancel: "Cancelar",
    apply: "Aplicar",
    admin: "administración",
    yes: "Sí",
    no: "No",
    applyFilters: "Aplicar filtros",
    clearFilters: "Borrar filtros",
    create: "Crear",
    reset: "Reiniciar",
    back: "Atrás",
    save: "Ahorrar",
    close: "Cerca",
    imDone: "He terminado",
    done: "Hecho",
    login: "Acceso",
    copied: "Copiado",
    finish: "Finalizar",
    confirm: "Confirmar",
    gotIt: "Entiendo",
    ok: "DE ACUERDO",
    search: "Buscar...",
    clearAllFilters: "Borrar todos los filtros",
    clearAll: "Borrar todo",
    disconnected: "Desconectado",
    connected: "Conectado",
    incomplete: "Incompleto",
    notConnected: "No conectado",
    enabled: "Activado",
    disabled: "Desactivado",
    bulkActions: "Acciones masivas",
    copyToClipboard: "Copiar al portapapeles",
    copy: "Copiar",
    viewEvents: "Ver entradas",
    viewAll: "Ver todo",
    view: "Vista",
    viewUsers: "Ver usuarios",
    service: "Servicio",
    needHelp: "necesito ayuda con esto",
    pleaseCorrectErrors: "Por favor corrija los errores",
    home: "tablero de acción",
    events: "Entradas",
    ticketLog: "Registro de entradas",
    ticketDetails: "Detalles del billete",
    goToDashboard: "Ir a mi panel",
    desktopNotSupported:
      "Nuestro sistema está diseñado para ejecutarse en una computadora de escritorio o portátil, con un navegador abierto en pantalla completa. ",
    low: "Bajo",
    medium: "Medio",
    high: "Alto",
    noData: "Sin datos",
    download: "Descargar",
    learnMore: "Más información",
    poweredBy: "Desarrollado por",
    outlook: "perspectiva 365",
    google: "Google",
    gmail: "Gmail",
    connect: "Conectar",
    enable: "Permitir",
    offline: "Desconectado",
    mute: "Silenciar",
    unmute: "Dejar de silenciar",
    resolve: "Resolver",
    actions: "Comportamiento",
    detectionLog: "Registro de detección",
    activityLog: "Registro de actividad",
    allActivity: "Toda la actividad",
    openTickets: "Entradas abiertas",
    lastLoggedInUsers: "Usuarios que iniciaron sesión recientemente",
    allOpenTickets: "Todas las entradas abiertas",
    noOpenTickets: "No hay entradas abiertas",
    noRecentLogins: "No hay inicios de sesión recientes",
    noActivity: "Ninguna actividad registrada",
    protectedSince: "Protegido desde",
    processed: "Cerrado",
    processedOn: "Cerrado el",
    unresolvedTickets: "Tickets no resueltos",
    noTickets: "Sin entradas",
    select: "Seleccionar",
    selected: "Seleccionado",
    all: "Todo",
    open: "Abierto",
    revoke: "Revocar",
    notAvailable: "N / A",
    addon: "Añadir",
    step: "Paso",
    osVersion: {
      android: "Androide",
      ios: "iOS",
      windows: "ventanas",
      macOs: "macos",
      osx: "macos",
    },
    refresh: "Refrescar",
    at: "en",
    until: "hasta",
    everyXDays: "Todos los días | ",
    none: "Ninguno",
    and: "y",
    on: "en",
    of: "de",
    group: "grupo | ",
    recaptcha:
      'Esta página está protegida por reCAPTCHA y sujeta a las normas de Google. <a data-testid="login-privacy-policy" class=\'coro-link\' href="https://www.google.com/policies/privacy/" target="_blank"> política de privacidad </a>y<a class=\'coro-link\' data-testid="login-terms-of-service" href="https://www.google.com/policies/terms/" target="_blank">\nTérminos de servicio</a>',
    add: "Agregar",
    tooltips: {
      usersSaySafe: "Usuarios marcados como seguros",
      usersSayPhishing: "Usuarios marcados como phishing",
      syncUsers: "Sincronice usuarios desde aplicaciones en la nube ahora",
    },
    by: "por",
    seeNMore: "Ver {n} más",
    seeMore: "Ver más",
    resolvedLastNinetyDays: "Resuelto los últimos 90 días",
    nUsers: "{n} usuario | ",
    edr: "Detección y respuesta de terminales",
    seeLess: "Ver menos",
    drives: "Unidades",
    name: "Nombre",
    status: "Estado",
    key: "Llave",
    blocked: "Obstruido",
    nonBlocked: "No bloqueado",
    closeUponConfirmation: "Cerrar este y tickets relacionados",
    closeOneTicketUponConfirmation: "Cerrar este ticket al confirmar",
    showAll: "Mostrar todo",
    emails: "Correos electrónicos",
    email: "Correo electrónico",
    remove: "Eliminar",
    edit: "Editar",
    hours: "{n} hora | ",
    minutes: "{n} minuto | ",
    test: "Prueba",
    format: "Formato",
    success: "Éxito",
    tryAgain: "Intentar otra vez",
    subscriptionExpired: "Tu suscripción ha caducado.",
    noPermissions: "No tienes permisos para realizar esta acción.",
    optional: "opcional",
    next: "Próximo",
    filters: "Filtros",
    accessToSensitiveDataRestricted: "El acceso a datos confidenciales está restringido",
    localeDisplayNames: {
      [WorkspaceLocale.EN]: "Inglés",
      [WorkspaceLocale.ES]: "Español",
    },
    viewByList: "Viewos by listos",
    views: "Viewos",
  },
  appBar: {
    userContextMenu: {
      logout: "desconectar",
      myAccount: "Cuenta",
      contactSupport: "Contactar con soporte",
    },
  },
  errors: {
    incorrectPSACredentials: "Algo salió mal. ",
    incorrectSIEMCredentials: "Ha proporcionado credenciales incorrectas para el proveedor SIEM",
    siemProviderNotAvailable: "Vaya, el proveedor SIEM no está disponible en este momento. ",
    generic: "Algo salió mal, por favor inténtalo de nuevo.",
    webhookTriggerTestFailed: "Error en la prueba de activación del webhook",
    webhookTestFailed: "La prueba del webhook falló",
    domainAlreadyExists: "El dominio ya existe",
    emailProxyRelayHostIsNotReachable:
      "Coro no puede alcanzar tu host de retransmisión SMTP. Verifica la configuración del host y del puerto.",
  },
  modals: {
    newOfficeAccountError: {
      title: "Esta cuenta de Microsoft 365 parece ser nueva",
      description:
        "Como puede tomar algunas horas para que Microsoft 365 configure los privilegios para cuentas nuevas, por favor regístrate en Coro un poco más tarde.",
      actionButton: "Entendido",
    },
    [WorkspaceCodeActions.CREATE]: {
      title: "Crear código de espacio de trabajo",
      actionBtn: "Crear",
      codeName: "Nombre del código",
      codeType: "Tipo de código",
      priceBookNumber: "Número del libro de precios",
      expirationDate: "Fecha de expiración",
      workspaceType: "Tipo de espacio de trabajo",
      bundles: "Paquetes",
      commercialTerms: "Términos comerciales",
      discount: "Descuento",
      trialDuration: "Duración de prueba",
      creditCardRequired: "Requiere tarjeta de crédito",
      relatedTo: "Relacionado con",
      agentDetails: "Agente",
      salesDetails: "AE (Ventas)",
      mspDetails: "MSP",
    },
    [WorkspaceCodeActions.DELETE]: {
      title: "¿Eliminar código de espacio de trabajo?",
      actionBtn: "Sí, eliminar",
      description: "El código de espacio de trabajo con todas sus configuraciones será eliminado",
    },
    [WorkspaceCodeActions.ACTIVATE]: {
      title: "¿Activar el código?",
      actionBtn: "Sí, activar",
      validTill: "Válido hasta",
      description:
        "El código de espacio de trabajo se activará y estará disponible para nuevos espacios de trabajo. Si lo necesitas, puedes establecer una fecha de vencimiento. Si no es necesario, déjalo en blanco.",
    },
    [WorkspaceCodeActions.DEACTIVATE]: {
      title: "¿Desactivar código de espacio de trabajo?",
      actionBtn: "Sí, desactivar",
      description:
        "El código de espacio de trabajo está asociado con espacios de trabajo y no puede ser eliminado. En su lugar, puedes desactivarlo para que no esté disponible para nuevos espacios de trabajo.",
    },
    [UserGroupAction.ADD_NEW_GROUP]: {
      title: "Agregar grupos a la protección de Coro",
      actionBtn: "Agregar grupos",
      description:
        "Puedes agregar usuarios a la protección en grupos, utilizando los grupos definidos en tu directorio de usuarios.",
      placeholder: "Ingresa el nombre del grupo/s",
      noData: "No se encontró ningún grupo coincidente",
      label: "Nombre del grupo",
    },
    [UserGroupAction.REMOVE_GROUPS]: {
      title: "¿Eliminar {n} grupo? | ¿Eliminar {n} grupos?",
      description: "Los servicios de protección de Coro ya no se aplicarán a estos grupos.",
      actionBtn: "Sí, eliminar",
    },
    [UserAction.IMPORT_USERS_FROM_CSV]: {
      title: "",
      actionBtn: "Agregar usuarios",
      disclaimer:
        "La primera columna del archivo CSV debe contener las direcciones de correo electrónico válidas de todos los usuarios que se agregarán a la protección de Coro. Las columnas adicionales y las entradas no válidas serán ignoradas.",
      parsingCsv: "Analizando CSV, por favor espera",
      uploadTxt: "Sube un archivo CSV",
      subtitle: "o arrastra y suelta el archivo aquí",
      downloadTemplate: "Descarga nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Algo salió mal durante la importación del CSV. Por favor intenta de nuevo.",
      },
      importInProgress:
        "La importación está en progreso. Consulta el registro de actividad para confirmar el resultado.",
    },
    [UserAction.ADD_ALL_USERS_CONFIRMATION]: {
      title: "¿Agregar protección a todos los usuarios de {service}?",
      description:
        "Todos los usuarios actuales de {service} se agregarán a la protección tras una sincronización con {service}. En adelante, los nuevos usuarios de {service} se agregarán automáticamente a la protección de Coro.",
      actionBtn: "Agregar protección",
    },
    [UserAction.ADD_USERS]: {
      title: "Agregar usuarios a la protección de Coro",
      actionBtn: "Agregar usuarios",
      label: "Correo electrónico",
      description: "Tienes 19 plazas disponibles en tu plan.",
      placeholder: "Ingresa el correo/s",
    },
    [UserAction.ADD_ALL_USERS]: {
      title: "Agregar protección a todos los usuarios",
      description:
        "Selecciona la aplicación en la nube para la cual deseas aplicar protección a todos los usuarios",
      noAppsProtected: "Actualmente no tienes ninguna aplicación en la nube protegida",
      noAppsProtectedDescription:
        "Conecta al menos un servicio en la nube para iniciar la protección",
      connectServicesBtn: "Conectar",
      actionBtn: "Agregar protección",
    },
    [ExceptionsAction.ADD_NEW_EXCEPTION]: {
      title: "Agregar usuarios individuales a la lista de exclusión",
      description: "Los usuarios agregados a la lista de exclusión estarán exentos de protección",
      actionBtn: "Agregar usuarios",
    },
    [UserAction.REMOVE_USERS]: {
      title: "Eliminar usuario",
      description: "Los servicios de protección de Coro ya no se aplicarán a <b>{email}</b>.",
      checkbox: "Agregar a la exclusión",
      actionBtn: "Sí, eliminar",
    },
    [UserAction.ADD_TO_EXCEPTION]: {
      title: "Agregar usuario individual a la lista de exclusión",
      description: "Los usuarios agregados a la lista de exclusión estarán exentos de protección",
      actionBtn: "Agregar usuarios",
    },
    [UserAction.REMOVE_EXCEPTION]: {
      title: "Eliminar exclusión",
      actionBtn: "Sí",
      description: "Los servicios de protección de Coro se aplicarán a este usuario nuevamente",
    },
    [TicketAction.SUSPEND_FROM_ALL]: {
      title:
        "¿Suspender al usuario de todas las aplicaciones protegidas? | ¿Suspender a los usuarios de todas las aplicaciones protegidas?",
      description:
        "El acceso del usuario a sus cuentas en todas las aplicaciones en la nube protegidas por Coro será suspendido temporalmente. | El acceso de los usuarios a sus cuentas en todas las aplicaciones en la nube protegidas por Coro será suspendido temporalmente.",
      actionBtn: "Suspender",
    },
    [TicketAction.SUSPEND_FROM_SERVICE]: {
      title: "¿Suspender al usuario de {serviceName}?",
      description:
        "El acceso del usuario a su cuenta en {serviceName} será suspendido temporalmente.",
      actionBtn: "Suspender",
    },
    [TicketAction.SIGN_IN_TO_ALL]: {
      title:
        "¿Solicitar al usuario que inicie sesión en todas las aplicaciones protegidas? | ¿Solicitar a los usuarios que inicien sesión en todas las aplicaciones protegidas?",
      description:
        "Se solicitará al usuario que vuelva a iniciar sesión en todas las aplicaciones en la nube protegidas por Coro que está utilizando. | Se solicitará a los usuarios que vuelvan a iniciar sesión en todas las aplicaciones en la nube protegidas por Coro que están utilizando.",
      actionBtn: "Solicitar",
    },
    [TicketAction.MARK_AS_PROCESSED]: {
      title: "¿Cerrar los tickets seleccionados?",
      description: "Los tickets cerrados serán eliminados de tu tablero de acciones.",
      permissionsBanner: "No tienes permiso para cerrar tickets relacionados con:",
      notRestrictedModules:
        "Los tickets cerrados en los siguientes módulos serán eliminados de tu tablero de acciones:",
      actionBtn: "Cerrar tickets",
    },
    [TicketAction.MARK_AS_UNPROCESSED]: {
      title: "¿Reabrir los tickets seleccionados?",
      description: "Los tickets reabiertos serán agregados a tu tablero de acciones.",
      actionBtn: "Reabrir tickets",
    },
    [MalwareAction.TOGGLE_INIT_SCAN]: {
      title: "Advertencia",
      description:
        "El escaneo de los discos de los endpoints es una operación que consume muchos recursos y podría afectar la experiencia de los usuarios finales.",
      actionBtn: "Continuar",
    },
    [AdminUsersAction.ADD]: {
      title: "Agregar usuario administrador",
      firstName: {
        placeholder: "Nombre",
        label: "Nombre",
      },
      lastName: {
        placeholder: "Apellido",
        label: "Apellido",
      },
      email: {
        placeholder: "Correo electrónico",
        label: "Correo electrónico",
      },
      role: {
        placeholder: "Rol",
        label: "Rol",
      },
      actionBtn: "Guardar",
      errors: {
        general: "Por favor corrige los errores a continuación",
        restriction: "Por favor selecciona el tipo de permiso",
      },
    },
    [AdminUsersAction.EDIT]: {
      title: "Editar usuario administrador",
      actionBtn: "Guardar",
    },
    addConnectwiseConnector: {
      integrationName: {
        label: "Nombre de la integración",
        placeholder: "Ingresa el nombre de la integración",
      },
      title: {
        generic: "Agregar conector a tu entorno PSA",
        add: {
          [PsaProviders.GRADIENT]: "Agregar conector Gradient",
          [PsaProviders.CONNECTWISE]: "Agregar conector ConnectWise",
          [PsaProviders.AUTOTASK]: "Agregar conector Autotask",
        },
        edit: {
          [PsaProviders.GRADIENT]: "Editar conector Gradient",
          [PsaProviders.CONNECTWISE]: "Editar conector ConnectWise",
          [PsaProviders.AUTOTASK]: "Editar conector Autotask",
        },
      },
      companyName: {
        label: "Nombre de la empresa",
        placeholder: "Ingresa el nombre de la empresa (el MSP)...",
      },
      domain: {
        label: "Dominio de ConnectWise",
        placeholder: "Ingresa el dominio de ConnectWise...",
      },
      publicKey: {
        label: "Clave pública",
        placeholder: "Ingresa la clave pública...",
      },
      privateKey: {
        label: "Clave privada",
        placeholder: "Ingresa la clave privada...",
      },
      enableTicketing: "Habilitar ticketing",
      enableBilling: "Habilitar información de facturación",
    },
    [ConnectorActions.ADD_SIEM]: {
      title: "Agregar conector",
      actionBtn: "Agregar",
      listenerUrlLabel: "URL del listener",
      listenerUrlHint: "HTTP y HTTPS compatibles",
      customHeaders: "Encabezados personalizados",
      applyToAll: "Aplicar a todos los clientes",
      header: "Encabezado",
      value: "Valor",
      token: "Token",
      addBtn: "+ Encabezado",
      azureSentinel: {
        workspaceId: "ID del espacio de trabajo",
        primaryKey: "Clave principal",
        logType: "Tipo de registro",
      },
    },
    [ConnectorActions.UPDATE_SIEM]: {
      title: "Editar conector",
      actionBtn: "Guardar",
    },
    [ConnectorActions.DELETE_SIEM]: {
      title: "¿Eliminar conector?",
      description:
        "El conector {name} será desactivado y eliminado de tu espacio de trabajo. | El conector {name} será desactivado y eliminado de los espacios de trabajo de todos los clientes.",
      actionBtn: "Sí, eliminar",
    },
    [PhishingAction.ADD_TO_ALLOWLIST]: {
      title: "Agregar a la lista de permitidos",
      label: "Ingresa correos electrónicos, dominios o IPs/rangos de IPs",
      addToListLabel: "Agregar correos electrónicos, dominios o IPs/rangos de IPs",
      placeholder:
        "Ingresa una o más direcciones de correo, dominios, direcciones IP o rangos de IP",
      actionBtn: "Agregar a la lista",
      authList: "Fallo de autenticación {listType}",
      suspiciousContentList: "Contenido sospechoso {listType}",
    },
    [PhishingAction.ADD_TO_BLOCKLIST]: {
      title: "Agregar a la lista de bloqueados",
      actionBtn: "Agregar a la lista",
    },
    [PhishingAction.REMOVE_FROM_BLOCKLIST]: {
      title: "¿Eliminar de la lista de bloqueados?",
      description: "{name} ya no estará en la lista de bloqueados.",
      actionBtn: "Sí, eliminar",
    },
    [PhishingAction.REMOVE_FROM_ALLOWLIST]: {
      title: "¿Eliminar de la lista de permitidos?",
      description: "{name} ya no estará en la lista de permitidos.",
      actionBtn: "Sí, eliminar",
    },
    [PhishingAction.DOWNLOAD_ALLOW_BLOCK_LIST_EXAMPLE_CSV]: {
      title: "",
      actionBtn: "Importar",
      disclaimer:
        "La primera columna del archivo CSV debe contener las direcciones de correo válidas, dominios, direcciones IP o rangos de direcciones IP de todos los usuarios que se agregarán a la protección de Coro. Las columnas adicionales y las entradas no válidas serán ignoradas.",
      parsingCsv: "Analizando CSV, por favor espera",
      parsingFile: "Analizando archivo, por favor espera",
      uploadTxt: "Sube un archivo CSV",
      subtitle: "o arrastra y suelta el archivo aquí",
      downloadTemplate: "Descarga nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Algo salió mal durante la importación del CSV. Por favor intenta de nuevo.",
      },
      importInProgress:
        "La importación está en progreso. Consulta el registro de actividad para confirmar el resultado.",
    },
    [DataLossAction.ADD_PERMISSION_FOR_ALL_USERS]: {
      title: "Agregar permiso para todos los usuarios",
      actionBtn: "Agregar",
    },
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_GROUPS]: {
      title: "Agregar permiso para grupos específicos",
      placeholder: "Ingresa nombres de grupos protegidos",
      label: "Nombre del grupo",
      actionBtn: "Agregar",
    },
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_USERS]: {
      title: "Aplicar política a estos usuarios o dominios",
      placeholder: "Ingresa correos electrónicos de usuarios o nombres de dominio...",
      label: "Ingresa correos electrónicos de usuarios o nombres de dominio...",
      actionBtn: "Agregar",
    },
    addAutotaskConnector: {
      userName: {
        label: "User name",
        placeholder: "Enter user name...",
      },
      secret: {
        label: "Secret",
        placeholder: "Enter secret...",
      },
    },
    removePsa: {
      title: "Disconnect connector?",
      description: "Are you sure you want to disconnect the connector?",
      actionBtn: "Yes, disconnect",
    },
    [AdminUsersAction.DELETE]: {
      title: "Remove admin user?",
      description:
        "<span class='text--semibold'>{email}</span> will no longer have access to this workspace.",
      channelDescription:
        "<span class='text--semibold'>{email}</span> will no longer have access to this workspace. If this admin user is added as MSP admin, these permissions will also be removed.",
      actionBtn: "Yes, remove",
    },
    [AdminUsersAction.DELETE_MFA]: {
      title: "Delete 2FA Data?",
      description:
        "<span class='text--semibold'>{email}</span> will be requested to re-setup 2FA data.",
      actionBtn: "Yes, delete",
    },
    [AdminUsersAction.TOGGLE_WORKSPACE_MFA]: {
      title: "Do not require 2FA?",
      description:
        "If two-factor authentication is disabled, the identity of workspace admin users will be verified only by their passwords, reducing workspace security.",
      actionBtn: "Yes",
    },
    [AccountAction.TOGGLE_MFA]: {
      title: "Disable 2FA?",
      description:
        "Disabling two-factor authentication means administrators' identities will be verified by passwords only, reducing workspace security.",
      actionBtn: "Yes, disable",
    },
    removeApiKey: {
      title: "Revoke API Credentials?",
      description: "These API credentials will no longer be valid. This action cannot be undone.",
      actionBtn: "Revoke",
    },
    addApiKey: {
      title: "Create API credentials",
      description: "Description",
      expirationDate: "Expiration date",
      makeSureYouSaveIt: "Make sure you save it - you won't be able to access it again.",
      yourNewToken: "Your new personal access token has been generated",
      actionBtn: "Create",
      clientId: "Client ID",
      clientSecret: "Client Secret",
    },
    copyInviteLink: {
      title: "Copy Invite Link",
      description: "Recipients will use this link to log in to Coro.",
      actionBtn: "Copy",
    },
    [DevicePostureAction.ADD]: {
      title: "Add new device policy to {osType}",
      macOsDisclaimer:
        "Devices running macOS 15 support only the review action, with policy enforcement unavailable.",
      selectPolicyType: "Select policy type",
      action: "Action",
      postureActions: {
        enforce: "Enforce",
        review: "Review",
      },
      applyPolicyToDevices: "Apply policy to devices with these labels",
      labels: "Labels",
      actionBtn: "Save",
      wifiSettings: {
        connection: "Connection",
        connectionTypes: {
          ALL_ENCRYPTED: "Allow connection to all encrypted networks",
          SPECIFIC: "Allow connection to specific networks",
        },
        networksPlaceholder: "Enter specific networks...",
      },
      usbLockdown: {
        blockPortableDevices: "Block portable devices",
        blockMassStorage: "Block mass storage devices",
        atLeastOneRequired: "At least one policy is required",
        policyIsRequired: "Policy is required",
      },
      dlpScanSchedule: {
        days: "{n} day | {n} days",
        every: "Every",
        preferredTime: "Preferred time",
        applyTo: "Apply to",
        driveTypes: {
          ALL: "All Drives",
          UNENCRYPTED: "Unencrypted Drives",
        },
      },
      userAccountPolicy: {
        password: "Password",
        screenLockout: "Screen lockout",
        minimumLength: "Minimum length",
        maximumAge: "Maximum age (days)",
        minimumAge: "Minimum age (days)",
        enforcePasswordHistory: "Enforce password history",
        passwordComplexity: "Password must meet complexity requirements",
        lockoutDuration: "Lockout duration",
        lockoutThreshold: "Lockout threshold (attempts)",
      },
    },
    [DevicePostureAction.EDIT]: {
      title: "Edit {osType} device policy",
      actionBtn: "Save",
    },
    [DevicePostureAction.REMOVE]: {
      title: "Delete policy?",
      description:
        "The deleted device posture policy will no longer apply to the respective devices.",
      actionBtn: "Confirm",
    },
    [SocContactsActions.EDIT]: {
      title: "Edit managed service contact",
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      notificationSettings: {
        title: "Notification settings",
        [SocContactNotificationTypes.EMAIL]: "Email",
        [SocContactNotificationTypes.DO_NOT_NOTIFY]: "Do not notify",
      },
      closureSettings: {
        title: "Receive reports about tickets handled by our Managed Service Team via email",
        [SocContactClosureNotification.WEEKLY]: "Weekly summary",
        [SocContactClosureNotification.PER_TICKET]: "Per ticket",
        [SocContactClosureNotification.DO_NOT_NOTIFY]: "Do not notify",
      },
      actionBtn: "Save",
    },
    [SocContactsActions.CREATE]: {
      title: "Create managed service contact",
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      notificationSettings: {
        title: "Notification settings",
        [SocContactNotificationTypes.EMAIL]: "Email",
        [SocContactNotificationTypes.DO_NOT_NOTIFY]: "Do not notify",
      },
      actionBtn: "Save",
    },
    [SocContactsActions.REMOVE]: {
      title: "Remove managed service contact",
      description: "Would you like to remove managed service contact?",
      actionBtn: "Yes, remove",
    },
    [ServiceAction.CONNECT]: {
      title: "Connect to {service}",
      noServiceTitle: "Connect cloud applications",
      description: "Are you sure you want to delete '{service}'?",
      actionBtn: "OK",
    },
    [ServiceAction.DISCONNECT]: {
      title: "Disconnect {service} from Coro protection?",
      description: "Your {service} service and data will no longer be monitored and protected.",
      actionBtn: "Yes, disconnect",
    },
    [SensitiveDataScansActions.CREATE]: {
      title: "Agregar nuevo horario de escaneo",
      selectOs: "Seleccionar sistema operativo",
      actionBtn: "Guardar",
      every: "Cada",
      preferredTime: "Hora preferida",
      applyTo: "Aplicar a",
      applyToLabels: "Aplicar política a dispositivos con estas etiquetas",
      labelsPlaceholder: "Seleccionar etiquetas...",
      labelsLabel: "Etiquetas",
      driveTypes: {
        ALL: "Todos",
        UNENCRYPTED: "No encriptados",
      },
    },
    [SensitiveDataScansActions.EDIT]: {
      title: "Editar horario de escaneo",
      selectOs: "Seleccionar sistema operativo",
      actionBtn: "Guardar",
      every: "Cada",
      preferredTime: "Hora preferida",
      applyTo: "Aplicar a",
      applyToLabels: "Aplicar política a dispositivos con estas etiquetas",
      labelsPlaceholder: "Seleccionar etiquetas...",
      labelsLabel: "Etiquetas",
      driveTypes: {
        ALL: "Todos",
        UNENCRYPTED: "No encriptados",
      },
    },
    [SensitiveDataScansActions.DELETE]: {
      title: "¿Eliminar horario de escaneo?",
      description:
        "La política de horario de escaneo eliminada ya no se aplicará a los dispositivos correspondientes.",
      actionBtn: "Eliminar",
    },
    [WorkspaceAction.SELECT_WORKPLACE]: {
      title: "Select Workspace to View",
      actionBtn: "Launch",
    },
    [WorkspaceAction.LAUNCH_WITH_2FA]: {
      title: "Set up 2FA?",
      description: "Workspace <b>{workspaceId}</b> requires two-factor authentication setup.",
      actionBtn: "Yes, set up",
    },
    [SocRemediationActions.CHANGE_REMEDIATION_TYPE]: {
      title: "Confirm your selection",
      description:
        "<b>Please read this</b><br><br>By clicking confirm, I acknowledge that granting permission for remote actions on Coro workspace and end-users' email, cloud, or endpoint access is done with my full understanding of the potential consequences. I understand that Coro shall not be held liable for any resulting business losses, work disruptions, or other issues, as these actions are executed at my request and with my express consent.<br>",
      actionBtn: "Confirm",
    },
    [DeviceAllowListActions.ADD_PROCESS_RECORD]: {
      title: "Agregar nuevo registro de proceso",
      descriptionTitle: "Agregar descripción",
      namePlaceholder: "Ingrese texto...",
      descriptionPlaceholder: "Ingrese texto...",
      descriptionLabel: "Descripción",
      list: "Lista",
      listDescription: "Los procesos se agregan automáticamente a la lista de bloqueo.",
      allow: "Permitir",
      block: "Bloquear",
      nameLabel: "Hash",
      nameDescription: "Agregar hash",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.ADD_FOLDER_RECORD]: {
      title: "Agregar nuevo registro de carpeta",
      descriptionTitle: "Agregar descripción",
      namePlaceholder: "Ingrese texto...",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Ingrese texto...",
      list: "Lista",
      listDescription: "Las carpetas se agregan automáticamente a la lista de permitidos.",
      allow: "Permitir",
      block: "Bloquear",
      nameLabel: "Carpeta",
      nameDescription: "Agregar carpeta",
      nameDescription2:
        "Use variables de entorno para aplicar valores en varias entradas. Obtenga más información en nuestra <a class='coro-link' href='https://docs.coro.net/endpointsecurity/allow-block/' target=\"_blank\">documentación</a>.",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.ADD_FILE_RECORD]: {
      title: "Agregar nuevo registro de archivo",
      descriptionTitle: "Agregar descripción",
      namePlaceholder: "Ingrese texto...",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Ingrese texto...",
      list: "Lista",
      listDescription: "Los archivos se agregan automáticamente a la lista de permitidos.",
      allow: "Permitir",
      block: "Bloquear",
      nameLabel: "Ruta",
      nameDescription: "Agregar ruta de archivo",
      nameDescription2:
        "Use variables de entorno para aplicar valores en varias entradas. Obtenga más información en nuestra <a class='coro-link' href='https://docs.coro.net/endpointsecurity/allow-block/' target=\"_blank\">documentación</a>",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST]: {
      title: "Agregar a la lista de permitidos",
      infoText:
        "Los procesos especificados por su hash o ubicación del archivo de imagen no generarán tickets EDR.<br>La información de proceso relacionada no se recopilará en las pestañas de Telemetría y Proceso.",
      namePlaceholder: "Ingrese hash o ruta de carpeta...",
      nameLabel: "Hash o ruta",
      nameDescription: "Agregar hash de proceso o ruta de carpeta",
      descriptionTitle: "Agregar descripción",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Ingrese descripción...",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST]: {
      title: "Agregar a la lista de bloqueados",
      infoText: `Los procesos bloqueados no podrán ejecutarse en toda la organización.`,
      namePlaceholder: "Ingrese hash...",
      nameLabel: "Hash",
      nameDescription: "Agregar hash de proceso",
      descriptionTitle: "Agregar descripción",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Ingrese descripción...",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.REMOVE_RECORD]: {
      title: "¿Eliminar registro?",
      descriptionBlocked: "El registro ya no estará en la lista de bloqueados",
      descriptionAllowed: "El registro ya no estará en la lista de permitidos",
      actionBtn: "Sí, eliminar",
    },
    [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: {
      title: "Importar CSV a la lista de permitidos / bloqueados",
      actionBtn: "Importar",
      disclaimer:
        "Las entradas deben contener valores válidos en las 4 columnas. Las entradas con columnas adicionales o valores no válidos serán ignoradas.",
      parsingFile: "Analizando CSV, por favor espere",
      downloadTemplate: "Descargue nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Ocurrió un error durante la importación de CSV. Inténtelo de nuevo.",
      },
      importInProgress:
        "La importación está en progreso. Vea el registro de actividades para confirmar el resultado.",
      uploadTxt: "<span class='coro-link'>Haga clic para subir</span> o arrastre y suelte",
      subtitle: "CSV (máx. {size})",
      successfullyUploaded: "{n} registros subidos correctamente",
      invalidRecords: "{n} de {sum} registros no eran válidos",
      notAllRecordsUploaded: "No se subieron todos los registros correctamente",
    },
    importUrlGroupAllowBlockListFromCsv: {
      allowListTitle: "Importar grupo de URL a la lista de permitidos",
      blockListTitle: "Importar grupo de URL a la lista de bloqueados",
      actionButton: "Importar",
      disclaimer:
        "Las entradas deben contener valores válidos en una columna. Las entradas con columnas adicionales o valores no válidos serán ignoradas.",
      parsingFile: "Analizando archivo, por favor espere",
      uploadTxt: "Subir un archivo TXT",
      uploadTextSubtitle: "o arrastre y suelte el archivo aquí",
      downloadTemplate: "Descargue nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Ocurrió un error durante la importación del archivo. Inténtelo de nuevo.",
      },
      importInProgress: "Entradas añadidas correctamente",
    },
    [RolesAction.ADD]: {
      title: "Agregar rol",
      role: "Rol",
      roleName: "Nombre del rol",
      workspacePermissions: "Permisos de espacio de trabajo",
      actionBtn: "Guardar",
    },
    [RolesAction.EDIT]: {
      title: "Editar rol",
      actionBtn: "Guardar",
    },
    [RolesAction.DUPLICATE]: {
      title: "Duplicar rol",
      actionBtn: "Guardar",
    },
    [RolesAction.DELETE]: {
      title: "¿Eliminar rol?",
      description: "El rol <strong>{name}</strong> será eliminado",
      actionBtn: "Sí, eliminar",
    },
    [RolesAction.UNABLE_TO_DELETE]: {
      title: "No se puede eliminar el rol",
      description:
        "Solo se pueden eliminar roles que no están asociados con usuarios administradores. Elimine este rol de los usuarios administradores e inténtelo de nuevo.",
      actionBtn: "Sí, eliminar",
    },
    [MspRolesAction.ADD]: {
      title: "Agregar rol MSP",
      role: "Rol",
      roleName: "Nombre del rol",
      childWorkspacePermissions: "Permisos de espacios de trabajo descendientes",
      workspace: {
        placeholder: "Seleccionar espacio de trabajo",
        label: "Espacio de trabajo",
        hint: "Selecciona el espacio de trabajo del canal al que estás agregando el rol. Este rol define los permisos para este espacio de trabajo y sus descendientes.",
      },
      tooltips: {
        [MspRolePermissionScopes.MSP_PORTAL_SCOPE]:
          "Estos permisos se aplican a elementos y acciones en el portal MSP.",
        [RolePermissionsScope.WORKSPACE_MANAGEMENT]:
          "Estas opciones determinan los permisos de los administradores MSP para la sección de Espacios de Trabajo en el Panel de Control en todos los espacios de trabajo descendientes.",
        [RolePermissionsScope.VIEWS]:
          "Estas opciones determinan los permisos de los administradores MSP para las vistas de Usuarios y Dispositivos en todos los espacios de trabajo descendientes.",
        [RolePermissionsScope.PROTECTION]:
          "Estas opciones determinan los permisos de los administradores MSP para la sección de Protección del Panel de Control en todos los espacios de trabajo descendientes.",
        [RolePermissionsScope.TICKETS]:
          "Estas opciones determinan los permisos de los administradores MSP para tickets y el Tablero de Acciones en todos los espacios de trabajo descendientes.",
      },
      actionBtn: "Guardar",
    },
    [MspRolesAction.EDIT]: {
      title: "Editar rol",
      actionBtn: "Guardar",
    },
    [MspRolesAction.DUPLICATE]: {
      title: "Duplicar rol",
      actionBtn: "Guardar",
    },
    [MspRolesAction.DELETE]: {
      title: "¿Eliminar rol?",
      description: "El rol <strong>{name}</strong> será eliminado",
      actionBtn: "Sí, eliminar",
    },
    [MspRolesAction.UNABLE_TO_DELETE]: {
      title: "No se puede eliminar el rol",
      description:
        "Solo se pueden eliminar roles que no estén asociados a usuarios administradores. Elimina este rol de los usuarios administradores e intenta de nuevo.",
      actionBtn: "Sí, eliminar",
    },
    [GlobalRolesAction.ADD]: {
      title: "Agregar rol global",
      actionBtn: "Guardar",
    },
    [GlobalRolesAction.EDIT]: {
      title: "Editar rol",
      actionBtn: "Guardar",
    },
    [GlobalRolesAction.DUPLICATE]: {
      title: "Duplicar rol",
      actionBtn: "Guardar",
    },
    [GlobalRolesAction.DELETE]: {
      title: "¿Eliminar rol?",
      description: "El rol <strong>{name}</strong> será eliminado",
      actionBtn: "Sí, eliminar",
    },
    [GlobalRolesAction.UNABLE_TO_DELETE]: {
      title: "No se puede eliminar el rol",
      description:
        "Solo se pueden eliminar roles que no estén asociados a usuarios administradores. Elimina este rol de los usuarios administradores e intenta de nuevo.",
      actionBtn: "Sí, eliminar",
    },
    [ActiveSessionsAction.REVOKE]: {
      title: "¿Revocar esta sesión activa?",
      description: "El usuario <strong>{email}</strong> será desconectado del sistema.",
      actionBtn: "Sí, revocar",
    },
    [AccessPermissionAction.ADD]: {
      title: "Nuevos permisos de acceso para {type}",
      description: "Permitir acceso a {service} desde países/direcciones IP específicos",
      restrict: {
        title: "Permitir acceso desde:",
        placeholder: "Seleccionar",
        allCountries: "Todos los países",
        usaStates: "Estados de EE. UU.",
        country: {
          placeholder: "Ingrese una lista de países desde los que se debe permitir el acceso",
          label: "Países",
          noData: "No se encontró ningún país coincidente",
        },
        ip: {
          placeholder:
            "Ingrese una lista de IPs o rangos de IP en notación CIDR desde los que se debe permitir el acceso",
          label: "Direcciones IP",
        },
        ipDescription: {
          placeholder:
            "Descripción opcional del conjunto de IPs seleccionado (por ejemplo, Oficina)",
          label: "Descripción",
        },
        types: {
          country: "País",
          ip: "Dirección IP",
        },
      },
      group: {
        title: "El acceso será permitido a:",
        label: "Grupos",
        placeholder: "Ingrese el nombre del grupo protegido",
        noData: "No se encontró ningún grupo coincidente",
        types: {
          all: "Todos los usuarios protegidos",
          group: "Grupos protegidos específicos",
        },
      },
      users: {
        label: "Correo electrónico",
        placeholder: "Ingrese correos electrónicos",
      },
      remediation: {
        title: "Remediación automática",
        types: {
          none: "Ninguna",
          suspend: "Suspender",
          signin: "Cerrar sesión",
        },
      },
      actionBtn: "Guardar permisos",
      errors: {
        general: "Falta información",
        restriction: "Por favor seleccione tipo de permiso",
      },
    },
    [AccessPermissionAction.EDIT]: {
      title: "Editar permiso de acceso",
      actionBtn: "Guardar permisos",
    },
    [AccessPermissionAction.DELETE]: {
      title: "¿Eliminar permiso de acceso?",
      description: "Los permisos de acceso ya no se aplicarán a los usuarios respectivos.",
      actionBtn: "Sí, eliminar",
    },
    [LabelAction.CREATE_LABEL]: {
      title: "Agregar etiqueta",
      placeholder: "Título",
      actionBtn: "Agregar",
      selectColor: "Seleccionar color",
    },
    [LabelAction.EDIT_LABEL]: {
      title: "Editar etiqueta",
      actionBtn: "Guardar",
    },
    [LabelAction.DELETE_LABEL]: {
      title: "¿Eliminar la etiqueta {name}?",
      description:
        "Tenga en cuenta que la etiqueta <b>{name}</b> será eliminada para <b>{deviceCount}</b> dispositivo. | Tenga en cuenta que la etiqueta <b>{name}</b> será eliminada para <b>{deviceCount}</b> dispositivos.",
      actionBtn: "Sí, eliminar",
    },
    [AgentDeploymentAction.SELECT_CHANNEL]: {
      title: "Mostrar canales de lanzamiento",
      description:
        "Configurar qué canales de lanzamiento están permitidos en el espacio de trabajo",
      channelChoices: {
        stable: "Canales de lanzamiento estables",
        all: "Todos los canales de lanzamiento",
      },
      actionBtn: "Guardar",
    },
    [DeviceAction.REMOVE_FROM_PROTECTION]: {
      title: "¿Deshabilitar protección? | ¿Ocultar estos dispositivos?",
      multipleDeviceTitle: "¿Ocultar estos dispositivos?",
      description:
        "El dispositivo ya no será monitoreado ni protegido por el agente de protección de endpoint de Coro. | Los dispositivos ya no serán monitoreados ni protegidos por los agentes de protección de endpoint de Coro.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.ENFORCE_UAC]: {
      title: "¿Habilitar notificación UAC?",
      description: "La notificación UAC en este dispositivo será reactivada.",
      actionBtn: "Habilitar",
    },
    [DeviceAction.DISABLE_DEVELOPER_MODE]: {
      title: "¿Deshabilitar modo desarrollador?",
      description: "El modo desarrollador en este dispositivo será deshabilitado.",
      actionBtn: "Deshabilitar",
    },
    [DeviceAction.DISABLE_TAMPER_RESISTANCE]: {
      title: "¿Prevenir desinstalación del agente?",
      description:
        "Prevenir la desinstalación protege el Agente de endpoint de Coro de interferencias por parte de usuarios o terceros.",
      actionBtn: "Sí",
    },
    [DeviceAction.ENABLE_FIREWALL]: {
      title: "Habilitar firewall",
      description: "¿Habilitar el firewall?",
      actionBtn: "Sí",
    },
    [DeviceAction.REMOTE_SCAN]: {
      title: "Escaneo remoto para malware",
      description: "¿Realizar escaneo remoto para malware?",
      actionBtn: "Sí",
    },
    [DeviceAction.DLP_PARTIAL_SCAN]: {
      title: "Escaneo remoto para datos sensibles",
      description:
        "El escaneo de discos finales para datos sensibles consume recursos (CPU/memoria).\n¿Proceder con el escaneo remoto?\n",
      partialScan: "Detener escaneo cuando los hallazgos ya sean sustanciales.",
      actionBtn: "Sí",
    },
    [DeviceAction.STOP_REMOTE_SCAN]: {
      title: "Detener escaneo remoto para malware",
      description: "¿Detener escaneo remoto para malware?",
      actionBtn: "Sí",
    },
    [DeviceAction.STOP_DLP_SCAN]: {
      title: "Detener escaneo para datos sensibles",
      description: "¿Detener escaneo para datos sensibles?",
      actionBtn: "Sí",
    },
    [DeviceAction.ALLOW_NO_ENCRYPTION]: {
      title: "¿Agregar este disco duro a la lista permitida?",
      description:
        "De ahora en adelante, este disco duro será tratado como si no contuviera datos sensibles que requieran cifrado de disco.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.COLLECT_LOGS]: {
      title: "¿Recoger registros?",
      description:
        "¿Está seguro de que desea recoger registros para el dispositivo <b>{enrollmentCode}</b>?",
      actionBtn: "Sí, recoger",
    },
    [DeviceAction.ENABLE_NETWORK_BLOCK_MODE]: {
      title: "¿Aislar dispositivo afectado? | ¿Aislar dispositivos afectados?",
      description:
        "El dispositivo será aislado de la red. | {quantity} dispositivos serán aislados de la red.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.DISABLE_NETWORK_BLOCK_MODE]: {
      title:
        "¿Deshabilitar aislamiento del dispositivo? | ¿Deshabilitar aislamiento de dispositivos?",
      description:
        "El aislamiento del dispositivo de la red será deshabilitado. | El aislamiento de {quantity} dispositivos de la red será deshabilitado.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.REBOOT_DEVICE]: {
      title: "¿Reiniciar dispositivo? | ¿Reiniciar dispositivos?",
      description: "El dispositivo será reiniciado. | {quantity} dispositivos serán reiniciados.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.SHUTDOWN_DEVICE]: {
      title: "¿Apagar dispositivo? | ¿Apagar dispositivos?",
      description: "El dispositivo será apagado. | {quantity} dispositivos serán apagados.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.UPDATE_AGENT_TO_LATEST]: {
      title: "¿Actualizar agente a la última versión estable?",
      description:
        "Los agentes en los dispositivos seleccionados serán actualizados a la última versión estable.",
      actionBtn: "Sí",
    },
    [DeviceAction.ENABLE_TAMPER_RESISTANCE]: {
      title: "¿Permitir desinstalación del agente?",
      description:
        "Permitir la desinstalación expone el Agente de endpoint de Coro y las copias de seguridad de recuperación a ataques de malware y ransomware. La desinstalación del agente solo debe permitirse para el mantenimiento controlado del dispositivo o para permitir al usuario desinstalar el Agente de endpoint de Coro.",
      actionBtn: "Permitir",
    },
    [DeviceAction.COLLECT_QUARANTINE_DATA]: {
      title: "¿Recoger lista de cuarentena?",
      description:
        "¿Está seguro de que desea recoger la lista de cuarentena para el dispositivo <b>{enrollmentCode}</b>?",
      actionBtn: "Sí, recoger",
    },
    [DeviceAction.ADD_LABEL]: {
      title: "Agregar etiqueta al dispositivo",
      placeholder: "Nombre...",
      actionBtn: "Guardar",
      manageLabels: "Gestionar etiquetas ›",
    },
    [DeviceAction.REMOVE_LABEL]: {
      title: "Eliminar etiqueta del dispositivo",
      placeholder: "Nombre...",
      actionBtn: "Guardar",
      manageLabels: "Gestionar etiquetas ›",
    },
    [DeviceAction.OPEN_REMOTE_SHELL_SESSION]: {
      title: "Conexión de shell remoto",
      connectingTo: "Conectando a {name}. La conexión puede tardar hasta {minutes} minutos",
      connectedTo: "Está conectado a {name}",
      commandInProgress: "Esperando la ejecución del comando",
      helpTitle: "A continuación se encuentra la lista de comandos soportados:",
      unknownCommand:
        'Comando desconocido: {command}. Por favor, escriba "help" para ver la lista de comandos soportados.',
      combinedCommandNotSupported:
        'Los comandos combinados no son soportados, para comandos soportados ejecute "help".',
      uploadScriptLabel: "Subir script",
      uploadScriptHint: "Solo se permiten archivos con extensión .ps1",
      run: "Ejecutar",
      errors: {
        shellSessionOpeningFailed:
          "El dispositivo no responde a la solicitud de shell remoto.<br> Por favor, intente nuevamente más tarde.",
        shellSessionClosed: "La sesión de shell remoto ha sido cerrada en el dispositivo.",
        commandFailed:
          "El dispositivo no responde a los comandos.<br> Por favor, intente nuevamente más tarde.",
        tooManyShellSessionsOpened:
          "El dispositivo tiene demasiadas conexiones de shell remoto activas.",
      },
    },
    [EdrAction.BLOCK_PROCESS]: {
      title: "¿Bloquear proceso? | ¿Bloquear procesos seleccionados?",
      description:
        "El proceso <b>{name}</b> con SHA-256 <b>{processHash}</b> será bloqueado. | Los procesos seleccionados serán bloqueados.",
      actionBtn: "Confirmar",
    },
    [EdrAction.UNBLOCK_PROCESS]: {
      title: "¿Desbloquear proceso? | ¿Desbloquear procesos seleccionados?",
      description:
        "El proceso <b>{name}</b> con SHA-256 <b>{processHash}</b> será desbloqueado. | Los procesos seleccionados serán desbloqueados.",
      actionBtn: "Confirmar",
    },
    [EdrAction.ENABLE_NETWORK_BLOCK_MODE]: {
      title: "¿Aislar dispositivo afectado? | ¿Aislar dispositivos afectados?",
      description:
        "El dispositivo será aislado de la red. | {quantity} dispositivos serán aislados de la red.",
      actionBtn: "Confirmar",
    },
    [EdrAction.DISABLE_NETWORK_BLOCK_MODE]: {
      title:
        "¿Deshabilitar aislamiento del dispositivo? | ¿Deshabilitar aislamiento de dispositivos?",
      description:
        "El aislamiento del dispositivo de la red será deshabilitado. | El aislamiento de {quantity} dispositivos de la red será deshabilitado.",
      actionBtn: "Confirmar",
    },
    [EdrAction.REBOOT_DEVICE]: {
      title: "¿Reiniciar dispositivo? | ¿Reiniciar dispositivos?",
      description: "El dispositivo será reiniciado. | {quantity} dispositivos serán reiniciados.",
      actionBtn: "Confirmar",
    },
    [EdrAction.SHUTDOWN_DEVICE]: {
      title: "¿Apagar dispositivo? | ¿Apagar dispositivos?",
      description: "El dispositivo será apagado. | {quantity} dispositivos serán apagados.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.EDIT_SUBSCRIPTION]: {
      title: "Editar suscripción",
      actionBtn: "Guardar",
    },
    [MspWorkspaceAction.STOP_SUBSCRIPTION]: {
      title: "¿Detener la suscripción?",
      description:
        "Detener su suscripción y acuerdo de facturación con Coro. La protección se desactivará para sus usuarios y dispositivos.",
      actionBtn: "Sí",
    },
    [MspWorkspaceAction.EDIT_SUBSCRIPTION_CONFIRMATION]: {
      title: "¿Deshabilitar módulos?",
      description:
        "Deshabilitar los módulos <b>{removedModules}</b>. Los tickets relacionados con estos módulos se cerrarán automáticamente.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.EXTEND_TRIAL]: {
      title: "¿Extender prueba?",
      description: "Seleccione una nueva fecha de expiración para esta prueba",
      expirationDate: "Fecha de expiración de la prueba",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.START_SUBSCRIPTION]: {
      title: "¿Iniciar suscripción?",
      description: "Inicie su suscripción y acuerdo de facturación con Coro.",
      actionBtn: "Sí",
    },
    [MspWorkspaceAction.ARCHIVE_WORKSPACE]: {
      title: "¿Archivar espacio de trabajo?",
      description: "De ahora en adelante, este espacio de trabajo será archivado.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.UNARCHIVE_WORKSPACE]: {
      title: "¿Restaurar espacio de trabajo?",
      description:
        "Su espacio de trabajo archivado será restaurado. Antes de poder usarlo, primero debe reconectar sus aplicaciones en la nube, agregar usuarios para protección y reconfigurar su configuración de protección.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.CONVERT]: {
      title: "Convertir espacio de trabajo",
      selectTypeLabel: "Seleccione el tipo al que le gustaría convertir su espacio de trabajo",
      selectChannelLabel:
        "Seleccione el espacio de trabajo del canal que debería ser utilizado como padre",
      actionBtn: "Convertir",
      convertToChannelDescription:
        "¿Está seguro de que desea convertir este espacio de trabajo a Canal?",
      convertToRegular: "Convertir a espacio de trabajo regular",
      changeParent: "Cambiar espacio de trabajo padre",
      changeChannelParent: "Cambiar espacio de trabajo padre",
      convertToChannel: "Convertir a espacio de trabajo de canal",
      convertToChild: "Convertir a espacio de trabajo hijo",
      workspacesAutocomplete: {
        label: "Espacio de trabajo",
        placeholder: "Seleccionar espacio de trabajo",
      },
      descendantConversionOptionsTitle:
        "Su espacio de trabajo de canal puede tener espacios de trabajo hijos, seleccione a continuación qué debería hacerse con ellos",
      descendantConversionOptions: {
        convertToRegular:
          "Convertir todos los espacios de trabajo hijos a regulares y mover solo este canal",
        cascadeMigration:
          "Mover espacios de trabajo hijos junto con este canal bajo un nuevo padre",
      },
    },
    [MspWorkspaceAction.CREATE_CHILD_WORKSPACE]: {
      title: "Crear un espacio de trabajo hijo",
      actionBtn: "Crear espacio de trabajo",
    },
    [MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE]: {
      title: "Crear un espacio de trabajo de canal",
      actionBtn: "Crear espacio de trabajo",
    },
    [MspWorkspaceAction.CREATE_REGULAR_WORKSPACE]: {
      title: "Crear un espacio de trabajo regular",
      actionBtn: "Crear espacio de trabajo",
    },
    [ThirdPartyAppsAction.BLOCK_APP]: {
      actionBtn: "Sí, bloquear",
      notifyUsersMessage: "Notificar a los usuarios que están usando esta aplicación bloqueada",
      body: "Esta acción bloqueará <b>{appName}</b> de acceder a los datos de sus usuarios en <b>{cloudAppName}</b>. La aplicación será prohibida y el acceso futuro será revocado automáticamente.",
      title: "¿Bloquear {appName}?",
    },
    [EmailProxyAction.ADD_DOMAIN]: {
      title: "Agregar dominio al proxy {direction}",
      inboundDescription:
        "El proxy SMTP de Coro procesa correos electrónicos enviados a cada uno de sus dominios comerciales que son recibidos de uno de los relay SMTP asociados con ese dominio.",
      outboundDescription: "lorem ipsum",
      domain: {
        label: "Dominio",
        placeholder: "Ingrese el nombre del dominio...",
      },
      smtpProxy: "Proxy SMTP Relay",
      host: {
        title: "Host",
        placeholder: "Ingrese el host...",
      },
      ip: {
        placeholder:
          "Ingrese IPs (o rangos de IP en notación CIDR) de los servidores SMTP permitidos",
      },
      port: "Puerto",
      actionBtn: "Agregar",
    },
    [EmailProxyAction.EDIT_DOMAIN]: {
      title: "Editar dominio {direction}",
      actionBtn: "Guardar",
    },
    [EmailProxyAction.REMOVE_DOMAIN]: {
      title: "¿Eliminar dominio?",
      description: "Coro dejará de procesar correos electrónicos enviados a <b>{name}</b>.",
      actionBtn: "Sí, eliminar",
    },
    [EmailProxyAction.TEST_DOMAIN]: {
      title: "Probar proxy",
      description: "Probar que el proxy está establecido correctamente",
      mailTo: {
        title: "Correo a",
        placeholder: "Correo a...",
      },
      actionButton: "Enviar correo electrónico de prueba",
      emailSentMessage:
        "Se envió una verificación de correo electrónico a {email}. Por favor, haga clic en el enlace en el correo electrónico para finalizar la prueba.",
      proxyFailedMessage:
        "<b>La prueba del proxy falló</b>. Por favor, asegúrese de que el proxy esté establecido como se indica en la documentación.",
    },
    [WebhookAction.ADD]: {
      title: "Crear webhook",
      actionBtn: "Crear",
      generalInfo: {
        title: "Información general",
        name: "Nombre",
        description: "Descripción",
      },
      authentication: {
        title: "Autenticación",
        url: "URL",
        secret: "Secreto",
        authorization: "Autorización",
      },
      triggers: {
        title: "Disparador",
      },
      additionalConfiguration: {
        applyToChildWorkspace: "Aplicar al espacio de trabajo hijo",
      },
    },
    [WebhookAction.EDIT]: {
      title: "Editar webhook",
      actionBtn: "Guardar",
    },
    [WebhookAction.REMOVE]: {
      title: "¿Eliminar webhook?",
      description: "¿Está seguro de que desea eliminar este webhook?",
      actionBtn: "Sí, eliminar",
    },
    [WebhookAction.DEACTIVATE]: {
      title: "¿Desactivar webhook?",
      description: "¿Está seguro de que desea desactivar este webhook?",
      actionBtn: "Sí, desactivar",
    },
    [WebhookAction.ACTIVATE]: {
      title: "¿Habilitar webhook?",
      description: "¿Está seguro de que desea habilitar este webhook?",
      actionBtn: "Sí, habilitar",
    },
    [WebhookAction.ACTION_REQUIRED]: {
      description:
        'Una nueva versión de disparador para <b>{name}</b> está disponible. Para mantener su webhook activo, seleccione <b>Editar</b> en el menú de tres puntos y configúrelo antes de {expirationDate}. <b>Después de esta fecha, este webhook dejará de funcionar.</b><br> <span style="line-height: 2">Actualice a la nueva versión de disparador para garantizar la funcionalidad continua.</span> | Una nueva versión de disparador para <b>{name}</b> está disponible. Para mantener su webhook activo, seleccione <b>Editar</b> en el menú de tres puntos y configúrelo antes de {expirationDate}. <b>Después de esta fecha, estos webhooks dejarán de funcionar.</b><br> <span style="line-height: 2">Actualice a la nueva versión de disparador para garantizar la funcionalidad continua.</span>',
      actionBtn: "Configurar webhook",
      cancelBtn: "Ignorar",
    },
    [WebhookAction.CANNOT_ACTIVATE]: {
      title: "El webhook no se puede reactivar",
      description:
        'El webhook no se puede activar porque está utilizando un disparador obsoleto. <br> <span style="line-height: 2">Actualice a la nueva versión de disparador para reactivarlo.</span>',
      actionBtn: "Configurar webhook",
      cancelBtn: "Cerrar",
    },
    createWorkspace: {
      chooseYourSubscription: "Elige tus suscripciones",
      recommendedModules: "Módulos recomendados",
      addons: "Complementos",
      includedInBundles: "Incluido en tus paquetes",
      includedInBundle: "Incluido en el paquete",
      companyDetails: "Detalles de la empresa",
      brandingSettings: "Configuraciones de marca",
      replaceCoroIcons: "Reemplazar íconos de Coro",
      promotionalCodes: "Códigos promocionales",
      reviewSubscription: "Revisar tu suscripción",
      advancedSettings: "Configuraciones avanzadas",
      workspaceSettings: "Configuraciones del espacio de trabajo",
      descriptions: {
        createChannelMspWorkspace:
          "Al crear un espacio de trabajo, se enviará una invitación al correo electrónico del representante.",
        createChildMspWorkspace:
          "Se enviará una invitación a este administrador del espacio de trabajo. Tendrás el rol de administrador para esta cuenta.",
        createRegularWorkspace:
          "Se enviará una invitación a este administrador del espacio de trabajo. Tendrás el rol de administrador para esta cuenta.",
      },
      workspaceCreated: "{workspace} ha sido creado con éxito",
      inviteSent: "Se envió una invitación a {email}",
      mspAdminExists:
        "Ten en cuenta que la cuenta del administrador {email} ya existe como administrador de MSP en el espacio de trabajo padre. Por lo tanto, esta cuenta no se añadirá como administrador a tu espacio de trabajo, pero aún podrá gestionar tu espacio de trabajo y sus descendientes según los permisos del rol de MSP.",
      requestInProgress: "Por favor espera. Estamos procesando tu solicitud",
      requestFailed:
        "Algo salió mal durante la creación del espacio de trabajo. Por favor, inténtalo de nuevo más tarde.",
      companyName: "Nombre de la empresa",
      domain: "Nombre de dominio",
      parentWorkspace: "Espacio de trabajo padre",
      domainPlaceholder: "Nombre de dominio (ej. “customerdomain.com”)",
      domainHint: "Por favor ingresa el dominio completo del correo electrónico del cliente",
      displayName: "Nombre para mostrar",
      displayNameHint: "Máx. 30 caracteres",
      limitProtectedUsers: "Limitar usuarios protegidos (opcional)",
      limitProtectedUsersPlaceholder: "Opcional: limitar el número de usuarios protegidos",
      limitProtectedUsersHint:
        "Si se limita, el cliente no podrá agregar más de la cantidad especificada de usuarios a la protección de Coro.",
      adminEmail: "Correo electrónico del administrador",
      adminEmailOptional: "Correo electrónico del administrador (opcional)",
      adminEmailPlaceholder: "Correo del administrador del cliente",
      adminEmailHint:
        "Se invitará al administrador del cliente al espacio de trabajo. También puedes hacerlo más tarde desde dentro del espacio de trabajo.",
      notifications: "Notificaciones",
      imageError: "La imagen no cumple con las especificaciones",
      headerIcon: "Ícono de encabezado",
      replaceImage: "Reemplazar",
      imageHint: "Ícono cuadrado blanco sobre fondo transparente. 500KB máx. png, svg",
      promoCodes: {
        label: "Código promocional",
        placeholder: "Ingresa el código de configuración del espacio de trabajo",
        description:
          "Completa el código del espacio de trabajo para ayudar al equipo de Coro a configurar tu espacio de trabajo",
      },
      language: {
        label: "Idioma predeterminado de la plataforma",
        placeholder: "Idioma predeterminado de la plataforma",
      },
      notificationLevels: {
        [WorkspaceType.CHANNEL]: {
          [NotificationsLevel.NONE]: "Ninguna",
          [NotificationsLevel.PARENT]:
            "Solo a los administradores MSP de <b>este</b> espacio de trabajo",
          [NotificationsLevel.ALL]: "Administradores locales y de MSP",
          [NotificationsLevel.LOCAL_ADMINS]: "Solo a los administradores locales",
          subchannelParent: "Solo a los administradores MSP del espacio de trabajo <b>superior</b>",
        },
        [WorkspaceType.CHILD]: {
          [NotificationsLevel.NONE]: "Ninguna",
          [NotificationsLevel.PARENT]:
            "Solo a los administradores MSP del espacio de trabajo <b>superior</b>",
          [NotificationsLevel.ALL]: "Administradores locales y de MSP",
          [NotificationsLevel.LOCAL_ADMINS]: "Solo a los administradores locales",
        },
        [WorkspaceType.REGULAR]: {
          [NotificationsLevel.NONE]: "Ninguna",
          [NotificationsLevel.PARENT]:
            "Solo a los usuarios administradores de <b>{domain}</b> y usuarios de <b>este</b> espacio de trabajo",
          [NotificationsLevel.ALL]: "Todos los usuarios administradores",
        },
      },
      actionBtn: "Crear espacio de trabajo",
      edrEnabled: "Detección y Respuesta de Endpoint Habilitada",
      psaEnabled: "PSA Habilitado",
      showSupportLink: "Mostrar enlace de contacto con soporte",
      scanUnprotectedUsers: "Escanear usuarios no protegidos",
      showDisabledModules: "Mostrar módulos y complementos deshabilitados",
      coroEmployeeWorkspace: "Espacio de trabajo de empleados de Coro",
      branding: {
        title: "Configuración de marca",
        aliasLabel: "Subdominio",
        aliasHint: "Máx. 40 caracteres",
        aliasFullDomainHint: "La dirección de dominio completo será {domain}.coro.net",
        supportEmailLabel: "Correo de soporte",
        supportEmailHint: "Máx. 100 caracteres",
        noReplyEmailLabel: "Correo sin respuesta",
        noReplyEmailHint: "El correo sin respuesta se genera automáticamente del campo Subdominio",
        brandColorLabel: "Color de marca",
        brandColorHint: "Usa un formato de color HEX válido (#262260)",
        companyAddressLabel: "Dirección de la empresa",
        companyAddressHint:
          "El nombre y la dirección de la empresa aparecerán en el pie de página de los correos enviados desde el correo sin respuesta de marca",
      },
      managed: "Gestionado",
      unmanaged: "No gestionado",
      summary: {
        modulesAndAddons: "Módulos y complementos",
        bundles: "Paquetes",
        title: "Resumen de suscripción",
        userMonth: "/ usuario / mes.",
        billedAnnually: "Facturado anualmente*",
        [SubscriptionBundle.CORO_CLASSIC]: {
          title: "Coro Clásico",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.CORO_COMPLETE]: {
          title: "Coro Completo",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_CORO_COMPLETE]: {
          title: "Coro Completo",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.MANAGED_CORO_CLASSIC]: {
          title: "Coro Clásico",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.ENDPOINT_PROTECTION]: {
          title: "Protección de Endpoint",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: {
          title: "Protección de Endpoint",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.SASE]: {
          title: "SASE",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_SASE]: {
          title: "SASE",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.EMAIL_PROTECTION]: {
          title: "Protección de Correo Electrónico",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: {
          title: "Protección de Correo Electrónico",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: {
          title: "Coro Esenciales",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.CORO_ESSENTIALS]: {
          title: "Coro Esenciales",
          subtitle: "No gestionado",
        },
        [SubscriptionModule.EMAIL_SECURITY]: {
          title: "Seguridad de Correo Electrónico",
        },
        [SubscriptionModule.NETWORK]: {
          title: "Red",
        },
        [SubscriptionModule.USER_DATA_GOVERNANCE]: {
          title: "Gobernanza de Datos del Usuario",
        },
        [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
          title: "Gobernanza de Datos de Endpoint",
        },
        [SubscriptionModule.MDM]: {
          title: "Gestión de Dispositivos Móviles",
        },
        [SubscriptionModule.ENDPOINT_SECURITY]: {
          title: "Seguridad de Endpoint",
        },
        [SubscriptionModule.CLOUD_SECURITY]: {
          title: "Seguridad en la Nube",
        },
        [SubscriptionModule.EDR]: {
          title: "EDR",
        },
        [SubscriptionAddon.WIFI_PHISHING]: {
          title: "Phishing en Wi-Fi",
        },
        [SubscriptionAddon.SECURED_MESSAGES]: {
          title: "Mensajes Seguros",
        },
        [SubscriptionAddon.INBOUND_GATEWAY]: {
          title: "Puerta de Enlace Entrante",
        },
        [SubscriptionAddon.SWG]: {
          title: "Puerta de Enlace Web Segura",
        },
        [SubscriptionAddon.ZTNA]: {
          title: "Acceso a Red de Confianza Cero",
        },
      },
    },
    [MspWorkspaceAction.EDIT_WORKSPACE]: {
      titles: {
        channel: "Editar información del espacio de trabajo del canal",
        child: "Editar información del espacio de trabajo hijo",
        regular: "Editar información del espacio de trabajo",
      },
      companyName: "Nombre de la empresa",
      displayName: "Nombre para mostrar",
      displayNameHint: "Máximo 30 caracteres",
      limitProtectedUsersPlaceholder: "Opcional: límite de usuarios protegidos",
      limitProtectedUsersHint:
        "Si se establece un límite, el cliente no podrá añadir más usuarios de los especificados a la protección de Coro.",
      limitProtectedUsers: "Límite de usuarios protegidos (opcional)",
      notifications: "Notificaciones",
      imageError: "La imagen no cumple con las especificaciones",
      headerIcon: "Icono de cabecera",
      replaceImage: "Reemplazar",
      deleteWorkspace: "Eliminar espacio de trabajo",
      imageHint: "Icono cuadrado blanco con fondo transparente. Máx. 500 KB. png, svg",
      notificationLevels: {
        [NotificationsLevel.NONE]: "Ninguna",
        [NotificationsLevel.PARENT]: "Solo a los usuarios administradores del canal",
        [NotificationsLevel.ALL]: "Todos los usuarios administradores",
        [NotificationsLevel.LOCAL_ADMINS]: "Solo a administradores locales",
      },
      actionBtn: "Guardar",
      edrEnabled: "Detección y Respuesta en el Endpoint habilitada",
      psaEnabled: "PSA habilitado",
      branding: {
        title: "Configuración de Marca",
        aliasLabel: "Subdominio",
        aliasHint: "Máximo 40 caracteres",
        aliasFullDomainHint: "La dirección completa del dominio será {domain}.coro.net",
        supportEmailLabel: "Correo de Soporte",
        supportEmailHint: "Máximo 100 caracteres",
        noReplyEmailLabel: "Correo No-Responder",
        noReplyEmailHint:
          "El correo No-Responder se genera automáticamente desde el campo Subdominio",
        brandColorLabel: "Color de la Marca",
        brandColorHint: "Usa un formato de color HEX válido (#262260)",
        companyAddressLabel: "Dirección de la empresa",
        companyAddressHint:
          "El nombre y dirección de la empresa aparecerán en el pie de página de los correos enviados desde el correo No-Responder con marca",
      },
    },
    [MspAdminUsersAction.ADD]: {
      title: "Agregar usuario administrador MSP",
      email: {
        placeholder: "Seleccionar usuario administrador",
        label: "Usuario administrador",
      },
      role: {
        placeholder: "Seleccionar rol",
        label: "Rol",
      },
      workspace: {
        placeholder: "Seleccionar espacio de trabajo",
        label: "Espacio de trabajo",
        hint: "Seleccione el espacio de trabajo para agregar el usuario administrador y el rol",
      },
      actionBtn: "Guardar",
      errors: {
        general: "Por favor corrija los errores a continuación",
        restriction: "Por favor seleccione el tipo de permiso",
      },
    },
    [MspAdminUsersAction.EDIT]: {
      title: "Editar usuario administrador MSP",
      actionBtn: "Guardar",
    },
    [MspAdminUsersAction.DELETE]: {
      title: "¿Eliminar usuario administrador MSP?",
      description:
        "<span class='text--semibold'>{email}</span> ya no tendrá acceso a los espacios de trabajo hijos.",
      actionBtn: "Sí, eliminar",
    },
    [GlobalAdminUsersAction.ADD]: {
      title: "Agregar usuario administrador global",
      actionBtn: "Guardar",
    },
    [GlobalAdminUsersAction.EDIT]: {
      title: "Editar usuario administrador global",
      actionBtn: "Guardar",
    },
    [GlobalAdminUsersAction.DELETE]: {
      title: "¿Eliminar usuario administrador global?",
      description:
        "<span class='text--semibold'>{email}</span> ya no tendrá acceso a los espacios de trabajo hijos.",
      actionBtn: "Guardar",
    },
    [MspAdminUsersAction.EDIT]: {
      title: "Editar usuario administrador global",
      actionBtn: "Guardar",
    },
    [MspAdminUsersAction.DELETE]: {
      title: "¿Eliminar usuario administrador global?",
      description:
        "<span class='text--semibold'>{email}</span> ya no tendrá acceso a los espacios de trabajo hijos.",
      actionBtn: "Sí, eliminar",
    },
    [ExportAction.EXPORT_ACTIVITY_LOGS]: {
      title: "Nueva exportación de registros de actividad",
      disclaimer: "Los datos de los últimos 90 días se incluirán por defecto",
      subtitle: "Actividades",
      choices: {
        [ExportChoice.ALL]: "Todas las actividades",
        [ExportChoice.SPECIFIC]: "Actividades específicas",
      },
      actionBtn: "Generar",
    },
    [ExportAction.EXPORT_PROTECTED_USERS]: {
      title: "Nueva exportación de usuarios protegidos",
      subtitle: "Usuarios protegidos",
      choices: {
        [ExportChoice.ALL]: "Todos los usuarios protegidos",
        [ExportChoice.SPECIFIC]: "Usuarios protegidos específicos",
      },
      actionBtn: "Generar",
    },
    [ExportAction.EXPORT_DEVICES]: {
      title: "Nueva exportación de dispositivos",
      subtitle: "Dispositivos",
      choices: {
        [ExportChoice.ALL]: "Todos los dispositivos",
        [ExportChoice.SPECIFIC]: "Dispositivos específicos",
      },
      actionBtn: "Generar",
    },
    [ExportAction.EXPORT_TICKETS]: {
      title: "Nueva exportación de registros de tickets",
      disclaimer: "Los datos de los últimos 90 días se incluirán por defecto",
      subtitle: "Tickets",
      choices: {
        [ExportChoice.ALL]: "Todos los tickets",
        [ExportChoice.SPECIFIC]: "Tickets específicos",
      },
      actionBtn: "Generar",
    },
    [SecureWebGatewayAction.TOGGLE_DNS_FILTERING]: {
      title: "Habilitar filtrado DNS",
      description:
        "<b>¡Debes leer esto!</b> <br> <br> Activar el filtrado DNS bloquea el acceso a recursos externos sospechosos o explícitamente bloqueados desde tu oficina virtual.",
      actionBtn: "Sí, habilitar",
    },
    [SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_ALLOW_LIST]: {
      title: "¿Deshabilitar lista de URL?",
      description: "Las URL de <b>{item}</b> ya no serán permitidas.",
      actionBtn: "Sí, deshabilitar",
    },
    [SecureWebGatewayAction.DISABLE_URL_GROUP_BLOCK_LIST]: {
      title: "¿Deshabilitar lista de URL?",
      description: "Las URL de <b>{name}</b> ya no serán bloqueadas.",
      actionBtn: "Sí, deshabilitar",
    },
    [SecureWebGatewayAction.ENABLE_URL_GROUP_BLOCK_LIST]: {
      title: "¿Habilitar lista de URL?",
      description: "Las URL de <b>{name}</b> serán bloqueadas.",
      actionBtn: "Sí, habilitar",
    },
    [SecureWebGatewayAction.ENABLE_URL_GROUP_ALLOW_LIST]: {
      title: "¿Habilitar lista de URL?",
      description: "Las URL de <b>{name}</b> serán permitidas.",
      actionBtn: "Sí, habilitar",
    },
    [SecureWebGatewayAction.REMOVE_FROM_ALLOWLIST]: {
      title: "¿Eliminar de la lista permitida?",
      description: "<b>{item}</b> ya no será permitido.",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.DISABLE_URL_GROUP_ALLOW_LIST]: {
      title: "¿Deshabilitar lista de URL?",
      description: "Las URL de <b>{name}</b> ya no serán permitidas.",
      actionBtn: "Sí, deshabilitar",
    },
    [SecureWebGatewayAction.REMOVE_FROM_BLOCKLIST]: {
      title: "¿Eliminar de la lista de bloqueo?",
      description: "<b>{item}</b> ya no será bloqueado.",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_BLOCK_LIST]: {
      title: "¿Eliminar de la lista de bloqueo?",
      description: "Las URL de <b>{item}</b> ya no serán bloqueadas.",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_ALLOW_LIST]: {
      title: "¿Eliminar de la lista permitida?",
      description: "Las URL de <b>{item}</b> ya no serán permitidas",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.ADD_URL_TO_ALLOWLIST]: {
      actionBtn: "Agregar a la lista",
    },
    allowBlockListSwg: {
      allowListTitle: "Agregar a la lista permitida",
      blockListTitle: "Agregar a la lista de bloqueo",
      recordType: "Tipo de registro",
      addCustomCategory: "Agregar categoría personalizada",
      addSpecificUrl: "Agregar URL específica",
      uploadCategoryCsv: "Subir archivo de categoría",
      actionBtn: "Agregar a la lista",
      nicknamePlaceholder: "Ingresar apodo de la categoría…",
      nicknameLabel: "Apodo",
      linkLabel: "URL",
      linkPlaceholder: "Enlace a la lista de URLs externas",
      urlLabel: "URLs",
      urlPlaceholder: "Ingresar URLs...",
      uploadFailed: "Carga fallida",
      incorrectFileExtension: "Extensión de archivo incorrecta",
      fileMissing: "Agregar un archivo",
      fileSizeError: "El archivo es demasiado grande. Suba un archivo que sea menor de 1 MB",
    },
    [VirtualOfficeAction.SETUP]: {
      title: "Confirma tu selección",
      description:
        "¡La selección de región es un proceso único durante la configuración! Para cambiar la región después de la configuración, contacta al soporte.",
      selectedRegion: "Región seleccionada:",
      confirmInputLabel: "Escribe APROBAR para confirmar:",
      actionBtn: "Confirmar",
    },
    [VirtualOfficeAction.SELECT_PREDEFINED_VPN_LABEL]: {
      title: "Confirma tu selección",
      description:
        "Asignar etiquetas predeterminadas potencialmente expone tu red a riesgos de seguridad. Los dispositivos añadidos a esta etiqueta ganan acceso automáticamente. <br> Recomendamos crear etiquetas manuales para supervisar y gestionar los dispositivos asignados.",
      selectedLabelConfirmation: "¿Estás seguro de que deseas asignar esta etiqueta?",
      selectedLabel: "Etiqueta seleccionada: ‘{name}’",
      actionBtn: "Confirmar",
    },
    [VirtualOfficeAction.TOGGLE_ENCRYPTION_STRENGTH]: {
      title: "¿Cambiar la fuerza de cifrado?",
      description:
        "Cambiar la fuerza de cifrado desconecta tu oficina virtual de Internet por hasta dos minutos. Recomendamos realizar este cambio fuera del horario laboral.",
      selectedLabelConfirmation: "¿Estás seguro de que deseas asignar esta etiqueta?",
      selectedLabel: "Etiqueta seleccionada: ‘{name}’",
      actionBtn: "Sí, cambiar",
    },
    [VirtualOfficeAction.VIEW_ALL_IPS]: {
      title: "Lista de direcciones IP",
      description: "{n} direcciones IP",
      actionBtn: "Cerrar",
    },
    [VirtualOfficeAction.REMOVE_FROM_INCLUDELIST]: {
      title: "¿Eliminar de la lista incluida?",
      description: "<b>{item}</b> ya no será incluido.",
      actionBtn: "Sí, eliminar",
    },
    [VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST]: {
      title: "¿Eliminar de la lista excluida?",
      description: "<b>{item}</b> ya no será excluido.",
      actionBtn: "Sí, eliminar",
    },
    [VirtualOfficeAction.ADD_URL_TO_URL_LIST]: {
      includeListTitle: "Agregar a la lista incluida",
      excludedListTitle: "Agregar a la lista excluida",
      label: "Ingresar dominios o direcciones IP...",
      placeholder: "Ingresar dominio/s y/o IPs…",
      actionBtn: "Agregar a la lista",
    },
    [SiteToSiteTunnelsAction.EDIT]: {
      title: "Editar túnel",
      actionBtn: "Guardar",
    },
    [SiteToSiteTunnelsAction.DELETE]: {
      title: "Eliminar túnel",
      description: "¿Te gustaría eliminar este túnel?",
      actionBtn: "Sí",
    },
    [SiteToSiteTunnelsAction.TEST]: {
      title: "Registro de prueba de túnel",
      actionBtn: "Cerrar",
    },
    [SiteToSiteTunnelsAction.ADD]: {
      title: "Agregar túnel",
      actionBtn: "Guardar",
      formTitle: "Detalles del sitio",
      inputs: {
        siteName: {
          placeholder: "Ingrese el nombre del sitio…",
          label: "Nombre del sitio",
        },
        siteDescription: {
          placeholder: "Ingrese la descripción del sitio...",
          label: "Descripción del sitio",
        },
        remoteGatewayIp: {
          placeholder: "Ingrese la IP pública del gateway remoto...",
          label: "IP pública del gateway remoto",
        },
        remoteNetworkIps: {
          subtitle: "Red de subred interna remota",
          placeholder: "IP de red interna / Máscara de red",
          label: "IP de red interna / Máscara de red",
          add: "+ Agregar red interna remota",
        },
        presharedKey: {
          placeholder: "Ingrese la clave precompartida...",
          label: "Clave precompartida",
          generateKey: "Generar clave",
        },
        lifetimeKey: {
          placeholder: "Ingrese la clave de tiempo de vida...",
          label: "Clave de tiempo de vida (en seg)",
        },
      },
      detailsTitle: "Oficina virtual",
      gatewayIp: "IP del gateway",
      subnet: "Subred",
      settings: {
        title: "Configuración del firewall",
        firewallType: "Tipo de firewall",
        remoteNetworkMask: "Máscara de red remota",
        ikeVersion: "Versión IKE",
        phase1Encryption: "Cifrado de fase 1",
        phase2Encryption: "Cifrado de fase 2",
        aggressiveMode: "Modo agresivo",
      },
      copySuccessMessage: "Clave precompartida copiada al portapapeles",
    },
    [ScheduledReportsAction.ADD_EXPORT]: {
      title: "Nueva exportación programada",
      actionBtn: "Programar",
      frequency: "Frecuencia",
      scope: "Ámbito",
      recipients: "Destinatarios",
      exportType: "Tipo de exportación",
      recipientsChoice: {
        allAdminUsers: "Todos los usuarios administradores del espacio de trabajo",
        specificAdminUsers: "Usuarios administradores específicos",
      },
      name: {
        label: "Nombre de la exportación",
      },
    },
    [ScheduledReportsAction.ADD_REPORT]: {
      title: "Nuevo informe programado",
      actionBtn: "Programar",
      frequency: "Frecuencia",
      scope: "Ámbito",
      recipients: "Destinatarios",
      reportType: "Tipo de informe",
      recipientsChoice: {
        allAdminUsers: "Todos los usuarios administradores del espacio de trabajo",
        specificAdminUsers: "Usuarios administradores específicos",
      },
      name: {
        label: "Nombre del informe",
      },
    },
    [ScheduledReportsAction.DELETE]: {
      title: "¿Eliminar informe programado?",
      actionBtn: "Sí, eliminar",
      description: "Los informes ya no serán enviados.",
    },
    [ScheduledReportsAction.EDIT_EXPORT]: {
      title: "Editar exportación programada",
      actionBtn: "Programar",
    },
    [ScheduledReportsAction.EDIT_REPORT]: {
      title: "Editar informe programado",
      actionBtn: "Programar",
    },
    [BillingAction.UPDATE_SUBSCRIPTION]: {
      title: "Actualizar plan",
      actionBtn: "Guardar",
    },
    [TicketsModal.IPS_MODAL]: {
      title: "Direcciones IP",
    },
    [TicketsModal.DLP_FINDINGS_MODAL]: {
      title: "Hallazgos",
      type: "<div class='v-row body2'><div class='v-col-3'>Tipo:</div> <div class='v-col-9'>{type}</div></div>",
      quote:
        "<div class='v-row body2'><div class='v-col-3'>Cita:</div> <div class='v-col-9'><span class='text-orange-base'>&quot;{quote}&quot;</span></div></div>",
      quotePlain:
        "<div class='v-row body2'><div class='v-col-3'>Cita:</div> <div class='v-col-9'><span class='text-orange-base'>{quote}</span></div></div>",
      fileName:
        "<div class='v-row body2'><div class='v-col-3'>Nombre del archivo:</div> <div class='v-col-9'><span>{fileName}</span></div></div>",
      virusName:
        "<div class='v-row body2'><div class='v-col-3'>Nombre del virus:</div> <div class='v-col-9'><span>{virusName}</span></div></div>",
      longQuote:
        "<div class='v-row body2'><div class='v-col-3'>Cita:</div> <div class='v-col-9'>&quot;{textBeforeHighlight}<span class='text-orange-base'>{highlightedText}</span>{textAfterHighlight}&quot;</div></div>",
      longQuoteWithoutHighlight:
        "<div class='v-row body2'><div class='v-col-3'>Cita:</div> <div class='v-col-9'>&quot;{longQuote}&quot;</div></div>",
      archivePath:
        "<div class='v-row body2'><div class='v-col-3'>Ruta de archivo:</div> <div class='v-col-9'><b>{archive}</b> &rarr; {path}<b>{file}</b></div></div>",
    },
    [TicketsModal.RECIPIENTS]: {
      title: "{n} destinatarios de correo electrónico",
    },
    [TicketsModal.USERS]: {
      title: "{n} usuarios",
    },
    [TicketsModal.BSSID_MODAL]: {
      title: "BSSID's",
    },
    [TicketsModal.SHARED_WITH]: {
      title: "Compartido con",
    },
    [CommentAction.ADD_COMMENT]: {
      title: "Nuevo comentario",
      label: "Comentario",
      placeholder: "Ingresar texto",
      notifyAffectedUsers: "Notificar a los usuarios afectados",
      notifyAdmins: "Notificar a todos los administradores del espacio de trabajo",
      notifyCustomRecipients: "Notificar a destinatarios personalizados",
      notifySocContacts: "Notificar a contactos del servicio gestionado",
      notifyAllWorkspaceAdmins: "Notificar a todos los administradores del espacio de trabajo",
      notifyCoroAdmins: "Notificar a todos los administradores de Coro",
      notifySpecificCoroAdmins: "Notificar a administradores específicos de Coro",
      actionBtn: "Comentar",
    },
    [CommentAction.BULK_ADD_COMMENT]: {
      title: "Nuevo comentario",
      actionBtn: "Comentar",
    },
    [TicketAction.APPROVE_EMAIL_WITH_OPTIONS]: {
      title: "Permitir",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_EMAIL_WITH_OPTIONS]: {
      title: "Bloquear",
      actionBtn: "Confirmar",
    },
    [TicketAction.CONTACT_USER]: {
      title: "Contactar usuario",
      description: "Usuario | Usuarios",
      actionBtn: "Enviar",
      more: "+ {n} más",
      placeholder: "Agregar mensaje",
      includeEventDetails: "Incluir detalles del ticket en el mensaje",
    },
    [TicketAction.REMOVE_EXPOSING_SHARING]: {
      title: "¿Eliminar exposición?",
      description:
        "Todas las comparticiones con personas fuera de su organización serán eliminadas.",
      actionBtn: "Eliminar",
    },
    [TicketAction.ADD_TO_DATA_GOVERNANCE_PERMISSIONS]: {
      title: "¿Ampliar permisos de gobernanza de datos?",
      description:
        "El usuario {violationUsers} estará autorizado para {violationDirection} datos que contienen {violationTrigger}. | El usuario {violationUsers} estará autorizado para {violationDirection} datos que contienen {violationTrigger}.",
      share: "Acceso&Exponer",
      access: "Acceso",
      actionBtn: "Confirmar",
    },
    [TicketAction.GENERAL_APPROVE]: {
      title: "Permitir",
      description: "El remitente/dominio/dirección IP/rango será eliminado de la lista de bloqueo.",
      specificDescription: {
        crowdblockedSender:
          "El remitente/dominio/dirección IP/rango de la lista de bloqueo global será permitido por contenido sospechoso.",
        blocklistedSender:
          "El remitente/dominio/dirección IP/rango será eliminado de la lista de bloqueo por contenido sospechoso.",
        missingRequiredAuthentication:
          "El remitente/dominio/dirección IP/rango será eliminado de la lista de bloqueo por fallas de autenticación.",
      },
      proxy:
        "Este correo electrónico será restaurado a las bandejas de entrada de los destinatarios.",
      actionBtn: "Confirmar",
    },
    [TicketAction.APPROVE_EMAILS_FROM_DOMAIN]: {
      title:
        "¿Agregar todos los remitentes de {name} a la lista de permitidos? | ¿Agregar todos los remitentes de los dominios seleccionados a la lista de permitidos?",
      description:
        "A partir de ahora, todos los correos electrónicos de <b>{name}</b> serán aprobados automáticamente. | A partir de ahora, todos los correos electrónicos de los dominios seleccionados serán aprobados automáticamente.",
      actionBtn: "Confirmar",
    },
    [TicketAction.ENCRYPT_DRIVE]: {
      title: "¿Cifrar unidad?",
      description: "Esta unidad de disco duro será cifrada.",
      actionBtn: "Sí",
    },
    [TicketAction.DISCARD_EMAILS_FROM_DOMAIN]: {
      title:
        "¿Agregar todos los remitentes de este dominio a la lista de bloqueo? | ¿Agregar todos los remitentes de los dominios seleccionados a la lista de bloqueo?",
      description:
        "A partir de ahora, todos los correos electrónicos de <b>{name}</b> serán eliminados automáticamente. | A partir de ahora, todos los correos electrónicos de los dominios seleccionados serán eliminados automáticamente.",
      error: "El remitente del dominio {domain} no puede ser agregado a la lista de bloqueo.",
      actionBtn: "Confirmar",
    },
    [TicketAction.APPROVE_EMAILS_FROM_SENDER]: {
      title:
        "¿Agregar {name} a la lista de permitidos? | ¿Agregar correos electrónicos seleccionados a la lista de permitidos?",
      description:
        "A partir de ahora, todos los correos electrónicos de <b>{name}</b> serán aprobados automáticamente. | A partir de ahora, todos los correos electrónicos de los remitentes seleccionados serán aprobados automáticamente.",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_EMAILS_FROM_SENDER]: {
      title:
        "¿Agregar este remitente a la lista de bloqueo? | ¿Agregar correos electrónicos seleccionados a la lista de bloqueo?",
      description:
        "A partir de ahora, todos los correos electrónicos de <b>{name}</b> serán eliminados automáticamente. | A partir de ahora, todos los correos electrónicos de los remitentes seleccionados serán eliminados automáticamente.",
      error: "El remitente del dominio {domain} no puede ser agregado a la lista de bloqueo.",
      actionBtn: "Confirmar",
    },
    [TicketAction.APPROVE_EMAIL]: {
      title:
        "¿Tratar el correo electrónico como legítimo? | ¿Tratar los correos electrónicos seleccionados como legítimos?",
      description:
        "Este correo electrónico será restaurado a la bandeja de entrada del destinatario. | Los correos electrónicos seleccionados serán restaurados a las bandejas de entrada de los destinatarios.",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_EMAIL]: {
      title:
        "¿Tratar el correo electrónico como malicioso? | ¿Tratar los correos electrónicos seleccionados como maliciosos?",
      description:
        "Este correo electrónico será descartado. | Los correos electrónicos seleccionados serán descartados.",
      actionBtn: "Confirmar",
    },
    [TicketAction.DOWNLOAD_EML_FILE]: {
      title: "¿Descargar el archivo EML?",
      description:
        "Dado que el correo electrónico ha sido marcado como sospechoso, trate sus adjuntos y enlaces incrustados con especial cuidado.",
      actionBtn: "Confirmar",
    },
    [TicketAction.TREAT_FILE_AS_SAFE]: {
      title: "¿Tratar el archivo como seguro?",
      description:
        "El archivo seleccionado será liberado de la cuarentena (si aplica). En el futuro, archivos idénticos serán considerados seguros y no serán puestos en cuarentena.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXCLUDE_FILE_PATH_AND_CERTIFICATE_FROM_SCAN]: {
      title: "¿Tratar el archivo como seguro?",
      description:
        "En adelante, archivos idénticos al seleccionado serán considerados seguros y, por lo tanto, no serán puestos en cuarentena.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXCLUDE_FILE_PATH_AND_FILE_HASH_FROM_SCAN]: {
      title: "¿Tratar el archivo como seguro?",
      description:
        "En adelante, archivos idénticos al seleccionado serán considerados seguros y, por lo tanto, no serán puestos en cuarentena.",
      actionBtn: "Confirmar",
    },
    [TicketAction.TREAT_FILE_AS_MALICIOUS]: {
      title: "¿Tratar el archivo como malicioso?",
      description:
        "En adelante, archivos idénticos al seleccionado serán considerados maliciosos y, por lo tanto, serán eliminados instantáneamente.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXCLUDE_FOLDER_FROM_SCAN]: {
      title: "¿Excluir carpeta del escaneo de malware?",
      description:
        "En adelante, el contenido de la carpeta seleccionada no será examinado en busca de malware y ransomware.",
      actionBtn: "Excluir",
    },
    [TicketAction.SIGN_IN_TO_SERVICE]: {
      title: "¿Solicitar al usuario que inicie sesión en {serviceName}?",
      description: "Se solicitará al usuario que inicie sesión nuevamente en {serviceName}.",
      actionBtn: "Solicitar",
    },
    [TicketAction.APPROVE_FILE]: {
      title: "Aprobar archivo",
      description:
        "¿Está seguro de que desea restaurar el acceso a este archivo y excluirlo de los escaneos de malware en el futuro?",
      actionBtn: "Sí",
    },
    [TicketAction.DELETE_FILE]: {
      title: "Eliminar archivo",
      description: "¿Está seguro de que desea eliminar este archivo permanentemente?",
      actionBtn: "Eliminar",
    },
    [TicketAction.APPROVE_PROCESS_GROUP]: {
      title: "¿Tratar el grupo de procesos como seguro?",
      description:
        "Grupos de procesos idénticos a este serán considerados seguros y, por lo tanto, no serán terminados.",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_INFECTING_FILE]: {
      title: "¿Tratar el archivo como malicioso?",
      description:
        "En adelante, archivos idénticos a este serán considerados maliciosos y, por lo tanto, serán eliminados instantáneamente.",
      actionBtn: "Confirmar",
    },
    [TicketAction.ENABLE_GATEKEEPER]: {
      title: "Habilitar Gatekeeper",
      description: "¿Aplicar habilitación de Gatekeeper?",
      actionBtn: "Sí",
    },
    [TicketAction.ENABLE_APPLE_MOBILE_FILE_INTEGRITY]: {
      title: "Habilitar la Integridad de Archivos Móviles de Apple",
      description: "¿Aplicar habilitación de la Integridad de Archivos Móviles de Apple?",
      actionBtn: "Sí",
    },
    [TicketAction.EXPORT_MASS_DOWNLOAD_FILES]: {
      title: "Exportar una lista de archivos afectados",
      description:
        "Descargue un archivo de valores separados por comas (CSV) que contenga la lista de archivos afectados en su computadora.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXPORT_MASS_DELETE_FILES]: {
      title: "Exportar una lista de archivos afectados",
      description:
        "Descargue un archivo de valores separados por comas (CSV) que contenga la lista de archivos afectados en su computadora.",
      actionBtn: "Confirmar",
    },
    [TicketAction.ALLOW_PROCESS]: {
      title: "¿Permitir proceso?",
      description: "¿Agregar el proceso a la lista de permitidos?",
      checkboxText: "Cerrar este y otros tickets relacionados con este proceso al confirmar",
      checkboxDescription:
        "El hash de proceso correspondiente será considerado seguro y no generará tickets de EDR.<br>La información relacionada con el proceso no será recopilada en las pestañas de Telemetría y Proceso.",
      actionBtn: "Sí",
    },
    allowBlockEmail: {
      closeAllTicketsInGroup: "Cerrar todos los tickets relacionados",
      actions: {
        default: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y agregar la dirección de correo del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Agregar la dirección de correo del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Agregar el dominio del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este dominio",
          },
          approveEmail: {
            title: "Permitir este correo electrónico únicamente",
          },
          discardEmail: {
            title: "Eliminar permanentemente este correo electrónico únicamente",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Agregar la dirección IP del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de esta dirección IP",
          },
        },
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title: "Agregar la dirección de correo del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title: "Agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Agregar la dirección de correo del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Agregar el dominio del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este dominio",
          },
          approveEmail: {
            title: "Permitir este correo electrónico únicamente",
          },
          discardEmail: {
            title: "Eliminar permanentemente este correo electrónico únicamente",
          },
          approveEmailsFromSenderIp: {
            title: "Agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Agregar la dirección IP del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de esta dirección IP",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y agregar la dirección de correo del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Agregar la dirección de correo del remitente a la lista negra y eliminar permanentemente este correo y bloquear todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Agregar el dominio del remitente a la lista negra y eliminar permanentemente este correo y bloquear todos los futuros correos de este dominio",
          },
          approveEmail: {
            title: "Permitir este correo electrónico únicamente",
          },
          discardEmail: {
            title: "Eliminar permanentemente este correo electrónico únicamente",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Agregar la dirección IP del remitente a la lista negra y eliminar permanentemente este correo y bloquear todos los futuros correos de esta dirección IP",
          },
        },
      },
      proxyActions: {
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title: "Agregar la dirección de correo del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title: "Agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Bloquear la dirección de correo del remitente y bloquear todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Bloquear el dominio del remitente y bloquear todos los futuros correos de este dominio",
          },
          approveEmailsFromSenderIp: {
            title: "Agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Bloquear la dirección IP del remitente y bloquear todos los futuros correos de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir este correo electrónico únicamente",
          },
          discardEmail: {
            title: "Eliminar permanentemente este correo electrónico únicamente",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y agregar la dirección de correo del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Agregar la dirección de correo del remitente a la lista negra y eliminar permanentemente este correo y bloquear todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Agregar el dominio del remitente a la lista negra y eliminar permanentemente este correo y bloquear todos los futuros correos de este dominio",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Agregar la dirección IP del remitente a la lista negra y eliminar permanentemente este correo y bloquear todos los futuros correos de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir este correo electrónico únicamente",
          },
          discardEmail: {
            title: "Eliminar permanentemente este correo electrónico únicamente",
          },
        },
      },
      malwareActions: {
        default: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y agregar la dirección de correo del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Agregar la dirección de correo del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Agregar el dominio del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este dominio",
          },
          approveEmail: {
            title: "Permitir este correo electrónico únicamente",
          },
          discardEmail: {
            title: "Eliminar permanentemente este correo electrónico únicamente",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Agregar la dirección IP del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de esta dirección IP",
          },
        },
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title: "Agregar la dirección de correo del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title: "Agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Agregar la dirección de correo del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Agregar el dominio del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de este dominio",
          },
          approveEmail: {
            title: "Permitir este correo electrónico únicamente",
          },
          discardEmail: {
            title: "Eliminar permanentemente este correo electrónico únicamente",
          },
          approveEmailsFromSenderIp: {
            title: "Agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Agregar la dirección IP del remitente a la lista negra y eliminar permanentemente este correo y todos los futuros correos de esta dirección IP",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title: "Agregar la dirección de correo electrónico del remitente a la lista blanca",
          },
          approveEmailsFromDomain: {
            title: "Agregar el dominio del remitente a la lista blanca",
          },
          discardEmailsFromSender: {
            title:
              "Agregar la dirección de correo electrónico del remitente a la lista negra y eliminar permanentemente todos los futuros correos de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Agregar el dominio del remitente a la lista negra y eliminar permanentemente todos los futuros correos de este dominio",
          },
          approveEmail: {
            title: "Permitir solo este correo",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo",
          },
          approveEmailsFromSenderIp: {
            title: "Agregar la dirección IP del remitente a la lista blanca",
          },
          discardEmailsFromSenderIp: {
            title:
              "Agregar la dirección IP del remitente a la lista negra y eliminar permanentemente todos los futuros correos de esta dirección IP",
          },
        },
      },
    },
    [EmailSettingsAction.ADD_SECURITY_AWARENESS_CONFIGURATION]: {
      addHeaderBtn: "+ Agregar nuevo encabezado",
      actionBtn: "Agregar a la lista",
      title: "Agregar a la lista de permitidos",
      sectionsTitles: {
        details: "Detalles",
        emailAndDomain: "Dominios, IPs o Rangos de IP",
        emailHeader: "Encabezado de correo electrónico",
      },
      labels: {
        name: "Nombre",
        emailAndDomain: "Ingresar dominios o IPs...",
        value: "Valor",
      },
    },
  },
  accessControlPage: {
    title: "Control de Acceso",
    adminUsers: {
      title: "Usuarios Administradores",
      addAdmin: "Agregar Admin",
      grantLoginAccess:
        "Otorgar acceso de inicio de sesión a los agentes de soporte técnico de Coro",
      requireMFA: "Requerir autenticación de dos factores para todos los administradores",
      table: {
        headers: {
          name: "Nombre",
          email: "Correo electrónico",
          status: "Estado",
          mfa: "2FA",
          role: "Rol",
        },
        actions: {
          [AdminUsersAction.CONTENT_INSPECTION]: "Permisos de inspección de contenido",
          [AdminUsersAction.EDIT]: "Editar usuario administrador",
          [AdminUsersAction.DELETE]: "Eliminar usuario administrador",
          [AdminUsersAction.RESEND_INVITE]: "Reenviar invitación",
          [AdminUsersAction.DELETE_MFA]: "Eliminar datos de 2FA",
          [AdminUsersAction.COPY_INVITE_LINK]: "Copiar enlace de invitación",
        },
        you: "Tú",
      },
      status: {
        active: "Activo",
        invited: "Invitado el {date}",
      },
      mfaStatus: {
        none: "Deshabilitado",
        totp: "Habilitado",
      },
      details: {
        title: "{name} — Permisos de Inspección de Contenido",
        description:
          "Utiliza esta página para habilitar permisos para que el usuario administrador nombrado vea el contenido y los hallazgos en correos electrónicos marcados para usuarios protegidos. Ten en cuenta que esto puede exponer información privada y sensible para los negocios, o contenido protegido por ley, política u obligación contractual.",
        connectMore: "Conectar más servicios en la nube ›",
        connectMoreProxies: "Conectar más proxies de correo electrónico ›",
        noItems: {
          connectBtn: "Conectar Aplicaciones en la Nube",
          title: "Primero necesitas conectar los servicios en la nube que te gustaría proteger",
          description:
            "Una vez conectado, establece permisos de inspección de contenido para correos y archivos sospechosos.",
        },
        noCloudServicesConnected: "No hay servicios en la nube conectados",
        noProxyConnected: "No hay proxy conectado",
      },
    },
    roles: {
      title: "Roles",
      table: {
        headers: {
          role: "Rol",
          assigneesCount: "No. de usuarios",
        },
        actions: {
          [RolesAction.ADD]: "Agregar rol",
          [RolesAction.EDIT]: "Editar rol",
          [RolesAction.DUPLICATE]: "Duplicar rol",
          [RolesAction.DELETE]: "Eliminar rol",
        },
      },
      scopes: {
        [GlobalRoleScopes.GLOBAL_SCOPE]: {
          title: "Gestión global de Coro",
          [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS]: {
            title: "Usuarios administradores globales",
            editAccessModePermission: {
              add: "Agregar usuarios administradores globales",
              remove: "Eliminar usuarios administradores globales",
              assignRoles: "Asignar roles a usuarios administradores globales",
            },
          },
          [GlobalRoleScopeSection.GLOBAL_ROLES]: {
            title: "Roles globales",
            editAccessModePermission: {
              add: "Agregar/duplicar roles globales",
              edit: "Editar roles globales",
              remove: "Eliminar roles globales",
            },
          },
          [GlobalRoleScopeSection.SOC_PORTAL]: {
            title: "Portal SOC",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de bajo impacto",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de alto impacto",
              tier1Statuses: "Estados de nivel 1",
              tier2Statuses: "Estados de nivel 2",
            },
          },
          [GlobalRoleScopeSection.WORKSPACE_SUBSCRIPTION_TYPE_ACCESS]: {
            title: "Acceso al espacio de trabajo por suscripción",
            editAccessModePermission: {
              prospects: "Prospectos (Nuevo, Inactivo, Prueba, Archivado)",
              customers: "Clientes (Suscripción)",
            },
          },
          [GlobalRoleScopeSection.WORKSPACE_TYPE_ACCESS]: {
            title: "Acceso al espacio de trabajo por tipo",
            editAccessModePermission: {
              regular: "Espacios de trabajo regulares",
              channel: "Espacios de trabajo de canal",
              child: "Espacios de trabajo secundarios",
            },
          },
          [GlobalRoleScopeSection.SPECIAL_PERMISSIONS]: {
            title: "Permisos específicos",
            editAccessModePermission: {
              collectLogs: "Recopilar registros",
              collectQuarantineData: "Recopilar datos de cuarentena",
              showAdvancedDeviceInformation: "Mostrar información avanzada de dispositivos",
              backOffice: "Acceso a Back Office",
              manageEndpointAutoUpdateGroups: "Gestionar grupos de autoactualización de endpoints",
              manageWorkspaceCodes: "Gestionar códigos de espacio de trabajo",
              demoMode: "Modo demostración",
            },
          },
        },
        [MspRolePermissionScopes.MSP_PORTAL_SCOPE]: {
          title: "Gestión MSP",
          [MspPortalScopeSection.MSP_WORKSPACES]: {
            title: "Portal MSP",
            editAccessModePermission: {
              editWorkspace: "Editar detalles del espacio de trabajo",
              startSubscription: "Iniciar suscripción",
              stopSubscription: "Detener suscripción",
              editSubscription: "Editar suscripción",
              createChildWorkspace: "Crear espacio de trabajo secundario",
              createChannelWorkspace: "Crear espacio de trabajo de canal",
              archiveWorkspace: "Archivar espacio de trabajo",
              generateMspExport: "Generar exportación MSP",
              generateMspSummaryReport: "Generar informe resumido de MSP",
              exportMspNotifications: "Exportar notificaciones MSP",
              extendTrial: "Extender prueba",
              convertWorkspace: "Convertir espacio de trabajo",
              restoreWorkspace: "Restaurar espacio de trabajo",
              createRegularWorkspace: "Crear espacio de trabajo regular",
            },
          },
          [MspPortalScopeSection.MSP_ADMIN_USERS]: {
            title: "Usuarios administradores MSP",
            editAccessModePermission: {
              add: "Agregar usuarios administradores MSP",
              remove: "Eliminar usuarios administradores MSP",
              assignRoles: "Asignar roles a usuarios administradores MSP",
            },
          },
          [MspPortalScopeSection.MSP_ROLES]: {
            title: "Roles MSP",
            editAccessModePermission: {
              add: "Agregar/duplicar roles MSP",
              edit: "Editar roles MSP",
              remove: "Eliminar roles MSP",
            },
          },
        },
        [RolePermissionsScope.WORKSPACE_MANAGEMENT]: {
          title: "Gestión",
          [WorkspaceManagementScopeSections.USERS]: "Usuarios",
          [WorkspaceManagementScopeSections.DEVICES]: "Dispositivos",
          [WorkspaceManagementScopeSections.CLOUD_APPS]: "Aplicaciones en la Nube",
          [WorkspaceManagementScopeSections.ACTIVE_SESSIONS]: "Sesiones Activas",
          [WorkspaceManagementScopeSections.ACTIVITY_LOGS]: "Registros de Actividad",
          [WorkspaceManagementScopeSections.CONNECTORS]: "Conectores",
          [WorkspaceManagementScopeSections.ROLES]: "Roles",
          [WorkspaceManagementScopeSections.REPORTS]: "Informes",
          [WorkspaceManagementScopeSections.ADMIN_USERS]: {
            title: "Usuarios administradores",
            editAccessModePermission: {
              add: "Agregar",
              edit: "Editar",
              remove: "Eliminar",
              removeMfaData: "Eliminar datos de 2FA",
              assignRoles: "Asignar roles",
              manageContentPermissions: "Gestionar permisos de contenido",
            },
          },
        },
        [RolePermissionsScope.VIEWS]: {
          title: "Vistas",
          usersView: "Usuarios",
          devicesView: "Dispositivos",
        },
        [RolePermissionsScope.PROTECTION]: {
          title: "Protección",
          [SubscriptionModule.CLOUD_SECURITY]: "Seguridad en la Nube",
          [SubscriptionModule.ENDPOINT_SECURITY]: "Seguridad de Endpoints",
          [SubscriptionModule.EMAIL_SECURITY]: "Seguridad de Correo Electrónico",
          [SubscriptionModule.USER_DATA_GOVERNANCE]: "Gobernanza de Datos de Usuario",
          [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "Gobernanza de Datos de Endpoints",
          [SubscriptionAddon.INBOUND_GATEWAY]: "Puerta de Enlace Entrante",
          [SubscriptionModule.EDR]: "EDR",
          [SubscriptionModule.NETWORK]: "Red",
          [ProtectionScopeModules.MANAGED_SOC]: "Servicio Gestionado",
          [SubscriptionModule.MDM]: "MDM",
          [SubscriptionAddon.SWG]: "Puerta de Enlace Web Segura",
          [SubscriptionAddon.WIFI_PHISHING]: "Phishing WiFi",
          [SubscriptionAddon.SECURED_MESSAGES]: "Mensajes Asegurados",
        },
        [RolePermissionsScope.TICKETS]: {
          title: "Tickets",
          [SubscriptionModule.CLOUD_SECURITY]: {
            title: "Seguridad en la Nube",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de bajo impacto",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de alto impacto",
            },
          },
          [SubscriptionModule.ENDPOINT_SECURITY]: {
            title: "Seguridad de Endpoints",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de bajo impacto",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de alto impacto",
            },
          },
          [SubscriptionModule.EMAIL_SECURITY]: {
            title: "Seguridad de Correo Electrónico",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de bajo impacto",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de alto impacto",
            },
          },
          [SubscriptionModule.USER_DATA_GOVERNANCE]: {
            title: "Gobernanza de Datos de Usuario",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de bajo impacto",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de alto impacto",
            },
          },
          [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
            title: "Gobernanza de Datos de Endpoints",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de bajo impacto",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de alto impacto",
            },
          },
          [SubscriptionModule.EDR]: {
            title: "EDR",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de bajo impacto",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de alto impacto",
            },
          },
        },
      },

      details: {
        adminUsers: "Usuarios administradores",
        permissions: "Permisos",
        permissionModes: {
          [RolePermissionAccessMode.EDIT]: "Puede editar",
          [RolePermissionAccessMode.VIEW]: "Puede ver",
          [RolePermissionAccessMode.NO_ACCESS]: "Sin acceso",
        },
      },
    },
    activeSessions: {
      title: "Sesiones Activas",
      description:
        "Estos dispositivos han iniciado sesión en el sistema como usuarios administradores. Revoca sesiones que no reconozcas.",
      table: {
        headers: {
          ip: "Direcciones IP",
          user: "Usuario",
          country: "País",
          signedIn: "Iniciado Sesión",
          lastAccessed: "Último Acceso",
        },
        revoke: "Revocar",
        currentSession: "Sesión actual",
      },
    },
  },
  soc: {
    title: "Servicio Gestionado",
    tabs: {
      remediation: "Remediación",
      contacts: "Contactos",
    },
    contactsTab: {
      addBtn: "Agregar contacto de servicio gestionado",
      emptyState: {
        description: "Actualmente no tiene contactos de servicio gestionado",
        subtitle:
          "Coro necesita conocer los puntos de contacto principales en su organización para todos los problemas del Centro de Operaciones de Seguridad (Servicio Gestionado).",
      },
      table: {
        email: "Correo electrónico",
        name: "Nombre",
        notifications: "Notificaciones",
      },
      actions: {
        [SocContactsActions.EDIT]: "Editar",
        [SocContactsActions.REMOVE]: "Eliminar",
      },
      notificationType: {
        email: "Correo electrónico",
        emailCriticalIssues: "Correo electrónico - problemas críticos",
        doNotNotify: "No notificar",
      },
    },
    remediationTab: {
      title: "Elija cómo le gustaría gestionar la remediación",
      [SocRemediationType.REMEDIATE_MYSELF]:
        "Remediar problemas yo mismo basado en las recomendaciones del equipo de servicio gestionado de Coro",
      remediateMyselfSubtitle:
        "Coro notificará recomendaciones a sus contactos de servicio gestionado de acuerdo con su nivel de notificación especificado.",
      [SocRemediationType.REMEDIATE_BY_CORO_SOC]:
        "Permitir que el equipo de servicio gestionado de Coro remedie problemas de forma remota en mi nombre",
      remediateByCoroSocSubtitle:
        "Coro actualizará a sus contactos de servicio gestionado sobre las acciones tomadas de acuerdo con su nivel de notificación especificado.",
    },
  },
  endpointDataGovernance: {
    title: "Gobernanza de Datos de Endpoint",
    privacySensitiveDataTab: {
      title: "Datos sensibles a la privacidad",
      subtitle: "Seleccione los tipos que le gustaría escanear",
      monitorPII: "Información personal identificable (PII)",
      monitorPCI: "Información de tarjeta de pago (PCI)",
      monitorPHI: "Información de salud protegida (PHI)",
      monitorNPI: "Información no pública (NPI)",
    },
    sensitiveDataScansTab: {
      title: "Escaneos de datos sensibles",
      addBtn: "Agregar programación",
      emptyState: {
        description: "Actualmente no tiene escaneos de datos sensibles programados.",
        subtitle:
          "Escanea e identifica posibles violaciones relacionadas con el almacenamiento de datos sensibles en los discos de los dispositivos de endpoint.",
      },
      table: {
        os: "SO",
        action: "Acción",
        drives: "Discos",
        labels: "Etiquetas",
        appliedDriveTypes: {
          ALL: "Todos",
          UNENCRYPTED: "No encriptados",
        },
        actions: {
          [SensitiveDataScansActions.DELETE]: "Eliminar",
          [SensitiveDataScansActions.EDIT]: "Editar",
        },
      },
    },
  },
  cloudSecurity: {
    title: "Seguridad en la Nube",
    description: "Monitorear y controlar el acceso a aplicaciones y datos en la nube",
    accessPermissions: "Permisos de Acceso",
    noItems: {
      title: "Agregue sus aplicaciones en la nube a la protección de Coro",
      description:
        "Monitoree las cuentas de sus aplicaciones en la nube empresarial para asegurar su entorno y cumplir \ncon las regulaciones de privacidad.",
      connectBtn: "Conectar Aplicaciones en la Nube",
      modalDescription: "Seleccione la aplicación en la nube que le gustaría conectar",
    },
    tabs: {
      accessPermission: "Permiso de acceso",
      thirdPartyApps: "Aplicaciones de terceros",
      settings: "Configuración",
    },
  },
  cloudSecuritySettings: {
    inactiveUsers: "Usuarios inactivos",
    default: "Predeterminado",
    custom: "Personalizado",
    numberOfDays: "Número de días",
    inactiveUsersSubtitle:
      "De forma predeterminada, los usuarios se consideran inactivos después de no usar una aplicación en la nube durante 30 días. Puedes personalizar el número de días antes de que un usuario se vuelva inactivo.",
  },
  userDataGovernanceSettings: {
    title: "Gobernanza de Datos de Usuario",
    description:
      "Monitoreo y control sobre el acceso a datos confidenciales y sensibles según las regulaciones",
    noEmailServicesConnected:
      "Conecte su <b>Microsoft 365</b> o <b>G-Suite</b> de la empresa a Coro para permitir una gobernanza efectiva de datos y protección contra la pérdida de datos",
    connectCloudAppsBtn: "Conectar Aplicaciones en la Nube",
    addPermission: "Agregar Permiso",
    tabs: {
      monitoring: "Monitoreo",
      permissions: "Permisos",
      exclusions: "Exclusiones",
      outboundGateway: "Puerta de Salida",
    },
    dataLossModals: {
      setPermissions: "Aplicar política a estos usuarios o dominios",
      selectDataType: "Seleccionar Tipo de Datos",
      dataTypes: {
        [TicketTrigger.DLP_PCI]: "Información de Tarjeta de Pago (PCI)",
        [TicketTrigger.DLP_PHI]: "Información de Salud Protegida (PHI)",
        [TicketTrigger.DLP_PII]: "Información Personal Identificable (PII)",
        [TicketTrigger.DLP_NPI]: "Información No Pública (NPI)",
        [TicketTrigger.CRITICAL_DATA_PASSWORD]: "Contraseñas",
        [TicketTrigger.CRITICAL_DATA_CERTIFICATE]: "Certificados",
        [TicketTrigger.CRITICAL_DATA_SOURCE_CODE]: "Código Fuente",
        [TicketTrigger.CRITICAL_DATA_KEYWORDS]: "Palabras Clave Sensibles",
        [TicketTrigger.CRITICAL_DATA_FILE_TYPES]: "Tipos de Archivos Sensibles",
      },
    },
    permissionsTab: {
      usersAndGroups: "Usuarios / Grupos",
      permission: "Permiso",
      allUsersPolicy: "Todos los usuarios del espacio de trabajo",
      dlpPci: "Información de Tarjeta de Pago (PCI)",
      dlpPhi: "Información de Salud Protegida (PHI)",
      dlpPii: "Información Personal Identificable (PII)",
      dlpNpi: "Información No Pública (NPI)",
      criticalDataPassword: "Contraseñas",
      criticalDataCertificate: "Certificados",
      criticalDataSourceCode: "Código Fuente",
      criticalDataKeywords: "Objetos de datos con palabras clave específicas",
      criticalDataFileTypes: "Tipos de Archivos Sensibles",
      addPermissionOptions: {
        addPermissionForAllUsers: "Todos los Usuarios",
        addPermissionForSpecificGroups: "Grupos Específicos",
        addPermissionForSpecificUsers: "Usuarios / Dominios Específicos",
      },
      actions: {
        accessOnly: "Solo Puede Acceder",
        accessAndExposure: "Puede Acceder y Exponer",
        remove: "Eliminar Permiso",
      },
      noPermissionsDescription: "Actualmente, no tiene permisos específicos sobre los datos",
    },
    monitoringTab: {
      dataPossession: "Posesión de Datos",
      dataExposure: "Exposición de Datos",
      dataExposureDescription:
        "Tipos de datos sensibles a monitorear para acceso y exposición en el intercambio de datos por correo electrónico y en la nube.",
      dataPossessionDescription:
        "Tipos de datos sensibles a buscar en escaneos iniciados de forma remota en los discos de dispositivos de endpoint.",
      monitorPII: "Información personal identificable (PII)",
      monitorPCI: "Información de tarjeta de pago (PCI)",
      monitorPHI: "Información de salud protegida (PHI)",
      monitorNPI: "Información no pública (NPI)",
      monitorCustomDataEntries: "Entradas de datos personalizadas",
      excludeEmailScans: "Excluir escaneos de correos electrónicos salientes",
      keywordsTitle: "Palabras clave específicas en el asunto",
      keywordsPlaceholder: "Ingrese palabras clave como “palabra1”, “palabra2”",
      passwords: "Contraseñas",
      certificates: "Certificados",
      sourceCode: "Código fuente",
      dataObjectsWithSpecificKeywords: "Objetos de datos con palabras clave específicas",
      specificKeywordsPlaceholder: "Ingrese palabras clave como “palabra1”, “palabra2”",
      specificFileTypesTitle: "Tipos de archivo específicos",
      specificFileTypesPlaceholder: "png, psd, docx…",
      privacySensitiveData: "Datos sensibles a la privacidad",
      securityAndBusinessData: "Datos de seguridad y comerciales sensibles",
    },
    exclusionsTab: {
      title: "Configurar datos que deben ser excluidos de los escaneos para datos sensibles",
      excludeEmailsWithKeywords:
        "Excluir correos electrónicos con palabras clave especificadas en el asunto",
      specificKeywordsPlaceholder: "Ingrese palabras clave como “palabra1”, “palabra2”",
    },
  },
  myAccount: {
    tabs: {
      profile: "Perfil",
      password: "Contraseña",
      mfa: "Autenticación de Dos Factores",
      notifications: "Notificaciones",
    },
    profile: {
      title: "Información del perfil",
      firstName: {
        label: "Nombre",
      },
      lastName: {
        label: "Apellido",
      },
      email: {
        label: "Correo electrónico",
        hint: "Su correo electrónico no se puede actualizar",
      },
      updateProfile: "Actualizar perfil",
    },
    password: {
      title: "Contraseña",
      password: {
        label: "Contraseña",
      },
      oldPassword: {
        label: "Contraseña anterior",
      },
      newPassword: {
        label: "Nueva contraseña",
      },
      setPassword: "Establecer contraseña",
      changePassword: "Cambiar contraseña",
      warningMessage:
        "Aún no ha establecido ninguna contraseña. Establecer una contraseña se aplicará a todos los espacios de trabajo de los que sea miembro.",
      successMessage: "Su contraseña fue actualizada.",
      passwordSentMessage:
        "Le enviamos un correo electrónico con instrucciones para restablecer la contraseña.",
      forgotPasswordBtn: "¿Olvidó la contraseña?",
    },
    notifications: {
      generalNotifications: "Notificaciones generales",
      dailyEmailUpdates: "Actualizaciones del estado del espacio de trabajo (recomendado)",
      criticalTickets: "Tickets críticos que requieren acción oportuna (recomendado)",
      releaseNotes: "Notas de lanzamiento",
      productAnnouncements: "Anuncios de productos",
      specificNotifications: "Notificaciones para tickets específicos",
      cloudSecurity: "Seguridad en la Nube",
      endpointSecurity: "Seguridad de Endpoint",
      emailSecurity: "Seguridad del Correo Electrónico",
      userDataGovernance: "Gobernanza de Datos de Usuario",
      endpointDataGovernance: "Gobernanza de Datos de Endpoint",
      edr: "EDR",
    },
    mfa: {
      title: "Autenticación de Dos Factores",
      notice:
        "Actualizar su autenticación de dos factores aquí también se aplicará a todos los espacios de trabajo de los que sea miembro.",
      mfaEnabled: "La autenticación de dos factores está habilitada",
      mfaEnabledDesc:
        "Ha habilitado la autenticación de dos factores para su cuenta utilizando una aplicación de autenticación.",
      mfaDisabled: "La autenticación de dos factores está deshabilitada",
      recoveryMobile: "Su número de móvil de recuperación",
      disableMFAButton: "Eliminar datos de autenticación de dos factores",
      fallbackTitle: "Habilitar Método de Recuperación Alternativo",
      fallbackDesc:
        "Por favor, ingrese un número de teléfono en caso de que no pueda autenticar su identidad y necesite recuperar su código por SMS.",
      fallbackCodeTitle: "Ingrese el código de 6 dígitos que recibió en su teléfono",
      finishSetupBtn: "Finalizar configuración",
      smsSent: "SMS enviado",
    },
  },
  tickets: {
    eventTypes: {
      [TicketType.ABNORMAL_ADMIN_ACTIVITY]: "Actividad Anormal de Administrador",
      [TicketType.MALWARE_IN_EMAIL_ATTACHMENTS]: "Malware en Adjunto de Correo Electrónico",
      [TicketType.SUSPECTED_IDENTITY_COMPROMISE]: "Compromiso de Identidad Sospechado",
      [TicketType.MALWARE_IN_CLOUD_DRIVE]: "Malware en Nube",
      [TicketType.ACCESS_PERMISSIONS_VIOLATION]: "Violación de Permisos de Acceso",
      [TicketType.MALWARE_IN_DEVICE]: "Malware en Endpoint",
      [TicketType.ENDPOINT_VULNERABILITY]: "Vulnerabilidad de Endpoint",
      [TicketType.EMAIL_PHISHING]: "Phishing por Correo Electrónico [Obsoleto]",
      [TicketType.WIFI_PHISHING]: "Phishing por WiFi",
      [TicketType.WIFI_FORBIDDEN_NETWORK]: "Red Wi-Fi Prohibida",
      [TicketType.ABNORMAL_DATA_MANIPULATION]: "Manipulación Anormal de Datos",
      [TicketType.SUSPECTED_BOT_ATTACKS]: "Ataques de Bot Sospechados",
      [TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL]:
        "Sospecha de Exposición de Datos Críticos por Correo Electrónico",
      [TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING]:
        "Sospecha de Exposición de Datos Críticos por Compartición",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL]:
        "Sospecha de Violación de Cumplimiento de Datos por Correo Electrónico",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING]:
        "Sospecha de Violación de Cumplimiento de Datos por Compartición",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE]: "Endpoint con Datos Sensibles",
    },
    eventTriggers: {
      [TicketTrigger.CRITICAL_DATA_FILE_TYPES]: "Exposición Sospechosa de Tipo de Archivo",
      [TicketTrigger.CRITICAL_DATA_SOURCE_CODE]: "Exposición Sospechosa de Código Fuente",
      [TicketTrigger.CRITICAL_DATA_PASSWORD]: "Exposición Sospechosa de Contraseña",
      [TicketTrigger.CRITICAL_DATA_CERTIFICATE]: "Exposición Sospechosa de Certificado",
      [TicketTrigger.DLP_PII]: "PII (Información Personal Identificable)",
      [TicketTrigger.DLP_PCI]: "PCI (Información de Tarjeta de Pago)",
      [TicketTrigger.DLP_PHI]: "PHI (Información de Salud Protegida)",
      [TicketTrigger.DLP_NPI]: "NPI (Información No Pública)",
      [TicketTrigger.DEVICE_DLP_PII]: "Endpoint con PII",
      [TicketTrigger.DEVICE_DLP_PCI]: "Endpoint con PCI",
      [TicketTrigger.DEVICE_DLP_PHI]: "Endpoint con PHI",
      [TicketTrigger.DEVICE_DLP_NPI]: "Endpoint con NPI",
      [TicketTrigger.CRITICAL_DATA_KEYWORDS]: "Exposición Sospechosa de Datos Críticos",
      [TicketTrigger.ENCRYPTION_DISABLED]: "Unidad de Endpoint Sin Cifrado",
      [TicketTrigger.APPS_FROM_UNKNOWN_SOURCES]: "Aplicaciones de Fuentes Desconocidas",
      [TicketTrigger.ANTI_VIRUS_DISABLED]: "Antivirus Desactivado",
      [TicketTrigger.DEVICE_PASSWORD_MISSING]: "Contraseña de Dispositivo Faltante",
      [TicketTrigger.DEVELOPMENT_MODE_ENABLED]: "Modo de Desarrollo Habilitado",
      [TicketTrigger.VSS_BACKUP_PROTECTION]: "Protección de Respaldo VSS",
      [TicketTrigger.MOCK_LOCATION_ENABLED]: "Ubicación Falsa Habilitada",
      [TicketTrigger.NON_GENUINE_WINDOWS]: "Copia de Windows No Genuina",
      [TicketTrigger.FIREWALL_DISABLED]: "Cortafuegos Desactivado",
      [TicketTrigger.UAC_NOTIFICATIONS_MISSING]: "Notificación UAC Faltante",
      [TicketTrigger.ACTIVEX_CODE_EXECUTION_ENABLED]: "Ejecución de Código Active X Habilitada",
      [TicketTrigger.REMOTE_CODE_EXECUTION_ENABLED]: "Ejecución Remota de Código Habilitada",
      [TicketTrigger.CRITICAL_UPDATES_NOT_INSTALLED]:
        "Actualizaciones Críticas de Windows Faltantes",
      [TicketTrigger.EXECUTION_POLICY_ENABLED]: "Configuración de PowerShell Insegura",
      [TicketTrigger.INFECTED_PROCESS]: "Proceso Infectado",
      [TicketTrigger.ABNORMAL_ADMIN_ACTIVITY]: "Actividad Anormal de Administrador",
      [TicketTrigger.SUSPECTED_IDENTITY_COMPROMISE]: "Compromiso de Identidad Sospechado",
      [TicketTrigger.MALWARE_IN_CLOUD_DRIVE]: "Malware en Nube",
      [TicketTrigger.EMAIL_PHISHING]: "Phishing por Correo Electrónico [Obsoleto]",
      [TicketTrigger.MALWARE_IN_EMAIL_ATTACHMENTS]: "Malware en Adjunto de Correo Electrónico",
      [TicketTrigger.ABNORMAL_DATA_MANIPULATION]: "Manipulación Anormal de Datos",
      [TicketTrigger.ACCESS_PERMISSIONS_VIOLATION]: "Violación de Permisos de Acceso",
      [TicketTrigger.MALWARE_ON_ENDPOINT]: "Malware en Endpoint",
      [TicketTrigger.SUSPECTED_BOT_ATTACKS]: "Ataques de Bot Sospechados",
      [TicketTrigger.MASS_DELETE]: "Eliminación Masiva de Datos",
      [TicketTrigger.MASS_DOWNLOAD]: "Descarga Masiva de Datos",
      [TicketTrigger.WIFI_PHISHING]: "Phishing por WiFi",
      [TicketTrigger.FORBIDDEN_NETWORK_CONNECTION]: "Conexión Wi-Fi Prohibida",
      [TicketTrigger.GATEKEEPER_UNAVAILABLE]: "Gatekeeper Deshabilitado",
      [TicketTrigger.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE]:
        "Integridad de Archivo Móvil de Apple Deshabilitada",
      [TicketTrigger.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE]:
        "Protección de Integridad del Sistema Deshabilitada",
      [TicketTrigger.USB_LOCKDOWN]: "Cierre de USB",
      [TicketTrigger.BLOCKLISTED_SENDER]: "Remitente en Lista de Bloqueo",
      [TicketTrigger.BRAND_IMPERSONATION]: "Suplantación de Marca",
      [TicketTrigger.CROWDBLOCKED_SENDER]: "Remitente Bloqueado por Crowd",
      [TicketTrigger.DOMAIN_IMPERSONATION]: "Suplantación de Dominio",
      [TicketTrigger.FORBIDDEN_ATTACHMENT_TYPE]: "Tipo de Adjunto Prohibido",
      [TicketTrigger.MISSING_REQUIRED_AUTHENTICATION]: "Falta Autenticación Requerida",
      [TicketTrigger.SUSPICIOUS_CONTENT]: "Contenido Sospechoso",
      [TicketTrigger.SPAM]: "Spam",
      [TicketTrigger.UNVERIFIED_SENDER]: "Remitente No Verificado",
      [TicketTrigger.USER_IMPERSONATION]: "Suplantación de Usuario",
      [TicketTrigger.REPORTED_BY_USER]: "Reportado por Usuario",
      [TicketTrigger.DOMAIN_SPOOFING]: "Suplantación de Dominio",
      [TicketTrigger.EDR_DETECTION_SUSPICIOUS_PROCESS]: "Proceso Sospechoso",
      [TicketTrigger.AUTOMATIC_UPDATE_DISABLED]: "Actualizaciones de Windows Desactivadas",
      [TicketTrigger.EDR_DETECTION_COLLECTION]: "Colección",
      [TicketTrigger.EDR_DETECTION_COMMAND_AND_CONTROL]: "Comando y Control",
      [TicketTrigger.EDR_DETECTION_CREDENTIAL_ACCESS]: "Acceso a Credenciales",
      [TicketTrigger.EDR_DETECTION_DEFENCE_EVASION]: "Evasión de Defensa",
      [TicketTrigger.EDR_DETECTION_EXECUTION]: "Ejecución",
      [TicketTrigger.EDR_DETECTION_DISCOVERY]: "Descubrimiento",
      [TicketTrigger.EDR_DETECTION_EXFILTRATION]: "Exfiltración",
      [TicketTrigger.EDR_DETECTION_IMPACT]: "Impacto",
      [TicketTrigger.EDR_DETECTION_INITIAL_ACCESS]: "Acceso Inicial",
      [TicketTrigger.EDR_DETECTION_LATERAL_MOVEMENT]: "Movimiento Lateral",
      [TicketTrigger.EDR_DETECTION_PERSISTENCE]: "Persistencia",
      [TicketTrigger.EDR_DETECTION_PRIVILEGE_ESCALATION]: "Escalación de Privilegios",
      [TicketTrigger.EDR_DETECTION_RECONNAISSANCE]: "Reconocimiento",
      [TicketTrigger.EDR_DETECTION_RESOURCE_DEVELOPMENT]: "Desarrollo de Recursos",
      [TicketTrigger.SUSPICIOUS_METADATA]: "Metadatos Sospechosos",
    },
    filters: {
      type: "Tipo",
      during: "Durante",
      status: "Estado",
      searchTickets: "Buscar tickets...",
      widgets: {
        placeholder: "En todas partes",
        deviceSecurityWidget: "Seguridad de Endpoint",
        cloudSecurity: "Seguridad en la Nube",
        emailsWidget: "Seguridad de Correo Electrónico",
        usersWidget: "Usuarios",
        userDataGovernance: "Gobernanza de Datos de Usuario",
        endpointDataGovernance: "Gobernanza de Datos de Endpoint",
        edr: "EDR",
      },
    },
    socStatuses: {
      [SocStatus.NEW]: "Nuevo",
      [SocStatus.WIP_TIER1]: "En Proceso Tier 1",
      [SocStatus.WIP_TIER2]: "En Proceso Tier 2",
      [SocStatus.REQUIRES_TIER2]: "Requiere Tier 2",
      [SocStatus.NEW_COMMENTS]: "Nuevos comentarios",
      closed: "Cerrado",
    },
  },
  workspaces: {
    title: "Seleccionar Espacio de Trabajo para Ver",
    tabs: {
      workplaces: "Espacios de Trabajo",
      pendingInvitations: "Invitaciones Pendientes",
    },
    tableHeaders: {
      id: "Id",
      name: "Nombre del Espacio de Trabajo",
    },
    selected: "Seleccionado",
    search: "Buscar espacio de trabajo",
    actions: {
      [WorkspaceAction.INVITE]: "Invitar",
      [WorkspaceAction.LAUNCH]: "Ver",
      [WorkspaceAction.JOIN]: "Unirse",
    },
    modals: {
      invite: {
        title: "Invitar usuario",
        label: "Por favor, escribe los usuarios que deseas invitar al espacio de trabajo.",
        actionButton: "Invitar usuarios",
      },
      selectWorkplace: {
        title: "Seleccionar Espacio de Trabajo para Ver",
        actionButton: "Lanzar",
      },
    },
  },
  reports: {
    title: "Informes",
    tabs: {
      reports: "Informes",
      exports: "Exportaciones",
      scheduled: "Programados",
    },
    executiveSummaryReport: {
      download: {
        title: "Descargar",
        toPdf: "PDF",
      },
      executiveSummary: "Resumen Ejecutivo",
      version: "Versión",
      generatedOn: "Generado el:",
      topVulnerabilities: "Principales vulnerabilidades",
      protection: "Protección",
      ticketsByType: "Tickets por tipo",
      dateRange: "Rango de fechas",
      disabledModuleNotification: "Este módulo está actualmente deshabilitado",
      ticketsGenerated: "Tickets generados",
      users: {
        total: "Total de usuarios",
        protected: "Protegidos",
        unprotected: "No protegidos",
      },
      devices: {
        total: "Dispositivos cubiertos",
      },
      tickets: {
        total: "Tickets generados",
        processed: "Tickets cerrados",
        unprocessed: "Tickets abiertos",
      },
      [SubscriptionModule.USER_DATA_GOVERNANCE]: {
        title: "Gobernanza de Datos de Usuario",
        description:
          "Análisis de anomalías para identificar y mitigar la exposición de datos sensibles a través de correo electrónico y compartición de archivos, cumpliendo con las configuraciones del espacio de trabajo; incluye exposición de PHI/PCI/PII/NPI y datos sensibles para el negocio.",
        topViolatorsTitle: "Principales infractores",
      },
      [SubscriptionModule.EMAIL_SECURITY]: {
        title: "Seguridad de Correo Electrónico",
        description:
          "Protección contra suplantación de dominio, correos electrónicos engañosos y adjuntos maliciosos, asegurando un entorno de correo electrónico seguro.",
        topViolatorsTitle: "Principales correos electrónicos sospechosos",
      },
      [SubscriptionModule.CLOUD_SECURITY]: {
        title: "Seguridad en la Nube",
        description:
          "Detección y remediación avanzada de malware, ransomware y acceso no autorizado en unidades en la nube. Los usuarios administradores pueden configurar geofencing/restricción de red, y el módulo optimiza la detección de incidentes en tiempo real a través de análisis de datos consolidados.",
        topViolatorsTitle: "Principales usuarios atacados",
      },
      [SubscriptionModule.ENDPOINT_SECURITY]: {
        title: "Seguridad de Endpoint",
        description:
          "Protección en tiempo real contra malware y ransomware a través de Antivirus de Nueva Generación (NGAV). Gestión integral de funciones críticas de seguridad de dispositivos, incluyendo escaneos de unidades, actualizaciones de agentes, monitoreo de actividad, configuraciones de aplicación personalizables, gestión de cortafuegos y notificaciones de Control de Cuentas de Usuario (UAC).",
        topViolatorsTitle: "Principales dispositivos vulnerables",
      },
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
        title: "Gobernanza de Datos de Endpoint",
        description:
          "Escaneos de endpoint para datos sensibles basados en las configuraciones del espacio de trabajo; incluye exposición de PHI/PCI/PII/NPI y datos sensibles para el negocio.",
        topViolatorsTitle: "Principales infractores",
      },
      [SubscriptionModule.EDR]: {
        title: "EDR",
        description:
          "Detección y respuesta a amenazas avanzadas que apuntan a dispositivos de endpoint, como actividad sospechosa, comportamiento malicioso y signos de compromiso.",
        topViolatorsTitle: "Principales procesos vulnerables",
      },
    },
    managedServicesSummaryReport: {
      title: "Resumen de servicios gestionados",
      totalTicketsResolved: "Total de tickets resueltos",
      resolvedTicketsByModules: "Tickets resueltos por módulos",
      ticketsResolved: "Tickets resueltos",
    },
    securedMessagesSummaryReport: {
      title: "Resumen de Mensajes Seguros",
      totalSecuredMessagesSent: "Total de mensajes seguros enviados",
      securedMessagesSentByUser: "Mensajes seguros enviados por usuarios",
    },
    dnsSummaryReport: {
      title: "Resumen de DNS",
      totalQueries: "Total de consultas",
      blockedQueries: "Consultas bloqueadas",
      topDomainsPermitted: "Principales dominios permitidos",
      topDomainsBlocked: "Principales dominios bloqueados",
      topClients: "Principales clientes permitidos",
      topClientsBlocked: "Principales clientes bloqueados",
      perHits: "por hits",
      perRequest: "por solicitud",
      queries: "Consultas",
      noData: "No tienes datos",
      noItems: {
        title: "El Filtrado DNS está desactivado",
        description: "Habilita el Filtrado DNS para ver el Resumen de Consultas DNS",
        actionBtn: "Ir a Configuración de SWG",
      },
    },
    exports: {
      newExport: "Nueva exportación",
      actions: {
        [ExportAction.EXPORT_DEVICES]: "Dispositivos",
        [ExportAction.EXPORT_PROTECTED_USERS]: "Usuarios protegidos",
        [ExportAction.EXPORT_TICKETS]: "Registro de tickets",
        [ExportAction.EXPORT_ACTIVITY_LOGS]: "Registro de actividad",
      },
      disclaimer:
        "<b>Las exportaciones están disponibles para descarga durante 24 horas.</b> Si tu exportación ha expirado, genera una nueva para descargarla.",
      table: {
        name: "Nombre",
        category: "Categoría",
        created: "Creado",
      },
      categories: {
        [ExportCategory.ACTIVITY_LOGS]: "Registro de actividad",
        [ExportCategory.DEVICES]: "Dispositivos",
        [ExportCategory.USERS]: "Usuarios protegidos",
        [ExportCategory.TICKETS]: "Registro de tickets",
      },
      exportFileExtensions: {
        [ExportFileExtension.CSV]: "CSV",
        [ExportFileExtension.ZIP]: "ZIP",
      },
      filters: {
        types: "Categoría",
        dateRange: "Rango de fechas",
      },
    },
    scheduled: {
      schedule: "Programar",
      table: {
        name: "Nombre",
        reportType: "Tipo",
        frequency: "Frecuencia",
        recipients: "Destinatarios",
        scope: "Ámbito",
      },
      type: {
        report: "Informe",
        export: "Exportación",
      },
      actions: {
        [ScheduledReportsAction.ADD_REPORT]: "Informe",
        [ScheduledReportsAction.EDIT_REPORT]: "Editar",
        [ScheduledReportsAction.ADD_EXPORT]: "Exportar",
        [ScheduledReportsAction.EDIT_EXPORT]: "Editar",
        [ScheduledReportsAction.DELETE]: "Eliminar",
      },
      subTypes: {
        [ExportCategory.ACTIVITY_LOGS]: "Exportación de registro de actividad",
        [ExportCategory.DEVICES]: "Exportación de dispositivos",
        [ExportCategory.USERS]: "Exportación de usuarios protegidos",
        [ExportCategory.TICKETS]: "Exportación de registro de tickets",
        [ReportType.DNS_SUMMARY]: "Informe resumen de DNS",
        [ReportType.SECURED_MESSAGES]: "Informe resumen de Mensajes Seguros",
        [ReportType.EXECUTIVE_SUMMARY]: "Informe resumen ejecutivo",
        [ReportType.MANAGED_SERVICES_SUMMARY]: "Informe resumen de servicios gestionados",
      },
      frequencies: {
        [ScheduledReportFrequency.DAILY]: "Diario",
        [ScheduledReportFrequency.WEEKLY]: "Semanal",
        [ScheduledReportFrequency.MONTHLY]: "Mensual",
      },
      scopes: {
        [ScheduledReportScope.WORKSPACE_AND_DIRECT_DESCENDANTS]:
          "Mi espacio de trabajo y descendientes directos",
        [ScheduledReportScope.WORKSPACE]: "Mi espacio de trabajo",
        [ScheduledReportScope.WORKSPACE_AND_ALL_DESCENDANTS]:
          "Mi espacio de trabajo y todos los descendientes",
      },
      recipients: {
        allAdminUsers: "Todos los usuarios administradores del espacio de trabajo",
        specificAdminUsers: "Usuarios administradores específicos",
      },
    },
  },
  dateRangePicker: {
    cancel: "Cancelar",
    reset: "Restablecer",
    select: "Seleccionar",
    lastThreeDays: "Últimos 3 días",
    lastSevenDays: "Últimos 7 días",
    lastThirtyDays: "Últimos 30 días",
    lastNinetyDays: "Últimos 90 días",
    last365Days: "Últimos 365 días",
    all: "Todo",
    today: "Hoy",
    yesterday: "Ayer",
    thisMonth: "Este mes",
    lastMonth: "Mes pasado",
  },
  devicesSettings: {
    title: "Seguridad de Endpoint",
    devicesTitle: "Dispositivos",
    description: "Desplegar agentes de protección de endpoint Coro en dispositivos de usuarios",
    helpText: "Necesito ayuda con esto",
    gpoDeployment: "Necesito despliegue masivo",
    universalDownloadCard: {
      title: "Enviar un enlace de descarga universal por correo electrónico",
      subtitle:
        "Los destinatarios utilizarán este enlace para instalar y activar los agentes de protección de Coro en sus dispositivos.",
      generateLink: "Generar nuevo enlace de invitación",
      copyLinkBtn: "Copiar enlace",
    },
    windowsDownloadCard: {
      title: "Descargar para Windows",
      subtitle:
        "Instalar de forma remota en múltiples dispositivos Windows utilizando una herramienta de despliegue masivo de su elección.",
    },
    macOSDownloadCard: {
      title: "Descargar para macOS",
      subtitle:
        "Instalar de forma remota en múltiples dispositivos macOS utilizando una herramienta de despliegue masivo de su elección.",
    },
    tabs: {
      agentDeployment: "Despliegue de Agentes",
      settings: "Configuraciones",
      devicePosture: "Postura del Dispositivo",
      labels: "Etiquetas",
      ngav: "NGAV",
      allowBlock: "Permitir/Bloquear",
      addons: "Complementos",
    },
    allowBlockList: {
      applyToChildWorkspaces:
        "Aplicar reglas de permitir/bloquear a todos los espacios de trabajo secundarios",
      applyFilesToChildWorkspaces:
        "Aplicar reglas de permitir/bloquear para archivos y carpetas a todos los espacios de trabajo secundarios",
      applyProcessesToChildWorkspaces:
        "Aplicar reglas de permitir/bloquear para procesos a todos los espacios de trabajo secundarios",
      table: {
        value: "Valor",
        list: "Lista",
        description: "Descripción",
      },
      actions: {
        [DeviceAllowListActions.ADD_PROCESS_RECORD]: "Agregar registro de proceso",
        [DeviceAllowListActions.ADD_FOLDER_RECORD]: "Agregar registro de carpeta",
        [DeviceAllowListActions.ADD_FILE_RECORD]: "Agregar registro de archivo",
        [DeviceAllowListActions.REMOVE_RECORD]: "Eliminar registro",
        [DeviceAllowListActions.EDIT_ALLOW_BLOCK_LIST_RECORD]: "Editar registro",
        [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: "Importar desde CSV",
        [DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST]: "Agregar a la lista blanca",
        [DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST]: "Agregar a la lista negra",
      },
      filters: {
        type: "Tipo",
        types: {
          [DeviceAllowBlockListTypes.EDR_FOLDER]: "Carpeta",
          [DeviceAllowBlockListTypes.FOLDER]: "Carpeta",
          [DeviceAllowBlockListTypes.EDR_FILE]: "Archivo",
          [DeviceAllowBlockListTypes.FILE]: "Archivo",
          [DeviceAllowBlockListTypes.PROCESS]: "Proceso",
        },
      },
    },
    agentDeploymentTab: {
      version: "Versión",
      released: "Liberado",
      channel: "Canal",
      devices: "Dispositivos",
      universalLink: "Enlace universal",
      beta: "Beta",
      stable: "Estable",
      copyLink: "Copiar enlace",
      releaseNotes: "Notas de la versión",
      osTypes: {
        osx: "macOS",
        windows: "Windows",
      },
      actions: {
        copyDownloadLink: "Copiar enlace",
        download: "Descargar",
      },
      channels: {
        [AgentDeploymentChannel.BETA]: "Beta",
        [AgentDeploymentChannel.RELEASE_CANDIDATE]: "Candidato a liberación",
        [AgentDeploymentChannel.GRADUAL_ROLLOUT]: "Despliegue gradual",
        [AgentDeploymentChannel.GENERAL_AVAILABILITY]: "Disponibilidad general",
      },
    },
    settingsCard: {
      heartbeatInterval: "Intervalo de latido",
      selectOption: "Cada minuto | Cada {n} minutos",
      heartbeatIntervalDescription:
        "Un intervalo de latido más corto puede causar una carga adicional en la gestión. Un intervalo de latido más largo puede llevar a registros y reportes menos actualizados.",
      enableTamperResistance: "Prevenir desinstalación del agente",
      tamperProtectionDescription:
        "Previene que los usuarios desinstalen manualmente el agente de endpoint, asegurando que la seguridad se mantenga en todos los dispositivos.",
      vssPolicyEnabled: "Copias de seguridad de sombra seguras",
      vssPolicyDescription:
        "Impone instantáneas de respaldo cada cuatro horas y bloquea procesos que representan riesgos para el respaldo.",
      atcEnabled: "Control de Amenazas Avanzado",
      atcDescription:
        "Monitorea procesos activos en busca de amenazas conocidas y potenciales, y bloquea procesos que exhiben comportamiento sospechoso mientras no están explícitamente en la lista blanca.",
      initialDevicesScan: "Habilitar un escaneo inicial de malware y ransomware",
      initialDevicesScanDescription:
        "Impone un escaneo de malware del dispositivo cuando el agente se instala inicialmente. Escaneos más profundos siempre se pueden iniciar de forma remota, en cualquier momento.",
      wifiPhishing: "Phishing WiFi",
      wifiPhishingDescription:
        "Detecta conexiones a puntos de acceso WiFi que son sospechosos de ataques de intermediarios destinados a secuestrar la comunicación del dispositivo.",
      realTimeMalwareProtection: "Protección en Tiempo Real contra Malware y Ransomware",
      applyToDevices: "Aplicar a dispositivos con estas etiquetas",
      deviceLabelsPlaceholder: "Etiquetas de dispositivo",
      autoUpdateTitle: "Permitir autoactualización a la última versión estable",
      allowToUninstallTitle: "Permitir desinstalar el agente Coro",
      passcode: "Código de acceso",
      regenerateBtn: "Regenerar",
      autoUpdateLabelsDescription:
        "En dispositivos macOS, el proceso de actualización solicitará la aprobación del usuario. En dispositivos Windows, la actualización será completamente automática.",
      visibilityMode: "Modo de visibilidad",
      visibilityModeDescription:
        "Restringir a los agentes de endpoint Coro a solo detección, sin acciones de remediación de respuesta automática.",
      enforceContainerQuarantine: "Cuarentena de Contenedores Infectados",
      enforceContainerQuarantineDescription:
        "Cuando la Protección de Endpoint Coro detecta un archivo malicioso dentro de un contenedor (archivo comprimido) y no puede extraerlo, pondrá en cuarentena todo el contenedor.",
    },
    devicePostureTab: {
      vulnerability: "Vulnerabilidad",
      action: "Acción",
      policies: "Políticas",
      required: "Requerido",
      policiesQuantity: "{n} política | {n} políticas",
      noPolicies: "Sin políticas",
      osTypes: {
        macOsSettingsLabeled: "macOS",
        windowsSettingsLabeled: "Windows",
        windowsServerSettingsLabeled: "Windows Server",
      },
      everyXDays: "Cada día | Cada {n} días",
      networks: "{n} red | {n} redes",
      encrypted: "Encriptado",
      choices: {
        enforce: "Aplicar",
        review: "Revisar",
        ignore: "Ignorar",
      },
      actions: {
        addDevicePosturePolicy: "Agregar a {service}",
        deleteDevicePosturePolicy: "Eliminar Política",
        editDevicePosturePolicy: "Editar Política",
      },
      posturePolicies: {
        uacNotificationMissing: "Notificación UAC Faltante",
        missingPinAndPasswordProtection: "Falta Protección con PIN y Contraseña",
        isUnencrypted: "Unidad de Endpoint No Encriptada",
        isDeveloperModeEnabled: "Modo de Desarrollo Habilitado",
        firewallUnavailable: "Firewall Desactivado",
        isNonGenuineWindows: "Copia de Windows No Genuina",
        wifiSettings: "Conexión Wi-Fi",
        dlpScanScheduleSettings: "Escaneos de Datos Sensibles",
        userAccountPolicies: "Bloqueo Remoto de Contraseña y Sesión",
        gatekeeperUnavailable: "Gatekeeper Desactivado",
        appleMobileFileIntegrityUnavailable: "Integridad de Archivos Móviles de Apple Desactivada",
        systemIntegrityProtectionUnavailable: "Protección de Integridad del Sistema Desactivada",
        usbLockdown: "Bloqueo de USB",
      },
    },
    labelsTab: {
      addLabel: "Agregar etiqueta",
      deviceCount: "No. de dispositivos",
      actions: {
        editLabel: "Editar",
        deleteLabel: "Eliminar",
      },
      noLabelsTitle: "Gestionar dispositivos con diferentes etiquetas",
      noLabelsSubtitle:
        "Crea etiquetas para dispositivos para que puedas agrupar y gestionar tus dispositivos más fácilmente.",
      addNewLabel: "Agregar nueva etiqueta",
      labelInUseTooltip: "Etiqueta en uso en la configuración del dispositivo",
    },
    channels: {
      all: "Todos los Canales",
      stable: "Canales Estables",
    },
  },
  phishingSettings: {
    applyToChildWorkspaces:
      "Aplicar reglas de permitir/bloquear a todos los espacios de trabajo hijos",
    title: "Phishing",
    description:
      "Defensa contra ataques de ingeniería social como phishing por correo electrónico, ataques de correo electrónico dirigidos y phishing por WiFi",
    noEmailServicesConnected:
      "Conecta tu <b>Microsoft 365</b> o <b>G-Suite</b> de la empresa a Coro para permitir una protección anti-phishing transparente",
    connectCloudAppsBtn: "Conectar Aplicaciones en la Nube",
    tabs: {
      settings: "Configuraciones",
      allowBlockList: "Permitir/Bloquear",
    },
    allowed: "Permitido",
    blocked: "Bloqueado",
    domain: "Dominio",
    email: "Correo Electrónico",
    allow: "Lista de Permitidos",
    block: "Lista de Bloqueados",
    local: "Contenido sospechoso {listType}",
    auth: "Fallo de autenticación {listType}",
    table: {
      name: "Nombre",
      list: "Lista",
    },
    actions: {
      addToAllowlist: "Agregar a la lista de permitidos",
      addToBlocklist: "Agregar a la lista de bloqueados",
      remove: "Eliminar",
      importFromCSV: "Importar desde CSV",
    },
    card: {
      title: "Phishing por Correo Electrónico [Obsoleto]",
      contentScan: "Contenido",
      suspiciousImpersonation: "Suplantación sospechosa",
      suspiciousLinksAndAttachments: "Enlaces y archivos adjuntos sospechosos",
      phishingTypeContent: "Contenido de tipo phishing",
      feedbackTitle: "Comentarios de Usuarios",
      feedbackDescription:
        "Para permitir comentarios de tus usuarios sobre correos electrónicos sospechosos y mal clasificados, instala el complemento de Coro:",
      feedbackDisclaimer:
        "Coro recopila y agrega continuamente comentarios de usuarios sobre clasificaciones inexactas de correos electrónicos y los proporciona para tu examen.",
      wifiPhishing: "Phishing por WiFi",
      wifiPhishingDescription:
        "Detecta conexiones a puntos de acceso WiFi sospechosos que pueden ser utilizados para robar datos de usuarios, incluidos credenciales de inicio de sesión y números de tarjetas de crédito.",
    },
    allEntities: "Todas las entidades",
    allLists: "Todas las listas",
  },
  ticketDetails: {
    processed: "Cerrado",
    triggers: "Disparadores",
    severity: "Severidad",
    rules: "Reglas",
    rule: "regla | reglas",
    service: "Servicio",
    actions: "Acciones",
    user: "Usuario",
    lastSeen: "Última vez visto",
    firstSeen: "Primera vez visto",
    duration: "Duración",
    showFindings: "Mostrar Hallazgos",
    certificates: "Certificados",
    sourceCode: "Código fuente",
    otherFileTypes: "Otros tipos de archivos",
    sharedBy: "Compartido por",
    sharedWith: "Compartido con",
    receiveTime: "Hora de recepción",
    happened: "Ocurrió {n} vez | Ocurrió {n} veces",
    downloaded: "Descargado {n} vez | Descargado {n} veces",
    happenedMoreThanForHundredTimes: "Ocurrió más de 400 veces",
    downloadedMoreThanForHundredTimes: "Descargado más de 400 veces",
    registryValue: "Valor del registro",
    processName: "Nombre del proceso",
    threatStatus: "Estado de la amenaza",
    hostName: "Nombre del host",
    osVersion: "Versión del SO",
    deviceId: "ID del dispositivo",
    affectedDevices: "Dispositivos afectados",
    upn: "UPN",
    times: "{n} vez | {n} veces",
    deviceLoginUserName: "Nombre de usuario de inicio de sesión del dispositivo",
    userName: "Nombre de usuario",
    email: "Correo electrónico",
    addProtectionBtn: "Agregar Protección",
    notProtectedUsersNotice: "Usuario no está protegido | {n} usuarios no están protegidos",
    lastModifiedBy: "Última modificación por",
    lastModifiedTime: "Última hora de modificación",
    interfaceType: "Tipo de interfaz",
    driveModel: "Modelo de unidad",
    driveName: "Nombre de la unidad",
    processPath: "Ruta del proceso",
    processHash: "Hash del proceso",
    processGroup: "Grupo de procesos",
    type: "Tipo",
    threatType: "Tipo de amenaza",
    suspiciousCharacteristics: "Características sospechosas",
    reports: "Informes",
    users: "Usuarios",
    lastUsersOnDevice: "Últimos usuarios en el dispositivo",
    when: "Cuándo",
    from: "Desde",
    to: "Hasta",
    internetMessageId: "ID del mensaje",
    senderIp: "IP del remitente",
    attachments: "Adjunto | Adjuntos",
    labels: "Etiquetas",
    keyDetails: {
      title: "Detalles Clave",
    },
    threatTypes: {
      virus: "Virus",
      spyware: "Spyware",
      ransomware: "Ransomware",
      adware: "Adware",
      dialer: "Marcador",
      potentiallyMaliciousApp: "Aplicación potencialmente maliciosa",
      archiveBombFile: "Archivo bomba comprimido",
      rootkit: "Rootkit",
      spam: "Spam",
      suspiciousBehavior: "Comportamiento sospechoso",
      trojan: "Troyano",
      worm: "Gusano",
      backdoor: "Puerta trasera",
      tool: "Herramienta",
      unknown: "Desconocido",
      potentiallyUnwantedSoftware: "Software potencialmente no deseado",
    },
    eventActions: {
      treatFileAsSafe: "Aprobar este archivo",
      treatFileAsMalicious: "Descartar este archivo",
      excludeFolderFromScan: "Excluir carpeta del escaneo de malware",
      enableFirewall: "Habilitar firewall",
      remoteScan: "Escaneo remoto para malware",
      markAsProcessed: "Cerrar ticket",
      markAsUnprocessed: "Reabrir",
      suspendFromAll: "Suspender usuario de todas las aplicaciones en la nube",
      suspendFromService: "Suspender usuario de {service}",
      signInToAll: "Solicitar a usuario que inicie sesión en todas las aplicaciones en la nube",
      signInToService: "Solicitar a usuario que inicie sesión en {service}",
      enforceUAC: "Hacer cumplir la notificación UAC",
      disableDevMode: "Desactivar modo desarrollador",
      inspectEmail: "Inspeccionar correo electrónico",
      approveEmailsFromSender: "Agregar remitente a la lista de permitidos",
      approveEmailsFromDomain: "Agregar dominio del remitente a la lista de permitidos",
      discardEmailsFromSender: "Agregar remitente a la lista de bloqueados",
      discardEmailsFromDomain: "Agregar dominio del remitente a la lista de bloqueados",
      approveEmail: "Aprobar este correo electrónico",
      discardEmail: "Eliminar este correo electrónico",
      removeExposingSharing: "Eliminar compartición expuesta",
      contactUser: "Contactar usuario",
      allowNoEncryption: "Permitir sin cifrado",
      approveProcessGroup: "Aprobar grupo de procesos",
      discardInfectingFile: "Descartar archivo infectante",
      approveFile: "Aprobar archivo",
      deleteFile: "Eliminar archivo",
      logForAuditReports: "Registrar y hacer referencia para informes de auditoría",
      unLogForAuditReports: "Desregistrar y eliminar de informes de auditoría",
      stopRemoteScan: "Detener escaneo remoto para malware",
      encryptDrive: "Cifrar unidad",
      addToDataGovernancePermissions: "Agregar a permisos de gobernanza de datos",
      downloadEmlFile: "Descargar archivo EML",
      enableGatekeeper: "Habilitar Gatekeeper",
      enableAppleMobileFileIntegrity: "Habilitar integridad de archivos móviles de Apple",
      approveEmailWithOptions: "Permitir",
      discardEmailWithOptions: "Bloquear",
      generalApprove: "Permitir",
      exportMassDownloadFiles: "Exportar lista de archivos",
      exportMassDeleteFiles: "Exportar lista de archivos",
      isolateDevice: "Aislar dispositivo",
      blockProcess: "Bloquear proceso",
      unblockProcess: "Desbloquear proceso",
      enableNetworkBlockMode: "Aislar dispositivos afectados de la red",
      disableNetworkBlockMode: "Deshabilitar aislamiento de dispositivos de la red",
      rebootDevice: "Reiniciar dispositivos",
      shutdownDevice: "Apagar dispositivos",
      allowProcess: "Permitir proceso",
    },
    eventBulkActions: {
      markAsProcessed: "Cerrar ticket | Cerrar tickets",
      markAsUnprocessed: "Reabrir ticket | Reabrir tickets",
      bulkAddComment: "Agregar comentario",
      exportCsv: "Exportar CSV",
    },
    actionTypes: {
      suspendUser: "Suspender usuario de todos los servicios",
      alertUser: "Alertar usuario",
    },
    fileName: "Nombre del archivo",
    fileSize: "Tamaño del archivo",
    path: "Ruta del archivo",
    allFiles: "Todos los archivos",
    link: "Enlace",
    file: "Archivo",
    ip: "IPs",
    ipAddress: "Dirección IP",
    ipAndCountry: "IP / País",
    ticketDetails: "Detalles del ticket",
    eventHappenedMessages: {
      default: "Ocurrió el evento",
      suspectedBotAttacks: "Fallo de inicio de sesión",
      suspiciousDownload: "Descargado",
      potentialRansomwareActivity: "Archivos sospechosos",
    },
    filesCount: "{n} archivo | {n} archivos",
    suspiciousFiles: "Archivos sospechosos",
    virusName: "Nombre del virus",
    virusType: "Tipo de virus",
    unknownVirus: "Desconocido (Detectado por el Servicio)",
    owner: "Propietario",
    sender: "Remitente",
    senderName: "Nombre del remitente",
    senderEmail: "Correo electrónico del remitente",
    recipients: "Destinatarios",
    subject: "Asunto",
    suspiciousContentLocation: "Ubicación del contenido sospechoso",
    sharedAt: "Compartido en",
    foundIn: "Encontrado en",
    performedBy: "Realizado por",
    ownedBy: "Propiedad de",
    lastModified: "Última modificación",
    created: "Creado",
    mimeType: "Tipo MIME",
    country: "País",
    foundInTypes: {
      attachment: "Adjunto de correo electrónico",
      body: "Cuerpo del correo electrónico",
      subject: "Asunto del correo electrónico",
    },
    emailSubject: "Asunto del correo electrónico",
    emailSender: "Remitente del correo electrónico",
    inspectEmail: "Inspeccionar correo electrónico",
    device: "Dispositivo",
    fileHash: "Hash del archivo",
    filePath: "Ruta del archivo",
    enrollmentCode: "Código de inscripción | Códigos de inscripción",
    model: "Modelo",
    os: "SO",
    network: "Red",
    ssid: "SSID",
    bssid: "BSSID",
    security: "Seguridad",
    findings: "Hallazgos",
    additionalFindings: "Hallazgos adicionales",
    process: "Proceso",
    hash: "Hash",
    allowedOn: "Proceso permitido en {date}",
    blockedOn: "Proceso bloqueado en {date}",
    incidentFindings: {
      type: {
        PHISHING_GENERAL: "Phishing por correo electrónico [Obsoleto]",
        PHISHING_ABNORMAL_SENDER: "Remitente anormal",
        MALWARE_DETECTION: "Archivo de malware",
        UNMET_ENFORCED_AUTHENTICATION_REQUIREMENTS:
          "No se cumplen los requisitos de autenticación forzada",
        UNVERIFIED_SENDER: "Remitente no verificado",
        SPEAR_PHISHING_USER_IMPERSONATION: "Spear phishing: Suplantación de usuario",
        SUSPICIOUS_ENVELOPE: "Sobre sospechoso",
        COUSIN_DOMAIN_DOMAIN_IMPERSONATION: "Dominio primo: Suplantación de marca",
        MESSAGE_HONEYPOT_BRAND_IMPERSONATION: "Mensaje honeypot: Suplantación de marca",
        BRAND_DOMAIN_SPOOFING: "Suplantación de dominio de marca",
        ENVELOPE_HONEYPOT_DOMAIN_IMPERSONATION: "Sobre honeypot: Suplantación de dominio",
        COUSIN_DOMAIN_BRAND_IMPERSONATION: "Dominio primo: Suplantación de dominio",
        DOMAIN_SPOOFING: "Suplantación de dominio",
        UNAUTHENTICATED_SELF_NOTE: "Nota propia no autenticada",
        HOMOGRAPHIC_DOMAIN_SPOOFING: "Suplantación de dominio homográfico",
        ENVELOPE_HONEYPOT_USER_IMPERSONATION: "Sobre honeypot: Suplantación de usuario",
        ENVELOPE_HONEYPOT_BRAND_IMPERSONATION: "Sobre honeypot: Suplantación de marca",
        HOMOGRAPHIC_USER_ADDRESS_SPOOFING: "Suplantación de dirección de usuario homográfica",
        HOMOGRAPHIC_USER_NAME_SPOOFING: "Suplantación de nombre de usuario homográfico",
        ABNORMAL_SENDER: "Remitente anormal",
        MALICIOUS_URL: "Enlace malicioso",
        MALICIOUS_QR_CODE: "Código QR sospechoso",
        EMAIL_MALWARE_ACTIVITY: "Malware en archivos adjuntos de correo electrónico",
        MALICIOUS_SENDER: "Remitente malicioso",
        PHISHING_TEST: "Prueba de phishing",
        CREDIT_CARD_NUMBER: "Número de tarjeta de crédito",
        IBAN_CODE: "Código IBAN",
        SWIFT_CODE: "Código SWIFT",
        US_BANK_ROUTING_MICR: "Número de ruta bancaria de EE. UU.",
        AMERICAN_BANKERS_CUSIP_ID: "ID CUSIP de banqueros estadounidenses",
        EMAIL_ADDRESS: "Dirección de correo electrónico",
        IP_ADDRESS: "Dirección IP",
        IP_ADDRESS_KEYWORD: "Dirección IP",
        MAC_ADDRESS: "Dirección MAC",
        MAC_ADDRESS_KEYWORDS: "Dirección MAC",
        PERSON_NAME: "Nombre de la persona",
        ADDRESS: "Dirección",
        IMEI_HARDWARE_ID: "ID de hardware IMEI",
        PHONE_NUMBER: "Número de teléfono",
        PHONE_NUMBER_KEYWORD: "Número de teléfono",
        US_PASSPORT: "Pasaporte de EE. UU.",
        US_PASSPORT_KEYWORD: "Pasaporte de EE. UU.",
        DRIVER_LICENSE: "Licencia de conducir",
        DRIVER_LICENSE_KEYWORD: "Licencia de conducir",
        DRIVER_LICENSE_NUMBER: "Número de licencia de conducir",
        BANK_ROUTING_NUMBER: "Número de ruta bancaria",
        BANK_ROUTING_NUMBER_KEYWORD: "Número de ruta bancaria",
        FINANCIAL_METADATA: "Metadatos financieros",
        FINANCIAL_CONTENT_KEYWORD: "Contenido financiero",
        FINANCIAL_CONTENT: "Contenido financiero",
        US_SOCIAL_SECURITY_NUMBER: "Número de seguro social de EE. UU.",
        US_SOCIAL_SECURITY_NUMBER_KEYWORD: "Seguro social de EE. UU.",
        US_BANK_KEYWORD: "Banco de EE. UU.",
        US_EMPLOYER_IDENTIFICATION_NUMBER: "Número de identificación del empleador de EE. UU.",
        TAXPAYER_IDENTIFICATION_NUMBER: "Número de identificación del contribuyente",
        TAXPAYER_IDENTIFICATION_NUMBER_KEYWORD: "Número de identificación del contribuyente",
        US_INDIVIDUAL_TAXPAYER_IDENTIFICATION_NUMBER:
          "Número de identificación del contribuyente individual de EE. UU.",
        VEHICLE_IDENTIFICATION_NUMBER: "Número de identificación del vehículo",
        US_VEHICLE_IDENTIFICATION_NUMBER: "Número de identificación del vehículo de EE. UU.",
        CANADA_PASSPORT: "Pasaporte de Canadá",
        CANADA_SOCIAL_INSURANCE_NUMBER: "Número de seguro social de Canadá",
        ISRAEL_ID: "ID de Israel",
        ICD9_CODE: "Código ICD9",
        ICD10_CODE: "Código ICD10",
        FDA_CODE: "Código FDA",
        US_HEALTHCARE_NPI: "NPI de atención médica de EE. UU.",
        US_HEALTHCARE_NPI_KEYWORD: "NPI de atención médica de EE. UU.",
        US_DEA_NUMBER: "Número DEA de EE. UU.",
        US_DEA_NUMBER_KEYWORD: "Número DEA de EE. UU.",
        CANADA_QUEBEC_HIN: "HIN de Quebec, Canadá",
        HEALTH_KEYWORDS: "Palabras clave de salud",
        DATE_OF_BIRTH: "Fecha de nacimiento",
        ISBN: "ISBN",
        ISBNKeyword: "ISBN",
        PASSWORD: "Contraseña",
        PASSWORD_KEYWORD: "Contraseña",
        KEYWORDS: "Palabras clave",
        REGEX: "Regex",
        PHISHING_SUSPICIOUS_SUBJECT: "Asunto sospechoso",
        PHISHING_SUSPICIOUS_TAGS_IN_ATTACHMENT: "Contenido de archivo adjunto sospechoso",
        MSP_PHISHING_BLOCKED_SENDER: "Remitente sospechoso (en lista bloqueada)",
        MSP_PHISHING_BLOCKED_URL: "Contenido incrustado sospechoso (en lista bloqueada)",
        BLOCKLISTED_DOMAIN: "Dominio en lista bloqueada",
        BLOCKLISTED_SENDER: "Remitente en lista bloqueada",
        REPORTED_BY_USER: "Informado por el usuario",
        HEALTH_CODE: "Código de salud",
        IBAN: "IBAN",
        SWIFT: "SWIFT",
        SWIFT_KEYWORD: "SWIFT",
        ANNUAL_CREDIT_REPORT_FORM: "Informe de crédito anual",
        ANNUAL_CREDIT_REPORT_FORM_KEYWORD: "Informe de crédito anual",
        BILL_OF_SALE_FORM: "Factura de venta",
        BILL_OF_SALE_FORM_KEYWORD: "Factura de venta",
        INSURANCE_CARD_FORM: "Tarjeta de seguro",
        INSURANCE_CARD_FORM_KEYWORD: "Tarjeta de seguro",
        FR44_FORM: "FR44",
        FR44_FORM_KEYWORD: "FR44",
        SR22_FORM: "SR22",
        SR22_FORM_KEYWORD: "SR22",
        PAY_STUB_FORM: "Comprobante de pago",
        PAY_STUB_FORM_KEYWORD: "Comprobante de pago",
        MISC_1099_FORM: "MISC 1099",
        MISC_1099_FORM_KEYWORD: "MISC 1099",
        BANK_STATEMENT_FORM: "Extracto bancario",
        BANK_STATEMENT_FORM_KEYWORD: "Extracto bancario",
        PERSONAL_NET_WORTH_FORM: "Valor neto personal",
        PERSONAL_NET_WORTH_FORM_KEYWORD: "Valor neto personal",
        VEHICLE_REGISTRATION_APPLICATION_FORM: "Solicitud de registro de vehículo",
        VEHICLE_REGISTRATION_APPLICATION_FORM_KEYWORD: "Solicitud de registro de vehículo",
        LEASE_AGREEMENT_FORM: "Contrato de arrendamiento",
        LEASE_AGREEMENT_FORM_KEYWORD: "Contrato de arrendamiento",
        CAR_TITLE_FORM: "Título del automóvil",
        CAR_TITLE_FORM_KEYWORD: "Título del automóvil",
        CREDIT_CARD_STATEMENT_FORM: "Extracto de tarjeta de crédito",
        CREDIT_CARD_STATEMENT_FORM_KEYWORD: "Extracto de tarjeta de crédito",
        W2_FORM: "W2",
        W2_FORM_KEYWORD: "W2",
        ODOMETER_DISCLOSURE_FORM: "Divulgación de odómetro",
        ODOMETER_DISCLOSURE_FORM_KEYWORD: "Divulgación de odómetro",
        USERNAME: "Nombre de usuario",
        CUSTOM_KEYWORD: "Personalizado",
        US_EMPLOYER_ID_NUMBER: "Número de identificación del empleador de EE. UU.",
        US_EMPLOYER_ID_NUMBER_KEYWORD: "Número de identificación del empleador de EE. UU.",
        MEDICAL_BENEFICIARY_IDENTIFIER: "Identificador de beneficiario médico",
        MEDICAL_BENEFICIARY_IDENTIFIER_KEYWORD: "Identificador de beneficiario médico",
        MEDICAL_RECORDS_NUMBER_KEYWORD: "Número de registros médicos",
        DATE: "Fecha",
        DATE_KEYWORD: "Fecha",
        INSURANCE_PLAN_PAYMENT: "Pago del plan de seguro",
        GENERAL_MEDICAL_KEYWORD: "Información médica",
        MEDICAL_FILE: "Expediente médico",
        CREDIT_CARD_NUMBER_KEYWORD: "Número de tarjeta de crédito",
        VEHICLE_IDENTIFICATION_NUMBER_KEYWORD: "Número de identificación del vehículo",
        US_ADDRESS: "Dirección de EE. UU.",
        HEALTH_INSURANCE_CLAIM_FORM: "Reclamo de seguro de salud",
        US_DEATH_CERTIFICATE_FORM: "Certificado de defunción de EE. UU.",
        US_BIRTH_CERTIFICATE_FORM: "Certificado de nacimiento de EE. UU.",
        IMEI: "IMEI",
        PHISHING_SUSPICIOUS_CONTENT: "Contenido sospechoso de phishing",
        BLOCKED_ATTACHMENT_TYPE: "Tipo de archivo adjunto bloqueado",
        CUSTOM_FILE_TYPE: "Tipo de archivo personalizado",
        MISSING_REQUIRED_AUTHENTICATION: "Falta autenticación requerida",
        CROWDBLOCKED_SENDER: "Remitente bloqueado por Crowd",
        MALWARE_IN_EMAIL_ATTACHMENT: "Malware en archivo adjunto de correo electrónico",
        USER_IMPERSONATION: "Suplantación de usuario",
        DOMAIN_IMPERSONATION: "Suplantación de dominio",
        BRAND_IMPERSONATION: "Suplantación de marca",
        FORBIDDEN_ATTACHMENT_TYPE: "Tipo de archivo adjunto prohibido",
        SUSPICIOUS_CONTENT: "Contenido sospechoso",
        SENDER_OR_DOMAIN_IN_GLOBAL_BLOCKLIST: "Remitente/dominio/IP en la lista negra global",
        SPF_AUTHENTICATION_FAILED: "Remitente no verificado",
        MALICIOUS_SENDER_DOMAIN: "Dominio de remitente malicioso",
        SPAM_CONTENT: "Contenido de spam",
      },
    },
    fullDetails: {
      title: "Detalles completos",
      notAvailable: "Los detalles completos no están disponibles",
      unknownDetectedBy: "Desconocido ({detectedBy})",
      detectedByBitdefender: "Detectado por BitDefender",
      detectedByMicrosoftDefender: "Detectado por Microsoft Defender",
      detectedByBoxShield: "Detectado por Box Shield",
      detectedByService: "Detectado por el servicio",
      emailAttachments: "Adjuntos de correo electrónico",
      emailContent: "Contenido del correo electrónico",
      anomalyDetection: {
        login: "Inicio de sesión",
        failedLogin: "Inicio de sesión fallido",
        share: "Compartir",
        upload: "Subir",
        download: "Descargar",
        collaborationInvite: "Invitación a colaborar",
        admin: "Inicio de sesión de administrador",
        countryAndIp: "País / IP",
        time: "Hora",
        type: "Tipo de registro",
        delete: "Eliminar",
        orgName: "ISP",
        threatType: "Tipo de amenaza",
        proxy: "Proxy",
        lastDetected: "Última detección:",
      },
      accessViolations: {
        title: "Violaciones de políticas",
        share: "El usuario {user} no tiene permiso para exponer {trigger}.",
        access: "El usuario {user} no tiene permiso para acceder a {trigger}.",
        dlpPci: "PCI",
        dlpPhi: "PHI",
        dlpPii: "PII",
        dlpNpi: "NPI",
        criticalDataSourceCode: "código fuente",
        criticalDataPassword: "contraseña",
        criticalDataCertificate: "certificado",
        criticalDataKeywords: "palabras clave",
        criticalDataFileTypes: "tipos de archivo",
      },
      devicePasswordMissing: {
        title: "Usuarios sin acceso por contraseña a este dispositivo",
        entry: "Usuario {userIdentifier}.",
      },
      fileFindings: {
        title: "Hallazgos",
        count: "# de hallazgos",
      },
      abnormalDataManipulation: {
        countryOrIP: "País / IP",
        host: "Host",
        files: "Archivos",
        name: "Nombre",
        mimeType: "Tipo MIME",
        folder: "Ruta",
        filesAffected: "Archivos afectados",
        massDownloadFiles: "Archivos descargados",
        massDeleteFiles: "Archivos eliminados",
      },
      edrAffectedDevices: {
        device: "Dispositivo",
        executionTime: "Tiempo de ejecución",
        commandLine: "Línea de comandos",
        path: "Ruta",
        processChain: "Cadena de procesos",
        mitre: "Mitre",
        insights: "Perspectivas",
      },
    },
    filters: {
      type: "Tipo",
      during: "Durante",
      status: "Estado",
      searchTickets: "Buscar tickets...",
      widgets: {
        placeholder: "En todas partes",
        deviceSecurityWidget: "Seguridad de Endpoint",
        cloudSecurity: "Seguridad en la nube",
        emailsWidget: "Seguridad de correo electrónico",
        usersWidget: "Usuarios",
        userDataGovernance: "Gobernanza de datos de usuario",
        endpointDataGovernance: "Gobernanza de datos de endpoint",
        edr: "EDR",
      },
    },
  },
  eventsPage: {
    duration: "Duración",
    whoShared: "Quién compartió",
    withWhom: "Con quién",
    user: "Usuario",
    sentBy: "Enviado por",
    sentTo: "Enviado a",
    lastLoggedInUser: "Último usuario conectado",
    more: "+ {n} más",
  },
  edr: {
    title: "Detección y Respuesta de Endpoint",
    isolatedFromNetwork: "Aislado de la red",
    tabs: {
      telemetry: "Telemetría",
      processes: "Procesos",
      allowBlockList: "Permitir/Bloquear",
    },
    table: {
      processes: "{n} proceso | {n} procesos",
      devices: "{n} dispositivo | {n} dispositivos",
      lastSeen: "Última vez visto",
      multipleNameProcess: "Proceso con múltiples nombres",
      blocked: "Bloqueado",
      blockedOn: "Bloqueado en",
    },
    filters: {
      during: "Durante",
      types: "Tipos",
    },
    actions: {
      isolateDevice: "Aislar dispositivo | Aislar dispositivos",
      blockProcess: "Bloquear proceso | Bloquear procesos",
      allowProcess: "Permitir proceso | Permitir procesos",
      unblockProcess: "Desbloquear proceso | Desbloquear procesos",
      enableNetworkBlockMode:
        "Aislar dispositivo afectado de la red | Aislar dispositivos afectados de la red",
      disableNetworkBlockMode:
        "Deshabilitar aislamiento de dispositivo de la red | Deshabilitar aislamiento de dispositivos de la red",
      rebootDevice: "Reiniciar dispositivo | Reiniciar dispositivos",
      shutdownDevice: "Apagar dispositivo | Apagar dispositivos",
      viewLog: "Ver registro completo",
    },
    details: {
      blockedOn: "Proceso bloqueado en ",
      hash: "Hash",
      devicesAffected: "Dispositivos",
      knownPaths: "Rutas conocidas",
      processAliases: "Alias de proceso",
      openTickets: "Tickets abiertos",
      isolated: "aislado",
      numberOfDevices: "Número de dispositivos",
      time: "Última vez visto",
      viewTelemetry: "Ver telemetría",
      viewOpenTickets: "Todos los tickets abiertos",
      firstExecutionDetails: "Detalles de la primera ejecución",
    },
    telemetryTab: {
      types: {
        registryKey: "Clave de registro",
        scheduledTask: "Tarea programada",
        accountEvent: "Evento de cuenta",
        usbDeviceActivity: "Actividad de dispositivo USB",
        groupPolicyChanges: "Cambios en la política de grupo",
      },
      table: {
        noData: {
          title: "No se encontraron resultados",
          description:
            "Por favor, refine su búsqueda, aplique diferentes filtros o limpie la búsqueda para intentar de nuevo",
          actionBtn: "Limpiar búsqueda y filtros",
        },
        forensic: "Forense",
        device: "Dispositivo",
        process: "Proceso",
        userName: "Nombre de usuario",
        eventId: "ID de evento",
        accountName: "Nombre de cuenta",
        processName: "Nombre de proceso",
        parentProcessName: "Nombre del proceso padre",
        processHash: "Hash",
        commandLine: "Línea de comandos",
        action: "Acción",
        trigger: "Disparador",
        operationType: "Tipo de operación",
        currentValue: "Valor actual",
        oldValue: "Valor antiguo",
        accountDomain: "Dominio de cuenta",
        targetServer: "Servidor de destino",
        detailedProcessInfo: "Información detallada del proceso",
        affectedDevices: "Dispositivos afectados",
        success: "Éxito",
        authenticationType: "Tipo",
        uid: "UID",
        userType: "Tipo de usuario",
        dbPath: "Ruta",
        instigator: "Instigador",
        usbDevice: "Dispositivo USB",
        friendlyName: "Nombre amigable",
        targetAccount: "Cuenta de destino",
        groupPolicyObjectName: "Nombre del objeto de política de grupo",
        passwordMinimumAgePolicy: "Política de edad mínima de contraseña",
        passwordLengthPolicy: "Política de longitud de contraseña",
      },
    },
    allowBlockListTab: {
      addProcessBtn: "Agregar proceso",
    },
  },
  activityLogs: {
    title: "Registros de actividad",
    search: "Buscar...",
    noData: "No se encontraron registros",
    undone: "Deshecho",
    automaticallyByCoro: "automáticamente, por Coro",
    actions: {
      undo: "Deshacer",
      download: "Descargar",
    },
    errors: {
      csvDownloadLinkExpired:
        "Los enlaces de descarga expiran después de 24 horas. Por favor, regenere el CSV",
      reportDownloadLinkExpired:
        "Los enlaces de descarga expiran después de 24 horas. Por favor, regenere el informe",
    },
    filters: {
      types: "Tipos",
      dateRange: "Rango de fechas",
      type: {
        [ActivityLogsType.ACCOUNT]: "Cuenta",
        [ActivityLogsType.CLOUD_APPS]: "Aplicaciones en la nube",
        [ActivityLogsType.DEVICE]: "Dispositivo",
        [ActivityLogsType.DLP]: "Gobernanza de datos de usuario",
        [ActivityLogsType.DEVICE_DLP]: "Gobernanza de datos de endpoint",
        [ActivityLogsType.EMAIL]: "Seguridad de correo electrónico",
        [ActivityLogsType.MALWARE]: "Malware",
        [ActivityLogsType.PHISHING]: "Phishing",
        [ActivityLogsType.UNDO]: "Deshacer",
        [ActivityLogsType.USERS]: "Usuarios",
        [ActivityLogsType.BILLING]: "Facturación",
        [ActivityLogsType.DETECTION_LOGS]: "Registros de detección",
        [ActivityLogsType.CSV_EXPORT]: "Exportación CSV",
        [ActivityLogsType.WORKSPACE]: "Espacio de trabajo",
        [ActivityLogsType.MSP]: "MSP",
        [ActivityLogsType.SIEM_CONFIG]: "Configuración SIEM",
        [ActivityLogsType.API_CREDENTIALS]: "Credenciales API",
        [ActivityLogsType.WORKSPACE_REPORT]: "Informe del espacio de trabajo",
        [ActivityLogsType.WORKSPACE_SCHEDULED_REPORT]: "Informe programado",
        [ActivityLogsType.SOC]: "Servicio administrado",
        [ActivityLogsType.MOBILE]: "Móvil",
        [ActivityLogsType.PSA]: "PSA",
        [ActivityLogsType.MDM_DEVICE]: "Dispositivo MDM",
        [ActivityLogsType.MDM_ACTION]: "Acción MDM",
        [ActivityLogsType.MDM_DEVICE_ACTION]: "Acción del dispositivo MDM",
        [ActivityLogsType.MDM_SYSTEM]: "Sistema MDM",
        [ActivityLogsType.WEBHOOK]: "Webhooks",
        mdm: "MDM",
      },
    },
  },
  thirdPartyApps: {
    columns: {
      thirdPartyApp: "Aplicación de terceros",
      cloudApplication: "Aplicación en la nube",
      users: "Usuarios",
    },
    filters: {
      cloudApps: "Aplicaciones en la nube",
      cloudAppsNoData: "No hay aplicaciones que coincidan con la búsqueda",
      status: "Estado",
    },
    status: {
      [ThirdPartyAppStatus.ENABLED]: "Permitido",
      [ThirdPartyAppStatus.DISABLED]: "Bloqueado",
    },
    connectionDate: "Fecha de conexión:",
    publisher: "Editor:",
    users: "{n} usuario | {n} usuarios",
    allow: "Permitir",
    block: "Bloquear",
  },
  emailProxy: {
    title: "Proxy de correo electrónico",
    addDomain: "Agregar dominio",
    directions: {
      inbound: "Entrante",
      outbound: "Saliente",
    },
    tabs: {
      inbound: "Entrante",
      outbound: "Saliente",
    },
    emailSecurityMode: {
      title: "Modo de seguridad del correo electrónico",
      types: {
        warning: "Solo advertencia",
        block: "Bloquear",
      },
      descriptions: {
        outbound: {
          warning:
            "Los correos electrónicos salientes sospechosos desde el punto de vista de la seguridad (phishing/malware) no se bloquean, pero se marcan con advertencias explicativas para los destinatarios.",
          block:
            "Los correos electrónicos salientes sospechosos desde el punto de vista de la seguridad (phishing/malware) se bloquean y solo pueden ser liberados de la cuarentena por los administradores del espacio de trabajo.",
        },
      },
    },
    dataGovernanceMode: {
      title: "Modo de gobernanza de datos",
      types: {
        warning: "Solo advertencia",
        block: "Bloquear",
      },
      descriptions: {
        inbound: {
          warning:
            "Los correos electrónicos entrantes con contenido sensible desde el punto de vista de la privacidad no se bloquean, pero se marcan con advertencias explicativas para los destinatarios.",
          block:
            "Los correos electrónicos entrantes con contenido sensible desde el punto de vista de la privacidad se bloquean y solo pueden ser liberados de la cuarentena por los administradores del espacio de trabajo.",
        },
        outbound: {
          warning:
            "Los correos electrónicos salientes con contenido sensible desde el punto de vista de la privacidad no se bloquean, pero se marcan con advertencias explicativas para los destinatarios.",
          block:
            "Los correos electrónicos salientes con contenido sensible desde el punto de vista de la privacidad se bloquean y solo pueden ser liberados de la cuarentena por los administradores del espacio de trabajo.",
        },
      },
    },
    enableProxyLink: "Cómo habilitar la puerta de enlace entrante",
    table: {
      title: "Usar proxy {direction} para estos dominios",
      headers: {
        domain: "Dominio",
        smpt: "Reenvío SMTP",
        host: "Host",
        port: "Puerto",
        status: "Estado de prueba",
        serverAddress: "Dirección del servidor",
        lastActive: "Última actividad",
      },
      proxies: "{n} proxy | {n} proxies",
      statuses: {
        passed: "Aprobado",
        notTested: "No probado",
        pending: "Pendiente",
        failed: "Fallido",
      },
      actions: {
        testDomain: "Probar",
        editDomain: "Editar",
        removeDomain: "Eliminar",
      },
      noOutgoingEmailsTooltip: "No ha habido correos electrónicos salientes en más de 12 horas",
      failedReason: {
        CORO_MX_IS_NOT_PRIMARY:
          "El MX principal del dominio no está reconocido como un servidor proxy SMTP de Coro. Verifica la configuración de tu DNS.",
        PROXY_POSTFIX_RELAY_HOST_NOT_REACHABLE:
          "Coro no puede alcanzar tu host de retransmisión SMTP. Verifica la configuración del host y del puerto.",
        PROXY_POSTFIX_SETTINGS_INACTIVE:
          "El host de retransmisión SMTP está inactivo. Contacta al soporte de Coro para activarlo.",
        GENERAL_VERIFICATION_FAIL:
          "La configuración del dominio de la puerta de enlace de entrada ha fallado. Verifica tu configuración e inténtalo nuevamente.",
        PROXY_VERIFICATION_TIMEOUT:
          "El mensaje de verificación no llegó al servidor proxy SMTP de Coro. Verifica la configuración de tu host de retransmisión SMTP.",
        USER_VERIFICATION_TIMEOUT:
          "El tiempo para la aprobación del correo electrónico de verificación ha expirado. Verifica el destinatario y repite la prueba.",
      },
    },
    noItems: {
      inbound: {
        description: "Agregar dominios de correo electrónico entrantes",
        subDescription: "El proxy no se habilitará si no se agregan dominios",
      },
      outbound: {
        description: "Agregar sus dominios de correo electrónico salientes",
        subDescription: "El proxy no se habilitará si no se agregan dominios",
      },
    },
    verification: {
      successMessage:
        "El proceso de verificación del proxy de correo electrónico se ha completado.",
      failureMessage1: "Algo salió mal :(",
      failureMessage2:
        "Parece que su tráfico de correo electrónico entrante no pasa por el proxy de seguridad de correo electrónico de Coro.",
      linkExpiredMessage1:
        "El enlace de verificación del proxy de puerta de enlace entrante ha expirado.",
      linkExpiredMessage2: "Por favor, reenvíe el correo de prueba y vuelva a intentarlo.",
    },
  },
  cloudApplications: {
    title: "Aplicaciones en la nube",
    description: "Monitorear y controlar el acceso a aplicaciones y datos en la nube",
    boxEnforcement: "Box",
    googleDirectoryEnforcement: "Google Workspace",
    googleDirectoryEnforcementAlias: "G-Suite",
    dropboxEnforcement: "Dropbox",
    office365Enforcement: "Microsoft 365",
    emailProxyEnforcement: "proxy de correo electrónico",
    slackEnforcement: "Slack",
    salesforceEnforcement: "Salesforce",
    zoomEnforcement: "Zoom",
    noServicesToAdd: "No hay servicios para agregar",
    google: "Google",
    coronet: "Coro",
    connect: "Conectar",
    cloudApplication: "Aplicación en la nube",
    connectCloudApplication: "Conectar aplicación en la nube",
    numberConnected: "{n} conectado",
    thirdPartyApps: "Aplicaciones de terceros",
    servicesFound: "{n} servicio | {n} servicios",
    connected: "Conectado",
    disconnected: "Desconectado",
    disabled: "Deshabilitado",
    incomplete: "Incompleto",
    notConnected: "No conectado",
    hubspotEnforcement: "Hubspot",
    oktaEnforcement: "Okta",
    buttons: {
      connect: "Conectar",
      disconnect: "Desconectar",
      continue: "Continuar",
      access: "Permisos de acceso",
      remove: "Eliminar",
    },
  },
  service: {
    zoomEnforcement: {
      notice1:
        "Para establecer la gobernanza de Zoom, el administrador del sistema debe asegurarse de que la cuenta de administrador de Zoom tenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten usar Coro para monitorear el acceso de usuarios y grupos de Zoom y las actividades del servicio.",
    },
    hubspotEnforcement: {
      notice1:
        "Para establecer la gobernanza de Hubspot, el administrador del sistema debe asegurarse de que la cuenta de administrador de Hubspot tenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten usar Coro para monitorear el acceso de usuarios y grupos de Hubspot y las actividades del servicio.",
    },
    oktaEnforcement: {
      notice1:
        "Para establecer la gobernanza de Okta, el administrador del sistema debe asegurarse de que la cuenta de administrador de Okta tenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten usar Coro para monitorear el acceso de usuarios y grupos de Okta y las actividades del servicio.",
    },
    dropboxEnforcement: {
      stepOne: {
        title: "Autorizar la aplicación de gestión de equipo",
        description:
          'Autorizar a Coro ("Coro Team Management" app) para acceder a los miembros de su equipo.',
      },
      stepTwo: {
        title: "Autorizar la aplicación de acceso a archivos del equipo",
        description:
          'Autorizar a Coro ("Coro File Access" app) para acceder a los archivos de los miembros de su equipo.',
      },
    },
    googleDirectoryEnforcement: {
      step1: {
        title: "Verifique que sea un administrador de Google",
        description: "Inicie sesión en su Google Workspace utilizando una cuenta de administrador.",
        descriptionError: {
          notAdmin: "Necesitamos verificar que {email} sea una cuenta de administrador de Google.",
          alreadyConnected:
            "Si tiene acceso a {email} <a class='coro-link' href='https://admin.google.com/u/1/ac/owl/list?tab=apps' target=\"_blank\">Google Admin Console</a>, puede revocar el acceso a la aplicación Coro y volver a intentarlo.",
          generic:
            "Algo salió mal durante la conexión a la cuenta seleccionada, por favor contacte a <a class='coro-link' href='https://docs.coro.net/' target=\"_blank\">Soporte de Coro</a>.",
          genericWithoutCoro:
            "Algo salió mal durante la conexión a la cuenta seleccionada, por favor contacte al soporte.",
        },
      },
      step2: {
        title: "Registrar a Coro como cliente API con Google",
        description1:
          "Vaya a <a class='coro-link' href='https://admin.google.com/ac/owl/domainwidedelegation' target=\"_blank\">https://admin.google.com/ac/owl/domainwidedelegation</a>.",
        description2:
          "Haga clic en \"<span class='description-bold'>Agregar nuevo</span> cliente API\".",
        description3:
          "Bajo <span class='description-bold'>ID del cliente</span> copie y pegue el siguiente ID:",
        description4:
          "Bajo <span class='description-bold'>Ámbitos OAuth</span> copie y pegue los siguientes ámbitos, incluyendo las comas:",
        description5: "<span class='description-bold'>Autorizar</span> el nuevo ID de cliente.",
      },
      step3: {
        title: "Verificar que la API de SDK de Drive esté habilitada",
        description:
          "Vaya a <a class='coro-link' href='https://admin.google.com/u/5/ac/appsettings/55656082996/data' target=\"_blank\">https://admin.google.com/u/5/ac/appsettings/55656082996/data</a>.<br>Verifique que la casilla \"Permitir a los usuarios acceder a Google Drive con la API de SDK de Drive\" esté <b>seleccionada</b>.",
      },
      step4: {
        title: "Realizar la instalación de dominio de Coro en Google Workspace Marketplace",
        description:
          'Vaya a <a class=\'coro-link\' href="https://workspace.google.com/marketplace/app/coronet_addon/98372960675" target="_blank">https://workspace.google.com/marketplace/app/coronet_addon/98372960675</a>.<br>Haga clic en “<b>Instalar dominio</b>”.',
      },
      successText: "¡Éxito! Google Workspace está conectado",
    },
    salesforceEnforcement: {
      notice1:
        "Para establecer la gobernanza de Salesforce, el administrador del sistema debe asegurarse de que la cuenta de administrador de Salesforce tenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten usar Coro para monitorear el acceso de usuarios y grupos de Salesforce y las actividades del servicio, detectar filtraciones de datos y malware en su correo electrónico y almacenamiento de archivos y mitigar amenazas.",
    },
    slackEnforcement: {
      notice1:
        "Debido a ciertas limitaciones impuestas por Slack a servicios de terceros como Coro, la protección de Coro ahora solo está disponible para clientes con planes <b>Slack Business+</b> y <b>Slack Enterprise Grid</b>.",
      notice2:
        "Al conectarse, autoriza a Coro a monitorear el acceso de usuarios y grupos de Slack y las actividades del servicio, detectando filtraciones de datos y malware en su correo electrónico y almacenamiento de archivos y mitigando amenazas.",
    },
    office365Enforcement: {
      stepOne: {
        accessDenied: "Algo salió mal, por favor intente de nuevo",
        error: "La cuenta no está asociada con un rol de administrador",
        accountAlreadyExist:
          "El servicio ya está conectado. Para continuar, contacte a los administradores <span class='coro-link'>{admins}</span>.",
        title: "Conectar Coro a Microsoft 365",
        description:
          "Al conectarse, autoriza a Coro a monitorear el acceso de usuarios y grupos de Microsoft 365 y las actividades del servicio, detectando filtraciones de datos y malware en su correo electrónico y almacenamiento de archivos y mitigando amenazas.",
        descriptionError:
          "Necesitamos verificar que su cuenta sea una cuenta de administrador de Microsoft 365.",
      },
      stepTwo: {
        title:
          "Verificar que la grabación de los registros de auditoría de Microsoft 365 esté habilitada",
        description:
          "Vaya a <a class='coro-link' href='https://compliance.microsoft.com/auditlogsearch' target=\"_blank\">https://compliance.microsoft.com/auditlogsearch</a> para realizar esta tarea.",
      },
      successText: "¡Éxito! Microsoft 365 está conectado",
    },
    boxEnforcement: {
      stepOne: {
        title: "Crear y configurar la aplicación Box",
        description:
          "Para una descripción paso a paso del proceso, consulte nuestro <a class='coro-link' href='https://docs.coro.net/protection/connecting-to-box/' target=\"_blank\">artículo de Ayuda y Documentación</a>.",
      },
      stepTwo: {
        title:
          "Suba el archivo de configuración de clave pública/privada que descargó en el Paso 1",
        error: "La cuenta no está asociada con un rol de administrador",
        label: "Subir el archivo de configuración",
      },
      successText: "¡Éxito! Box está conectado",
    },
    errors: {
      notAdmin: "{email} no está asociado con un rol de administrador",
      alreadyConnected: "{email} ya está en uso por otra cuenta de Coro",
      generic: "Ups, algo salió mal",
    },
  },
  serviceStatuses: {
    active: "Activo",
    suspendedByCoronet: "Suspendido por Coro",
    suspendedByService: "Suspendido por el servicio",
    invited: "Invitado",
    deleted: "Eliminado",
    inactive: "Inactivo",
  },
  cloudAppsSettings: {
    connect: "Conectar",
    connectionCompleted: "¡Genial! Tu {service} ahora está conectado.",
    noCloudApps: {
      title: "Agrega tus aplicaciones en la nube a la protección de Coro",
      description:
        "Monitorea las cuentas de tus aplicaciones en la nube empresarial para asegurar tu entorno y cumplir \ncon las regulaciones de privacidad.",
      connectBtn: "Conectar Aplicaciones en la Nube",
      modalDescription: "Selecciona la aplicación en la nube que te gustaría conectar",
    },
  },
  connectors: {
    title: "Conectores",
    tabs: {
      siem: "SIEM",
      apiKeys: "Credenciales API",
      psa: "PSA",
      webhooks: "Webhooks",
    },
    siem: {
      add: "Agregar conector",
      description: "Exportar datos a sistemas externos de agregación de datos de seguridad.",
      table: {
        headers: {
          name: "Nombre",
          status: "Estado",
        },
        actions: {
          edit: "Editar",
          delete: "Eliminar",
          sync: "Sincronizar",
        },
      },
      format: {
        splunk: "Splunk",
        azureSentinel: "Azure Sentinel",
        fluency: "Fluency",
        generic: "Genérico",
      },
      noItems: {
        description:
          "Actualmente, los datos de tu espacio de trabajo de Coro no se exportan a sistemas de terceros",
        subDescription:
          "Agrega conectores a sistemas externos de gestión de información y eventos de seguridad",
      },
    },
    webhooks: {
      add: "Crear webhook",
      description: "Exportar datos a sistemas externos de agregación de datos de seguridad.",
      status: {
        [WebhookStatus.ENABLED]: "Activo",
        [WebhookStatus.DISABLED]: "Deshabilitado",
        [WebhookStatus.DISCONNECTED]: "Desconectado",
        [WebhookStatus.INACTIVE]: "Deshabilitado",
        [WebhookStatus.ERROR]: "Error",
      },
      testStatus: {
        [WebhookTriggerTestStatus.SUCCESS]: "Aprobado",
        [WebhookTriggerTestStatus.FAILURE]: "Fallido",
        notTested: "No probado",
      },
      error: {
        title: "Respuesta no exitosa de la URL del webhook.",
        subtitle: "Verifica que la URL esté configurada correctamente y pruébala nuevamente.",
        retest: "Reprobar",
      },
      expirationLabels: {
        expired: "Caducado",
        expiresIn: "Caduca en {n}",
      },
      triggerLabels: {
        newVersion: "Nueva versión",
        aboutToExpire: "Caduca {expirationDate}",
        expired: "Caducado",
      },
      alerts: {
        aboutToExpire: {
          title:
            "Acción necesaria: actualiza tu webhook | Acción necesaria: actualiza tus webhooks",
          body: 'Una nueva versión del trigger para <b>"{name}"</b> está disponible. Para mantener tu webhook activo, selecciona <b>Editar</b> en el menú de tres puntos y configúralo antes de {expirationDate}.',
        },
        expired: {
          title: 'El webhook "{name}" ahora está deshabilitado',
          body: "Selecciona <b>Editar</b> en el menú de tres puntos para configurar y reactivar su funcionalidad.",
        },
        triggerAboutToExpire: {
          title: "Un nuevo trigger está disponible",
          body: "Coro recomienda seleccionarlo junto con el trigger que está por expirar para realizar pruebas. <br><b>Antes de que expire el antiguo trigger, desmárcalo para evitar cualquier interrupción.</b>",
        },
        triggerExpired: {
          title: "Un trigger ha caducado y el webhook ahora está desactivado.",
          body: "Desmarca el trigger caducado y selecciona el nuevo trigger para reactivar el webhook.",
        },
      },
      table: {
        headers: {
          name: "Nombre",
          status: "Estado",
          created: "Creado",
          expires: "Caduca",
          appliedToChildWorkspace: "Aplicado al espacio de trabajo hijo",
        },
        sections: {
          description: "Descripción",
          authenticationUrl: "URL de autenticación",
          secret: "Secreto",
          authorization: "Autorización",
          trigger: "Trigger",
          lastUsed: "Último uso",
          testStatus: "Estado de prueba",
          action: "Acción",
          header: "Encabezado",
          value: "Valor",
        },
        actions: {
          [WebhookAction.EDIT]: "Editar",
          [WebhookAction.TEST]: "Probar",
          [WebhookAction.DEACTIVATE]: "Desactivar",
          [WebhookAction.ACTIVATE]: "Activar",
          [WebhookAction.REMOVE]: "Eliminar",
        },
      },
      noItems: {
        description: "Actualmente no hay webhooks configurados en este espacio de trabajo",
        subDescription: "Crea un webhook para este espacio de trabajo.",
      },
      webhookTriggers: {
        [WebhookTriggerType.WORKSPACE_CREATED]: "Espacio de trabajo creado",
        [WebhookTriggerType.WORKSPACE_STATUS_UPDATED]: "Estado del espacio de trabajo actualizado",
        [WebhookTriggerType.SUBSCRIPTION_MODULES_UPDATED]: "Módulos de suscripción actualizados",
        [WebhookTriggerType.SUBSCRIPTION_ADDONS_UPDATED]:
          "Complementos de suscripción actualizados",
        [WebhookTriggerType.SUBSCRIPTION_BUNDLES_UPDATED]: "Paquetes de suscripción actualizados",
        [WebhookTriggerType.PROTECTED_USERS_ADDED]: "Usuarios protegidos añadidos",
        [WebhookTriggerType.PROTECTED_USERS_UPDATED]: "Usuarios protegidos actualizados",
        [WebhookTriggerType.PROTECTED_USERS_DELETED]: "Usuarios protegidos eliminados",
        [WebhookTriggerType.PROTECTED_USERS_COUNT_UPDATED]:
          "Conteo de usuarios protegidos actualizado",
        [WebhookTriggerType.PROTECTED_DEVICES_UPDATED]: "Dispositivos protegidos actualizados",
        [WebhookTriggerType.PROTECTED_DEVICES_ADDED]: "Dispositivos protegidos añadidos",
        [WebhookTriggerType.PROTECTED_DEVICES_DELETED]: "Dispositivos protegidos eliminados",
        [WebhookTriggerType.PROTECTED_DEVICES_COUNT_UPDATED]:
          "Conteo de dispositivos protegidos actualizado",
        [WebhookTriggerType.SUBSCRIPTION_UPDATED]: "Suscripción actualizada",
      },
    },
    psa: {
      title: "Conectores PSA",
      add: "Agregar conector",
      connector: "Conector",
      workspaceMapping: "Mapeo de espacio de trabajo",
      serviceMapping: "Mapeo de servicio",
      ticketing: "Ticketing",
      setupRequired: "Configuración requerida",
      nOfm: "{first} de {second}",
      mappingSelectPlaceholder: "Mapear",
      comingSoon: "Próximamente",
      clearMapping: "Borrar mapeo",
      actions: {
        edit: "Editar conector",
        disable: "Desconectar",
        connect: "Conectar",
      },
      provider: {
        gradient: "Gradient",
        connectwise: "Connectwise",
        autotask: "Autotask",
      },
      status: {
        disconnected: "Desconectado",
        connected: "Conectado",
        configSuspended: "Desconectado",
        configNotFound: "Configuración requerida",
      },
      activeWorkspaces: "Espacios de trabajo activos",
      noItems: {
        description: "Conecta tus espacios de trabajo a sistemas PSA",
        subDescription: "Agrega conectores a tu entorno PSA.",
      },
      connectwiseWorkspaceMapping: {
        title: "Mapear espacios de trabajo a empresas de ConnectWise",
      },
      connectwiseServiceMapping: {
        title: "Mapear módulos y complementos a servicios de ConnectWise",
      },
      autotaskWorkspaceMapping: {
        title: "Mapear espacios de trabajo a empresas de Autotask",
      },
      autotaskServiceMapping: {
        title: "Mapear módulos y complementos a servicios de Autotask",
      },
      addPsaConnector: {
        title: "Agregar conexión PSA",
        description: "Selecciona el conector que te gustaría conectar",
        gradient: {
          label: "Clave API de Gradient",
          placeholder: "Ingresa tu clave API de Gradient",
          description:
            "A través de Gradient, puedes conectar tu espacio de trabajo de Coro a la mayoría de los sistemas PSA en uso.",
        },
        connectwise: {
          subtitle: "Detalles de integración de API de ConnectWise",
          description: "Crear un nuevo conector de ConnectWise",
        },
        autotask: {
          subtitle: "Detalles de integración de API de Autotask",
          description: "Crear un nuevo conector de Autotask",
        },
        actionButton: "Agregar",
      },
      products: {
        [CoroProductNames.CLOUD_SECURITY]: "Seguridad en la Nube",
        [CoroProductNames.ENDPOINT_SECURITY]: "Seguridad de Endpoint",
        [CoroProductNames.EMAIL_SECURITY]: "Seguridad de Correo Electrónico",
        [CoroProductNames.USER_DATA_GOVERNANCE]: "Gobernanza de Datos de Usuario",
        [CoroProductNames.ENDPOINT_DATA_GOVERNANCE]: "Gobernanza de Datos de Endpoint",
        [CoroProductNames.EDR]: "EDR",
        [CoroProductNames.NETWORK]: "Red",
        [CoroProductNames.MDM]: "MDM",
        [CoroProductNames.INBOUND_GATEWAY]: "Puerta de Enlace de Entrada",
        [CoroProductNames.SECURED_WEB_GATEWAY]: "Puerta de Enlace Web Asegurada",
        [CoroProductNames.WIFI_PHISHING]: "Phishing WiFi",
        [CoroProductNames.SECURED_MESSAGES]: "Mensajes Asegurados",
        [CoroProductNames.MDR]: "MDR",
        [CoroProductNames.SOC_CLOUD_SECURITY]: "Seguridad en la Nube como Servicio Gestionado",
        [CoroProductNames.SOC_ENDPOINT_SECURITY]: "Seguridad de Endpoint como Servicio Gestionado",
        [CoroProductNames.SOC_EMAIL_SECURITY]:
          "Seguridad de Correo Electrónico como Servicio Gestionado",
        [CoroProductNames.SOC_USER_DATA_GOVERNANCE]:
          "Gobernanza de datos de usuario con servicio gestionado",
        [CoroProductNames.SOC_ENDPOINT_DATA_GOVERNANCE]:
          "Gobernanza de datos de endpoint con servicio gestionado",
        [CoroProductNames.SOC_NETWORK]: "Red con servicio gestionado",
        [CoroProductNames.ZERO_TRUST_NETWORK_ACCESS]: "Acceso a la red de confianza cero",
        [CoroProductNames.OUTBOUND_GATEWAY]: "Gateway de salida",
      },
    },
    apiKeys: {
      add: "Crear credenciales API",
      expired: "Expirado",
      table: {
        clientId: "ID del cliente",
        expirationTime: "Expira",
        createdTime: "Creado",
        lastUsedTime: "Último uso",
        description: "Descripción",
      },
      noItems: {
        description: "Actualmente no se han creado credenciales API",
        subDescription: "Cree credenciales para obtener acceso a la API de Coro",
      },
    },
  },
  connectwise: {
    tabs: {
      workspaceMapping: "Mapeo de espacios de trabajo",
      serviceMapping: "Mapeo de servicios",
      ticketing: "Ticketing",
    },
  },
  workspaceMapping: {
    statusSelectPlaceholder: "Estado",
    companySelectPlaceholder: "Mapear",
    coroWorkspace: "Espacio de trabajo de Coro",
    connectwiseCompany: "Empresa de ConnectWise",
    autotaskCompany: "Empresa de Autotask",
    searchForWorkspaces: "Buscar espacios de trabajo...",
    mappingFilterType: {
      mapped: "Mapeado",
      unmapped: "No mapeado",
      all: "Todos",
    },
  },
  serviceMapping: {
    statusSelectPlaceholder: "Estado",
    companySelectPlaceholder: "Mapear",
    coroModules: "Módulos / complementos de Coro",
    connectwiseServices: "Servicios de ConnectWise",
    autotaskServices: "Servicios de Autotask",
    searchForModules: "Buscar módulos...",
    mappingFilterType: {
      mapped: "Mapeado",
      unmapped: "No mapeado",
      all: "Todos",
    },
  },
  betaChip: {
    title: "Beta",
    tooltip: "Esta función está disponible hasta {n} en fase Beta",
  },
  betaBanner: {
    title: "Bienvenido a la versión Beta de {addonOrModule}",
    subtitle: "Esto es lo que necesitas saber:",
    limitedSupport: "Soporte limitado",
    limitedSupportDescription: "Espera un soporte técnico mínimo en esta etapa.",
    potentialBugs: "Posibles errores",
    potentialBugsDescription:
      "Tu retroalimentación es crucial. Si encuentras algún problema, infórmanos a <span class='coro-link'>support@coro.net.</span>",
    notForProduction: "No para producción",
    notForProductionDescription:
      "Esta función aún no está lista para su uso en producción completa o aplicaciones críticas.",
    constantImprovements: "Mejoras constantes",
    constantImprovementsDescription:
      "Las cosas pueden cambiar a medida que refinamos y mejoramos los componentes.",
    releaseUpdates: "Actualizaciones de lanzamiento",
    releaseUpdatesDescription:
      "Mantente atento a las noticias de lanzamientos oficiales. Te mantendremos informado sobre cualquier cambio.",
  },
  accessPermissions: {
    title: "Permisos de acceso a {service}",
    description: "Permitir acceso a {service} desde países/direcciones IP específicas",
    addRestriction: "Nuevos permisos",
    noItems: {
      description:
        "Actualmente, tus usuarios protegidos no tienen permisos específicos sobre el acceso a {service}",
      subDescription: "Permitir acceso a {service} desde países/direcciones IP específicas",
    },
    types: {
      all: "Todos los usuarios",
      group: "Grupos específicos",
      user: "Usuarios específicos",
    },
    actions: {
      editAccessPermissions: "Editar",
      deleteAccessPermissions: "Eliminar",
    },
    table: {
      allUsers: "Todos los Usuarios",
      headers: {
        groups: "Grupos",
        restrictions: "Países / IPs permitidos",
        description: "Descripción",
        remediation: "Remediación automática",
      },
      remediationTypes: {
        none: "Ninguna",
        signin: "Cerrar sesión",
        suspend: "Suspender usuario",
      },
    },
  },
  mobileActivation: {
    success: {
      title: "Todo listo",
      subtitle:
        "La activación se completó con éxito. Abre la aplicación Coro en tu dispositivo móvil para ver el estado de tu conexión VPN.",
      mobileSubtitle: "La activación se completó con éxito",
      openApp: "Abrir app",
    },
    failure: {
      title: "La activación falló",
      mobileSubtitle: "La activación de tu cuenta falló",
      resendButton: "Reenviar enlace de activación",
      errors: {
        [ActivateMobileErrorType.GENERIC]: "La activación de tu cuenta falló",
        [ActivateMobileErrorType.ACTIVATION_LINK_EXPIRED]: "El enlace de activación ha caducado",
        [ActivateMobileErrorType.PART_OF_WORKSPACE]: "Ya eres parte de {workspace}",
        [ActivateMobileErrorType.WORKSPACE_IS_NOT_PROTECTED]:
          "Este usuario ha sido eliminado de la protección, por lo que no se puede unir al espacio de trabajo. <br>Contacta a tu administrador para más detalles.",
        [ActivateMobileErrorType.WORKSPACE_ARCHIVED]:
          "El espacio de trabajo asociado a esta dirección de correo electrónico ha sido archivado, por lo que no se puede unir. <br>Contacta a tu administrador para más detalles.",
        [ActivateMobileErrorType.NETWORK_MODULE_DISABLED]:
          "El módulo de red asociado a esta dirección de correo electrónico ha sido deshabilitado, por lo que no se puede unir al espacio de trabajo. <br>Contacta a tu administrador para más detalles.",
      },
    },
    activationLinkSent: {
      title: "Por favor, revisa tu correo electrónico",
      subtitle:
        "Hemos enviado un enlace de activación a {name}. Selecciona el espacio de trabajo al que te gustaría unirte para activar tu cuenta.",
      mobileSubtitle:
        "Hemos enviado un enlace de activación a {name}. Selecciona el espacio de trabajo al que te gustaría unirte para activar tu cuenta.",
    },
  },
  usersPage: {
    title: "Usuarios Protegidos",
    selected: "{n} seleccionado",
    dynamicTitle: "Usuarios Protegidos - {n}",
    usersQuantity: "{n} usuario | {n} usuarios",
    noData: "No se encontraron usuarios",
    none: "Ninguno",
    noIssues: "No hay problemas",
    protectedSince: "Protegido desde",
    protectedCloudApps: "Aplicaciones en la Nube Protegidas",
    noProtectedCloudApps: "El usuario no está listado en ninguna aplicación en la nube protegida",
    protectedDevices: "Dispositivos Protegidos",
    noProtectedDevices: "Actualmente, no hay dispositivos protegidos",
    tableHeaders: {
      user: "Usuario",
      vulnerability: "Vulnerabilidad",
    },
    actions: {
      viewDetails: "Ver detalles",
      markAsProcessed: "Cerrar todos los tickets",
      suspendFromAll: "Suspender usuario de todas las aplicaciones en la nube",
      signInToAll: "Solicitar al usuario que inicie sesión en todas las aplicaciones en la nube",
    },
    criticalIssues: "Problemas críticos",
  },
  subscriptions: {
    plans: {
      [SubscriptionType.FREEZE]: "Inactivo",
      [SubscriptionType.SUBSCRIPTION]: "Suscripción",
      [SubscriptionType.TRIAL]: "Prueba",
      [SubscriptionType.INITIAL]: "Nuevo",
      [SubscriptionType.ARCHIVED]: "Archivado",
    },
    bundles: {
      title: "Paquetes",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "Protección de Endpoints",
      [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: "Protección de Endpoints Administrada",
      [SubscriptionBundle.EMAIL_PROTECTION]: "Protección de Correo Electrónico",
      [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]:
        "Protección de Correo Electrónico Administrada",
      [SubscriptionBundle.SASE]: "SASE",
      [SubscriptionBundle.MANAGED_SASE]: "SASE Administrado",
      [SubscriptionBundle.CORO_ESSENTIALS]: "Coro Esenciales",
      [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: "Coro Esenciales Administrado",
      [SubscriptionBundle.CORO_CLASSIC]: "Coro Clásico",
      [SubscriptionBundle.MANAGED_CORO_CLASSIC]: "Coro Clásico Administrado",
      [SubscriptionBundle.CORO_COMPLETE]: "Coro Completo",
      [SubscriptionBundle.MANAGED_CORO_COMPLETE]: "Coro Completo Administrado",
    },
    modules: {
      title: "Módulos",
      [SubscriptionModule.CLOUD_SECURITY]: "Seguridad en la Nube",
      [SubscriptionModule.ENDPOINT_SECURITY]: "Seguridad de Endpoints",
      [SubscriptionModule.EMAIL_SECURITY]: "Seguridad de Correo Electrónico",
      [SubscriptionModule.USER_DATA_GOVERNANCE]: "Gobernanza de Datos de Usuarios",
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "Gobernanza de Datos de Endpoints",
      [SubscriptionModule.EDR]: "EDR",
      [SubscriptionModule.MDM]: "MDM",
      [SubscriptionModule.NETWORK]: "Red",
    },
    addons: {
      title: "Complementos",
      [SubscriptionAddon.SOC]: "Servicio Gestionado",
      [SubscriptionAddon.SOC_CLOUD]: "Servicio Gestionado - Seguridad en la Nube",
      [SubscriptionAddon.SOC_ENDPOINT]: "Servicio Gestionado - Seguridad de Endpoints",
      [SubscriptionAddon.SOC_EMAIL]: "Servicio Gestionado - Seguridad de Correo Electrónico",
      [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE]:
        "Servicio Gestionado - Gobernanza de Datos de Usuarios",
      [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE]:
        "Servicio Gestionado - Gobernanza de Datos de Endpoints",
      [SubscriptionAddon.SOC_NETWORK]: "Servicio Gestionado - Red",
      [SubscriptionAddon.MDR]: "MDR",
      [SubscriptionAddon.WIFI_PHISHING]: "Phishing por Wi-Fi",
      [SubscriptionAddon.SECURED_MESSAGES]: "Mensajes Seguros",
      [SubscriptionAddon.INBOUND_GATEWAY]: "Puerta de Enlace de Entrada",
      [SubscriptionAddon.OUTBOUND_GATEWAY]: "Puerta de Enlace de Salida",
      [SubscriptionAddon.SWG]: "Puerta de Enlace Web Segura",
      [SubscriptionAddon.ZTNA]: "Acceso a Red de Confianza Cero",
    },
    moduleIncludedInBundle: "Este módulo ya está incluido en uno de los paquetes que seleccionaste",
    addonIncludedInBundle:
      "Este complemento ya está incluido en uno de los paquetes que seleccionaste",
    usDataCenterOnly: "Solo centro de datos en EE. UU.",
    packages: "Paquetes",
  },
  emailSettings: {
    title: "Seguridad de Correo Electrónico",
    tabs: {
      settings: "Configuraciones",
      allowBlockList: "Permitir/ bloquear",
      addIns: "Complementos",
      coroMail: "Mensajes Seguros",
      inboundGateway: "Puerta de Enlace de Entrada",
    },
    settingsTab: {
      realTimeSecurity: "Escanear correos electrónicos para los siguientes tipos de amenazas",
      scanBypass: "Permitir que los correos electrónicos eviten el escaneo",
      securityMode: "Modo de seguridad",
      securityModeInfoText:
        "CUARENTENA: Los correos electrónicos que contienen malware detectado son eliminados. Los correos electrónicos que contienen spam/phishing son puestos en cuarentena. Si la puerta de enlace de entrada está habilitada, todos los correos electrónicos sospechosos son puestos en cuarentena de forma segura." +
        "<br/><br/>" +
        "ADVERTIR AL DESTINATARIO: Los correos electrónicos sospechosos no son bloqueados, pero incluyen una etiqueta/banner de advertencia.",
      securityModes: {
        [SecurityMode.QUARANTINE_EMAIL]: "Cuarentena",
        [SecurityMode.WARN_RECIPIENT]: "Advertir al destinatario",
      },
      threatType: "Tipo de amenaza",
      realTimeSecurityDisabled: "Seguridad en tiempo real deshabilitada",
      scanMalware: "Malware en Archivos Adjuntos de Correo Electrónico",
      scanSpam: "Spam",
      scanPhishing: "Phishing",
      scanNewlyRegisteredDomains: "Dominios Nuevamente Registrados",
      scanEncryptedAttachments: "Adjunto Encriptado",
      scanUnverifiedSender: "Remitente No Verificado",
      scanUnknownExternalSender: "Remitente Externo Desconocido",
      quarantineByAttachmentType: "Cuarentena por tipo de archivo adjunto",
      securityAwarenessTraining: "Entrenamiento de Concienciación en Seguridad",
      securityAwarenessTrainingSubtitle:
        "Permitir correos electrónicos para propósitos de Entrenamiento de Concienciación en Seguridad",
      emailSubjectKeywordsPlaceHolder: "Palabras clave del asunto",
      securityAwarenessTable: {
        configurationDescription: "{quantity} Dominio o IP y | {quantity} Dominios o IPs y",
        headerDescription: "{quantity} encabezado | {quantity} encabezados",
        domainsOrIp: "Dominio o IP",
        headersTitle: "Encabezados",
        headers: {
          name: "Nombre",
          value: "Valor",
          configuration: "Configuración",
          date: "Fecha de agregado",
        },
        actions: {
          remove: "Eliminar",
        },
      },
      allowList: "Lista de permitidos",
      subjectLine: "Línea de asunto",
      seeAll: "Ver todos los tipos",
      seeLess: "Ver menos tipos",
      quarantineEmails:
        "Poner en cuarentena correos electrónicos con archivos adjuntos de estos tipos de archivo especificados",
      customFileTypes: "Tipos de archivos personalizados",
      apiBasedEmailSecurity: "Seguridad de correo electrónico basada en API",
      quarantineFolder: "Carpeta de Cuarentena",
      scanBypassLabel:
        "Permitir correos electrónicos con palabras clave especificadas en la línea de asunto",
      quarantineFolderDescription:
        "Los correos electrónicos bloqueados serán puestos en cuarentena en esta carpeta:",
      quarantineFolderType: {
        suspected: "Carpeta 'Sospechada' dedicada",
        trash: "Carpeta de papelera del sistema",
      },
      quarantineFolderInfoTooltip:
        "Conecta una o más aplicaciones en la nube a Coro para habilitar esta configuración.",
      disclaimer:
        "Para una protección anti-phishing transparente, conecta la cuenta de Microsoft 365 o Google Workspace de tu organización, o configura la puerta de enlace del proxy de correo electrónico entrante.",
    },
    addInsSettings: {
      title: "Complementos para retroalimentación del usuario",
      description:
        "Coro recopila continuamente comentarios directamente de los usuarios sobre la clasificación de correos electrónicos potencialmente inexacta y los entrega para tu revisión cuando este complemento de correo electrónico está instalado en los clientes de correo electrónico de tus usuarios. La instalación es muy sencilla: puedes instalar simultáneamente para todos los usuarios actuales (y futuros).",
      installFor: "Instalar para {provider}",
    },
  },
  entityTicketsList: {
    modulesTooltip:
      "Los tickets de {modules} no están incluidos porque no tienes acceso a ellos | Los tickets de {modules} no están incluidos porque no tienes acceso a ellos",
    noPermissionsText: "No tienes permisos para acceder a los tickets",
  },
  devices: {
    defaultTitle: "Dispositivos",
    advancedTitle: "Dispositivos - {n}",
    selected: "{n} seleccionados",
    clientOutdated: "Cliente desactualizado",
    criticalIssues: "Problemas críticos",
    noIssues: "No hay problemas",
    actions: {
      [DeviceAction.EXAMINE_MALWARE]: "Examinar malware",
      [DeviceAction.ENABLE_FIREWALL]: "Habilitar firewall",
      [DeviceAction.REMOTE_SCAN]: "Escaneo remoto de malware",
      [DeviceAction.DLP_PARTIAL_SCAN]: "Escaneo remoto de datos sensibles",
      [DeviceAction.STOP_REMOTE_SCAN]: "Detener escaneo remoto de malware",
      [DeviceAction.STOP_DLP_SCAN]: "Detener escaneo de datos sensibles",
      [DeviceAction.REMOVE_FROM_PROTECTION]: "Deshabilitar protección",
      [DeviceAction.ENFORCE_UAC]: "Hacer cumplir la notificación de UAC",
      [DeviceAction.DISABLE_DEVELOPER_MODE]: "Deshabilitar modo desarrollador",
      [DeviceAction.MARK_AS_PROCESSED]: "Cerrar tickets relacionados",
      [DeviceAction.VIEW_DETAILS]: "Ver detalles",
      [DeviceAction.ENABLE_TAMPER_RESISTANCE]: "Permitir desinstalación del agente",
      [DeviceAction.DISABLE_TAMPER_RESISTANCE]: "Prevenir desinstalación del agente",
      [DeviceAction.ALLOW_NO_ENCRYPTION]: "Permitir sin encriptación",
      [DeviceAction.COLLECT_LOGS]: "Recopilar registros",
      [DeviceAction.COLLECT_QUARANTINE_DATA]: "Recopilar lista de cuarentena",
      [DeviceAction.DOWNLOAD_LOGS]: "Descargar registros",
      [DeviceAction.ENABLE_NETWORK_BLOCK_MODE]: "Aislar de la red",
      [DeviceAction.DISABLE_NETWORK_BLOCK_MODE]: "Deshabilitar aislamiento de red",
      [DeviceAction.REBOOT_DEVICE]: "Reiniciar dispositivo",
      [DeviceAction.SHUTDOWN_DEVICE]: "Apagar dispositivo",
      [DeviceAction.UPDATE_AGENT_TO_LATEST]: "Actualizar agente a la última versión estable",
      [DeviceAction.ADD_LABEL]: "Agregar etiqueta",
      [DeviceAction.REMOVE_LABEL]: "Eliminar etiqueta",
      [DeviceAction.SHOW_ADVANCED_INFORMATION]: "Mostrar información avanzada",
      [DeviceAction.OPEN_REMOTE_SHELL_SESSION]: "Abrir sesión remota de shell",
      [DeviceAction.EXPORT_TO_CSV]: "Exportar a CSV",
    },
    noDevices: "No hay dispositivos conectados",
    clientVersion: "Coro",
    drives: {
      statuses: {
        [DriveEncryptionStatus.ENCRYPTED]: "Encriptado",
        [DriveEncryptionStatus.ENCRYPTED_BY_CORO]: "Encriptado poe Coro",
        [DriveEncryptionStatus.NOT_ENCRYPTED]: "No encriptado",
        [DriveEncryptionStatus.FAILED]: "Error en la encriptación",
        [DriveEncryptionStatus.SUSPENDED]: "Encriptación suspendida",
        [DriveEncryptionStatus.IN_PROGRESS]: "Encriptación en progreso",
      },
      types: {
        removable: "Removible",
        nonRemovable: "No removible",
      },
      encryptBtn: {
        windows: "Encriptar",
        osx: "Encriptar",
      },
      password: "Clave de recuperación",
      model: "Modelo",
      serialKey: "Clave de serie",
      encryptionInProgress: "Encriptación en progreso",
    },
    statuses: {
      none: "Ninguno",
      shutdown: "Apagado",
      isolated: "Aislado",
      online: "En línea",
      offline: "Desconectado",
    },
    filters: {
      osType: "Tipo de SO",
      osVersion: "Versión de SO",
      clientVersion: "Versión de Coro",
      isolationStatus: "Estado de aislamiento",
      vulnerability: "Vulnerabilidad",
      status: "Estado",
      groups: "Grupos",
      labels: "Etiquetas",
      label: {
        title: "Etiqueta",
        noData: "No existen etiquetas",
      },
    },
  },
  deviceDetails: {
    lastSeen: "Última vez visto",
    labels: "Etiquetas",
    duplicateBanner: {
      title: "Registros duplicados detectados",
      description:
        "Hemos encontrado dispositivos adicionales asociados con este usuario. Si son duplicados de este dispositivo, recomendamos fusionarlos y sus registros.",
      merge: "Fusionar dispositivos",
      ignore: "Ignorar",
    },
    deviceUser: {
      title: "Usuario del dispositivo",
      status: "Estado",
      email: "Correo electrónico",
      viewUser: "Ver usuario",
      name: "Nombre",
      username: "Nombre de usuario del dispositivo",
      upn: "UPN",
      onPremiseUPN: "UPN en las instalaciones",
      enrollmentCode: "Código de inscripción",
      clientVersion: "Versión del cliente",
      location: "Ubicación",
      vulnerabilities: "Vulnerabilidades generales",
      licenseToShowVulnerabilities: "Licencia al usuario para mostrar vulnerabilidades",
      userNotLicensed: "El usuario asociado con este usuario de dispositivo no tiene licencia",
      incompliantVulnerabilities: "Vulnerabilidades no conformes",
      firstActivity: "Primera actividad",
      lastActivity: "Última actividad",
      activity: "Actividad",
      user: "Usuario",
    },
    device: {
      title: "Dispositivo",
      model: "Modelo",
      os: "SO",
      osVersion: "Versión de SO",
      subVersion: "Sub-versión",
      hostname: "Nombre del host",
      deviceID: "ID del dispositivo Coro",
      type: "Tipo",
      modelAndId: "Modelo e ID",
      hostName: "Nombre de host",
      coroProtection: "Protección Coro",
      staticIp: "Dirección IP estática de oficina virtual",
      version: "Versión",
      added: "Agregado",
      lastKnownIp: "Última IP conocida",
      lastRemoteScan: "Último escaneo remoto en",
      malwareScanInProgress: "Escaneo remoto en progreso",
      noRemoteScans: "No se han realizado escaneos remotos aún",
      lastLogsUploadedAt: "Registros subidos en",
      modelId: "ID del modelo",
      serialNumber: "Número de serie",
      rebootNeeded: "Reinicio necesario",
      mobileId: "ID móvil",
      username: "Nombre de usuario",
    },
  },
  coroMailSettings: {
    pageTitle: "Mensajes seguros",
    title: "Complementos para usar Mensajes seguros",
    description:
      "Usando el complemento Mensajes seguros de Coro, los usuarios de Outlook 365 y Gmail pueden enviar mensajes asegurados directamente desde sus aplicaciones de correo electrónico basadas en la web y nativas. Usa los botones a continuación para comenzar.",
    coroMailPortal: {
      title: "Portal web de Mensajes seguros",
      description: "Enviar y recibir mensajes asegurados en el portal web de Mensajes seguros.",
    },
    installFor: "Instalar para {provider}",
    messageSettings: "Configuración de mensajes",
    restrictMessageForwarding: "Restringir el reenvío de mensajes",
    setMessageExpiration: "Establecer período de expiración del mensaje (días)",
    numberOfDays: {
      label: "Número de días",
      placeholder: "Ingrese el número de días",
    },
  },
  usersSettings: {
    title: "Usuarios Protegidos",
    excluded: "excluido",
    addUsersBtn: "Agregar Usuarios",
    addGroupsBtn: "Agregar Grupos",
    addExceptionsBtn: "Agregar Exclusiones",
    aliases: "Alias",
    tabs: {
      users: "Usuarios Protegidos",
      groups: "Grupos Protegidos",
      exceptions: "Exclusiones",
    },
    noItems: {
      description:
        "Agrega grupos de usuarios a la protección de Coro en lugar de uno a la vez, y ahorra tiempo",
      groupsDescription:
        "Agrega los grupos de usuarios que ya has definido en tus cuentas en la nube empresariales, como Microsoft 365 o Google Workspace, para añadir múltiples usuarios a la protección de Coro rápidamente.",
      usersDescription: "Alternativamente, agrega usuarios a la protección de Coro uno a la vez.",
      groupsShortDescription:
        "Agrega los grupos de usuarios que ya has definido en tus cuentas en la nube empresariales.",
    },
    actions: {
      addUsers: "Agregar Usuarios Específicos",
      addGroups: "Agregar Grupos Específicos",
      addAllUsers: "Agregar Protección a Todos los Usuarios",
      importUsersFromCsv: "Importar desde CSV",
    },
    userGroupsTab: {
      groupsList: "Lista de Grupos",
      noGroups: "No hay grupos que coincidan con esta consulta",
      tableHeaders: {
        groupName: "Nombre del Grupo",
        size: "Tamaño",
        protectionGrantedTime: "Protección Otorgada",
      },
      actions: {
        removeGroups: "Eliminar grupo",
        removeException: "Eliminar exclusiones",
      },
      groupsSelected: "{n} seleccionado | {n} seleccionados",
      removeGroupsBtn: "Eliminar {n} grupo | Eliminar {n} grupos",
    },
    usersTab: {
      noUsers: "No hay usuarios protegidos que coincidan con esta consulta",
      directMember: "Añadido individualmente",
      addedViaCsv: "Añadido vía CSV",
      excluded: "(excluido)",
      active: "Activo",
      inactive: "Inactivo",
      groups: "Grupos",
      status: {
        [UserSettingsAppStatus.ACTIVE]: "Activo",
        [UserSettingsAppStatus.INACTIVE]: "Inactivo",
      },
      tableHeaders: {
        user: "Usuario",
        joinedVia: "Ingresó a través de",
        appsProtected: "Aplicaciones Protegidas",
        status: "Estado",
        protectionGranted: "Protección otorgada",
      },
      userActions: {
        removeUsers: "Eliminar usuario",
        addToException: "Agregar a exclusión",
        removeException: "Eliminar exclusión",
      },
    },
    exceptionsTab: {
      exceptionsList: "Exclusiones",
      noExceptions: "No hay exclusiones que coincidan con esta consulta",
      notExcluded: "(admin no excluido)",
      actions: {
        removeException: "Eliminar exclusión",
      },
      tableHeaders: {
        email: "Correo electrónico",
        addedBy: "Añadido por",
        addedOn: "Añadido el",
      },
    },
    filters: {
      protectedVia: "Ingresó a través de",
      inboundGateway: "Puerta de enlace entrante",
      cloudServices: "Servicios en la nube",
      status: "Estado",
      protectedApps: "Aplicaciones Protegidas",
    },
  },
  manageWorkspaces: {
    title: "Gestionar Espacios de Trabajo",
    bundles: {
      title: "Paquetes",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "Protección de Endpoint",
      [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: "Protección de Endpoint",
      [SubscriptionBundle.EMAIL_PROTECTION]: "Protección de Correo",
      [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: "Protección de Correo",
      [SubscriptionBundle.SASE]: "SASE",
      [SubscriptionBundle.MANAGED_SASE]: "SASE",
      [SubscriptionBundle.CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.MANAGED_CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.CORO_COMPLETE]: "Coro Completo",
      [SubscriptionBundle.MANAGED_CORO_COMPLETE]: "Coro Completo",
    },
    tabs: {
      mspWorkspaces: "Espacios de Trabajo",
      mspAdminUsers: "Usuarios Admin MSP",
      mspRoles: "Roles MSP",
    },
    table: {
      name: "Nombre",
      type: "Tipo",
      plan: "Estado",
      addons: "Complementos",
      maxProtectedUsers: "Usuarios Máx.",
      protectedDevices: "Dispositivos",
      createdOn: "Creado el",
      protectedUsers: "Usuarios",
      protectableUsers: "Potencial de Usuario",
      lastActivity: "Última Actividad",
    },
    filters: {
      type: "Tipo",
      plan: "Estado",
      addons: "Módulos",
    },
    addonTypes: {
      SOC: "Servicio Gestionado",
      SOC_PRO: "Servicio Gestionado Pro",
      EDR: "EDR",
      MDR: "MDR",
    },
    actions: {
      [MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE]: "Crear espacio de trabajo de Canal",
      [MspWorkspaceAction.CREATE_REGULAR_WORKSPACE]: "Crear espacio de trabajo Regular",
      [MspWorkspaceAction.EDIT_WORKSPACE]: "Editar espacio de trabajo",
      [MspWorkspaceAction.VIEW_WORKSPACE]: "Ver espacio de trabajo",
      [MspWorkspaceAction.CONVERT]: "Convertir espacio de trabajo",
      [MspWorkspaceAction.EXPORT_MSP]: "Exportar lista de espacios de trabajo CSV",
      [MspWorkspaceAction.EXPORT_MSP_SUMMARY]: "Exportar resumen de espacios de trabajo CSV",
      [MspWorkspaceAction.CREATE_CHILD_WORKSPACE]: "Crear espacio de trabajo Hijo",
      [MspWorkspaceAction.EDIT_SUBSCRIPTION]: "Editar suscripción",
      [MspWorkspaceAction.START_SUBSCRIPTION]: "Iniciar suscripción",
      [MspWorkspaceAction.STOP_SUBSCRIPTION]: "Detener suscripción",
      [MspWorkspaceAction.EXPORT_MSP_NOTIFICATIONS]: "Exportar notificaciones admin",
      [MspWorkspaceAction.ARCHIVE_WORKSPACE]: "Archivar espacio de trabajo",
      [MspWorkspaceAction.UNARCHIVE_WORKSPACE]: "Restaurar espacio de trabajo",
      [MspWorkspaceAction.EXTEND_TRIAL]: "Extender prueba",
    },
    workspaceTypes: {
      [WorkspaceType.CHANNEL]: "Canal",
      [WorkspaceType.REGULAR]: "Regular",
      [WorkspaceType.CHILD]: "Hijo",
    },
    createDisabled: "Solo puedes crear un espacio de trabajo cuando estás en el espacio principal",
    noData: "No hay resultados",
    roles: {
      workspacePermissionsSectionTitle: "Espacio de trabajo Hijo",
      table: {
        headers: {
          role: "Rol",
          assigneesCount: "N° de usuarios admin",
          workspace: "Espacio de trabajo",
        },
        actions: {
          [MspRolesAction.ADD]: "Agregar rol MSP",
          [MspRolesAction.EDIT]: "Editar rol",
          [MspRolesAction.DUPLICATE]: "Duplicar rol",
          [MspRolesAction.DELETE]: "Eliminar rol",
        },
      },
    },
    adminUsers: {
      table: {
        headers: {
          name: "Nombre",
          email: "Correo electrónico",
          role: "Rol",
          workspace: "Espacio de trabajo",
        },
        actions: {
          [MspAdminUsersAction.ADD]: "Agregar usuario admin MSP",
          [MspAdminUsersAction.EDIT]: "Editar usuario admin MSP",
          [MspAdminUsersAction.DELETE]: "Eliminar usuario admin MSP",
        },
        you: "Tú",
      },
    },
  },
  logoUploader: {
    imageError: "La imagen no cumple con las especificaciones",
    headerIcon: "Icono del encabezado",
    emailIcon: "Icono del correo",
    replaceImage: "Haz clic para reemplazar",
    description: "Usa un icono cuadrado blanco con fondo transparente. Máximo 500KB. png, svg",
    emailDescription: "Usa un icono cuadrado oscuro con fondo transparente. Máximo 500KB. png, svg",
    imageHint:
      "Barra de encabezado: tu icono de canal se mostrará en la barra de encabezado de la plataforma junto con el tuyo.",
    emailImageHint:
      "Encabezado de correo: el logo del canal se mostrará en comunicaciones por correo junto con el tuyo.",
  },
  backOffice: {
    widgets: {
      overviewWidget: {
        types: {
          [BackOfficeOverviewWidgetType.WINDOWS_DEVICES]: {
            title: "Dispositivos Windows",
          },
          [BackOfficeOverviewWidgetType.MACOS_DEVICES]: {
            title: "Dispositivos macOS",
          },
          [BackOfficeOverviewWidgetType.CUSTOMERS]: {
            title: "Clientes",
          },
          [BackOfficeOverviewWidgetType.PROTECTABLE_USERS]: {
            title: "Usuarios protegibles",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS]: {
            title: "Usuarios protegidos",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS_REPORTED_TO_RECURLY]: {
            title: "Usuarios protegidos reportados a Recurly",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS_IN_PAID_WORKSPACES]: {
            title: "Usuarios protegidos en espacios de trabajo pagos",
          },
        },
      },
      table: {
        headers: {
          coroVersion: "Versión de Coro",
          count: "Número de dispositivos",
        },
      },
    },
    manageAdminPermissions: "Gestionar permisos de administrador",
    title: "Backoffice",
    beVersion: "Rama/commit de BE:",
    agentsGradualUpdate: "Actualización gradual de agentes",
    workspaceGroup: "Grupo de espacios de trabajo",
    deviceGroup: "Grupo de dispositivos",
    betaUpdateGroup: "Grupo de actualización beta",
    totalWorkspaces: "Total de espacios de trabajo",
    totalDevices: "Total de dispositivos",
    permissions: "Permisos",
    manageWorkspaceCodes: "Gestionar códigos de espacios de trabajo",
    roles: {
      workspacePermissionsSectionTitle: "Permisos de espacios de trabajo",
      table: {
        headers: {
          role: "Rol",
          assigneesCount: "N.º de usuarios administradores",
        },
        actions: {
          [GlobalRolesAction.ADD]: "Agregar rol global",
          [GlobalRolesAction.EDIT]: "Editar rol",
          [GlobalRolesAction.DUPLICATE]: "Duplicar rol",
          [GlobalRolesAction.DELETE]: "Eliminar rol",
        },
      },
    },
    adminUsers: {
      table: {
        headers: {
          name: "Nombre",
          email: "Correo",
          role: "Rol",
        },
        actions: {
          [GlobalAdminUsersAction.ADD]: "Agregar usuario administrador global",
          [GlobalAdminUsersAction.EDIT]: "Editar usuario administrador global",
          [GlobalAdminUsersAction.DELETE]: "Eliminar usuario administrador global",
        },
        you: "Tú",
      },
    },
  },
  workspaceCodes: {
    title: "Códigos de espacios de trabajo",
    description: "Crear código promocional de espacio de trabajo",
    subDescription: "Crear código de espacio de trabajo que se utilizará durante la incorporación",
    type: {
      [WorkspaceCodeType.SUBSCRIPTION]: "Suscripción",
      [WorkspaceCodeType.CREDIT_CARD_DETAILS]: "Detalles de tarjeta de crédito",
      [WorkspaceCodeType.FIXED_DISCOUNT]: "Descuento fijo",
      [WorkspaceCodeType.COMMERCIAL_TERMS]: "Términos comerciales",
      [WorkspaceCodeType.TRIAL_DURATION]: "Duración de prueba",
      [WorkspaceCodeType.COMBINED]: "Combinado",
    },
    status: {
      [WorkspaceCodeStatus.ACTIVATED]: "Activo",
      [WorkspaceCodeStatus.DEACTIVATED]: "Desactivado",
    },
    actions: {
      [WorkspaceCodeActions.DELETE]: "Eliminar",
      [WorkspaceCodeActions.ACTIVATE]: "Activar",
      [WorkspaceCodeActions.DEACTIVATE]: "Desactivar",
      [WorkspaceCodeActions.COPY_LINK_TO_SIGNUP]: "Copiar enlace de registro",
      [WorkspaceCodeActions.CREATE]: "Crear código de espacio de trabajo",
      [WorkspaceCodeActions.EXPORT_CSV]: "Exportar CSV",
    },
    codeName: "Nombre",
    codeType: "Tipo",
    codeStatus: "Estado",
    validTill: "Válido hasta",
    assignedWorkspacesCount: "Cantidad de espacios de trabajo",
    priceBookNumber: "Número de lista de precios",
    agentDetails: "Agente",
    mspDetails: "MSP",
    salesDetails: "Agente de ventas",
    expirationDate: "Fecha de expiración",
    workspaceType: "Tipo de espacio de trabajo",
    relatedTo: "Relacionado con",
    relationTypes: {
      [WorkspaceCodeRelationType.GENERAL]: "General",
      [WorkspaceCodeRelationType.AGENT]: "Agente",
      [WorkspaceCodeRelationType.MARKETING]: "Marketing",
      [WorkspaceCodeRelationType.MSP]: "MSP",
      [WorkspaceCodeRelationType.SALES]: "Ventas",
    },
    modules: "Módulos",
    addons: "Complementos",
    bundles: "Paquetes",
    commercialTerms: "Términos comerciales",
    discount: "Descuento",
    trialDuration: "Duración de prueba",
    creditCardRequired: "Se requiere tarjeta de crédito",
  },
  oauthPage: {
    pleaseWait: "Por favor espera, te estamos conectando",
  },
  rangeBundleSelector: {
    [SubscriptionBundle.ENDPOINT_PROTECTION]: {
      description:
        "Registra toda la actividad de los endpoints, analiza anomalías de datos y automatiza la resolución de la mayoría de incidentes de seguridad.",
    },
    [SubscriptionBundle.SASE]: {
      description:
        "Añade protección a los dispositivos de la empresa donde sea que estén a través de una red en la nube impenetrable.",
    },
    [SubscriptionBundle.EMAIL_PROTECTION]: {
      description:
        "Escanea los correos electrónicos en busca de amenazas y remédialos automáticamente, reduciendo el tiempo necesario para gestionar la seguridad del correo.",
    },
    [SubscriptionBundle.CORO_ESSENTIALS]: {
      description:
        "Obtén cobertura esencial para endpoints, correo y aplicaciones en la nube, automatizando la resolución de la mayoría de incidentes de seguridad.",
    },
    [SubscriptionBundle.CORO_COMPLETE]: {
      description: "",
    },
    [SubscriptionBundle.CORO_CLASSIC]: {
      description: "",
    },
    billedAnnually: "Facturado anualmente",
    perUser: "Por Usuario / Mes",
    managed: "Administrado",
    unmanaged: "No administrado",
  },
  moduleOrAddonSelector: {
    [SubscriptionAddon.SOC]: {
      description:
        "Permite que nuestro equipo de Servicios de Seguridad Gestionados maneje la seguridad de correo electrónico, endpoint y SASE por ti",
    },
    [SubscriptionModule.EMAIL_SECURITY]: {
      description: "Protege contra phishing, malware y otros mensajes fraudulentos",
    },
    [SubscriptionModule.NETWORK]: {
      description: "Protege tu red con VPN en la nube, firewall y clúster de oficina remota",
    },
    [SubscriptionModule.USER_DATA_GOVERNANCE]: {
      description: "Aplica manejo seguro de PII y otros datos críticos",
    },
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
      description: "Aplica políticas de seguridad de datos en endpoints",
    },
    [SubscriptionModule.MDM]: {
      description: "Asegura que tus dispositivos móviles permanezcan libres de amenazas",
    },
    [SubscriptionModule.ENDPOINT_SECURITY]: {
      description:
        "Configura la postura de los dispositivos, implementa NGAV y permite/bloquea procesos",
    },
    [SubscriptionModule.CLOUD_SECURITY]: {
      description:
        "Protege contra malware en unidades en la nube y actividad administrativa anómala",
    },
    [SubscriptionModule.EDR]: {
      description: "Reinicia, apaga e aísla dispositivos y procesos comprometidos",
    },
    [SubscriptionAddon.WIFI_PHISHING]: {
      description: "Evita que los usuarios finales se conecten a puntos wifi sospechosos",
    },
    [SubscriptionAddon.SECURED_MESSAGES]: {
      description:
        "Cifra correos electrónicos salientes para que solo los destinatarios puedan verlos",
    },
    [SubscriptionAddon.INBOUND_GATEWAY]: {
      description:
        "Intercepta correos entrantes para que solo remitentes seguros lleguen a los destinatarios",
    },
    [SubscriptionAddon.SWG]: {
      description: "Filtra el tráfico web para proteger tu negocio",
    },
    [SubscriptionAddon.ZTNA]: {
      description: "Asegura el acceso a aplicaciones, datos y servicios",
    },
    addonDisabled: "Selecciona el Módulo <b>{module}</b> para activar este complemento",
    perUser: "por usuario",
    month: "/mes",
    managed: "Administrado",
    unmanaged: "No administrado",
  },
  network: {
    title: "Red",
    noData: {
      title: "Tu oficina virtual se está configurando. <br> Vuelve en unos minutos.",
      subtitle:
        "Si el proceso de configuración tarda más de 10 minutos,<br> contacta a nuestro equipo de soporte.",
      contactSupport: "Contactar soporte",
    },
    virtualOffice: {
      title: "Oficina Virtual",
      vpnPolicy: "Política de VPN",
      labelsAutocompleteLabel: "Etiquetas de dispositivos",
      labelsAutocompletePlaceholder: "Selecciona etiquetas...",
      defaultSettings: "Configuración <br> Predeterminada",
      mode1Description:
        "Por defecto, tus dispositivos protegidos no tienen acceso a tu oficina virtual",
      mode2Description:
        "Usa la VPN para acceder a tu oficina virtual y a cualquier recurso externo marcado como Incluido abajo.",
      mode3Description:
        "Usa la VPN para acceder a toda la red excepto los recursos externos marcados como Excluidos abajo.",
      manualDisconnectionEnabled: "Permitir desconexión manual de la VPN",
      manualDisconnectionDescription:
        "Por defecto, los usuarios no pueden desconectar manualmente sus dispositivos de la VPN, por lo que la política de uso de VPN se aplica completamente de forma automática.",
      blockLocalNetworkEnabled: "Bloquear Red de Área Local",
      blockLocalNetworkDescription:
        "Especifica para qué dispositivos debe bloquearse la Red de Área Local.",
      includedExcludedResources: "Listas de Inclusión/Exclusión",
      excluded: "Excluidos",
      included: "Incluidos",
      allLists: "Todas las listas",
      trafficNotThroughVpn: "Tráfico sin pasar por VPN",
      trafficThroughVpn: "Tráfico a través de VPN",
      ipAddresses: "Dirección IP | Direcciones IP",
      encryptionStrength: {
        title: "Fuerza de cifrado",
        AES128: "AES-128",
        AES256: "AES-256",
        AES256Hint: "Más lento, seguridad de grado militar.",
        AES128Hint: "Más rápido, seguridad de nivel empresarial.",
      },
      actions: {
        addUrlToIncludelist: "Añadir a la lista de inclusión",
        addUrlToExcludelist: "Añadir a la lista de exclusión",
        remove: "Eliminar",
      },
      table: {
        name: "Nombre",
        status: "Estado",
      },
      mobileBanner: {
        title: "Habilitar acceso a la aplicación móvil",
        description:
          "Solo los usuarios protegidos pueden usar la aplicación Coro Endpoint Protection.<br> Asegúrate de que tus usuarios móviles estén incluidos en la lista de usuarios protegidos.",
        importList: "Importar lista de usuarios móviles",
      },
    },
    secureWebGateway: {
      title: "SWG",
      dnsFilteringLabel: "Filtrado de DNS",
      dnsFilteringDescription:
        "Activa el filtrado DNS para bloquear el acceso a recursos externos no deseados desde tu oficina virtual.",
      dnsResolverLabel: "Modo anónimo del servidor DNS",
      dnsResolverDescription:
        "En modo anónimo, el historial de solicitudes DNS no se analiza ni almacena. Solo están disponibles estadísticas generales sobre las solicitudes DNS.",
      localDnsEnabledLabel: "Usar servidor local para solicitudes DNS locales",
      localNetwork: "Red local",
      localNetworkInputPlaceholder: "Introduce el rango de IP en notación CIDR…",
      localNetworkInputLabel: "Dirección IP",
      localDNSServer: "Servidor DNS local",
      localDNSServerInputPlaceholder: "Introduce IP…",
      localDNSServerInputLabel: "Dirección IP",
      allowBlockList: "Listas de Permitir/Bloquear",
      allowBlockListDescription:
        "Los registros a continuación están activos cuando el filtrado DNS está activado",
      allLists: "Todas las listas",
      allCategories: "Todas las categorías",
      actions: {
        addUrlToAllowlist: "Añadir a la lista de permitidos",
        addUrlToBlocklist: "Añadir a la lista de bloqueados",
        importUrlGroupToAllowlist: "Importar grupo de URLs a la lista de permitidos",
        importUrlGroupToBlocklist: "Importar grupo de URLs a la lista de bloqueados",
        removeUrlFromAllowlist: "Eliminar lista",
        removeUrlFromBlocklist: "Eliminar lista",
        viewUrlList: "Ver lista",
        disableUrlGroupAllowList: "Desactivar lista",
        disableUrlGroupBlockList: "Desactivar lista",
        enableUrlGroupAllowList: "Activar lista",
        enableUrlGroupBlockList: "Activar lista",
        removeUrlGroupFromAllowList: "Eliminar lista",
        removeUrlGroupFromBlockList: "Eliminar lista",
      },
      filters: {
        allowed: "Permitido",
        blocked: "Bloqueado",
        allowedDisabled: "Permitido (Desactivado)",
        blockedDisabled: "Bloqueado (Desactivado)",
        url: "URL",
        urlGroup: "Grupo de URLs",
        lockedUrlGroup: "Grupo de URLs bloqueado",
      },
      table: {
        name: "Nombre",
        action: "Acción",
        allowed: "Permitido",
        blocked: "Bloqueado",
      },
    },
    settings: {
      title: "Configuraciones",
      voFirewall: "Excepciones de Firewall de Oficina Virtual",
      voFirewallDisclaimer:
        "Todos los puertos del firewall de la oficina virtual están cerrados a la comunicación entrante a menos que se solicite explícitamente lo contrario en un registro a continuación",
      voFirewallTable: {
        protocol: "Protocolo",
        port: "Puerto",
        allowConnection: "Permitir conexión desde",
      },
      actions: {
        editVoFirewall: "Editar puerto de firewall",
        deleteVoFirewall: "Eliminar puerto de firewall",
      },
      tunnels: {
        title: "Túneles de Sitio a Sitio",
        empty: {
          title: "Agregar túnel de firewall",
          description:
            "Permite que los dispositivos habilitados con Coro se conecten a recursos locales a través del firewall de la empresa",
          addButton: "Agregar túnel",
        },
        addButton: "+ Agregar",
        table: {
          tunnel: "Túnel",
          siteName: "Nombre del sitio",
          remoteGateway: "Gateway remoto",
          status: "Estado",
          [SiteToSiteTunnelStatus.CONNECTED]: "Conectado",
          [SiteToSiteTunnelStatus.DISABLED]: "Desactivado",
          [SiteToSiteTunnelStatus.DISCONNECTED]: "Desconectado",
          testState: {
            title: "Estado de prueba",
            [SiteToSiteTunnelTestState.FAILED]: "Fallido",
            [SiteToSiteTunnelTestState.PASSED]: "Aprobado",
          },
        },
        actions: {
          [SiteToSiteTunnelsAction.TEST]: "Probar",
          [SiteToSiteTunnelsAction.EDIT]: "Editar",
          [SiteToSiteTunnelsAction.DELETE]: "Eliminar",
          [SiteToSiteTunnelsAction.DOWNLOAD_LOG]: "Descargar registro",
          [SiteToSiteTunnelsAction.ENABLE_TUNNEL]: "Habilitar",
          [SiteToSiteTunnelsAction.DISABLE_TUNNEL]: "Deshabilitar",
        },
      },
    },
    initialSetup: {
      title: "Configurando tu Oficina Virtual",
      selectRegionTitle: "Selecciona la región para el enrutamiento global de VPN",
      selectRegionDisclaimer:
        "La selección de región es un proceso único durante la configuración.",
      selectRegionPlaceholder: "Selecciona una región",
    },
  },
  billing: {
    title: "Facturación y Suscripciones",
    tabs: {
      subscription: "Suscripción",
      paymentMethod: "Método de pago",
    },
    subscriptionTab: {
      subscriptionSummary: "Resumen de suscripción",
      planDetails: "Detalles del plan",
      devicesDescription:
        "Actualmente estás suscrito para <strong>{protectedDevicesCount} dispositivo.</strong> | Actualmente estás suscrito para <strong>{protectedDevicesCount} dispositivos.</strong>",
      usersDescription:
        "Actualmente estás suscrito para <strong>{protectedUsersCount} usuario.</strong> | Actualmente estás suscrito para <strong>{protectedUsersCount} usuarios.</strong>",
      updateSubscriptionDescription:
        "Puedes actualizar tu suscripción si tus necesidades cambiaron",
      addDevices: "Agregar dispositivos",
      addUsers: "Agregar usuarios",
      updatePlan: "Actualizar plan",
    },
  },
  messages: {
    title: "Mensajes",
    categories: {
      cloudApps: "Seguridad en la Nube",
      connectors: "Conectores",
    },
    types: {
      [MessageType.ON_BOARDING_COMPLETED]:
        "¡Bienvenido a Coro! Tu cuenta empresarial de {service} ha sido conectada con éxito y\nCoro ahora protege tu negocio. Nuestro sistema se adaptará y optimizará aún más para brindarte \nla seguridad de datos que mejor se ajusta a las especificidades de tu negocio. Este proceso suele tardar algunas horas, ¡pero vale la pena! &#x1F609;",
      [MessageType.AUDIT_LOGS_DISABLED]:
        "No hay acceso a los registros de auditoría de {service}.\nPor favor, verifica que <a href='{link}' target=\"_blank\" class='coro-link'>{linkDescription}</a> esté habilitado.",
      [MessageType.AUDIT_LOGS_DISABLED_RESOLVED]:
        "Se ha restablecido el acceso a los registros de auditoría de {service}.",
      [MessageType.AUDIT_LOGS_INTERRUPTED]:
        "Interrupciones en el acceso a los registros de auditoría de {service}.\nNo hay registros de auditoría desde {startDate}.",
      [MessageType.AUDIT_LOGS_INTERRUPTED_RESOLVED]:
        "Interrupciones en el acceso a los registros de auditoría de {service}.\nEl problema estuvo presente entre {startDate} y {endDate}.\nEl problema ha sido resuelto.",
      [MessageType.GOOGLE_DRIVE_SDK_DISABLED]: "El SDK de Google Drive ha sido deshabilitado",
      [MessageType.GOOGLE_DRIVE_SDK_DISABLED_RESOLVED]: "El SDK de Google Drive ha sido habilitado",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED]: "{service} ha sido desconectado de Coro",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED]:
        "Se ha restablecido la conexión a {service}",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR]:
        "El servicio {service} ha sido desconectado por {operator_name}.",
      [MessageType.ADDON_NOT_INSTALLED]:
        "Recomendamos encarecidamente instalar el complemento de Coro para {service} para permitir retroalimentación instantánea sobre correos electrónicos de phishing de los usuarios en tu organización.",
      [MessageType.CONNECTOR_HAS_BEEN_DISCONNECTED]:
        "El conector {connectorName} ha sido desconectado de Coro",
      [MessageType.MAX_PROTECTED_USERS_HAS_EXCEEDED]:
        "El número de usuarios protegidos ha superado su límite de {MAX_PROTECTED_USERS} usuarios. Para aumentar el límite, por favor contacta a tu proveedor de servicios.",
    },
    auditLogs: "Registros de Auditoría",
    gSuiteAuditLogs: "Registros de auditoría de Google Workspace",
    learnMore: "Saber más",
    noAlerts: "No tienes alertas",
    noNewAlerts: "No tienes nuevas alertas",
    upgradeNow: "Actualizar ahora",
    subscriptionExpired: "Suscripción vencida",
    regularSubscriptionExpiredDescription:
      "Tu espacio de trabajo está actualmente inactivo.<br>Para suscripciones, por favor contacta a tu administrador de cuenta de Coro.",
    mspSubscriptionExpiredDescription:
      "Tu espacio de trabajo está actualmente inactivo.<br>Para suscripciones, por favor contacta a tu proveedor de servicios.",
    daysRemaining: "quedan en tu prueba gratuita",
  },
  pniVerification: {
    success: "¡Gracias!",
    failure: "Algo salió mal",
    successMessage: "Tu retroalimentación ha sido recibida con éxito.",
    failureMessage: "No se recibió tu retroalimentación. Inténtalo de nuevo en unos minutos.",
  },
  mdmCallbackPage: {
    contactSupport: "Contactar Soporte",
    errors: {
      generic: "Se produjo un error al acceder a la gestión de dispositivos móviles",
    },
  },
  login: {
    title: "Iniciar sesión",
    email: "Correo electrónico",
    password: "Contraseña",
    signIn: "Iniciar sesión",
    signUp: "Registrarse",
    dontHaveAccount: "¿No tienes una cuenta?",
    forgotPassword: "¿Olvidaste tu contraseña?",
    errors: {
      badCredentials: "Credenciales incorrectas. Por favor verifica e inténtalo de nuevo.",
      noAccountFound: "No se encontró una cuenta de Coro",
      generalError: "Algo salió mal",
      workspaceIsArchived: "No se encontró una cuenta de Coro",
      loginUnsuccessful: "No pudimos iniciar sesión. Inténtalo más tarde.",
      noOfficeAccount:
        "Esta cuenta de Microsoft 365 no está asociada con una cuenta de Coro. Inténtalo nuevamente o haz clic en el enlace Registrarse a continuación",
      noGoogleAccount:
        "Esta cuenta de Google Workspace no está asociada con una cuenta de Coro. Inténtalo nuevamente o haz clic en el enlace Registrarse a continuación",
      [AccountErrors.NOT_ACCEPTED_INVITATION]:
        "Tu cuenta aún no está activada. Revisa tu correo para el enlace de invitación.",
    },
  },
  forgotPassword: {
    title: "Restablecer contraseña",
    resetPasswordBtn: "Restablecer Contraseña",
    explanation: "Te ayudaremos a restablecerla y volver al camino correcto.",
    backToLoginBtn: "Volver al inicio de sesión",
    backToDashboardBtn: "Volver al tablero",
    successText: "Por favor revisa tu correo y haz clic en el enlace.",
    emailNotFound: "Correo no encontrado. Intenta con un correo diferente.",
    genericError:
      "Algo salió mal durante el restablecimiento de la contraseña, por favor intenta nuevamente.",
    resendLink: "Reenviar enlace",
    tryDifferentEmail: "Intenta con un correo diferente",
    checkYourEmail: "Revisa tu correo",
    recoverInstruction: "Hemos enviado instrucciones de recuperación de contraseña a {email}",
    didNotReceiveEmail: "¿No recibiste el correo? ¡Revisa tu carpeta de spam!",
  },
  resetPassword: {
    title: "Crear Nueva Contraseña",
    resetPasswordBtn: "Restablecer Contraseña",
    newPassword: "Nueva contraseña",
    repeatNewPassword: "Repite la nueva contraseña",
    successText: "Tu contraseña ha sido restablecida con éxito",
    passwordsNotMatch: "Las contraseñas no coinciden.",
    passwordShouldBeDifferent: "La contraseña debe ser diferente a la anterior.",
    resetLinkInvalid: "El enlace para restablecer la contraseña ha expirado o no es válido.",
    genericError:
      "Algo salió mal durante el restablecimiento de la contraseña, por favor intenta nuevamente.",
    description:
      "Tu nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente.",
    weakPassword:
      "La contraseña debe tener al menos 8 símbolos y contener al menos 1 mayúscula, 1 minúscula y 1 símbolo numérico.",
  },
  forms: {
    email: {
      label: "Correo electrónico",
      placeholder: "Ingresa la dirección de correo electrónico",
    },
    password: {
      title: "Contraseña",
      placeholder: "Ingresa la contraseña",
    },
  },
  downloadsPage: {
    title:
      "Has sido invitado por {customer} para instalar <b>Coro Endpoint Protection</b> en tus dispositivos.",
    downloadBtn: "Descargar e Instalar",
    footerDescription:
      "Después de la instalación, tu dispositivo estará protegido bajo la suscripción de {customer}. Una vez que instales <b>Coro Endpoint Protection</b> y lo actives con tu correo electrónico de trabajo, tu dispositivo se registrará bajo la cuenta de {customer}. Esto permitirá a {customer} monitorear el estado de seguridad de tu dispositivo y verificar su ubicación en caso de un evento de seguridad.",
  },
  mfa: {
    title: "Autenticación de Dos Factores",
    notice:
      "Actualizar tu autenticación de dos factores aquí se aplicará a todos los espacios de trabajo de los que eres miembro.",
    mfaEnabled: "Autenticación de Dos Factores está habilitada",
    mfaEnabledDesc:
      "Has habilitado la autenticación de dos factores para tu cuenta usando una aplicación de autenticación.",
    mfaDisabled: "Autenticación de Dos Factores está deshabilitada",
    recoveryMobile: "Tu número de móvil de recuperación",
    disableMFAButton: "Eliminar datos de autenticación de dos factores",
    fallbackTitle: "Habilitar Método de Recuperación Alternativa",
    fallbackDesc:
      "Por favor, ingresa un número de teléfono en caso de que no puedas autenticarte y necesites recuperar tu código vía SMS.",
    fallbackCodeTitle: "Ingresa el código de 6 dígitos que recibiste en tu teléfono",
    finishSetupBtn: "Finalizar configuración",
    smsSent: "SMS enviado",
    createMFATitle: "Crear autenticación de dos factores",
    createMFADesc:
      "Escanea este código QR en tu aplicación de autenticación móvil (como <a class='coro-link' href='https://support.google.com/accounts/answer/1066447?hl=es&co=GENIE.Platform%3DAndroid&oco=0' target='_blank'>Google Authenticator</a>), y luego ingresa el código a continuación para confirmar. Para agregar la entrada manualmente, usa este código: <strong>{code}</strong>. La autenticación de dos factores no estará activa hasta que completes este paso.",
    enterCode: "Ingresa el código",
    resendSMS: "Reenviar SMS",
  },
  verification: {
    title: "Verifica que eres tú",
    app: {
      enterCode: "Ingresa un código de 6 dígitos desde tu aplicación de autenticación",
      fallbackLink: "Enviar código por SMS",
    },
    sms: {
      enterCode: "Ingresa un código de 6 dígitos que recibiste en tu teléfono",
      fallbackLink: "Ingresa código desde la aplicación de autenticación",
      noSMS: "¿No recibiste el SMS?",
    },
    supportLink:
      "<a href='https://docs.coro.net/' class='coro-link' target=\"_blank\">Contactar soporte</a>",
    verifyBtn: "Verificar",
    cantFindCode: "¿No encuentras tu código?",
  },
  invitedPage: {
    cloudAppsSecurity: "Aplicaciones en la Nube",
    emailSecurity: "Correo Electrónico",
    deviceSecurity: "Dispositivos Endpoint",
    dataSecurity: "Datos",
    usersSecurity: "Usuarios",
    title: "Establecer una Contraseña",
    welcomeToCoro: "Bienvenido a Coro",
    youHaveBeenInvited:
      "Has sido invitado por <span class='text--semibold'>{email}</span> para unirte a <span class='text--semibold'>{workplace}</span>",
    youHaveBeenInvitedViaApi:
      "Has sido invitado para unirte a <span class='text--semibold'>{workplace}</span>",
    acceptInvitationBtn: "Aceptar Invitación",
    acceptAndLoginBtn: "Aceptar e Iniciar Sesión",
    setPasswordBtn: "Establecer Contraseña",
    newPassword: "Nueva contraseña",
    successText: "Tu contraseña ha sido establecida exitosamente",
    successfullyJoined: "Te has unido exitosamente al espacio de trabajo",
    goToLogin: "Ir a Iniciar Sesión",
    errors: {
      tokenExpired:
        "El enlace de invitación ha caducado. Por favor, contacta a {admin} para obtener un nuevo enlace.",
      inviteRevoked:
        "La invitación ha sido revocada. Por favor, contacta a {admin} para obtener un nuevo enlace.",
      tokenExpiredViaApi:
        "El enlace de invitación ha caducado. Por favor, contacta al remitente original.",
      inviteRevokedViaApi:
        "La invitación ha sido revocada. Por favor, contacta al remitente original.",
      privacyPolicy: "Debes aceptar los Términos de Uso y la Política de Privacidad",
    },
    termsOfUseDescription: "Acepto los Términos de Uso de Coro",
    termsOfUseLink: "Términos de Uso",
    privacyPolicyLink: "Política de Privacidad",
  },
  settings: {
    title: "Panel de Control",
    protectionCoverage: "Cobertura de Protección",
    account: "Cuenta",
    adminAccounts: "Usuarios Administradores",
    accessControl: "Control de Acceso",
    activityLogs: "Registros de Actividad",
    users: "Usuarios",
    notifications: "Notificaciones",
    endpointSecurity: "Seguridad del Endpoint",
    devices: "Dispositivos",
    connect: "Conectar",
    soc: "Servicio Administrado",
    security: "Configuración de Seguridad",
    malware: "Malware y Ransomware",
    phishing: "Phishing",
    accountBlocker: "Bloqueador de Cuentas",
    dataLoss: "Gobernanza de Datos",
    cloudSecurity: "Seguridad en la Nube",
    cloudApps: "Aplicaciones en la Nube",
    accountSecurity: "Sesiones Activas",
    settingsBreadCrumb: "Configuración",
    protection: "Protección",
    connectors: "Conectores",
    reports: "Informes",
    billing: "Facturación y Suscripciones",
    email: "Seguridad de Correo Electrónico",
    userDataGovernance: "Gobernanza de Datos del Usuario",
    endpointDataGovernance: "Gobernanza de Datos del Endpoint",
    workspace: "Espacio de Trabajo",
    emailProxy: "Proxy de Correo Electrónico",
    coroMail: "Mensajes Seguros",
    network: "Red",
    edr: "EDR",
    mdm: "Gestión de Dispositivos Móviles",
    mdmShort: "Gestión de Dispositivos",
    demoMode: {
      title: "Modo de Demostración",
      resetBtn: "Restablecer",
    },
  },
  sso: {
    loginInProcess: "Por favor espera, estamos iniciando sesión.",
    errors: {
      genericError:
        "Algo salió mal durante el inicio de sesión. Por favor, intenta nuevamente más tarde.",
    },
  },
  signUpPage: {
    title: "Prueba Coro Gratis",
    signUpOffice: "Regístrate con tu cuenta de administrador de Microsoft 365",
    signUpGoogle: "Regístrate con tu cuenta de administrador de Google Workspace",
    signUpBtn: "Regístrate",
    description: "Tu prueba gratuita incluye todas las funciones premium",
    cloudAppsSecurity: "Aplicaciones en la Nube",
    emailSecurity: "Correo Electrónico",
    deviceSecurity: "Dispositivos Endpoint",
    dataSecurity: "Datos",
    usersSecurity: "Usuarios",
    welcomeToCoronet: "Bienvenido a Coro",
    office365NextStep: "Estás a un paso de obtener protección completa para Microsoft 365.",
    googleNextStep: "Estás a un paso de obtener protección completa para Google Workspace.",
    finishSetupBtn: "Finalizar configuración",
    errors: {
      notAdmin: "Debes iniciar sesión en Coro con una cuenta de administrador",
      accountNotAssociatedOffice:
        "La cuenta no está asociada a un rol de administrador en Microsoft 365",
      accountNotAssociatedGoogle:
        "La cuenta no está asociada a un rol de administrador en Google Workspace",
      accountAlreadyCreated: "La cuenta para tu empresa ya está creada.",
      contactAdmin: "Contacta a <span class='coro-link'>{admin}</span> para crear una cuenta.",
      newOfficeAccountAlertText: "Esta cuenta de Microsoft 365 parece ser nueva.",
      newOfficeAccountErrorText:
        "Como puede tomar unas horas para que Microsoft 365 configure privilegios para cuentas nuevas, por favor regístrate en Coro un poco más tarde.",
      privacyPolicy: "Debes aceptar los Términos de Uso y la Política de Privacidad",
    },
    termsOfUseDescription: "Acepto los Términos de Uso de Coro",
    termsOfUseLink: "Términos de Uso",
    privacyPolicyLink: "Política de Privacidad",
  },
  dashboard: {
    addons: "Complementos",
    seeDisabledModules: "Ver módulos deshabilitados...",
    hideDisabledModules: "Ocultar módulos deshabilitados...",
    seeDisabledAddons: "Ver complementos deshabilitados...",
    hideDisabledAddons: "Ocultar complementos deshabilitados...",
    widgets: {
      overviewWidget: {
        noIssues: "Sin problemas",
        addProtection: "Agregar protección",
        ticketsToReview: "ticket para revisar | tickets para revisar",
        types: {
          userDataGovernance: {
            title: "Gobernanza de Datos de Usuario",
            description: "Problemas",
          },
          endpointDataGovernance: {
            title: "Gobernanza de Datos de Endpoint",
            description: "Problemas",
          },
          emailSecurity: {
            title: "Seguridad de Correo",
            description: "Problemas",
          },
          endpointSecurity: {
            title: "Seguridad de Endpoint",
            description: "Problemas",
          },
          cloudSecurity: {
            title: "Seguridad en la Nube",
            description: "Problemas",
          },
          edr: {
            title: "EDR",
          },
          network: {
            title: "Red",
            description: "Configuración de ajustes",
          },
          mdm: {
            title: "Gestión de Dispositivos Móviles",
          },
          inboundGateway: {
            title: "Gateway de Entrada",
            description: "Configuración de ajustes",
          },
          wifiPhishing: {
            title: "Wi-Fi Phishing",
            description: "Configuración de ajustes",
          },
          secureWebGateway: {
            title: "Gateway Web Seguro",
            description: "Configuración de ajustes",
          },
          securedMessages: {
            title: "Mensajes Seguros",
            description: "Configuración de ajustes",
          },
          placeholder: {
            title: "No hay módulos habilitados",
            noAccessTitle: "Sin acceso a los módulos habilitados",
          },
        },
        resolved: "resuelto",
        ninetyDays: "90 días",
      },
      alerts: {
        title: "Mensajes",
        categories: {
          cloudApps: "Seguridad en la Nube",
          connectors: "Conectores",
        },
        types: {
          ON_BOARDING_COMPLETED:
            "¡Bienvenido a Coro! Tu cuenta de negocio de {service} se ha conectado correctamente y Coro ahora protege tu negocio. Nuestros sistemas se adaptarán y optimizarán para ofrecerte una seguridad de datos acorde a las necesidades de tu empresa. Este proceso suele llevar unas horas, ¡pero vale la pena! &#x1F609;",
          AUDIT_LOGS_DISABLED:
            "No hay acceso a los registros de auditoría de {service}.<br>Verifica que <a href='{link}' target=\"_blank\">{linkDescription}</a> esté habilitado.",
          AUDIT_LOGS_DISABLED_RESOLVED:
            "Se ha restablecido el acceso a los registros de auditoría de {service}.",
          AUDIT_LOGS_INTERRUPTED:
            "Interrupciones en el acceso a los registros de auditoría de {service}.<br>Sin registros de auditoría desde {startDate}.",
          AUDIT_LOGS_INTERRUPTED_RESOLVED:
            "Interrupciones en el acceso a los registros de auditoría de {service}.<br>El problema persistió entre {startDate} y {endDate}.<br>El problema se ha resuelto.",
          GOOGLE_DRIVE_SDK_DISABLED: "El SDK de Google Drive ha sido deshabilitado",
          GOOGLE_DRIVE_SDK_DISABLED_RESOLVED: "El SDK de Google Drive ha sido habilitado",
          SERVICE_HAS_BEEN_DISCONNECTED: "{service} se ha desconectado de Coro",
          SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED: "La conexión con {service} se ha restablecido",
          SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR:
            "El servicio {service} ha sido desconectado por {operator_name}.",
          ADDON_NOT_INSTALLED:
            "Recomendamos instalar el complemento de Coro para {service} para permitir retroalimentación inmediata sobre correos electrónicos de phishing de los usuarios en tu organización.",
          CONNECTOR_HAS_BEEN_DISCONNECTED: "El conector {connectorName} se ha desconectado de Coro",
          MAX_PROTECTED_USERS_HAS_EXCEEDED:
            "El número de usuarios protegidos ha superado su límite de {MAX_PROTECTED_USERS} usuarios. Para aumentar el límite, contacta a tu proveedor de servicios.",
        },
        auditLogs: "Registros de Auditoría",
        gSuiteAuditLogs: "Registros de auditoría de Google Workspace",
        learnMore: "Aprende más",
        noAlerts: "No tienes ninguna alerta",
        noNewAlerts: "No tienes nuevas alertas",
        upgradeNow: "Actualizar Ahora",
        subscriptionExpired: "Suscripción Expirada",
        regularSubscriptionExpiredDescription:
          "Tu espacio de trabajo está inactivo.<br>Para suscribirte, contacta a tu gerente de cuenta de Coro.",
        mspSubscriptionExpiredDescription:
          "Tu espacio de trabajo está inactivo.<br>Para suscribirte, contacta a tu proveedor de servicios.",
        daysRemaining: "días restantes en tu prueba gratuita",
      },
      allIssues: "Todos los problemas",
      userDataGovernance: {
        title: "Gobernanza de Datos de Usuario",
        noDataLossProtection:
          "Actualmente no tienes gobernanza de datos de usuario ni protección contra pérdida de datos",
        noDLPServicesConnected: "Conecta Microsoft 365 o Google Workspace a Coro",
        objectsProcessed: "Objetos de datos procesados",
        topViolators: "Usuarios con Violaciones de Permiso",
        last90Days: "90 días",
      },
      endpointDataGovernance: {
        title: "Gobernanza de Datos de Endpoint",
        noDataLossProtection:
          "Actualmente no tienes gobernanza de datos de endpoint ni protección contra pérdida de datos",
        noDLPServicesConnected:
          "Instala el cliente de Seguridad de Endpoint de Coro en los dispositivos de los usuarios",
        objectsProcessed: "Objetos de datos procesados",
        topViolators: "Usuarios con Violaciones de Permiso",
        last90Days: "90 días",
        addProtectionBtn: "Agregar Protección",
      },
      devices: {
        title: "Seguridad de Endpoint",
        chartLabel: "{n} Dispositivo | {n} Dispositivos",
        protectedDevices: "Dispositivos",
        noDevicesProtected: "Actualmente no tienes dispositivos protegidos",
        installCoroClients:
          "Instala el cliente de Seguridad de Endpoint de Coro en los dispositivos de los usuarios",
        addProtectionBtn: "Agregar Protección",
        protectedUsers: "Usuarios protegidos",
        last90Days: "90 días",
      },
      emailPhishing: {
        title: "Seguridad de Correo",
        emailSecurityDisabled: "Tu seguridad de correo está actualmente deshabilitada",
        enableEmailMonitoring: "Habilita el monitoreo de tus correos para malware y phishing",
        enableEmailMonitoringBtn: "Habilitar",
        noEmailServicesConnected: "Tu aplicación de correo no está conectada",
        connectSecureCloud: "Actualmente no estás conectado a Microsoft 365 y/o Google Workspace",
        addProtectionBtn: "Agregar Protección",
        topSuspiciousDomains: "Principales Orígenes de Correos Sospechosos",
        emailsScanned: "Correos Procesados",
        maliciousEmails: "Maliciosos",
        quarantined: "En cuarentena",
        blockedAndDiscarded: "Bloqueados y Descartados",
        noSuspiciousDomainsFound: "No se encontraron dominios sospechosos",
        suspiciousPhishing: "Contenido Sospechoso en Correos",
        suspiciousMalware: "Malware en Archivos Adjuntos de Correos",
        last90Days: "90 días",
      },
      cloudSecurity: {
        title: "Seguridad en la Nube",
        noAppsProtected: "Actualmente no tienes aplicaciones en la nube protegidas",
        noAppsProtectedDescription:
          "Conecta Microsoft 365 y/o Google Workspace para comenzar la protección",
        noIssuesFound: "No se encontraron problemas",
        notConnected: "No conectado",
        connectionIncomplete: "Conexión incompleta",
        auditLogsAreDisabled: "Los registros de auditoría están deshabilitados",
        protectedUsers: "Usuarios protegidos",
        chartLabel: "{n} Usuario | {n} Usuarios",
        services: {
          boxEnforcement: "Box",
          googleDirectoryEnforcement: "Google",
          dropboxEnforcement: "Dropbox",
          office365Enforcement: "Microsoft 365",
          slackEnforcement: "Slack",
          salesforceEnforcement: "Salesforce",
          zoomEnforcement: "Zoom",
        },
      },
      disclaimerWidget: {
        title: "No tienes módulos habilitados",
        regularWorkspaceDescription:
          "Contacta a ventas de Coro para habilitar los módulos deseados.",
        mspWorkspaceDescription:
          "Contacta a tu proveedor de servicios para habilitar los módulos deseados.",
        noAccess: "No tienes acceso a ningún módulo",
      },
      edr: {
        title: "EDR",
        processes: "Procesos",
        topProcesses: "Procesos más impactantes",
        last30Days: "30 días",
      },
    },
  },
  comments: {
    title: "Comentarios",
    sentTo: "Notificaciones enviadas a:",
    notifySocContacts: "Contactos del servicio administrado",
    notifyAllWorkspaceAdmins: "Todos los administradores del espacio de trabajo",
    notifyAffectedUsers: "Todos los usuarios afectados",
    plusNComments: "+ {n} comentarios",
    confidential: "Confidencial",
    noPermission: "No tienes permiso para comentar en este ticket",
    unread: "No leído",
    read: "Leído",
    markAsRead: "Marcar como leído",
    markAsUnread: "Marcar como no leído",
    noComments: "Sin comentarios",
  },
  phishingEmailDetails: {
    emailBodyEmpty: "El cuerpo del correo está vacío",
  },
};
