<template>
  <v-tooltip v-if="!isEmpty(getBetaTag(addonOrModule))" open-delay="300" location="top">
    <template #activator="{ props }">
      <coro-label
        background-color="rgb(var(--v-theme-orange-base))"
        mix-blend-mode="normal"
        v-bind="props"
        density="xs"
      >
        {{ $t("betaChip.title") }}
      </coro-label>
    </template>
    {{
      $t("betaChip.tooltip", { n: format(getBetaTag(addonOrModule)?.endDate || 0, "yyyy-MM-dd") })
    }}
  </v-tooltip>
</template>

<script lang="ts">
import isEmpty from "lodash/isEmpty";
import { getBetaTag } from "@/_helpers/utils";
import { format } from "date-fns";
import CoroLabel from "@/components/CoroLabel.vue";

export default {
  components: {
    CoroLabel,
  },
  props: {
    addonOrModule: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      isEmpty,
      getBetaTag,
      format,
    };
  },
};
</script>
