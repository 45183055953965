<template>
  <div class="d-flex">
    <div
      v-if="!isEmpty(segment.key)"
      class="segment-key body3 text-primary"
      v-html="segment.key.displayName"
    />
    <div
      v-if="!isEmpty(segment.operator)"
      class="segment-operator body3 text-primary ml-1 mr-1"
      v-html="segment.operator.displayName"
    />
    <div
      v-if="segment.value"
      class="segment-value body3 text-primary"
      :class="{ 'segment-value--single': isSingleSelect }"
    >
      <span>{{ segment.value }}</span>
      <v-icon icon="$x" size="16" class="ml-1 cursor-pointer" @click="onItemRemoveClick" />
    </div>
  </div>
</template>

<script lang="ts">
import isEmpty from "lodash/isEmpty";
import { computed, defineComponent, type PropType } from "vue";
import type {
  SmartSearchSegmentKey,
  SmartSearchSegmentOperator,
} from "@/components/smart-search/SmartSearch.vue";
import type { TelemetryType } from "@/constants/edr";
import type { TimeRange } from "@/_store/filters.module";

export interface SmartSearchSegment {
  key?: SmartSearchSegmentKey;
  operator?: SmartSearchSegmentOperator;
  value: string | TelemetryType[] | TimeRange;
  id?: number;
  displayValue?: string;
}

export default defineComponent({
  props: {
    segment: {
      type: Object as PropType<SmartSearchSegment>,
      required: true,
    },
  },
  emits: ["segment-removed"],
  setup(props, { emit }) {
    const isSingleSelect = computed(() => {
      return !props.segment.key && !props.segment.operator;
    });

    const onItemRemoveClick = () => {
      emit("segment-removed", props.segment);
    };
    return {
      isEmpty,
      onItemRemoveClick,
      isSingleSelect,
    };
  },
});
</script>

<style scoped lang="scss">
.segment-key {
  background-color: rgb(var(--v-theme-indigo-faint));
  border-radius: 20px 4px 4px 20px;
  padding: 4px 8px;
  height: 26px;
}

.segment-operator {
  background-color: rgb(var(--v-theme-green-faint));
  border-radius: 4px;
  padding: 4px 8px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
}

.segment-value {
  background-color: rgb(var(--v-theme-indigo-faint));
  border-radius: 4px 20px 20px 4px;
  padding: 4px 8px;
  height: 26px;
  &--single {
    border-radius: 20px;
  }
}
</style>
