<template>
  <div class="mb-8">
    <div class="subtitle1 mb-4">{{ $t("modals.addDevicePosturePolicy.action") }}</div>
    <v-checkbox
      v-if="isWindows"
      v-model="config.blockPortableDevices"
      :label="$t('modals.addDevicePosturePolicy.usbLockdown.blockPortableDevices')"
      :ripple="false"
      :rules="required"
      :hide-details="true"
      :validation-value="isUsbLockdownDisabled"
    >
    </v-checkbox>
    <v-checkbox
      v-model="config.blockMassStorage"
      :label="$t('modals.addDevicePosturePolicy.usbLockdown.blockMassStorage')"
      :ripple="false"
      :rules="required"
      :hide-details="false"
      class="mt-2 mb-2"
      :validation-value="isUsbLockdownDisabled"
    >
    </v-checkbox>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from "vue";
import { DevicePostureOsType } from "@/constants/devices";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const { config } = toRefs(props);

    const isWindows = computed(() => {
      return config.value.osType === DevicePostureOsType.WINDOWS;
    });

    const isUsbLockdownDisabled = computed(() => {
      return !config.value.blockPortableDevices && !config.value.blockMassStorage;
    });

    return {
      required: [
        (value: boolean) => {
          if (value) {
            return i18n.t("validations.required");
          }

          return true;
        },
      ],
      isWindows,
      isUsbLockdownDisabled,
    };
  },
});
</script>
