<template>
  <div class="subtitle1 mb-4">
    {{ title }}
    <span v-if="subTitle" class="body3">{{ ` (${subTitle})` }}</span>
  </div>
  <template v-if="tilesData.length">
    <div v-for="(item, index) in tilesData" :key="index" class="mb-4">
      <div class="body2">
        {{ item[itemKey] }}
      </div>
      <div class="d-flex justify-start align-center">
        <CoronetProgress
          :value="percentage(item.count)"
          :height="4"
          color="rgb(var(--v-indigo-medium-base))"
        />
        <span class="ml-2 coro-body2 coronet-primary--text">{{ item.count }}</span>
      </div>
    </div>
  </template>
  <div v-else class="body2 text-indigo-medium">
    {{ $t("reports.dnsSummaryReport.noData") }}
  </div>

  <div
    v-if="showViewMoreButton"
    role="button"
    class="coro-link item-expander"
    data-html2canvas-ignore
    @click="expanded = !expanded"
  >
    <span v-if="expanded">
      {{ $t("general.seeLess") }}
    </span>
    <span v-else class="item-collapsed">
      {{ $t("general.seeMore") }}
    </span>
  </div>
</template>

<script lang="ts">
import CoronetProgress from "@/components/CoronetProgress.vue";
import { computed, defineComponent, ref, type PropType } from "vue";

interface ReportTile {
  [key: string]: unknown;
  count: number;
}

export default defineComponent({
  components: { CoronetProgress },
  props: {
    data: {
      type: Array as PropType<ReportTile[]>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: "",
    },
    itemKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const expanded = ref(false);
    const limit = 3;
    const highestCountValue = Math.max(...props.data.map(({ count }) => count));
    const percentage = (count: number) => {
      return Math.floor((count / highestCountValue) * 100);
    };

    const tilesData = computed(() => {
      return expanded.value ? props.data : props.data.slice(0, limit);
    });

    const showViewMoreButton = computed(() => {
      return props.data.length > limit;
    });

    return {
      percentage,
      showViewMoreButton,
      expanded,
      tilesData,
    };
  },
});
</script>

<style scoped lang="scss"></style>
