export enum AdminUsersAction {
  ADD = "addAdminUser",
  EDIT = "editAdminUser",
  DELETE = "deleteAdminUser",
  CONTENT_INSPECTION = "contentInspection",
  RESEND_INVITE = "resendInvite",
  DELETE_MFA = "deleteMFA",
  TOGGLE_WORKSPACE_MFA = "toggleWorkspaceMFA",
  COPY_INVITE_LINK = "copyInviteLink",
}

export enum MspAdminUsersAction {
  ADD = "addMspAdminUser",
  EDIT = "editMspAdminUser",
  DELETE = "deleteMspAdminUser",
}

export enum GlobalAdminUsersAction {
  ADD = "addGlobalAdminUser",
  EDIT = "editGlobalAdminUser",
  DELETE = "deleteGlobalAdminUser",
}

export enum AdminAccountStatus {
  ACTIVE = "ACTIVE",
  INVITED = "INVITED",
}
