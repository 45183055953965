<template>
  <div class="no-users">
    <v-icon class="no-items-icon mt-3 mb-3" icon="$users" size="80" />
    <div class="headline5 mb-9">
      {{ $t("usersSettings.noItems.description") }}
    </div>

    <div class="d-flex justify-center no-users__items">
      <div class="no-users__item">
        <v-menu offset-y>
          <template #activator="{ props }">
            <v-btn
              data-testid="users-page-add-groups-btn-empty-state"
              class="mb-4"
              color="primary"
              rounded
              large
              v-bind="props"
            >
              {{ $t("usersSettings.addGroupsBtn") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              data-testid="users-page-add-groups-action-empty"
              @click="
                onActionClick({
                  action: userGroupActions.ADD_NEW_GROUP,
                  disable: actionNotAllowed,
                })
              "
            >
              <v-list-item-title>
                {{ $t("usersSettings.actions.addGroups") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              data-testid="users-page-add-all-users-action-empty"
              @click="
                onActionClick({
                  action: userActions.IMPORT_USERS_FROM_CSV,
                  disable: actionNotAllowed,
                })
              "
            >
              <v-list-item-title>
                {{ $t("usersSettings.actions.addAllUsers") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <div data-testid="users-page-add-users-description" class="coro-body2">
          {{ groupsDescription }}
        </div>
      </div>
      <div class="no-users__item">
        <v-menu offset-y>
          <template #activator="{ props }">
            <v-btn
              data-testid="users-page-add-users-btn-empty-state"
              class="mb-4"
              color="primary"
              rounded
              large
              v-bind="props"
            >
              {{ $t("usersSettings.addUsersBtn") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              data-testid="users-page-add-users-action-empty"
              @click="
                onActionClick({
                  action: userActions.ADD_USERS,
                  disable: actionNotAllowed,
                })
              "
            >
              <v-list-item-title>
                {{ $t("usersSettings.actions.addUsers") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              data-testid="users-page-import-users-action"
              @click="
                onActionClick({
                  action: userActions.IMPORT_USERS_FROM_CSV,
                  disable: actionNotAllowed,
                })
              "
            >
              <v-list-item-title>
                {{ $t("usersSettings.actions.importUsersFromCsv") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              data-testid="users-page-add-all-users-action-empty"
              @click="
                onActionClick({
                  action: userActions.ADD_ALL_USERS,
                  disable: actionNotAllowed,
                })
              "
            >
              <v-list-item-title>
                {{ $t("usersSettings.actions.addAllUsers") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <div data-testid="users-page-add-groups-description" class="coro-body2">
          {{ usersDescription }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { computed, defineComponent } from "vue";
import { i18n } from "@/plugins/i18n";
import { UserAction, UserGroupAction } from "@/constants/users";
import { CoroIcons } from "@/constants/coro-icon";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

export default defineComponent({
  props: {
    usersDescription: {
      type: String,
      default() {
        return i18n.global.t("usersSettings.noItems.usersDescription");
      },
    },
    groupsDescription: {
      type: String,
      default() {
        return i18n.global.t("usersSettings.noItems.groupsDescription");
      },
    },
  },
  emits: ["action-clicked"],
  setup(_, { emit }) {
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      );
    });

    const onActionClick = ({ action, disable }: { action: string; disable: boolean }) => {
      emit("action-clicked", {
        action,
        disable,
      });
    };

    return {
      coronetIcons: CoroIcons,
      userActions: UserAction,
      userGroupActions: UserGroupAction,
      actionNotAllowed,
      onActionClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.no-users {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__items {
    max-width: 750px;
  }

  &__item {
    padding: 0 30px;
    width: 50%;
    box-sizing: initial;
  }
}
</style>
