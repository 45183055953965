export enum WebhookStatus {
  ENABLED = "enabled",
  DISCONNECTED = "disconnected",
  DISABLED = "disabled",
  INACTIVE = "inactive",
  ERROR = "error",
}

export enum WebhookTriggerTestStatus {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum WebhookTriggerType {
  WORKSPACE_CREATED = "workspaceCreated",
  WORKSPACE_STATUS_UPDATED = "workspaceStatusUpdated",
  SUBSCRIPTION_MODULES_UPDATED = "subscriptionModulesUpdated",
  SUBSCRIPTION_ADDONS_UPDATED = "subscriptionAddonsUpdated",
  SUBSCRIPTION_BUNDLES_UPDATED = "subscriptionBundlesUpdated",
  PROTECTED_USERS_ADDED = "protectedUsersAdded",
  PROTECTED_USERS_UPDATED = "protectedUsersUpdated",
  PROTECTED_USERS_DELETED = "protectedUsersDeleted",
  PROTECTED_USERS_COUNT_UPDATED = "protectedUsersCountUpdated",
  PROTECTED_DEVICES_UPDATED = "protectedDevicesUpdated",
  PROTECTED_DEVICES_ADDED = "protectedDevicesAdded",
  PROTECTED_DEVICES_DELETED = "protectedDevicesDeleted",
  PROTECTED_DEVICES_COUNT_UPDATED = "protectedDevicesCountUpdated",
  SUBSCRIPTION_UPDATED = "subscriptionUpdated",
}

export enum WebhookAction {
  ADD = "addWebhook",
  EDIT = "editWebhook",
  TEST = "testWebhook",
  DEACTIVATE = "deactivateWebhook",
  ACTIVATE = "activateWebhook",
  REMOVE = "removeWebhook",
  ACTION_REQUIRED = "actionRequired",
  CANNOT_ACTIVATE = "cannotActivate",
}
