import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import type { AdaptedPsaConnectorServiceMapping } from "@/_store/connectors/adapters";
import { PsaConnectionStatus, servicesMappingAdapter } from "@/_store/connectors/adapters";
import { upperCase } from "lodash";
import type { Pagination } from "@/types";
import type {
  MappablePsaItemsFilter,
  PsaConnectorMappableCompany,
  PsaConnectorMappableProduct,
  PsaConnectorWorkspaceMapping,
  PsaConnectorWorkspaceMappingUpdate,
  PsaMappingSelectOption,
} from "@/_store/connectors/psa.module";
import { CoroProductNames, PsaProviders } from "@/constants/connectors";

interface State {
  loading: boolean;
  showSkeletonLoader: boolean;
  companiesLoading: boolean;
  workspaceMapping: PsaConnectorWorkspaceMapping[];
  companies: PsaConnectorMappableCompany[];
  servicesMapping: AdaptedPsaConnectorServiceMapping[];
  servicesMappingCache: AdaptedPsaConnectorServiceMapping[];
  products: PsaConnectorMappableProduct[];
  pagination: Pagination;
  totalWorkspaceMappings: number;
}

const defaultState = {
  connectors: [],
  loading: false,
  showSkeletonLoader: false,
  companiesLoading: false,
  workspaceMapping: [],
  companies: [],
  products: [],
  servicesMapping: [],
  servicesMappingCache: [],
  pagination: {
    page: 0,
    pageSize: 25,
  },
  totalWorkspaceMappings: 0,
};

export const usePsaConfigurationStore = (provider: PsaProviders) => {
  return defineStore(`psaConfiguration${provider}`, {
    state: (): State => ({ ...defaultState }),
    getters: {
      companiesMappingOptions(state): PsaMappingSelectOption[] {
        return (
          state.companies?.map(({ id, name, mapped }) => {
            return {
              title: name,
              value: id,
              isMapped: mapped,
            };
          }) || []
        );
      },
      productsMappingOptions(state): PsaMappingSelectOption[] {
        return (
          state.products?.map(({ id, identifier, mapped }) => {
            return {
              title: identifier,
              value: id,
              isMapped: mapped,
            };
          }) || []
        );
      },
    },
    actions: {
      setPagination(pagination: Pagination) {
        this.pagination = pagination;
      },
      resetPagination() {
        this.pagination = { ...defaultState.pagination };
      },
      async getWorkspaceMapping(
        filters: MappablePsaItemsFilter,
        showSkeletonLoader: boolean = true
      ): Promise<void> {
        if (!showSkeletonLoader) {
          this.loading = true;
        }

        this.showSkeletonLoader = showSkeletonLoader;
        const method =
          provider === PsaProviders.CONNECTWISE
            ? api.getPsaConnectwiseConnector
            : api.getPsaAutotaskConnector;

        const connector = await axiosInstance.request(method());

        if (connector.data.status === PsaConnectionStatus.CONNECTED) {
          const getWorkspaceMappingMethod =
            provider === PsaProviders.CONNECTWISE
              ? api.connectwiseWorkspacesMapping
              : api.autotaskWorkspacesMapping;

          try {
            const response = await axiosInstance.request({
              ...getWorkspaceMappingMethod(),
              params: {
                search: filters.search,
                status: upperCase(filters.status),
                ...this.pagination,
              },
            });

            this.loading = false;
            this.showSkeletonLoader = false;

            this.workspaceMapping = response.data.items;
            this.totalWorkspaceMappings = response.data.total;
            return;
          } catch (err) {
            this.loading = false;
            this.showSkeletonLoader = false;
            console.error(err);
          }
        }
      },
      async getServiceMapping(
        filter: MappablePsaItemsFilter,
        showSkeletonLoader = true
      ): Promise<void> {
        if (!showSkeletonLoader) {
          this.loading = true;
        }

        this.showSkeletonLoader = showSkeletonLoader;

        const method =
          provider === PsaProviders.CONNECTWISE
            ? api.getPsaConnectwiseConnector
            : api.getPsaAutotaskConnector;

        const connector = await axiosInstance.request(method());

        if (connector.data.status === PsaConnectionStatus.CONNECTED) {
          try {
            const serviceMappingMethod =
              provider === PsaProviders.CONNECTWISE
                ? api.connectwiseServicesMapping
                : api.autotaskServicesMapping;

            const response = await axiosInstance.request({
              ...serviceMappingMethod(),
            });

            const adaptedServices = servicesMappingAdapter(response.data, filter);
            this.servicesMapping = adaptedServices;
            this.servicesMappingCache = adaptedServices;
            this.showSkeletonLoader = false;
            this.loading = false;
          } catch (err) {
            console.error(err);
            this.showSkeletonLoader = false;
            this.loading = false;
          }
        }
      },
      async updateWorkspaceMapping(data: PsaConnectorWorkspaceMappingUpdate): Promise<void> {
        try {
          this.loading = true;

          const method =
            provider === PsaProviders.CONNECTWISE
              ? api.connectwiseCompanies
              : api.autotaskCompanies;

          const request = {
            ...method(),
            method: "put",
            data,
          };

          await axiosInstance.request(request);
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.error(err);
          throw err;
        }
      },
      async updateServiceMapping({
        service,
        product,
      }: {
        service: CoroProductNames;
        product?: {
          id: string;
          identifier: string;
        };
      }): Promise<void> {
        try {
          this.loading = true;

          const data = product
            ? {
                coroproductId: service,
                product,
              }
            : {
                coroproductId: service,
              };

          const method =
            provider === PsaProviders.CONNECTWISE ? api.connectwiseProducts : api.autotaskProducts;

          const request = {
            ...method(),
            method: "put",
            data,
          };

          await axiosInstance.request(request);
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.error(err);
          throw err;
        }
      },
      async getCompanies(filter: string): Promise<void> {
        try {
          this.companiesLoading = true;

          const method =
            provider === PsaProviders.CONNECTWISE
              ? api.connectwiseCompanies
              : api.autotaskCompanies;

          const request = {
            ...method(),
            params: {
              search: filter,
            },
          };

          const response = await axiosInstance.request(request);

          this.companies = response.data;
          this.companiesLoading = false;
        } catch (err) {
          this.companiesLoading = false;
          console.error(err);
          throw err;
        }
      },
      async getProducts(filter: string): Promise<void> {
        try {
          this.companiesLoading = true;

          const method =
            provider === PsaProviders.CONNECTWISE ? api.connectwiseProducts : api.autotaskProducts;

          const request = {
            ...method(),
            params: {
              search: filter,
            },
          };

          const response = await axiosInstance.request(request);

          this.products = response.data;
          this.companiesLoading = false;
        } catch (err) {
          this.companiesLoading = false;
          console.error(err);
          throw err;
        }
      },
    },
  })();
};
