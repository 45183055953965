<template>
  <div class="coro-widget">
    <template v-if="widgetData && widgetData.protectedDevices > 0">
      <div class="coro-widget__content">
        <div class="coro-widget__tile coro-widget__tile-content">
          <div class="d-inline-flex">
            <div class="d-flex align-start flex-column">
              <div class="coro-widget-number" data-testid="device-widget-protected-devices">
                {{ widgetData.protectedDevices }}
              </div>
              <div class="body2">
                <span>{{ $t("dashboard.widgets.devices.protectedDevices") }}</span>
                <router-link
                  v-if="!isAccessRestricted(rolePermissionsScope.VIEWS, 'devicesView')"
                  :to="{ name: RouteName.DEVICES }"
                >
                  <a class="coro-link text-no-wrap ml-2" data-testid="devices-widget-view-devices"
                    >{{ $t("general.view") }} ›</a
                  >
                </router-link>
              </div>
            </div>
          </div>
          <div class="coro-widget__tile__doughnut-chart">
            <doughnut-chart :data="chartData" @on-chart-click="onChartClick($event)">
            </doughnut-chart>
          </div>
        </div>
        <div class="coro-widget__tile device-table">
          <div data-testid="device-widget-vulnerabilities-table">
            <vulnerabilities-tile
              :widget-type="widgetConfig.widgetType"
              :widget-data="widgetData"
              @all-resolved-link-clicked="viewAllTickets(false, widgetFilter)"
              @all-active-link-clicked="viewAllTickets(true, widgetFilter)"
              @vulnerability-clicked="viewVulnerability($event, widgetFilter)"
            ></vulnerabilities-tile>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="coro-widget__content coro-widget--empty"
      data-testid="device-widget-no-devices-container"
    >
      <v-icon class="empty-data-icon" :icon="`$${emptyDataIcon}`" />
      <div class="headline5 mt-4">
        {{ $t("dashboard.widgets.devices.noDevicesProtected") }}
      </div>
      <div class="caption text-indigo-medium mt-3 mb-8">
        {{ $t("dashboard.widgets.devices.installCoroClients") }}
      </div>
      <router-link
        v-if="
          !isAccessRestricted(
            rolePermissionsScope.WORKSPACE_MANAGEMENT,
            workspaceManagementScope.DEVICES
          )
        "
        :to="{ name: RouteName.DEVICES_SETTINGS_PAGE }"
      >
        <v-btn color="primary" rounded size="large" data-testid="device-widget-add-protection-btn">
          {{ $t("dashboard.widgets.devices.addProtectionBtn") }}
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue";
import { TicketsModuleFilter } from "@/constants/tickets";
import VulnerabilitiesTile from "@/components/VulnerabilitiesTile.vue";
import DoughnutChart from "@/components/DoughnutChart.vue";
import { WidgetType } from "@/constants/dashboard";
import { DoughnutChartColors } from "@/constants/charts";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import type { WidgetConfig, WidgetData } from "@/_store/dashboard/dashboard.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import kebabCase from "lodash/kebabCase";
import { useWidgetContent } from "@/composables/useWidgetContent";
import { CoroIcons } from "@/constants/coro-icon";
import { RouteName } from "@/constants/routes";

interface DeviceWidgetData extends WidgetData {
  devicesWithIssues: number;
  devicesWithoutIssues: number;
}

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<DeviceWidgetData>,
      required: true,
    },
    widgetConfig: {
      type: Object as PropType<WidgetConfig>,
      required: true,
    },
  },
  components: {
    VulnerabilitiesTile,
    DoughnutChart,
  },
  setup(props) {
    const router = useRouter();
    const i18n = useI18n();
    const { viewVulnerability, viewAllTickets, isAccessRestricted } = useWidgetContent();
    const chartData = computed(() => {
      const { devicesWithIssues, devicesWithoutIssues } = props.widgetData;
      return {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [
          i18n.t("dashboard.widgets.devices.chartLabel", devicesWithoutIssues),
          i18n.t("dashboard.widgets.devices.chartLabel", devicesWithIssues),
        ],
        datasets: [
          {
            label: "",
            backgroundColor: [DoughnutChartColors.SUCCESS, DoughnutChartColors.ERROR],
            hoverBackgroundColor: [DoughnutChartColors.SUCCESS, DoughnutChartColors.ERROR],
            data: [devicesWithoutIssues, devicesWithIssues],
          },
        ],
      };
    });

    const emptyDataIcon = computed(() => {
      if (props?.widgetConfig?.widgetType === WidgetType.ENDPOINT_DATA_GOVERNANCE) {
        return CoroIcons.ENDPOINT_DATA_GOVERNANCE;
      }
      return CoroIcons.ENDPOINT_SECURITY;
    });

    const widgetFilter = computed(() => {
      const widgetFilterMap: any = {
        [WidgetType.ENDPOINT_SECURITY]: TicketsModuleFilter.DEVICE_WIDGET,
        [WidgetType.ENDPOINT_DATA_GOVERNANCE]: TicketsModuleFilter.ENDPOINT_DATA_GOVERNANCE,
      };
      return widgetFilterMap[props?.widgetConfig.widgetType];
    });

    const onChartClick = (index: number) => {
      if (isAccessRestricted(RolePermissionsScope.VIEWS, "devicesView")) return;
      const query: { [key: string]: string } = {
        widget: widgetFilter.value,
      };
      query.hasVulnerabilities = (index === 1).toString();

      router.push({
        name: "devices",
        query,
      });
    };

    return {
      ticketsWidgetFilters: TicketsModuleFilter,
      viewVulnerability,
      viewAllTickets,
      isAccessRestricted,
      rolePermissionsScope: RolePermissionsScope,
      workspaceManagementScope: WorkspaceManagementScopeSections,
      chartData,
      emptyDataIcon,
      widgetFilter,
      onChartClick,
      kebabCase,
      RouteName,
    };
  },
});
</script>
