<template>
  <div>
    <div class="mb-8 body1">{{ $t("modals.startDlpPartialScan.description") }}</div>
    <v-checkbox v-model="localValue.partialScan">
      <template #label>
        <div class="body1">{{ $t("modals.startDlpPartialScan.partialScan") }}</div>
      </template>
    </v-checkbox>
  </div>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import { defineComponent, type PropType, ref, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  emits: ["update:localValue"],
  setup(props, { emit }) {
    const localValue = ref(cloneDeep(props.config.item));
    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", {
          action: newVal.action,
          item: newVal,
        });
      },
      { deep: true, immediate: true }
    );
    return {
      localValue,
    };
  },
});
</script>
