export enum WidgetType {
  CLOUD_SECURITY = "cloudSecurity",
  ENDPOINT_SECURITY = "endpointSecurity",
  EMAIL_SECURITY = "emailSecurity",
  USER_DATA_GOVERNANCE = "userDataGovernance",
  ENDPOINT_DATA_GOVERNANCE = "endpointDataGovernance",
  EDR = "edr",
}

export enum WidgetAction {
  VIEW_ALL = "viewAll",
  VIEW_CRITICAL_DEVICE_VULNERABILITIES = "viewCriticalDeviceVulnerablilities",
  VIEW_NON_CRITICAL_DEVICE_VULNERABILITIES = "viewNonCriticalDeviceVulnerablilities",
}

export enum OverviewWidgetType {
  CLOUD_SECURITY = "cloudSecurity",
  ENDPOINT_SECURITY = "endpointSecurity",
  EMAIL_SECURITY = "emailSecurity",
  USER_DATA_GOVERNANCE = "userDataGovernance",
  ENDPOINT_DATA_GOVERNANCE = "endpointDataGovernance",
  EDR = "edr",
  NETWORK = "network",
  MOBILE_DEVICE_MANAGEMENT = "mdm",
}

export enum AddonOverviewWidgetType {
  INBOUND_GATEWAY = "inboundGateway",
  WIFI_PHISHING = "wifiPhishing",
  SECURE_WEB_GATEWAY = "secureWebGateway",
  SECURED_MESSAGES = "securedMessages",
}

export enum OverviewWidgetAppearance {
  NUMBER = "number",
  SETTINGS = "settings",
  BLANK = "blank",
}

export enum OverviewWidgetSize {
  LARGE = "large",
  SMALL = "small",
}
