<template>
  <v-menu offset-y bottom right z-index="100">
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        class="add-btn"
        color="primary"
        rounded
        size="large"
        data-testid="data-governance-add-permission-btn"
      >
        <v-icon color="white" class="mr-2" icon="$add"></v-icon>
        {{ $t("userDataGovernanceSettings.addPermission") }}
      </v-btn>
    </template>
    <v-list>
      <template v-for="action in actionsList" :key="action">
        <v-list-item
          :data-testid="`data-governance-add-permission-menu-item-${action}`"
          @click="onActionClick(action)"
        >
          <v-list-item-title>
            {{ $t(`userDataGovernanceSettings.permissionsTab.addPermissionOptions.${action}`) }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SubscriptionModule } from "@/constants/workplaces";
import { DataLossAction } from "@/constants/user-data-governance";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import AddPermissionModal from "@/components/modals/AddPermissionModal.vue";
import { useUserDataGovernanceModule } from "@/_store/user-data-governance/user-data-governance.module";
import type { PermissionsFormData } from "@/_store/user-data-governance/adapter";

export default defineComponent({
  setup() {
    const dialogsStore = useDialogsStore();
    const userDataGovernanceStore = useUserDataGovernanceModule();

    const onActionClick = (action: DataLossAction) => {
      const dialogConfig = componentDialogsConfigConstructor({
        action,
        component: AddPermissionModal,
        width: ModalWidth.LARGE,
        callback: async (val: PermissionsFormData) => {
          await userDataGovernanceStore.addDataLossPermission(val);
        },
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.PROTECTION,
          SubscriptionModule.USER_DATA_GOVERNANCE
        ),
      });

      dialogsStore.openDialog(dialogConfig);
    };
    return {
      actionsList: [
        DataLossAction.ADD_PERMISSION_FOR_ALL_USERS,
        DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_GROUPS,
        DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_USERS,
      ],
      onActionClick,
    };
  },
});
</script>
