import { computed } from "vue";
import type { Ref } from "vue";
import type { GenericCallback, Pagination } from "@/types";

export function usePageableTable<T = any>(
  props: {
    items: Ref<T[]>;
    pagination: Ref<Pagination>;
    totalItems: Ref<number>;
  },
  paginationChangeCallback: GenericCallback
) {
  const page = computed(() => {
    return props.pagination.value.page + 1;
  });

  const totalPages = computed(() => {
    return Math.ceil(props.totalItems.value / props.pagination.value.pageSize);
  });

  const showPagination = computed(() => {
    return props.totalItems.value > props.pagination.value.pageSize;
  });

  const handlePaginationChange = (page: number) => {
    paginationChangeCallback({
      page: page - 1,
      pageSize: props.pagination.value.pageSize,
    });
  };

  const handleListTablePaginationChange = (pagination: Pagination) => {
    paginationChangeCallback({
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
  };

  return {
    totalPages,
    handlePaginationChange,
    handleListTablePaginationChange,
    showPagination,
    page,
  };
}
