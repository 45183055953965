<template>
  <div>
    <div class="body1" v-html="$t(`service.${config.item.name}.notice1`)"></div>
    <v-btn
      class="mt-5 mb-5"
      rounded
      color="primary"
      width="120"
      :loading="isLoading"
      @click="connect({ service: config.item.name as Service })"
    >
      {{ $t("cloudApplications.buttons.connect") }}
    </v-btn>
    <div v-if="errorCode && !isSlackAccessDenied" class="error-message mt-2">
      {{ $t(`service.errors.${errorCode}`, { email }) }}
    </div>
    <div class="caption">
      {{ $t(`service.${config.item.name}.notice2`) }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { OfficeErrorCode, Service } from "@/constants/cloud-apps";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  setup(props) {
    const cloudAppsModule = useCloudAppsStore();
    const { isLoading } = storeToRefs(cloudAppsModule);
    const route = useRoute();

    const errorCode = route.query.error;

    const isAccessDenied = computed(() => {
      return errorCode === OfficeErrorCode.ACCESS_DENIED;
    });

    const isSlackAccessDenied = computed(() => {
      return props.config.item.name === Service.SLACK && isAccessDenied.value;
    });

    return {
      isLoading,
      Service,
      connect: cloudAppsModule.connect,
      isSlackAccessDenied,
      errorCode,
      email: route.query.email,
    };
  },
});
</script>
