import { createI18n } from "vue-i18n";
import { WorkspaceLocale } from "@/constants/workplaces";
import { en } from "@/translations/en";
import { es } from "@/translations/es";

export const i18n = createI18n<false>({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: WorkspaceLocale.EN,
  fallbackLocale: WorkspaceLocale.EN,
  messages: {
    [WorkspaceLocale.EN]: en,
    [WorkspaceLocale.ES]: es,
  },
});
