<template>
  <v-card class="h-100">
    <v-card-text class="px-8">
      <v-form ref="form" v-model="valid">
        <v-switch
          v-model="localValue.dnsFilteringEnabled"
          class="v-input--reverse"
          color="primary lighten-1"
          hide-details
          :disabled="loading || actionNotAllowed"
          :ripple="false"
          @click.prevent.stop.capture="onChangeDnsEnabledToggle"
        >
          <template #label>
            <span
              class="subtitle1 text--black mr-2"
              v-text="$t('network.secureWebGateway.dnsFilteringLabel')"
            />
          </template>
        </v-switch>
        <span class="body2" v-text="$t('network.secureWebGateway.dnsFilteringDescription')" />
        <v-divider class="my-6" />
        <v-checkbox
          v-model="localValue.dnsAnonymousModeEnabled"
          :disabled="isFieldDisabled || loading"
          :ripple="false"
          :label="$t('network.secureWebGateway.dnsResolverLabel')"
          class="mt-3 mb-1"
        />
        <span
          class="body2 ml-7"
          :class="{
            'text-indigo-medium':
              !localValue.dnsFilteringEnabled || !localValue.dnsAnonymousModeEnabled,
          }"
          v-text="$t('network.secureWebGateway.dnsResolverDescription')"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from "vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SecureWebGatewayAction } from "@/constants/network";
import { useI18n } from "vue-i18n";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionAddon } from "@/constants/workplaces";

export default defineComponent({
  props: {
    settings: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const form = ref();
    const valid = ref(true);
    const localValue = ref({ ...props.settings });
    const dialogsStore = useDialogsStore();

    const { openDialog } = dialogsStore;
    const { t } = useI18n();

    const actionNotAllowed = computed(() =>
      isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SWG)
    );

    const onChangeDnsEnabledToggle = (): void => {
      if (!localValue.value.dnsFilteringEnabled) {
        const modalConfig = {
          ...confirmationDialogsConfigConstructor({
            item: {},
            action: SecureWebGatewayAction.TOGGLE_DNS_FILTERING,
            callback: () => {
              localValue.value.dnsFilteringEnabled = !localValue.value.dnsFilteringEnabled;
            },
          }),
          header: {
            title: t(`modals.${SecureWebGatewayAction.TOGGLE_DNS_FILTERING}.title`),
            titleClass: "text-red-dark",
            titleIcon: "warning",
          },
        };
        openDialog(modalConfig);
      } else {
        localValue.value.dnsFilteringEnabled = !localValue.value.dnsFilteringEnabled;
      }
    };

    const isFieldDisabled = computed(
      () => actionNotAllowed.value || !localValue.value.dnsFilteringEnabled
    );

    watch(
      () => props.settings,
      (newVal) => {
        localValue.value = { ...newVal };
      },
      { immediate: true, deep: true }
    );
    watch(
      localValue,
      debounce((newVal) => {
        if (
          (newVal.dnsFilteringEnabled === false || form.value?.validate()) &&
          !isEqual(newVal, props.settings)
        ) {
          emit("settings-updated", newVal);
        }
      }, 500),
      { deep: true }
    );

    return {
      form,
      valid,
      localValue,
      actionNotAllowed,
      onChangeDnsEnabledToggle,
      isFieldDisabled,
    };
  },
});
</script>

<style scoped lang="scss">
.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}

.label-popup-block span {
  flex: unset !important;
}

.disabled-text {
  color: rgb(var(--v-theme-indigo-medium));
}

:deep(*) {
  .v-input--checkbox .v-input__slot {
    margin-bottom: 0 !important;
  }

  .v-input--reverse .v-input__slot {
    .v-label {
      order: -1;
      flex-grow: 0 !important;
    }
  }
}
</style>
