<template>
  <v-form v-model="valid" ref="form" class="dialog-content">
    <div v-if="valid === false" class="error-block d-flex mb-2 align-center w-100">
      <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
      <span class="body2 text-red-dark"> {{ $t("validations.bundleOrModuleIsRequired") }}</span>
    </div>
    <!--Faking validation for bundles and modules-->
    <v-select
      :items="[SubscriptionBundle.CORO_CLASSIC]"
      v-model="localValue.item.bundles"
      multiple
      :rules="[bundleValidationRule()]"
      class="d-none"
      :validation-value="moduleOrBundleValidationValue"
    >
    </v-select>
    <v-tabs
      v-model="selectedTab"
      align-with-title
      class="edit-subscription-tabs"
      color="orange-base"
      background-color="white"
      slider-color="orange-base"
      :height="48"
    >
      <v-tab v-for="item in tabs" :key="item" class="mr-8" :height="42">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-window v-model="selectedTab">
      <v-window-item v-for="item in tabs" :key="item">
        <div v-if="selectedTab === 1" class="mt-4">
          <div class="subtitle1 mb-4">
            {{ $t("modals.createWorkspace.recommendedModules") }}
          </div>
          <div class="d-flex flex-wrap row-gap mb-10">
            <template v-for="(module, moduleName) in localValue.item.modules" :key="moduleName">
              <module-or-addon-selector
                v-model="module.enabled"
                :name="moduleName"
                v-if="!moduleIncludedInBundle(moduleName)"
              >
                <template #content:after>
                  <div class="mt-5">
                    <template v-for="addon in module.addons" :key="addon.name">
                      <v-btn-toggle
                        v-if="isSocAddon(addon.name)"
                        v-model="addon.enabled"
                        color="indigo-pale"
                        group
                        mandatory
                        density="comfortable"
                        rounded="xl"
                      >
                        <v-btn :value="false">
                          {{ $t("moduleOrAddonSelector.unmanaged") }}
                        </v-btn>
                        <v-btn :value="true">
                          {{ $t("moduleOrAddonSelector.managed") }}
                        </v-btn>
                      </v-btn-toggle>
                    </template>
                  </div>
                </template>
              </module-or-addon-selector>
            </template>
          </div>

          <template v-if="showAddonsSelection">
            <v-divider class="mt-8"></v-divider>
            <div class="subtitle1 mb-4 mt-4">
              {{ $t("modals.createWorkspace.addons") }}
            </div>

            <div
              class="d-flex flex-wrap row-gap"
              :class="{ 'mb-10': !hasAtLeastOneModuleIncludedInBundle }"
            >
              <template v-for="(module, moduleName) in localValue.item.modules" :key="moduleName">
                <template v-for="addon in module.addons" :key="addon.name">
                  <module-or-addon-selector
                    :is-addon="true"
                    v-model="addon.enabled"
                    :name="addon.name"
                    :disabled="!module.enabled"
                    v-if="!isSocAddon(addon.name) && !addonIncludedInBundle(addon.name)"
                  >
                    <template #content:after>
                      <div
                        class="info-block info-block--large align-center mt-5 d-flex"
                        v-if="!module.enabled"
                      >
                        <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
                        <span
                          class="body2 text-primary mr-2"
                          v-html="
                            $t(`moduleOrAddonSelector.addonDisabled`, {
                              module: $t(`subscriptions.modules.${moduleName}`),
                            })
                          "
                        ></span>
                      </div>
                    </template>
                  </module-or-addon-selector>
                </template>
              </template>
            </div>
          </template>

          <template v-if="hasAtLeastOneModuleIncludedInBundle">
            <v-divider class="mt-8"></v-divider>
            <div class="subtitle1 mb-4 mt-4">
              {{ $t("modals.createWorkspace.includedInBundles") }}
            </div>
            <div class="d-flex flex-wrap row-gap mb-10">
              <template v-for="moduleName in getSelectedModules(localValue.item)" :key="moduleName">
                <module-or-addon-selector
                  :model-value="false"
                  :name="moduleName"
                  v-if="moduleIncludedInBundle(moduleName)"
                >
                  <template #content:after>
                    <div
                      class="info-block included-in-bundle-block mt-5"
                      v-if="moduleIncludedInBundle(moduleName)"
                    >
                      <span class="text-primary mr-1">
                        {{ $t("modals.createWorkspace.includedInBundle") }}
                      </span>
                      <v-icon size="18" icon="$warning"></v-icon>
                    </div>
                  </template>
                  <template #footer>
                    <div class="w-100">
                      <v-divider class="mt-3 mb-3"></v-divider>
                      <div class="d-flex align-center justify-center">
                        <v-icon class="mr-2" icon="$check"></v-icon>
                        <span class="text-uppercase text--semibold body2">
                          {{ $t("general.added") }}
                        </span>
                      </div>
                    </div>
                  </template>
                </module-or-addon-selector>
              </template>
              <template v-for="addon in getSelectedAddons()" :key="addon">
                <module-or-addon-selector
                  :model-value="false"
                  :is-addon="true"
                  :name="addon"
                  v-if="addonIncludedInBundle(addon) && !isSocAddon(addon)"
                >
                  <template #content:after>
                    <div class="info-block included-in-bundle-block mt-5">
                      <span class="text-primary mr-1">
                        {{ $t("modals.createWorkspace.includedInBundle") }}
                      </span>
                      <v-icon size="18" icon="$warning"></v-icon>
                    </div>
                  </template>
                  <template #footer>
                    <div class="w-100">
                      <v-divider class="mt-3 mb-3"></v-divider>
                      <div class="d-flex align-center justify-center">
                        <v-icon class="mr-2" icon="$check"></v-icon>
                        <span class="text-uppercase text--semibold body2">
                          {{ $t("general.added") }}
                        </span>
                      </div>
                    </div>
                  </template>
                </module-or-addon-selector>
              </template>
              <!--Displaying only one SOC addon -->
              <module-or-addon-selector
                :is-addon="true"
                :name="SubscriptionAddon.SOC"
                :model-value="false"
                v-if="hasAtLeastOneSocAddon"
              >
                <template #content:after>
                  <div class="info-block included-in-bundle-block mt-5">
                    <span class="text-primary mr-1">
                      {{ $t("modals.createWorkspace.includedInBundle") }}
                    </span>
                    <v-icon size="18" icon="$warning"></v-icon>
                  </div>
                </template>
                <template #footer>
                  <div class="w-100">
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <div class="d-flex align-center justify-center">
                      <v-icon class="mr-2" icon="$check"></v-icon>
                      <span class="text-uppercase text--semibold body2">
                        {{ $t("general.added") }}
                      </span>
                    </div>
                  </div>
                </template>
              </module-or-addon-selector>
            </div>
          </template>
        </div>
        <template v-else>
          <div class="d-flex mt-4 row-gap mb-10">
            <range-bundle-selector
              v-model="coroEssentialsBundle"
              :managed-option="SubscriptionBundle.MANAGED_CORO_ESSENTIALS"
              :unmanaged-option="SubscriptionBundle.CORO_ESSENTIALS"
            >
            </range-bundle-selector>
            <range-bundle-selector
              v-model="endpointBundle"
              :managed-option="SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION"
              :unmanaged-option="SubscriptionBundle.ENDPOINT_PROTECTION"
            >
            </range-bundle-selector>
            <range-bundle-selector
              v-model="emailBundle"
              :managed-option="SubscriptionBundle.MANAGED_EMAIL_PROTECTION"
              :unmanaged-option="SubscriptionBundle.EMAIL_PROTECTION"
            >
            </range-bundle-selector>
            <range-bundle-selector
              v-model="accessBundle"
              :managed-option="SubscriptionBundle.MANAGED_SASE"
              :unmanaged-option="SubscriptionBundle.SASE"
            >
            </range-bundle-selector>
          </div>
          <div class="d-flex mt-4 row-gap mb-10">
            <range-bundle-selector
              v-model="coroClassicBundle"
              :managed-option="SubscriptionBundle.MANAGED_CORO_CLASSIC"
              :unmanaged-option="SubscriptionBundle.CORO_CLASSIC"
            >
            </range-bundle-selector>
            <range-bundle-selector
              v-model="coroCompleteBundle"
              :managed-option="SubscriptionBundle.MANAGED_CORO_COMPLETE"
              :unmanaged-option="SubscriptionBundle.CORO_COMPLETE"
            >
            </range-bundle-selector>
          </div>
        </template>
      </v-window-item>
    </v-window>
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, toRef, watch } from "vue";
import RangeBundleSelector from "@/components/subscription-selectors/RangeBundleSelector.vue";
import ModuleOrAddonSelector from "@/components/subscription-selectors/ModuleOrAddonSelector.vue";
import {
  accessBundles,
  coroClassicBundles,
  coroCompleteBundles,
  coroEssentialsBundles,
  emailBundles,
  endpointBundles,
  useSubscriptionEditor,
} from "@/composables/useSubscriptionEditor";
import type { Subscription } from "@/_store/msp.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import cloneDeep from "lodash/cloneDeep";
import {
  defaultSubscriptionModulesConfig,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
} from "@/constants/workplaces";
import type {
  ModulesSettings,
  SubscriptionAddonInfo,
  SubscriptionModuleInfo,
} from "@/_store/subscription.module";
import { useI18n } from "vue-i18n";
import type { VuetifyFormRef } from "@/types";
import difference from "lodash/differenceBy";
import intersection from "lodash/intersection";
import get from "lodash/get";
import unionBy from "lodash/unionBy";
import sortBy from "lodash/sortBy";

export default defineComponent({
  components: { ModuleOrAddonSelector, RangeBundleSelector },
  props: {
    config: {
      type: Object as PropType<
        DialogDataConfig<Subscription & { modulesWhichRemovedDuringEdit?: SubscriptionModule[] }>
      >,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const selectedTab = ref(0);
    const valid = ref(true);
    const form = ref<VuetifyFormRef>();
    const localValue = ref<
      DialogDataConfig<Subscription & { modulesWhichRemovedDuringEdit?: SubscriptionModule[] }>
    >(
      cloneDeep({
        ...props.config,
        item: {
          bundles: props.config.item.bundles ?? [],
          modules: setDefaultModules(),
        },
      })
    );
    const {
      accessBundle,
      endpointBundle,
      emailBundle,
      coroEssentialsBundle,
      coroClassicBundle,
      coroCompleteBundle,
      isBundleSelected,
      moduleIncludedInBundle,
      addonIncludedInBundle,
      getSelectedModules,
      getSelectedAddons,
      isSocAddon,
      hasAtLeastOneSocAddon,
      hasAtLeastOneModuleIncludedInBundle,
    } = useSubscriptionEditor(toRef(localValue.value.item), (newData: Subscription) => {
      localValue.value.item = newData;
    });

    const moduleOrBundleValidationValue = computed(() => {
      const isModuleEnabled = Object.values(localValue.value.item.modules).some(
        (module: SubscriptionModuleInfo) => module.enabled
      );
      const isBundleSelected = localValue.value.item.bundles.length > 0;
      return isBundleSelected || isModuleEnabled;
    });

    const showAddonsSelection = computed(() => {
      const modulesArray = Object.values(localValue.value.item.modules);
      return modulesArray.some((module: SubscriptionModuleInfo) => {
        return module.addons.some((addon: SubscriptionAddonInfo) => {
          return !isSocAddon(addon.name) && !addonIncludedInBundle(addon.name);
        });
      });
    });

    const modulesWhichRemovedDuringEdit = computed(() => {
      const oldModules = getSelectedModules(props.config.item);
      const newModules = getSelectedModules(localValue.value.item);
      const modulesWithoutTickets = [
        SubscriptionModule.NETWORK,
        SubscriptionModule.EDR,
        SubscriptionModule.MDM,
      ];
      return difference(oldModules, newModules).filter((module) => {
        return !modulesWithoutTickets.includes(module);
      });
    });

    const bundleValidationRule = (
      errorMessage: string = i18n.t("validations.bundleOrModuleIsRequired")
    ) => {
      return (value: boolean) => {
        if (!value) return errorMessage;
        return true;
      };
    };

    const validate = async () => {
      const validationResult = await form.value?.validate();
      return validationResult?.valid;
    };

    watch(
      localValue,
      (value) => {
        const newValue = cloneDeep(value);
        newValue.item.modulesWhichRemovedDuringEdit = modulesWhichRemovedDuringEdit.value;
        emit("update:localValue", newValue);
      },
      { deep: true }
    );

    watch(
      valid,
      (newVal: boolean | null) => {
        emit("update:valid", newVal ?? true);
      },
      { immediate: false }
    );

    watch(
      props.config.item.bundles,
      (bundles) => {
        accessBundle.value = intersection(bundles, accessBundles)[0];
        emailBundle.value = intersection(bundles, emailBundles)[0];
        endpointBundle.value = intersection(bundles, endpointBundles)[0];
        coroEssentialsBundle.value = intersection(bundles, coroEssentialsBundles)[0];
        coroClassicBundle.value = intersection(bundles, coroClassicBundles)[0];
        coroCompleteBundle.value = intersection(bundles, coroCompleteBundles)[0];
      },
      { deep: true, immediate: true }
    );

    /**
     * Merges the default subscription modules configuration with the provided module settings.
     *
     * This function ensures that enabled states are converted to booleans and adds unique addons
     * from both the new and default configurations, sorted by name.
     *
     * Note: lodash's defaultsDeep function behaves such that if a value exists in both
     * arrays, it will add the value again instead of maintaining uniqueness.
     *
     * @returns {ModulesSettings} Merged modules settings with defaults applied.
     */
    function setDefaultModules(): ModulesSettings {
      const defaultModules = cloneDeep(defaultSubscriptionModulesConfig);
      const newModules = props.config.item.modules;
      const modules: ModulesSettings = {} as ModulesSettings;

      Object.keys(defaultModules).forEach((key) => {
        const module = key as SubscriptionModule;
        const currentAddons: SubscriptionAddonInfo[] = get(newModules[module], "addons", []);
        const defaultAddons: SubscriptionAddonInfo[] = defaultModules[module].addons;

        // Use unionBy to merge the addons uniquely by their name
        modules[module] = {
          enabled: Boolean(newModules[module]?.enabled),
          addons: sortBy(unionBy(currentAddons, defaultAddons, "name"), ["name"]),
        };
      });

      return modules;
    }

    return {
      tabs: [i18n.t("subscriptions.bundles.title"), i18n.t("subscriptions.modules.title")],
      form,
      localValue,
      selectedTab,
      valid,
      accessBundle,
      endpointBundle,
      emailBundle,
      coroEssentialsBundle,
      coroClassicBundle,
      coroCompleteBundle,
      isBundleSelected,
      moduleIncludedInBundle,
      addonIncludedInBundle,
      getSelectedModules,
      getSelectedAddons,
      isSocAddon,
      showAddonsSelection,
      hasAtLeastOneSocAddon,
      hasAtLeastOneModuleIncludedInBundle,
      SubscriptionBundle,
      SubscriptionAddon,
      moduleOrBundleValidationValue,
      bundleValidationRule,
      validate,
    };
  },
});
</script>

<style scoped lang="scss">
.dialog-content {
  min-width: 650px;
  max-width: 1150px;
  width: 100%;
  margin-right: 30px;
  margin-left: 30px;
}

.scroll-area {
  height: calc(100vh - 160px);
  overflow: auto;
}

.v-switch {
  .v-label {
    opacity: 1 !important;
  }
}

.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.notification-label {
  width: 500px;
}

.row-gap {
  gap: 23px;
}

:deep(*) {
  .v-btn-group--density-default.v-btn-group {
    height: unset !important;
  }

  .bundle-selector {
    background-color: white;
  }

  .v-btn-group {
    border: 1px solid rgb(var(--v-theme-primary)) !important;
    padding: 4px;
    width: 100%;
    .v-btn__overlay,
    .v-btn--active,
    .v-btn__underlay {
      border-radius: 30px;
    }
    .v-btn {
      width: 50%;
      .v-btn__content {
        text-transform: none !important;
        color: rgb(var(--v-theme-primary)) !important;
        letter-spacing: 0 !important;
      }
    }
  }
}

.info-block--large {
  min-height: 68px;
}

.included-in-bundle-block {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}
</style>
