<template>
  <div v-if="usersWithoutPassword?.length">
    <h6 class="headline6 mb-4">{{ $t("ticketDetails.fullDetails.title") }}</h6>
    <div class="full-details-wrapper mb-10">
      <div class="d-flex align-center px-4 pt-4">
        <div class="subtitle1 mb-4" data-testid="tickets-page-device-password-title">
          {{ $t("ticketDetails.fullDetails.devicePasswordMissing.title") }}
        </div>
      </div>
      <v-divider></v-divider>
      <div class="py-4">
        <div v-for="(user, index) of usersWithoutPassword" :key="index">
          <div class="px-4 d-flex align-center">
            <div
              class="inner-circle red-circle mr-2"
              :data-testid="`tickets-page-device-password-circle-row-${index}`"
            />
            <div
              :data-testid="`tickets-page-device-password-entry-row-${index}`"
              v-html="
                $t(`ticketDetails.fullDetails.devicePasswordMissing.entry`, {
                  userIdentifier: getUserIdentifier(user),
                })
              "
            ></div>
          </div>
          <v-divider v-if="index !== usersWithoutPassword.length - 1" class="my-4"></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { DeviceUserMetaData, TicketDetails } from "@/_store/tickets/tickets.module";

export default defineComponent({
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const usersWithoutPassword = computed(
      () => props.ticket.sections?.deviceMetaData?.usersWithoutPassword ?? []
    );

    const getUserIdentifier = (user: DeviceUserMetaData) => {
      // all this logic exists because of priority in showing properties in defined order
      // login => upn => userName
      // if something is missing, order should be same
      const fields = [user.login, user.upn, user.userName].filter(Boolean);
      if (fields.length === 1) {
        return fields[0];
      }
      if (fields.length === 2) {
        return fields[0].concat(` (${fields[1]})`);
      }
      return user.login.concat(` (${user.upn}, ${user.userName})`);
    };

    return {
      usersWithoutPassword,
      getUserIdentifier,
    };
  },
});
</script>

<style lang="scss" scoped>
.red-circle {
  background-color: rgb(var(--v-theme-red-dark));
  border-color: rgb(var(--v-theme-red-dark));
}
.full-details-wrapper {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>
