<template>
  <div>
    <div class="mb-8 coro-body1" v-html="localValue?.item.text"></div>
    <v-checkbox v-if="localValue.item.showCheckbox" v-model="localValue.item.checkboxValue">
      <template #label>
        <div class="coro-body1">{{ checkboxText }}</div>
      </template>
    </v-checkbox>
  </div>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  emits: ["update:localValue"],
  setup(props, { emit }) {
    const localValue = ref({
      item: {
        checkboxText: "",
        checkboxValue: false,
        text: "",
        showCheckbox: false,
      },
    } as DialogDataConfig);
    const checkboxText = computed(() => props?.config?.item.checkboxText);

    watch(
      localValue.value,
      (newVal: DialogDataConfig) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    onMounted(() => {
      localValue.value = cloneDeep(props.config);
      emit("update:localValue", localValue);
    });

    return {
      checkboxText,
      localValue,
    };
  },
});
</script>

<style lang="scss" scoped></style>
