<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <div class="subtitle2 mb-4">
      {{ $t("modals.addSecurityAwarenessConfiguration.sectionsTitles.details") }}
    </div>
    <v-text-field
      v-model="localValue.name"
      :rules="[required()]"
      :label="$t('modals.addSecurityAwarenessConfiguration.labels.name')"
    />
    <div class="subtitle2 mb-4">
      {{ $t("modals.addSecurityAwarenessConfiguration.sectionsTitles.emailAndDomain") }}
    </div>

    <v-combobox
      ref="combobox"
      v-model="localValue.terms"
      v-model:search="currentInputValue"
      class="mt-4 mb-4 coro-scrollable-combobox"
      :label="$t('modals.addSecurityAwarenessConfiguration.labels.emailAndDomain')"
      :placeholder="$t('modals.allowBlockListSwg.urlPlaceholder')"
      hide-details="auto"
      variant="outlined"
      multiple
      chips
      no-filter
      hide-no-data
      @blur="handleBlur"
    >
      <template #chip="{ props, item }">
        <v-chip
          v-bind="props"
          variant="flat"
          color="indigo-faint"
          closable
          close-icon="$closeCircle"
        >
          <div class="d-flex align-center justify-space-between">
            <span class="ml-1 mr-2" v-text="item.raw" />
          </div>
        </v-chip>
      </template>
    </v-combobox>
    <div class="subtitle2 mb-4">
      {{ $t("modals.addSecurityAwarenessConfiguration.sectionsTitles.emailHeader") }}
    </div>
    <key-value-input
      class="mt-10 mb-4"
      v-model:value="localValue.headers"
      :key-label="$t('modals.addSecurityAwarenessConfiguration.labels.name')"
      :value-label="$t('modals.addSecurityAwarenessConfiguration.labels.value')"
      :show-add-btn="false"
      :key-config="{
        cols: 6,
        field: $t('modals.addSecurityAwarenessConfiguration.labels.name').toLowerCase(),
        rules: [required()],
        md: 6,
      }"
      :value-config="{
        cols: 6,
        md: 6,
        rules: [required()],
      }"
    >
    </key-value-input>
    <span class="coro-link" @click="onAddNewHeader">{{
      $t("modals.addSecurityAwarenessConfiguration.addHeaderBtn")
    }}</span>
  </v-form>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { hasSeparator } from "@/_helpers/utils";
import { required } from "@/_helpers/validators";
import KeyValueInput from "@/components/KeyValueInput.vue";

export default defineComponent({
  components: { KeyValueInput },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const currentInputValue = ref("");
    const valid = ref(false);
    const localValue = ref({
      name: "",
      terms: [] as never[],
      headers: [
        {
          name: "",
          value: "",
        },
      ],
    });
    const handleBlur = () => {
      if (!currentInputValue.value) return;
      const hasCommaSeparator = hasSeparator(currentInputValue.value);
      if (hasCommaSeparator) {
        currentInputValue.value = "";
      }
    };

    const onAddNewHeader = () => {
      localValue.value.headers.push({
        name: "",
        value: "",
      });
    };
    watch(
      localValue,
      (val) => {
        localValue.value = val;
        emit("update:localValue", localValue.value);
      },
      { deep: true, immediate: true }
    );
    watch(
      valid,
      (newVal: boolean) => {
        emit("update:valid", newVal);
      },
      { immediate: true }
    );
    return {
      onAddNewHeader,
      handleBlur,
      required,
      localValue,
      valid,
      currentInputValue,
    };
  },
});
</script>
