<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlitable mb-10"
      :items="items"
      :headers="headers"
      :height="tableHeight"
      :loading="loading"
      :fixed-header="true"
      :show-select="false"
      :items-per-page="pagination.pageSize"
      :items-length="totalItems"
      item-value="id"
      @update:options="handlePageChange"
    >
      <template #item="{ item, index }">
        <tr
          :id="`${index + 1}`"
          :key="index"
          :row-disabled="isRowDisabled(item.disabled)"
          v-intersect="handleVirtualScroll"
        >
          <td class="pl-2 pr-0">
            <v-icon
              :class="{ 'text-indigo-medium': item.disabled }"
              :size="43"
              :icon="iconToTypeMap(item.category)"
            />
            <span
              class="body2 text--semibold"
              :class="{ 'text-indigo-medium': item.disabled }"
              v-text="item.name"
            />
          </td>
          <td>
            <span
              v-if="item.allowed"
              class="body2"
              :class="{
                'text-green-dark': !item.disabled,
                'text-indigo-medium': item.disabled,
              }"
              v-text="$t('network.secureWebGateway.table.allowed')"
            />
            <span
              v-else
              class="body2"
              :class="{
                'text-red-dark': !item.disabled,
                'text-indigo-medium': item.disabled,
              }"
              v-text="$t('network.secureWebGateway.table.blocked')"
            />
          </td>
          <td class="d-flex justify-end align-center">
            <v-icon
              v-if="item.category === SecureWebGatewayCategories.URL"
              :disabled="loading"
              icon="$trashOutlined"
              :class="{ 'text-indigo-medium': item.disabled }"
              @click="
                handleItemAction(
                  item.allowed
                    ? SecureWebGatewayAction.REMOVE_FROM_ALLOWLIST
                    : SecureWebGatewayAction.REMOVE_FROM_BLOCKLIST,
                  item
                )
              "
            />
            <v-menu v-else :disabled="loading">
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="$dots" />
              </template>
              <v-list>
                <template v-for="(action, index) in getActionsList(item)" :key="index">
                  <v-list-item @click="handleItemAction(action, item)">
                    <span v-text="$t(`network.secureWebGateway.actions.${action}`)" />
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { SecureWebGatewayAction, SecureWebGatewayCategories } from "@/constants/network";
import type { Pagination } from "@/types";
import type { SecureWebGatewayUrlItem } from "@/_store/secure-web-gateway.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: { type: Array as PropType<SecureWebGatewayUrlItem[]>, required: true },
    loading: { type: Boolean, default: false },
    totalItems: { type: Number, required: true },
    pagination: { type: Object as PropType<Pagination>, required: true },
  },
  emits: ["last-item-reached", "page-change", "item-action-applied"],

  setup(props, { emit }) {
    const { t } = useI18n();

    const handleItemAction = (
      action: SecureWebGatewayAction,
      item: SecureWebGatewayUrlItem
    ): void => {
      emit("item-action-applied", { action, item });
    };

    const handlePageChange = (payload: Pagination): void => {
      emit("page-change", payload);
    };

    const handleVirtualScroll = (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === props.items?.length - 1) {
        if (intersectedItemId === props.totalItems - 1) {
          return;
        }
        emit("last-item-reached");
      }
    };

    const getActionsList = (item: SecureWebGatewayUrlItem) => {
      const enableAction = item.allowed
        ? SecureWebGatewayAction.ENABLE_URL_GROUP_ALLOW_LIST
        : SecureWebGatewayAction.ENABLE_URL_GROUP_BLOCK_LIST;

      const disableAction = item.allowed
        ? SecureWebGatewayAction.DISABLE_URL_GROUP_ALLOW_LIST
        : SecureWebGatewayAction.DISABLE_URL_GROUP_BLOCK_LIST;

      const removeAction = item.allowed
        ? SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_ALLOW_LIST
        : SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_BLOCK_LIST;

      switch (item.category) {
        case SecureWebGatewayCategories.URL_GROUP:
          return [
            SecureWebGatewayAction.VIEW_URL_LIST,
            item.disabled ? enableAction : disableAction,
            removeAction,
          ];
        case SecureWebGatewayCategories.LOCKED_URL_GROUP:
          return [
            SecureWebGatewayAction.VIEW_URL_LIST,
            item.disabled ? enableAction : disableAction,
          ];
        default:
          return [];
      }
    };
    const tableHeight = computed(() => (!props.items?.length ? 540 : undefined));

    const iconToTypeMap = (type: string) => {
      const types: Record<string, string> = {
        urlGroup: "folder",
        lockedUrlGroup: "folderLocked",
        url: "globe",
      };
      return `$${types[type]}`;
    };

    const isRowDisabled = (disabled: boolean): boolean | null => {
      return disabled ? true : null;
    };

    const headers = [
      {
        title: t("network.secureWebGateway.table.name"),
        key: "name",
      },
      {
        title: t("network.secureWebGateway.table.action"),
        key: "action",
      },
      {},
    ];

    return {
      isRowDisabled,
      handleItemAction,
      handlePageChange,
      handleVirtualScroll,
      getActionsList,
      headers,
      tableHeight,
      SecureWebGatewayAction,
      iconToTypeMap,
      SecureWebGatewayCategories,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  [row-disabled] {
    background-color: rgb(var(--v-theme-gray-faint));
  }
  .text-indigo-medium {
    &.icon-folder-locked:before {
      color: rgb(var(--v-theme-indigo-medium));
    }
  }
}
</style>
