export enum DeviceVulnerability {
  UAC_NOTIFICATIONS_MISSING = "uacNotificationMissing",
  DEVICE_PASSWORD_MISSING = "missingPinAndPasswordProtection",
  FIREWALL_DISABLED = "firewallUnavailable",
  MALWARE = "malwareOnEndpoint",
  ENCRYPTION_DISABLED = "isUnencrypted",
  DEVELOPMENT_MODE_ENABLED = "isDeveloperModeEnabled",
  NON_GENUINE_WINDOWS = "isNonGenuineWindows",
  INFECTED_PROCESS = "infectedProcess",
  GATEKEEPER_UNAVAILABLE = "gatekeeperUnavailable",
  APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE = "appleMobileFileIntegrityUnavailable",
  SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE = "systemIntegrityProtectionUnavailable",
  DEVICE_DLP_PHI = "deviceDlpPhi",
  DEVICE_DLP_PCI = "deviceDlpPci",
  DEVICE_DLP_PII = "deviceDlpPii",
  DEVICE_DLP_NPI = "deviceDlpNpi",
  // TODO: uncomment when available
  // USB_LOCKDOWN: "usbLockdown",
  VSS_BACKUP_PROTECTION = "vssBackupProtection",
  FORBIDDEN_NETWORK_CONNECTION = "forbiddenNetworkConnection",
}

export enum VulnerabilityStatus {
  NOT_MUTED = "notMuted",
  MUTED = "muted",
  NO_ISSUES = "noIssues",
}

export enum DeviceAction {
  GPO_DEPLOYMENT = "gpoDeployment",
  VIEW_DETAILS = "viewDetails",
  ENABLE_FIREWALL = "enableFirewall",
  REMOTE_SCAN = "remoteScan",
  DLP_FULL_SCAN = "startDlpFullScan",
  DLP_PARTIAL_SCAN = "startDlpPartialScan",
  STOP_REMOTE_SCAN = "stopRemoteScan",
  STOP_DLP_SCAN = "stopDlpScan",
  EXAMINE_MALWARE = "examineMalware",
  REMOVE_FROM_PROTECTION = "removeFromProtection",
  ENFORCE_UAC = "enforceUAC",
  DISABLE_DEVELOPER_MODE = "disableDevMode",
  MARK_AS_PROCESSED = "markAsProcessed",
  ENABLE_TAMPER_RESISTANCE = "enableTamperResistance",
  DISABLE_TAMPER_RESISTANCE = "disableTamperResistance",
  ALLOW_NO_ENCRYPTION = "allowNoEncryption",
  COLLECT_LOGS = "uploadLogs",
  DOWNLOAD_LOGS = "downloadLogs",
  ENCRYPT_DRIVE = "encryptDrive",
  ENABLE_NETWORK_BLOCK_MODE = "enableNetworkBlockMode",
  DISABLE_NETWORK_BLOCK_MODE = "disableNetworkBlockMode",
  REBOOT_DEVICE = "rebootDevice",
  SHUTDOWN_DEVICE = "shutdownDevice",
  UPDATE_AGENT_TO_LATEST = "updateAgentToLatest",
  ADD_LABEL = "addLabel",
  REMOVE_LABEL = "removeLabel",
  OPEN_REMOTE_SHELL_SESSION = "openRemoteShellSession",
  CLOSE_REMOTE_SHELL_SESSION = "closeRemoteShellSession",
  COLLECT_QUARANTINE_DATA = "collectQuarantineData",
  SHOW_ADVANCED_INFORMATION = "showAdvancedInformation",
  EXPORT_TO_CSV = "exportToCsv",
}

export enum DeviceAllowListActions {
  ADD_PROCESS_RECORD = "addProcessRecord",
  ADD_FOLDER_RECORD = "addFolderRecord",
  ADD_FILE_RECORD = "addFileRecord",
  REMOVE_RECORD = "removeDeviceAllowBlocklistRecord",
  IMPORT_ALLOW_BLOCK_LIST_CSV = "importEdrAllowBlockListFromCsv",
  EDIT_ALLOW_BLOCK_LIST_RECORD = "editDeviceAllowBlocklistRecord",
  EDR_ADD_TO_ALLOWLIST = "edrAddToAllowlist",
  EDR_ADD_TO_BLOCKLIST = "edrAddToBlocklist",
}

export enum DeviceAllowBlockListTypes {
  FOLDER = "folder",
  PROCESS = "process",
  FILE = "file",
  EDR_FILE = "edrFile",
  EDR_FOLDER = "edrFolder",
}

export enum LabelAction {
  CREATE_LABEL = "createLabel",
  EDIT_LABEL = "editLabel",
  DELETE_LABEL = "deleteLabel",
}

export enum OsType {
  WINDOWS = "WINDOWS",
  OSX = "OSX",
  MAC_OS = "macOs",
  IOS = "IOS",
  ANDROID = "ANDROID",
}

export enum IsolationStatus {
  NONE = "none",
  ISOLATED = "isolated",
}

export enum DuplicateDeviceMergeState {
  MERGED = "MERGED",
  MERGE_IGNORED = "MERGE_IGNORED",
  PENDING_POTENTIAL_MERGE = "PENDING_POTENTIAL_MERGE",
}

export enum DevicePostureOsType {
  WINDOWS = "windowsSettingsLabeled",
  MAC_OS = "macOsSettingsLabeled",
}

export enum DevicePostureChoice {
  ENFORCE = "enforce",
  REVIEW = "review",
}

export enum DevicePostureAction {
  ADD = "addDevicePosturePolicy",
  EDIT = "editDevicePosturePolicy",
  REMOVE = "deleteDevicePosturePolicy",
}

export enum DevicePostureSettings {
  WIFI = "wifiSettings",
  DLP_SCAN_SCHEDULE = "dlpScanScheduleSettings",
  USER_ACCOUNT_POLICY = "userAccountPolicies",
  USB_LOCKDOWN = "usbLockdown",
}

export enum DriveEncryptionStatus {
  ENCRYPTED = "encrypted",
  ENCRYPTED_BY_CORO = "encryptedByCoro",
  NOT_ENCRYPTED = "notEncrypted",
  FAILED = "failed",
  SUSPENDED = "suspended",
  IN_PROGRESS = "inProgress",
}

export const driveType = {
  REMOVABLE: "removable",
  NON_REMOVABLE: "nonRemovable",
};

export enum AgentDeploymentChannel {
  STABLE = "stable",
  BETA = "beta",
  ALL = "all",
  RELEASE_CANDIDATE = "releaseCandidate",
  GRADUAL_ROLLOUT = "gradualRollout",
  GENERAL_AVAILABILITY = "generalAvailability",
}

export enum AgentDeploymentAction {
  SELECT_CHANNEL = "selectChannel",
  DOWNLOAD = "download",
  COPY_LINK = "copyDownloadLink",
}

export const devicesLabelsColors = [
  "#b8ddb0",
  "#f5ea92",
  "#fbd19c",
  "#efb3ab",
  "#dfc0eb",
  "#8fdfeb",
  "#7bc86c",
  "#f5dd2a",
  "#ffaf3f",
  "#ee7564",
  "#cd8de5",
  "#28cce5",
];

export enum WifiPostureConnectionType {
  ALL_ENCRYPTED = "ALL_ENCRYPTED",
  SPECIFIC = "SPECIFIC",
}

export enum ActionTypeTarget {
  BULK = "BULK",
  SINGLE = "SINGLE",
}
