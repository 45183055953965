<template>
  <div class="import-url-group-modal">
    <div v-if="!csvLoaded">
      <div v-if="csvLoading" class="pt-12 pb-12 pl-12 pr-12 text-center">
        <v-progress-linear class="mt-12" rounded height="10" indeterminate />
        <div
          class="headline6 mt-4"
          v-text="$t(`modals.importUrlGroupAllowBlockListFromCsv.parsingFile`)"
        />
      </div>
      <template v-else>
        <v-form ref="form" v-model="valid" validate-on="input lazy" @submit.prevent>
          <draggable-file-uploader
            accept="text/plain"
            :description="$t(`modals.importUrlGroupAllowBlockListFromCsv.uploadTxt`)"
            :subtitle="$t(`modals.importUrlGroupAllowBlockListFromCsv.uploadTextSubtitle`)"
            :class="{ 'error-border': !!errorMessage }"
            @file-changed="handleFileUpload"
          />
          <div v-if="!!errorMessage" class="body1 text-red-dark mr-3">
            {{ errorMessage }}
          </div>
          <div class="caption mt-2">
            <a
              class="coro-link"
              @click="getSwgExampleFile"
              v-text="$t(`modals.importUrlGroupAllowBlockListFromCsv.downloadTemplate`)"
            />
            {{ $t(`modals.importUrlGroupAllowBlockListFromCsv.disclaimer`) }}
          </div>
          <v-text-field
            validate-on="submit lazy"
            v-model="localValue.nickname"
            outlined
            background-color="white"
            persistent-placeholder
            hide-details="auto"
            :rules="nicknameRules"
            :counter="100"
            :placeholder="$t('modals.allowBlockListSwg.nicknamePlaceholder')"
            :label="localValue.nickname ? $t('modals.allowBlockListSwg.nicknameLabel') : ''"
            class="mt-3 nickname-input"
          />
          <div class="d-flex mt-5" :class="[getButtonsClass]">
            <template v-if="!csvLoaded && !csvLoadError">
              <v-btn
                color="primary"
                :ripple="false"
                variant="text"
                @click="$emit('dismissed')"
                :text="$t('general.cancel')"
              />

              <v-btn
                rounded
                color="primary"
                data-testid="modal-action-button"
                size="large"
                :disabled="actionNotAllowed"
                @click="handleFileImport"
                :text="$t(`modals.importUrlGroupAllowBlockListFromCsv.actionButton`)"
              />
            </template>
            <v-btn
              v-else
              rounded
              class="close-btn"
              color="primary"
              data-testid="modal-action-button"
              size="large"
              @click="$emit('dismissed')"
            >
              {{ $t("general.close") }}
            </v-btn>
          </div>
        </v-form>
      </template>
    </div>
    <div v-else class="d-flex w-100 flex-column align-center text-center">
      <v-icon size="70" class="mb-6 success-icon" icon="$allGood" />
      <div
        class="headline6 text-center px-6"
        v-text="$t(`modals.importUrlGroupAllowBlockListFromCsv.importInProgress`)"
      />
      <v-btn color="primary" class="mt-7" rounded size="large" @click="$emit('dismissed')">
        {{ $t("general.gotIt") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useSWGStore } from "@/_store/secure-web-gateway.module";
import { isWorkspaceFrozenOrActionRestricted, onlySpaces } from "@/_helpers/utils";
import { DataSizeUnit } from "@/constants/general";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import DraggableFileUploader from "@/components/DraggableFileUploader.vue";
import { useImportCsvModal } from "@/composables/useImportCsvModal";

export default defineComponent({
  components: { DraggableFileUploader },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { getSwgExampleFile, importToSwgUrlList } = useSWGStore();
    const { t } = useI18n();
    const { file, csvLoaded, csvLoading, csvLoadError, handleFileUpload, handleImport } =
      useImportCsvModal();

    const valid = ref(true);
    const form = ref();
    const localValue = ref({
      nickname: "",
    });
    const isFormSubmitted = ref(false);

    const nicknameRules = [
      (value: string) => {
        if (!value || onlySpaces(value)) {
          return t("validations.required");
        }
        if (value.length > 100) {
          return t("validations.maxNCharacters", { n: 100 });
        }
        return true;
      },
    ];

    const actionNotAllowed = computed(() =>
      isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.NETWORK
      )
    );

    const handleFileImport = async () => {
      isFormSubmitted.value = true;
      const { valid: formValid } = await form.value.validate();

      if (!file.value || !!errorMessage.value || !formValid) {
        return;
      }
      const payload = {
        nickname: localValue.value.nickname,
        allowed: props.config.item.allowed,
      };
      await handleImport({ file: file.value, ...payload }, importToSwgUrlList);
    };

    const errorMessage = computed(() => {
      if (isFormSubmitted.value) {
        if (!file.value) {
          return t("modals.allowBlockListSwg.fileMissing");
        }

        if (file.value.type !== "text/plain") {
          return t("modals.allowBlockListSwg.incorrectFileExtension");
        }

        if (file.value.size > DataSizeUnit.MB) {
          return t("modals.allowBlockListSwg.fileSizeError");
        }
      }
      return "";
    });

    const getButtonsClass = computed(() => {
      return !csvLoaded.value && !csvLoadError.value ? "justify-end" : "justify-center";
    });

    return {
      handleFileUpload,
      form,
      valid,
      file,
      localValue,
      nicknameRules,
      actionNotAllowed,
      errorMessage,
      csvLoaded,
      csvLoadError,
      csvLoading,
      isFormSubmitted,
      handleFileImport,
      getSwgExampleFile,
      getButtonsClass,
    };
  },
});
</script>

<style lang="scss" scoped>
.import-url-group-modal {
  min-height: 300px;
}

.success-icon:before {
  color: rgb(var(--v-theme-green-base));
}

.close-btn {
  margin-bottom: 24px;
}

.error-border {
  border: solid 1px rgb(var(--v-theme-red-dark));
}
</style>
