<template>
  <div class="devices-page mt-4 content-md margin-auto">
    <div>
      <v-breadcrumbs :items="breadCrumbsItems"></v-breadcrumbs>
    </div>
    <div class="headline5 settings-header-with-btn">
      {{ $t("devicesSettings.devicesTitle") }}
      <v-btn
        v-if="showAddLabelBtn"
        color="primary"
        data-testid="label-add-btn"
        rounded
        size="large"
        class="add-label-btn"
        @click="addLabel()"
      >
        <v-icon color="white" class="mr-2" icon="$add"> </v-icon>
        <span>{{ $t("devicesSettings.labelsTab.addLabel") }}</span>
      </v-btn>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-4"> </page-tabs>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useRoute } from "vue-router";
import { useDialogsStore } from "@/_store/dialogs.module";
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { LabelAction } from "@/constants/devices";
import { modalWidthByType } from "@/constants/modals";
import AddLabelModal from "@/components/modals/AddLabelModal.vue";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },

  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const dialogsStore = useDialogsStore();
    const deviceSettingsStore = useDevicesSettingsStore();
    const showAddLabelBtn = computed(() => {
      return route.name === RouteName.DEVICES_SETTINGS_LABELS_TAB;
    });

    const addLabel = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        component: AddLabelModal,
        action: LabelAction.CREATE_LABEL,
        width: modalWidthByType.ADD_LABEL,
        callback: deviceSettingsStore.createLabel,
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.DEVICES
        ),
      });
      dialogsStore.openDialog(dialogConfig);
    };

    return {
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      tabs: [
        {
          id: 1,
          name: i18n.t("devicesSettings.tabs.agentDeployment"),
          route: { name: RouteName.DEVICES_SETTINGS_AGENT_DEPLOYMENT_TAB },
        },
        {
          id: 2,
          name: i18n.t("devicesSettings.tabs.labels"),
          route: { name: RouteName.DEVICES_SETTINGS_LABELS_TAB },
        },
        {
          id: 3,
          name: i18n.t("devicesSettings.tabs.settings"),
          route: { name: RouteName.DEVICES_SETTINGS_DEVICE_SETTINGS_TAB },
        },
      ] as CoroTab[],
      showAddLabelBtn,
      addLabel,
    };
  },
});
</script>

<style scoped></style>
