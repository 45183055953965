<template>
  <div class="list-modal__wrapper">
    <div
      v-if="config.item.description"
      class="body2 text--semitransparent mb-6"
      v-text="config.item.description"
    />
    <ul id="list-modal-list">
      <li
        v-for="(listItem, index) in config.item.items"
        :id="`list-modal-item-${index}`"
        :key="index"
        class="mb-4"
      >
        <span class="body1" v-text="listItem" />
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<{ items: unknown[]; description?: string }>>,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
}
</style>
