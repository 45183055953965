<template>
  <div>
    <div class="d-flex justify-space-between mb-4">
      <div class="headline6">
        {{ $t("general.openTickets") }}
        <v-tooltip v-if="showInfoTooltip && !accessRestricted" open-delay="300" location="top">
          <template #activator="{ props }">
            <v-icon v-bind="props" icon="$info" size="28"> </v-icon>
          </template>
          {{ infoTooltipText }}
        </v-tooltip>
      </div>
      <router-link v-if="tickets.length" :to="allOpenTicketsLink">
        <a class="coro-link">{{ $t("general.allOpenTickets") }} ›</a>
      </router-link>
    </div>
    <div class="tickets-list" data-testid="tickets-list">
      <div v-if="accessRestricted" class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="80" icon="$alertTriangle" />
        <span class="coro-subtitle-1">{{ $t("entityTicketsList.noPermissionsText") }}</span>
      </div>
      <v-table v-else-if="tickets.length" class="tickets-list__table pa-2">
        <template #default>
          <tbody>
            <tr v-for="vulnerability in tickets" :key="vulnerability.name">
              <td class="text-left vulnerability-name-col">
                <div class="text-no-wrap d-flex align-center">
                  <div class="inner-circle red-circle mr-2" />
                  <span
                    class="body2 ticket-item cursor-pointer"
                    @click="viewVulnerability(vulnerability)"
                    >{{ $t(`tickets.eventTriggers.${vulnerability.name}`) }}</span
                  >
                </div>
              </td>
              <td class="text-right body2">
                {{ vulnerability.count }}
              </td>
              <td class="text-center">
                <div class="d-flex align-center justify-end">
                  <v-btn
                    v-if="showResolve"
                    class="resolve-button"
                    rounded
                    size="small"
                    color="primary"
                    @click="viewVulnerability(vulnerability)"
                  >
                    {{ $t("general.resolve") }}
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
      <div v-else class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="80" icon="$allGood" />
        <span class="subtitle1">{{ $t("general.noOpenTickets") }}</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { RouteLocationRaw } from "vue-router";

export interface EntityTicket {
  name: string;
  count: number;
}

export default defineComponent({
  props: {
    tickets: {
      type: Array as PropType<EntityTicket[]>,
      required: true,
      default() {
        return [];
      },
    },
    showResolve: {
      type: Boolean,
      default: true,
    },
    allOpenTicketsLink: {
      type: Object as PropType<RouteLocationRaw>,
      default() {
        return {};
      },
    },
    accessRestricted: {
      type: Boolean,
      default: false,
    },
    showInfoTooltip: {
      type: Boolean,
      default: false,
    },
    infoTooltipText: {
      type: String,
      default: "",
    },
  },
  emits: ["view-vulnerability"],
  setup(props, { emit }) {
    function viewVulnerability(vulnerability: EntityTicket) {
      emit("view-vulnerability", vulnerability.name);
    }
    return {
      viewVulnerability,
    };
  },
});
</script>
<style lang="scss" scoped>
.ticket-item {
  text-decoration: none;
  color: rgb(var(--v-theme-red-dark)) !important;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &--disabled:hover {
    text-decoration: none;
    cursor: default;
  }
}
.red-circle {
  background-color: rgb(var(--v-theme-red-dark));
  border-color: rgb(var(--v-theme-red-dark));
}
.tickets-list {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}

.icon-all-good:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}

.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}

.resolve-button {
  width: 72px;
}

.cursor-pointer {
  cursor: pointer;
}

:deep(*) {
  .tickets-list__table {
    td {
      border-bottom: 0 !important;
    }

    .v-data-table__wrapper {
      box-shadow: none !important;
      border-radius: 0 !important;
    }

    .resolve-button {
      .v-btn__content {
        font-size: 12px !important;
      }
    }
  }
}
</style>
