<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-autocomplete
        v-model="localValue.item.metadata.labels[0]"
        class="mb-3"
        :items="filteredLabels"
        :label="$t('general.name')"
        :placeholder="$t('general.name')"
        :rules="[atLeastOneIsRequired()]"
        variant="outlined"
        item-value="id"
        item-title="name"
        clear-on-select
      >
        <template #item="{ item, props }">
          <v-list-item v-bind="props" title="">
            <div class="d-flex align-center label-popup-block">
              <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
              <span>
                {{ item.raw.name }}
              </span>
            </div>
          </v-list-item>
        </template>
      </v-autocomplete>
      <a class="coro-link" @click="goToLabels()">{{ $t("modals.addLabel.manageLabels") }}</a>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import cloneDeep from "lodash/cloneDeep";
import { useRouter } from "vue-router";
import { atLeastOneIsRequired, required } from "@/_helpers/validators";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { storeToRefs } from "pinia";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const deviceSettingsStore = useDevicesSettingsStore();
    const { filteredLabels } = storeToRefs(deviceSettingsStore);
    const form = ref();
    const valid = ref(true);
    const router = useRouter();
    const localValue = ref({
      item: {
        ...cloneDeep(props.config.item),
        metadata: { labels: [] },
      },
    });

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    watch(valid, (value: boolean | null) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    function goToLabels() {
      router.push({ name: RouteName.DEVICES_SETTINGS_LABELS_TAB });
      emit("dismissed");
    }

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    return {
      valid,
      form,
      localValue,
      filteredLabels,
      validate,
      required,
      goToLabels,
      atLeastOneIsRequired,
    };
  },
});
</script>
<style lang="scss" scoped>
.color-preview {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 4px !important;
}

.colors-container {
  gap: 5px;
}

.selected-color-outline {
  border: solid 2px rgb(var(--v-theme-primary)) !important;
}
:deep(*) {
  .v-btn-group--density-default.v-btn-group {
    height: unset;
  }
  .v-btn-toggle > .v-btn.v-btn {
    opacity: 1 !important;
  }
  .v-input__slot {
    padding-right: 8px !important;
  }
  .v-field__append-inner {
    margin-top: 0 !important;
    padding-left: 8px !important;
    height: 56px;
    border-left: 1px solid rgb(var(--v-theme-indigo-pale)) !important;
    display: flex;
    align-items: center;
  }
}
</style>
