<template>
  <v-card class="overview-widget">
    <v-card-text class="px-0">
      <div class="overview-widget__title">
        <span class="text-uppercase subtitle2 mr-1">
          {{ $t(`backOffice.widgets.overviewWidget.types.${item.type}.title`) }}
        </span>
      </div>
      <div class="overview-widget__content mt-4">
        <v-icon class="overview-widget__icon" :icon="iconsMap[item.type]"> </v-icon>
        <div class="overview-widget__content-numbers">
          <div class="headline4 mt-4">
            {{ item.count }}
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { BackOfficeOverviewWidgetType } from "@/constants/back-office";

const iconsMap = {
  [BackOfficeOverviewWidgetType.WINDOWS_DEVICES]: "$windowsHexagon",
  [BackOfficeOverviewWidgetType.MACOS_DEVICES]: "$macOSHexagon",
  [BackOfficeOverviewWidgetType.CUSTOMERS]: "$users",
  [BackOfficeOverviewWidgetType.PROTECTABLE_USERS]: "$users",
  [BackOfficeOverviewWidgetType.PROTECTED_USERS]: "$users",
  [BackOfficeOverviewWidgetType.PROTECTED_USERS_REPORTED_TO_RECURLY]: "$users",
  [BackOfficeOverviewWidgetType.PROTECTED_USERS_IN_PAID_WORKSPACES]: "$users",
};

defineProps<{
  item: { type: BackOfficeOverviewWidgetType; count?: number; index?: number };
}>();
</script>

<style scoped lang="scss">
.overview-widget {
  height: 220px;
  text-align: center;
  transition: all 0.4s ease-in-out;
  &__icon {
    font-size: 72px;
  }

  &__title {
    display: inline-flex;
  }

  &__content {
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: center;
    flex-direction: column;
    &-numbers {
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      .mdi-check {
        font-size: 30px !important;
        margin-top: 5px;
        margin-bottom: 22px;
      }
    }
  }
}
</style>
