<template>
  <div>
    <template v-if="text.length > maxLength">
      <div class="comment-message body2">
        {{ displayedText }}
      </div>
      <div class="coro-link" @click="expandIndividualRow($event)">
        {{ isExpanded ? $t("general.seeLess") : $t("general.seeMore") }}
      </div>
    </template>
    <div v-else class="comment-message body2">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from "vue";

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const isExpanded = ref(props.value);

    const displayedText = computed(() => {
      return isExpanded.value ? props.text : `${props.text.slice(0, props.maxLength)}...`;
    });

    const expandIndividualRow = (event: Event) => {
      event.stopPropagation();
      isExpanded.value = !isExpanded.value;
      emit("update:value", isExpanded.value);
    };

    watch(
      () => props.value,
      (newValue) => {
        if (newValue !== isExpanded.value) {
          isExpanded.value = newValue;
        }
      }
    );

    return {
      isExpanded,
      displayedText,
      expandIndividualRow,
    };
  },
});
</script>

<style scoped>
.comment-message {
  white-space: pre-line;
}
</style>
