import { onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  type MspRoleListItem,
  type RoleListItem,
  RolePermissionsScope,
  useRolesStore,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { storeToRefs } from "pinia";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import { difference } from "lodash";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  dialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import AddRoleModal from "@/components/modals/add-role/AddRoleModal.vue";
import { ModalWidth } from "@/constants/modals";
import AddMspRoleModal from "@/components/modals/add-role/AddMspRoleModal.vue";
import { GlobalRolesAction, MspRolesAction, RolesAction, RoleType } from "@/constants/roles";
import {
  isMspActionRestricted,
  isMspActionRestrictedForWorkspace,
} from "@/_helpers/msp-permissions";
import AddGlobalRoleModal from "@/components/modals/add-role/AddGlobalRoleModal.vue";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";

type RoleAction = RolesAction | MspRolesAction | GlobalRolesAction;

export function useRolesTab<T extends RoleAction = RolesAction>(roleType: RoleType) {
  const expanded = ref<string[]>([]);
  const i18n = useI18n();
  const rolesStore = useRolesStore(roleType);
  const dialogsStore = useDialogsStore();
  const { getDetails, getRoles, setPagination, resetPagination, deleteRole, editRole, addRole } =
    rolesStore;
  const { roles, rolesTotal, roleDetails, pagination, loading, showSkeletonLoader } =
    storeToRefs(rolesStore);
  const { showPagination, page, handleListTablePaginationChange, totalPages } = usePageableTable(
    {
      items: roles,
      pagination: pagination,
      totalItems: rolesTotal,
    },
    async (pagination: Pagination) => {
      setPagination(pagination);
      await getRoles();
    }
  );
  watch(expanded, async (currentValue, prevValue) => {
    const expandedItemId = difference(currentValue, prevValue)[0];
    if (expandedItemId) {
      const assigneesCount = roles.value.find((v) => v.id === expandedItemId)?.assigneesCount;
      await getDetails(expandedItemId, assigneesCount);
    }
  });

  onUnmounted(() => {
    resetPagination();
  });

  const getActions = (item: RoleListItem): T[] => {
    const enumActionMap = {
      [RoleType.WORKSPACE]: RolesAction,
      [RoleType.MSP]: MspRolesAction,
      [RoleType.GLOBAL]: GlobalRolesAction,
    };
    const actionEnum = enumActionMap[roleType];
    if (item.customRole) {
      return [actionEnum.DUPLICATE, actionEnum.EDIT, actionEnum.DELETE] as T[];
    }
    return [actionEnum.DUPLICATE] as T[];
  };

  const onWorkspaceActionClick = async (action: RolesAction, item: RoleListItem) => {
    let dialogConfig;
    const disable = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      WorkspaceManagementScopeSections.ROLES
    );
    if (item.assigneesCount > 0 && action === RolesAction.DELETE) {
      action = RolesAction.UNABLE_TO_DELETE;
    }
    switch (action) {
      case RolesAction.DUPLICATE:
        await getDetails(item.id);
        dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...roleDetails.value[item.id].permissions,
            name: `${item.name} (Copy)`,
          },
          action,
          component: AddRoleModal,
          width: ModalWidth.LARGE,
          callback: addRole,
          disable,
          scrollable: true,
        });
        break;
      case RolesAction.EDIT:
        await getDetails(item.id);
        dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...roleDetails.value[item.id].permissions,
            id: item.id,
          },
          action,
          component: AddRoleModal,
          width: ModalWidth.LARGE,
          callback: editRole,
          disable,
          scrollable: true,
        });
        break;
      case RolesAction.UNABLE_TO_DELETE:
        dialogConfig = dialogsConfigConstructor({
          width: ModalWidth.SMALL,
          header: {
            title: i18n.t(`modals.${action}.title`),
            titleClass: "text-red-dark",
            titleIcon: "warning",
          },
          content: {
            html: i18n.t(`modals.${action}.description`),
          },
          footer: {
            buttons: [
              {
                title: i18n.t("general.ok"),
                color: "primary",
                spacer: "before",
                callback: dialogsStore.closeDialog,
                cancel: false,
              },
            ],
          },
        });
        break;
      default:
        dialogConfig = confirmationDialogsConfigConstructor({
          item,
          action,
          callback: deleteRole,
          disable,
          text: i18n.t(`modals.${action}.description`, { name: item.name }),
        });
        break;
    }

    dialogsStore.openDialog(dialogConfig);
  };

  const onMspActionClick = async (action: MspRolesAction, item: MspRoleListItem) => {
    let dialogConfig;
    if (item.assigneesCount > 0 && action === MspRolesAction.DELETE) {
      action = MspRolesAction.UNABLE_TO_DELETE;
    }
    switch (action) {
      case MspRolesAction.DUPLICATE:
        await getDetails(item.id);
        dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...roleDetails.value[item.id].permissions,
            name: `${item.name} (Copy)`,
          },
          action,
          component: AddMspRoleModal,
          width: ModalWidth.LARGE,
          callback: addRole,
          disable: isMspActionRestricted(MspPortalScopeSection.MSP_ROLES, "add"),
          scrollable: true,
        });
        break;
      case MspRolesAction.EDIT:
        await getDetails(item.id);
        dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...roleDetails.value[item.id].permissions,
            id: item.id,
            workspaceId: item.workspaceId,
          },
          action,
          component: AddMspRoleModal,
          width: ModalWidth.LARGE,
          callback: editRole,
          disable: isMspActionRestrictedForWorkspace(
            item.workspaceId,
            MspPortalScopeSection.MSP_ROLES,
            "edit"
          ),
          scrollable: true,
        });
        break;
      case MspRolesAction.UNABLE_TO_DELETE:
        dialogConfig = dialogsConfigConstructor({
          width: ModalWidth.SMALL,
          header: {
            title: i18n.t(`modals.${action}.title`),
            titleClass: "text-red-dark",
            titleIcon: "warning",
          },
          content: {
            html: i18n.t(`modals.${action}.description`),
          },
          footer: {
            buttons: [
              {
                title: i18n.t("general.ok"),
                color: "primary",
                spacer: "before",
                callback: dialogsStore.closeDialog,
                cancel: false,
              },
            ],
          },
        });
        break;
      case MspRolesAction.DELETE:
        dialogConfig = confirmationDialogsConfigConstructor({
          item,
          action,
          callback: deleteRole,
          disable: isMspActionRestrictedForWorkspace(
            item.workspaceId,
            MspPortalScopeSection.MSP_ROLES,
            "remove"
          ),
          text: i18n.t(`modals.${action}.description`, { name: item.name }),
        });
        break;
      default:
        return;
    }

    dialogsStore.openDialog(dialogConfig);
  };

  const onGlobalRoleActionClick = async (action: GlobalRolesAction, item: RoleListItem) => {
    let dialogConfig;
    if (item.assigneesCount > 0 && action === GlobalRolesAction.DELETE) {
      action = GlobalRolesAction.UNABLE_TO_DELETE;
    }
    switch (action) {
      case GlobalRolesAction.DUPLICATE:
        await getDetails(item.id);
        dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...roleDetails.value[item.id].permissions,
            name: `${item.name} (Copy)`,
          },
          action,
          component: AddGlobalRoleModal,
          width: ModalWidth.LARGE,
          callback: addRole,
          disable: isGlobalActionRestricted(
            GlobalRoleScopes.GLOBAL_SCOPE,
            GlobalRoleScopeSection.GLOBAL_ROLES,
            "add"
          ),
          scrollable: true,
        });
        break;
      case GlobalRolesAction.EDIT:
        await getDetails(item.id);
        dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...roleDetails.value[item.id].permissions,
            id: item.id,
          },
          action,
          component: AddGlobalRoleModal,
          width: ModalWidth.LARGE,
          callback: editRole,
          disable: isGlobalActionRestricted(
            GlobalRoleScopes.GLOBAL_SCOPE,
            GlobalRoleScopeSection.GLOBAL_ROLES,
            "edit"
          ),
          scrollable: true,
        });
        break;
      case GlobalRolesAction.UNABLE_TO_DELETE:
        dialogConfig = dialogsConfigConstructor({
          width: ModalWidth.SMALL,
          header: {
            title: i18n.t(`modals.${action}.title`),
            titleClass: "text-red-dark",
            titleIcon: "warning",
          },
          content: {
            html: i18n.t(`modals.${action}.description`),
          },
          footer: {
            buttons: [
              {
                title: i18n.t("general.ok"),
                color: "primary",
                spacer: "before",
                callback: dialogsStore.closeDialog,
                cancel: false,
              },
            ],
          },
        });
        break;
      case GlobalRolesAction.DELETE:
        dialogConfig = confirmationDialogsConfigConstructor({
          item,
          action,
          callback: deleteRole,
          disable: isGlobalActionRestricted(
            GlobalRoleScopes.GLOBAL_SCOPE,
            GlobalRoleScopeSection.GLOBAL_ROLES,
            "remove"
          ),
          text: i18n.t(`modals.${action}.description`, { name: item.name }),
        });
        break;
      default:
        return;
    }

    dialogsStore.openDialog(dialogConfig);
  };

  return {
    roles,
    roleDetails,
    expanded,
    showPagination,
    page,
    totalPages,
    loading,
    showSkeletonLoader,
    pagination,
    resetPagination,
    getRoles,
    rolesTotal,
    handlePaginationChange: handleListTablePaginationChange,
    getActions,
    onWorkspaceActionClick,
    onMspActionClick,
    onGlobalRoleActionClick,
  };
}
