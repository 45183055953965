<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
  >
    <div class="allow-blocklist-tab-content allow-blocklist-tab-filters d-flex justify-end">
      <v-menu>
        <template #activator="{ props }">
          <v-btn
            class="add-btn allow-blocklist-tab-add-btn"
            color="primary"
            rounded
            density="default"
            size="large"
            data-testid="data-governance-add-permission-btn"
            v-bind="props"
          >
            <v-icon class="mr-2" icon="$add" />
            {{ $t("general.add") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(action, index) in actions"
            :key="index"
            data-testid="allow-block-page-add-groups-action"
            class="v-list-item v-list-item--link theme--light"
            @click="
              onActionClick({
                action: action.actionKey,
                callback: action.callback,
              })
            "
          >
            <v-list-item-title>
              {{ $t(`devicesSettings.allowBlockList.actions.${action.actionKey}`) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="d-flex justify-end allow-blocklist-tab-content allow-blocklist-tab-filters">
      <filter-wrapper
        class="mb-5"
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(clearFiltersCallback)"
      >
        <v-select
          v-model="localFilters.type"
          :items="filterTypes"
          :menu-props="{ maxHeight: '300' }"
          class="filter-menu mr-2"
          :class="{ 'filter-active': localFilters.type }"
          :placeholder="$t('devicesSettings.allowBlockList.filters.type')"
          density="compact"
          variant="outlined"
          rounded
          menu-icon="icon-triangle"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item
              v-bind="props"
              :title="$t(`devicesSettings.allowBlockList.filters.types.${item.value}`)"
            >
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span class="body2">
              {{ $t(`devicesSettings.allowBlockList.filters.types.${item.value}`) }}
            </span>
          </template>
        </v-select>
        <v-text-field
          v-model="localFilters.search"
          data-testid="users-page-users-search"
          :class="{ 'filter-active': localFilters.search?.length }"
          density="compact"
          variant="outlined"
          :placeholder="$t('general.search')"
          class="search-field"
          prepend-inner-icon="icon-search"
          clearable
          rounded
          clear-icon="icon-x"
        />
      </filter-wrapper>
    </div>
    <template v-if="showApplyToChildWorkspaces">
      <v-switch
        v-model="localValue.edrFiles"
        class="allow-blocklist-tab-content fit-content"
        color="primary lighten-1"
        density="compact"
        hide-details
        :ripple="false"
        :disabled="actionNotAllowed"
      >
        <template #label>
          <div class="text--primary">
            {{ $t(`devicesSettings.allowBlockList.applyFilesToChildWorkspaces`) }}
          </div>
        </template>
      </v-switch>
      <v-switch
        v-model="localValue.processes"
        class="allow-blocklist-tab-content mb-2 fit-content"
        color="primary lighten-1"
        density="compact"
        hide-details
        :ripple="false"
        :disabled="actionNotAllowed"
      >
        <template #label>
          <div class="text--primary">
            {{ $t(`devicesSettings.allowBlockList.applyProcessesToChildWorkspaces`) }}
          </div>
        </template>
      </v-switch>
    </template>
    <v-progress-linear
      v-if="loading"
      class="allow-blocklist-tab-content"
      indeterminate
      height="2px"
    />
    <devices-allow-white-list-table
      :items="items"
      :actions="itemActions"
      :pagination="pagination"
      :total-items="total"
      :disable-actions="actionNotAllowed"
      @page-changed="handlePagination($event)"
    />
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { DeviceAllowBlockListTypes, DeviceAllowListActions } from "@/constants/devices";
import { PhishingAction } from "@/constants/phishing";
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
  useTwoWayBinding,
} from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useDeviceAllowBlockListStore } from "@/_store/endpoint-security/device-allow-block-list.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import AllowBlockListDevicesModal from "@/components/modals/AllowBlocklistDevicesModal.vue";
import ImportCsvModal from "@/components/modals/ImportCSVModal.vue";
import { FilterContext } from "@/_store/filters.module";
import { useFilters } from "@/composables/useFilters";
import DevicesAllowWhiteListTable from "@/components/tables/DevicesAllowBlockListTable.vue";
import type { ComponentDialogConfig, GenericCallback, Pagination } from "@/types";
import { SubscriptionModule, WorkspaceType } from "@/constants/workplaces";
import { useAccountStore } from "@/_store/account.module";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { RolePermissionsScope } from "@/_store/roles.module";
import { DataSizeUnit } from "@/constants/general";

const filterContext = FilterContext.EDR_ALLOW_BLOCK_LIST;
export default defineComponent({
  components: {
    DevicesAllowWhiteListTable,
    FilterWrapper,
  },
  setup() {
    const dialogsStore = useDialogsStore();
    const deviceAllowBlockListModule = useDeviceAllowBlockListStore();
    const accountStore = useAccountStore();
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } =
      useFilters(filterContext);
    const { items, loading, inheritanceSettings, pagination, total, showSkeletonLoader } =
      storeToRefs(deviceAllowBlockListModule);
    const {
      addDeviceAllowBlockList,
      uploadEdrAllowBlockListCSV,
      downloadEdrAllowBlockListCSVTemplate,
      removeDeviceAllowBlockListRecord,
      getItems,
      getInheritanceSettings,
      updateInheritanceSettings,
      setPagination,
      resetPagination,
      setIsEdr,
    } = deviceAllowBlockListModule;
    const showApplyToChildWorkspaces = computed(() => {
      return accountStore.account.workspaceType === WorkspaceType.CHANNEL;
    });
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.EDR
      );
    });
    const localValue = useTwoWayBinding(inheritanceSettings, updateInheritanceSettings);
    const filterTypes = [DeviceAllowBlockListTypes.EDR_FOLDER, DeviceAllowBlockListTypes.PROCESS];
    const actions = [
      {
        actionKey: DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST,
        callback: addDeviceAllowBlockList,
      },
      {
        actionKey: DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST,
        callback: addDeviceAllowBlockList,
      },
      {
        actionKey: DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV,
        callback: () => {},
      },
    ];
    const itemActions = [
      {
        actionKey: DeviceAllowListActions.REMOVE_RECORD,
        callback: removeDeviceAllowBlockListRecord,
      },
    ];

    const onActionClick = (event: { action: string; callback: GenericCallback }) => {
      const config: ComponentDialogConfig<any> = {
        action: event.action,
        callback: event.callback,
        width: ModalWidth.LARGE,
        item: {},
        component: {},
        disable: actionNotAllowed.value,
      };
      switch (event.action) {
        case DeviceAllowListActions.ADD_PROCESS_RECORD:
        case DeviceAllowListActions.ADD_FILE_RECORD:
        case DeviceAllowListActions.ADD_FOLDER_RECORD:
        case DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST:
        case DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST:
          config.component = AllowBlockListDevicesModal;
          config.width = ModalWidth.LARGE;
          config.item.isEdr = true;
          break;
        case DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV:
          config.component = ImportCsvModal;
          config.width = ModalWidth.MEDIUM;
          config.item.downloadTemplateCallback = downloadEdrAllowBlockListCSVTemplate;
          config.item.uploadCallback = uploadEdrAllowBlockListCSV;
          config.item.showDownloadReport = true;
          config.item.maxFileSize = DataSizeUnit.MB * 10;
          config.hideFooter = true;
          break;
      }
      const dialogConfig = componentDialogsConfigConstructor(config);
      dialogsStore.openDialog(dialogConfig);
    };

    const handlePagination = (pagination: Pagination) => {
      setPagination(pagination);
      getItems();
    };

    const clearFiltersCallback = async () => {
      await resetPagination();
      await getItems();
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await getItems();
      },
      { deep: true }
    );

    onMounted(async () => {
      setIsEdr(true);
      await getItems({ showSkeletonLoader: true });
      await getInheritanceSettings();
    });

    return {
      PhishingActions: PhishingAction,
      showClearFiltersButton,
      showApplyToChildWorkspaces,
      localValue,
      items,
      actions,
      itemActions,
      localFilters,
      loading,
      pagination,
      total,
      onActionClick,
      showSkeletonLoader,
      clearFilters,
      clearFiltersCallback,
      handlePagination,
      coronetSkeletonLoaderTypes,
      filterTypes,
      actionNotAllowed,
    };
  },
});
</script>

<style scoped lang="scss">
.allow-blocklist-tab-content {
  position: relative;
}
.allow-blocklist-tab-add-btn {
  position: absolute;
  top: -102px;
}

.filter-menu {
  width: 130px;
}
</style>
