import { getDownloadLink } from "@/_helpers/utils";
import { defineStore } from "pinia";

interface DownloadsStoreState {
  showSkeletonLoader: boolean;
  downloadLinks: Record<string, string>;
  customer: string | null;
}

const defaultDownloadsState = {
  showSkeletonLoader: false,
  downloadLinks: {},
  customer: null,
};
/**
 * @deprecated There is a possibility that this store is not used anymore, so keeping it just for old times sake.
 */
export const useDownloadsStore = defineStore("downloads", {
  state: (): DownloadsStoreState => ({ ...defaultDownloadsState }),
  actions: {
    async init(payload: { id: string; showSkeletonLoader?: boolean }) {
      const { id, showSkeletonLoader = true } = payload;

      this.showSkeletonLoader = showSkeletonLoader;

      try {
        const links = await getDownloadLink({ id });
        const { customerName } = await getDownloadDetails(id);
        this.downloadLinks = links;
        this.customer = customerName;
      } catch (error) {
        console.error(error);
      } finally {
        this.showSkeletonLoader = false;
      }
    },
  },
});

async function getDownloadDetails(id: string) {
  const response = await fetch(`${import.meta.env.VITE_API_HOST}clients/downloadDetails/?id=${id}`);
  return await response.json();
}
