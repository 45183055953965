<template>
  <v-menu location="bottom" open-on-click min-width="240" :disabled="statusSelectionDisabled">
    <template #activator="{ props }">
      <div v-bind="props">
        <soc-status-label
          :soc-status="socStatus"
          size="regular"
          append-icon="$dropdown"
          :role="statusSelectionDisabled ? '' : 'button'"
        />
      </div>
    </template>

    <v-list>
      <v-list-item v-for="item in availableSocStatuses" :key="item" @click="onStatusClick(item)">
        <div class="d-flex justify-space-between">
          <div class="coro-body2">{{ $t(`tickets.socStatuses.${item}`) }}</div>
          <div class="color-preview" :style="{ 'background-color': socStatusColorMap[item] }"></div>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import SocStatusLabel from "@/components/tickets/SocStatusLabel.vue";
import { SocStatus, socStatusColorMap } from "@/constants/tickets";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { computed, defineComponent, type PropType } from "vue";
import { GlobalRoleScopes, GlobalRoleScopeSection } from "@/_store/roles.module";

export default defineComponent({
  components: { SocStatusLabel },
  props: {
    socStatus: {
      type: String as PropType<SocStatus>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const availableSocStatuses = computed(() => {
      let availableSocStatuses: SocStatus[] = [];
      if (
        !isGlobalActionRestricted(
          GlobalRoleScopes.GLOBAL_SCOPE,
          GlobalRoleScopeSection.SOC_PORTAL,
          "tier1Statuses"
        )
      ) {
        availableSocStatuses = [SocStatus.NEW, SocStatus.WIP_TIER1, SocStatus.REQUIRES_TIER2];
      }
      if (
        !isGlobalActionRestricted(
          GlobalRoleScopes.GLOBAL_SCOPE,
          GlobalRoleScopeSection.SOC_PORTAL,
          "tier2Statuses"
        )
      ) {
        availableSocStatuses = [
          SocStatus.NEW,
          SocStatus.WIP_TIER1,
          SocStatus.REQUIRES_TIER2,
          SocStatus.WIP_TIER2,
        ];
      }
      return availableSocStatuses.filter((status) => status !== props.socStatus);
    });

    const statusSelectionDisabled = computed(() => {
      return (
        isGlobalActionRestricted(
          GlobalRoleScopes.GLOBAL_SCOPE,
          GlobalRoleScopeSection.SOC_PORTAL,
          "tier1Statuses"
        ) &&
        isGlobalActionRestricted(
          GlobalRoleScopes.GLOBAL_SCOPE,
          GlobalRoleScopeSection.SOC_PORTAL,
          "tier2Statuses"
        )
      );
    });

    const onStatusClick = (newSocStatus: SocStatus) => {
      emit("status-updated", newSocStatus);
    };

    return {
      socStatusColorMap,
      availableSocStatuses,
      statusSelectionDisabled,
      onStatusClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.color-preview {
  width: 24px !important;
  height: 24px !important;
  min-width: 20px !important;
  border-radius: 4px !important;
}
</style>
