import cloneDeep from "lodash/cloneDeep";
import { defineStore } from "pinia";
import { i18n } from "@/plugins/i18n";
import type { ISelection } from "@/_store/selector.module";
import type { Component } from "vue";
import type { GenericCallback } from "@/types";

export interface DialogFooterBtn {
  title: string;
  spacer?: string;
  type?: string;
  cancel?: boolean;
  color?: string;
  callback?: GenericCallback;
}

export interface DialogDataConfig<T = any, Action = string> {
  action?: Action;
  item: T;
  selection?: ISelection<T>;
  callback?: GenericCallback;
}

export interface DialogConfig<T = any> {
  width?: number;
  header: {
    title: string;
    close?: boolean;
    titleClass?: string;
    titleIcon?: string;
  };
  content: {
    component?: Component;
    html?: string;
    height?: number;
    maxHeight?: number;
  };
  footer: {
    buttons: Array<DialogFooterBtn>;
  } | null;
  data: DialogDataConfig<T>;
  disable: boolean;
  closeCallback?: GenericCallback;
  disablePersistentAnimation?: boolean;
  scrollable?: boolean;
  fullscreen?: boolean;
  persistOnAction?: boolean;
  disclaimer: string | null;
  showBtnLoadingIndicator?: boolean;
}

interface DialogsState {
  dialogConfig: DialogConfig;
  showDialog: boolean;
}

export const defaultDialogsConfig: DialogConfig = {
  header: {
    title: "",
    close: true,
  },
  content: {},
  footer: {
    buttons: [
      {
        title: i18n.global.t("general.cancel"),
        spacer: "before",
        type: "text",
        cancel: true,
      },
    ],
  },
  data: {
    action: "",
    item: undefined,
  },
  disable: false,
  disclaimer: null,
};

const defaultDialogsState: DialogsState = {
  dialogConfig: { ...defaultDialogsConfig },
  showDialog: false,
};

export const useDialogsStore = defineStore("dialogs", {
  state: (): DialogsState => ({ ...defaultDialogsState }),
  persist: false,
  actions: {
    openDialog(config: DialogConfig) {
      this.dialogConfig = cloneDeep(config);
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
});
