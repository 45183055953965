<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
  >
    <div v-if="items.length > 0" class="mt-2">
      <table-wrapper>
        <v-table class="white">
          <template #default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("soc.contactsTab.table.name") }}
                </th>
                <th class="text-left">
                  {{ $t("soc.contactsTab.table.email") }}
                </th>
                <th class="text-left">
                  {{ $t("soc.contactsTab.table.notifications") }}
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in items"
                :key="item.id"
                :data-testid="`table-row-${i}`"
                class="soc-contact-row"
              >
                <td>
                  <div class="d-flex align-center">
                    <v-icon icon="$user" :size="40" />
                    <span class="subtitle2 ml-2"> {{ item.firstName }} {{ item.lastName }} </span>
                  </div>
                </td>
                <td>
                  <span class="body2">{{ item.email }}</span>
                </td>
                <td>
                  <span class="body2">{{
                    $t(`soc.contactsTab.notificationType.${item.notification}`)
                  }}</span>
                </td>
                <td class="text-right" @click="$event.stopPropagation()">
                  <v-menu bottom left>
                    <template v-slot:activator="{ props }">
                      <v-icon icon="$dots" v-bind="props"> </v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(action, index) in actions"
                        :key="index"
                        :value="index"
                        @click="action.callback(item)"
                      >
                        <v-list-item-title>
                          {{ $t(`soc.contactsTab.actions.${action.action}`) }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </table-wrapper>
    </div>
    <div v-else class="d-flex align-center justify-center">
      <empty-state class="mt-12">
        <template #icon>
          <v-icon icon="$socSettings" :size="80" />
        </template>
        <template #description>
          {{ $t("soc.contactsTab.emptyState.description") }}
        </template>
        <template #subtitle>
          {{ $t("soc.contactsTab.emptyState.subtitle") }}
        </template>
        <template #button>
          <v-btn
            rounded
            color="primary"
            density="default"
            size="large"
            elevation="0"
            @click="addSocContactItem"
          >
            {{ $t("soc.contactsTab.addBtn") }}
          </v-btn>
        </template>
      </empty-state>
    </div>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import type { SocContactItem } from "@/_store/soc/soc-contact.module";
import {
  SocContactClosureNotification,
  SocContactNotificationTypes,
  useSocContactStore,
} from "@/_store/soc/soc-contact.module";
import { storeToRefs } from "pinia";
import EmptyState from "@/components/EmptyState.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import AddSocContactModal from "@/components/modals/AddSocContactModal.vue";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SocContactsActions } from "@/constants/soc";
import { useDialogsStore } from "@/_store/dialogs.module";
import { modalWidthByType } from "@/constants/modals";
import { ProtectionScopeModules, RolePermissionsScope } from "@/_store/roles.module";

export default defineComponent({
  components: {
    EmptyState,
    TableWrapper,
  },
  setup() {
    const socContactsStore = useSocContactStore();
    const dialogsStore = useDialogsStore();
    const { items, showSkeletonLoader } = storeToRefs(socContactsStore);
    const {
      addSocRemediationSettings,
      updateSocRemediationSettings,
      deleteSocRemediationSettings,
    } = socContactsStore;
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        ProtectionScopeModules.MANAGED_SOC
      );
    });
    const deleteItem = (item: SocContactItem) => {
      const dialogConfig = confirmationDialogsConfigConstructor({
        item,
        action: SocContactsActions.REMOVE,
        callback: deleteSocRemediationSettings,
        disable: actionNotAllowed.value,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const editItem = (item: SocContactItem) => {
      const dialogConfig = componentDialogsConfigConstructor({
        item,
        action: SocContactsActions.EDIT,
        component: AddSocContactModal,
        width: modalWidthByType.ADD_ALL_USERS,
        callback: updateSocRemediationSettings,
        disable: actionNotAllowed.value,
        scrollable: true,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const addSocContactItem = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        item: {
          firstName: "",
          lastName: "",
          email: null,
          notification: SocContactNotificationTypes.EMAIL,
          closureNotification: SocContactClosureNotification.WEEKLY,
        },
        action: SocContactsActions.CREATE,
        component: AddSocContactModal,
        width: modalWidthByType.ADD_ALL_USERS,
        callback: addSocRemediationSettings,
        disable: actionNotAllowed.value,
        scrollable: true,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const actions = [
      {
        action: SocContactsActions.REMOVE,
        callback: deleteItem,
      },
      {
        action: SocContactsActions.EDIT,
        callback: editItem,
      },
    ];

    onMounted(async () => {
      await socContactsStore.getSocContacts();
    });

    return {
      actions,
      addSocContactItem,
      showSkeletonLoader,
      items,
      coronetSkeletonLoaderTypes,
    };
  },
});
</script>

<style scoped lang="scss">
.icon-dots:before {
  color: rgb(var(--v-theme-primary));
}

.icon-soc-settings:before {
  color: rgba(var(--v-theme-primary), 0.65);
}

.soc-contact-row {
  height: 72px;
}
</style>
