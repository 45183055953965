<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.DEFAULT_TABLE"
    >
      <template v-if="connectors.length">
        <div class="setting-description mb-4">
          {{ $t("connectors.siem.description") }}
        </div>
        <v-progress-linear
          :style="{ visibility: loading ? 'visible' : 'hidden' }"
          indeterminate
          height="2px"
        />
        <table-wrapper>
          <v-table class="white">
            <template #default>
              <tbody>
                <tr
                  v-for="item in connectors"
                  :key="item.name"
                  class="service-row"
                  :data-testid="`connector-row-${item.name}`"
                >
                  <td>
                    <div class="d-flex align-center">
                      <v-icon
                        class="mr-4"
                        size="48"
                        v-if="item.format === SiemConnectorFormat.GENERIC"
                        icon="$connectors"
                      ></v-icon>
                      <v-img
                        v-else
                        class="mr-4"
                        max-height="48"
                        max-width="48"
                        :src="`/images/logos/${item.format}.svg`"
                        contain
                      />
                      <div>
                        <div class="subtitle1">{{ item.name }}</div>
                        <div :class="`body1 ${item.status}`">
                          {{ $t(`services.status.${item.status}`) }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-right" @click="$event.stopPropagation()">
                    <v-menu bottom left>
                      <template v-slot:activator="{ props }">
                        <v-icon icon="$dots" v-bind="props"> </v-icon>
                      </template>
                      <v-list>
                        <template v-for="(actionItem, index) in getActions()" :key="index">
                          <v-list-item :value="index" @click="actionItem.callback(item)">
                            <v-list-item-title>
                              {{ $t(`connectors.siem.table.actions.${actionItem.action}`) }}
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </table-wrapper>
      </template>
      <template v-else>
        <empty-state class="margin-auto mt-12 w-65">
          <template #icon>
            <v-icon icon="$connectors" :size="80" />
          </template>
          <template #description>
            <div class="headline5 mb-3 mt-3">
              {{ $t("connectors.siem.noItems.description") }}
            </div>
          </template>
          <template #subtitle>
            {{ $t("connectors.siem.noItems.subDescription") }}
          </template>
          <template #button>
            <v-btn
              rounded
              color="primary"
              density="default"
              size="large"
              elevation="0"
              @click="openModal()"
            >
              {{ $t("connectors.siem.add") }}
            </v-btn>
          </template>
        </empty-state>
      </template>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useSiemStore } from "@/_store/connectors/siem.module";
import type { SiemConnector } from "@/_store/connectors/siem.module";
import { storeToRefs } from "pinia";
import EmptyState from "@/components/EmptyState.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { ConnectorActions, SiemConnectorFormat } from "@/constants/connectors";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { useDialogsStore } from "@/_store/dialogs.module";
import AddSiemConnectorModal from "@/components/modals/connectors/AddSiemConnectorModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useI18n } from "vue-i18n";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

enum SiemTabAction {
  EDIT = "edit",
  DELETE = "delete",
  SYNC = "sync",
}
export default defineComponent({
  computed: {
    SiemConnectorFormat() {
      return SiemConnectorFormat;
    },
  },
  components: { TableWrapper, EmptyState },
  setup() {
    const siemModule = useSiemStore();
    const dialogModule = useDialogsStore();
    const i18n = useI18n();
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CONNECTORS
      );
    });
    const { connectors, showSkeletonLoader, loading } = storeToRefs(siemModule);

    const { getConnectors, syncConnector, deleteConnector, updateConnector, addSiemConnector } =
      siemModule;

    onMounted(() => {
      getConnectors();
    });

    const openModal = (item?: SiemConnector) => {
      const dialogConfig = componentDialogsConfigConstructor({
        item,
        action: ConnectorActions.ADD_SIEM,
        component: AddSiemConnectorModal,
        width: ModalWidth.MEDIUM,
        disable: actionNotAllowed.value,
        callback: addSiemConnector,
      });

      dialogModule.openDialog({
        ...dialogConfig,
        header: {
          title: i18n.t("modals.addSiemConnector.title"),
          close: true,
        },
        footer: {
          buttons: [
            {
              title: i18n.t("general.cancel"),
              spacer: "before",
              type: "text",
              cancel: true,
            },
            {
              title: i18n.t(
                `modals.${
                  item ? ConnectorActions.UPDATE_SIEM : ConnectorActions.ADD_SIEM
                }.actionBtn`
              ),
              color: "primary",
              callback: item ? updateConnector : addSiemConnector,
              cancel: false,
            },
          ],
        },
      });
    };

    const getActions = () => {
      return [
        {
          action: SiemTabAction.EDIT,
          show: () => !actionNotAllowed.value,
          callback: openModal,
        },
        {
          action: SiemTabAction.DELETE,
          show: () => !actionNotAllowed.value,
          callback: (item: SiemConnector) => {
            const dialogConfig = confirmationDialogsConfigConstructor({
              action: ConnectorActions.DELETE_SIEM,
              item,
              callback: () => deleteConnector(item),
            });

            dialogModule.openDialog(dialogConfig);
          },
        },
        {
          action: SiemTabAction.SYNC,
          show: () => !actionNotAllowed.value,
          callback: syncConnector,
        },
      ];
    };
    return {
      coronetSkeletonLoaderTypes,
      showSkeletonLoader,
      loading,
      connectors,
      openModal,
      getActions,
    };
  },
});
</script>

<style lang="scss" scoped>
.heading-text {
  line-height: 40px;
}

:deep(*) {
  .service-row {
    height: 80px;

    // Overrides styles from main.scss - background-color: var(--v-neutral-lighten2) !important;
    .transparent-bg {
      background-color: transparent !important;
    }
  }

  .service-name {
    font-size: 18px;
    line-height: 24px;
  }

  .service-status {
    font-size: 16px;
  }
}
</style>
