<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <v-autocomplete
      v-model="localValue.item.email"
      class="mt-6"
      :placeholder="$t('modals.addAdminUser.email.placeholder')"
      :label="$t('modals.addAdminUser.email.label')"
      :disabled="isEditMode"
      item-title="email"
      item-value="email"
      :return-object="false"
      :hide-no-data="false"
      :no-filter="true"
      auto-select-first="exact"
      :rules="[required()]"
      :loading="adminsSearchLoading"
      :menu-props="{
        maxHeight: '250',
      }"
      :items="adminUserCandidates"
      variant="outlined"
      @update:search="onGlobalAdminUserSearchUpdate($event)"
    ></v-autocomplete>
    <v-autocomplete
      v-model="localValue.item.role"
      :disabled="isEditMode ? roleAssignmentRestricted : false"
      class="mt-6"
      :placeholder="$t('modals.addAdminUser.role.placeholder')"
      :label="$t('modals.addAdminUser.role.label')"
      item-title="roleName"
      item-value="roleId"
      :return-object="true"
      :hide-no-data="false"
      :no-filter="true"
      auto-select-first="exact"
      :rules="assignRolesRules"
      :loading="rolesSearchLoading"
      :menu-props="{
        maxHeight: '250',
      }"
      :items="roles"
      variant="outlined"
      @update:search="onRoleSearchUpdate($event)"
    ></v-autocomplete>
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { useI18n } from "vue-i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { emailRule, required } from "@/_helpers/validators";
import { GlobalRoleScopes, GlobalRoleScopeSection, useRolesStore } from "@/_store/roles.module";
import { debounce } from "lodash";
import isEmpty from "lodash/isEmpty";
import { GlobalAdminUsersAction } from "@/constants/admin-accounts";
import { RoleType } from "@/constants/roles";
import { type GlobalAdmin, useBackOfficeStore } from "@/_store/back-office/back-office.module";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<GlobalAdmin>>,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const rolesStore = useRolesStore(RoleType.GLOBAL);
    const { searchRoles } = rolesStore;
    const { searchGlobalAdminUsersCandidates } = useBackOfficeStore();
    const localValue = ref({
      item: cloneDeep(props.config.item ?? {}),
      action: props.config.action,
    });
    const roles = ref<{ roleName: string; roleId: string }[]>([]);
    const adminUserCandidates = ref<{ email: string }[]>([]);
    const rolesSearchLoading = ref<boolean>(false);
    const adminsSearchLoading = ref<boolean>(false);
    const valid = ref(true);
    const form = ref();
    const isEditMode: boolean = props.config.action === GlobalAdminUsersAction.EDIT;
    const assignRolesRules = computed(() => {
      // Case when adding admin user => role is required
      if (!isEditMode) {
        return [roleRequiredRule];
      }
      // Case when editing admin user and can assign role
      if (isEditMode && !roleAssignmentRestricted.value) {
        return [roleRequiredRule];
      }
      // Case when editing admin user and can't assign role
      return [];
    });
    const roleAssignmentRestricted = computed(() =>
      isGlobalActionRestricted(
        GlobalRoleScopes.GLOBAL_SCOPE,
        GlobalRoleScopeSection.GLOBAL_ADMIN_USERS,
        "assignRoles"
      )
    );

    const roleRequiredRule = (value?: { roleName: string; roleId: string }) => {
      if (isEmpty(value)) {
        return i18n.t("validations.required");
      }
      return true;
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );

    const onRoleSearchUpdate = debounce(function (searchString) {
      if (!searchString && !localValue.value.item.role?.roleName) {
        fetchRoles();
      }
      if (searchString && searchString !== localValue.value.item.role?.roleName) {
        fetchRoles(searchString);
      }
    }, 500);

    const onGlobalAdminUserSearchUpdate = debounce(function (searchString) {
      if (!searchString && !localValue.value.item.email) {
        fetchGlobalAdmins();
      }
      if (searchString !== localValue.value.item.email) {
        fetchGlobalAdmins(searchString);
      }
    }, 500);

    watch(
      valid,
      (newVal: boolean | null) => {
        emit("update:valid", newVal ?? true);
      },
      { immediate: false }
    );

    onMounted(async () => {
      await fetchRoles();
      if (!isEditMode) {
        await fetchGlobalAdmins();
      }
    });

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const fetchRoles = async (search: string = "") => {
      rolesSearchLoading.value = true;
      const data = await searchRoles({ page: 0, pageSize: 50, search });
      roles.value = data.items;
      rolesSearchLoading.value = false;
    };

    const fetchGlobalAdmins = async (search: string = "") => {
      adminsSearchLoading.value = true;
      adminUserCandidates.value = await searchGlobalAdminUsersCandidates(search);
      adminsSearchLoading.value = false;
    };

    return {
      localValue,
      valid,
      form,
      roles,
      rolesSearchLoading,
      adminUserCandidates,
      adminsSearchLoading,
      isEditMode,
      roleAssignmentRestricted,
      assignRolesRules,
      required,
      emailRule,
      validate,
      onRoleSearchUpdate,
      onGlobalAdminUserSearchUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-field--error:not(.v-field--disabled) .v-field__outline {
    --v-field-border-width: 2px !important;
  }
}
</style>
