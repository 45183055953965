<template>
  <v-menu
    v-if="items.length > threshold"
    v-model="showPopover"
    :absolute="false"
    :open-on-hover="false"
    :close-on-click="true"
    :close-on-content-click="false"
    :offset-y="true"
  >
    <template #activator="{ props }">
      <span class="coro-link" role="button" v-bind="props">
        <slot name="custom-activator" :items="itemsAfterThreshold">
          {{ `+${items.length - threshold}` }}
        </slot>
      </span>
    </template>
    <div class="coro-popover-wrapper">
      <div class="d-flex justify-space-between mb-2">
        <div class="mr-2 caption text-indigo-medium">{{ title }}</div>
        <v-icon size="16" class="popover__close-btn" @click="showPopover = false" icon="$x" />
      </div>
      <slot name="content" :items="itemsAfterThreshold">
        <div v-for="(item, index) in items" :key="index">
          {{ item }}
        </div>
      </slot>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },
    threshold: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const showPopover = ref(false);
    const itemsAfterThreshold = computed(() => {
      return props.items.slice(props.threshold);
    });

    return {
      itemsAfterThreshold,
      showPopover,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .coro-popover-wrapper {
    padding: 6px 20px 6px 20px;
    background-color: rgb(var(--v-theme-white));
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .popover__close-btn {
    margin-right: -12px;
  }
}
</style>
