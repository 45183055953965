import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";

export interface CloudAppsSettings {
  inactiveDays: number | null;
}

export interface CloudAppsSettingsState {
  settings: CloudAppsSettings;
  isLoading: boolean;
  showSkeletonLoader: boolean;
}

export const useCloudAppsSettingsStore = defineStore("cloud-apps-settings", {
  state: (): CloudAppsSettingsState => ({
    settings: {
      inactiveDays: null,
    },
    isLoading: false,
    showSkeletonLoader: false,
  }),
  actions: {
    async getSettings(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;

      const request = api.cloudSecuritySettings();

      try {
        const { data } = await axiosInstance.request(request);

        this.settings = {
          inactiveDays: data.inactiveDays,
        };
      } catch (error) {
        console.error(error);
      }
      this.showSkeletonLoader = false;
    },
    async updateSetting(data: Partial<CloudAppsSettings>) {
      this.isLoading = true;

      const request = {
        ...api.cloudSecuritySettings(),
        data: {
          inactiveDays: data.inactiveDays,
        },
        method: "PUT",
      };

      try {
        await axiosInstance.request(request);

        this.getSettings(false);
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
  },
});
