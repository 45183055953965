<template>
  <div v-if="findings?.length">
    <h6 v-if="showTitle" class="headline6 mb-4">
      {{ $t("ticketDetails.fullDetails.title") }}
    </h6>
    <div class="full-details-wrapper">
      <template v-if="showSubtitle">
        <div class="d-flex align-center px-4 pt-4">
          <div class="subtitle1 mb-4" data-testid="tickets-page-findings-title">
            {{ $t("ticketDetails.findings") }}
          </div>
        </div>
        <v-divider></v-divider>
      </template>
      <div class="pa-4 findings-wrapper">
        <div v-if="findingsDetails?.filePath" class="mb-12">
          <div class="v-row body2">
            <div class="v-col-3">{{ $t("ticketDetails.filePath") }}:</div>
            <div class="v-col-9">{{ findingsDetails.filePath }}</div>
          </div>
          <div v-if="findingsDetails.fileSize" class="v-row body2">
            <div class="v-col-3">{{ $t("ticketDetails.fileSize") }}:</div>
            <div class="v-col-9">
              {{ convertSize(findingsDetails.fileSize, 1) }}
            </div>
          </div>
        </div>
        <template v-for="(finding, index) of findingsTemplates" :key="index">
          <div :data-testid="`tickets-page-finding-row-${index}`" v-html="finding"></div>
          <v-divider
            v-if="index !== findingsTemplates.length - 1"
            :key="finding"
            class="my-4"
          ></v-divider>
        </template>
      </div>
    </div>

    <div v-if="additionalFindings?.length" class="full-details-wrapper mt-4">
      <div class="d-flex align-center px-4 pt-4">
        <div class="subtitle1 mb-4" data-testid="tickets-page-findings-title">
          {{ $t("ticketDetails.additionalFindings") }}
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pa-4 findings-wrapper">
        <template v-for="(finding, index) of additionalFindingsTemplates" :key="index">
          <div :data-testid="`tickets-page-finding-row-${index}`" v-html="finding"></div>
          <v-divider
            v-if="index !== additionalFindingsTemplates.length - 1"
            :key="finding"
            class="my-4"
          ></v-divider>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import escape from "lodash/escape";
import { computed, defineComponent, type PropType } from "vue";
import type { Finding, TicketDetails } from "@/_store/tickets/tickets.module";
import { useI18n } from "vue-i18n";
import { convertSize } from "@/_helpers/utils";
import type { FileFinding } from "@/_store/tickets/ticket.module";

export default defineComponent({
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showSubtitle: {
      type: Boolean,
      default: true,
    },
    findingsDetails: {
      type: Object as PropType<FileFinding>,
      required: false,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const findings = computed(
      () => props.ticket?.sections?.findings || props.findingsDetails?.findings || []
    );
    const additionalFindings = computed(() => props.ticket?.sections?.additionalFindings || []);
    const findingsTemplates = computed(() => getSortedFindingTemplates(findings.value));
    const additionalFindingsTemplates = computed(() =>
      getSortedFindingTemplates(additionalFindings.value)
    );

    const createFindingTemplate = (finding: Finding) => {
      let template = "";
      const quote = escape(finding.quote);
      const longQuote = escape(finding.longQuote);
      const filePath = finding.filePath;
      const virusName = finding.virusName;
      // if finding file path has a '#' symbol then it's an archive
      if (filePath && filePath.includes("#")) {
        // split logic is used to highlight the archive name and file name
        const splittedFullPath = filePath.split("#");
        const archive = splittedFullPath.at(0);
        const splittedFilePath = splittedFullPath.at(1)?.split("/") ?? [];
        const file = splittedFilePath.pop();
        const path = splittedFilePath.length >= 1 ? splittedFilePath.concat("").join("/") : "";
        template += i18n.t("modals.dlpFindings.archivePath", {
          archive,
          path,
          file,
        });
      }
      if (finding.typeUiView) {
        template += i18n.t("modals.dlpFindings.type", {
          type: finding.typeUiView,
        });
      } else {
        template += i18n.t("modals.dlpFindings.type", {
          type: i18n.t(`ticketDetails.incidentFindings.type.${finding.type}`),
        });
      }
      if (longQuote) {
        // if quote present, highlight it, otherwise show longQuote as it is
        if (quote && longQuote.includes(quote)) {
          const highlightedTextStartIndex = longQuote.indexOf(quote);
          const highlightedTextEndIndex = longQuote.indexOf(quote) + quote.length;
          const textBeforeHighlight = longQuote.substring(0, highlightedTextStartIndex);
          const textAfterHighlight = longQuote.substring(highlightedTextEndIndex, longQuote.length);
          const highlightedText = quote;
          template =
            template +
            i18n.t("modals.dlpFindings.longQuote", {
              textBeforeHighlight,
              textAfterHighlight,
              highlightedText,
            });
        } else {
          template =
            template +
            i18n.t("modals.dlpFindings.longQuoteWithoutHighlight", {
              longQuote,
            });
        }
      }
      if (!longQuote && quote) {
        if (finding.type === "BLOCKLISTED_SENDER") {
          // Split sender finding quote and list them one by one in a column
          template += i18n.t("modals.dlpFindings.quotePlain", {
            quote: quote
              .split(" ")
              .map((v) => `"${v}"`)
              .join("<br>"),
          });
        } else {
          template += i18n.t("modals.dlpFindings.quote", { quote });
        }
      }
      if (virusName) {
        template += i18n.t("modals.dlpFindings.virusName", { virusName });
      }
      if (filePath) {
        template += i18n.t("modals.dlpFindings.fileName", {
          fileName: filePath,
        });
      }
      return template;
    };

    const getSortedFindingTemplates = (findings: Finding[]) =>
      findings
        .slice()
        .sort((a, b) => {
          // if it's archive - sort by filePath
          if (a.filePath?.includes("#")) {
            return a.filePath.localeCompare(b.filePath);
          }
          if (a.typeUiView) {
            return a.typeUiView.localeCompare(b.typeUiView!);
          }
          return a.type.localeCompare(b.type);
        })
        .map(createFindingTemplate);

    return {
      findings,
      convertSize,
      findingsTemplates,
      additionalFindingsTemplates,
      additionalFindings,
    };
  },
});
</script>

<style lang="scss" scoped>
.full-details-wrapper {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>
