<template>
  <section class="snackbar" :style="cssVars">
    <ul>
      <li
        v-for="(item, i) in stack"
        :key="item.id"
        :data-testid="item.elementId ? item.elementId : `snackbar-${i}`"
        :class="[
          {
            hidden: item.hidden,
            'recently-added': item.recentlyAdded,
            max: i === config.maxSize - 1,
          },
        ]"
      >
        <section :class="['type-' + item.type]">
          <v-icon v-if="item.type === SnackbarTypes.ERROR" class="ml-3" icon="$warning"></v-icon>
          <div :class="{ 'pl-1': item.type === SnackbarTypes.ERROR }" v-html="item.html"></div>
          <v-btn
            v-if="item.callback"
            rounded
            class="mr-3 text-primary"
            data-testid="snackbar-callback-btn"
            @click="
              item.callback!();
              remove(item.id);
            "
          >
            {{ item.callbackName }}
          </v-btn>
        </section>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { storeToRefs } from "pinia";

export default defineComponent({
  setup() {
    const snackbarStore = useSnackbarStore();
    const { remove, startRemovalByInterval } = snackbarStore;
    const cssVars = computed(() => {
      return {
        "--snackbar-speed-appear": `${config.value.speedAppear / 1000}s`,
        "--snackbar-speed-disappear": `${config.value.speedDisappear / 1000}s`,
        "--snackbar-speed-collapse": `${config.value.speedCollapse / 1000}s`,
        "--snackbar-max-size": config.value.maxSize,
      };
    });
    const { stack, config } = storeToRefs(snackbarStore);

    startRemovalByInterval();

    return {
      remove,
      cssVars,
      stack,
      config,
      SnackbarTypes,
    };
  },
});
</script>
<style lang="scss" scoped>
:deep(*) {
  .icon-warning:before {
    color: rgb(var(--v-theme-white)) !important;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes collapse {
  100% {
    max-height: 0;
  }
}

.snackbar {
  position: fixed;
  left: 80px;
  bottom: 24px;
  min-height: 1px;
  z-index: 999; // copy to clipboard toast appears when modal is open, so we need to show toast over modal
  ul {
    display: flex;
    flex-direction: column-reverse;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 358px;

    li {
      display: flex;
      flex-grow: 1;
      max-height: 68px;
      height: 68px;

      > section {
        display: flex;
        flex-grow: 1;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0;
        font-weight: normal;
        align-items: center;
        color: rgb(var(--v-theme-white));
        border-radius: 4px;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
          0 1px 18px 0 rgba(0, 0, 0, 0.12);
        margin-bottom: 12px;

        &.type-info {
          background-color: #32393f;
        }

        &.type-success {
          background-color: rgb(var(--v-theme-gray-dark));
        }

        &.type-error {
          background-color: rgb(var(--v-theme-red-dark));
        }

        &.type-warning {
          background-color: #32393f;
        }

        > div {
          flex-grow: 1;
          padding: 22px 16px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > a {
          display: flex;
          height: 100%;
          align-items: center;
          margin-left: 24px;
          font-weight: 500;
          color: rgb(var(--v-theme-white));
          text-transform: uppercase;
        }
      }

      &.hidden {
        animation: disappear var(--snackbar-speed-disappear) ease-in-out forwards;
        transition: max-height var(--snackbar-speed-collapse) ease var(--snackbar-speed-disappear);
        max-height: 0;
        opacity: 0;
      }

      &.recently-added,
      &.max:not(.hidden) {
        animation: appear var(--snackbar-speed-appear) ease-in-out forwards;
      }
    }
  }
}
</style>
