<template>
  <v-tooltip open-delay="300" location="top">
    <template #activator="{ props }">
      <div role="button" :class="{ 'not-clickable': disabled }" v-bind="props">
        <v-icon color="primary" @click="syncUsers()" icon="$sync" />
      </div>
    </template>
    {{ $t("general.tooltips.syncUsers") }}
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useUsersSettingsTabStore } from "@/_store/users-settings/users-tab.module";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const usersTabStore = useUsersSettingsTabStore();
    const { syncUsers } = usersTabStore;
    return {
      syncUsers,
    };
  },
});
</script>

<style scoped></style>
