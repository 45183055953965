<template>
  <div>
    <div class="subtitle1 mb-1">
      {{ $t("modals.exportDevices.subtitle") }}
    </div>
    <v-radio-group v-model="localValue.type" hide-details>
      <v-radio
        v-for="choice in [ExportChoice.ALL, ExportChoice.SPECIFIC]"
        :key="choice"
        class="mb-3"
        :value="choice"
        :ripple="false"
        :label="$t(`modals.exportDevices.choices.${choice}`)"
      ></v-radio>
    </v-radio-group>
    <filter-wrapper class="mt-2" :show-clear-button="false">
      <div class="d-flex flex-column">
        <v-select
          v-model="localValue.filters.labels"
          :disabled="localValue.type === ExportChoice.ALL"
          :items="labelItems"
          bg-color="white"
          menu-icon="icon-triangle"
          item-value="id"
          item-title="name"
          :menu-props="{ maxHeight: '300' }"
          class="filter filter-menu mt-2"
          :class="{ 'filter-active': localValue.filters.labels?.length }"
          :placeholder="$t('devices.filters.groups')"
          :no-data-text="$t('devices.filters.label.noData')"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
          return-object
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("devices.filters.groups") }}</span>
          </template>
          <template #label>
            <span v-if="localValue.filters.labels?.length" class="filter-label">
              {{ localValue.filters.labels.length }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.name" />
            <v-divider v-else-if="'divider' in item.raw" v-bind="props" />
            <v-list-item v-else v-bind="props" title="">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>

              <v-list-item-title class="d-flex align-center label-popup-block" title="">
                <span class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span>{{ item.raw.name }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="localValue.filters.osVersion"
          :disabled="localValue.type === ExportChoice.ALL"
          :items="osVersions"
          :menu-props="{ maxHeight: '300' }"
          class="os-version-filter filter-menu mt-2 fit"
          :class="{ 'filter-active': localValue.filters.osVersion }"
          :placeholder="$t('devices.filters.osVersion')"
          data-testid="devices-page-os-version-filter"
          density="compact"
          item-value="name"
          item-title="name"
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ item.title }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
            <v-list-item
              v-else
              :data-testid="`devices-page-os-version-filter-${item}-item`"
              v-bind="props"
            >
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="localValue.filters.clientVersion"
          :disabled="localValue.type === ExportChoice.ALL"
          :items="clientVersions"
          :menu-props="{ maxHeight: '300' }"
          class="filter filter-menu mt-2 fit"
          :class="{ 'filter-active': localValue.filters.clientVersion }"
          :placeholder="$t('devices.filters.clientVersion')"
          data-testid="devices-page-client-version-filter"
          density="compact"
          variant="outlined"
          item-value="name"
          item-title="name"
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ `Coro ${item.title}` }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
            <v-list-item
              v-else
              v-bind="props"
              :data-testid="`devices-page-client-version-filter-${item}-item`"
            >
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="localValue.filters.vulnerabilities"
          :disabled="localValue.type === ExportChoice.ALL"
          :items="Object.values(DeviceVulnerability)"
          :menu-props="{ maxHeight: '300' }"
          class="filter filter-menu mt-2"
          :class="{
            'filter-active':
              localValue.filters.vulnerabilities && localValue.filters.vulnerabilities.length,
          }"
          :placeholder="$t('devices.filters.vulnerability')"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("devices.filters.vulnerability") }}</span>
          </template>
          <template #label>
            <span v-if="localValue.filters.vulnerabilities?.length" class="filter-label">{{
              localValue.filters.vulnerabilities.length
            }}</span>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" :title="$t(`tickets.eventTriggers.${camelCase(item.raw)}`)">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-select>
      </div>
    </filter-wrapper>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import { ExportChoice, type ExportDialogPayload } from "@/_store/exports.module";
import { defaultFiltersState, type DevicesFilters } from "@/_store/filters.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { DeviceVulnerability, OsType } from "@/constants/devices";
import { camelCase } from "lodash";
import { useDevicesStore } from "@/_store/devices.module";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { FilterWrapper },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const devicesStore = useDevicesStore();
    const devicesSettingsStore = useDevicesSettingsStore();
    const osVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const clientVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const { osVersions: osVersionsFromStore, clientVersions: clientVersionsFromStore } =
      storeToRefs(devicesStore);

    const localValue = ref<ExportDialogPayload<DevicesFilters>>({
      type: ExportChoice.ALL,
      filters: {
        ...defaultFiltersState.devicesFilters,
      },
    });

    const labelItems = computed(() => {
      const labels = devicesSettingsStore.labels ?? [];
      const statuses = devicesStore.statuses ?? [];

      const result = [];
      if (statuses.length) {
        result.push({ subheader: true, name: i18n.t("devices.filters.status") }, ...statuses, {
          divider: true,
        });
      }
      result.push({ subheader: true, name: i18n.t("devices.filters.labels") }, ...labels);
      return result;
    });

    onMounted(async () => {
      await devicesStore.init();
      populateVersionsList();
    });

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );

    function populateVersionsList() {
      const osTypesForVersions = [OsType.WINDOWS, OsType.OSX, OsType.IOS, OsType.ANDROID] as const;
      osVersions.value = [];
      clientVersions.value = [];
      osTypesForVersions.forEach((neededVersion) => {
        const lowercaseVersion = neededVersion.toLowerCase() as Lowercase<
          Exclude<OsType, OsType.MAC_OS>
        >;
        if (osVersionsFromStore.value[lowercaseVersion]?.length) {
          osVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...osVersionsFromStore.value[lowercaseVersion]!.map((v) => ({ name: v }))
          );
        }

        if (clientVersionsFromStore.value?.[lowercaseVersion]?.length) {
          clientVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...clientVersionsFromStore.value[lowercaseVersion]!.slice()
              .sort()
              .reverse()
              .map((v) => ({ name: v }))
          );
        }
      });
    }

    return {
      localValue,
      ExportChoice,
      DeviceVulnerability,
      camelCase,
      labelItems,
      osVersions,
      clientVersions,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-menu {
  width: 240px !important;
}
</style>
