import isEmpty from "lodash/isEmpty";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";

interface PrivacySensitiveDataSettings {
  monitorPII: boolean;
  monitorPCI: boolean;
  monitorPHI: boolean;
  monitorNPI: boolean;
}

interface PrivacySensitiveDataState {
  settings: PrivacySensitiveDataSettings;
  showSkeletonLoader: boolean;
}

export const defaultPrivacySensitiveDataState = {
  settings: {
    monitorPII: false,
    monitorPCI: false,
    monitorPHI: false,
    monitorNPI: false,
  },
  showSkeletonLoader: false,
};

export const usePrivacySensitiveDataStore = defineStore("privacySensitiveData", {
  state: (): PrivacySensitiveDataState => ({
    ...defaultPrivacySensitiveDataState,
  }),
  getters: {
    deviceSettingsDisabled(state) {
      const deviceSettings = state.settings;
      return (
        !isEmpty(deviceSettings) &&
        !(
          deviceSettings.monitorNPI ||
          deviceSettings.monitorPCI ||
          deviceSettings.monitorPHI ||
          deviceSettings.monitorPII
        )
      );
    },
  },
  actions: {
    async getSettings() {
      this.showSkeletonLoader = true;

      const request = {
        ...api.endpointDataGovernanceSettings,
      };

      try {
        const { data } = await axiosInstance.request(request);

        this.settings = data;
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },
    async updateSettings(newSettings: PrivacySensitiveDataSettings) {
      const request = {
        ...api.endpointDataGovernanceSettings,
        method: "put",
        data: newSettings,
      };

      try {
        await axiosInstance.request(request);

        this.settings = newSettings;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
