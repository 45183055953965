<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("accessControlPage.title") }}
      </div>
      <div>
        <v-btn
          color="primary"
          rounded
          elevation="0"
          size="large"
          class="add-btn"
          data-testid="invite-admin-button"
          v-if="showAddButton(RouteName.ACCESS_CONTROL_ADMIN_USERS_TAB)"
          @click="addAdmin()"
        >
          <v-icon color="white" class="mr-2" icon="$add"></v-icon>
          <span>{{ $t("accessControlPage.adminUsers.addAdmin") }}</span>
        </v-btn>
        <v-btn
          color="primary"
          rounded
          elevation="0"
          size="large"
          class="add-btn"
          data-testid="invite-admin-button"
          v-else-if="showAddButton(RouteName.ACCESS_CONTROL_ROLES_TAB)"
          @click="openAddRoleDialog()"
        >
          <v-icon color="white" class="mr-2" icon="$add"></v-icon>
          <span>{{ $t("accessControlPage.roles.table.actions.addRole") }}</span>
        </v-btn>
      </div>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useRoute } from "vue-router";
import {
  componentDialogsConfigConstructor,
  isAccessRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { AdminUsersAction } from "@/constants/admin-accounts";
import AddAdminUserModal from "@/components/modals/AddAdminUserModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useAdminUsersStore } from "@/_store";
import {
  RolePermissionsScope,
  useRolesStore,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import AddRoleModal from "@/components/modals/add-role/AddRoleModal.vue";
import { emptyPermissions, RolesAction, RoleType } from "@/constants/roles";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const adminAccountStore = useAdminUsersStore();
    const rolesStore = useRolesStore(RoleType.WORKSPACE);
    const route = useRoute();
    const dialogsStore = useDialogsStore();

    const tabs = computed<CoroTab[]>(() => {
      const sections = [
        {
          id: 1,
          name: i18n.t("accessControlPage.adminUsers.title"),
          route: { name: RouteName.ACCESS_CONTROL_ADMIN_USERS_TAB },
          permissionScope: WorkspaceManagementScopeSections.ADMIN_USERS,
        },
        {
          id: 2,
          name: i18n.t("accessControlPage.roles.title"),
          route: { name: RouteName.ACCESS_CONTROL_ROLES_TAB },
          permissionScope: WorkspaceManagementScopeSections.ROLES,
        },
        {
          id: 3,
          name: i18n.t("accessControlPage.activeSessions.title"),
          route: { name: RouteName.ACCESS_CONTROL_ACTIVE_SESSIONS_TAB },
          permissionScope: WorkspaceManagementScopeSections.ACTIVE_SESSIONS,
        },
      ];

      return sections
        .filter(
          ({ permissionScope }) =>
            !isAccessRestricted(RolePermissionsScope.WORKSPACE_MANAGEMENT, permissionScope)
        )
        .map(({ permissionScope, ...tab }) => ({ ...tab }));
    });

    const addAdmin = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: AdminUsersAction.ADD,
        component: AddAdminUserModal,
        width: ModalWidth.LARGE,
        callback: adminAccountStore.addAdminUser,
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.ADMIN_USERS,
          "add"
        ),
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const openAddRoleDialog = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: RolesAction.ADD,
        item: { ...emptyPermissions },
        component: AddRoleModal,
        width: ModalWidth.LARGE,
        callback: rolesStore.addRole,
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.ROLES
        ),
      });
      dialogsStore.openDialog({ ...dialogConfig, scrollable: true });
    };

    const showAddButton = (
      routeName: RouteName.ACCESS_CONTROL_ADMIN_USERS_TAB | RouteName.ACCESS_CONTROL_ROLES_TAB
    ) => {
      return route.name === routeName;
    };

    return {
      tabs,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      showAddButton,
      addAdmin,
      openAddRoleDialog,
      RouteName,
    };
  },
});
</script>

<style scoped></style>
