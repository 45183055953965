/**
 * @deprecated Please not use this use ModalWidth instead
 */
export const modalWidthByType = {
  ADD_LABEL: 319,
  LIST: 440,
  TEXT: 449,
  TEXT_SMALL: 450,
  SERVICE: 550,
  GROUP: 592,
  CONNECT_APP: 752,
  RESTRICTION: 762,
  TERMINAL: 900,
  GPO: 762,
  FILTER: 1120,
  ADD_GROUP: 752,
  EMAIL_ACTION: 550,
  ADD_ALL_USERS: 762,
  ADD_NEW_EXCEPTION: 762,
  DELETE_WORKSPACE: 450,
  CREATE_WORKSPACE: 760,
  EDIT_WORKSPACE: 760,
  COPY_INVITE_LINK: 700,
  ADD_GLOBAL_ADMIN: 592,
  IMPORT_USERS_FROM_CSV: 580,
  ADD_EDIT_VO_FIREWALL: 762,
  ADD_TUNNEL: 763,
  ADD_API_KEY: 592,
};

export enum ModalWidth {
  SMALL = 450,
  MEDIUM = 592,
  LARGE = 762,
}

export const modalContentHeightByType = {
  LIST: 480,
  ADD_NEW_RULE: 490,
  ADD_WORKPLACE: 448,
};
