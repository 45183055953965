<template>
  <div>
    <div class="headline6 mb-1" data-testid="ticket-preview-title">
      {{ $t("ticketDetails.users") }}
    </div>

    <div class="mb-10">
      <div data-testid="ticket-preview-users">
        <span class="body1 mr-1" data-testid="ticket-preview-recipient">{{ recipients[0] }}</span>
        <span
          v-if="recipients.length > 1"
          role="button"
          data-testid="ticket-preview-plus-n-btn"
          class="plus-one-text"
          @click="openListModal(TicketsModal.RECIPIENTS, recipients)"
          >&nbsp;+{{ recipients.length - 1 }}</span
        >
      </div>
      <div v-if="showAddToProtectionBtn">
        <div
          class="body2 text-red-dark mb-3"
          data-testid="ticket-preview-potentially-protectable-users"
        >
          {{
            $t(
              "ticketDetails.notProtectedUsersNotice",
              { n: ticketDetails.potentiallyProtectableUsers.length },
              ticketDetails.potentiallyProtectableUsers.length
            )
          }}
        </div>
        <v-btn
          data-testid="tickets-page-add-users-btn"
          outlined
          rounded
          :disabled="addUsersNotAllowed"
          @click="addUsers(usersToAdd)"
        >
          {{ $t("ticketDetails.addProtectionBtn") }}
        </v-btn>
      </div>
    </div>

    <div class="headline6 mb-1">{{ $t("ticketDetails.when") }}</div>
    <div class="body1 mb-10" data-testid="ticket-preview-when">
      {{ format(ticketDetails.endTime, "MMM, DD YYYY, h:mm A") }}
    </div>

    <div class="headline6 mb-4">{{ $t("general.service") }}</div>
    <div class="d-flex align-center mb-4">
      <v-icon
        v-if="ticketDetails.sections.serviceName === Service.PROXY"
        size="40"
        class="mr-2"
        icon="$accessRestrictions"
      >
      </v-icon>
      <coro-icon v-else class="service-icon mr-2" :icon-name="ticketDetails.sections.serviceName" />
      <div
        class="subtitle2"
        :data-testid="`ticket-preview-service-${ticketDetails.sections.serviceName}`"
      >
        {{ serviceNameTitle }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { useTicketPreviewGeneralInfo } from "@/composables/useTicketPreviewGeneralInfo";
import intersection from "lodash/intersection";
import { arrayToLowerCase } from "@/_helpers/utils";
import { useI18n } from "vue-i18n";
import { Service } from "@/constants/cloud-apps";
import { TicketsModal, TicketType } from "@/constants/tickets";
import { ProxyDirection } from "@/constants/email-proxy";
import CoroIcon from "@/components/CoroIcon.vue";

export default defineComponent({
  components: { CoroIcon },
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const { format, addUsersNotAllowed, usersToAdd, addUsers, openListModal } =
      useTicketPreviewGeneralInfo(props.ticketDetails);

    const recipients = computed(() => props.ticketDetails.sections?.emailMetaData.recipients ?? []);

    const showAddToProtectionBtn = computed(() => {
      // if processed and not a white ticket, then the action button will be visible (`Re-open ticket`)
      const showActionBtn = props.ticketDetails.actions?.length;

      if (!showActionBtn) return false;

      if (
        props.ticketDetails.eventType === TicketType.EMAIL_PHISHING &&
        props.ticketDetails.potentiallyProtectableUsers?.length
      ) {
        return true;
      }

      return (
        intersection(
          arrayToLowerCase(recipients.value),
          arrayToLowerCase(props.ticketDetails.potentiallyProtectableUsers)
        ).length > 0
      );
    });

    const serviceNameTitle = computed(() => {
      const serviceName = i18n.t(`services.${props.ticketDetails.sections.serviceName}`);
      if (props.ticketDetails.sections.serviceName === Service.PROXY) {
        const direction =
          props.ticketDetails.sections.emailMetaData?.emailProxyDirection === ProxyDirection.INBOUND
            ? i18n.t("emailProxy.directions.inbound")
            : i18n.t("emailProxy.directions.outbound");

        return `${direction} ${serviceName}`;
      }
      return serviceName;
    });

    return {
      format,
      addUsersNotAllowed,
      usersToAdd,
      addUsers,
      recipients,
      showAddToProtectionBtn,
      serviceNameTitle,
      openListModal,
      TicketsModal,
      Service,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  width: 40px;
  height: 40px;
}
</style>
