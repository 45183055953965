import {
  GlobalRoleScopes,
  type MspChannelAdminsEditAccessModePermission,
  type MspPortalEditAccessModePermission,
  type MspPortalScope,
  type MspRolesEditAccessModePermission,
  type NestedMspPermissions,
} from "@/_store/roles.module";
import { RolePermissionAccessMode } from "@/constants/roles";
import { useAccountStore } from "@/_store";
import { toRaw } from "vue";
import { isGlobalAccessRestricted, isGlobalActionRestricted } from "@/_helpers/global-permissions";

export function isMspActionRestricted<
  K extends keyof MspPortalScope,
  V extends MspPortalScope[K] extends NestedMspPermissions
    ? NonNullable<MspPortalScope[K]["editAccessModePermission"]>
    : undefined
>(key: NonNullable<K>, nestedPermissionKey?: keyof V) {
  const accountStore = useAccountStore();
  const mspWorkspaceIds = Object.keys(toRaw(accountStore.account.mspPortalPermissionsMap));
  return (
    mspWorkspaceIds.every((id) =>
      isMspActionRestrictedForWorkspace(id, key, nestedPermissionKey)
    ) &&
    isGlobalActionRestricted<
      GlobalRoleScopes.MSP_PORTAL_SCOPE,
      keyof MspPortalScope,
      keyof (
        | MspPortalEditAccessModePermission
        | MspChannelAdminsEditAccessModePermission
        | MspRolesEditAccessModePermission
      )
    >(GlobalRoleScopes.MSP_PORTAL_SCOPE, key, nestedPermissionKey)
  );
}

export function isMspAccessRestricted<K extends keyof MspPortalScope = keyof MspPortalScope>(
  key: K
): boolean {
  const accountStore = useAccountStore();
  const mspWorkspaceIds = Object.keys(toRaw(accountStore.account.mspPortalPermissionsMap));
  return (
    mspWorkspaceIds.every((id) => isMspAccessRestrictedForWorkspace(id, key)) &&
    isGlobalAccessRestricted<GlobalRoleScopes.MSP_PORTAL_SCOPE, keyof MspPortalScope>(
      GlobalRoleScopes.MSP_PORTAL_SCOPE,
      key
    )
  );
}

export function isMspActionRestrictedForWorkspace<
  K extends keyof MspPortalScope,
  V extends MspPortalScope[K] extends NestedMspPermissions
    ? NonNullable<MspPortalScope[K]["editAccessModePermission"]>
    : undefined
>(workspaceId: string, key: NonNullable<K>, nestedPermissionKey?: keyof V) {
  const permissions = getMspPermissionsByKeyAndWorkspaceId(workspaceId, key);
  // Filter case when permissions doesn't exist
  if (!permissions) {
    return isGlobalActionRestricted<
      GlobalRoleScopes.MSP_PORTAL_SCOPE,
      keyof MspPortalScope,
      keyof (
        | MspPortalEditAccessModePermission
        | MspChannelAdminsEditAccessModePermission
        | MspRolesEditAccessModePermission
      )
    >(GlobalRoleScopes.MSP_PORTAL_SCOPE, key, nestedPermissionKey);
  }

  // Filter case when permissions is just string
  if (typeof permissions === "string") {
    return permissions !== RolePermissionAccessMode.EDIT;
  }

  // Filter case when permissions is an object of such shape
  // {
  //   accessMode: RolePermissionAccessMode;
  //   editAccessModePermission?: {[key: string]: boolean}
  // };

  if (typeof permissions === "object") {
    // If no key provided, then check if it's edit or not
    if (!nestedPermissionKey) {
      return permissions.accessMode !== RolePermissionAccessMode.EDIT;
    }

    // If key is provided, then check the particular action
    if (
      nestedPermissionKey &&
      permissions.accessMode === RolePermissionAccessMode.EDIT &&
      permissions.editAccessModePermission
    ) {
      return permissions.editAccessModePermission
        ? // @ts-ignore
          !permissions.editAccessModePermission[nestedPermissionKey]
        : true;
    }
  }

  return true;
}
function isMspAccessRestrictedForWorkspace<K extends keyof MspPortalScope = keyof MspPortalScope>(
  workspaceId: string,
  key: K
) {
  const permissions = getMspPermissionsByKeyAndWorkspaceId(workspaceId, key);
  if (!permissions) return true;

  if (typeof permissions === "string") {
    return permissions === RolePermissionAccessMode.NO_ACCESS;
  }
  return permissions.accessMode === RolePermissionAccessMode.NO_ACCESS;
}

export function getMspPermissionsByKeyAndWorkspaceId<
  K extends keyof MspPortalScope = keyof MspPortalScope
>(workspaceId: string, key: K) {
  const accountStore = useAccountStore();
  const mspPermissions: MspPortalScope | undefined = toRaw(
    accountStore.account.mspPortalPermissionsMap[workspaceId]
  );
  return mspPermissions?.[key] as NestedMspPermissions | RolePermissionAccessMode | undefined;
}
