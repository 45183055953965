<template>
  <v-form ref="form" v-model="valid">
    <label class="subtitle2">
      {{ $t("modals.addToAllowlist.addToListLabel") }}
    </label>
    <v-combobox
      v-model="localValue.items"
      data-testid="add-user-modal-combobox"
      class="mt-3 coro-scrollable-combobox"
      :label="$t('modals.addToAllowlist.label')"
      :placeholder="$t('modals.addToAllowlist.placeholder')"
      :rules="emailDomainRules"
      variant="outlined"
      multiple
      no-filter
      hide-no-data
      @update:search="onComboboxInput()"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :closable="true"
          variant="flat"
          size="default"
          :color="getChipColor(item.title)"
          close-icon="$closeCircle"
          :text="item.title"
        >
          <div class="d-flex align-center">
            <span class="ml-1 mr-2">{{ item.title }}</span>
          </div>
        </v-chip>
      </template>
      <template #item="{ item }">
        <v-list-item :data-testid="`item-${camelCase(item.title)}`">
          <span class="ml-1">{{ item.title }}</span>
        </v-list-item>
      </template>
    </v-combobox>
    <v-checkbox
      v-model="localValue.senderClassificationTypes"
      :value="AllowListSubType.SUSPICIOUS_CONTENT"
      :ripple="false"
      class="mt-4"
      hide-details
      multiple
      :rules="checkboxRules"
      :label="
        $t('modals.addToAllowlist.suspiciousContentList', {
          listType: getListTypeText,
        })
      "
    />
    <v-checkbox
      v-model="localValue.senderClassificationTypes"
      :value="AllowListSubType.AUTH_FAILURE"
      :ripple="false"
      class="mt-1"
      multiple
      :hide-details="false"
      :rules="checkboxRules"
      :label="
        $t('modals.addToAllowlist.authList', {
          listType: getListTypeText,
        })
      "
    />
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import type { Ref } from "vue";
import Patterns from "@/constants/patterns";
import { useI18n } from "vue-i18n";
import { AllowListSubType } from "@/constants/phishing";
import { EmailError } from "@/constants/emails";
import isEqual from "lodash/isEqual";
import { hasSeparator } from "@/_helpers/utils";
import { camelCase, uniq } from "lodash";
import type { VuetifyFormRef } from "@/types";
import type { AllowBlockListForm } from "@/_store/email-security/email-settings.module";

export default defineComponent({
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const form = ref<VuetifyFormRef>();
    const localValue: Ref<AllowBlockListForm> = ref({
      items: [],
      action: props.config.action,
      senderClassificationTypes: [AllowListSubType.SUSPICIOUS_CONTENT],
      errors: [],
    });
    const valid = ref(true);
    const previousEmailOrDomainValues: Ref<string[]> = ref([]);

    const emailDomainRules = [
      (values: string[]) => {
        if (!values.length) {
          return i18n.t("validations.required");
        }

        for (const value of values) {
          if (
            !(Patterns.EMAIL.test(value) || Patterns.DOMAIN.test(value) || Patterns.IP.test(value))
          ) {
            return i18n.t("validations.emailOrDomainOrIp");
          }
        }

        if (
          localValue.value.errors?.includes(EmailError.GLOBAL_DOMAINS_NOT_SUPPORTED) ||
          isEqual(previousEmailOrDomainValues.value, values)
        ) {
          previousEmailOrDomainValues.value = values;
          localValue.value.errors = [];
          return i18n.t("errors.globalDomainsNotSupported");
        }

        return true;
      },
    ];

    const getListTypeText = computed(() => {
      return props.config.item?.allowList
        ? i18n.t("phishingSettings.allow").toLowerCase()
        : i18n.t("phishingSettings.block").toLowerCase();
    });

    const onComboboxInput = () => {
      const previousValues = localValue.value.items;
      const lastKeyword = previousValues?.at(-1);
      const hasCommaSeparator = hasSeparator(lastKeyword!);

      if (hasCommaSeparator) {
        const split = lastKeyword!.split(",");

        localValue.value.items = uniq(
          [...localValue.value.items.slice(0, -1), ...split].map((item) =>
            item.toLowerCase().trim()
          )
        );
      }
    };

    const checkboxRules = [
      () => {
        if (!localValue.value.senderClassificationTypes.length) {
          return i18n.t("validations.pleaseSelectAtLeastOneOption");
        }
        return true;
      },
    ];

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    watch(valid, (value) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    const validate = async () => {
      const validationResult = await form.value?.validate();
      return validationResult?.valid;
    };

    const getChipColor = (value: string) => {
      const isValid =
        Patterns.EMAIL.test(value) || Patterns.DOMAIN.test(value) || Patterns.IP.test(value);

      return isValid ? "indigo-faint" : "error";
    };

    return {
      validate,
      form,
      valid,
      localValue,
      previousEmailOrDomainValues,
      emailDomainRules,
      AllowListSubType,
      getChipColor,
      camelCase,
      getListTypeText,
      onComboboxInput,
      checkboxRules,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-input--checkbox .v-messages {
    display: block;
  }
}
</style>
