<template>
  <div class="content-sm margin-auto" data-testid="reset-password-page-container">
    <div class="d-flex justify-center mt-16">
      <div class="reset-password-content">
        <v-card width="400" v-if="!account.requestCompleted">
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <div class="headline5">{{ $t("resetPassword.title") }}</div>
              <div class="body1 text-indigo-medium mt-8 mb-8">
                {{ $t("resetPassword.description") }}
              </div>
              <!--Text field with email is present here for google chrome's update password functionality. Without this field
              Chrome will not know to which user to update password.-->
              <v-text-field
                v-model="account.email"
                dense
                placeholder="Email"
                type="email"
                class="d-none"
              ></v-text-field>
              <div v-if="account.error" class="error-block d-flex mb-6 mt-3 align-center w-100">
                <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
                <span class="body2 text-red-dark">
                  {{ $t("general.pleaseCorrectErrors") }}
                </span>
              </div>
              <v-text-field
                v-model="newPassword"
                :disabled="account.requestInProgress"
                :append-inner-icon="showNewPassword ? 'icon-eye' : 'icon-eye-off'"
                class="mt-1"
                :placeholder="$t('forms.password.placeholder')"
                :label="$t('resetPassword.newPassword')"
                :rules="passwordRules"
                hide-details="auto"
                :type="showNewPassword ? 'text' : 'password'"
                data-testid="reset-password-password-input"
                @click:appendInner="showNewPassword = !showNewPassword"
                @keydown.enter="onResetPasswordClick()"
              ></v-text-field>
              <password-strength-meter class="mt-2" :password="newPassword" />
              <div v-if="!!account.error" class="text-error caption mb-3">
                {{ $t(`resetPassword.${account.error}`) }}
              </div>
              <v-btn
                size="large"
                rounded
                color="primary"
                class="mb-5 mt-3"
                :loading="account.requestInProgress"
                data-testid="reset-password-reset-btn"
                @click="onResetPasswordClick()"
              >
                {{ $t("resetPassword.resetPasswordBtn") }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <div v-else class="d-flex align-center flex-column text-center">
          <v-icon class="mb-3" icon="$resetPassword" size="90" />
          <div class="headline5">
            {{ $t("resetPassword.successText") }}
          </div>
          <router-link v-if="logged" :to="{ name: RouteName.DASHBOARD }">
            <v-btn size="large" rounded class="mt-10" color="primary">
              {{ $t("forgotPassword.backToDashboardBtn") }}
            </v-btn>
          </router-link>
          <router-link v-else :to="{ name: RouteName.LOGIN }">
            <v-btn size="large" rounded class="mt-10" color="primary">
              {{ $t("forgotPassword.backToLoginBtn") }}
            </v-btn>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useAccountStore } from "@/_store";
import { emailRule } from "@/_helpers/validators";
import { storeToRefs } from "pinia";
import PasswordStrengthMeter from "@/components/PasswordStrengthMeter.vue";
import Patterns from "@/constants/patterns";
import { useI18n } from "vue-i18n";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  components: { PasswordStrengthMeter },
  setup(props) {
    const accountStore = useAccountStore();
    const i18n = useI18n();
    const { setRequestInProgress, setRequestCompleted, setError, resetPassword } = accountStore;
    const { account, logged } = storeToRefs(accountStore);
    const newPassword = ref();
    const showNewPassword = ref(false);
    const showValidationAlert = ref(false);
    const valid = ref(true);
    const form = ref();

    watch(
      () => newPassword.value,
      () => {
        setError("");
        setRequestInProgress(false);
      }
    );

    onMounted(() => {
      setError("");
      setRequestInProgress(false);
      setRequestCompleted(false);
    });

    const onResetPasswordClick = async () => {
      setError("");
      const { valid } = (await form.value.validate()) ?? { valid: false };

      if (!valid) {
        showValidationAlert.value = !valid;
      } else {
        await resetPassword({
          token: props.token,
          newPassword: newPassword.value,
        });
      }
    };

    return {
      RouteName,
      valid,
      form,
      newPassword,
      showNewPassword,
      showValidationAlert,
      account,
      emailRule,
      onResetPasswordClick,
      logged,
      passwordRules: [
        (password: string) => {
          if (!password || password.length <= 0) {
            return i18n.t("validations.required");
          }
          if (!Patterns.PASSWORD.test(password)) {
            return i18n.t("validations.password");
          }
          return true;
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
.reset-password-content {
  max-width: 370px;
  min-width: 300px;
  width: 370px;
}
a {
  text-decoration: none;
}
</style>
