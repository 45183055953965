<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <div>
      <div class="subtitle1 mb-4">{{ $t("modals.addRole.roleName") }}</div>
      <v-text-field
        v-model.trim="localValue.item.name"
        class="mb-6"
        variant="outlined"
        :rules="[required()]"
        :label="$t('modals.addRole.role')"
        :placeholder="$t('modals.addRole.role')"
        rounded="lg"
      ></v-text-field>
      <div class="headline6 mb-6">
        {{ $t("modals.addRole.workspacePermissions") }}
      </div>
      <add-role-modal-section
        v-for="scope in workspaceRolesSectionScopes"
        class="mb-6"
        :item="localValue.item"
        :scopeName="scope"
        :key="scope"
        :empty-permissions-object="emptyPermissions"
        @update:item="localValue.item = $event"
      />
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { required } from "@/_helpers/validators";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { type RoleDetails, RolePermissionsScope } from "@/_store/roles.module";
import AddRoleModalSection from "@/components/modals/add-role/AddRoleModalSection.vue";
import { emptyPermissions } from "@/constants/roles";

export default defineComponent({
  components: { AddRoleModalSection },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<RoleDetails>>,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const localValue = ref({
      item: cloneDeep(props.config.item),
      action: props.config.action,
    });
    const valid = ref(true);
    const form = ref();

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );
    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const workspaceRolesSectionScopes = [
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      RolePermissionsScope.VIEWS,
      RolePermissionsScope.PROTECTION,
      RolePermissionsScope.TICKETS,
    ];

    return {
      localValue,
      valid,
      form,
      required,
      validate,
      workspaceRolesSectionScopes,
      emptyPermissions,
    };
  },
});
</script>

<style lang="scss" scoped></style>
