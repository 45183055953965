<template>
  <div>
    <div class="mb-4">
      {{ $t("connectors.psa.addPsaConnector.gradient.description") }}
    </div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="localValue.item.partnerApiKey"
        data-testid="add-psa-connector-modal-textfield"
        class="mt-3 coro-scrollable-combobox"
        :label="$t('connectors.psa.addPsaConnector.gradient.label')"
        :placeholder="$t('connectors.psa.addPsaConnector.gradient.placeholder')"
        :rules="[required()]"
        outlined
      >
      </v-text-field>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import type { GradientConnectionPayload } from "@/_store/connectors/psa.module";
import cloneDeep from "lodash/cloneDeep";
import { required } from "@/_helpers/validators";

const defaultGradientItem: GradientConnectionPayload = {
  partnerApiKey: "",
};

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["cancel", "update:value", "update:valid"],
  setup(props, { emit }) {
    const valid = ref(false);
    const localValue = ref({
      item: cloneDeep(props.config.item || defaultGradientItem),
    });

    watch(
      localValue,
      (value) => {
        emit("update:value", value.item);
      },
      { deep: true }
    );

    watch(
      valid,
      (newVal) => {
        if (newVal === null) {
          return;
        }

        emit("update:valid", newVal);
      },
      { immediate: false }
    );

    return {
      valid,
      required,
      localValue,
      loading: false,
    };
  },
});
</script>
