<template>
  <v-card class="h-100">
    <v-card-text>
      <div class="ml-4 mt-3 mr-4">
        <v-checkbox
          v-model="localValue.wifiPhishingEnabled"
          data-testid="device-settings-wifi-phishing-checkbox"
          :ripple="false"
          class="mt-1"
          :label="$t('devicesSettings.settingsCard.wifiPhishing')"
          :disabled="actionNotAllowed"
        />
        <div class="body2 ml-7">
          {{ $t("devicesSettings.settingsCard.wifiPhishingDescription") }}
        </div>
        <div class="body1 mt-4 ml-7" :class="{ 'coronet-neutral-darken1--text': actionNotAllowed }">
          {{ $t("devicesSettings.settingsCard.applyToDevices") }}
        </div>
        <v-autocomplete
          v-model="localValue.wifiPhishingLabels"
          class="mt-4 ml-7"
          :items="labels"
          :label="deviceLabelsPlaceholder(localValue.wifiPhishingLabels?.length)"
          :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
          :disabled="actionNotAllowed"
          variant="outlined"
          multiple
          return-object
          data-testid="device-settings-wifi-phishing-labels"
          item-value="id"
          item-text="name"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              :closable="true"
              variant="flat"
              size="default"
              :color="item.raw.color"
              :disabled="actionNotAllowed"
              close-icon="$closeCircle"
              :text="item.raw.name"
            >
              <div class="d-flex align-center">
                <span class="ml-1 mr-2">{{ item.raw.name }}</span>
              </div>
            </v-chip>
          </template>

          <template #item="{ item, props }">
            <v-list-item v-bind="props" title="" class="d-flex align-center label-popup-block">
              <span
                class="color-preview mr-2 mb-0"
                :style="{ 'background-color': item.raw.color }"
              />
              <span
                :data-testid="`device-settings-wifi-phishing-${kebabCase(
                  item.raw.name
                )}-select-item`"
              >
                {{ item.raw.name }}
              </span>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";
import kebabCase from "lodash/kebabCase";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import type { WifiPhishingSettings } from "@/_store/devices-settings.module";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionAddon } from "@/constants/workplaces";
import { useI18n } from "vue-i18n";
import isEqual from "lodash/isEqual";

export default defineComponent({
  setup() {
    const localValue: Ref<WifiPhishingSettings> = ref({} as WifiPhishingSettings);
    const i18n = useI18n();

    const devicesSettingsStore = useDevicesSettingsStore();
    const { getWifiPhishingSettings, updateWifiPhishingSettings, getLabels } = devicesSettingsStore;
    const { wifiPhishingSettings, labels } = storeToRefs(devicesSettingsStore);

    watch(wifiPhishingSettings, (val) => {
      localValue.value = cloneDeep(val);
    });

    watch(
      localValue,
      async (newVal) => {
        if (!isEqual(newVal, wifiPhishingSettings.value)) {
          await updateWifiPhishingSettings(newVal);
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      await getLabels();
      await getWifiPhishingSettings();
    });

    const deviceLabelsPlaceholder = (labelsLength: number) => {
      return labelsLength ? i18n.t("devicesSettings.settingsCard.deviceLabelsPlaceholder") : "";
    };
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionAddon.WIFI_PHISHING
      );
    });

    return {
      kebabCase,
      actionNotAllowed,
      labels,
      deviceLabelsPlaceholder,
      localValue,
    };
  },
});
</script>

<style lang="scss">
.label-popup-block {
  .v-list-item__content {
    display: flex;
    align-items: center;
  }
}
</style>
