<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" divider=""></v-breadcrumbs>
    <v-skeleton-loader :loading="loading" :type="coronetSkeletonLoaderTypes.USERS_SETTINGS">
      <div class="settings-header-with-btn">
        <div class="headline5 mb-2 mt-4 d-flex align-center">
          <coro-icon :icon-name="service" class="service-icon--large mr-1"></coro-icon>
          <span>{{
            $t("accessPermissions.title", {
              service: $t(`services.${service}`),
            })
          }}</span>
        </div>
        <v-menu v-if="permissions.length > 0" offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              color="primary"
              rounded
              size="large"
              class="add-btn"
              data-testid="access-restrictions-page-add-btn"
              v-bind="props"
            >
              <v-icon color="white" icon="$add" class="mr-2"></v-icon>
              <span>{{ $t("accessPermissions.addRestriction") }}</span>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="menuItem in addMenuItems"
              :key="menuItem.type"
              @click="onAddActionClick(menuItem)"
            >
              <v-list-item-title data-testid="access-restrictions-add-all-users-action">
                {{ $t(`accessPermissions.types.${menuItem.type}`) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <template v-if="permissions.length > 0">
        <div class="body1 mb-4">
          {{
            $t("accessPermissions.description", {
              service: $t(`services.${service}`),
            })
          }}
        </div>
        <table-wrapper class="access-restrictions-table__wrapper">
          <v-table class="white">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left caption">
                    {{ $t("accessPermissions.table.headers.groups") }}
                  </th>
                  <th class="text-left caption">
                    {{ $t("accessPermissions.table.headers.restrictions") }}
                  </th>
                  <th class="text-left caption">
                    {{ $t("accessPermissions.table.headers.description") }}
                  </th>
                  <th class="text-left caption">
                    {{ $t("accessPermissions.table.headers.remediation") }}
                  </th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in permissions"
                  :key="item.restrictionId"
                  class="service-row"
                >
                  <td class="content-row">
                    <span v-if="item.monitoredScope.type === 'all'">
                      <coro-icon icon-name="coronet" class="service-icon mr-2" />
                      <span class="subtitle2 vertical-align-top">{{
                        $t("accessPermissions.table.allUsers")
                      }}</span>
                    </span>
                    <template v-else-if="item.monitoredScope.type === 'group'">
                      <span
                        v-for="(group, groupIndex) in item.monitoredScope.groups"
                        :key="group.groupId"
                      >
                        <span v-if="groupIndex !== 0" class="body2 vertical-align-top mr-2">,</span>
                        <br v-if="groupIndex !== 0 && groupIndex % 2 === 0" />
                        <coro-icon :icon-name="group.serviceName" class="service-icon mr-2" />
                        <span class="subtitle2 vertical-align-top">{{ group.groupName }}</span>
                      </span>
                    </template>
                    <template v-else>
                      <span
                        v-for="(user, userIndex) in item.monitoredScope.users"
                        :key="user.userId"
                      >
                        <span v-if="userIndex !== 0" class="body2 vertical-align-top mr-2">, </span>
                        <br v-if="userIndex !== 0 && userIndex % 2 === 0" />
                        <span class="subtitle2 vertical-align-top">{{ user.email }}</span>
                      </span>
                    </template>
                  </td>
                  <td class="content-row">
                    <template v-if="item.restrictions.type === 'country'">
                      <span
                        v-for="(country, countryIndex) in item.restrictions.allowedCountries"
                        :key="country"
                      >
                        <span v-if="countryIndex !== 0" class="body2">, </span>
                        <br v-if="countryIndex !== 0 && countryIndex % 2 === 0" />
                        <span class="body2">{{ getCountryOrStateName(country) }}</span>
                      </span>
                    </template>
                    <template v-else>
                      <span v-for="(ip, ipIndex) in item.restrictions.allowedIps" :key="ip">
                        <span v-if="ipIndex !== 0" class="body2">, </span>
                        <br v-if="ipIndex !== 0 && ipIndex % 2 === 0" />
                        <span class="body2">{{ ip }}</span>
                      </span>
                    </template>
                  </td>
                  <td class="content-row white-space-normal">
                    <span v-if="item.restrictions.type === 'ip'" class="body2">{{
                      item.restrictions.description
                    }}</span>
                  </td>
                  <td class="content-row">
                    <span class="body2">{{
                      $t(`accessPermissions.table.remediationTypes.${item.remediation}`)
                    }}</span>
                  </td>
                  <td class="text-right" @click="$event.stopPropagation()">
                    <v-menu bottom left>
                      <template v-slot:activator="{ props }">
                        <v-btn
                          dark
                          icon
                          :data-testid="`access-restrictions-page-actions-btn-row-${index}`"
                          v-bind="props"
                        >
                          <v-icon icon="$dots"></v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="action in itemActions"
                          :key="action"
                          :data-testid="`access-restrictions-page-restriction-${action}-action`"
                          @click="onActionClick(item, action)"
                        >
                          <v-list-item-title>
                            {{ $t(`accessPermissions.actions.${action}`) }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </table-wrapper>
      </template>
      <empty-state v-else class="margin-auto mt-12">
        <template #icon>
          <v-icon icon="$accessRestrictions" :size="80"></v-icon>
        </template>
        <template #description>
          {{
            $t("accessPermissions.noItems.description", {
              service: $t(`services.${service}`),
            })
          }}
        </template>
        <template #subtitle>
          {{
            $t("accessPermissions.noItems.subDescription", {
              service: $t(`services.${service}`),
            })
          }}
        </template>
        <template #button>
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn
                color="primary"
                rounded
                size="large"
                class="mt-4"
                data-testid="access-restrictions-page-add-btn-empty-state"
                v-bind="props"
              >
                {{ $t("accessPermissions.addRestriction") }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="menuItem in addMenuItems"
                :key="menuItem.type"
                @click="onAddActionClick(menuItem)"
              >
                <v-list-item-title data-testid="access-restrictions-add-all-users-action">
                  {{ $t(`accessPermissions.types.${menuItem.type}`) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </empty-state>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { countriesList, usaStatesList } from "@/constants/countries";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  getCountryOrStateName,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import TableWrapper from "@/components/TableWrapper.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { useI18n } from "vue-i18n";
import { computed, defineComponent, onMounted, type PropType } from "vue";
import {
  type AccessPermissionItem,
  useAccessPermissionsStore,
} from "@/_store/cloud-apps/access-permissions.module";
import { AccessPermissionAction, AccessPermissionType, type Service } from "@/constants/cloud-apps";
import { SubscriptionModule } from "@/constants/workplaces";
import { RolePermissionsScope } from "@/_store/roles.module";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module";
import AddAccessPermissionModal from "@/components/modals/AddAccessPermissionModal.vue";
import { ModalWidth } from "@/constants/modals";
import EmptyState from "@/components/EmptyState.vue";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    EmptyState,
    TableWrapper,
    CoroIcon,
  },
  props: {
    service: {
      type: String as PropType<Service>,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const accessPermissionsStore = useAccessPermissionsStore();
    const dialogStore = useDialogsStore();
    const { permissions, loading } = storeToRefs(accessPermissionsStore);
    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.CLOUD_SECURITY
    );

    const addMenuItems = computed(() => {
      return [
        {
          action: AccessPermissionAction.ADD,
          service: props.service,
          type: AccessPermissionType.ALL_USERS,
        },
        {
          action: AccessPermissionAction.ADD,
          service: props.service,
          type: AccessPermissionType.SPECIFIC_GROUPS,
        },
        {
          action: AccessPermissionAction.ADD,
          service: props.service,
          type: AccessPermissionType.SPECIFIC_USERS,
        },
      ];
    });

    onMounted(async () => {
      await accessPermissionsStore.getAccessPermissions(props.service);
    });

    const onAddActionClick = (item: {
      action: AccessPermissionAction;
      service: Service;
      type: AccessPermissionType;
    }) => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: item.action,
        item: {
          type: item.type,
          serviceName: item.service,
        },
        component: AddAccessPermissionModal,
        scrollable: true,
        callback: accessPermissionsStore.addAccessPermissions,
        width: ModalWidth.LARGE,
        disable: actionNotAllowed,
      });
      dialogStore.openDialog({
        ...dialogConfig,
        header: {
          ...dialogConfig.header,
          title: i18n.t(`modals.${item.action}.title`, {
            type: i18n.t(`accessPermissions.types.${item.type}`),
          }),
        },
      });
    };

    const onActionClick = (item: AccessPermissionItem, action: AccessPermissionAction) => {
      switch (action) {
        case AccessPermissionAction.DELETE:
          dialogStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              item,
              callback: () => accessPermissionsStore.deleteAccessPermissions(item),
              disable: actionNotAllowed,
            })
          );
          break;
        case AccessPermissionAction.EDIT:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              action: action,
              item,
              component: AddAccessPermissionModal,
              scrollable: true,
              callback: accessPermissionsStore.editAccessPermissions,
              width: ModalWidth.LARGE,
              disable: actionNotAllowed,
            })
          );
          break;
        default:
          return;
      }
      return item;
    };

    return {
      getCountryOrStateName,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
        {
          title: `‹ ${i18n.t("cloudSecurity.title")}`,
          disabled: false,
          to: { name: RouteName.CLOUD_SECURITY_PAGE },
        },
      ],
      itemActions: [AccessPermissionAction.EDIT, AccessPermissionAction.DELETE],
      coronetSkeletonLoaderTypes,
      countriesList,
      usaStatesList,
      actionNotAllowed,
      permissions,
      loading,
      addMenuItems,
      onAddActionClick,
      onActionClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.heading-text {
  line-height: 40px;
}

:deep(*) {
  .service-icon {
    width: 24px;
    height: 24px;

    &--large {
      width: 40px;
      height: 40px;
    }
  }

  th {
    background-color: white !important;
  }

  td.content-row {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    vertical-align: top;
  }

  .vertical-align-top {
    vertical-align: top;
  }
}
</style>
