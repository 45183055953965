<template>
  <v-form v-if="!vpnConnectionStatus.configured" ref="form" v-model="formValid" lazy-validation>
    <v-card class="w-50 mt-3">
      <v-card-title class="pa-6">
        <div class="headline6">
          {{ $t("network.initialSetup.title") }}
        </div>
      </v-card-title>
      <v-card-text class="pa-6">
        <div class="subtitle1 mb-4">
          {{ $t("network.initialSetup.selectRegionTitle") }}
        </div>
        <div class="warning-block d-flex align-center mb-5">
          <v-icon class="mr-3 ml-4" icon="$warning"> </v-icon>
          <span class="body2 coronet-warning-darken1--text">{{
            $t("network.initialSetup.selectRegionDisclaimer")
          }}</span>
        </div>
        <v-autocomplete
          v-model="localValue.region"
          :items="sortedRegions"
          menu-icon="icon-triangle"
          item-title="displayValue"
          item-value="value"
          :rules="[requiredRule]"
          :label="$t('network.initialSetup.selectRegionPlaceholder')"
          variant="outlined"
          background-color="white"
          return-object
        >
          <template #item="{ props, item }">
            <v-list-item v-bind="props" title="">
              <span class="ml-1">{{ item.raw.displayValue }}</span>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-6">
        <v-btn
          color="primary"
          rounded
          elevation="0"
          size="large"
          variant="flat"
          :ripple="false"
          @click="saveSettings()"
        >
          {{ $t("general.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
  <empty-state v-else class="margin-auto mt-12 w-65">
    <template #icon>
      <v-icon class="empty-state-icon mr-1" icon="$addonNetwork"> </v-icon>
    </template>
    <template #description><span v-html="$t('network.noData.title')"></span></template>
    <template #subtitle>
      <span v-html="$t('network.noData.subtitle')"></span>
    </template>
    <template #button>
      <a class="d-inline-flex align-center flex-grow-0" :href="supportLink" target="_blank">
        <v-icon size="18" class="mr-1" icon="$help"></v-icon>
        <span class="body2 coro-link">{{ $t("network.noData.contactSupport") }}</span>
      </a>
    </template>
  </empty-state>
</template>

<script lang="ts">
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { computed, defineComponent, onMounted, ref } from "vue";
import { required } from "@/_helpers/validators";
import { useVirtualOfficeStore } from "@/_store/network/virtual-office.module";
import { storeToRefs } from "pinia";
import type { VuetifyFormRef } from "@/types";
import { useDialogsStore } from "@/_store/dialogs.module";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { VirtualOfficeAction } from "@/constants/network";
import { ModalWidth } from "@/constants/modals";
import SelectRegionConfirmationModal from "@/components/modals/network/SelectRegionConfirmationModal.vue";
import EmptyState from "@/components/EmptyState.vue";
import isEmpty from "lodash/isEmpty";
import { useI18n } from "vue-i18n";

type Region = { value: string; displayValue: string };
export default defineComponent({
  components: { EmptyState },
  setup() {
    const i18n = useI18n();
    const dialogsStore = useDialogsStore();
    const virtualOfficeStore = useVirtualOfficeStore();
    const form = ref<VuetifyFormRef>();
    const { vpnConnectionStatus } = storeToRefs(virtualOfficeStore);
    const supportLink = "https://docs.coro.net/";
    const localValue = ref<{ region?: Region }>({});
    const regions = ref<Record<string, string>>({});
    const formValid = ref(true);
    const requiredRule = (v: Region) => (isEmpty(v) ? i18n.t("validations.required") : true);

    const sortedRegions = computed(() => {
      return Object.entries(regions.value)
        .map(
          ([value, displayValue]): Region => ({
            value,
            displayValue,
          })
        )
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    });

    onMounted(async () => {
      regions.value = await virtualOfficeStore.getVpnRegions();
    });

    const saveSettings = async () => {
      const validationResult = await form.value?.validate();

      if (validationResult?.valid) {
        dialogsStore.openDialog(
          componentDialogsConfigConstructor({
            callback: virtualOfficeStore.setupVirtualOffice,
            component: SelectRegionConfirmationModal,
            item: { region: localValue.value.region },
            width: ModalWidth.MEDIUM,
            action: VirtualOfficeAction.SETUP,
            disable: isWorkspaceFrozenOrActionRestricted(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.NETWORK
            ),
          })
        );
      }
    };

    return {
      localValue,
      regions,
      formValid,
      required,
      supportLink,
      sortedRegions,
      vpnConnectionStatus,
      form,
      saveSettings,
      requiredRule,
    };
  },
});
</script>

<style lang="scss" scoped>
.empty-state-icon {
  font-size: 80px !important;
}

:deep(*) {
  .icon-privatize::before {
    color: rgb(var(--v-theme-indigo-light)) !important;
  }
}
</style>
