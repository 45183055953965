<template>
  <div>
    <v-btn
      color="primary"
      rounded
      elevation="0"
      size="large"
      class="add-btn"
      @click="openAddAdminUserDialog()"
    >
      <v-icon color="white" class="mr-2" icon="$add"></v-icon>
      <span>{{ $t("backOffice.adminUsers.table.actions.addGlobalAdminUser") }}</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { componentDialogsConfigConstructor } from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import { GlobalAdminUsersAction } from "@/constants/admin-accounts";
import { useBackOfficeStore } from "@/_store/back-office/back-office.module";
import AddGlobalAdminUserModal from "@/components/modals/AddGlobalAdminUserModal.vue";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { GlobalRoleScopes, GlobalRoleScopeSection } from "@/_store/roles.module";

export default defineComponent({
  setup() {
    const dialogsStore = useDialogsStore();
    const { addGlobalAdminUser } = useBackOfficeStore();
    const openAddAdminUserDialog = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        action: GlobalAdminUsersAction.ADD,
        component: AddGlobalAdminUserModal,
        width: ModalWidth.LARGE,
        callback: addGlobalAdminUser,
        disable: isGlobalActionRestricted(
          GlobalRoleScopes.GLOBAL_SCOPE,
          GlobalRoleScopeSection.GLOBAL_ADMIN_USERS,
          "add"
        ),
      });
      dialogsStore.openDialog(dialogConfig);
    };

    return {
      openAddAdminUserDialog,
    };
  },
});
</script>

<style scoped></style>
