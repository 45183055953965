import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { defineStore } from "pinia";
import { useSnackbarStore } from "@/_store";
import { i18n } from "@/plugins/i18n";

export interface ApiKey {
  workspaceId: string;
  clientId: string;
  createdBy: string;
  createdDateTimeMs: number;
  description: string;
  expirationTime: number;
  lastUsedTime: number;
}

interface ApiCredentialsState {
  apiKeys: ApiKey[];
  createdKey: {
    clientId: string;
    clientSecret: string;
  } | null;
  loading: boolean;
  showSkeletonLoader: boolean;
}

export interface AddApiKeyPayload {
  expirationTime: number | null;
  description: string;
}

const defaultApiCredentialsState = {
  apiKeys: [],
  createdKey: null,
  loading: false,
  showSkeletonLoader: false,
};

export const useApiCredentialsStore = defineStore("apiCredentials", {
  state: (): ApiCredentialsState => ({ ...defaultApiCredentialsState }),
  actions: {
    async getApiKeys(): Promise<void> {
      this.showSkeletonLoader = true;

      try {
        const { data } = await axiosInstance.request(api.apiCredentials());

        this.apiKeys = data;
        this.showSkeletonLoader = false;
      } catch (err) {
        console.error(err);
        this.showSkeletonLoader = false;
        throw err;
      }
    },
    async addApiKey(payload: AddApiKeyPayload): Promise<void> {
      this.showSkeletonLoader = true;

      try {
        const request = {
          ...api.apiCredentials(),
          method: "POST",
          data: payload,
        };

        const response = await axiosInstance.request(request);

        this.createdKey = response.data;

        await this.getApiKeys();
        this.showSkeletonLoader = false;
      } catch (err) {
        console.error(err);
        this.showSkeletonLoader = false;
        throw err;
      }
    },
    async removeApiKey(payload: ApiKey): Promise<void> {
      this.showSkeletonLoader = true;

      try {
        const { clientId } = payload;

        const request = {
          ...api.apiCredentials(),
          method: "DELETE",
          params: {
            clientId,
          },
        };

        await axiosInstance.request(request);

        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.connectors.apiKeyRemoved")
        );

        await this.getApiKeys();

        const { data } = await axiosInstance.request(api.apiCredentials());

        this.apiKeys = data;
        this.showSkeletonLoader = false;
      } catch (err) {
        console.error(err);
        this.showSkeletonLoader = false;
        throw err;
      }
    },
    clearCreatedApiKey(): void {
      this.createdKey = null;
    },
  },
});
