<template>
  <div>
    <div class="headline6">
      {{ $t("modals.addTunnel.formTitle") }}
    </div>
    <v-form ref="form" v-model="formValid" class="mt-3" lazy-validation>
      <v-text-field
        class="mb-2"
        v-model="localValue.item.siteName"
        data-testid="add-tunnel-modal-site-name"
        outlined
        :rules="[required()]"
        :label="$t('modals.addTunnel.inputs.siteName.label')"
        :placeholder="$t('modals.addTunnel.inputs.siteName.placeholder')"
      />
      <v-text-field
        class="mb-2"
        v-model="localValue.item.siteDescription"
        data-testid="add-tunnel-modal-site-description"
        outlined
        :rules="[required()]"
        :label="$t('modals.addTunnel.inputs.siteDescription.label')"
        :placeholder="$t('modals.addTunnel.inputs.siteDescription.placeholder')"
      />
      <v-text-field
        class="mb-2"
        v-model="localValue.item.remoteGatewayIp"
        data-testid="add-tunnel-modal-remote-gateway-id"
        outlined
        :rules="[ipAddressRules, required()]"
        :label="$t('modals.addTunnel.inputs.remoteGatewayIp.label')"
        :placeholder="$t('modals.addTunnel.inputs.remoteGatewayIp.placeholder')"
      />
      <div class="d-flex justify-space-between mb-2">
        <v-text-field
          v-model="localValue.item.presharedKey"
          data-testid="add-tunnel-modal-preshared-key"
          outlined
          :rules="[presharedKeyRules, required()]"
          :label="$t('modals.addTunnel.inputs.presharedKey.label')"
          :placeholder="$t('modals.addTunnel.inputs.presharedKey.placeholder')"
        >
          <template #append-inner v-if="localValue.item.presharedKey">
            <v-icon icon="$copy" @click="copyKey(localValue.item.presharedKey)" />
          </template>
        </v-text-field>
        <v-btn class="ml-4" height="56" rounded="pill" variant="outlined" @click="generateKey">
          {{ $t("modals.addTunnel.inputs.presharedKey.generateKey") }}
        </v-btn>
      </div>
      <v-text-field
        v-model.number="localValue.item.lifetimeKey"
        data-testid="add-tunnel-modal-lifetime-key"
        type="number"
        min="360"
        outlined
        :rules="[lifetimeKeyRules]"
        :label="$t('modals.addTunnel.inputs.lifetimeKey.label')"
        :placeholder="$t('modals.addTunnel.inputs.lifetimeKey.placeholder')"
        @keydown="filterKey"
        @paste.prevent
      />
      <div class="subtitle1 mb-4">
        {{ $t("modals.addTunnel.inputs.remoteNetworkIps.subtitle") }}
      </div>
      <div
        class="d-flex align-center"
        v-for="(_, index) in localValue.item.remoteNetworkIps"
        :key="index"
      >
        <v-text-field
          class="mb-2"
          v-model="localValue.item.remoteNetworkIps[index]"
          variant="outlined"
          :rules="[
            required(),
            remoteNetworkIpsRule(),
            uniqueIpRule(),
            reservedIpRules(),
            subnetRangeRules(),
          ]"
          hide-details="auto"
          :label="$t('modals.addTunnel.inputs.remoteNetworkIps.label')"
          :placeholder="$t('modals.addTunnel.inputs.remoteNetworkIps.placeholder')"
        >
          <template #append>
            <v-btn
              v-if="localValue.item.remoteNetworkIps.length > 1"
              @click="localValue.item.remoteNetworkIps.splice(index, 1)"
              class="ml-3"
              :icon="true"
            >
              <v-icon class="delete-internal-network-ip-icon" size="32" icon="$trash"></v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
      <div
        role="button"
        class="body2 coro-link mt-4"
        @click="localValue.item.remoteNetworkIps.push('')"
      >
        {{ $t("modals.addTunnel.inputs.remoteNetworkIps.add") }}
      </div>
      <div>
        <div class="mt-5 headline6">
          {{ $t("modals.addTunnel.detailsTitle") }}
        </div>
        <table class="settings-table virtual-office-section mt-4">
          <tr>
            <td class="setting-name pa-4 subtitle1">
              {{ $t("modals.addTunnel.gatewayIp") }}
            </td>
            <td class="setting-value pa-4 subtitle1">
              {{ $t("modals.addTunnel.subnet") }}
            </td>
          </tr>
          <tr v-for="availableServer in availableRocServers" :key="availableServer.server">
            <td class="body2 pa-4 setting-name">
              {{ availableServer.server }}
            </td>
            <td class="body2 pa-4 setting-value">
              {{ availableServer.subnet }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-5">
        <div class="mt-5 headline6">
          {{ $t("modals.addTunnel.settings.title") }}
        </div>
        <table class="settings-table mt-4 pb-6">
          <tr :class="{ 'table-select--error': !isValid('ikeVersion') }">
            <td class="subtitle1 pa-4 setting-name">
              {{ $t("modals.addTunnel.settings.ikeVersion") }}
            </td>
            <td class="setting-value">
              <v-select
                v-model="localValue.item.ikeVersion"
                :items="ikeVersions"
                :rules="[required()]"
                :menu-props="{
                  maxHeight: '300',
                  closeOnContentClick: true,
                }"
                class="tunnel-modal-select pr-4"
                menu-icon="icon-triangle"
                hide-details
                density="compact"
                variant="plain"
                solo
              >
                <template #selection="{ item }">
                  <span class="body1 tunnel-modal-select__selected-item">
                    {{ item.title }}
                  </span>
                </template>
              </v-select>
            </td>
          </tr>
          <tr :class="{ 'table-select--error': !isValid('phase1Encryption') }">
            <td class="subtitle1 pa-4 setting-name">
              {{ $t("modals.addTunnel.settings.phase1Encryption") }}
            </td>
            <td class="setting-value">
              <v-select
                v-model="localValue.item.phase1Encryption"
                :items="phase1Encryptions"
                :rules="[required()]"
                :menu-props="{
                  maxHeight: '300',
                  closeOnContentClick: true,
                }"
                class="tunnel-modal-select pr-4"
                menu-icon="icon-triangle"
                hide-details
                density="compact"
                variant="plain"
                solo
              >
                <template #selection="{ item }">
                  <span class="body1 tunnel-modal-select__selected-item">
                    {{ item.title }}
                  </span>
                </template>
              </v-select>
            </td>
          </tr>
          <tr :class="{ 'table-select--error': !isValid('phase2Encryption') }">
            <td class="subtitle1 pa-4 setting-name">
              {{ $t("modals.addTunnel.settings.phase2Encryption") }}
            </td>
            <td class="setting-value">
              <v-select
                v-model="localValue.item.phase2Encryption"
                :items="phase2Encryptions"
                :rules="[required()]"
                :menu-props="{
                  maxHeight: '300',
                  closeOnContentClick: true,
                }"
                class="tunnel-modal-select pr-4"
                menu-icon="icon-triangle"
                hide-details
                density="compact"
                variant="plain"
                solo
              >
                <template #selection="{ item }">
                  <span class="body1 tunnel-modal-select__selected-item">
                    {{ item.title }}
                  </span>
                </template>
              </v-select>
            </td>
          </tr>
          <tr :class="{ 'table-select--error': !isValid('aggressiveMode') }">
            <td class="subtitle1 pa-4 setting-name">
              {{ $t("modals.addTunnel.settings.aggressiveMode") }}
            </td>
            <td class="setting-value">
              <v-select
                v-model="localValue.item.aggressiveMode"
                :items="aggressiveModes"
                :rules="[aggressiveModeRules()]"
                :disabled="localValue.item.ikeVersion === 'IKEv2'"
                :menu-props="{
                  maxHeight: '300',
                  closeOnContentClick: true,
                }"
                class="tunnel-modal-select pr-4"
                menu-icon="icon-triangle"
                hide-details
                density="compact"
                variant="plain"
                solo
                item-title="title"
                item-value="value"
              >
                <template #selection="{ item }">
                  <span class="body1 tunnel-modal-select__selected-item">
                    {{ item.title }}
                  </span>
                </template>
              </v-select>
            </td>
          </tr>
        </table>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import cloneDeep from "lodash/cloneDeep";
import Patterns from "@/constants/patterns";
import { defineComponent, onMounted, type PropType, ref, watch } from "vue";
import type { VuetifyFormRef } from "@/types";
import { useI18n } from "vue-i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { useSnackbarStore } from "@/_store";
import {
  type SiteToSiteTunnel,
  useSiteToSiteTunnelStore,
} from "@/_store/network/site-to-site-tunnels.module";
import { required } from "@/_helpers/validators";
import { copyToClipboard } from "@/_helpers/utils";
import { SnackbarTypes } from "@/_store";

const phase1Encryptions = [
  "AES256-SHA1-D14",
  "AES256-SHA1-D2",
  "AES128-SHA1-D14",
  "AES128-SHA1-D2",
  "AES256-SHA1-D2",
  "AES256-SHA1-D14",
  "AES256-SHA256-D2",
  "AES256-SHA256-D14",
  "AES128-SHA1-D5",
  "AES128-SHA256-D5",
  "AES128-SHA1-D5",
  "AES256-SHA1-D5",
];
const phase2Encryptions = [
  "AES256-SHA1-D14",
  "AES256-SHA1-D2",
  "AES128-SHA1-D14",
  "AES128-SHA1-D2",
  "AES256-SHA1-D2",
  "AES256-SHA1-D14",
  "AES256-SHA256-D2",
  "AES256-SHA256-D14",
  "AES128-SHA1-D5",
  "AES128-SHA256-D5",
  "AES128-SHA1-D5",
  "AES256-SHA1-D5",
];
const ikeVersions = ["IKEv1", "IKEv2"];
const aggressiveModes = [
  { title: "Yes", value: true },
  { title: "No", value: false },
];

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<SiteToSiteTunnel>>,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const localValue = ref(
      props.config.item
        ? { ...cloneDeep(props.config), item: props.config.item }
        : {
            ...cloneDeep(props.config),
            item: {
              siteName: "",
              siteDescription: "",
              remoteGatewayIp: "",
              presharedKey: "",
              lifetimeKey: 3600,
              ikeVersion: "",
              phase1Encryption: "",
              phase2Encryption: "",
              aggressiveMode: null,
              remoteNetworkIps: [""],
            },
          }
    );
    const form = ref<VuetifyFormRef>();
    const i18n = useI18n();
    const formValid = ref(null);
    const siteToSiteTunnelsStore = useSiteToSiteTunnelStore();
    const snackbarStore = useSnackbarStore();
    const availableRocServers = ref<{ server: string; subnet: string }[]>([]);
    let initialRemoteNetworkIps: string[];

    const { validateSubnetRange, getRocSettings } = siteToSiteTunnelsStore;

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );
    const aggressiveModeRules = () => {
      return (value: { title: string; value: boolean } | null) => {
        if (value === null) return i18n.t("validations.required");
        return true;
      };
    };
    const remoteNetworkIpsRule = () => {
      return (value: string) => {
        if (!Patterns.IP_WITH_REQUIRED_CIDR.test(value)) {
          return i18n.t("validations.ipRange");
        }
        return true;
      };
    };

    const uniqueIpRule = (): ((value: string) => string | boolean) => () => {
      if (
        localValue.value.item.remoteNetworkIps.length !==
        new Set(localValue.value.item.remoteNetworkIps).size
      ) {
        return i18n.t("validations.ipExists");
      }
      return true;
    };

    const subnetRangeRules = (): (() => Promise<boolean | string>) => async () => {
      let payload: string[] = [];
      if (initialRemoteNetworkIps?.length !== 0) {
        payload = localValue.value.item?.remoteNetworkIps.filter(
          (value: string) => value && !initialRemoteNetworkIps.includes(value)
        );
      }
      if (payload.length === 0) {
        return true;
      }
      const valid = await validateSubnetRange(payload);

      if (!valid) return i18n.t("validations.overlap");

      return valid;
    };

    const reservedIpRules = (): ((value: string) => string | boolean) => (value) => {
      const RESERVED_IPS = ["10.10.10.0/16", "10.9.0.0/16", "10.8.0.0/16"];
      if (RESERVED_IPS.includes(value)) {
        return i18n.t("validations.reservedIp");
      }
      return true;
    };

    const copyKey = (text: string): void => {
      copyToClipboard(text);
      snackbarStore.add({
        html: i18n.t("modals.addTunnel.copySuccessMessage"),
        type: SnackbarTypes.INFO,
      });
    };
    watch(
      () => localValue.value.item.ikeVersion,
      (newVal) => {
        if (newVal === "IKEv2") {
          localValue.value.item.aggressiveMode = false;
        }
      },
      { deep: true }
    );

    watch(
      formValid,
      (newVal: boolean | null) => {
        if (newVal === null) {
          return emit("update:valid", true);
        }

        emit("update:valid", newVal);
      },
      { immediate: false }
    );

    const validate = async () => {
      const validationResult = await form.value?.validate();

      return validationResult?.valid;
    };

    onMounted(async () => {
      initialRemoteNetworkIps = [...(props.config.item?.remoteNetworkIps ?? [])];
      try {
        const { data } = await getRocSettings();
        availableRocServers.value = data;
      } catch (e) {
        console.error(e);
      }
    });

    const isValid = (val: keyof SiteToSiteTunnel) => {
      if (formValid.value === null) return true;
      return formValid.value || !!(localValue.value.item as SiteToSiteTunnel)[val];
    };

    const filterKey = (e: KeyboardEvent) => {
      const key = e.key;
      // do not allow - '.', 'e', '-', '+', ','
      if (["-", "+", "e", ".", ","].includes(key)) return e.preventDefault();
    };

    const generateKey = () => {
      const arr = new Uint8Array(24);
      while (
        btoa(String.fromCharCode(...arr)) === "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA" ||
        !Patterns.NETWORK_KEY.test(window.btoa(String.fromCharCode(...arr)))
      ) {
        window.crypto.getRandomValues(arr);
      }
      localValue.value.item.presharedKey = window.btoa(String.fromCharCode(...arr));
    };

    return {
      aggressiveModeRules,
      reservedIpRules,
      subnetRangeRules,
      uniqueIpRule,
      remoteNetworkIpsRule,
      copyKey,
      availableRocServers,
      phase1Encryptions,
      phase2Encryptions,
      ikeVersions,
      aggressiveModes,
      required,
      formValid,
      form,
      localValue,
      validate,
      isValid,
      filterKey,
      generateKey,
      ipAddressRules: (value: string) => {
        if (!value) return true;
        if (!Patterns.IP_WITHOUT_CIDR.test(value)) {
          return i18n.t("validations.ipAddress");
        }
        return true;
      },

      remoteNetworkIpRules: (value: string) => {
        if (!value) return true;
        if (!Patterns.IP_WITHOUT_CIDR.test(value)) {
          return i18n.t("validations.ipAddress");
        }
        // Subnets 10.8 and 10.9 are not allowed
        const notAllowedSubnets = ["10.8", "10.9"];
        if (notAllowedSubnets.some((subnet) => value.startsWith(subnet))) {
          return i18n.t("validations.uniqueSubnet");
        }
        return true;
      },

      presharedKeyRules: (value: string) => {
        if (!value) return true;
        if (value.length < 20) {
          return i18n.t("validations.minNCharacters", { n: 20 });
        }
        if (!Patterns.NETWORK_KEY.test(value)) {
          return i18n.t("validations.onlyCertainSpecialCharactersAllowed", {
            characters: "! @ # $ % ^ & * ( )",
          });
        }
        return true;
      },

      lifetimeKeyRules: (number: string) => {
        if (!number) return i18n.t("validations.required");
        if (parseInt(number) < 360) {
          return i18n.t("validations.minNumberAllowed", { n: 360 }, 360);
        }
        if (parseInt(number) > 86400) {
          return i18n.t("validations.maxNumberAllowed", { n: 86400 }, 86400);
        }
        if (!Patterns.ONLY_DIGITS.test(number)) return i18n.t("validations.onlyInteger");
        return true;
      },
    };
  },
});
</script>
<style lang="scss" scoped>
td {
  border-bottom: 1px solid rgb(var(--v-theme-indigo-faint)) !important;
  width: 50%;
}
.table-select--error {
  width: 50%;
}
.settings-table {
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  &.virtual-office-section {
    td {
      border-bottom: 1px solid rgb(var(--v-theme-indigo-faint)) !important;
      border-left: 1px solid rgb(var(--v-theme-indigo-faint)) !important;
    }
  }

  td:last-child {
    border-right: 1px solid rgb(var(--v-theme-indigo-faint));
  }

  tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }
  tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  tr:first-child td {
    border-top: 1px solid rgb(var(--v-theme-indigo-faint));
  }
  tr td:first-child {
    border-left: 1px solid rgb(var(--v-theme-indigo-faint));
  }

  .table-select--error {
    td {
      border-width: 2px !important;
      border-color: rgb(var(--v-theme-red-dark)) !important;
    }

    &:first-child td {
      border-top: 2px solid rgb(var(--v-theme-red-dark)) !important ;
    }
    & td:first-child {
      border-left: 2px solid rgb(var(--v-theme-red-dark)) !important;
    }
  }
}
.tunnel-modal-select {
  text-align: right;
  margin-top: 0;
}
.tunnel-modal-select__selected-item {
  text-align: right;
  margin-left: auto;
  display: block;
  width: 100%;
}
:deep(*) {
  .v-input--horizontal .v-input__append {
    margin: 0 !important;
  }
  .tunnel-modal-select {
    .v-field__input {
      justify-content: flex-end;
    }
  }

  .delete-internal-network-ip-icon.icon-trash {
    &::before {
      color: rgb(var(--v-theme-indigo-medium));
    }
  }
}
</style>
