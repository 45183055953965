<template>
  <div class="mb-4">
    <services-list v-if="!config.item.name" :config="config" />
    <app-connection-completed
      @dismissed="$emit('dismissed')"
      v-else-if="connectionCompleted"
      :config="config"
    />
    <component
      :is="serviceComponent"
      v-else-if="showStepper"
      :config="config"
      @complete-connection="onConnectionComplete()"
      @complete="onComplete()"
      @dismissed="$emit('dismissed')"
    ></component>
    <template v-else>
      <app-connection-completed v-if="completed" :config="config" @dismissed="$emit('dismissed')" />
      <app-connection-not-completed v-else :config="config" @dismissed="$emit('dismissed')" />
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import ServicesList from "@/views/settings/cloud-apps/modals/ServicesList.vue";
import { Service, ServiceAction, ServiceStatus } from "@/constants/cloud-apps";
import { useI18n } from "vue-i18n";
import Microsoft365Connect from "@/views/settings/cloud-apps/modals/Microsoft365Connect.vue";
import AppConnectionCompleted from "@/views/settings/cloud-apps/modals/AppConnectionCompleted.vue";
import GsuiteConnect from "@/views/settings/cloud-apps/modals/GsuiteConnect.vue";
import BoxConnect from "@/views/settings/cloud-apps/modals/BoxConnect.vue";
import DropboxConnect from "@/views/settings/cloud-apps/modals/DropboxConnect.vue";
import AppConnectionNotCompleted from "@/views/settings/cloud-apps/modals/AppConnectionNotCompleted.vue";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";

export default defineComponent({
  components: {
    ServicesList,
    Microsoft365Connect,
    GsuiteConnect,
    DropboxConnect,
    AppConnectionCompleted,
    BoxConnect,
    AppConnectionNotCompleted,
  },
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  emits: ["change-header-title", "dismissed", "hide-default-footer"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const connectionCompleted = ref(props.config.item.serviceStatus === ServiceStatus.CONNECTED);
    const completed = ref(false);

    const showStepper = computed(() => {
      if (completed.value || connectionCompleted.value) {
        return false;
      }

      return [Service.BOX, Service.OFFICE_365, Service.G_SUITE, Service.DROPBOX].includes(
        props.config.item.name!
      );
    });

    watch(
      () => props.config.item.name,
      (val) => {
        emit(
          "change-header-title",
          i18n.t(`modals.${ServiceAction.CONNECT}.title`, { service: i18n.t(`services.${val}`) })
        );
      },
      {
        immediate: true,
      }
    );

    const onConnectionComplete = () => {
      connectionCompleted.value = true;
    };
    const onComplete = () => {
      completed.value = true;
    };
    const serviceComponent = computed(() => {
      switch (props.config.item.name) {
        case Service.BOX:
          return "BoxConnect";
        case Service.OFFICE_365:
          return "Microsoft365Connect";
        case Service.G_SUITE:
          return "GsuiteConnect";
        case Service.DROPBOX:
          return "DropboxConnect";
        default:
          return null;
      }
    });

    return {
      showStepper,
      onConnectionComplete,
      onComplete,
      connectionCompleted,
      completed,
      serviceComponent,
    };
  },
});
</script>
<style scoped lang="scss">
:deep(*) {
  .v-expansion-panel--active:not(.v-stepper-vertical-item--error)
    .v-stepper-vertical-item__avatar.v-avatar {
    background-color: rgb(var(--v-theme-primary));
  }

  .v-stepper-vertical-item--error {
    .v-avatar {
      background: transparent !important;
    }
  }

  .v-slide-group__content {
    flex-wrap: wrap;
    width: 100%;
  }

  .v-chip {
    width: 160px;
    height: 160px !important;
    border-radius: 6px;
  }

  .v-chip .v-chip__content {
    width: 100% !important;
    text-align: center;
  }

  .v-chip-group .v-chip--active {
    border: 2px solid rgb(var(--v-theme-primary)) !important;
  }

  .deep-purple--text.text--accent-4 {
    color: transparent !important;
  }

  .v-chip:not(.v-chip--active) {
    border: solid 1px rgb(var(--v-theme-indigo-pale)) !important;
  }

  .service-icon {
    height: 48px;
    width: 48px;
  }

  .services-chip-group {
    display: flex;
    justify-content: center;
    margin-bottom: 92px;
    margin-top: 32px;
    max-width: 510px;
  }

  .description-bold {
    font-weight: 600;
  }

  .client-id-copy-field {
    width: 348px;

    .v-text-field__details {
      display: none;
    }

    .v-input__slot {
      padding-right: 0 !important;

      fieldset {
        border-color: rgb(var(--v-theme-indigo-medium)) !important;
      }
    }

    .v-input__append-inner {
      margin-top: 0 !important;
    }

    .v-btn {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      pointer-events: initial;
    }

    .v-label {
      color: rgb(var(--v-theme-indigo-medium));
    }
  }

  .scopes-copy-field__wrapper {
    border-radius: 4px;
    border: 1px solid rgb(var(--v-theme-indigo-medium));
    width: 616px;
    color: rgb(var(--v-theme-indigo-medium));

    .scopes-copy-field__links {
      padding: 8px 25px 8px 13px;
    }

    .scopes-copy-field__btn {
      position: absolute;
      right: 71px;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-top: -40px !important;
      margin-right: -52px !important;

      &--no-stepper {
        right: 164px;
      }
    }
  }

  .mdi-check:before {
    font-size: 16px;
  }

  .icon-warning:before {
    color: rgb(var(--v-theme-red-dark)) !important;
  }

  .connect-service-modal__service-icon {
    width: 96px;
    height: 96px;
  }
}
</style>
