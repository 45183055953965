<template>
  <div
    class="d-flex align-center"
    :style="{ 'max-width': maxWidth, width: width }"
    @mouseover="showCopyBtn = true"
    @mouseleave="showCopyBtn = false"
    @click="copyText()"
  >
    <div class="ellipsis">
      <span data-testid="ellipsified-text">{{ text }}</span>
    </div>
    <v-icon
      v-if="showCopyBtn"
      size="x-small"
      color="primary"
      class="cursor-pointer ml-1"
      icon="$copy"
    >
    </v-icon>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { copyToClipboard } from "@/_helpers/utils";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const snackbarStore = useSnackbarStore();
    const i18n = useI18n();
    const showCopyBtn = ref(false);
    const copyText = () => {
      copyToClipboard(props.text);
      snackbarStore.add({
        html: i18n.t("snackbar.messages.general.copiedToClipboard"),
        type: SnackbarTypes.INFO,
      });
    };
    return {
      showCopyBtn,
      copyText,
    };
  },
});
</script>
