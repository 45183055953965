<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <div class="mt-6 v-row">
      <div class="v-col-6">
        <v-text-field
          v-model="localValue.item.firstName"
          data-testid="add-admin-modal-firstname"
          variant="outlined"
          :rules="[required()]"
          :label="$t('modals.addAdminUser.firstName.label')"
          :placeholder="$t('modals.addAdminUser.firstName.placeholder')"
        ></v-text-field>
      </div>
      <div class="v-col-6">
        <v-text-field
          v-model="localValue.item.lastName"
          data-testid="add-admin-modal-lastname"
          variant="outlined"
          :rules="[required()]"
          :label="$t('modals.addAdminUser.lastName.label')"
          :placeholder="$t('modals.addAdminUser.lastName.placeholder')"
        ></v-text-field>
      </div>
    </div>
    <v-text-field
      v-model="localValue.item.email"
      data-testid="add-admin-modal-email"
      class="mt-6 mb-2"
      variant="outlined"
      :readonly="isEditMode"
      :rules="isEditMode ? [] : [required(), emailRule()]"
      :label="$t('modals.addAdminUser.email.label')"
      :placeholder="$t('modals.addAdminUser.email.placeholder')"
    ></v-text-field>
    <v-autocomplete
      v-model="localValue.item.role"
      :disabled="isEditMode ? roleAssignmentRestricted : false"
      class="mt-6"
      :placeholder="$t('modals.addAdminUser.role.placeholder')"
      :label="$t('modals.addAdminUser.role.label')"
      item-title="roleName"
      item-value="roleId"
      :return-object="true"
      :hide-no-data="false"
      :no-filter="true"
      auto-select-first="exact"
      :rules="assignRolesRules"
      :loading="rolesSearchLoading"
      :menu-props="{
        maxHeight: '250',
      }"
      :items="roles"
      variant="outlined"
      @update:search="onSearchUpdate($event)"
    ></v-autocomplete>
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { useI18n } from "vue-i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { emailRule, required } from "@/_helpers/validators";
import {
  RolePermissionsScope,
  useRolesStore,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import { debounce } from "lodash";
import isEmpty from "lodash/isEmpty";
import { AdminUsersAction } from "@/constants/admin-accounts";
import { isActionRestricted } from "@/_helpers/utils";
import type { AdminUser } from "@/_store";
import { RoleType } from "@/constants/roles";

const emptyAdminUser = {
  firstName: "",
  lastName: "",
  email: "",
};

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<AdminUser>>,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const rolesStore = useRolesStore(RoleType.WORKSPACE);
    const { searchRoles } = rolesStore;
    const localValue = ref({
      item: cloneDeep(props.config.item ?? emptyAdminUser),
      action: props.config.action,
    });
    const roles = ref<{ roleName: string; roleId: string }[]>([]);
    const rolesSearchLoading = ref<boolean>(false);
    const valid = ref(true);
    const form = ref();
    const isEditMode = computed(() => props.config.action === AdminUsersAction.EDIT);
    const assignRolesRules = computed(() => {
      // Case when adding admin user => role is required
      if (!isEditMode.value) {
        return [roleRequiredRule];
      }
      // Case when editing admin user and can assign role
      if (isEditMode.value && !roleAssignmentRestricted.value) {
        return [roleRequiredRule];
      }
      // Case when editing admin user and can't assign role
      return [];
    });
    const roleAssignmentRestricted = computed(() =>
      isActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ADMIN_USERS,
        "assignRoles"
      )
    );
    const roleRequiredRule = (value?: { roleName: string; roleId: string }) => {
      if (isEmpty(value)) {
        return i18n.t("validations.required");
      }
      return true;
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    const onSearchUpdate = debounce(function (searchString) {
      if (!searchString && !localValue.value.item.role?.roleName) {
        fetchRoles();
      }
      if (searchString !== localValue.value.item.role?.roleName) {
        fetchRoles(searchString);
      }
    }, 500);

    watch(
      valid,
      (newVal: boolean | null) => {
        if (newVal === null) return emit("update:valid", true);
        emit("update:valid", newVal);
      },
      { immediate: false }
    );

    onMounted(async () => {
      await fetchRoles();
    });

    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const fetchRoles = async (search: string = "") => {
      rolesSearchLoading.value = true;
      const data = await searchRoles({ page: 0, pageSize: 50, search });
      roles.value = data.items;
      rolesSearchLoading.value = false;
    };

    return {
      localValue,
      valid,
      form,
      roles,
      rolesSearchLoading,
      isEditMode,
      roleAssignmentRestricted,
      assignRolesRules,
      required,
      emailRule,
      validate,
      onSearchUpdate,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-field--error:not(.v-field--disabled) .v-field__outline {
    --v-field-border-width: 2px !important;
  }
}
</style>
