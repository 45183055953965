<template>
  <div class="content-xl">
    <user-groups-table
      :items="userGroups"
      :total-items="totalItems"
      :item-actions="userGroupsActions"
      :selection="selection"
      :pagination="pagination"
      :loading="loading"
      @page-changed="applyPaginationChange($event)"
      @item-action-applied="handleAction($event)"
      @bulk-action-applied="handleBulkAction($event)"
    ></user-groups-table>
  </div>
</template>

<script lang="ts">
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { computed, defineComponent, onMounted, onUnmounted } from "vue";
import UserGroupsTable from "@/components/tables/UserGroupsTable.vue";
import { UserGroupAction } from "@/constants/users";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useGroupsSettingsTabStore } from "@/_store/users-settings/user-groups-tab.module";
import { storeToRefs } from "pinia";
import { useSelectorStore } from "@/_store/selector.module";
import { useI18n } from "vue-i18n";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import type { UserSettingsListItem } from "@/_store/users-settings/users-tab.module";
import AddGroupModal from "@/components/modals/AddGroupModal.vue";
import { ModalWidth } from "@/constants/modals";

export default defineComponent({
  components: {
    UserGroupsTable,
  },
  setup() {
    const userGroupsStore = useGroupsSettingsTabStore();
    const selectorStore = useSelectorStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const { userGroups, totalItems, totalPages, pagination, loading } =
      storeToRefs(userGroupsStore);

    const { selection } = storeToRefs(selectorStore);

    const { getUserGroups, applyPaginationChange, addNewGroup } = userGroupsStore;

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      );
    });

    onMounted(() => {
      getUserGroups(true);
    });

    onUnmounted(() => {
      selectorStore.$reset();
    });

    const handleBulkAction = (action: string) => {
      const selectedGroupsCount = selection.value.isAllPages
        ? totalItems.value - selection.value.exclude.length
        : selection.value.include.length;
      const dialogConfig = confirmationDialogsConfigConstructor({
        action,
        disable: actionNotAllowed.value,
        text: i18n.t(`modals.${action}.description`),
        callback: userGroupsStore.removeGroups,
      });
      dialogConfig.data.selection = selection.value;
      dialogConfig.header.title = i18n.t(`modals.${action}.title`, { n: selectedGroupsCount });
      dialogsStore.openDialog(dialogConfig);
    };

    const handleAction = ({ action, item }: { action: string; item: UserSettingsListItem }) => {
      let dialogConfig = {} as DialogConfig;
      switch (action) {
        case UserGroupAction.REMOVE_GROUPS:
          dialogConfig = confirmationDialogsConfigConstructor({
            action,
            disable: actionNotAllowed.value,
            text: i18n.t(`modals.${action}.description`),
            callback: userGroupsStore.removeGroups,
          });
          dialogConfig.header.title = i18n.t(`modals.${action}.title`, { n: 1 });
          dialogConfig.data.selection = {
            isAllPages: false,
            include: [item],
            exclude: [],
          };
          break;
        case UserGroupAction.ADD_NEW_GROUP:
          dialogConfig = componentDialogsConfigConstructor({
            action: action,
            component: AddGroupModal,
            width: ModalWidth.LARGE,
            callback: addNewGroup,
            disable: actionNotAllowed.value,
          });
          break;
      }

      dialogsStore.openDialog(dialogConfig);
    };

    return {
      userGroups,
      totalItems,
      totalPages,
      pagination,
      loading,
      selection,
      actionNotAllowed,
      userGroupsActions: [UserGroupAction.REMOVE_GROUPS],
      handleBulkAction,
      applyPaginationChange,
      handleAction,
    };
  },
});
</script>
