export enum MessageType {
  OPEN_SENSITIVE_FILES_ALLOWED_TO_ADMINS = "OPEN_SENSITIVE_FILES_ALLOWED_TO_ADMINS",
  VIEWER_USER_ADDED = "VIEWER_USER_ADDED",
  ADMIN_USER_ADDED = "ADMIN_USER_ADDED",
  CONTENT_SCAN_REPORT_READY = "CONTENT_SCAN_REPORT_READY",
  SERVICE_DISCONNECTED = "SERVICE_DISCONNECTED",
  SERVICE_NOT_MONITORED = "SERVICE_NOT_MONITORED",
  SERVICE_REMOVED = "SERVICE_REMOVED",
  NO_USERS_PROTECTED = "NO_USERS_PROTECTED",
  PROTECTED_USERS_MASS_REDUCTION = "PROTECTED_USERS_MASS_REDUCTION",
  LESS_THEN_8O_PERCENT_USERS_PROTECTED = "LESS_THEN_8O_PERCENT_USERS_PROTECTED",
  USER_SUSPENDED_BY_ADMIN = "USER_SUSPENDED_BY_ADMIN",
  USER_SIGNED_OUT_BY_ADMIN = "USER_SIGNED_OUT_BY_ADMIN",
  USER_SUSPENDED_FROM_SERVICE = "USER_SUSPENDED_FROM_SERVICE",
  MASS_USER_SIGNED_OUT_OF_SERVICE = "MASS_USER_SIGNED_OUT_OF_SERVICE",
  ABNORMAL_INCREASE_IN_EVENTS = "ABNORMAL_INCREASE_IN_EVENTS",
  MASS_FILE_COLLABORATION_BLOCKAGE = "MASS_FILE_COLLABORATION_BLOCKAGE",
  MASS_PHISHING_EMAILS_CLEANING = "MASS_PHISHING_EMAILS_CLEANING",
  ON_BOARDING_COMPLETED = "ON_BOARDING_COMPLETED",
  AUDIT_LOGS_DISABLED = "AUDIT_LOGS_DISABLED",
  AUDIT_LOGS_DISABLED_RESOLVED = "AUDIT_LOGS_DISABLED_RESOLVED",
  AUDIT_LOGS_INTERRUPTED = "AUDIT_LOGS_INTERRUPTED",
  AUDIT_LOGS_INTERRUPTED_RESOLVED = "AUDIT_LOGS_INTERRUPTED_RESOLVED",
  GOOGLE_DRIVE_SDK_DISABLED = "GOOGLE_DRIVE_SDK_DISABLED",
  GOOGLE_DRIVE_SDK_DISABLED_RESOLVED = "GOOGLE_DRIVE_SDK_DISABLED_RESOLVED",
  SERVICE_HAS_BEEN_DISCONNECTED = "SERVICE_HAS_BEEN_DISCONNECTED",
  SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED = "SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED",
  SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR = "SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR",
  ADDON_NOT_INSTALLED = "ADDON_NOT_INSTALLED",
  CONNECTOR_HAS_BEEN_DISCONNECTED = "CONNECTOR_HAS_BEEN_DISCONNECTED",
  MAX_PROTECTED_USERS_HAS_EXCEEDED = "MAX_PROTECTED_USERS_HAS_EXCEEDED",
}
