import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import {
  denormalizePermissionsData,
  denormalizePermissionsForCreation,
  mapDataLossPermissionCategory,
} from "@/_store/user-data-governance/adapter";
import type { UserDataGovernancePermissionConfig } from "@/_store/user-data-governance/adapter";
import type { PermissionsFormData } from "@/_store/user-data-governance/adapter";
import type { DataLossPermissionsNormalized } from "@/_store/user-data-governance/adapter";
import type { TicketTrigger } from "@/constants/tickets";
import cloneDeep from "lodash/cloneDeep";

export interface UserDataGovernanceSettings {
  monitorPII: boolean;
  monitorPCI: boolean;
  monitorPHI: boolean;
  monitorNPI: boolean;
  customData: {
    monitorPasswords: boolean;
    monitorCertificates: boolean;
    monitorSourceCodes: boolean;
    monitorSensitiveKeywords: boolean;
    sensitiveKeywords: string[];
    monitorSensitiveFileTypes: boolean;
    sensitiveFileTypes: string[];
  };
}

export interface UserDataGovernanceExclusions {
  emailSubjectKeywordsEnabled: boolean;
  emailSubjectKeywords: string[];
}

export interface UserDataGovernanceState {
  settings: UserDataGovernanceSettings;
  exclusions: UserDataGovernanceExclusions;
  permissions: DataLossPermissionsNormalized[];
  showSkeletonLoader: boolean;
}

const defaultUserDataGovernanceState = {
  settings: {
    customData: {
      monitorPasswords: false,
      monitorCertificates: false,
      monitorSourceCodes: false,
      monitorSensitiveKeywords: false,
      sensitiveKeywords: [],
      monitorSensitiveFileTypes: false,
      sensitiveFileTypes: [],
    },
    monitorPII: false,
    monitorPCI: false,
    monitorPHI: false,
    monitorNPI: false,
  },
  exclusions: {
    emailSubjectKeywordsEnabled: false,
    emailSubjectKeywords: [],
  },
  permissions: [],
  showSkeletonLoader: false,
  dataLossPermissions: {},
  exclusionsData: {},
};

export const useUserDataGovernanceModule = defineStore("userDataGovernanceModule", {
  state: (): UserDataGovernanceState => ({ ...defaultUserDataGovernanceState }),
  actions: {
    async getSettings(): Promise<void> {
      try {
        const response = await axiosInstance.request(api.userDataGovernanceSettings());
        this.settings = response.data;
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },
    async updateSettings(newSettings: UserDataGovernanceSettings): Promise<void> {
      const request = {
        ...api.userDataGovernanceSettings(),
        method: "put",
        data: newSettings,
      };

      try {
        const response = await axiosInstance.request(request);

        this.settings = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getExclusions(): Promise<void> {
      try {
        const response = await axiosInstance.request(api.userDataGovernanceExclusions());

        this.exclusions = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateExclusions(exclusions: UserDataGovernanceExclusions) {
      const request = {
        ...api.userDataGovernanceExclusions(),
        method: "put",
        data: exclusions,
      };

      try {
        await axiosInstance.request(request);

        this.exclusions = cloneDeep(exclusions);
      } catch (error) {
        console.error(error);
      }
    },
    async getDataLossPermissions(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;

      try {
        const { data } = await axiosInstance.request<
          Record<TicketTrigger, UserDataGovernancePermissionConfig>
        >(api.dataLossPermissions());
        this.permissions = Object.entries(data).flatMap(([key, value]) =>
          mapDataLossPermissionCategory([key as TicketTrigger, value])
        );
      } catch (error) {
        console.error(error);
      }

      this.showSkeletonLoader = false;
    },
    async addDataLossPermission(payload: PermissionsFormData) {
      const { holderType, data } = denormalizePermissionsForCreation(payload);

      const request = {
        ...api.dataLossPermissions(),
        method: "POST",
        params: {
          holderType,
        },
        data,
      };

      try {
        await axiosInstance.request(request);
        await this.getDataLossPermissions();
      } catch (error) {
        console.error(error);
      }
    },
    async updateDataLossPermissions(payload: DataLossPermissionsNormalized[]) {
      const denormalizedData = denormalizePermissionsData(payload);

      const request = {
        ...api.dataLossPermissions(),
        method: "put",
        data: denormalizedData,
      };

      try {
        await axiosInstance.request(request);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
