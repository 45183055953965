import { type Ticket, useTicketsStore } from "@/_store/tickets/tickets.module";
import {
  defaultFiltersState,
  type FilterContext,
  type SocTicketsFilters,
} from "@/_store/filters.module";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters";
import { useSelectorStore } from "@/_store/selector.module";
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { debounce, findIndex } from "lodash";
import { eventTriggerFilterMap, TicketStatus, TicketTrigger } from "@/constants/tickets";
import type { Pagination } from "@/types";
import { type RouteLocationNormalizedLoadedGeneric, useRoute, useRouter } from "vue-router";
import { handleVirtualScrollData } from "@/_helpers/utils";

export function useTicketsPage<T extends FilterContext.TICKETS | FilterContext.SOC_TICKETS>(
  filterContext: T,
  isSoc: boolean
) {
  const i18n = useI18n();
  const {
    localFilters,
    filtersUpdating,
    clearFilters: clearFiltersFromComposable,
  } = useFilters(filterContext);
  const ticketsStore = useTicketsStore();
  const selectorStore = useSelectorStore();
  const router = useRouter();
  const ticketPreview = ref<Ticket>();
  const workspaceSearchPage = ref(0);
  const hasMoreWorkspaces = ref(true);
  const workspaces = ref<{ workspaceId: string; workspaceName: string }[]>([]);
  const workspacesLoading = ref(false);

  const { selection } = storeToRefs(selectorStore);
  const { deselectAllPages } = selectorStore;

  const { tickets, pagination, allTickets, loading, showSkeletonLoader, ticketDetailsLoading } =
    storeToRefs(ticketsStore);

  const { resetState, getTickets, resetPagination } = ticketsStore;

  const showClearFiltersButton = computed(() => {
    return !isEqual(
      { ...localFilters.value, status: null },
      { ...defaultFiltersState[filterContext], status: null }
    );
  });

  const clearFilters = () => {
    localFilters.value = cloneDeep({
      ...defaultFiltersState[filterContext],
      status: localFilters.value.status,
    });
  };

  watch(
    localFilters,
    debounce(() => {
      deselectAllPages();
    }, 300),
    { deep: true }
  );

  const sortTriggers = (triggers: TicketTrigger[]) => {
    const sortedTriggers = triggers.sort((a, b) =>
      i18n.t(`tickets.eventTriggers.${a}`).localeCompare(i18n.t(`tickets.eventTriggers.${b}`))
    );
    if (sortedTriggers.includes(TicketTrigger.EMAIL_PHISHING)) {
      const index = findIndex(sortedTriggers, (e) => e === TicketTrigger.EMAIL_PHISHING);
      sortedTriggers.splice(index, 1);
      sortedTriggers.push(TicketTrigger.EMAIL_PHISHING);
    }
    return sortedTriggers;
  };

  const tabs = isSoc
    ? [
        { id: 0, name: i18n.t("general.open"), status: TicketStatus.OPEN },
        { id: 1, name: i18n.t("general.processed"), status: TicketStatus.CLOSED },
      ]
    : [
        { id: 0, name: i18n.t("general.open"), status: TicketStatus.OPEN },
        { id: 1, name: i18n.t("general.processed"), status: TicketStatus.CLOSED },
        { id: 2, name: i18n.t("general.all"), status: TicketStatus.ALL },
      ];

  const currentTab = computed(() => {
    return tabs.find((tab) => tab.status === localFilters.value.status)!;
  });

  const triggers = computed(() => {
    return localFilters.value.widget
      ? [
          {
            type: "subheader",
            value: i18n.t(`ticketDetails.filters.widgets.${localFilters.value.widget}`),
          },
          ...sortTriggers(eventTriggerFilterMap[localFilters.value.widget]).map((v) => ({
            type: v,
            value: v,
          })),
        ]
      : Object.entries(eventTriggerFilterMap).flatMap(([widget, triggers]) => [
          {
            type: "subheader",
            value: i18n.t(`ticketDetails.filters.widgets.${widget}`),
          },
          ...sortTriggers(triggers).map((v) => ({ type: v, value: v })),
        ]);
  });

  const showLoader = computed(() => {
    return showSkeletonLoader.value && ticketDetailsLoading.value;
  });

  watch(
    filtersUpdating,
    async (val) => {
      if (val) {
        resetPagination();
        await getTickets(false);
      }
    },
    { deep: true, immediate: true }
  );

  const onPageChanged = async (payload: Pagination) => {
    if (!isEqual(payload, pagination)) {
      ticketsStore.$patch({ pagination: payload });
      await getTickets(false);
    }
  };

  const applyQueryParams = (route: RouteLocationNormalizedLoadedGeneric) => {
    const result = cloneDeep(localFilters.value);

    Object.keys(localFilters.value).forEach((k) => {
      const param = route.query[k] as string;
      if (param) {
        const key = k as keyof typeof localFilters.value;
        if (Array.isArray(localFilters.value[key])) {
          Object.assign(result, { [key]: param.split(",") });
        } else {
          Object.assign(result, { [key]: param });
        }
      }
    });

    const startTime = route.query.startTime as string;
    const endTime = route.query.endTime as string;

    if (startTime && endTime) {
      result.eventTimeRange = {
        start: startTime,
        end: endTime,
      };
    }

    const shouldMakeRequest = isEqual(localFilters.value, result);
    localFilters.value = cloneDeep(result);

    return shouldMakeRequest;
  };

  const onWorkspaceSearchUpdate = debounce(function (searchString: string) {
    if (searchString !== (localFilters.value as SocTicketsFilters).workspaceId) {
      searchWorkspaces(searchString);
    }
  });

  async function searchWorkspaces(search = "") {
    workspaceSearchPage.value = 0;
    await fetchWorkspaces(search);
  }

  async function onIntersect(isIntersecting: boolean) {
    if (isIntersecting && hasMoreWorkspaces) {
      workspaceSearchPage.value += 1;
      await fetchWorkspaces((localFilters.value as SocTicketsFilters).workspaceId ?? "", true);
    }
  }

  async function fetchWorkspaces(search: string, virtualScroll = false) {
    workspacesLoading.value = true;
    const loadedWorkspaces = await ticketsStore.searchSocWorkspaces({
      search,
      page: workspaceSearchPage.value,
      pageSize: 25,
    });
    hasMoreWorkspaces.value = loadedWorkspaces.length > 25;
    workspaces.value = virtualScroll
      ? handleVirtualScrollData(workspaces.value, loadedWorkspaces, "workspaceId")
      : [...loadedWorkspaces];
    workspacesLoading.value = false;
  }

  onMounted(async () => {
    resetState();
    const route = useRoute();

    ticketsStore.$patch({ isSoc });

    const shouldMakeRequest = applyQueryParams(route);
    if (shouldMakeRequest) {
      await getTickets();
    }
    if (isSoc) {
      await searchWorkspaces();
    }
    // router replace doesn't work just raw in some cases. This manipulations are done in order to always clear the query
    await nextTick();
    await Promise.resolve(); // This ensures all previous awaits are fully resolved
    router.replace({ query: {} }).catch(() => {});
  });

  onBeforeUnmount(() => {
    clearFiltersFromComposable();
  });

  return {
    breadCrumbsItems: [
      {
        title: `‹ ${i18n.t("general.home")}`,
        disabled: false,
        to: { path: "/portal/dashboard" },
      },
    ],
    localFilters,
    filtersUpdating,
    showClearFiltersButton,
    clearFilters,
    getTickets,
    triggers,
    showLoader,
    tickets,
    allTickets,
    selection,
    pagination,
    loading,
    onPageChanged,
    tabs,
    currentTab,
    ticketPreview,
    workspaces,
    workspacesLoading,
    onWorkspaceSearchUpdate,
    onIntersect,
  };
}
