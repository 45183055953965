<template>
  <div class="wrapper">
    <v-card max-width="450" width="100%" class="px-6 pt-6 pb-10">
      <v-img
        src="/images/logos/coro-logo-large-primary.svg"
        width="110"
        height="36"
        contain
        class="mb-8"
      ></v-img>
      <div v-if="loading" class="align-center d-flex h-100 justify-center">
        <v-progress-circular size="120" indeterminate />
      </div>
      <div v-else class="d-flex flex-column align-center">
        <v-icon :icon="status ? iconMap[status] : Statuses.FAILURE" size="80" class="mb-2" />
        <div class="headline5 mb-2">
          {{ $t(`mobileActivation.${status}.title`) }}
        </div>
        <div class="body1 w-75 text-center mb-2" v-html="description"></div>
        <v-btn
          v-if="showResendButton"
          rounded
          class="mt-6"
          color="primary"
          density="default"
          size="large"
          elevation="0"
          @click="resendActivation()"
        >
          {{ $t(`mobileActivation.failure.resendButton`) }}
        </v-btn>
        <v-btn
          v-if="status === Statuses.SUCCESS && isMobile"
          rounded
          class="mt-6"
          color="primary"
          density="default"
          size="large"
          elevation="0"
          :href="magicLink"
        >
          {{ $t(`mobileActivation.success.openApp`) }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { parseJwt } from "@/_helpers/utils";
import { useMobileActivationStore } from "@/_store/mobile-activation.module";
import { useI18n } from "vue-i18n";

enum Statuses {
  SUCCESS = "success",
  FAILURE = "failure",
  ACTIVATION_LINK_SEND = "activationLinkSent",
}

export enum ActivateMobileErrorType {
  GENERIC = "GenericError",
  ACTIVATION_LINK_EXPIRED = "ActivationLinkExpired",
  PART_OF_WORKSPACE = "DeviceAlreadyActivated",
  NETWORK_MODULE_DISABLED = "NetworkModuleDisabled",
  WORKSPACE_ARCHIVED = "ArchivedWorkspace",
  WORKSPACE_IS_NOT_PROTECTED = "NotProtectedWorkspace",
}

const iconMap = {
  [Statuses.SUCCESS]: "$allGood",
  [Statuses.FAILURE]: "$alertTriangle",
  [Statuses.ACTIVATION_LINK_SEND]: "$emails",
};

export default defineComponent({
  props: {
    mobileToken: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const isMobile = computed(() => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    });
    const { activateMobile, resendActivationLink } = useMobileActivationStore();
    const status = ref<Statuses | null>(null);
    const loading = ref<boolean>(false);
    const errorType = ref<ActivateMobileErrorType>();
    const alreadyJoinedWorkspace = ref<string>();

    const parsedToken = computed<{ email: string; customerId: string }>(() => {
      return parseJwt(props.mobileToken);
    });

    const magicLink = computed(() => {
      const baseUrl: string = import.meta.env.VITE_API_HOST;
      return `${baseUrl.replace("customer-server", "online-server")}openMobileApp`;
    });

    const showResendButton = computed(() => {
      return (
        status.value === Statuses.FAILURE &&
        [ActivateMobileErrorType.GENERIC, ActivateMobileErrorType.ACTIVATION_LINK_EXPIRED].includes(
          errorType.value!
        )
      );
    });

    const description = computed(() => {
      if (status.value === Statuses.FAILURE) {
        return i18n.t(`mobileActivation.${Statuses.FAILURE}.errors.${errorType.value}`, {
          workspace: alreadyJoinedWorkspace.value,
        });
      }
      return isMobile.value
        ? i18n.t(`mobileActivation.${status.value}.mobileSubtitle`, {
            name: parsedToken.value.email,
          })
        : i18n.t(`mobileActivation.${status.value}.subtitle`, {
            name: parsedToken.value.email,
          });
    });

    onMounted(async () => {
      loading.value = true;
      try {
        await activateMobile(props.mobileToken, parsedToken.value.customerId);
        status.value = Statuses.SUCCESS;
      } catch (e: any) {
        handleError(e.response?.data);
      }
      loading.value = false;
    });

    const handleError = (err: { code: number; type: string; errors: [string] }) => {
      status.value = Statuses.FAILURE;
      if (!err) {
        errorType.value = ActivateMobileErrorType.GENERIC;
        return;
      }
      const [error] = err.errors;
      // Find the error by text
      errorType.value =
        Object.values(ActivateMobileErrorType).find((e) => error.includes(e)) ??
        ActivateMobileErrorType.GENERIC;
      // If the error is "PART_OF_WORKSPACE", extract workspace name from text
      if (errorType.value === ActivateMobileErrorType.PART_OF_WORKSPACE) {
        alreadyJoinedWorkspace.value = error.split(" ").at(-1);
      }
    };

    const resendActivation = async () => {
      loading.value = true;
      try {
        await resendActivationLink(parsedToken.value);
        status.value = Statuses.ACTIVATION_LINK_SEND;
      } catch (e: any) {
        handleError(e.response?.data);
      }
      loading.value = false;
    };

    return {
      parsedToken,
      status,
      Statuses,
      iconMap,
      loading,
      isMobile,
      magicLink,
      showResendButton,
      description,
      resendActivation,
    };
  },
});
</script>

<style scoped lang="scss">
.wrapper {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(*) {
  .icon-all-good:before {
    color: rgb(var(--v-theme-green-base)) !important;
  }
}
</style>
