<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlightable"
      :items="items"
      :fixed-header="true"
      :page="pagination.page + 1"
      :show-select="false"
      :headers-length="headers.length"
      item-key="sessionId"
      :loading="loading"
      :headers="headers"
      v-model:items-per-page="pagination.pageSize"
      :items-length="totalItems"
      @update:options="handlePaginationChange($event.page)"
    >
      <template #item="{ item, index }">
        <tr :key="item.sessionId" :data-testid="`table-row-${index}`" class="service-row">
          <td class="content-row">
            <div class="subtitle2 mt-3">{{ item.ip }}</div>
            <div class="body2 mb-3">
              {{ `${item.browser} ${$t("general.on")} ${item.os}` }}
            </div>
          </td>
          <td class="content-row">
            <div class="body2">{{ item.email }}</div>
            <div class="body3 text--semitransparent">
              {{ item.name }}
            </div>
          </td>
          <td class="content-row">
            <span class="body2"> {{ getCountry(item.country) }}</span>
          </td>
          <td class="content-row">
            <span class="body2"> {{ getFormattedDateTime(item.signedIn) }}</span>
          </td>
          <td class="content-row">
            <span class="body2">
              {{
                item.currentSession
                  ? $t("accessControlPage.activeSessions.table.currentSession")
                  : getFormattedDateTime(item.lastAccessed)
              }}</span
            >
          </td>
          <td class="text-right">
            <v-btn
              v-if="!item.currentSession"
              rounded
              color="primary"
              @click="onRevokeSessionClick(item)"
            >
              {{ $t("accessControlPage.activeSessions.table.revoke") }}
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { countriesList } from "@/constants/countries";
import {
  confirmationDialogsConfigConstructor,
  getFormattedDateTime,
  isActionRestricted,
} from "@/_helpers/utils";
import { defineComponent, type PropType, toRefs } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import { type ActiveSessionItem, useActiveSessionsStore } from "@/_store/active-sessions.module";
import { useI18n } from "vue-i18n";
import { ActiveSessionsAction } from "@/constants/active-session";
import { useDialogsStore } from "@/_store/dialogs.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<ActiveSessionItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["page-changed"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { totalItems, items, pagination } = toRefs(props);
    const dialogStore = useDialogsStore();
    const activeSessionStore = useActiveSessionsStore();
    const { showPagination, totalPages, page, handlePaginationChange } = usePageableTable(
      {
        totalItems,
        items,
        pagination,
      },
      (value: Pagination) => {
        emit("page-changed", value);
      }
    );

    const getCountry = (country: string) => {
      return countriesList[country];
    };

    const onRevokeSessionClick = (item: ActiveSessionItem) => {
      dialogStore.openDialog(
        confirmationDialogsConfigConstructor({
          item,
          action: ActiveSessionsAction.REVOKE,
          disable: isActionRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.ACTIVE_SESSIONS
          ),
          callback: activeSessionStore.revokeSession,
        })
      );
    };

    return {
      headers: [
        {
          title: i18n.t("accessControlPage.activeSessions.table.headers.ip"),
          key: "ip",
          sortable: false,
        },
        {
          title: i18n.t("accessControlPage.activeSessions.table.headers.user"),
          key: "user",
          sortable: false,
        },
        {
          title: i18n.t("accessControlPage.activeSessions.table.headers.country"),
          key: "country",
          sortable: false,
        },
        {
          title: i18n.t("accessControlPage.activeSessions.table.headers.signedIn"),
          key: "signedIn",
          sortable: false,
        },
        {
          title: i18n.t("accessControlPage.activeSessions.table.headers.lastAccessed"),
          key: "lastAccessed",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
      showPagination,
      totalPages,
      page,
      onRevokeSessionClick,
      handlePaginationChange,
      isActionRestricted,
      getFormattedDateTime,
      getCountry,
    };
  },
});
</script>

<style lang="scss" scoped></style>
