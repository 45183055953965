<template>
  <div class="margin-auto w-50 text-center mt-16">
    <v-progress-circular v-if="isSessionExpiredError" color="primary" indeterminate size="120" />
    <empty-state v-else class="margin-auto mt-12 w-65">
      <template #icon>
        <v-icon class="mr-1" size="80" icon="$redAlert"> </v-icon>
      </template>
      <template #description>
        <span v-html="$t('mdmCallbackPage.errors.generic')"></span>
      </template>
      <template #button>
        <v-btn large color="primary" rounded class="mt-10" @click="contactSupport()">
          {{ $t("mdmCallbackPage.contactSupport") }}
        </v-btn>
      </template>
    </empty-state>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, toRefs } from "vue";
import EmptyState from "@/components/EmptyState.vue";
import { useSettingsStore } from "@/_store/settings.module";

enum MdmError {
  SESSION_EXPIRED = "session_expired",
}

export default defineComponent({
  components: { EmptyState },
  props: {
    error: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { error } = toRefs(props);
    const supportLink = "https://docs.coro.net/";
    const isSessionExpiredError = computed(() => error.value === MdmError.SESSION_EXPIRED);

    onMounted(async () => {
      if (isSessionExpiredError.value) {
        await useSettingsStore().loginToMdm(false);
      }
    });

    const contactSupport = () => {
      window.open(supportLink, "_blank");
    };
    return {
      contactSupport,
      isSessionExpiredError,
    };
  },
});
</script>
