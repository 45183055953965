import { ref, watch, onMounted, onUnmounted } from "vue";
import Patterns from "@/constants/patterns";
import { hasSeparator } from "@/_helpers/utils";
import camelCase from "lodash/camelCase";
import trim from "lodash/trim";
import uniqBy from "lodash/uniqBy";
import { useUsersSettingsTabStore } from "@/_store/users-settings/users-tab.module";
import { useI18n } from "vue-i18n";
import type { GenericCallback, VuetifyFormRef } from "@/types";
import { Service } from "@/constants/cloud-apps";

export interface ComboboxUserItem {
  email: string;
  service: Service;
}

export function useAddUserModal(
  usersChangedCallback: GenericCallback,
  validationCallback: GenericCallback
) {
  const usersStore = useUsersSettingsTabStore();
  const i18n = useI18n();
  const users = ref<any[]>([]);
  const form = ref<VuetifyFormRef>();
  const valid = ref<boolean>(false);
  const currentInputValue = ref<string>("");
  const dataLoading = ref<boolean>(false);
  const localValue = ref<{ users: ComboboxUserItem[] }>({
    users: [],
  });

  const emailRules = [
    (values: { email: string }[]) => {
      if (values) {
        if (values.length === 0) {
          return i18n.t("validations.required");
        }

        for (const value of values) {
          if (!Patterns.EMAIL.test(value.email)) {
            return i18n.t("validations.email");
          }
        }
      }
      return true;
    },
  ];

  let timer: ReturnType<typeof setTimeout>;

  const validate = async () => {
    const validationResult = await form.value?.validate();
    validationCallback(!!validationResult?.valid);
    return validationResult?.valid;
  };

  const handleCommaDelimiterPaste = (newVal: string) => {
    const importedEmails = newVal
      .split(",")
      .filter((email) => email)
      .map((e) => {
        return {
          email: trim(e),
          service: Service.CORONET,
        };
      });
    currentInputValue.value = "";
    localValue.value.users = uniqBy([...localValue.value.users].concat(importedEmails), "email");
    localValue.value.users.forEach((u) => {
      validationCallback(Patterns.EMAIL.test(u.email));
    });
  };

  const handleChange = () => {
    localValue.value.users = localValue.value.users
      .filter((u) => !!u)
      .map((user: string | { email: string; service: Service }) => {
        if ((user as { email: string; service: Service }).email) {
          return user as { email: string; service: Service };
        }
        return {
          email: user as string,
          service: Service.CORONET,
        };
      });
  };

  const handleBlur = () => {
    if (!currentInputValue.value) return;
    const hasCommaSeparator = hasSeparator(currentInputValue.value);
    if (hasCommaSeparator) {
      currentInputValue.value = "";
    }
  };

  const getChipColor = (email: string) => {
    const isEmailValid = Patterns.EMAIL.test(email);
    return isEmailValid ? "indigo-faint" : "error";
  };

  // Watchers
  watch(
    localValue,
    (value) => {
      if (value.users?.length === 0) {
        users.value = [];
      }
      // setTimeout for emitting invalid if no users selected after email validation is fired.
      if (value.users) {
        setTimeout(() => {
          validationCallback(value.users.length > 0 && valid.value);
        });
      }
      usersChangedCallback(value);
    },
    { deep: true }
  );

  watch(currentInputValue, async (newVal: string) => {
    clearTimeout(timer);
    dataLoading.value = true;
    // delay new call 500ms
    timer = setTimeout(async () => {
      const hasCommaSeparator = hasSeparator(newVal);
      // if new value have comma delimiter, split it by comma,
      // We need it to be in debounce not to split value once user accidentally type comma when he types
      if (hasCommaSeparator) {
        handleCommaDelimiterPaste(newVal);
      }
      const response = await usersStore.searchUsers(newVal);
      users.value = response.data;
      dataLoading.value = false;
    }, 500);
  });

  // Lifecycle Hooks
  onMounted(() => {
    usersChangedCallback(localValue.value);
  });

  onUnmounted(() => {
    clearTimeout(timer);
  });

  return {
    users,
    form,
    valid,
    currentInputValue,
    dataLoading,
    localValue,
    emailRules,
    handleChange,
    validate,
    handleBlur,
    getChipColor,
    camelCase,
  };
}
