<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
    >
      <div class="d-flex justify-end">
        <filter-wrapper
          class="mb-5"
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters(clearFiltersCallback)"
        >
          <v-text-field
            v-model="localFilters.search"
            :class="{ 'filter-active': localFilters.search?.length }"
            density="compact"
            variant="outlined"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            :clearable="true"
            rounded
            clear-icon="icon-x"
            @click:clear="localFilters.search = ''"
          />
        </filter-wrapper>
      </div>
      <table-wrapper>
        <v-table class="white" density="comfortable">
          <template #top>
            <v-progress-linear
              :style="{ visibility: loading ? 'visible' : 'hidden' }"
              :indeterminate="true"
              height="2px"
            />
          </template>
          <template #default>
            <thead>
              <tr>
                <th class="text-left caption">
                  {{ $t("backOffice.adminUsers.table.headers.name") }}
                </th>
                <th class="text-left caption">
                  {{ $t("backOffice.adminUsers.table.headers.email") }}
                </th>
                <th class="text-left caption">
                  {{ $t("backOffice.adminUsers.table.headers.role") }}
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in globalAdmins" :key="item.id" class="service-row">
                <td class="content-row">
                  <div class="d-flex align-center">
                    <v-icon icon="$user" size="40" class="mr-4"></v-icon>
                    <span class="subtitle2">{{
                      item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : ""
                    }}</span>
                    <span v-if="!isNotCurrentAdmin(item)" class="subtitle2">
                      ({{ $t("backOffice.adminUsers.table.you") }})
                    </span>
                  </div>
                </td>
                <td class="content-row">
                  <span class="body2">{{ item.email }}</span>
                </td>
                <td class="content-row">
                  <span class="body2">{{ item.role?.roleName }}</span>
                </td>
                <td class="text-right" @click="$event.stopPropagation()">
                  <v-menu v-if="getAdminActions(item).length > 0" location="bottom left">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="$dots"
                        :data-testid="`admin-account-table-menu-${i}`"
                        class="item-clickable"
                        v-bind="props"
                      ></v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(action, index) in getAdminActions(item)"
                        :key="action"
                        :data-testid="`admin-account-table-menu-item-${index}`"
                        @click="handleAction(item, action)"
                      >
                        <v-list-item-title>
                          <span class="body2">
                            {{ $t(`backOffice.adminUsers.table.actions.${action}`) }}
                          </span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </table-wrapper>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import TableWrapper from "@/components/TableWrapper.vue";
import { defineComponent, onMounted, watch } from "vue";
import { GlobalAdminUsersAction } from "@/constants/admin-accounts";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
} from "@/_helpers/utils";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useAccountStore } from "@/_store/account.module";
import { ModalWidth } from "@/constants/modals";
import AddGlobalAdminUserModal from "@/components/modals/AddGlobalAdminUserModal.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { type GlobalAdmin, useBackOfficeStore } from "@/_store/back-office/back-office.module";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { GlobalRoleScopes, GlobalRoleScopeSection } from "@/_store/roles.module";
export default defineComponent({
  components: {
    FilterWrapper,
    TableWrapper,
  },
  setup() {
    const { localFilters, showClearFiltersButton, clearFilters, filtersUpdating } = useFilters(
      FilterContext.BACK_OFFICE_ADMIN_USERS
    );
    const backOfficeStore = useBackOfficeStore();
    const dialogsStore = useDialogsStore();
    const accountStore = useAccountStore();
    const { getGlobalAdmins, removeGlobalAdminUser, updateGlobalAdminUser } = backOfficeStore;
    const { account } = accountStore;
    const { globalAdmins, showSkeletonLoader, loading } = storeToRefs(backOfficeStore);

    const isNotCurrentAdmin = (item: GlobalAdmin) => {
      return item.email !== account.email;
    };
    const handleAction = (item: GlobalAdmin, action: GlobalAdminUsersAction) => {
      switch (action) {
        case GlobalAdminUsersAction.DELETE:
          dialogsStore.openDialog(
            confirmationDialogsConfigConstructor({
              item,
              action,
              callback: removeGlobalAdminUser,
              disable: isGlobalActionRestricted(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.GLOBAL_ADMIN_USERS,
                "remove"
              ),
            })
          );
          break;
        case GlobalAdminUsersAction.EDIT:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              item,
              action: GlobalAdminUsersAction.EDIT,
              component: AddGlobalAdminUserModal,
              width: ModalWidth.LARGE,
              callback: updateGlobalAdminUser,
              disable: isGlobalActionRestricted(
                GlobalRoleScopes.GLOBAL_SCOPE,
                GlobalRoleScopeSection.GLOBAL_ADMIN_USERS,
                "assignRoles"
              ),
            })
          );
          break;
      }
    };

    const clearFiltersCallback = async () => {
      await getGlobalAdmins();
    };

    const getAdminActions = (item: GlobalAdmin): GlobalAdminUsersAction[] => {
      if (isNotCurrentAdmin(item)) {
        return [GlobalAdminUsersAction.EDIT, GlobalAdminUsersAction.DELETE];
      }
      return [];
    };

    watch(
      filtersUpdating,
      async (updating: boolean) => {
        if (updating) {
          await getGlobalAdmins();
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      await getGlobalAdmins(true);
    });

    return {
      breadCrumbsItems: [
        {
          title: `‹ Control Panel`,
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      globalAdmins,
      showSkeletonLoader,
      loading,
      localFilters,
      showClearFiltersButton,
      clearFilters,
      clearFiltersCallback,
      isNotCurrentAdmin,
      getAdminActions,
      coronetSkeletonLoaderTypes,
      handleAction,
    };
  },
});
</script>

<style lang="scss" scoped>
.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}
</style>
