<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("soc.title") }}
      </div>
      <div>
        <v-btn
          rounded
          color="primary"
          density="default"
          size="large"
          elevation="0"
          v-if="showAddContactBtn"
          @click="addSocContactItem"
        >
          <v-icon icon="$add" class="mr-1"></v-icon>
          {{ $t("soc.contactsTab.addBtn") }}
        </v-btn>
      </div>
    </div>
    <PageTabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useRoute } from "vue-router";
import {
  SocContactClosureNotification,
  SocContactNotificationTypes,
  useSocContactStore,
} from "@/_store/soc/soc-contact.module";
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SocContactsActions } from "@/constants/soc";
import AddSocContactModal from "@/components/modals/AddSocContactModal.vue";
import { modalWidthByType } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import { storeToRefs } from "pinia";
import { ProtectionScopeModules, RolePermissionsScope } from "@/_store/roles.module";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const dialogsStore = useDialogsStore();
    const { addSocRemediationSettings } = useSocContactStore();
    const { items } = storeToRefs(useSocContactStore());
    const currentRoute = computed(() => route.name);
    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t("soc.tabs.contacts"),
        route: { name: RouteName.SOC_CONTACTS_TAB },
      },
      {
        id: 2,
        name: i18n.t("soc.tabs.remediation"),
        route: { name: RouteName.SOC_REMEDIATION_TAB },
      },
    ];
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        ProtectionScopeModules.MANAGED_SOC
      );
    });
    const addSocContactItem = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        item: {
          firstName: "",
          lastName: "",
          email: null,
          notification: SocContactNotificationTypes.EMAIL,
          closureNotification: SocContactClosureNotification.WEEKLY,
        },
        action: SocContactsActions.CREATE,
        component: AddSocContactModal,
        width: modalWidthByType.ADD_ALL_USERS,
        callback: addSocRemediationSettings,
        disable: actionNotAllowed.value,
        scrollable: true,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const showAddContactBtn = computed(() => {
      return currentRoute.value === RouteName.SOC_CONTACTS_TAB && items.value.length > 0;
    });
    return {
      tabs,
      currentRoute,
      addSocContactItem,
      showAddContactBtn,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped></style>
