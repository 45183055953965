<template>
  <div class="coro-widget">
    <div class="coro-widget__content">
      <div class="coro-widget__tile coro-widget__tile-content">
        <div class="mb-3">
          <div class="coro-widget-number text-left" data-testid="emails-widget-scanned-emails">
            {{ widgetData.processesCount.toLocaleString() }}
          </div>
          <div class="body2">
            {{ $t("dashboard.widgets.edr.processes") }} /
            {{ $t("dashboard.widgets.edr.last30Days") }}
          </div>
        </div>
        <div v-if="widgetData.topProcesses?.length" class="mb-3 mt-12">
          <div class="subtitle2 mb-4" data-testid="emails-widget-top-suspicious-domains">
            {{ $t("dashboard.widgets.edr.topProcesses") }}
          </div>
          <div v-for="process in widgetData.topProcesses" :key="process.name" class="mb-4">
            <div
              class="coro-link body2"
              :data-testid="`emails-widget-${kebabCase(process.name)}-link`"
              @click="viewProcess({ processName: process.name })"
            >
              {{ process.name }}
            </div>
            <div class="d-flex justify-start align-center">
              <coro-progress
                :value="process.percentage"
                :height="4"
                color="rgb(var(--v-theme-indigo-medium))"
              />
              <span class="ml-2 body2 text-primary">{{ process.count }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="coro-widget__tile">
        <vulnerabilities-tile
          :widget-data="widgetData"
          @all-resolved-link-clicked="viewAllTickets(false, ticketsWidgetFilters.EDR)"
          @all-active-link-clicked="viewAllTickets(true, ticketsWidgetFilters.EDR)"
          @vulnerability-clicked="viewVulnerability($event, ticketsWidgetFilters.EDR)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TicketsModuleFilter, TicketStatus } from "@/constants/tickets";
import CoroProgress from "@/components/CoroProgress.vue";
import { computed, defineComponent, type PropType } from "vue";
import VulnerabilitiesTile from "@/components/VulnerabilitiesTile.vue";
import kebabCase from "lodash/kebabCase";
import { useRouter } from "vue-router";
import { getCurrentDate, getNDaysAgo } from "@/constants/date-range-picker";
import type { WidgetConfig, WidgetData } from "@/_store/dashboard/dashboard.module";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useWidgetContent } from "@/composables/useWidgetContent";

export default defineComponent({
  props: {
    widgetData: {
      type: Object as PropType<WidgetData>,
      required: true,
    },
    widgetConfig: {
      type: Object as PropType<WidgetConfig>,
      required: true,
    },
  },
  components: {
    VulnerabilitiesTile,
    CoroProgress,
  },
  setup(props) {
    const router = useRouter();
    const showWidgetData = computed(() => {
      return props.widgetData && props.widgetData.isProtectionEnabled;
    });

    const { viewVulnerability, viewAllTickets, isAccessRestricted } = useWidgetContent();

    const viewProcess = (query: { processName: string }) => {
      router.push({
        name: "tickets",
        query: {
          from: "widget",
          status: TicketStatus.ALL,
          widget: TicketsModuleFilter.EDR,
          startTime: getNDaysAgo(30),
          endTime: getCurrentDate(),
          search: `process-name:${query.processName}`,
        },
      });
    };

    return {
      showWidgetData,
      viewProcess,
      viewVulnerability,
      viewAllTickets,
      isAccessRestricted,
      ticketsWidgetFilters: TicketsModuleFilter,
      rolePermissionsScope: RolePermissionsScope,
      kebabCase,
    };
  },
});
</script>

<style scoped lang="scss">
.coro-link {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
