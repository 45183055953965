<template>
  <v-form ref="form" v-model="valid" validate-on="lazy input">
    <div class="body1 mb-4">{{ $t("modals.selectChannel.description") }}</div>
    <v-radio-group v-model="localValue">
      <v-radio
        v-for="channel in channels"
        :key="channel"
        :label="$t(`modals.selectChannel.channelChoices.${channel}`)"
        :value="channel"
        density="default"
      ></v-radio>
    </v-radio-group>
  </v-form>
</template>

<script lang="ts">
import { AgentDeploymentChannel } from "@/constants/devices";
import { defineComponent, type PropType, ref, watch } from "vue";
import type { VuetifyFormRef } from "@/types";
import cloneDeep from "lodash/cloneDeep";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<AgentDeploymentChannel>>,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const valid = ref(true);
    const localValue = ref(cloneDeep(props.config.item));
    const form = ref<VuetifyFormRef | null>(null);
    watch(
      localValue,
      (value: AgentDeploymentChannel) => {
        emit("update:localValue", value);
      },
      { deep: true, immediate: false }
    );

    const validate = async () => {
      const validationResult = await form.value?.validate();
      return validationResult?.valid;
    };

    return {
      form,
      channels: [AgentDeploymentChannel.STABLE, AgentDeploymentChannel.ALL],
      valid,
      localValue,
      validate,
    };
  },
});
</script>
