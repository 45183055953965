import type { Pagination } from "@/types";
import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import { downloadFile, handleVirtualScrollData } from "@/_helpers/utils";
import { i18n } from "@/plugins/i18n";
import { useSnackbarStore } from "@/_store";
import type { DialogDataConfig } from "@/_store/dialogs.module";

export interface SiteToSiteTunnel {
  id: number | string;
  siteName: string;
  siteDescription: string;
  remoteGatewayIp: string;
  presharedKey: string;
  lifetimeKey: number;
  firewallType: string;
  ikeVersion: string;
  phase1Encryption: string;
  phase2Encryption: string;
  aggressiveMode: boolean;
  testDate?: number;
  testState?: SiteToSiteTunnelTestState;
  servers: SiteToSiteTunnelServer[];
  remoteNetworkIps: string[];
  status: SiteToSiteTunnelStatus;
}

export interface SiteToSiteTunnelServer {
  ip: string;
  passed?: boolean;
}

export enum SiteToSiteTunnelTestState {
  PASSED = "passed",
  FAILED = "failed",
}

export enum SiteToSiteTunnelStatus {
  CONNECTED = "CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  DISABLED = "DISABLED",
}

export interface SiteToSiteTunnelsStoreState {
  tunnelsList: SiteToSiteTunnel[];
  total: number;
  listLoading: boolean;
  showSkeletonLoader: boolean;
  pagination: Pagination;
}

const defaultTunnelsState: SiteToSiteTunnelsStoreState = {
  tunnelsList: [],
  total: 0,
  listLoading: false,
  showSkeletonLoader: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
};

export const useSiteToSiteTunnelStore = defineStore("siteToSiteTunnels", {
  state: (): SiteToSiteTunnelsStoreState => ({ ...defaultTunnelsState }),
  actions: {
    resetTunnelsList() {
      this.tunnelsList = [];
    },
    resetPagination() {
      this.pagination = { ...defaultTunnelsState.pagination };
    },
    async getTunnelsList(showSkeletonLoader = false, handleVirtualScroll = true) {
      this.showSkeletonLoader = showSkeletonLoader;
      const { page, pageSize } = this.pagination;
      try {
        const request = {
          ...api.sideToSideTunnels,
          params: {
            page,
            pageSize,
          },
        };
        const {
          data: { items, total },
        } = await axiosInstance.request(request);
        this.tunnelsList = handleVirtualScroll
          ? handleVirtualScrollData(this.tunnelsList, items, "id")
          : [...items];
        this.total = total;
      } catch (error) {
        console.error(error);
      } finally {
        this.showSkeletonLoader = false;
      }
    },
    async getRocSettings() {
      const request = {
        ...api.rocSettings,
      };

      return axiosInstance.request(request);
    },
    async editTunnel(payload: DialogDataConfig<SiteToSiteTunnel>) {
      this.listLoading = true;
      try {
        const request = {
          ...api.siteToSiteTunnel(payload.item.id),
          method: "PUT",
          data: getRequestDataForBe(payload.item),
        };
        await axiosInstance.request(request);
        this.resetTunnelsList();
        this.resetPagination();
        await this.getTunnelsList();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.sideToSideTunnels.listUpdated")
        );
      } catch (error) {
        console.error(error);
      }
      this.listLoading = false;
    },
    async addTunnel(payload: DialogDataConfig<SiteToSiteTunnel>) {
      this.listLoading = true;
      try {
        const request = {
          ...api.sideToSideTunnels,
          method: "POST",
          data: getRequestDataForBe(payload.item),
        };
        await axiosInstance.request(request);
        this.resetTunnelsList();
        this.resetPagination();
        await this.getTunnelsList();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.sideToSideTunnels.listUpdated")
        );
      } catch (error) {
        console.error(error);
      }
      this.listLoading = false;
    },
    async deleteTunnel(payload: DialogDataConfig<SiteToSiteTunnel>) {
      this.listLoading = true;
      try {
        const request = {
          ...api.siteToSiteTunnel(payload.item.id),
          method: "DELETE",
        };
        await axiosInstance.request(request);
        this.resetTunnelsList();
        this.resetPagination();
        await this.getTunnelsList();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.sideToSideTunnels.listUpdated")
        );
      } catch (error) {
        console.error(error);
      }
      this.listLoading = false;
    },
    async testTunnel(payload: SiteToSiteTunnel): Promise<SiteToSiteTunnelServer[]> {
      this.listLoading = true;
      try {
        const request = {
          ...api.testSideToSideTunnels(payload.id),
        };
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (error) {
        console.error(error);
        return [];
      } finally {
        this.listLoading = false;
      }
    },

    async validateSubnetRange(payload: string[]) {
      const requestConfig = api.validateSubnetRange(payload);
      try {
        const { data } = await axiosInstance.request(requestConfig);
        return data;
      } catch (e) {
        console.error(e);
        return true;
      }
    },

    async toggleTunnelDisabledState(payload: SiteToSiteTunnel, enable: boolean) {
      this.listLoading = true;
      try {
        const request = {
          ...api.toggleSiteToSiteTunnel(payload.id, enable),
        };
        await axiosInstance.request(request);
        this.resetTunnelsList();
        this.resetPagination();
        await this.getTunnelsList();
        useSnackbarStore().addGenericSuccess(
          i18n.global.t("snackbar.messages.sideToSideTunnels.listUpdated")
        );
      } catch (error) {
        console.error(error);
      }
      this.listLoading = false;
    },
    async downloadTunnelLogs(tunnel: SiteToSiteTunnel) {
      try {
        const request = {
          ...api.downloadTunnelLogs(tunnel.id),
        };
        const { data, headers } = await axiosInstance.request(request);
        const blob = new Blob([data], { type: headers["content-type"] });
        const fileName = `${tunnel.siteName}_logs.txt`;
        downloadFile(blob, fileName);
      } catch (error) {
        console.error(error);
      }
    },
  },
});

function getRequestDataForBe(data: SiteToSiteTunnel) {
  return {
    ...data,
    firewallType: "WatchGuard", // temporary solution, will be updated when list will be available
  };
}
