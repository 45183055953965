<template>
  <div class="content-md margin-auto mt-4 es-report">
    <date-range-picker
      v-model="localFilters.reportTimeFrame"
      :preset-dates="presets"
      :placeholder="$t('reports.executiveSummaryReport.dateRange')"
      class="mb-4 ml-auto range-picker"
    ></date-range-picker>
    <v-card class="pa-12" v-if="loading">
      <v-skeleton-loader color="transparent" type="heading,list-item-three-line" />
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader color="transparent" type="avatar, list-item-two-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader
        color="transparent"
        type="heading,list-item-three-line, article, article"
      />
      <v-skeleton-loader class="mx-3 rounded-lg" color="transparent" type="image" />
    </v-card>
    <v-card class="pa-12 mt-1" v-else-if="securedMessagesSummaryReport" id="report-card">
      <secured-messages-summary-general-info
        :version="reportVersion"
        :logo-path="logoPath"
        :workplace="account.customerName"
        :selected-date-range="formattedSelectedDateRange"
        :report-data="securedMessagesSummaryReport"
      />
      <div class="es-report__section-title mb-6">
        {{ $t("reports.securedMessagesSummaryReport.securedMessagesSentByUser") }}
      </div>

      <div class="es-report__top-vulnerabilities py-6 px-10 mb-14">
        <div class="w-50">
          <template v-for="{ email, count } in sortedProtectionUsersData" :key="email">
            <div class="d-flex justify-space-between text-primary py-2 protected-users-entry">
              <div class="caption">
                {{ email }}
              </div>
              <div class="caption">
                {{ count }}
              </div>
            </div>
            <v-divider class="border-opacity-100" color="white" />
          </template>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import moment from "moment";
import { ReportType } from "@/_store/reports.module";
import DateRangePicker from "@/components/DateRangePicker.vue";
import SecuredMessagesSummaryGeneralInfo from "@/components/reports/SecuredMessagesSummaryGeneralInfo.vue";
import { useReportsTab } from "@/composables/useReportsTab";

export default defineComponent({
  components: {
    SecuredMessagesSummaryGeneralInfo,
    DateRangePicker,
  },
  setup() {
    const {
      account,
      securedMessagesSummaryReport,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
    } = useReportsTab(ReportType.SECURED_MESSAGES);

    const sortedProtectionUsersData = computed(() => {
      const data = securedMessagesSummaryReport?.value?.protectedUsers ?? {};
      return Object.entries(data)
        .map(([email, count]) => ({ email, count }))
        .slice()
        .sort((a, b) => {
          return b.count - a.count;
        });
    });

    return {
      account,
      securedMessagesSummaryReport,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
      sortedProtectionUsersData,
      moment,
    };
  },
});
</script>

<style scoped lang="scss">
.es-report {
  .range-picker {
    max-width: 280px;
  }

  &__top-vulnerabilities {
    background-color: rgb(var(--v-theme-indigo-faint)) !important;
    border-radius: 8px;
  }

  &__section-title {
    border-radius: 4px;
    background: rgb(var(--v-theme-primary));
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    padding: 8px;
  }
}

:deep(*) {
  .coro-chip--default .coro-chip__content,
  .coro-chip--default.coro-chip__content {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
</style>
