<template>
  <div>
    <div class="d-flex">
      <div
        v-for="(_, index) in 4"
        :key="index"
        :style="{
          'background-color':
            score.value >= index ? score.color : 'rgb(var(--v-theme-indigo-faint))',
        }"
        class="strength-meter-section"
      />
    </div>
    <div v-if="score.value >= 0" class="strength-meter-label" :style="{ color: score.color }">
      {{ score.label }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from "vue";
import zxcvbn from "zxcvbn";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    password: {
      type: String,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const { password } = toRefs(props);

    const score = computed(() => {
      const passwordScore = zxcvbn(password.value || "").score;
      switch (passwordScore) {
        case 4:
          return {
            color: "rgb(var(--v-theme-green-dark))",
            value: 3,
            label: i18n.t("validations.strengthMeter.great"),
          };
        case 3:
          return {
            color: "rgb(var(--v-theme-green-light))",
            value: 2,
            label: i18n.t("validations.strengthMeter.good"),
          };
        case 2:
          return {
            color: "rgb(var(--v-theme-orange-light))",
            value: 1,
            label: i18n.t("validations.strengthMeter.soso"),
          };
        default:
          if (password.value) {
            return {
              color: "rgb(var(--v-theme-red-dark))",
              value: 0,
              label: i18n.t("validations.strengthMeter.veryWeak"),
            };
          }

          return { value: -1 };
      }
    });

    return {
      score,
    };
  },
});
</script>

<style lang="scss" scoped>
.strength-meter {
  &-section {
    height: 4px;
    width: 25%;

    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  &-label {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: right;
  }
}
</style>
