<template>
  <div v-if="aliases.length">
    <span>
      {{ aliases[0] }}
      <coro-popover :items="aliases" :title="$t('usersSettings.aliases')" :threshold="1" />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CoroPopover from "@/components/CoroPopover.vue";

export default defineComponent({
  components: {
    CoroPopover,
  },
  props: {
    aliases: {
      type: Array,
      default() {
        return [];
      },
    },
  },
});
</script>

<style lang="scss" scoped></style>
