<template>
  <table-wrapper>
    <v-data-table-server
      class="coronet-table--highlightable mb-10"
      :items="tunnelsList"
      v-model:expanded="expanded"
      :fixed-header="true"
      :items-length="total"
      :items-per-page="pagination.pageSize"
      :page="pagination.page + 1"
      :show-select="false"
      item-key="id"
      :headers="headers"
      show-expand
    >
      <!--Slot for table header-->
      <template #headers>
        <tr>
          <th class="w-5 pl-0 pr-0"></th>
          <th class="w-5 pl-0 pr-0"></th>
          <th class="pl-0 w-30">
            <span>
              {{ $t(`network.settings.tunnels.table.siteName`) }}
            </span>
          </th>
          <th class="w-30">
            <span>
              {{ $t(`network.settings.tunnels.table.remoteGateway`) }}
            </span>
          </th>
          <th class="w-20">
            <span>
              {{ $t(`network.settings.tunnels.table.status`) }}
            </span>
          </th>
          <th class="w-5"></th>
        </tr>
      </template>
      <!--Slot for rows-->
      <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
        <tr
          :id="`${index}`"
          :key="index"
          v-intersect="handleVirtualScroll"
          :data-testid="`table-row-${index}`"
          class="side-to-side-table-row"
          :row-disabled="isRowDisabled(item)"
        >
          <td>
            <v-icon
              class="ml-1"
              size="24"
              :class="{ rotated: isExpanded(internalItem) }"
              :data-testid="`tunnels-expander-btn-${index}`"
              icon="$chevronDown"
              @click="toggleExpand(internalItem)"
            >
            </v-icon>
          </td>
          <td class="pl-2 pr-2" :data-testid="`allow-list-table-name-col-${index}`">
            <v-icon size="43" icon="$globe"></v-icon>
          </td>
          <td class="pl-0">
            <span class="body2 text--semibold">{{ item.siteName }}</span>
          </td>
          <td>
            <span class="body2">{{ item.remoteGatewayIp }}</span>
          </td>
          <td>
            <span :class="getStateClass(item)">
              {{ $t(`network.settings.tunnels.table.${item.status}`) }}
            </span>
          </td>
          <td>
            <v-menu bottom left>
              <template v-slot:activator="{ props }">
                <v-icon icon="$dots" class="item-clickable" v-bind="props"></v-icon>
              </template>

              <v-list>
                <v-list-item
                  v-for="tunnelsAction in getActionsList(item)"
                  :key="tunnelsAction"
                  @click="handleItemAction(tunnelsAction, item)"
                >
                  <v-list-item-title :data-testid="`tunnels-table-${tunnelsAction}-action`">
                    {{ $t(`network.settings.tunnels.actions.${tunnelsAction}`) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
      <template #expanded-row="{ item }">
        <tr v-for="server in item.servers" :key="server.ip" :row-disabled="isRowDisabled(item)">
          <td></td>
          <td></td>
          <td class="pl-0">
            <span class="body2">
              {{ server.ip }}
            </span>
          </td>
          <td>
            <span class="body2"> - </span>
          </td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:bottom> </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { format } from "date-fns";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { computed, defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import {
  type SiteToSiteTunnel,
  SiteToSiteTunnelStatus,
  useSiteToSiteTunnelStore,
} from "@/_store/network/site-to-site-tunnels.module";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";
import { useI18n } from "vue-i18n";
import TableWrapper from "@/components/TableWrapper.vue";
import { SiteToSiteTunnelsAction } from "@/constants/network";

export default defineComponent({
  components: {
    TableWrapper,
  },
  emits: ["item-action-applied", "last-item-reached"],
  setup(_, { emit }) {
    const i18n = useI18n();
    const expanded = ref([]);
    const siteToSiteTunnelsStore = useSiteToSiteTunnelStore();
    const { tunnelsList, total, pagination } = storeToRefs(siteToSiteTunnelsStore);
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.NETWORK
      );
    });

    const getStateClass = (item: SiteToSiteTunnel) => ({
      "subtitle2 text-green-dark": item.status === SiteToSiteTunnelStatus.CONNECTED,
      "subtitle2 text-red-dark": item.status === SiteToSiteTunnelStatus.DISCONNECTED,
      "body2 text-indigo-medium": item.status === SiteToSiteTunnelStatus.DISABLED,
    });

    const handleItemAction = (action: SiteToSiteTunnelsAction, item: SiteToSiteTunnel) => {
      const payload = {
        item,
        action,
      };
      emit("item-action-applied", payload);
    };

    const getActionsList = (item: SiteToSiteTunnel) => {
      if (item.status === SiteToSiteTunnelStatus.DISABLED) {
        return [
          SiteToSiteTunnelsAction.EDIT,
          SiteToSiteTunnelsAction.ENABLE_TUNNEL,
          SiteToSiteTunnelsAction.DELETE,
        ];
      }
      return [
        SiteToSiteTunnelsAction.EDIT,
        SiteToSiteTunnelsAction.TEST,
        SiteToSiteTunnelsAction.DOWNLOAD_LOG,
        SiteToSiteTunnelsAction.DISABLE_TUNNEL,
        SiteToSiteTunnelsAction.DELETE,
      ];
    };

    const isRowDisabled = (item: SiteToSiteTunnel) => {
      return item.status === SiteToSiteTunnelStatus.DISABLED ? true : null;
    };

    const handleVirtualScroll = (isIntersecting: boolean, entries: IntersectionObserverEntry[]) => {
      const intersectedItemId = Number(entries[0].target.id);
      if (isIntersecting && intersectedItemId === tunnelsList.value.length - 1) {
        // last tab reached in backend, don't do new request
        if (intersectedItemId === total.value - 1) {
          return;
        }
        emit("last-item-reached");
      }
    };

    return {
      expanded,
      tunnelsList,
      total,
      pagination,
      actionNotAllowed,
      format,
      getStateClass,
      handleItemAction,
      handleVirtualScroll,
      getActionsList,
      isRowDisabled,
      headers: [
        { title: "", key: "data-table-expand", sortable: false },
        { title: "", key: "icon", sortable: false },
        {
          title: i18n.t("network.settings.tunnels.table.siteName"),
          key: "siteName",
          sortable: false,
        },
        {
          title: i18n.t("network.settings.tunnels.table.remoteGateway"),
          key: "remoteGateway",
          sortable: false,
        },
        {
          title: i18n.t("network.settings.tunnels.table.status"),
          key: "workspaceId",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  [row-disabled] {
    background-color: rgb(var(--v-theme-gray-faint));
  }
}
</style>
