<template>
  <v-form ref="form" v-model="valid" validate-on="input lazy">
    <div v-if="showInfoText" class="body1 mb-5" v-html="$t(`modals.${config.action}.infoText`)" />
    <div class="subtitle1">
      {{ $t(`modals.${config.action}.nameDescription`) }}
    </div>
    <div
      v-if="showDocumentationLink"
      class="body2 mt-1"
      v-html="$t(`modals.${config.action}.nameDescription2`)"
    />
    <v-text-field
      v-model.trim="localValue.item.value"
      data-testid="add-allowlist-modal-name"
      class="mt-3"
      :label="$t(`modals.${config.action}.nameLabel`)"
      :placeholder="$t(`modals.${config.action}.namePlaceholder`)"
      :rules="required"
      outlined
    />
    <div class="subtitle1 mt-5">
      {{ $t(`modals.${config.action}.descriptionTitle`) }}
      <span class="font-weight-regular"> ({{ $t(`general.optional`) }}) </span>
    </div>
    <v-text-field
      v-model="localValue.item.description"
      data-testid="add-allowlist-modal-description"
      class="mt-3"
      :label="$t(`modals.${config.action}.descriptionLabel`)"
      :placeholder="$t(`modals.${config.action}.descriptionPlaceholder`)"
      outlined
    >
    </v-text-field>
  </v-form>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { DeviceAllowBlockListTypes, DeviceAllowListActions } from "@/constants/devices";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { DeviceAllowBlockListItem } from "@/_store/endpoint-security/device-allow-block-list.module";
import Patterns from "@/constants/patterns";
import cloneDeep from "lodash/cloneDeep";

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const valid = ref(true);
    const form = ref();
    const type = computed(() => {
      switch (props.config.action) {
        case DeviceAllowListActions.ADD_FOLDER_RECORD:
          return props.config.item.isEdr
            ? DeviceAllowBlockListTypes.EDR_FOLDER
            : DeviceAllowBlockListTypes.FOLDER;
        case DeviceAllowListActions.ADD_FILE_RECORD:
          return props.config.item.isEdr
            ? DeviceAllowBlockListTypes.EDR_FILE
            : DeviceAllowBlockListTypes.FILE;
        default:
          return DeviceAllowBlockListTypes.PROCESS;
      }
    });
    const showInfoText = computed(() => {
      return (
        props.config.action === DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST ||
        props.config.action === DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST
      );
    });
    const localValue = ref<DialogDataConfig<DeviceAllowBlockListItem>>({
      item: {
        value: "",
        description: "",
        blocked:
          props.config.action === DeviceAllowListActions.ADD_PROCESS_RECORD ||
          props.config.action === DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST,
        type,
        valueType:
          props.config.action === DeviceAllowListActions.ADD_FILE_RECORD ? "path" : undefined,
      },
      action: props.config.action,
    });

    const required = reactive([
      (v: string) => {
        if (!v || v.length <= 0) {
          return i18n.t("validations.required");
        }

        if (
          props.config.action === DeviceAllowListActions.ADD_PROCESS_RECORD ||
          props.config.action === DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST
        ) {
          if (!Patterns.SHA_256.test(v) && !Patterns.CD_HASH.test(v)) {
            return i18n.t("validations.sha256OrCdhash");
          }
        }

        if (props.config.action === DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST) {
          if (!Patterns.SHA_256.test(v) && !Patterns.CD_HASH.test(v) && !Patterns.FILE_PATH(v)) {
            return i18n.t("validations.hashOrFilePath");
          }
        }

        if (props.config.action === DeviceAllowListActions.ADD_FILE_RECORD) {
          if (!Patterns.FILE_PATH(v)) {
            return i18n.t("validations.filePath");
          }
        }

        return true;
      },
    ]);
    const validate = async () => {
      const { valid } = await form.value.validate();
      return valid;
    };

    const showDocumentationLink = computed(() => {
      return (
        props.config.action === DeviceAllowListActions.ADD_FILE_RECORD ||
        props.config.action === DeviceAllowListActions.ADD_FOLDER_RECORD
      );
    });

    watch(
      localValue,
      (newVal) => {
        const newValue = cloneDeep(newVal);
        if (props.config.action === DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST) {
          if (
            Patterns.SHA_256.test(newVal.item.value) ||
            Patterns.CD_HASH.test(newVal.item.value)
          ) {
            newValue.item.type = DeviceAllowBlockListTypes.PROCESS;
          }
          if (Patterns.FILE_PATH(newVal.item.value)) {
            newValue.item.type = DeviceAllowBlockListTypes.EDR_FOLDER;
          }
        }

        emit("update:localValue", newValue);
      },
      { deep: true, immediate: true }
    );
    watch(
      valid,
      (newVal) => {
        if (newVal === null) return;
        emit("update:valid", newVal);
      },
      { deep: true, immediate: false }
    );

    return {
      localValue,
      valid,
      form,
      required,
      validate,
      showInfoText,
      showDocumentationLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.period-select {
  width: 172px;
}
</style>
