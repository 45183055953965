import cloneDeep from "lodash/cloneDeep";
import { defaultFiltersState, useFiltersStore } from "@/_store/filters.module";
import type { FilterContext, FiltersState } from "@/_store/filters.module";
import { computed, ref, watch } from "vue";
import { debounce } from "lodash";
import isEqual from "lodash/isEqual";
import type { Ref } from "vue";
import { SearchSuggestion } from "@/constants/general";
import Patterns from "@/constants/patterns";

// TODO: remove this and refactor to something simpler
export function useFilters<K extends FilterContext>(
  context: K,
  searchMinimumCharacters: number = 3
) {
  const { resetFilters, setFilters, filters } = useFiltersStore();
  const defaultFilters: FiltersState[K] = cloneDeep(defaultFiltersState[context]);
  // pass this ref to the filters in your template
  const localFilters = ref(cloneDeep({ ...filters[context] })) as Ref<FiltersState[K]>;

  /** watch this flag for changes in your component for API calls
   *  Example:
   *  watch(
   *    filtersUpdating,
   *    async (value) => {
   *      if (value) {
   *        await getItems();
   *      }
   *    },
   *    {  deep: true }
   *  )
   */
  const filtersUpdating = ref(false);

  // We need this for watch() to get correct previous values
  const clonedFilters = computed(() => {
    return cloneDeep(localFilters.value);
  });

  const showClearFiltersButton = computed(() => {
    return !isEqual(localFilters.value, defaultFilters);
  });

  const updateFilters = (filters: FiltersState[K]) => {
    setFilters(context, filters);
  };

  const clearFilters = async (callback: Function = () => {}) => {
    resetFilters(context);
    localFilters.value = cloneDeep(defaultFilters);
    await callback();
  };

  /**
   * This watcher observes changes to the filters associated with the specified context.
   * When a change is detected, it updates `localFilters` with a deep clone of the new filter values only if they differ
   * from the current `clonedFilters`. This mechanism allows modifications to the filters outside of this component,
   * ensuring that changes are reflected in the component's state and the corresponding filters are applied.
   */
  watch(
    () => filters[context],
    (newVal) => {
      if (!isEqual(newVal, clonedFilters.value)) {
        localFilters.value = cloneDeep(newVal);
      }
    }
  );

  watch(
    clonedFilters, // equals to localFilters
    debounce((newVal, oldVal) => {
      if (
        (!newVal.search || getSearchCharacterCount(newVal.search) >= searchMinimumCharacters) &&
        !isEqual(newVal, oldVal)
      ) {
        updateFilters(newVal);
        if (filtersUpdating.value) {
          // Flip the flag to reliably trigger watch in component
          filtersUpdating.value = false;
        }
        filtersUpdating.value = true;
      }
    }, 500),
    { deep: true }
  );

  return {
    localFilters,
    filtersUpdating,
    showClearFiltersButton,
    defaultFilters,
    clearFilters,
    updateFilters,
  };
}

function getSearchCharacterCount(search: string): number {
  if (Object.values(SearchSuggestion).some((str) => search.startsWith(str))) {
    const match = search.match(Patterns.SEARCH_PREFIX);
    // @ts-ignore
    return search.replace(match[0], "").trim().length;
  }

  return search?.length;
}
