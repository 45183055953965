<template>
  <div class="import-url-group-modal">
    <div
      v-if="csvLoaded && !config.item.showDownloadReport"
      class="d-flex w-100 flex-column align-center text-center"
    >
      <template v-if="!csvLoadError">
        <v-icon size="70" class="mb-6 success-icon" icon="$allGood" />
        <div class="headline6 text-center px-6">
          {{ $t(`modals.${config.action}.importInProgress`) }}
        </div>
        <v-btn color="primary" class="mt-7" rounded size="large" @click="$emit('dismissed')">
          {{ $t("general.gotIt") }}
        </v-btn>
      </template>
    </div>
    <div v-else>
      <div v-if="csvLoading" class="pt-12 pb-12 pl-12 pr-12 text-center">
        <v-progress-linear class="mt-12" rounded height="10" indeterminate />
        <div class="headline6 mt-4">
          {{ $t(`modals.${config.action}.parsingFile`) }}
        </div>
      </div>
      <template v-else>
        <v-form ref="form" v-model="valid" validate-on="input lazy" @submit.prevent>
          <draggable-file-uploader
            v-if="!csvLoaded && !csvLoadError"
            :description="$t(`modals.${config.action}.uploadTxt`)"
            :subtitle="
              $t(`modals.${config.action}.subtitle`, {
                size: humanizeFileSize(config.item.maxFileSize) || '1 MB',
              })
            "
            :class="{ 'error-border': !!errorMessage }"
            :error-message="errorMessage"
            @file-changed="handleFileUpload($event)"
          />
          <div v-else class="csv-upload-report pl-4 pt-6 pr-4 pb-6">
            <div class="body1" mb-1>{{ file?.name }}</div>
            <div class="body1 text-indigo-medium mb-1">{{ humanizeFileSize(file?.size) }}</div>
            <div v-if="!invalidRecords" class="d-flex align-center">
              <v-icon size="24" icon="$circleCheck" class="mr-2" />
              <span class="body2 text-green-dark">
                {{
                  $t(`modals.${config.action}.successfullyUploaded`, { n: successfullyUploaded })
                }}
              </span>
            </div>
            <div v-else>
              <div class="d-flex align-center">
                <v-icon size="24" icon="$redAlert" class="mr-1" />
                <span class="body2 text-red-dark">
                  {{ $t(`modals.${config.action}.notAllRecordsUploaded`) }}
                </span>
              </div>
              <div class="pl-7">
                <div class="body2 text-indigo-medium">
                  {{
                    $t(`modals.${config.action}.invalidRecords`, {
                      n: invalidRecords,
                      sum: invalidRecords + successfullyUploaded,
                    })
                  }}
                </div>
                <a class="coro-link" @click="handleFileImport()">{{ $t("general.tryAgain") }}</a>
              </div>
            </div>
          </div>
          <div v-if="!(successfullyUploaded && !invalidRecords)" class="body3 mt-2">
            <a class="coro-link" @click="downloadTemplate()">{{
              $t(`modals.${config.action}.downloadTemplate`)
            }}</a>
            {{ $t(`modals.${config.action}.disclaimer`) }}
          </div>
          <div class="d-flex justify-end mt-5 mb-3">
            <template v-if="!csvLoaded && !csvLoadError">
              <v-btn
                data-testid="modal-cancel-button"
                color="primary"
                :ripple="false"
                variant="text"
                @click="$emit('dismissed')"
              >
                {{ $t("general.cancel") }}
              </v-btn>
              <v-btn
                rounded
                color="primary"
                data-testid="modal-action-button"
                size="large"
                :disabled="disable"
                @click="handleFileImport()"
              >
                {{ $t(`modals.${config.action}.actionBtn`) }}
              </v-btn>
            </template>
            <v-btn
              v-else
              rounded
              class="close-btn"
              color="primary"
              data-testid="modal-action-button"
              size="large"
              @click="$emit('dismissed')"
            >
              {{ $t("general.close") }}
            </v-btn>
          </div>
        </v-form>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import DraggableFileUploader from "@/components/DraggableFileUploader.vue";
import { computed, defineComponent, type PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useImportCsvModal } from "@/composables/useImportCsvModal";
import { DataSizeUnit } from "@/constants/general";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { humanizeFileSize } from "@/_helpers/utils";

export default defineComponent({
  components: {
    DraggableFileUploader,
  },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["dismissed"],
  setup(props) {
    const valid = ref(true);
    const form = ref();
    const isFormSubmitted = ref(false);

    const i18n = useI18n();

    const {
      file,
      csvLoaded,
      csvLoading,
      csvLoadError,
      handleFileUpload,
      handleImport,
      invalidRecords,
      successfullyUploaded,
    } = useImportCsvModal();

    const errorMessage = computed(() => {
      if (isFormSubmitted.value) {
        if (!file.value) {
          return i18n.t("validations.missingFile");
        }
        if (file.value.size > (props.config.item.maxFileSize || DataSizeUnit.MB)) {
          return i18n.t("validations.fileSizeError");
        }
      }

      return "";
    });

    const handleFileImport = async () => {
      isFormSubmitted.value = true;
      if (!file.value || !!errorMessage.value || !form.value.validate()) {
        return;
      }

      await handleImport(file.value, props.config.item.uploadCallback);
    };

    const downloadTemplate = async () => {
      await props.config.item.downloadTemplateCallback();
    };

    return {
      form,
      valid,
      file,
      csvLoaded,
      csvLoading,
      csvLoadError,
      errorMessage,
      handleFileUpload,
      handleFileImport,
      downloadTemplate,
      invalidRecords,
      successfullyUploaded,
      humanizeFileSize,
    };
  },
});
</script>

<style lang="scss" scoped>
.import-url-group-modal {
  min-height: 300px;
}

.success-icon:before {
  color: rgb(var(--v-theme-green-base));
}

.error-border {
  border: solid 1px rgb(var(--v-theme-red-dark));
}

.csv-upload-report {
  border: solid 1px rgb(var(--v-theme-indigo-pale));
  border-radius: 8px;
}

.close-btn {
  bottom: 0;
  position: absolute;
  margin-bottom: 24px;
}
</style>
