import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Paged, Pagination } from "@/types";
import {
  type DevicesFilters,
  type ScheduledReportsFilters,
  type TicketsFilters,
  type UsersSettingsFilters,
  useFiltersStore,
} from "@/_store/filters.module";
import type { ActivityLogsFilters } from "@/_store/activity-logs.module";
import { ExportCategory } from "@/_store/exports.module";
import type { ReportType } from "@/_store/reports.module";
import { useAccountStore } from "@/_store/account.module";
import type { MspAdminUser } from "@/_store/msp-admin-users.module";
import type { AdminUser } from "@/_store/admin-users.module";
import type { Service } from "@/constants/cloud-apps";
import type { UserSettingsFilterItem } from "@/_store/users-settings/users-settings.module";

export type ScheduledReportsListItem = ScheduledReportItem | ScheduledExportItem;

export type ReportTypeFilterItem =
  | { type: ScheduledType.REPORT; subType: ReportType }
  | { type: ScheduledType.EXPORT; subType: ExportCategory };

export enum ScheduledType {
  REPORT = "report",
  EXPORT = "export",
}

export interface BaseScheduledReportItem {
  id: string;
  name: string;
  createdBy: string;
  type: ScheduledType;
  frequency: ScheduledReportFrequency;
  scope?: ScheduledReportScope;
  recipients: string[];
}

export interface ScheduledReportItem extends BaseScheduledReportItem {
  type: ScheduledType.REPORT;
  subType: ReportType;
}

export interface ScheduledExportItem extends BaseScheduledReportItem {
  type: ScheduledType.EXPORT;
  subType: ExportCategory;
  filters: ScheduledExportItemFilters;
}

export interface ScheduledExportItemFilters {
  activityLogs: ActivityLogsFilters;
  devices: DevicesFilters;
  users: UsersSettingsFilters;
  tickets: TicketsFilters;
}

export enum ScheduledReportFrequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

export enum ScheduledReportScope {
  WORKSPACE = "workspace",
  WORKSPACE_AND_DIRECT_DESCENDANTS = "workspaceAndDirectDescendants",
  WORKSPACE_AND_ALL_DESCENDANTS = "workspaceAndAllDescendants",
}

interface ScheduledReportsState {
  loading: boolean;
  scheduledReportsList: Paged<ScheduledReportsListItem>;
  pagination: Pagination;
}

const defaultReportsState: ScheduledReportsState = {
  loading: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  scheduledReportsList: {
    items: [],
    total: 0,
  },
};

export const useScheduledReportsStore = defineStore("scheduledReports", {
  state: (): ScheduledReportsState => ({ ...defaultReportsState }),
  actions: {
    async getScheduledReportsList() {
      const { page, pageSize } = this.pagination;
      const filters = convertFiltersForBackend(useFiltersStore().filters.scheduledReportsFilters);
      const request = {
        ...api.scheduledReports(),
        params: {
          page,
          pageSize,
          ...filters,
        },
      };
      try {
        this.loading = true;
        const { data } = await axiosInstance.request(request);
        this.scheduledReportsList = data;
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    async addScheduledReport(data: ScheduledExportItem | ScheduledReportItem): Promise<void> {
      try {
        const request = {
          ...api.scheduledReports(),
          data: convertDataForBE(data),
          method: "POST",
        };
        await axiosInstance.request(request);
        this.pagination = { ...defaultReportsState.pagination };
        await this.getScheduledReportsList();
      } catch (e) {
        console.error(e);
      }
    },
    async deleteScheduledReport(id: string): Promise<void> {
      try {
        const request = {
          ...api.scheduledReport(id),
          method: "DELETE",
        };
        await axiosInstance.request(request);
        this.pagination = { ...defaultReportsState.pagination };
        await this.getScheduledReportsList();
      } catch (e) {
        console.error(e);
      }
    },
    async editScheduledReport(data: ScheduledExportItem | ScheduledReportItem): Promise<void> {
      try {
        const request = {
          ...api.scheduledReport(data.id),
          data,
          method: "PUT",
        };
        await axiosInstance.request(request);
        this.pagination = { ...defaultReportsState.pagination };
        await this.getScheduledReportsList();
      } catch (e) {
        console.error(e);
      }
    },
    async searchSpecificAdministrators(
      search: string,
      scope?: ScheduledReportScope
    ): Promise<AdminUser[]> {
      if (
        scope === ScheduledReportScope.WORKSPACE_AND_DIRECT_DESCENDANTS ||
        scope === ScheduledReportScope.WORKSPACE_AND_ALL_DESCENDANTS
      ) {
        try {
          const { data } = await axiosInstance.request<Paged<MspAdminUser>>({
            ...api.mspAdminUsers(),
            params: {
              workspaceId: useAccountStore().account.workplace,
              search,
              page: 0,
              pageSize: 25,
            },
          });
          return data.items;
        } catch (e) {
          console.error(e);
          return [];
        }
      } else {
        try {
          const { data } = await axiosInstance.request({
            ...api.adminUsers,
            params: { search },
          });
          return data;
        } catch (e) {
          console.error(e);
          return [];
        }
      }
    },
  },
});

function convertFiltersForBackend({ search, types }: ScheduledReportsFilters) {
  return {
    search,
    subTypes: types?.join(","),
  };
}

function convertDataForBE(data: ScheduledExportItem | ScheduledReportItem) {
  if (data.type === ScheduledType.EXPORT && data.subType === ExportCategory.USERS) {
    data.filters.users.cloudServices = data.filters.users.cloudServices
      .filter((v) => v.type === "cloudService")
      .map((v) => v.name as Service) as unknown as UserSettingsFilterItem[];

    return data;
  }
  return data;
}
