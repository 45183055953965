<template>
  <v-form ref="formRef" v-model="valid">
    <v-combobox
      v-model="localValue.item.recipients"
      :items="recipients"
      :rules="emailRules"
      class="mb-6"
      variant="outlined"
      multiple
      :placeholder="$t('ticketDetails.recipients')"
      :label="$t('ticketDetails.recipients')"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :closable="true"
          variant="flat"
          size="default"
          close-icon="$closeCircle"
          color="indigo-faint"
          :text="item.title"
        >
          <div class="d-flex align-center">
            <span class="ml-1 mr-2">{{ item.title }}</span>
          </div>
        </v-chip>
      </template>
      <template #item="{ item, props }">
        <v-list-item v-bind="props" :title="item.title"> </v-list-item>
      </template>
    </v-combobox>

    <v-textarea
      v-model="localValue.item.contactMessage"
      variant="outlined"
      :label="$t('modals.contactUser.placeholder')"
      :rules="[required()]"
      :placeholder="$t('modals.contactUser.placeholder')"
    >
    </v-textarea>

    <v-checkbox
      v-model="localValue.item.includeEventDetails"
      :label="$t('modals.contactUser.includeEventDetails')"
    >
    </v-checkbox>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, type PropType } from "vue";
import Patterns from "@/constants/patterns";
import type { VuetifyFormRef } from "@/types";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";
import { required } from "@/_helpers/validators";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<
        DialogDataConfig<{
          includeEventDetails: boolean;
          contactMessage: string;
          recipients: string[];
          id: string;
          by: "event";
          isDeviceEvent?: boolean;
        }>
      >,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const localValue = ref({
      ...props.config,
      item: {
        ...props.config.item,
        includeEventDetails: true,
        contactMessage: "",
        recipients: props.config.item.isDeviceEvent
          ? props.config.item.recipients.slice(0, 1)
          : props.config.item.recipients,
      },
    });
    const valid = ref(false);
    const formRef = ref<VuetifyFormRef>();
    const recipients = computed(() => props.config.item.recipients);

    const emailRules = [
      (emails: string[]) => {
        if (emails) {
          if (emails.length === 0) return i18n.t("validations.required");
          for (const value of emails) {
            if (!Patterns.EMAIL.test(value)) {
              return i18n.t("validations.email");
            }
          }
        }
        return true;
      },
    ];

    watch(
      localValue,
      (newValue) => {
        emit("update:localValue", newValue);
      },
      { deep: true }
    );

    const validate = async () => {
      const validationResult = await formRef.value?.validate();

      return validationResult?.valid;
    };

    return {
      localValue,
      valid,
      required,
      emailRules,
      recipients,
      validate,
      formRef,
    };
  },
});
</script>
