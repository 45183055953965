<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
    <v-combobox
      v-model="localValue.names"
      v-model:search="currentInputValue"
      class="mt-3 coro-scrollable-combobox"
      :label="$t('modals.allowBlockListSwg.urlLabel')"
      :placeholder="$t('modals.allowBlockListSwg.urlPlaceholder')"
      :rules="rules"
      :counter="50"
      hide-details="auto"
      autofocus
      variant="outlined"
      multiple
      chips
      no-filter
      hide-no-data
      @blur="handleBlur"
    >
      <template #chip="{ props, item }">
        <v-chip
          v-bind="props"
          variant="flat"
          :color="getChipColor(item.raw)"
          closable
          close-icon="$closeCircle"
        >
          <div class="d-flex align-center justify-space-between">
            <span class="ml-1 mr-2" v-text="item.raw" />
          </div>
        </v-chip>
      </template>
    </v-combobox>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, type Ref, ref, watch } from "vue";
import Patterns from "@/constants/patterns";
import { useI18n } from "vue-i18n";
import { hasSeparator } from "@/_helpers/utils";
import uniq from "lodash/uniq";

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["input", "update:valid", "update:localValue"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const valid = ref(false);
    const localValue: Ref<{ names: string[]; allowed?: boolean; action: string }> = ref({
      names: [],
      action: props.config.action,
    });
    const currentInputValue = ref("");

    onMounted(() => {
      localValue.value.allowed = props.config.item.allowed;
    });

    watch(
      localValue,
      (newVal) => {
        if (newVal?.names?.length) {
          setTimeout(() => emit("update:valid", newVal.names.length > 0 && valid.value));
        }
        const { names, allowed } = localValue.value;
        emit("update:localValue", { names, allowed });
      },
      { deep: true }
    );

    watch(valid, (newValid) => {
      emit("update:valid", newValid);
    });

    watch(currentInputValue, (newVal) => {
      if (!newVal) {
        return;
      }
      const hasCommaSeparator = hasSeparator(newVal);

      if (!hasCommaSeparator) {
        const isValid = matchWildcardDomain(newVal);
        emit("update:valid", isValid);
      }
      if (hasCommaSeparator) {
        handleCommaDelimiterPaste(newVal);
      }
    });

    const rules = [
      (values: string[]) => {
        if (!values || !values.length) return t("validations.required");
        if (values.length > 50) return t("validations.maximumNumberOfItemsExceeded");

        for (const value of values) {
          if (!matchWildcardDomain(value)) {
            return t("validations.domain");
          }
        }
        return true;
      },
    ];

    const handleCommaDelimiterPaste = (newVal: string) => {
      const importedDomains = newVal
        .split(",")
        .filter((domain) => domain)
        .map((v) => v.trim());
      currentInputValue.value = "";
      localValue.value.names = uniq([...localValue.value.names, ...importedDomains]);
      const isInValid = localValue.value.names.some((name) => !matchWildcardDomain(name));
      emit("update:valid", !isInValid);
    };

    const handleBlur = () => {
      if (!currentInputValue.value) return;
      const hasCommaSeparator = hasSeparator(currentInputValue.value);
      if (hasCommaSeparator) {
        currentInputValue.value = "";
      }
    };

    const matchWildcardDomain = (val: string): boolean => {
      return Patterns.WILDCARD_DOMAIN.test(val);
    };

    const getChipColor = (domain: string) => {
      return matchWildcardDomain(domain) ? "indigo-faint" : "error";
    };

    return {
      valid,
      localValue,
      currentInputValue,
      rules,
      handleCommaDelimiterPaste,
      handleBlur,
      getChipColor,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-autocomplete.coro-scrollable-combobox {
    .v-text-field__details {
      display: flex !important;
    }
  }
}
</style>
