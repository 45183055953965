<template>
  <div class="margin-auto content-sm" data-testid="invited-page-container">
    <div class="d-flex justify-center mt-16">
      <div v-if="!inviteAccepted" class="d-flex flex-column align-center">
        <div class="mt-8 mb-3 w-100">
          <div class="headline4 text-center">
            {{ $t("invitedPage.welcomeToCoro") }}
          </div>
        </div>
        <div class="text-center">
          <div class="body2 mb-3" v-html="invitedText"></div>
          <div v-if="error" class="error-block d-flex mb-3 mt-6 align-center w-100">
            <v-icon class="mr-3 ml-4" icon="$warning" />
            <span class="body2 text-red-dark mr-2">
              {{ $t(`invitedPage.errors.${error}`, { admin: email }) }}
            </span>
          </div>
        </div>
        <v-form
          ref="termsOfUseForm"
          v-model="isTermsOfUseFormValid"
          class="d-flex flex-column align-center"
        >
          <v-checkbox
            v-model="isTermsOfUseChecked"
            data-testid="scan-email-checkbox"
            :ripple="false"
            class="mt-3 mb-1"
            :rules="required"
          >
            <template #label>
              <div
                class="caption text-center align-center terms-of-use-label"
                :class="{ 'text-error': isTermsOfUseFormValid === false }"
                @click.stop
              >
                <span>{{ $t("invitedPage.termsOfUseDescription") }} </span>
                <a
                  class="text-primary text-decoration-underline ml-1"
                  :class="{ 'text-red-dark': isTermsOfUseFormValid === false }"
                  target="_blank"
                  data-testid="sign-up-terms-of-use"
                  :href="coroMarketingLinks.TERMS_OF_USE"
                  >{{ $t("invitedPage.termsOfUseLink") }}</a
                >
                <span class="ml-1 mr-1">{{ $t("general.and") }}</span>
                <a
                  class="text-primary text-decoration-underline mr-1"
                  :class="{ 'text-red-dark': isTermsOfUseFormValid === false }"
                  target="_blank"
                  data-testid="sign-up-privacy-policy"
                  :href="coroMarketingLinks.PRIVACY_POLICY"
                  >{{ $t("invitedPage.privacyPolicyLink") }}</a
                >
              </div>
            </template>
          </v-checkbox>
        </v-form>
        <v-btn class="mt-3 mb-12" color="primary" rounded size="large" @click="onJoinWorkspace()">
          {{ $t("invitedPage.acceptInvitationBtn") }}
        </v-btn>
        <coro-features class="mt-16"></coro-features>
      </div>
      <template v-if="inviteAccepted && isNewUser">
        <div class="set-password-content text-center">
          <template v-if="!isPasswordSet">
            <v-form ref="passwordForm" v-model="valid">
              <div class="headline5 mb-9">{{ $t("invitedPage.title") }}</div>
              <v-text-field
                v-model="newPassword"
                :append-inner-icon="showNewPassword ? 'icon-eye' : 'icon-eye-off'"
                class="mt-1"
                :placeholder="$t('forms.password.placeholder')"
                :label="$t('invitedPage.newPassword')"
                :type="showNewPassword ? 'text' : 'password'"
                :rules="passwordRules"
                @click:appendInner="showNewPassword = !showNewPassword"
              ></v-text-field>
              <password-strength-meter class="mt-2" :password="newPassword" />
              <v-btn size="large" rounded color="primary" class="mb-5 mt-3" @click="setPassword()">
                {{ $t("invitedPage.setPasswordBtn") }}
              </v-btn>
            </v-form>
          </template>
          <div v-else class="d-flex align-center flex-column text-center">
            <v-icon class="no-items-icon mb-3" icon="$resetPassword" :size="90" />
            <div class="headline5">
              {{ $t("invitedPage.successText") }}
            </div>
            <router-link :to="'/login'">
              <v-btn size="large" rounded class="mt-10" color="primary">
                {{ $t("invitedPage.goToLogin") }}
              </v-btn>
            </router-link>
          </div>
        </div>
      </template>
      <template v-if="inviteAccepted && !isNewUser">
        <div class="d-flex align-center flex-column text-center">
          <v-icon class="no-items-icon mb-3" icon="$resetPassword" :size="90" />
          <div class="headline5">{{ $t("invitedPage.successfullyJoined") }} {{ workplace }}</div>
          <router-link :to="'/login'">
            <v-btn size="large" rounded class="mt-10" color="primary">
              {{ $t("general.login") }}
            </v-btn>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { AccountErrors } from "@/constants/account";
import { useI18n } from "vue-i18n";
import { useAccountStore, useAdminUsersStore } from "@/_store";
import { storeToRefs } from "pinia";
import { useMyAccountStore } from "@/_store/my-account.module";
import { CoroMarketingLink } from "@/constants/general";
import { RouteName } from "@/constants/routes";
import CoroFeatures from "@/components/CoroFeatures.vue";
import PasswordStrengthMeter from "@/components/PasswordStrengthMeter.vue";
import Patterns from "@/constants/patterns";

export default defineComponent({
  components: {
    CoroFeatures,
    PasswordStrengthMeter,
  },
  setup() {
    const i18n = useI18n();
    const accountStore = useAccountStore();
    const adminUsersStore = useAdminUsersStore();
    const myAccountStore = useMyAccountStore();
    const router = useRouter();
    const { setToken, setWorkspace } = accountStore;
    const { logged } = storeToRefs(accountStore);
    const { acceptInvite } = adminUsersStore;
    const { setPassword: setAccountPassword } = myAccountStore;
    const newPassword = ref();
    const valid = ref(true);
    const isNewUser = ref(false);
    const email = ref();
    const workplace = ref();
    const showNewPassword = ref(false);
    const isPasswordSet = ref(false);
    const error = ref();
    const isTermsOfUseChecked = ref(false);
    const isTermsOfUseFormValid = ref(true);
    const inviteFromApi = ref(false);
    const passwordForm = ref();
    const termsOfUseForm = ref();

    const passwordRules = [
      (password: string) => {
        if (!password || password.length <= 0) {
          return i18n.t("validations.required");
        }
        if (!Patterns.PASSWORD.test(password)) {
          return i18n.t("validations.password");
        }
        return true;
      },
    ];
    const required = [(v: boolean) => (!v ? i18n.t("validations.required") : true)];

    const inviteAccepted = computed(() => accountStore.account.inviteAccepted);
    const invitedText = computed(() => {
      return inviteFromApi.value
        ? i18n.t("invitedPage.youHaveBeenInvitedViaApi", { workplace: workplace.value })
        : i18n.t("invitedPage.youHaveBeenInvited", {
            email: email.value,
            workplace: workplace.value,
          });
    });

    const resetAccountState = () => accountStore.$reset();

    const onJoinWorkspace = async () => {
      if (!isTermsOfUseChecked.value) {
        const { valid } = await termsOfUseForm.value.validate();
        if (!valid) {
          error.value = "privacyPolicy";
        }
        return;
      }

      if (isNewUser.value) {
        try {
          await acceptInvite();
        } catch (e) {
          handleErrors(e);
        }
      } else {
        if (logged.value) {
          setWorkspace("");
          await router.push({ name: RouteName.WORKSPACES });
        } else {
          try {
            await acceptInvite();
          } catch (e) {
            handleErrors(e);
          }
        }
      }
    };

    const setPassword = async () => {
      const { valid } = await passwordForm.value.validate();
      if (!valid) return;
      setAccountPassword({
        newPassword: newPassword.value,
      }).then(() => {
        isPasswordSet.value = true;
      });
    };

    const handleErrors = (error: any) => {
      const response = error.response;
      if (response.status === 401) {
        error.value = inviteFromApi.value
          ? `${AccountErrors.TOKEN_EXPIRED}ViaApi`
          : AccountErrors.TOKEN_EXPIRED;
      } else if (response.status === 400) {
        const isInviteRevoked = response?.data?.errors?.includes(
          "Unable to join. Invite has been revoked"
        );
        if (isInviteRevoked) {
          error.value = inviteFromApi.value
            ? `${AccountErrors.INVITE_HAS_BEEN_REVOKED}ViaApi`
            : AccountErrors.INVITE_HAS_BEEN_REVOKED;
        }
      }
    };

    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      isNewUser.value = urlParams.get("newUser") === "true";
      email.value = urlParams.get("email");
      workplace.value = urlParams.get("workspace");
      const token = urlParams.get("token") || "";
      inviteFromApi.value = urlParams.get("unknownInviter") === "true";

      if (!token) {
        router.push({ name: RouteName.LOGIN });
      }
      setToken(token);
    });

    onBeforeUnmount(() => {
      resetAccountState();
    });

    return {
      newPassword,
      valid,
      passwordForm,
      termsOfUseForm,
      isNewUser,
      email,
      workplace,
      passwordRules,
      inviteAccepted,
      showNewPassword,
      isPasswordSet,
      error,
      required,
      isTermsOfUseChecked,
      isTermsOfUseFormValid,
      inviteFromApi,
      invitedText,
      onJoinWorkspace,
      setPassword,
      handleErrors,
      coroMarketingLinks: CoroMarketingLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.set-password-content {
  max-width: 536px;
  min-width: 300px;
  width: 536px;
  background-color: rgb(var(--v-theme-white));
  border-radius: 8px;
  padding: 30px;
}
.terms-of-use-label {
  margin-top: 3px;
}
</style>
