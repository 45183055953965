<template>
  <v-card class="add-ins-tab pb-3">
    <v-card-text>
      <div class="ml-4 mt-3 w-95">
        <div class="subtitle1 mb-2">
          {{ $t("emailSettings.addInsSettings.title") }}
        </div>
        <div class="body1 mb-6">
          {{ $t("emailSettings.addInsSettings.description") }}
        </div>
      </div>
      <div class="d-flex ml-4">
        <a
          v-for="service in emailServices"
          :key="service.name"
          class="d-flex align-center add-in-service-btn mr-4"
          :href="service.link"
          target="_blank"
          :class="{
            'not-clickable': isWorkspaceFrozenOrActionRestricted(
              RolePermissionsScope.PROTECTION,
              SubscriptionModule.EMAIL_SECURITY
            ),
          }"
        >
          <div class="d-flex flex-column align-center justify-center w-100">
            <coro-icon class="service-icon" :icon-name="service.name"></coro-icon>
            <div class="subtitle1">{{ getDescription(service.name) }}</div>
          </div>
        </a>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { SubscriptionModule } from "@/constants/workplaces";
import { RolePermissionsScope } from "@/_store/roles.module";
import { EmailAddInsProvider } from "@/constants/emails";
import { AddonMarketplaceLink } from "@/constants/general";
import { isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { useI18n } from "vue-i18n";
import CoroIcon from "@/components/CoroIcon.vue";

export default defineComponent({
  components: {
    CoroIcon,
  },
  setup() {
    const i18n = useI18n();
    const localValue = ref({});

    const getDescription = (provider: EmailAddInsProvider) => {
      return i18n.t("emailSettings.addInsSettings.installFor", {
        provider: i18n.t(`general.${provider}`),
      });
    };

    return {
      SubscriptionModule,
      RolePermissionsScope,
      localValue,
      emailServices: [
        { name: EmailAddInsProvider.OUTLOOK, link: AddonMarketplaceLink.OUTLOOK },
        { name: EmailAddInsProvider.GMAIL, link: AddonMarketplaceLink.GMAIL },
      ],
      isWorkspaceFrozenOrActionRestricted,
      getDescription,
    };
  },
});
</script>

<style lang="scss" scoped>
.add-in-service-btn {
  width: 272px;
  height: 176px !important;
  border-radius: 6px;
  border: 1px solid rgb(var(--v-theme-indigo-faint));
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 2px solid rgb(var(--v-theme-primary)) !important;
  }
}

.service-icon {
  width: 64px;
  height: 64px;
}

.v-chip-group .v-chip--active {
  border: 2px solid rgb(var(--v-theme-primary)) !important;
}
</style>
