<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      max-width="800px"
      type="heading,list-item-three-line@4"
    >
      <div class="d-flex align-center mb-3 mt-2">
        <div class="user-circle">{{ initials }}</div>
        <div class="headline5">
          {{
            $t("accessControlPage.adminUsers.details.title", {
              name: adminUserName,
            })
          }}
        </div>
      </div>
      <div class="setting-description body1 mb-4">
        {{ $t("accessControlPage.adminUsers.details.description") }}
      </div>
      <table-wrapper class="service-table__wrapper mb-3">
        <v-table>
          <template #default>
            <tbody>
              <template v-if="hasMonitoredScopes">
                <tr v-for="(key, i) in monitoredScopes" :key="key">
                  <td class="d-flex justify-space-between align-center service-row">
                    <div class="d-flex">
                      <coro-icon class="service-icon mr-1" :icon-name="key"></coro-icon>
                      <div class="subtitle2">
                        {{ $t(`services.${key}`) }}
                      </div>
                    </div>
                    <v-switch
                      class="d-flex flex-grow-0"
                      v-model="adminUserDetails.monitoredScopes[key]"
                      :data-testid="`admin-account-monitored-scopes-switch-${i}`"
                      color="primary lighten-1"
                      :disabled="actionNotAllowed"
                      :ripple="false"
                      hide-details
                      @change="updateMonitoredScopes()"
                    />
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="subtitle2">
                    {{ $t("accessControlPage.adminUsers.details.noCloudServicesConnected") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-table>
      </table-wrapper>
      <a
        class="coro-link"
        v-if="
          !isAccessRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.CLOUD_APPS
          )
        "
        @click="goToCloudApps()"
      >
        {{ $t("accessControlPage.adminUsers.details.connectMore") }}
      </a>
      <table-wrapper class="service-table__wrapper mb-3 mt-3">
        <v-table>
          <template #default>
            <tbody>
              <template v-if="hasEmailProxyMonitoredScopes">
                <tr
                  v-for="(key, i) in Object.keys(adminUserDetails.emailProxyMonitoredScopes)"
                  :key="key"
                >
                  <td class="d-flex justify-space-between align-center service-row">
                    <div class="d-flex align-center">
                      <v-icon size="30" class="mr-1" icon="$accessRestrictions" />
                      <div class="subtitle2">
                        {{ key }}
                      </div>
                    </div>
                    <v-switch
                      class="d-flex flex-grow-0"
                      v-model="adminUserDetails.emailProxyMonitoredScopes[key]"
                      :data-testid="`admin-account-monitored-scopes-switch-${i}`"
                      color="primary lighten-1"
                      :disabled="actionNotAllowed"
                      :ripple="false"
                      hide-details
                      @change="updateMonitoredScopes()"
                    />
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="subtitle2">
                    {{ $t("accessControlPage.adminUsers.details.noProxyConnected") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-table>
      </table-wrapper>
      <a
        class="coro-link"
        v-if="
          !isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.INBOUND_GATEWAY)
        "
        @click="goInboundGateway"
      >
        {{ $t("accessControlPage.adminUsers.details.connectMoreProxies") }}
      </a>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { isAccessRestricted, isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { computed, defineComponent, onMounted } from "vue";
import TableWrapper from "@/components/TableWrapper.vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useAdminUsersStore } from "@/_store";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { i18n } from "@/plugins/i18n";
import isEmpty from "lodash/isEmpty";
import { SubscriptionAddon } from "@/constants/workplaces";
import type { Service } from "@/constants/cloud-apps";

import { RouteName } from "@/constants/routes";

const breadCrumbsItems = [
  {
    title: i18n.global.t("general.backToControlPanel"),
    disabled: false,
    to: { path: "/portal/settings" },
  },
  {
    title: `‹ ${i18n.global.t("accessControlPage.adminUsers.title")}`,
    disabled: false,
    to: { path: "/portal/settings/access-control/admin-users" },
  },
];

export default defineComponent({
  components: {
    TableWrapper,
    CoroIcon,
  },
  props: {
    adminUserId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const adminUsersStore = useAdminUsersStore();
    const { getAdminUserDetails, updateContentInspectionPermissions } = adminUsersStore;
    const { adminUserDetails, showSkeletonLoader } = storeToRefs(adminUsersStore);
    const router = useRouter();
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ADMIN_USERS,
        "manageContentPermissions"
      );
    });
    const hasMonitoredScopes = computed(() => {
      return Object.keys(adminUserDetails.value.monitoredScopes ?? {}).length > 0;
    });
    const hasEmailProxyMonitoredScopes = computed(() => {
      return Object.keys(adminUserDetails.value.emailProxyMonitoredScopes ?? {}).length > 0;
    });
    const monitoredScopes = computed(() => {
      return Object.keys(adminUserDetails.value.monitoredScopes) as Service[];
    });
    const initials = computed(() => {
      if (adminUserDetails.value.firstName && adminUserDetails.value.lastName) {
        return (
          adminUserDetails.value.firstName.substring(0, 1) +
          adminUserDetails?.value.lastName.substring(0, 1)
        );
      }

      return adminUserDetails.value.email ? adminUserDetails.value.email.substring(0, 2) : "";
    });

    const adminUserName = computed(() => {
      if (adminUserDetails.value.firstName && adminUserDetails.value.lastName) {
        return `${adminUserDetails.value.firstName} ${adminUserDetails.value.lastName}`;
      }
      return adminUserDetails.value.email;
    });

    const goToCloudApps = () => {
      router.push({ name: RouteName.CLOUD_APPS_PAGE });
    };

    const goInboundGateway = () => {
      router.push({ path: "/portal/settings/email/inbound-gateway" });
    };

    onMounted(async () => {
      await getAdminUserDetails(props.adminUserId);
    });

    const updateMonitoredScopes = () => {
      updateContentInspectionPermissions(props.adminUserId, {
        monitoredScopes: {
          ...adminUserDetails.value.monitoredScopes,
        },
        emailProxyMonitoredScopes: {
          ...adminUserDetails.value.emailProxyMonitoredScopes,
        },
      });
    };

    return {
      RolePermissionsScope,
      breadCrumbsItems,
      adminUserDetails,
      showSkeletonLoader,
      actionNotAllowed,
      hasMonitoredScopes,
      hasEmailProxyMonitoredScopes,
      initials,
      adminUserName,
      monitoredScopes,
      goToCloudApps,
      updateMonitoredScopes,
      isAccessRestricted,
      WorkspaceManagementScopeSections,
      SubscriptionAddon,
      goInboundGateway,
      isEmpty,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .service-table__wrapper {
    max-width: 568px;
  }

  .service-icon {
    height: 24px;
    width: 24px;
  }

  .service-row {
    height: 80px !important;
  }

  .setting-description {
    max-width: 800px;
  }
}
</style>
