<template>
  <div>
    <v-radio-group v-model="localValue.action" hide-details>
      <template v-for="action in actionsList" :key="action">
        <v-radio
          class="mb-4 body2"
          :data-testid="`allow-block-email-modal-${action}-action`"
          :label="getLabel(action)"
          :value="action"
          :ripple="false"
          :disabled="isActionDisabled(action)"
        />
      </template>
    </v-radio-group>
    <template v-if="showCloseTicket">
      <v-divider class="mt-2 mb-6"></v-divider>
      <v-checkbox
        v-model="localValue.item.closeTicket"
        data-testid="close-ticket-checkbox"
        :label="$t('modals.allowBlockEmail.closeAllTicketsInGroup')"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { TicketAction, TicketActionSeverity, TicketTrigger } from "@/constants/tickets";
import { getDomainFromEmail, isActionRestricted } from "@/_helpers/utils";
import { Service } from "@/constants/cloud-apps";
import { RolePermissionsScope, type TicketsScope } from "@/_store/roles.module";
import { useI18n } from "vue-i18n";

const allowActions = [
  TicketAction.APPROVE_EMAIL,
  TicketAction.APPROVE_EMAILS_FROM_DOMAIN,
  TicketAction.APPROVE_EMAILS_FROM_SENDER,
  TicketAction.APPROVE_IP,
];

const blockActions = [
  TicketAction.DISCARD_EMAIL,
  TicketAction.DISCARD_EMAILS_FROM_DOMAIN,
  TicketAction.DISCARD_EMAILS_FROM_SENDER,
  TicketAction.DISCARD_IP,
];

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<
        DialogDataConfig<
          TicketDetails & {
            closeTicket?: boolean;
            allowAction: boolean;
            enabledActions: TicketAction[];
            name?: string;
          },
          TicketAction
        >
      >,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const actionsList = computed(() => {
      return props.config.item.allowAction ? allowActions : blockActions;
    });
    const localValue = ref({ ...props.config, action: actionsList.value.at(0) });

    const name = computed(() => {
      if (
        [TicketAction.APPROVE_EMAILS_FROM_DOMAIN, TicketAction.DISCARD_EMAILS_FROM_DOMAIN].includes(
          localValue.value.action!
        )
      ) {
        return getDomainFromEmail(localValue.value.item.sections.emailMetaData?.senderEmail);
      }
      if (
        [TicketAction.APPROVE_EMAILS_FROM_SENDER, TicketAction.DISCARD_EMAILS_FROM_SENDER].includes(
          localValue.value.action!
        )
      ) {
        return localValue.value.item.sections.emailMetaData?.senderEmail;
      }
      return props.config.item.name;
    });

    const showCloseTicket = computed(() => {
      return !isActionRestricted(
        RolePermissionsScope.TICKETS,
        localValue.value.item.moduleName as keyof TicketsScope,
        TicketActionSeverity.LOW
      );
    });

    watch(
      localValue,
      (newValue) => {
        emit("update:localValue", { ...newValue, item: { ...newValue.item, name: name.value } });
      },
      { deep: true, immediate: true }
    );

    const isActionDisabled = (action: TicketAction) => {
      return !props.config.item.enabledActions.includes(action);
    };

    const getLabel = (action: string) => {
      const isProxy = props.config.item.sections.serviceName === Service.PROXY;
      const isMalwareInEmailAttachment =
        props.config.item.eventTrigger === TicketTrigger.MALWARE_IN_EMAIL_ATTACHMENTS;
      const proxyModeTranslationKey =
        props.config.item.sections?.emailMetaData?.proxyBlockingMode ?? "default";

      if (isProxy) {
        return i18n.t(
          `modals.allowBlockEmail.proxyActions.${proxyModeTranslationKey}.${action}.title`
        );
      } else if (isMalwareInEmailAttachment) {
        return i18n.t(
          `modals.allowBlockEmail.malwareActions.${proxyModeTranslationKey}.${action}.title`
        );
      }

      return i18n.t(`modals.allowBlockEmail.actions.${proxyModeTranslationKey}.${action}.title`);
    };

    return {
      localValue,
      actionsList,
      name,
      isActionDisabled,
      getLabel,
      showCloseTicket,
    };
  },
});
</script>
