<template>
  <div class="d-flex justify-space-between align-start">
    <coro-label background-color="rgb(var(--v-theme-indigo-faint))" mix-blend-mode="initial">
      {{ workplace }}
    </coro-label>
    <img alt="Logo" :src="logoPath" width="56" />
  </div>
  <div class="mb-14">
    <div class="headline5 mb-1">
      {{ $t("reports.managedServicesSummaryReport.title") }}
    </div>
    <div class="body2 mb-2">
      {{ selectedDateRange }}
    </div>
    <div class="body3 text--semitransparent mb-2">
      {{ $t("reports.executiveSummaryReport.generatedOn") }} {{ generatedOn }}
    </div>
    <div class="body3 text--semitransparent">
      {{ $t("reports.executiveSummaryReport.version") }} {{ version }}
    </div>
  </div>
  <div class="d-flex report-general-info__columns mb-14">
    <div class="flex-grow-1">
      <div class="d-flex flex-column">
        <div class="big-number mb-2 text-primary">
          {{ reportData.totalResolvedTickets }}
        </div>
        <div class="subtitle2 mb-2">
          {{ $t("reports.managedServicesSummaryReport.totalTicketsResolved") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CoroLabel from "@/components/CoroLabel.vue";
import type { ManagedServicesReportData } from "@/_store/reports.module";
import { computed } from "vue";
import moment from "moment";

const props = defineProps<{
  workplace: string;
  reportData: ManagedServicesReportData;
  selectedDateRange: string;
  logoPath: string;
  version: string;
}>();
const generatedOn = computed(() =>
  moment(props.version, "YYYYMMDD_HHmmss").format("YYYY/MM/DD HH:mm:ss")
);
</script>

<style scoped lang="scss">
.report-general-info {
  &__columns {
    gap: 56px;
  }
}
</style>
