import api from "@/_helpers/api";
import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import { OsType } from "@/constants/devices";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { SensitiveDataScanDriveTypes } from "@/constants/endpoint-data-governance";
import type { DeviceLabel } from "@/_store/devices-settings.module";

export interface NewSensitiveDataScanItem {
  appliedDriveType: SensitiveDataScanDriveTypes;
  daysCount: number;
  osType: string;
  preferredHour: number;
  labels: Array<DeviceLabel>;
}

export interface SensitiveDataScanItem extends NewSensitiveDataScanItem {
  id: string;
}

interface PrivacySensitiveDataState {
  items: Array<SensitiveDataScanItem>;
  showSkeletonLoader: boolean;
}

export const defaultPrivacySensitiveDataState = {
  items: [],
  showSkeletonLoader: false,
};

export const useSensitiveDataScansStore = defineStore("sensitiveDataScans", {
  state: (): PrivacySensitiveDataState => ({
    ...defaultPrivacySensitiveDataState,
  }),
  actions: {
    async getSensitiveDataScans(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;
      const request = {
        ...api.sensitiveDataScans,
      };
      try {
        const { data } = await axiosInstance.request(request);
        const windowsData = data.windowsSettingsLabeled.map((item: SensitiveDataScanItem) => {
          return {
            ...item,
            osType: OsType.WINDOWS,
          };
        });
        const macData = data.macOsSettingsLabeled.map((item: SensitiveDataScanItem) => {
          return {
            ...item,
            osType: OsType.MAC_OS,
          };
        });
        this.items = [...windowsData, ...macData];
      } catch (e) {
        console.error(e);
      }
      this.showSkeletonLoader = false;
    },
    async createSensitiveDataScanPolicy(payload: DialogDataConfig<SensitiveDataScanItem>) {
      const request = {
        ...api.sensitiveDataScans,
        method: "POST",
        data: payload.item,
      };
      try {
        await axiosInstance.request(request);
        await this.getSensitiveDataScans(false);
      } catch (e) {
        console.error(e);
      }
    },
    async editSensitiveDataScanPolicy(payload: DialogDataConfig<SensitiveDataScanItem>) {
      const data = {
        appliedDriveType: payload.item.appliedDriveType,
        daysCount: payload.item.daysCount,
        osType: payload.item.osType,
        preferredHour: payload.item.preferredHour,
        labels: payload.item.labels,
      };
      const request = {
        ...api.sensitiveDataScans,
        method: "PUT",
        data: data,
        params: {
          id: payload.item.id,
        },
      };
      try {
        await axiosInstance.request(request);
        await this.getSensitiveDataScans(false);
      } catch (e) {
        console.error(e);
      }
    },
    async deleteSensitiveDataScanPolicy(payload: DialogDataConfig<SensitiveDataScanItem>) {
      const request = {
        ...api.sensitiveDataScans,
        method: "DELETE",
        params: {
          id: payload.item.id,
        },
      };
      try {
        await axiosInstance.request(request);
        await this.getSensitiveDataScans(false);
      } catch (e) {
        console.error(e);
      }
    },
  },
});
