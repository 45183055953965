<template>
  <VueDatePicker
    :ui="{ menu: menuClassName }"
    ref="datepicker"
    v-bind="props"
    v-model="internalModel"
    :multi-calendars="{ solo: false, static: true }"
    @internal-model-change="onRangeSelect"
    position="right"
  >
    <template #clear-icon />
    <template #action-preview />
    <template #dp-input="{ value }">
      <v-text-field
        :class="{ 'value-selected': internalModel }"
        :model-value="value"
        density="compact"
        rounded
        :readonly="true"
        :placeholder="placeholder"
        :disabled="disabled"
        append-inner-icon="$triangle"
        bg-color="white"
        hide-details
        base-color="primary"
        role="button"
      ></v-text-field>
    </template>
    <template #action-buttons>
      <v-btn rounded variant="text" class="mr-2" @click="resetDate">
        {{ $t("dateRangePicker.reset") }}
      </v-btn>
      <v-btn rounded variant="text" class="mr-2" @click="closeMenu">
        {{ $t("dateRangePicker.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        rounded
        variant="flat"
        :disabled="isSelectDateDisabled"
        @click="selectDate"
      >
        {{ $t("dateRangePicker.select") }}
      </v-btn>
    </template>
  </VueDatePicker>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import type { VueDatePickerProps, DatePickerInstance } from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { dateRangePresets } from "@/constants/date-range-picker";
import type { TimeFrame } from "@/types";

export type DateRangePickerProps = Pick<
  VueDatePickerProps,
  | "minDate"
  | "maxDate"
  | "presetDates"
  | "disabled"
  | "teleport"
  | "enableTimePicker"
  | "config"
  | "startTime"
  | "range"
> & {
  modelValue: TimeFrame;
  placeholder: string;
  hideMenuArrow?: boolean;
};

const props = withDefaults(defineProps<DateRangePickerProps>(), {
  presetDates: () => [...dateRangePresets],
  minDate: undefined,
  maxDate: undefined,
  enableTimePicker: false,
  config: () => ({ shadowDom: true }),
  startTime: () => [
    { hours: 0, minutes: 0 },
    { hours: 23, minutes: 59 },
  ],
  range: () => ({
    showLastInRange: false,
  }),
});
const emit = defineEmits<{
  (e: "update:modelValue", value: TimeFrame): void;
}>();

const internalModel = computed({
  get() {
    return props.modelValue.start && props.modelValue.end
      ? [props.modelValue.start, props.modelValue.end]
      : null;
  },
  set(value) {
    const [start, end] = value ?? ["", ""];
    emit("update:modelValue", { start, end });
  },
});
const datepicker = ref<DatePickerInstance>(null);
const selectedRange = ref<[string, string] | null>(null);
const menuClassName = computed(() => {
  return props.hideMenuArrow ? "coro-datepicker coro-datepicker--no-arrow" : "coro-datepicker";
});
const isSelectDateDisabled = computed(() => {
  return selectedRange.value?.length !== 2;
});

const onRangeSelect = (range: [string, string]) => {
  selectedRange.value = range;
};

const selectDate = () => {
  if (!selectedRange.value) {
    datepicker.value?.clearValue();
    return;
  }
  datepicker.value?.selectDate();
};

const closeMenu = () => {
  datepicker.value?.closeMenu();
};

const resetDate = () => {
  datepicker.value?.updateInternalModelValue(null);
};
</script>

<style lang="scss">
.coro-datepicker {
  --dp-font-family: "Source Sans Pro", sans-serif !important;
  --dp-text-color: rgb(var(--v-theme-primary));
  --dp-primary-color: rgb(var(--v-theme-primary));
  --dp-hover-text-color: rgb(var(--v-theme-primary));
  --dp-hover-color: rgb(var(--v-theme-indigo-pale));
  --dp-range-between-dates-background-color: rgb(var(--v-theme-indigo-faint));
  --dp-border-radius: 8px;
  &--no-arrow {
    .dp__arrow_bottom {
      display: none !important;
    }
  }
  .dp__arrow_top {
    display: none !important;
  }
  .dp__menu {
    box-shadow: 0 4px 16px 0 rgb(var(--v-theme-gray-pale)) !important;
  }
  .dp__input_wrap {
    box-sizing: border-box;
  }
  .dp__btn.dp--preset-range {
    color: rgb(var(--v-theme-primary));
    border-radius: 4px !important;
    padding: 6px;
  }

  .v-field__append-inner > .v-icon {
    opacity: var(--v-medium-emphasis-opacity) !important;
  }
}
</style>

<style scoped lang="scss">
:deep(*) {
  --dp-font-family: "Source Sans Pro", sans-serif !important;
  --dp-text-color: rgb(var(--v-theme-primary));
  --dp-primary-color: rgb(var(--v-theme-primary));
  --dp-hover-text-color: rgb(var(--v-theme-primary));
  --dp-hover-color: rgb(var(--v-theme-indigo-pale));
  --dp-range-between-dates-background-color: rgb(var(--v-theme-indigo-faint));
  --dp-border-radius: 8px;
  .coro-datepicker--no-arrow {
    .dp__arrow_bottom {
      display: none !important;
    }
  }
  .dp__arrow_top {
    display: none !important;
  }
  .dp__menu {
    box-shadow: 0 4px 16px 0 rgb(var(--v-theme-gray-pale)) !important;
  }
  .dp__input_wrap {
    box-sizing: border-box;
  }
  .dp__btn.dp--preset-range {
    color: rgb(var(--v-theme-primary));
    border-radius: 4px !important;
    padding: 6px;
  }

  .v-field__append-inner > .v-icon {
    opacity: var(--v-medium-emphasis-opacity) !important;
  }

  .v-btn.bg-primary.v-btn--variant-flat.v-btn--disabled {
    padding: 0 18px;
  }
}
:deep(.v-input__control) {
  .icon.icon-triangle {
    color: black;
    opacity: 1;
    font-size: 24px;
  }
  input::placeholder {
    color: rgb(var(--v-theme-primary)) !important;
    opacity: 0.9 !important;
    font-family: "Source Sans Pro", sans-serif !important;
  }
}

:deep(.value-selected) {
  .v-field__outline {
    --v-field-border-opacity: 1 !important;
    .v-field__outline__start {
      border-color: rgb(var(--v-theme-primary)) !important;
      border-top-width: 2px;
      border-left-width: 2px;
      border-bottom-width: 2px;
    }
    .v-field__outline__end {
      border-color: rgb(var(--v-theme-primary)) !important;
      border-top-width: 2px;
      border-right-width: 2px;
      border-bottom-width: 2px;
    }
  }
}
</style>
