<template>
  <div>
    <div class="d-flex justify-end mb-5">
      <div class="w-70">
        <filter-wrapper
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="onClearFiltersClicked"
        >
          <v-select
            v-model="localFilters.types"
            :items="exportTypes"
            bg-color="white"
            menu-icon="icon-triangle"
            item-value="id"
            item-title="name"
            :menu-props="{ maxHeight: '300' }"
            class="types-filter filter-menu mr-2"
            :class="{ 'filter-active': localFilters.types?.length }"
            :placeholder="$t('reports.exports.filters.types')"
            data-testid="devices-page-label-filter"
            density="compact"
            variant="outlined"
            multiple
            rounded
            hide-details
            background-color="white"
            return-object
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("reports.exports.filters.types")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.types?.length" class="filter-label">
                {{ localFilters.types.length }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <v-list-item-title :data-testid="`devices-page-label-filter-${item}-item`" title="">
                  {{ $t(`reports.exports.categories.${item.raw}`) }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <date-range-picker
            v-model="localFilters.timeFrame"
            :preset-dates="presets"
            :placeholder="$t('reports.exports.filters.dateRange')"
            class="mr-2 range-picker"
          ></date-range-picker>
          <v-text-field
            v-model="localFilters.search"
            data-testid="users-page-users-search"
            :class="{ 'filter-active': localFilters.search?.length }"
            density="compact"
            rounded
            persistent-clear
            bg-color="white"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            clearable
            variant="outlined"
            clear-icon="icon-x"
            @click:clear="localFilters.search = ''"
          />
        </filter-wrapper>
      </div>
    </div>
    <div class="info-block px-3 py-1 mb-4 d-flex align-center">
      <v-icon size="24" icon="$warning" />
      <span class="body2 ml-2" v-html="$t('reports.exports.disclaimer')" />
    </div>
    <v-data-table-server
      class="coronet-table--highlitable"
      :headers="headers"
      :items="exportsList.items"
      :loading="loading"
      :page="pagination.page + 1"
      :items-length="exportsList.total"
      @update:options="
        handlePaginationChange({
          pageSize: $event.itemsPerPage,
          page: $event.page - 1,
        })
      "
      :items-per-page="pagination.pageSize"
    >
      <template #item="{ item }">
        <tr :key="item.id">
          <td class="w-33">
            <div class="d-flex align-center">
              <v-icon :icon="iconMap[item.exportFileExtension]" size="40" class="mr-2"></v-icon>
              <span class="subtitle2">{{ item.exportName }}</span>
            </div>
          </td>
          <td class="body2 w-33">
            {{ $t(`reports.exports.categories.${item.type}`) }}
          </td>
          <td class="body2 w-33">
            {{ getFormattedDateTime(item.creationTime) }}
          </td>
          <td class="actions-col">
            <div
              v-if="!actionNotAllowed"
              class="body2 coro-link"
              role="button"
              @click="downloadCsv(item)"
            >
              {{ $t(`reports.exports.exportFileExtensions.${item.exportFileExtension}`) }}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import { useI18n } from "vue-i18n";
import type { Pagination } from "@/types";
import moment from "moment";
import {
  dialogsConfigConstructor,
  downloadFileByLink,
  isActionRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import {
  ExportCategory,
  ExportFileExtension,
  type ExportListItem,
  useExportsStore,
} from "@/_store/exports.module";
import { storeToRefs } from "pinia";
import { ModalWidth } from "@/constants/modals";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import DateRangePicker from "@/components/DateRangePicker.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { i18n } from "@/plugins/i18n";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";

const presets = [
  {
    label: i18n.global.t("dateRangePicker.today"),
    value: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.yesterday"),
    value: [
      moment().subtract(1, "days").format("YYYY-MM-DD"),
      moment().subtract(1, "days").format("YYYY-MM-DD"),
    ],
  },
  {
    label: i18n.global.t("dateRangePicker.lastSevenDays"),
    value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThirtyDays"),
    value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.lastNinetyDays"),
    value: [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
];

const exportTypes = [
  ExportCategory.ACTIVITY_LOGS,
  ExportCategory.DEVICES,
  ExportCategory.TICKETS,
  ExportCategory.USERS,
];

const iconMap: Record<ExportFileExtension, string> = {
  [ExportFileExtension.CSV]: "$csvFile",
  [ExportFileExtension.ZIP]: "$zipFile",
};

export default defineComponent({
  components: { FilterWrapper, DateRangePicker },
  setup() {
    const i18n = useI18n();
    const exportsStore = useExportsStore();
    const dialogStore = useDialogsStore();
    const { loading, pagination, exportsList } = storeToRefs(exportsStore);
    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } = useFilters(
      FilterContext.EXPORTS_FILTERS
    );
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.REPORTS
      );
    });

    const handlePaginationChange = async (pagination: Pagination) => {
      exportsStore.$patch({ pagination });
      await exportsStore.getExportsList();
    };

    const getFormattedDateTime = (timestamp?: number) => {
      if (timestamp) {
        return moment(timestamp).format("ddd, MMM D, hh:mm A");
      }
    };

    const downloadCsv = (item: ExportListItem) => {
      const logCreatedHoursAgo = moment().diff(moment(item.creationTime), "hours");
      if (logCreatedHoursAgo > 24) {
        const errorModal = {
          width: ModalWidth.SMALL,
          header: undefined,
          content: {
            component: ErrorModal,
          },
          footer: null,
          data: {
            item: i18n.t("activityLogs.errors.csvDownloadLinkExpired"),
          },
        };
        const dialogConfig = dialogsConfigConstructor(errorModal);
        dialogStore.openDialog(dialogConfig);
        return;
      }
      downloadFileByLink(item.downloadLink);
    };

    watch(
      filtersUpdating,
      async (val) => {
        if (val) {
          exportsStore.$patch({ pagination: { page: 0, pageSize: 25 } });
          await exportsStore.getExportsList();
        }
      },
      { deep: true }
    );

    const onClearFiltersClicked = () => {
      clearFilters(exportsStore.getExportsList);
    };

    return {
      headers: [
        {
          title: i18n.t("reports.exports.table.name"),
          key: "exportName",
          sortable: false,
        },
        {
          title: i18n.t("reports.exports.table.category"),
          key: "exportType",
          sortable: false,
        },
        {
          title: i18n.t("reports.exports.table.created"),
          key: "creationTime",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
      presets,
      exportsList,
      pagination,
      loading,
      localFilters,
      showClearFiltersButton,
      exportTypes,
      iconMap,
      actionNotAllowed,
      clearFilters,
      handlePaginationChange,
      isActionRestricted,
      getFormattedDateTime,
      downloadCsv,
      onClearFiltersClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
.actions-col {
  width: 56px;
  text-align: center;
}

.types-filter {
  width: 110px !important;
}
.range-picker {
  width: 240px;
}
</style>
