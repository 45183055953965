export enum PhishingAction {
  REMOVE_FROM_ALLOWLIST = "removeFromAllowlist",
  REMOVE_FROM_BLOCKLIST = "removeFromBlocklist",
  ADD_TO_ALLOWLIST = "addToAllowlist",
  ADD_TO_BLOCKLIST = "addToBlocklist",
  IMPORT_ALLOW_BLOCK_LIST = "importAllowBlockListFromCsv",
  DOWNLOAD_ALLOW_BLOCK_LIST_EXAMPLE_CSV = "downloadAllowBlockListExampleCsv",
}

export enum AllowListSubType {
  AUTH_FAILURE = "auth",
  SUSPICIOUS_CONTENT = "local",
}

export enum AllowBlockListType {
  ALLOW = "allow",
  BLOCK = "block",
}

export enum EmailSecurityAllowBlockItemType {
  DOMAIN = "domain",
  EMAIL = "email",
}
