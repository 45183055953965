<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("edr.title") }}
      </div>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t("edr.tabs.processes"),
        route: { name: RouteName.EDR_PROCESSES_TAB },
      },
      {
        id: 2,
        name: i18n.t("edr.tabs.telemetry"),
        route: { name: RouteName.EDR_TELEMETRY_TAB },
      },
      {
        id: 3,
        name: i18n.t("edr.tabs.allowBlockList"),
        route: { name: RouteName.EDR_ALLOW_BLOCK_LIST_TAB },
      },
    ];
    return {
      tabs,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.nav-tabs) {
  .wrapper {
    z-index: 0 !important;
  }
}
</style>
