<template>
  <div class="d-flex justify-center mt-16">
    <v-progress-circular v-if="loading" color="primary" indeterminate size="120" />
    <empty-state v-else>
      <template #icon>
        <v-icon :class="iconClass" size="80" class="mr-1" :icon="statusIcon"></v-icon>
      </template>
      <template #description>
        <span v-html="testStatusMessage"></span>
      </template>
      <template #subtitle>
        <span v-html="testStatusDescription"></span>
      </template>
    </empty-state>
  </div>
</template>

<script lang="ts">
import { parseJwt } from "@/_helpers/utils";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { ProxyError } from "@/constants/email-proxy";
import { computed, defineComponent, onMounted, type Ref, ref } from "vue";
import {
  type EmailProxyToken,
  useEmailProxyStore,
} from "@/_store/email-security/email-proxy.module";
import { useI18n } from "vue-i18n";
import type { AxiosError } from "axios";
import EmptyState from "@/components/EmptyState.vue";

export default defineComponent({
  components: { EmptyState },
  setup() {
    const i18n = useI18n();
    const statusIcon = ref("");
    const testStatusMessage = ref("");
    const testStatusDescription = ref("");
    const isSuccess = ref(false);
    const loading = ref(true);
    const proxyToken: Ref<EmailProxyToken> = ref({} as EmailProxyToken);

    const iconClass = computed(() => {
      return isSuccess.value
        ? "proxy-verification__success-icon"
        : "proxy-verification__failure-icon";
    });

    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      proxyToken.value = parseJwt(urlParams.get("proxyToken"));
      try {
        await useEmailProxyStore().completeProxyTest(proxyToken.value);
        isSuccess.value = true;
        testStatusMessage.value = `${i18n.t("general.success")}!`;
        testStatusDescription.value = i18n.t("emailProxy.verification.successMessage");
        statusIcon.value = "$allGood";
      } catch (err) {
        const linkExpired: boolean | undefined = (
          err as AxiosError<{ errors: string[] }>
        ).response?.data?.errors?.includes(ProxyError.PROXY_VERIFICATION_LINK_EXPIRED);

        testStatusMessage.value = linkExpired
          ? i18n.t("emailProxy.verification.linkExpiredMessage1")
          : i18n.t("emailProxy.verification.failureMessage1");
        testStatusDescription.value = linkExpired
          ? i18n.t("emailProxy.verification.linkExpiredMessage2")
          : i18n.t("emailProxy.verification.failureMessage2");
        statusIcon.value = "$redAlert";
      } finally {
        loading.value = false;
      }
    });

    return {
      coronetSkeletonLoaderTypes,
      statusIcon,
      testStatusMessage,
      testStatusDescription,
      isSuccess,
      loading,
      iconClass,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .proxy-verification__success-icon:before {
    color: rgb(var(--v-theme-green-dark));
  }
  .proxy-verification__failure-icon:before {
    color: rgb(var(--v-theme-red-dark));
  }
}
</style>
