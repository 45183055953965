<template>
  <div class="pa-4 h-100">
    <div class="subtitle1 mb-4">{{ $t("ticketDetails.fullDetails.emailContent") }}</div>
    <div v-if="hasTextOrImgInHtmlContent()" class="email-content" v-html="sanitizedContent"></div>
    <div v-else class="email-content--empty headline5 text-indigo-medium">
      {{ $t("phishingEmailDetails.emailBodyEmpty") }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import DOMPurify from "dompurify";

export default defineComponent({
  name: "EmailContent",
  props: {
    htmlContent: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const sanitizeConfig = {
      FORBID_ATTR: ["href"],
      FORBID_TAGS: ["style"],
    };

    const sanitizedContent = computed(() => {
      return DOMPurify.sanitize(props.htmlContent, sanitizeConfig);
    });

    const hasTextOrImgInHtmlContent = () => {
      const htmlString = sanitizedContent.value;
      // Remove all HTML tags except <img> tags and check if there's any non-whitespace text left
      const hasText = Boolean(htmlString.replace(/<[^img][^>]*>/g, "").trim());
      // Check if there's an <img> tag in the HTML string
      const hasImg = /<img\b[^>]*>/i.test(htmlString);
      return hasImg || hasText;
    };

    return {
      sanitizedContent,
      hasTextOrImgInHtmlContent,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .email-content {
    overflow: auto;

    img {
      box-sizing: initial;
    }

    &--empty {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
