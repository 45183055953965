import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";

export enum SocRemediationType {
  REMEDIATE_MYSELF = "remediateMyself",
  REMEDIATE_BY_CORO_SOC = "remediateByCoroSoc",
}

export enum SocRemediationNotifications {
  NO_REMEDIATION = "noRemediation",
  MEDIUM_IMPACT_REMEDIATION = "mediumImpactRemediation",
  LOW_IMPACT_REMEDIATION = "lowImpactRemediation",
}

interface SocRemediationSettings {
  remediationType: SocRemediationType;
  grantAccess: boolean;
  notification: SocRemediationNotifications;
}

interface SocRemediationState {
  settings: SocRemediationSettings;
  showSkeletonLoader: boolean;
}

export const defaultSocRemediationState: SocRemediationState = {
  settings: {
    remediationType: SocRemediationType.REMEDIATE_MYSELF,
    grantAccess: false,
    notification: SocRemediationNotifications.NO_REMEDIATION,
  },
  showSkeletonLoader: false,
};

export const useSocRemediationStore = defineStore("socRemediation", {
  state: (): SocRemediationState => ({ ...defaultSocRemediationState }),
  persist: false,
  actions: {
    async getSocRemediationSettings(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;
      const request = {
        ...api.socRemediation,
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.settings = data;
        this.showSkeletonLoader = false;
      } catch (e) {
        console.error(e);
        this.showSkeletonLoader = false;
      }
    },
    async updateSocRemediationSettings(payload: SocRemediationSettings) {
      const request = {
        ...api.socRemediation,
        method: "PUT",
        data: payload,
      };
      try {
        await axiosInstance.request(request);
        await this.getSocRemediationSettings(false);
      } catch (e) {
        console.error(e);
      }
    },
  },
});
