<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("endpointDataGovernance.title") }}
      </div>
      <div>
        <v-btn
          rounded
          color="primary"
          density="default"
          size="large"
          v-if="showAddSensitiveScanBtn"
          @click="addSensitiveDataScan()"
        >
          <v-icon icon="$add" class="mr-1"></v-icon>
          {{ $t("endpointDataGovernance.sensitiveDataScansTab.addBtn") }}
        </v-btn>
      </div>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useRoute } from "vue-router";
import { useSensitiveDataScansStore } from "@/_store/endpoint-data-governance/sensitive-data-scans.module";
import { storeToRefs } from "pinia";
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import {
  defaultSensitiveDataItem,
  SensitiveDataScansActions,
} from "@/constants/endpoint-data-governance";
import SensitiveDataScansModal from "@/components/modals/SensitiveDataScansModal.vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import cloneDeep from "lodash/cloneDeep";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";

import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const dialogsStore = useDialogsStore();
    const currentRoute = computed(() => route.name);
    const sensitiveDataScansStore = useSensitiveDataScansStore();
    const { items } = storeToRefs(sensitiveDataScansStore);
    const { createSensitiveDataScanPolicy } = sensitiveDataScansStore;
    const showAddSensitiveScanBtn = computed(() => {
      return (
        currentRoute.value === RouteName.ENDPOINT_DATA_GOVERNANCE_SENSITIVE_DATA_SCANS_TAB &&
        items.value.length > 0
      );
    });
    const addSensitiveDataScan = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        item: {
          ...cloneDeep(defaultSensitiveDataItem),
        },
        action: SensitiveDataScansActions.CREATE,
        component: SensitiveDataScansModal,
        width: ModalWidth.LARGE,
        callback: createSensitiveDataScanPolicy,
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.PROTECTION,
          SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
        ),
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t("endpointDataGovernance.privacySensitiveDataTab.title"),
        route: { name: RouteName.ENDPOINT_DATA_GOVERNANCE_PRIVACY_SENSITIVE_DATA_TAB },
      },
      {
        id: 2,
        name: i18n.t("endpointDataGovernance.sensitiveDataScansTab.title"),
        route: { name: RouteName.ENDPOINT_DATA_GOVERNANCE_SENSITIVE_DATA_SCANS_TAB },
      },
    ];
    return {
      tabs,
      showAddSensitiveScanBtn,
      addSensitiveDataScan,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped></style>
