<template>
  <v-form
    ref="form"
    v-model="valid"
    v-click-outside="{
      handler: onClickOutside,
    }"
    lazy-validation
  >
    <v-textarea
      v-model.trim="localValue.message"
      class="coro-input-light"
      variant="outlined"
      :label="$t('modals.addNewComment.label')"
      :placeholder="$t('modals.addNewComment.placeholder')"
      hide-details
      auto-grow
      rows="1"
      @focus="focused = true"
    >
    </v-textarea>
    <div v-if="showFooter" class="d-flex justify-space-between align-center mt-4">
      <div class="d-flex align-center">
        <div class="mr-5 cursor-pointer text-no-wrap" @click="expand = !expand">
          <v-icon icon="$triangle" size="8"></v-icon>
          <span class="ml-2 body2">{{ $t("general.notify") }}</span>
        </div>
        <div v-if="isGlobalAdmin" class="confidential-switch pl-5">
          <v-switch
            v-model="confidential"
            :ripple="false"
            density="compact"
            flat
            inset
            color="primary"
            hide-details
            :label="$t('comments.confidential')"
          />
        </div>
      </div>
      <div class="d-flex">
        <v-btn variant="text" color="primary" @click="cancel()">
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn rounded color="primary" @click="addNewComment()">
          {{ $t("general.comment") }}
        </v-btn>
      </div>
    </div>

    <div v-if="expand" class="mt-4">
      <coro-comment-notification
        :settings="localValue"
        :confidential="confidential"
        @update-value="updateNotificationSettings($event)"
      ></coro-comment-notification>
    </div>

    <v-divider v-if="showFooter" class="mt-6 mb-6"></v-divider>
  </v-form>
</template>

<script lang="ts">
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { isDeviceTicket } from "@/_helpers/utils";
import { computed, defineComponent, type PropType, ref } from "vue";
import CoroCommentNotification from "@/components/CoroCommentNotification.vue";
import {
  type CommentSettings,
  defaultCommentsSettings,
  useTicketCommentsStore,
} from "@/_store/ticket-comments.module";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import type { VuetifyFormRef } from "@/types";
import { useAccountStore } from "@/_store";

export default defineComponent({
  components: { CoroCommentNotification },
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const ticketCommentsStore = useTicketCommentsStore();
    const accountStore = useAccountStore();
    const localValue = ref({
      ...cloneDeep(defaultCommentsSettings),
      socModuleEnabled: props.ticket.socModuleEnabled,
      isDeviceTicket: isDeviceTicket(props.ticket.eventType),
    });
    const confidential = ref(false);
    const valid = ref(false);
    const focused = ref(false);
    const expand = ref(false);
    const form = ref<VuetifyFormRef>();
    const isGlobalAdmin = computed(() => accountStore.isGlobalAdmin);
    const showFooter = computed(() => {
      return focused.value || localValue.value.message;
    });

    const cancel = () => {
      localValue.value = cloneDeep({
        ...cloneDeep(defaultCommentsSettings),
        socModuleEnabled: props.ticket.socModuleEnabled,
        isDeviceTicket: isDeviceTicket(props.ticket.eventType),
      });
      expand.value = false;
      focused.value = false;
      confidential.value = false;
    };

    const onClickOutside = async () => {
      const {
        notifyAffectedUsers,
        notifyAllWorkspaceAdmins,
        includeTicketDetails,
        notifyCustomRecipients,
        notifySocContacts,
        customRecipients,
        notifyCoroAdmins,
        message,
      } = localValue.value;
      if (
        !isEqual(
          {
            notifyAffectedUsers,
            notifyAllWorkspaceAdmins,
            includeTicketDetails,
            notifyCustomRecipients,
            notifySocContacts,
            customRecipients,
            notifyCoroAdmins,
            message,
          },
          defaultCommentsSettings
        )
      )
        return;
      await form.value?.validate();
      cancel();
      focused.value = false;
    };

    const updateNotificationSettings = (event: CommentSettings) => {
      localValue.value = {
        ...localValue.value,
        ...cloneDeep(event),
      };
    };

    const addNewComment = async () => {
      const validationResult = await form.value?.validate();
      if (!validationResult?.valid) return;
      if (localValue.value.message) {
        await ticketCommentsStore.addComment({
          ...localValue.value,
          ...props.ticket,
          confidential: confidential.value,
        });
        cancel();
      }
    };

    return {
      form,
      localValue,
      confidential,
      valid,
      focused,
      expand,
      isGlobalAdmin,
      showFooter,
      onClickOutside,
      cancel,
      updateNotificationSettings,
      addNewComment,
    };
  },
});
</script>

<style lang="scss" scoped>
.confidential-switch {
  border-left: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>
