<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.DEFAULT_TABLE"
    >
      <template v-if="webhooks.length">
        <v-alert
          v-for="alert in alerts"
          :key="alert.type"
          :closable="true"
          type="warning"
          icon="icon-warning"
          :color="alert.type === SeverityType.ERROR ? 'red-faint' : 'yellow-faint'"
          class="mb-5"
          :class="{
            'text-yellow-base warning-block': alert.type === SeverityType.WARNING,
            'text-red-dark': alert.type === SeverityType.ERROR,
          }"
        >
          <div
            class="subtitle1"
            :class="{
              'text-yellow-base': alert.type === SeverityType.WARNING,
              'text-red-dark': alert.type === SeverityType.ERROR,
            }"
            v-html="alert.title"
          ></div>
          <div v-html="alert.body"></div>
        </v-alert>

        <v-progress-linear
          :style="{ visibility: loading ? 'visible' : 'hidden' }"
          indeterminate
          height="2px"
        />
        <table-wrapper>
          <v-data-table-server
            class="white"
            v-model:expanded="expanded"
            :items="webhooks"
            item-value="id"
            :items-length="webhooks.length"
            :show-expand="true"
            :hide-default-header="true"
            color="primary"
          >
            <template #headers>
              <tr>
                <th class="w-5"></th>
                <th>{{ $t("connectors.webhooks.table.headers.name") }}</th>
                <th>{{ $t("connectors.webhooks.table.headers.status") }}</th>
                <th>{{ $t("connectors.webhooks.table.headers.created") }}</th>
                <th>{{ $t("connectors.webhooks.table.headers.expires") }}</th>
                <th>
                  {{ $t("connectors.webhooks.table.headers.appliedToChildWorkspace") }}
                </th>
                <th class="w-5"></th>
              </tr>
            </template>

            <template #item="{ item, toggleExpand, isExpanded, internalItem }">
              <tr
                :class="{
                  'disabled-webhook text-indigo-medium': [
                    WebhookStatus.DISABLED,
                    WebhookStatus.DISCONNECTED,
                    WebhookStatus.INACTIVE,
                  ].includes(item.status),
                }"
              >
                <td class="w-5" :class="{ 'border-0': isExpanded(internalItem) }">
                  <v-icon
                    class="mr-2 cursor-pointer"
                    icon="$dropdown"
                    color="primary"
                    :class="{ rotated: isExpanded(internalItem) }"
                    @click="toggleExpand(internalItem)"
                  />
                </td>
                <td class="pl-0 font-weight-bold w-33 white-space-normal">
                  {{ item.name }}
                </td>
                <td :class="getWebhookStatusClass(item.status)">
                  {{ $t(`connectors.webhooks.status.${item.status}`) }}
                </td>
                <td>
                  {{
                    item.created ? format(item.created, "MMM d, yyyy") : $t("general.notAvailable")
                  }}
                </td>
                <td>
                  <div v-if="!item.isExpired">
                    {{
                      item.expirationDate
                        ? format(item.expirationDate, "MMM d, yyyy")
                        : $t("general.notAvailable")
                    }}
                  </div>
                  <span
                    v-if="item.expirationDate"
                    class="caption"
                    :class="getExpirationClass(item)"
                    >{{ item.expirationLabel }}</span
                  >
                </td>
                <td :class="{ 'text-indigo-medium': !item.applyToAllDescendants }">
                  {{ $t(item.applyToAllDescendants ? "general.yes" : "general.no") }}
                </td>
                <td class="w-5">
                  <v-menu bottom left>
                    <template v-slot:activator="{ props }">
                      <v-icon icon="$dots" class="item-clickable" v-bind="props"></v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(action, index) in getActions(item)"
                        :key="index"
                        :value="index"
                        @click="action.callback()"
                      >
                        <v-list-item-title>
                          {{ $t(`connectors.webhooks.table.actions.${action.action}`) }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-row="{ item }">
              <tr class="border-opacity-0" v-if="item.status === WebhookStatus.ERROR">
                <td></td>
                <td colspan="6" class="pl-0">
                  <v-alert
                    :closable="false"
                    type="warning"
                    icon="icon-warning"
                    color="red-faint"
                    class="mt-5 text-red-dark"
                  >
                    <div class="subtitle1 text-red-dark">
                      {{ $t("connectors.webhooks.error.title") }}
                    </div>
                    <div>{{ $t("connectors.webhooks.error.subtitle") }}</div>
                    <span
                      @click="testWebhook(item)"
                      class="text-decoration-underline font-weight-bold"
                      role="button"
                      >{{ $t("connectors.webhooks.error.retest") }}</span
                    >
                  </v-alert>
                </td>
              </tr>
              <tr>
                <td></td>
                <td colspan="5" class="pa-0">
                  <div class="subtitle2 mt-4" v-if="item.description">
                    {{ $t("connectors.webhooks.table.sections.description") }}
                  </div>
                  <div class="white-space-normal">{{ item.description }}</div>
                  <div class="subtitle2 mt-4">
                    {{ $t("connectors.webhooks.table.sections.authenticationUrl") }}
                  </div>
                  <div>{{ item.webhookUrl }}</div>
                  <div class="subtitle2 mt-4">
                    {{ $t("connectors.webhooks.table.sections.secret") }}
                  </div>
                  <div>{{ item.secret }}</div>
                  <div v-if="item.headers?.length">
                    <div class="subtitle2 mt-4">
                      {{ $t("connectors.webhooks.table.sections.authorization") }}
                    </div>
                    <div v-for="header in item.headers" v-bind:key="header.key">
                      <span class="subtitle2">{{
                        $t("connectors.webhooks.table.sections.header") + ":"
                      }}</span>
                      {{ header.key }} /
                      <span class="subtitle2">{{
                        $t("connectors.webhooks.table.sections.value") + ":"
                      }}</span>
                      {{ header.value }}
                    </div>
                  </div>

                  <div>{{ item.authorization }}</div>
                  <table class="w-100 mt-10 mb-3">
                    <thead>
                      <th class="text-left">
                        <span class="subtitle2 mt-4">{{
                          $t("connectors.webhooks.table.sections.trigger")
                        }}</span>
                      </th>
                      <th class="text-left">
                        <span class="subtitle2 mt-4">{{
                          $t("connectors.webhooks.table.sections.lastUsed")
                        }}</span>
                      </th>
                      <th class="text-left">
                        <span class="subtitle2 mt-4">{{
                          $t("connectors.webhooks.table.sections.testStatus")
                        }}</span>
                      </th>
                      <th class="text-left" v-if="item.status === WebhookStatus.ENABLED">
                        <span class="subtitle2 mt-4">{{
                          $t("connectors.webhooks.table.sections.action")
                        }}</span>
                      </th>
                    </thead>
                    <tr class="h-0" v-for="trigger in item.triggers" :key="trigger.triggerId">
                      <td class="w-40">
                        <div>
                          {{ $t(`connectors.webhooks.webhookTriggers.${trigger.triggerId}`) }}
                          {{ `(${trigger.version})` }}
                        </div>
                      </td>
                      <td>
                        <div>
                          {{
                            trigger.lastUsed
                              ? format(trigger.lastUsed, "MMM d, yyyy")
                              : $t("general.notAvailable")
                          }}
                        </div>
                      </td>
                      <td>
                        <div v-if="trigger.testStatus" :class="getStatusClass(trigger.testStatus!)">
                          {{ $t(`connectors.webhooks.testStatus.${trigger.testStatus}`) }}
                          ({{ format(trigger.lastTestAt!, "d/MM/yy 'at' HH:mm") }})
                        </div>
                        <div v-else class="text-indigo-medium">
                          {{ $t(`connectors.webhooks.testStatus.notTested`) }}
                        </div>
                      </td>
                      <td v-if="item.status === WebhookStatus.ENABLED">
                        <div class="coro-link" @click="onTestTrigger(item, trigger)">
                          {{ $t("general.test") }}
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
                <td></td>
              </tr>
            </template>
            <template #bottom></template>
          </v-data-table-server>
        </table-wrapper>
      </template>
      <template v-else>
        <empty-state class="margin-auto mt-12 w-65">
          <template #icon>
            <v-icon icon="$connectors" :size="80" />
          </template>
          <template #description>
            <div class="headline5 mb-3 mt-3">
              {{ $t("connectors.webhooks.noItems.description") }}
            </div>
          </template>
          <template #subtitle>
            {{ $t("connectors.webhooks.noItems.subDescription") }}
          </template>
          <template #button>
            <v-btn
              rounded
              color="primary"
              density="default"
              size="large"
              elevation="0"
              @click="openModal()"
            >
              {{ $t("connectors.webhooks.add") }}
            </v-btn>
          </template>
        </empty-state>
      </template>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { storeToRefs } from "pinia";
import EmptyState from "@/components/EmptyState.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import { useI18n } from "vue-i18n";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import AddWebhookModal from "@/components/modals/connectors/AddWebhookModal.vue";
import type { Webhook, WebhookTrigger } from "@/_store/connectors/webhooks.module";
import { useWebhooksStore } from "@/_store/connectors/webhooks.module";
import { WebhookAction, WebhookStatus, WebhookTriggerTestStatus } from "@/constants/webhooks";
import { format } from "date-fns";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { SeverityType } from "@/constants/general";

export default defineComponent({
  components: { TableWrapper, EmptyState },
  setup() {
    const webhooksModule = useWebhooksStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CONNECTORS
      );
    });
    const { webhooks, showSkeletonLoader, loading, alerts } = storeToRefs(webhooksModule);

    const { getWebhooks, updateWebhook, addWebhook, deleteWebhook, testWebhook } = webhooksModule;

    const expanded = ref<string[]>([]);

    onMounted(async () => {
      await getWebhooks();

      const { webhookId } = route.query;

      if (webhookId) {
        const webhookToEdit = webhooks.value.find((webhook) => webhook.id === webhookId);

        if (webhookToEdit) {
          openModal(webhookToEdit);
        }

        router.replace({ query: {} });

        return;
      }

      expanded.value = webhooks.value
        .filter((webhook) => webhook.status === WebhookStatus.ERROR)
        .map(({ id }) => id);

      const webhooksToExpire = webhooks.value.filter((webhook) => webhook.showExpirationModal);

      if (webhooksToExpire.length && !actionNotAllowed.value) {
        const firstAboutToExpireWebhook = webhooksToExpire[0];
        const webhookNames = webhooksToExpire.map((webhook) => webhook.name).join(", ");

        dialogsStore.openDialog({
          ...confirmationDialogsConfigConstructor({
            action: WebhookAction.ACTION_REQUIRED,
            width: ModalWidth.MEDIUM,
            text: i18n.t(
              `modals.${WebhookAction.ACTION_REQUIRED}.description`,
              {
                name: webhookNames,
                expirationDate: moment(firstAboutToExpireWebhook.expirationDate).format(
                  "MMM D, yyyy"
                ),
              },
              webhooksToExpire.length
            ),
            callback: () => {
              nextTick(() => {
                openModal(firstAboutToExpireWebhook);
              });
            },
          }),
          header: {
            title: i18n.t(
              "connectors.webhooks.alerts.aboutToExpire.title",
              webhooksToExpire.length
            ),
            close: true,
          },
        });
      }
    });

    const openModal = (item?: Webhook) => {
      const dialogConfig = componentDialogsConfigConstructor({
        item,
        action: item ? WebhookAction.EDIT : WebhookAction.ADD,
        component: AddWebhookModal,
        width: ModalWidth.LARGE,
        disable: actionNotAllowed.value,
        callback: item ? updateWebhook : addWebhook,
        scrollable: true,
      });

      dialogsStore.openDialog({
        ...dialogConfig,
      });
    };

    const getExpirationClass = (item: Webhook) => {
      return item.isExpired ? "text-red-dark" : "text-yellow-base";
    };

    const openConfirmationModal = (action: WebhookAction, item: Webhook) => {
      dialogsStore.openDialog({
        ...confirmationDialogsConfigConstructor({
          action,
          disable: actionNotAllowed.value,
          item,
          text: i18n.t(`modals.${action}.description`),
          callback: async () => {
            switch (action) {
              case WebhookAction.REMOVE: {
                await deleteWebhook(item);
                break;
              }
              case WebhookAction.DEACTIVATE: {
                await updateWebhook({
                  ...item,
                  status: WebhookStatus.DISABLED,
                });
                break;
              }
              case WebhookAction.ACTIVATE: {
                await updateWebhook({
                  ...item,
                  status: WebhookStatus.ENABLED,
                });
                break;
              }
              default: {
                return;
              }
            }
          },
        }),
      });
    };

    const openCannotActivateDialog = (item: Webhook) => {
      dialogsStore.openDialog({
        ...confirmationDialogsConfigConstructor({
          action: WebhookAction.CANNOT_ACTIVATE,
          width: ModalWidth.MEDIUM,
          text: i18n.t(`modals.${WebhookAction.CANNOT_ACTIVATE}.description`),
          callback: () => {
            nextTick(() => {
              openModal(item);
            });
          },
        }),
        header: {
          title: i18n.t(i18n.t(`modals.${WebhookAction.CANNOT_ACTIVATE}.title`)),
          close: true,
        },
      });
    };

    const getStatusClass = (status: WebhookTriggerTestStatus) => {
      return status === WebhookTriggerTestStatus.SUCCESS ? "text-green-dark" : "text-red-dark";
    };

    const getWebhookStatusClass = (status: WebhookStatus) => {
      if (status === WebhookStatus.ERROR) {
        return "text-red-dark";
      }

      if (status === WebhookStatus.ENABLED) {
        return "text-green-dark";
      }

      return "text-indigo-medium";
    };

    const getActions = (item: Webhook) => {
      if ([WebhookStatus.DISCONNECTED, WebhookStatus.DISABLED].includes(item.status)) {
        return [
          {
            action: WebhookAction.ACTIVATE,
            show: () => !actionNotAllowed.value,
            callback: () => openConfirmationModal(WebhookAction.ACTIVATE, item),
          },
          {
            action: WebhookAction.REMOVE,
            show: () => !actionNotAllowed.value,
            callback: () => openConfirmationModal(WebhookAction.REMOVE, item),
          },
        ];
      } else if (item.status === WebhookStatus.INACTIVE) {
        return [
          {
            action: WebhookAction.EDIT,
            callback: () => openModal(item),
          },
          {
            action: WebhookAction.ACTIVATE,
            show: () => !actionNotAllowed.value,
            callback: () => openCannotActivateDialog(item),
          },
          {
            action: WebhookAction.REMOVE,
            show: () => !actionNotAllowed.value,
            callback: () => openConfirmationModal(WebhookAction.REMOVE, item),
          },
        ];
      } else {
        return [
          {
            action: WebhookAction.EDIT,
            callback: () => openModal(item),
          },
          {
            action: WebhookAction.TEST,
            show: () => !actionNotAllowed.value,
            callback: async () => await testWebhook(item),
          },
          {
            action: WebhookAction.DEACTIVATE,
            show: () => !actionNotAllowed.value,
            callback: () => openConfirmationModal(WebhookAction.DEACTIVATE, item),
          },
          {
            action: WebhookAction.REMOVE,
            show: () => !actionNotAllowed.value,
            callback: () => openConfirmationModal(WebhookAction.REMOVE, item),
          },
        ];
      }
    };

    const onTestTrigger = async (webhook: Webhook, trigger: WebhookTrigger) => {
      await testWebhook(webhook, trigger);
    };

    return {
      coronetSkeletonLoaderTypes,
      showSkeletonLoader,
      loading,
      webhooks,
      openModal,
      getActions,
      expanded,
      format,
      testWebhook,
      getStatusClass,
      getWebhookStatusClass,
      onTestTrigger,
      WebhookStatus,
      getExpirationClass,
      alerts,
      SeverityType,
    };
  },
});
</script>

<style lang="scss" scoped>
.heading-text {
  line-height: 40px;
}

.disabled-webhook {
  background-color: rgb(var(--v-theme-indigo-pale));
}

.text-indigo-medium {
  > td {
    color: rgb(var(--v-theme-indigo-medium));
  }
}

:deep(*) {
  .v-table__wrapper {
    border-radius: 0;
  }
}
</style>
