import { defineStore } from "pinia";
import { useErrorsStore } from "@/_store/errors.module";
import type { GenericCallback } from "@/types";

export enum SnackbarTypes {
  INFO = "info",
  WARNING = "warning",
  SUCCESS = "success",
  ERROR = "error",
}

export interface SnackbarItemConfig {
  type: SnackbarTypes;
  id: number;
  hidden: boolean;
  recentlyAdded: boolean;
  elementId?: number;
  callback?: GenericCallback;
  callbackName: string;
  html: string; // have seen somewhere this property
}

interface SnackbarState {
  lastPushedId: number;
  config: {
    speedAppear: number;
    speedDisappear: number;
    speedCollapse: number;
    speedInterval: number;
    maxSize: number;
  };
  stack: Array<SnackbarItemConfig>;
  busy: boolean;
}

const defaultSnackbarState = {
  lastPushedId: 4,
  config: {
    speedAppear: 100,
    speedDisappear: 75,
    speedCollapse: 75,
    speedInterval: 5000,
    maxSize: 5,
  },
  stack: [],
  busy: false,
};

export const useSnackbarStore = defineStore("snackbar", {
  state: (): SnackbarState => ({ ...defaultSnackbarState }),
  getters: {
    snackbarStack(state) {
      return state.stack.slice(0, state.config.maxSize);
    },
    stackExcludingHidden(state) {
      return state.stack.filter((item) => item.hidden === false);
    },
  },
  actions: {
    add(item: Partial<SnackbarItemConfig>) {
      this.increaseLastPushedId();
      const id = this.lastPushedId;
      item.type = item.type || SnackbarTypes.INFO;
      item = { ...item, id, hidden: false, recentlyAdded: true };
      this.push(item as SnackbarItemConfig);
      setTimeout(() => {
        const index = this.stack.findIndex((x) => x.id === id);
        this.disableRecentlyAdded(index);
      }, this.config.speedAppear);
      this.startRemovalByInterval();
    },
    remove(id: number) {
      const index = this.stack.findIndex((x) => x.id === id);
      this.hide(index);
      setTimeout(() => {
        const indexToDelete = this.stack.findIndex((x) => x.id === id);
        this.removeSnackbar(indexToDelete);
      }, this.config.speedDisappear + this.config.speedCollapse);
    },
    addGenericSuccess(html: string): void {
      const snackbarMessage = {
        html,
        type: SnackbarTypes.SUCCESS,
      };

      this.add(snackbarMessage);
    },
    startRemovalByInterval() {
      const errorsStore = useErrorsStore();
      if (!this.busy) {
        if (this.stackExcludingHidden.length > 0) {
          this.setBusy(true);
          const interval = setInterval(() => {
            if (this.stackExcludingHidden.length) {
              this.remove(this.stackExcludingHidden[0].id);
              errorsStore.pop();
            }
            if (!this.stackExcludingHidden.length) {
              this.setBusy(false);
              clearInterval(interval);
            }
          }, this.config.speedInterval);
        }
      }
    },
    increaseLastPushedId() {
      this.lastPushedId += 1;
    },
    push(item: SnackbarItemConfig) {
      this.stack.push(item);
    },
    hide(index: number) {
      this.stack[index].hidden = true;
    },
    disableRecentlyAdded(index: number) {
      this.stack[index].recentlyAdded = false;
    },
    removeSnackbar(index: number) {
      this.stack.splice(index, 1);
    },
    setBusy(value: boolean) {
      this.busy = value;
    },
  },
});
