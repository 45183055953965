<template>
  <div class="coro-empty-state">
    <slot name="icon" />
    <div class="headline6 mt-2">
      <slot name="description" />
    </div>
    <div class="body2 coro-empty-state-subtitle">
      <slot name="subtitle" />
    </div>
    <slot name="button" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped lang="scss">
.coro-empty-state {
  width: 510px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-items: center;
  text-align: center;
  &-subtitle {
    color: rgba(var(--v-theme-primary), 0.65);
    margin: 5px 20px 24px;
  }
}
</style>
