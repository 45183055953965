<template>
  <div class="emails-page content-md mt-2 margin-auto">
    <div>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </div>
    <div class="headline5 settings-header-with-btn">
      {{ $t("emailSettings.title") }}
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-4"> </page-tabs>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { SubscriptionAddon } from "@/constants/workplaces";
import { isAccessRestricted, isAddonDisabled } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useAccountStore } from "@/_store";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const accountStore = useAccountStore();
    const { showDisabledModules } = accountStore.account;

    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t("emailSettings.tabs.settings"),
        route: { name: RouteName.EMAIL_SECURITY_SETTINGS_TAB },
      },
      {
        id: 2,
        name: i18n.t("emailSettings.tabs.allowBlockList"),
        route: { name: RouteName.EMAIL_SECURITY_ALLOW_BLOCK_LIST_TAB },
      },
      {
        id: 3,
        name: i18n.t("emailSettings.tabs.addIns"),
        route: { name: RouteName.EMAIL_SECURITY_ADD_INS_TAB },
      },
      {
        id: 4,
        name: i18n.t("emailSettings.tabs.inboundGateway"),
        route: { name: RouteName.EMAIL_SECURITY_INBOUND_GATEWAY_TAB },
        addonOrModule: SubscriptionAddon.INBOUND_GATEWAY,
        disabled: isAddonDisabled(SubscriptionAddon.INBOUND_GATEWAY),
        hide:
          (!showDisabledModules && isAddonDisabled(SubscriptionAddon.INBOUND_GATEWAY)) ||
          isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.INBOUND_GATEWAY),
      },
      {
        id: 5,
        name: i18n.t("emailSettings.tabs.coroMail"),
        route: { name: RouteName.EMAIL_SECURITY_CORO_MAIL_TAB },
        disabled: isAddonDisabled(SubscriptionAddon.SECURED_MESSAGES),
        addonOrModule: SubscriptionAddon.SECURED_MESSAGES,
        hasSeparator: true,
        hide:
          (!showDisabledModules && isAddonDisabled(SubscriptionAddon.SECURED_MESSAGES)) ||
          isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SECURED_MESSAGES),
      },
    ]
      .filter((tab) => !tab.hide)
      .map(({ hide, ...tab }) => ({ ...tab }));

    return {
      breadcrumbs: [
        {
          title: `${i18n.t("general.backToControlPanel")}`,
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      tabs,
    };
  },
});
</script>
<style lang="scss" scoped>
:deep(*) {
  .bar.nav-tabs {
    .wrapper {
      z-index: 0 !important;
    }
  }
}
</style>
