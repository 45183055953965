<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("devicesSettings.title") }}
      </div>

      <v-menu v-if="isPostureTab" offset-y bottom right z-index="100">
        <template v-slot:activator="{ props }">
          <v-btn
            class="add-btn"
            color="primary"
            rounded
            large
            data-testid="data-governance-add-permission-btn"
            v-bind="props"
          >
            <v-icon class="mr-2" icon="$add"></v-icon>
            {{ $t("general.add") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :key="action.osType"
            v-for="action in addPolicyActions"
            @click="handleAction(action)"
          >
            <v-list-item-title>
              {{ action.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import {
  componentDialogsConfigConstructor,
  isAccessRestricted,
  isAddonDisabled,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { useAccountStore } from "@/_store/account.module";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useRoute, useRouter } from "vue-router";
import {
  DevicePostureAction,
  DevicePostureOsType,
  DevicePostureSettings,
} from "@/constants/devices";
import { ModalWidth } from "@/constants/modals";
import AddOrEditPostureModal from "@/components/modals/device-posture/AddOrEditPostureModal.vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useDevicesPostureStore } from "@/_store/device-posture/devices-posture-settings.module";
import type { DeviceLabel } from "@/_store/devices-settings.module";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const accountStore = useAccountStore();
    const dialogsStore = useDialogsStore();
    const devicesPostureModule = useDevicesPostureStore();
    const route = useRoute();
    const router = useRouter();

    const tabs = computed<CoroTab[]>(() => {
      const tabs = [
        {
          id: 1,
          name: i18n.t("devicesSettings.tabs.devicePosture"),
          route: { name: RouteName.ENDPOINT_SECURITY_DEVICE_POSTURE_TAB },
        },
        {
          id: 2,
          name: i18n.t("devicesSettings.tabs.ngav"),
          route: { name: RouteName.ENDPOINT_SECURITY_NGAV_TAB },
        },
        {
          id: 3,
          name: i18n.t("devicesSettings.tabs.allowBlock"),
          route: { name: RouteName.ENDPOINT_SECURITY_ALLOW_BLOCK_LIST_TAB },
        },
        {
          id: 4,
          name: i18n.t("devicesSettings.tabs.addons"),
          route: { name: RouteName.ENDPOINT_SECURITY_DEVICE_ADDONS_TAB },
          disabled: isAddonDisabled(SubscriptionAddon.WIFI_PHISHING),
          hasSeparator: true,
        },
      ];
      if (
        (!accountStore.account.showDisabledModules &&
          isAddonDisabled(SubscriptionAddon.WIFI_PHISHING)) ||
        isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.WIFI_PHISHING)
      ) {
        tabs.pop();
      }

      return tabs;
    });

    const isPostureTab = computed(() => {
      return route.name === RouteName.ENDPOINT_SECURITY_DEVICE_POSTURE_TAB;
    });

    onMounted(async () => {
      const { policyType, enrollmentCode, osType } = route.query;

      let labels: DeviceLabel[] = [];

      if (enrollmentCode) {
        labels = await devicesPostureModule.getDeviceLabels(enrollmentCode as string);
      }

      if (policyType && osType) {
        handleAction(
          {
            osType: DevicePostureOsType[osType as keyof typeof DevicePostureOsType],
            action: DevicePostureAction.ADD,
          },
          labels,
          policyType as DevicePostureSettings
        );
      }
      router.replace({ query: {} });
    });

    const handleAction = (
      {
        osType,
        action,
      }: {
        osType: DevicePostureOsType;
        action: DevicePostureAction;
      },
      labels: DeviceLabel[] = [],
      policyType?: DevicePostureSettings
    ) => {
      const config = componentDialogsConfigConstructor({
        action,
        item: {
          osType,
          labels,
          policyType,
        },
        width: ModalWidth.LARGE,
        component: AddOrEditPostureModal,
        callback: devicesPostureModule.addDevicePosturePolicy,
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.PROTECTION,
          SubscriptionModule.ENDPOINT_SECURITY
        ),
        scrollable: true,
      });

      const translatedOsType = i18n.t(`devicesSettings.devicePostureTab.osTypes.${osType}`);

      dialogsStore.openDialog({
        ...config,
        header: {
          title: i18n.t(`modals.${action}.title`, { osType: translatedOsType }),
        },
      });
    };

    const addPolicyActions = [
      {
        action: DevicePostureAction.ADD,
        osType: DevicePostureOsType.WINDOWS,
        title: i18n.t(`devicesSettings.devicePostureTab.actions.${DevicePostureAction.ADD}`, {
          service: i18n.t(
            `devicesSettings.devicePostureTab.osTypes.${DevicePostureOsType.WINDOWS}`
          ),
        }),
      },
      {
        action: DevicePostureAction.ADD,
        osType: DevicePostureOsType.MAC_OS,
        title: i18n.t(`devicesSettings.devicePostureTab.actions.${DevicePostureAction.ADD}`, {
          service: i18n.t(`devicesSettings.devicePostureTab.osTypes.${DevicePostureOsType.MAC_OS}`),
        }),
      },
    ];
    return {
      tabs,
      isPostureTab,
      addPolicyActions,
      handleAction,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>
