import { defineStore } from "pinia";
interface ErrorsState {
  errors: Array<any>;
}

const defaultErrorsState: ErrorsState = {
  errors: [],
};

export const useErrorsStore = defineStore("errors", {
  state: (): ErrorsState => ({ ...defaultErrorsState }),
  persist: false,
  getters: {
    error(state) {
      return state.errors.length > 0 ? state.errors[state.errors.length - 1] : null;
    },
  },
  actions: {
    push(error: any) {
      this.errors.push(error);
    },
    pop() {
      this.errors.pop();
    },
  },
});
