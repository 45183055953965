<template>
  <v-card class="px-8 py-4">
    <div class="d-flex flex-column">
      <div class="vpn-policy">
        <div class="ips-data">
          <div class="headline6" v-text="localValue.region" />
          <div class="body1">
            <span class="text--semitransparent">
              {{ $t("network.virtualOffice.ipAddresses", localValue.servers?.length) }}:
              {{ ips }}</span
            >
            <span
              v-if="hasManyServers"
              role="button"
              class="body2 coro-link"
              @click="onShowAllIpsClick"
              >&nbsp;{{ $t("general.viewAll") }} ›</span
            >
          </div>
          <v-divider class="mt-6 mb-6" />
          <div class="d-flex justify-space-between mt-8">
            <div class="subtitle1" v-text="$t('network.virtualOffice.vpnPolicy')"></div>
            <div>
              <div class="d-flex align-center">
                <div class="vpn-circle vpn-circle--12px mr-2"></div>
                <div
                  class="body2 text--black"
                  v-text="$t('network.virtualOffice.trafficNotThroughVpn')"
                />
              </div>
              <div class="d-flex align-center">
                <div class="vpn-circle vpn-circle--primary vpn-circle--12px mr-2" />
                <div
                  class="body2 text--black"
                  v-text="$t('network.virtualOffice.trafficThroughVpn')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-7 d-flex align-center">
          <div class="vpn-circle d-flex align-center justify-center">
            <span
              class="text--primary subtitle2 text-center text-uppercase"
              v-html="$t('network.virtualOffice.defaultSettings')"
            >
            </span>
          </div>
          <span class="subtitle1 ml-10" v-text="$t('network.virtualOffice.mode1Description')" />
        </div>

        <div class="mb-7 d-flex align-center">
          <div class="vpn-circle d-flex align-center justify-center">
            <div
              class="vpn-circle vpn-circle--primary vpn-circle--smallest d-flex align-center justify-center"
            >
              <v-icon color="primary" size="80" icon="$hexagonVo" />
            </div>
          </div>
          <div class="ml-10">
            <span class="subtitle1 mb-2" v-text="$t('network.virtualOffice.mode2Description')" />
            <div
              class="body1 mt-4"
              :class="{ 'text-indigo-medium': actionNotAllowed }"
              v-text="$t('devicesSettings.settingsCard.applyToDevices')"
            />
            <v-autocomplete
              v-model="localValue.mode2Labels"
              class="mt-4"
              :items="labels"
              :label="autocompleteLabel(localValue?.mode2Labels?.length)"
              :placeholder="$t('network.virtualOffice.labelsAutocompletePlaceholder')"
              :disabled="actionNotAllowed"
              variant="outlined"
              multiple
              return-object
              chips
              closable-chips
              clear-on-select
              data-testid="device-settings-tamper-resistance-labels"
              item-value="id"
              item-title="name"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip
                  v-bind="props"
                  :closable="true"
                  variant="flat"
                  size="default"
                  :color="item.raw.color"
                  close-icon="$closeCircle"
                  :text="item.raw.name"
                  class="label-chip"
                >
                  <div class="d-flex align-center">
                    <span class="ml-1 mr-2 body2" v-text="item.title" />
                  </div>
                </v-chip>
              </template>
              <template v-slot:item="{ item, props }">
                <v-list-item v-bind="props" title="">
                  <template #default="{ isSelected }">
                    <div
                      class="d-flex align-center label-popup-block"
                      @click="onLabelListItemClick(item, $event, 'mode2Labels', isSelected)"
                    >
                      <div
                        class="color-preview mr-2"
                        :style="{ 'background-color': item.raw.color }"
                      />
                      <span v-text="item.raw.name" />
                    </div>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <div class="mb-7 d-flex align-center">
          <div class="vpn-circle d-flex align-center justify-center">
            <div
              class="vpn-circle vpn-circle--primary vpn-circle--smaller d-flex align-center justify-center"
            >
              <v-icon color="primary" size="80" icon="$hexagonVo" />
            </div>
          </div>
          <div class="ml-10">
            <span class="subtitle1 mb-2" v-text="$t('network.virtualOffice.mode3Description')" />
            <div
              class="body1 mt-4"
              :class="{ 'text-indigo-medium': actionNotAllowed }"
              v-text="$t('devicesSettings.settingsCard.applyToDevices')"
            ></div>
            <v-autocomplete
              v-model="localValue.mode3Labels"
              class="mt-4"
              :items="labels"
              :label="autocompleteLabel(localValue?.mode3Labels?.length)"
              :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
              :disabled="actionNotAllowed"
              variant="outlined"
              multiple
              return-object
              chips
              closable-chips
              clear-on-select
              item-value="id"
              item-title="name"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip
                  v-bind="props"
                  :closable="true"
                  variant="flat"
                  size="default"
                  :color="item.raw.color"
                  close-icon="$closeCircle"
                  :text="item.raw.name"
                  class="label-chip"
                >
                  <div class="d-flex align-center">
                    <span class="ml-1 mr-2 body2" v-text="item.title" />
                  </div>
                </v-chip>
              </template>
              <template v-slot:item="{ item, props }">
                <v-list-item v-bind="props" title="">
                  <template #default="{ isSelected }">
                    <div
                      class="d-flex align-center label-popup-block"
                      @click="onLabelListItemClick(item, $event, 'mode3Labels', isSelected)"
                    >
                      <div
                        class="color-preview mr-2"
                        :style="{ 'background-color': item.raw.color }"
                      />
                      <span v-text="item.raw.name" />
                    </div>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <div class="ml-4 mt-3 mr-4">
      <v-divider class="mt-8 mb-7" />
      <div v-if="mobileBannerConfiguration.visible" class="info-block mb-6 pa-4">
        <div class="d-flex justify-space-between align-start">
          <div class="d-flex align-start flex-grow-1">
            <v-icon icon="$warning" size="24" />
            <div class="d-flex flex-column flex-grow-1 ml-2">
              <div class="subtitle1" v-text="$t('network.virtualOffice.mobileBanner.title')" />
              <div
                class="w-60 body2"
                v-html="$t('network.virtualOffice.mobileBanner.description')"
              ></div>
              <router-link
                class="body2 font-weight-bold text-decoration-underline coronet-primary--text"
                to="/portal/settings/users/protected-users?openImportModal=true"
              >
                {{ $t("network.virtualOffice.mobileBanner.importList") }}
              </router-link>
            </div>
          </div>
          <v-icon size="24" role="button" @click="dismissBanner()" icon="$x" />
        </div>
      </div>
      <v-checkbox
        v-model="localValue.manualDisconnectionEnabled"
        :ripple="false"
        class="mt-1"
        :disabled="actionNotAllowed"
      >
        <template #label>
          <div
            class="subtitle1"
            :class="{ 'text-indigo-medium': actionNotAllowed }"
            v-text="$t('network.virtualOffice.manualDisconnectionEnabled')"
          />
        </template>
      </v-checkbox>
      <div
        class="body2 text--semitransparent ml-7"
        v-text="$t('network.virtualOffice.manualDisconnectionDescription')"
      />

      <div
        class="body1 mt-4 ml-7"
        :class="{
          'text-indigo-medium':
            actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled),
        }"
        v-text="$t('devicesSettings.settingsCard.applyToDevices')"
      />
      <v-autocomplete
        v-model="localValue.manualDisconnectionLabels"
        class="mt-4 ml-7"
        :items="labels"
        :label="autocompleteLabel(localValue.manualDisconnectionLabels?.length)"
        :placeholder="$t('network.virtualOffice.labelsAutocompletePlaceholder')"
        :disabled="
          actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled)
        "
        outlined
        variant="outlined"
        multiple
        return-object
        chips
        closable-chips
        clear-on-select
        item-value="id"
        item-text="name"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            :color="item.raw.color"
            close-icon="$closeCircle"
            :text="item.raw.name"
            class="label-chip"
            :disabled="
              actionNotAllowed || isParentSettingDisabled(localValue.manualDisconnectionEnabled)
            "
            :style="{ 'background-color': item.raw.color }"
          >
            <div class="d-flex align-center">
              <span class="ml-1 mr-2 body2" v-text="item.raw.name" />
            </div>
          </v-chip>
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item v-bind="props">
            <span class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
            <span v-text="item.raw.name" />
          </v-list-item>
        </template>
      </v-autocomplete>

      <v-divider class="mt-8 mb-7" />
      <div class="subtitle1 mb-4" v-text="$t('network.virtualOffice.encryptionStrength.title')" />

      <v-progress-linear
        v-if="encryptionLoading"
        indeterminate
        height="4px"
        class="progress-bar mb-1"
      />
      <v-radio-group
        v-model="localValue.dataCiphers"
        :disabled="encryptionLoading || actionNotAllowed"
      >
        <template v-for="encryption in EncryptionStrength" :key="encryption">
          <v-radio
            :value="encryption"
            class="mb-1"
            :label="$t(`network.virtualOffice.encryptionStrength.${encryption}`)"
            @click.native.prevent.stop.capture="onEncryptionStrengthChange(encryption)"
          >
            <template #label>
              <div
                class="subtitle1"
                :class="{
                  'text-indigo-medium': encryptionLoading,
                }"
                v-text="$t(`network.virtualOffice.encryptionStrength.${encryption}`)"
              ></div>
            </template>
          </v-radio>
          <div
            class="body2 text--semitransparent ml-8"
            v-text="$t(`network.virtualOffice.encryptionStrength.${encryption}Hint`)"
          />
        </template>
      </v-radio-group>
    </div>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, watch, type Ref } from "vue";
import { EncryptionStrength } from "@/constants/network";
import {
  useVirtualOfficeStore,
  type VirtualOfficeSettings,
} from "@/_store/network/virtual-office.module";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { ModalWidth } from "@/constants/modals";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";
import { VirtualOfficeAction } from "@/constants/network";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import SelectPredefinedVPNLabel from "@/components/modals/network/SelectPredefinedVPNLabel.vue";
import { storeToRefs } from "pinia";
import NewListModal from "@/components/modals/network/ListModal.vue";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";

export default defineComponent({
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  emits: ["settings-update"],

  setup(props, { emit }) {
    const virtualOfficeStore = useVirtualOfficeStore();
    const deviceSettingsStore = useDevicesSettingsStore();

    const { getMobileBannerConfiguration, updateMobileBannerConfiguration } = virtualOfficeStore;
    const { mobileBannerConfiguration, encryptionLoading } = storeToRefs(virtualOfficeStore);
    const { getLabels } = deviceSettingsStore;
    const { labels } = storeToRefs(deviceSettingsStore);
    const { openDialog, closeDialog } = useDialogsStore();

    const { t } = useI18n();

    onMounted((): void => {
      getLabels();
      getMobileBannerConfiguration();
    });

    const localValue: Ref<VirtualOfficeSettings> = ref({
      servers: [],
      dataCiphers: EncryptionStrength.AES128,
      manualDisconnectionEnabled: false,
      manualDisconnectionLabels: [],
      blockLocalNetworkEnabled: false,
      blockLocalNetworkLabels: [],
      mode3Labels: [],
      mode2Labels: [],
      region: "",
    });

    const ips = computed(() => localValue.value?.servers?.slice(0, 5).join(", ") || "");

    const hasManyServers = computed((): boolean => localValue?.value?.servers.length > 4);

    const actionNotAllowed = computed((): boolean => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.NETWORK
      );
    });

    const autocompleteLabel = (labelsLength: number) => {
      return labelsLength ? t("network.virtualOffice.labelsAutocompleteLabel") : "";
    };

    const onLabelListItemClick = (
      item: {
        raw: {
          id: string;
          name: string;
          color: string;
          deviceCount: number;
          predefined: boolean;
          inUseBySettings: boolean;
        };
      },
      event: Event,
      modelKey: "mode2Labels" | "mode3Labels",
      selected: boolean
    ) => {
      const showConfirmationModal = item.raw.predefined && !selected;
      if (showConfirmationModal) {
        event.preventDefault();
        event.stopImmediatePropagation();
        const config = {
          data: {
            action: VirtualOfficeAction.SELECT_PREDEFINED_VPN_LABEL,
            item: {
              raw: {
                id: item.raw.id,
                name: item.raw.name,
                color: item.raw.color,
                deviceCount: item.raw.deviceCount,
                predefined: item.raw.predefined,
                inUseBySettings: item.raw.inUseBySettings,
              },
            },
            callback: () => {
              localValue.value[modelKey] = [...localValue.value[modelKey], item.raw];
            },
          },
        };
        openModal(config);
      }
    };

    const onShowAllIpsClick = () => {
      const config = {
        data: {
          action: VirtualOfficeAction.VIEW_ALL_IPS,
          item: {
            servers: localValue.value.servers,
          },
          callback: () => {
            closeDialog();
          },
        },
      };

      openModal(config);
    };

    const onEncryptionStrengthChange = (dataCiphers: EncryptionStrength) => {
      const config = {
        data: {
          item: {},
          action: VirtualOfficeAction.TOGGLE_ENCRYPTION_STRENGTH,
          callback: () => {
            localValue.value = { ...localValue.value, dataCiphers };
          },
        },
      };
      openModal(config);
    };

    const dismissBanner = () => {
      updateMobileBannerConfiguration({ visible: false });
    };

    const isParentSettingDisabled = (parentSettingEnabled: boolean): boolean => {
      return !parentSettingEnabled;
    };

    const openModal = (config: Partial<DialogConfig>) => {
      let modalConfig: DialogConfig | undefined;
      const { action, callback, item } = config.data ?? {};

      if (action && callback && item) {
        switch (action) {
          case VirtualOfficeAction.SELECT_PREDEFINED_VPN_LABEL:
            modalConfig = {
              ...confirmationDialogsConfigConstructor({
                callback,
                action,
                width: ModalWidth.SMALL,
                text: t(`modals.${action}.description`),
                item: { name: item?.raw.name },
              }),
              header: {
                title: t(`modals.${action}.title`),
                titleClass: "text-red-dark",
                titleIcon: "warning",
              },
            };
            modalConfig.content.component = SelectPredefinedVPNLabel;
            break;
          case VirtualOfficeAction.TOGGLE_ENCRYPTION_STRENGTH:
            modalConfig = {
              ...confirmationDialogsConfigConstructor({
                item: {},
                callback,
                disable: actionNotAllowed.value,
                width: ModalWidth.SMALL,
                action,
              }),
              header: {
                title: t(`modals.${action}.title`),
                titleClass: "text-red-dark",
                titleIcon: "warning",
              },
            };
            break;

          case VirtualOfficeAction.VIEW_ALL_IPS:
            modalConfig = {
              ...componentDialogsConfigConstructor({
                width: ModalWidth.MEDIUM,
                component: NewListModal,
                action,
                item: {
                  items: localValue.value.servers,
                  description: t(`modals.${action}.description`, localValue.value?.servers?.length),
                },
                callback: () => {},
              }),
              scrollable: true,
              header: {
                title: t(`modals.${action}.title`),
              },
              footer: {
                buttons: [
                  {
                    title: t(`modals.${action}.actionBtn`),
                    color: "primary",
                    spacer: "before",
                    callback: () => {
                      closeDialog();
                    },
                  },
                ],
              },
            };
            break;
          default:
            modalConfig = undefined;
        }
      }
      if (modalConfig) {
        openDialog(modalConfig);
      }
    };

    watch(
      props.settings,
      (newValue) => {
        localValue.value = newValue as VirtualOfficeSettings;
      },
      { deep: true, immediate: true }
    );

    watch(
      localValue,
      () => {
        emit("settings-update", localValue.value);
      },
      { deep: true }
    );

    return {
      localValue,
      ips,
      hasManyServers,
      actionNotAllowed,
      labels,
      autocompleteLabel,
      openDialog,
      VirtualOfficeAction,
      onLabelListItemClick,
      mobileBannerConfiguration,
      dismissBanner,
      encryptionLoading,
      isParentSettingDisabled,
      EncryptionStrength,
      onEncryptionStrengthChange,
      onShowAllIpsClick,
    };
  },
});
</script>

<style scoped lang="scss">
.vpn-circle {
  min-width: 200px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgb(var(--v-theme-indigo-light));

  &--primary {
    background-color: rgb(var(--v-theme-primary)) !important;
  }

  &--smaller {
    min-width: 145px;
    width: 145px;
    height: 145px;
  }

  &--smallest {
    min-width: 100px;
    width: 100px;
    height: 100px;
  }

  &--12px {
    min-width: 12px;
    width: 12px;
    height: 12px;
  }
}

.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}

i.icon.text-primary:before {
  color: rgb(var(--v-theme-gray-faint)) !important;
}

.label-popup-block span {
  flex: unset !important;
}

:deep(*) {
  .v-input--checkbox .v-input__slot {
    margin-bottom: 0 !important;
  }

  .label-chip {
    .icon-rules-close:before {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>
