<template>
  <div class="coro-features__container">
    <div class="coro-features__item">
      <div class="coro-features__item-content">
        <v-icon class="mr-8 ml-8 coro-features__icon" icon="$cloudApps" />
        <div class="body1 mt-1">
          {{ $t("invitedPage.cloudAppsSecurity") }}
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="coro-features__item">
      <div class="coro-features__item-content">
        <v-icon class="mr-8 ml-8 coro-features__icon" icon="$users" />
        <div class="body1 mt-1">
          {{ $t("invitedPage.usersSecurity") }}
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="coro-features__item">
      <div class="coro-features__item-content">
        <v-icon class="mr-8 ml-8 coro-features__icon" icon="$emails" />
        <div class="body1 mt-1">
          {{ $t("invitedPage.emailSecurity") }}
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="coro-features__item">
      <div class="coro-features__item-content">
        <v-icon class="mr-8 ml-8 coro-features__icon" icon="$devices" />
        <div class="body1 mt-1">
          {{ $t("invitedPage.deviceSecurity") }}
        </div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="coro-features__item">
      <div class="coro-features__item-content">
        <v-icon class="mr-8 ml-8 coro-features__icon" icon="$data" />
        <div class="body1 mt-1">
          {{ $t("invitedPage.dataSecurity") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.coro-features {
  &__icon {
    font-size: 80px;
  }
  &__alert {
    max-width: 59%;
    min-width: 320px;
  }
  &__container {
    display: flex;
    justify-content: center;
    margin: auto;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    &-content {
      text-align: center;
      margin-right: 20px;
      margin-left: 20px;
      display: flex;
      width: 95px;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
    }
  }
}

.divider {
  border-bottom: 2px solid rgb(var(--v-theme-primary));
  width: 71px;
  margin-bottom: 30px;
}

/*
On small screens hiding divider and making feature items align into columns
*/
@media only screen and (max-width: 800px) {
  .divider {
    width: 0;
  }
  .coro-features__item {
    flex-basis: 50%;
    padding: 15px;
  }
  .coro-features__item:nth-child(odd) {
    justify-content: flex-end;
  }

  .coro-features__item:nth-child(even) {
    justify-content: flex-start;
  }
}
</style>
