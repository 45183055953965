<template>
  <div>
    <div>
      {{ $t(`modals.${config.action}.description`) }}
    </div>
    <div class="link-container body2 mt-3">
      {{ config.item.inviteLink }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.link-container {
  border: 1px rgb(var(--v-theme-indigo-faint)) solid;
  border-radius: 6px;
  padding: 12px 20px 12px 16px;
}
</style>
