<template>
  <div v-if="keyDetails.length">
    <div class="headline6 mb-4">{{ $t("ticketDetails.keyDetails.title") }}</div>
    <template v-for="(detail, index) in keyDetails">
      <div v-if="detail.value" :key="index">
        <template v-if="detail.ticketModal === ticketsModals.DLP_FINDINGS_MODAL">
          <div
            role="button"
            class="coro-link"
            :data-testid="`device-preview-key-details-${kebabCase(detail.titleKey)}-findings`"
            @click="openDlpFindingsModal()"
          >
            {{ $t("ticketDetails.showFindings") }}
          </div>
        </template>

        <template v-else>
          <div
            class="subtitle1 mb-1"
            :data-testid="`device-preview-key-details-${kebabCase(detail.titleKey)}-title`"
            v-html="detail.titleKey"
          ></div>

          <div v-if="detail.ticketModal" class="mb-4">
            <span
              class="body1 mr-1"
              :data-testid="`device-preview-key-details-${kebabCase(detail.titleKey)}-modal-value`"
              >{{ detail.value[0] }}</span
            >
            <span
              v-if="detail.value.length > 1"
              role="button"
              class="plus-one-text"
              :data-testid="`device-preview-key-details-${kebabCase(
                detail.titleKey
              )}-open-modal-btn`"
              @click="openListModal(detail.ticketModal!, detail.value as string[])"
              >&nbsp;+{{ detail.value.length - 1 }}
            </span>
          </div>

          <div v-else class="body1 mb-4">
            <div v-if="detail.loopWithIcon">
              <div
                v-for="item in detail.value"
                :key="item[item.key]"
                class="d-flex justify-start align-baseline"
              >
                <v-icon size="16" class="mr-1">{{ item.icon }}</v-icon>
                <span
                  class="mb-2 word-break-all"
                  :data-testid="`device-preview-key-details-${kebabCase(detail.titleKey)}-${
                    item.key
                  }`"
                  v-html="item[item.key]"
                ></span>
              </div>
            </div>
            <div
              v-else
              :data-testid="`device-preview-key-details-${kebabCase(detail.titleKey)}-value`"
              v-html="detail.value"
            ></div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { mapKeyDetails } from "@/_helpers/ticket-key-details";
import { computed, defineComponent, type PropType } from "vue";
import type { Finding, TicketDetails } from "@/_store/tickets/tickets.module";
import { TicketsModal } from "@/constants/tickets";
import { useTicketPreviewGeneralInfo } from "@/composables/useTicketPreviewGeneralInfo";
import { useI18n } from "vue-i18n";
import kebabCase from "lodash/kebabCase";

export default defineComponent({
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const { openListModal } = useTicketPreviewGeneralInfo(props.ticket);
    const i18n = useI18n();
    const keyDetails = computed(() => mapKeyDetails(props.ticket));

    const openDlpFindingsModal = () => {
      openListModal(
        TicketsModal.DLP_FINDINGS_MODAL,
        props.ticket.sections.findings.map((finding) => {
          return {
            template: createFindingsModalTemplate(finding),
          };
        })
      );
    };

    function createFindingsModalTemplate(finding: Finding) {
      let template = i18n.t(`modals.${TicketsModal.DLP_FINDINGS_MODAL}.type`, {
        type: i18n.t(`ticketDetails.incidentFindings.type.${finding.type}`),
      });
      if (finding.longQuote) {
        // if quote present, highlight it, otherwise show longQuote as it is
        if (finding.quote && finding.longQuote.includes(finding.quote)) {
          const highlightedTextStartIndex = finding.longQuote.indexOf(finding.quote);
          const highlightedTextEndIndex =
            finding.longQuote.indexOf(finding.quote) + finding.quote.length;
          const textBeforeHighlight = finding.longQuote.substring(0, highlightedTextStartIndex);
          const textAfterHighlight = finding.longQuote.substring(
            highlightedTextEndIndex,
            finding.longQuote.length
          );
          const highlightedText = finding.quote;
          template =
            template +
            i18n.t(`modals.${TicketsModal.DLP_FINDINGS_MODAL}.longQuote`, {
              textBeforeHighlight,
              textAfterHighlight,
              highlightedText,
            });
        } else {
          template =
            template +
            i18n.t("modals.dlpFindings.longQuoteWithoutHighlight", {
              longQuote: finding.longQuote,
            });
        }
      }
      if (!finding.longQuote && finding.quote) {
        template =
          template +
          i18n.t(`modals.${TicketsModal.DLP_FINDINGS_MODAL}.quote`, {
            quote: finding.quote,
          });
      }
      return template;
    }

    return {
      kebabCase,
      ticketsModals: TicketsModal,
      keyDetails,
      openDlpFindingsModal,
      openListModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  width: 40px;
  height: 40px;
}
</style>
