<template>
  <v-form ref="form" v-model="valid" class="pb-16" lazy-validation>
    <div class="coro-body1 mb-4">{{ $t("modals.testDomain.description") }}</div>
    <v-text-field
      v-model="localValue.testEmail"
      :label="localValue.testEmail ? $t('modals.testDomain.mailTo.title') : ''"
      variant="outlined"
      :rules="emailRules"
      :placeholder="$t('modals.testDomain.mailTo.placeholder')"
      @keydown.enter.prevent="sendEmail()"
    />
    <v-btn
      v-if="!testSuccess"
      color="primary"
      rounded
      :disabled="!valid || config.disable"
      @click="sendEmail()"
    >
      {{ $t("modals.testDomain.actionButton") }}
    </v-btn>
    <div
      :class="{
        hidden: !showTestStatusMessage,
        'text-green-dark': !testFailed,
        'text-red-dark': testFailed,
      }"
      class="coro-body1 mt-5 d-flex align-start"
    >
      <v-icon v-if="testFailed" icon="$warning"></v-icon>
      <span v-html="testStatusMessage" />
    </div>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Patterns from "@/constants/patterns";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import { useEmailProxyStore } from "@/_store/email-security/email-proxy.module";
import type { VuetifyFormRef } from "@/types";

export default defineComponent({
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const i18n = useI18n();
    const valid = ref(true);
    const localValue = ref({
      ...cloneDeep(props.config.item),
      testEmail: "",
    });
    const form = ref<VuetifyFormRef>();
    const emailProxyStore = useEmailProxyStore();

    const showTestStatusMessage = ref(false);
    const testStatusMessage = ref("");
    const testFailed = ref(false);
    const testSuccess = ref(false);

    const sendEmail = async () => {
      try {
        await emailProxyStore.testDomain(localValue.value);
        testStatusMessage.value = i18n.t("modals.testDomain.emailSentMessage", {
          email: localValue.value.testEmail,
        });
        testSuccess.value = true;
      } catch (error) {
        testStatusMessage.value = i18n.t("modals.testDomain.proxyFailedMessage");
        testFailed.value = true;
      }
      showTestStatusMessage.value = true;
    };

    return {
      localValue,
      valid,
      form,
      showTestStatusMessage,
      testStatusMessage,
      testFailed,
      testSuccess,
      sendEmail,
      emailRules: [
        (email: string) => {
          if (!email) {
            return i18n.t("validations.required");
          }
          if (!Patterns.EMAIL.test(email)) {
            return i18n.t("validations.email");
          }

          if (!email.endsWith(localValue.value.name)) {
            return i18n.t("validations.emailIsNotInTheListOfProxyDomains");
          }

          return true;
        },
      ],
    };
  },
});
</script>
