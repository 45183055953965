<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="d-flex justify-space-between align-center mb-5 mt-4">
      <div class="headline5 d-flex align-center text-primary">
        <span>{{ $t("cloudSecurity.title") }}</span>
      </div>
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="mb-4"> </page-tabs>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import type { CoroTab } from "@/components/PageTabs.vue";
import PageTabs from "@/components/PageTabs.vue";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const i18n = useI18n();

    const tabs: CoroTab[] = [
      {
        id: 0,
        name: i18n.t("cloudSecurity.tabs.accessPermission"),
        route: { name: RouteName.CLOUD_SECURITY_PERMISSIONS_LIST_TAB },
      },
      {
        id: 1,
        name: i18n.t("cloudSecurity.tabs.thirdPartyApps"),
        route: { name: RouteName.CLOUD_SECURITY_THIRD_PARTY_APPS_TAB },
      },
      {
        id: 2,
        name: i18n.t("cloudSecurity.tabs.settings"),
        route: { name: RouteName.CLOUD_SECURITY_SETTINGS },
      },
    ];

    return {
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      coronetSkeletonLoaderTypes,
      tabs,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  tr {
    height: 54px;
  }
}

.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}
</style>
