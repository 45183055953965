import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Paged, Pagination } from "@/types";
import {
  addWorkspaceHeaderIfNeeded,
  convertSelectionForSocBulkAction,
} from "@/_store/tickets/adapters";
import type { CommentAction } from "@/constants/tickets";
import { useTicketsStore } from "@/_store/tickets/tickets.module";
import { i18n } from "@/plugins/i18n";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { useSelectorStore } from "@/_store/selector.module";
import { useTicketStore } from "@/_store/tickets/ticket.module";

export interface TicketCommentsState {
  comments: Comment[];
  loading: boolean;
  totalComments: number;
  pagination: Pagination;
}

export interface CommentBase {
  message: string;
  notifyAffectedUsers: boolean;
  notifyAllWorkspaceAdmins: boolean;
  includeTicketDetails: boolean;
  notifyCustomRecipients: boolean;
  customRecipients: string[];
  socRead: boolean | undefined;
  confidential?: boolean;
}

export interface CommentSettings extends CommentBase {
  notifySocContacts: boolean;
  notifyCoroAdmins: boolean;
  socModuleEnabled?: boolean;
  isDeviceTicket?: boolean;
}

export const defaultCommentsSettings: CommentSettings = {
  notifyAffectedUsers: false,
  notifyAllWorkspaceAdmins: false,
  includeTicketDetails: true,
  notifyCustomRecipients: false,
  notifySocContacts: false,
  customRecipients: [],
  notifyCoroAdmins: false,
  message: "",
  socRead: undefined,
};

export interface Comment extends CommentBase {
  id: string;
  createdBy: string;
  createdAt: Date;
}

const defaultCommentsState = {
  comments: [],
  loading: false,
  totalComments: 0,
  pagination: {
    page: 0,
    pageSize: 5,
  },
};

export const useTicketCommentsStore = defineStore("ticketComments", {
  state: (): TicketCommentsState => ({ ...defaultCommentsState }),
  actions: {
    async getComments({ eventId, workspaceId }: { eventId: string; workspaceId?: string }) {
      this.loading = true;
      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.getComments({ eventId, ...this.pagination }),
        },
        workspaceId
      );
      try {
        const comments = await axiosInstance.request(request);
        this.comments = comments.data.items;
        this.totalComments = comments.data.total;
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    },
    async getNextPage({ eventId, workspaceId }: { eventId: string; workspaceId?: string }) {
      this.pagination.page = this.pagination.page + 1;
      this.loading = true;
      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.getComments({ eventId, ...this.pagination }),
        },
        workspaceId
      );
      try {
        const { data } = await axiosInstance.request<Paged<Comment>>(request);
        this.comments = [...this.comments, ...data.items];
        this.totalComments = data.total;
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async addComment(
      payload: CommentSettings & {
        eventId: string;
        workspaceId?: string;
      }
    ) {
      this.loading = true;
      const {
        message,
        includeTicketDetails,
        notifyAffectedUsers,
        notifyAllWorkspaceAdmins,
        notifyCustomRecipients,
        customRecipients,
        eventId,
        notifySocContacts,
        workspaceId,
        notifyCoroAdmins,
        confidential,
      } = payload;

      const request = addWorkspaceHeaderIfNeeded(
        {
          ...api.addComment(eventId),
          data: {
            message,
            includeTicketDetails,
            notifyAffectedUsers,
            notifyAllWorkspaceAdmins,
            notifyCustomRecipients,
            customRecipients,
            notifySocContacts,
            notifyCoroAdmins,
            confidential,
          },
        },
        workspaceId
      );
      try {
        await axiosInstance.request(request);
        this.pagination.page = 0;
        await this.getComments({ eventId });
      } catch (e) {
        console.error(e);
      }
    },
    async bulkAddComment(
      payload: CommentSettings & {
        eventId: string;
        workspaceId?: string;
      }
    ) {
      const {
        message,
        includeTicketDetails,
        notifyAffectedUsers,
        notifyAllWorkspaceAdmins,
        notifyCustomRecipients,
        customRecipients,
        notifySocContacts,
        notifyCoroAdmins,
        confidential,
      } = payload;
      const data = {
        commentRequest: {
          message,
          includeTicketDetails,
          notifyAffectedUsers,
          notifyAllWorkspaceAdmins,
          notifyCustomRecipients,
          customRecipients,
          notifySocContacts,
          notifyCoroAdmins,
          confidential,
        },
        socTicketDtos: convertSelectionForSocBulkAction(useSelectorStore().selection),
      };
      const request = {
        ...api.addSocBulkComment,
        data,
      };
      await axiosInstance.request(request);
      this.pagination = {
        ...this.pagination,
        page: 0,
      };
      await useTicketStore().getTicket();
      useSelectorStore().deselectAllPages();
      useSnackbarStore().addGenericSuccess(
        i18n.global.t("snackbar.messages.event.newCommentIsAdded")
      );
    },

    async commentAction({
      commentId,
      action,
      eventId,
      workspaceId,
    }: {
      commentId: string;
      action: CommentAction;
      eventId: string;
      workspaceId?: string;
    }) {
      this.loading = true;

      const request = {
        ...api.socCommentAction(commentId),
        data: {
          action,
        },
      };
      await axiosInstance.request(request);
      await this.getComments({ eventId, workspaceId });
      await useTicketsStore().getTickets();
      this.loading = false;
      useSnackbarStore().addGenericSuccess(i18n.global.t("snackbar.messages.event.commentUpdated"));
    },
    async searchAdminEmails(email: string = ""): Promise<string[]> {
      const request = {
        ...api.coroAdminEmails,
        params: { email },
      };
      try {
        const { data } = await axiosInstance.request(request);
        return data;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
  },
});
