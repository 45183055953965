<template>
  <div>
    <div class="subtitle1 mb-4">
      {{ $t("modals.addDevicePosturePolicy.action") }}
    </div>
    <v-radio-group v-model="config.postureValue" class="mb-8">
      <v-radio
        v-for="action in postureActions"
        :key="action"
        :label="$t(`modals.addDevicePosturePolicy.postureActions.${action}`)"
        :value="action"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script lang="ts">
import { DevicePostureChoice, DevicePostureOsType, DeviceVulnerability } from "@/constants/devices";
import { computed, defineComponent, ref, watch } from "vue";
import type { PropType } from "vue";
import type { DevicePostureFormData } from "@/_store/device-posture/devices-posture-settings.module";

const macOsPoliciesMap: Partial<Record<DeviceVulnerability, DevicePostureChoice[]>> = {
  [DeviceVulnerability.DEVICE_PASSWORD_MISSING]: [DevicePostureChoice.REVIEW],
  [DeviceVulnerability.FIREWALL_DISABLED]: [
    DevicePostureChoice.ENFORCE,
    DevicePostureChoice.REVIEW,
  ],
  [DeviceVulnerability.ENCRYPTION_DISABLED]: [DevicePostureChoice.REVIEW],
  [DeviceVulnerability.GATEKEEPER_UNAVAILABLE]: [
    DevicePostureChoice.ENFORCE,
    DevicePostureChoice.REVIEW,
  ],
  [DeviceVulnerability.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE]: [
    DevicePostureChoice.ENFORCE,
    DevicePostureChoice.REVIEW,
  ],
  [DeviceVulnerability.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE]: [DevicePostureChoice.REVIEW],
};

const windowsPoliciesMap: Partial<Record<DeviceVulnerability, DevicePostureChoice[]>> = {
  [DeviceVulnerability.UAC_NOTIFICATIONS_MISSING]: [
    DevicePostureChoice.ENFORCE,
    DevicePostureChoice.REVIEW,
  ],
  [DeviceVulnerability.DEVELOPMENT_MODE_ENABLED]: [
    DevicePostureChoice.ENFORCE,
    DevicePostureChoice.REVIEW,
  ],
  [DeviceVulnerability.DEVICE_PASSWORD_MISSING]: [DevicePostureChoice.REVIEW],
  [DeviceVulnerability.FIREWALL_DISABLED]: [
    DevicePostureChoice.ENFORCE,
    DevicePostureChoice.REVIEW,
  ],
  [DeviceVulnerability.ENCRYPTION_DISABLED]: [DevicePostureChoice.REVIEW],
  [DeviceVulnerability.NON_GENUINE_WINDOWS]: [DevicePostureChoice.REVIEW],
};

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DevicePostureFormData>,
      required: true,
    },
    payload: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:valid", "update:value"],
  setup(props, { emit }) {
    const localValue = ref({});
    const form = ref({});
    const valid = ref(true);

    watch(valid, (value) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    watch(
      localValue,
      (value) => {
        emit("update:value", value);
      },
      { deep: true }
    );

    const postureActions = computed(() => {
      if (props.config.osType === DevicePostureOsType.MAC_OS) {
        return macOsPoliciesMap[props.config.policyType as DeviceVulnerability];
      }

      return windowsPoliciesMap[props.config.policyType as DeviceVulnerability];
    });

    watch(
      () => props.config.policyType,
      (newVal, oldVal) => {
        if (newVal !== oldVal && postureActions.value) {
          props.config.postureValue = postureActions.value[0];
        }
      },
      { immediate: true }
    );

    return {
      localValue,
      form,
      valid,
      postureActions,
    };
  },
});
</script>
<style lang="scss" scoped>
.color-preview {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 4px !important;
}

:deep(*) {
  .v-input__slot {
    padding-right: 8px !important;
  }

  .v-input__append-inner {
    margin-top: 0 !important;
    padding-left: 8px !important;
    height: 56px;
    display: flex;
    align-items: center;
  }

  .label-chip {
    .icon-rules-close:before {
      color: var(--v-primary-base) !important;
    }
  }
}
</style>
