<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("reports.title") }}
      </div>
      <v-skeleton-loader v-if="showSkeletonLoader">
        <v-row>
          <v-skeleton-loader color="transparent" width="100" type="button" />
        </v-row>
      </v-skeleton-loader>
      <div class="d-flex align-center" v-else-if="showButtons">
        <span
          v-if="isDnsSummaryTab"
          role="button"
          class="d-flex align-center mr-6"
          @click="getDnsReport()"
        >
          <v-icon size="18" icon="$sync" class="refresh-icon mr-1"></v-icon>
          <span class="coro-link body2">{{ $t("general.refresh") }}</span>
        </span>
        <executive-summary-report-download-menu
          v-if="showHeaderButton(RouteName.REPORTS_PAGE)"
          :report-type="reportType"
          :before-download-hook="onDownloadStart"
          :after-download-hook="onDownloadEnd"
        />
      </div>

      <new-export-menu v-if="showHeaderButton(RouteName.REPORTS_EXPORTS_TAB)" />
      <schedule-report-menu v-if="showHeaderButton(RouteName.REPORTS_SCHEDULED_TAB)" />
    </div>
    <page-tabs :tabs="tabs" :with-translation="false" class="nav-tabs mb-5" />
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageTabs, { type CoroTab } from "@/components/PageTabs.vue";
import { useRoute } from "vue-router";
import ExecutiveSummaryReportDownloadMenu from "@/components/reports/ExecutiveSummaryReportDownloadMenu.vue";
import NewExportMenu from "@/components/reports/NewExportMenu.vue";
import ScheduleReportMenu from "@/components/reports/ScheduleReportMenu.vue";
import { ReportType, useReportsStore } from "@/_store/reports.module";
import { storeToRefs } from "pinia";
import { useSWGStore } from "@/_store/secure-web-gateway.module";
import { Chart } from "chart.js";

import { RouteName } from "@/constants/routes";

const routeToReportTypeMap: Record<string, ReportType> = {
  [RouteName.REPORTS_EXECUTIVE_SUMMARY_TAB]: ReportType.EXECUTIVE_SUMMARY,
  [RouteName.REPORTS_MANAGED_SERVICES_SUMMARY_TAB]: ReportType.MANAGED_SERVICES_SUMMARY,
  [RouteName.REPORTS_DNS_SUMMARY_TAB]: ReportType.DNS_SUMMARY,
  [RouteName.REPORTS_SECURED_MESSAGES_SUMMARY_TAB]: ReportType.SECURED_MESSAGES,
};

export default defineComponent({
  components: {
    ScheduleReportMenu,
    NewExportMenu,
    ExecutiveSummaryReportDownloadMenu,
    PageTabs,
  },
  setup() {
    const i18n = useI18n();
    const route = useRoute();
    const swgStore = useSWGStore();
    const reportsStore = useReportsStore();
    const { getDnsReport } = reportsStore;
    const { showSkeletonLoader, settings } = storeToRefs(swgStore);
    const tabs: CoroTab[] = [
      {
        id: 1,
        name: i18n.t("reports.tabs.reports"),
        route: { name: RouteName.REPORTS_SELECT_REPORTS },
      },
      {
        id: 2,
        name: i18n.t("reports.tabs.exports"),
        route: { name: RouteName.REPORTS_EXPORTS_TAB },
      },
      {
        id: 3,
        name: i18n.t("reports.tabs.scheduled"),
        route: { name: RouteName.REPORTS_SCHEDULED_TAB },
      },
    ];

    const reportSubRoutes = [
      RouteName.REPORTS_EXECUTIVE_SUMMARY_TAB,
      RouteName.REPORTS_DNS_SUMMARY_TAB,
      RouteName.REPORTS_MANAGED_SERVICES_SUMMARY_TAB,
      RouteName.REPORTS_SECURED_MESSAGES_SUMMARY_TAB,
    ];

    const showHeaderButton = (
      selectedRoute:
        | RouteName.REPORTS_PAGE
        | RouteName.REPORTS_EXPORTS_TAB
        | RouteName.REPORTS_SCHEDULED_TAB
    ) => {
      return selectedRoute === RouteName.REPORTS_PAGE
        ? reportSubRoutes.includes(route.name as RouteName)
        : route.name === selectedRoute;
    };

    const reportType = computed(() => {
      return routeToReportTypeMap[route.name as string];
    });

    const isDnsSummaryTab = computed(() => {
      return route.name === RouteName.REPORTS_DNS_SUMMARY_TAB;
    });

    const showButtons = computed(() => {
      return isDnsSummaryTab.value ? settings.value.dnsFilteringEnabled : true;
    });

    const breadCrumbs = computed(() => {
      return reportSubRoutes.includes(route.name as RouteName)
        ? [
            {
              title: i18n.t("general.genericBackTo"),
              disabled: false,
              to: { path: "/portal/settings/reports/select" },
            },
          ]
        : [
            {
              title: i18n.t("general.backToControlPanel"),
              disabled: false,
              to: { path: "/portal/settings" },
            },
          ];
    });

    const onDownloadStart = async () => {
      Array.from(document.querySelectorAll(".item-expander .item-collapsed")).forEach(
        (expander) => {
          (expander.parentNode as HTMLElement)?.classList.add("auto-collapsable");
          (expander as HTMLElement).click();
        }
      );

      for (let id in Chart.instances) {
        const parentNode = Chart.instances[id].canvas.parentNode as HTMLDivElement;
        Chart.instances[id].resize(
          parentNode.offsetWidth * 0.8,
          parentNode.offsetHeight - 51 - 21 - 32
        );
      }

      await new Promise((res) => {
        setTimeout(() => {
          res(null);
        }, 200);
      });
    };

    const onDownloadEnd = () => {
      Array.from(document.querySelectorAll(".item-expander.auto-collapsable")).forEach(
        (expander) => {
          expander.classList.remove("auto-collapsable");
          (expander as HTMLElement).click();
        }
      );

      for (let id in Chart.instances) {
        Chart.instances[id].resize();
      }
    };

    return {
      tabs,
      showHeaderButton,
      breadCrumbsItems: breadCrumbs,
      reportType,
      isDnsSummaryTab,
      getDnsReport,
      showSkeletonLoader,
      showButtons,
      onDownloadStart,
      onDownloadEnd,
      RouteName,
    };
  },
});
</script>

<style scoped lang="scss">
.refresh-icon {
  &:before {
    color: rgb(var(--v-theme-link));
  }
}
:deep(*) {
  .v-skeleton-loader__button {
    max-width: 100px;
    margin: 0;
  }
}
</style>
