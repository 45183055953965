<template>
  <div class="d-flex">
    <v-tooltip v-if="potentiallyProtectableUsers.length" open-delay="300" top>
      <template #activator="{ on }">
        <v-icon
          :data-testid="`tickets-table-unprotected-user-icon-${index}`"
          class="mr-1"
          v-on="on"
          icon="$noProtection"
        >
        </v-icon>
      </template>
      {{ $t("ticketDetails.notProtectedUsersNotice", { n: potentiallyProtectableUsers.length }) }}
    </v-tooltip>
    <v-tooltip v-if="processed" open-delay="300" location="top">
      <template #activator="{ props }">
        <v-icon v-bind="props" icon="$circleCheck"></v-icon>
      </template>
      {{ $t("general.processed") }}
    </v-tooltip>
    <slot name="soc-comments-label" />
  </div>
</template>
<script>
export default {
  name: "TicketListStatusIcons",
  props: {
    index: {
      type: Number,
      default: null,
    },
    processed: {
      type: Boolean,
      default: false,
    },
    potentiallyProtectableUsers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
