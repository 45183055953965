<template>
  <div class="content-md margin-auto">
    <div class="headline5 mt-6 text-primary">
      {{ $t("settings.title") }}
    </div>
    <div class="mt-10 text-primary subtitle1">{{ $t("settings.protection") }}</div>
    <div v-if="getProtectionSettings(false).length" class="d-inline-flex flex-wrap mt-2 mb-2">
      <div
        v-for="protectionSetting in getProtectionSettings(false)"
        :key="protectionSetting.name"
        class="mr-12"
      >
        <v-btn
          :data-testid="`setting-${kebabCase(protectionSetting.name)}-btn`"
          variant="plain"
          class="settings-btn"
          @click="handleProtectionSettingClick(protectionSetting)"
        >
          <div class="d-flex justify-center align-center flex-column">
            <div class="mb-2">
              <v-icon size="80" :icon="`$${protectionSetting.icon}`" color="primary"> </v-icon>
            </div>
            <div class="body2">{{ protectionSetting.name }}</div>
          </div>
        </v-btn>
      </div>
    </div>
    <div
      v-if="account.showDisabledModules && getProtectionSettings(true).length"
      class="d-flex flex-wrap mt-2 mb-2"
    >
      <div
        v-for="protectionSetting in getProtectionSettings(true)"
        :key="protectionSetting.name"
        class="mr-12"
      >
        <v-btn
          :data-testid="`setting-${kebabCase(protectionSetting.name)}-btn`"
          variant="plain"
          :to="{ name: protectionSetting.route }"
          class="settings-btn settings-btn--disabled"
        >
          <div class="d-flex justify-center align-center flex-column settings-button__content">
            <div class="mb-2">
              <v-icon size="80" :icon="`$${protectionSetting.icon}`"> </v-icon>
            </div>
            <div class="body2">{{ protectionSetting.name }}</div>
          </div>
        </v-btn>
      </div>
    </div>
    <v-divider class="white"></v-divider>
    <div class="mt-6 text-primary subtitle1">{{ $t("settings.workspace") }}</div>
    <div class="d-inline-flex flex-wrap mt-2 mb-2">
      <div
        v-for="workspaceSetting in workspaceAssetsSettings"
        :key="workspaceSetting.name"
        class="mr-12"
      >
        <v-btn
          :data-testid="`setting-${kebabCase(workspaceSetting.name)}-btn`"
          variant="plain"
          :to="{ name: workspaceSetting.route }"
          class="settings-btn"
        >
          <div class="d-flex justify-center align-center flex-column settings-button__content">
            <div class="mb-2">
              <v-icon size="80" :icon="`$${workspaceSetting.icon}`" color="primary"> </v-icon>
            </div>
            <div class="body2">{{ workspaceSetting.name }}</div>
          </div>
        </v-btn>
      </div>
    </div>
    <v-divider class="white"></v-divider>
    <div class="d-inline-flex flex-wrap mt-2 mb-2">
      <div
        v-for="workspaceSetting in workspaceAdminSettings"
        :key="workspaceSetting.name"
        class="mr-12"
      >
        <v-btn
          :data-testid="`setting-${kebabCase(workspaceSetting.name)}-btn`"
          variant="plain"
          :to="{ name: workspaceSetting.route }"
          class="settings-btn"
        >
          <div class="d-flex justify-center align-center flex-column settings-button__content">
            <div class="mb-2">
              <v-icon size="80" :icon="`$${workspaceSetting.icon}`" color="primary"> </v-icon>
            </div>
            <div class="body2">{{ workspaceSetting.name }}</div>
          </div>
        </v-btn>
      </div>
    </div>
    <template v-if="showDemoMode">
      <v-divider class="white"></v-divider>
      <div class="w-100 d-flex justify-end align-center mt-13 mb-13">
        <span
          v-if="demoModeEnabledValue"
          role="button"
          class="coro-link mr-4"
          @click="resetDemoModeData()"
          >{{ $t("settings.demoMode.resetBtn") }}</span
        >
        <v-switch
          v-model="demoModeEnabledValue"
          color="primary"
          inset
          :ripple="false"
          density="compact"
          hide-details
        >
          <template #label>
            <span class="subtitle2">{{ $t("settings.demoMode.title") }}</span>
          </template>
        </v-switch>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import kebabCase from "lodash/kebabCase";
import { isAccessRestricted, isModuleDisabled, socAddonsEnabled } from "@/_helpers/utils";
import { SubscriptionModule } from "@/constants/workplaces";
import { computed, defineComponent, onMounted } from "vue";
import { useAccountStore } from "@/_store";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/_store/settings.module";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  ProtectionScopeModules,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import { useI18n } from "vue-i18n";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";
import { CoroIcons } from "@/constants/coro-icon";

export default defineComponent({
  setup: function () {
    const i18n = useI18n();
    const router = useRouter();
    const accountStore = useAccountStore();
    const { account } = storeToRefs(accountStore);
    const settingsStore = useSettingsStore();
    const { demoModeEnabled } = storeToRefs(settingsStore);

    const demoModeEnabledValue = computed<boolean>({
      get() {
        return demoModeEnabled.value;
      },
      set(value) {
        settingsStore.changeDemoModeState(value);
      },
    });

    const showDemoMode = computed(() => {
      return !isGlobalActionRestricted(
        GlobalRoleScopes.GLOBAL_SCOPE,
        GlobalRoleScopeSection.SPECIAL_PERMISSIONS,
        "demoMode"
      );
    });

    const getProtectionSettings = (disabled: boolean) => {
      const settings = [
        {
          name: i18n.t("settings.cloudSecurity"),
          icon: CoroIcons.CLOUD_SECURITY,
          route: RouteName.CLOUD_SECURITY_PAGE,
          disabled: isModuleDisabled(SubscriptionModule.CLOUD_SECURITY),
          hide: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.CLOUD_SECURITY
          ),
        },
        {
          name: i18n.t("settings.endpointSecurity"),
          icon: CoroIcons.ENDPOINT_SECURITY,
          route: RouteName.ENDPOINT_SECURITY_SETTINGS_PAGE,
          disabled: isModuleDisabled(SubscriptionModule.ENDPOINT_SECURITY),
          hide: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.ENDPOINT_SECURITY
          ),
        },
        {
          name: i18n.t("settings.email"),
          icon: CoroIcons.EMAILS,
          route: RouteName.EMAIL_SECURITY_PAGE,
          disabled: isModuleDisabled(SubscriptionModule.EMAIL_SECURITY),
          hide: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.EMAIL_SECURITY
          ),
        },
        {
          name: i18n.t("settings.userDataGovernance"),
          icon: CoroIcons.USER_DATA_GOVERNANCE,
          route: RouteName.USER_DATA_GOVERNANCE_PAGE,
          disabled: isModuleDisabled(SubscriptionModule.USER_DATA_GOVERNANCE),
          hide: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.USER_DATA_GOVERNANCE
          ),
        },
        {
          name: i18n.t("settings.endpointDataGovernance"),
          icon: CoroIcons.ENDPOINT_DATA_GOVERNANCE,
          route: RouteName.ENDPOINT_DATA_GOVERNANCE_PAGE,
          disabled: isModuleDisabled(SubscriptionModule.ENDPOINT_DATA_GOVERNANCE),
          hide: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
          ),
        },
        {
          name: i18n.t("settings.edr"),
          icon: CoroIcons.EDR,
          route: RouteName.EDR_PAGE,
          disabled: isModuleDisabled(SubscriptionModule.EDR),
          hide: isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR),
        },
        {
          name: i18n.t("settings.soc"),
          icon: CoroIcons.SOC,
          route: RouteName.SOC_PAGE,
          disabled: !socAddonsEnabled(),
          hide: isAccessRestricted(
            RolePermissionsScope.PROTECTION,
            ProtectionScopeModules.MANAGED_SOC
          ),
        },
        {
          name: i18n.t("settings.network"),
          icon: CoroIcons.NETWORK,
          route: RouteName.NETWORK_PAGE,
          disabled: isModuleDisabled(SubscriptionModule.NETWORK),
          hide: isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.NETWORK),
        },
        {
          name: i18n.t("settings.mdmShort"),
          icon: CoroIcons.MOBILE_DEVICE_MANAGEMENT,
          disabled: isModuleDisabled(SubscriptionModule.MDM),
          hide: isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.MDM),
        },
      ];

      return settings
        .filter((setting) => !setting.hide)
        .filter((setting) => setting.disabled === disabled);
    };

    const workspaceAssetsSettings = computed(() => {
      const settings = [
        {
          name: i18n.t("settings.users"),
          icon: CoroIcons.PROTECTED_USERS,
          route: RouteName.USERS_SETTINGS_PAGE,
          hide: isAccessRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.USERS
          ),
        },
        {
          name: i18n.t("settings.devices"),
          icon: CoroIcons.DEVICES,
          route: RouteName.DEVICES_SETTINGS_PAGE,
          hide: isAccessRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.DEVICES
          ),
        },
        {
          name: i18n.t("settings.cloudApps"),
          icon: CoroIcons.CLOUD_APPS,
          route: RouteName.CLOUD_APPS_PAGE,
          hide: isAccessRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.CLOUD_APPS
          ),
        },
      ];
      return settings.filter((setting) => !setting.hide);
    });

    const workspaceAdminSettings = computed(() => {
      const settings = [
        {
          name: i18n.t("settings.accessControl"),
          icon: CoroIcons.ADMIN_USERS,
          route: RouteName.ACCESS_CONTROL_PAGE,
          hide:
            isAccessRestricted(
              RolePermissionsScope.WORKSPACE_MANAGEMENT,
              WorkspaceManagementScopeSections.ADMIN_USERS
            ) &&
            isAccessRestricted(
              RolePermissionsScope.WORKSPACE_MANAGEMENT,
              WorkspaceManagementScopeSections.ROLES
            ) &&
            isAccessRestricted(
              RolePermissionsScope.WORKSPACE_MANAGEMENT,
              WorkspaceManagementScopeSections.ACTIVE_SESSIONS
            ),
        },
        {
          name: i18n.t("settings.activityLogs"),
          icon: CoroIcons.ACTIVITY_LOGS,
          route: RouteName.ACTIVITY_LOGS,
          hide: isAccessRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.ACTIVITY_LOGS
          ),
        },
        {
          name: i18n.t("settings.connectors"),
          icon: CoroIcons.CONNECTORS,
          route: RouteName.CONNECTORS_PAGE,
          hide: isAccessRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.CONNECTORS
          ),
        },
        {
          name: i18n.t("settings.reports"),
          icon: CoroIcons.REPORTS,
          route: RouteName.REPORTS_PAGE,
          hide: isAccessRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.REPORTS
          ),
        },
      ];
      return settings.filter((setting) => !setting.hide);
    });

    onMounted(async () => {
      if (showDemoMode.value) {
        await settingsStore.getDemoModeState();
      }
    });

    const handleProtectionSettingClick = async (setting: {
      icon: CoroIcons;
      route?: RouteName;
    }) => {
      switch (setting.icon) {
        case CoroIcons.MOBILE_DEVICE_MANAGEMENT:
          await settingsStore.loginToMdm(false);
          break;
        default:
          await router.push({ name: setting.route! });
          break;
      }
    };

    return {
      kebabCase,
      account,
      demoModeEnabledValue,
      getProtectionSettings,
      workspaceAssetsSettings,
      workspaceAdminSettings,
      showDemoMode,
      resetDemoModeData: settingsStore.resetDemoModeData,
      handleProtectionSettingClick,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .settings-btn {
    height: 150px;
    width: 150px;
    text-transform: capitalize !important;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    opacity: 1;
  }

  .settings-button__content {
    height: inherit;
    width: inherit;
    font-weight: normal;
    font-size: 16px;
  }

  .settings-btn {
    transition: all 0.4s ease-in-out;
    &--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  .settings-btn:hover {
    transform: scale(1.1);
  }
}
</style>
